import filter from 'lodash/filter';
import { ADD_WORKSHEET_TO_WORKBOOK } from 'constants/action-types/workbook-manager';
import {
  SET_WORKBOOK,
  WORKBOOK_SET_ALL,
  WORKBOOK_REMOVE_WORKSHEET,
  LOAD_WORKSHEET,
  WORKBOOK_REMOVE,
} from 'constants/action-types/workbook';
import { worksheetReducer } from 'reducers/worksheet';

const initialState = {};

export const dummyEmptyWorksheetState = worksheetReducer(undefined, {
  type: 'GET_INITIAL_STATE',
});

export const worksheetsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case WORKBOOK_REMOVE:
      return filter(state, (worksheet) => worksheet.workbookId !== action.payload).reduce(
        (map, worksheet) => {
          map[worksheet.id] = worksheet;
          return map;
        },
        {}
      );
    case WORKBOOK_REMOVE_WORKSHEET:
      if (state[action.payload.worksheetId]) {
        delete state[action.payload.worksheetId];
        return { ...state };
      }
      return state;
    case ADD_WORKSHEET_TO_WORKBOOK:
      return {
        ...state,
        [action.payload.worksheetId]: worksheetReducer(
          {
            ...dummyEmptyWorksheetState,
            id: action.payload.worksheetId,
            name: action.payload.worksheetName,
            workbookId: action.payload.workbookId,
          },
          { type: 'unknown' }
        ),
      };
    case WORKBOOK_SET_ALL:
      const workbooks = action.payload;
      const worksheetsById = Object.fromEntries(
        workbooks
          .flatMap((workbook) =>
            workbook.worksheets.map((worksheet) => ({
              workbook: workbook,
              worksheet: worksheet,
            }))
          )
          .map((_) => [
            _.worksheet.worksheetId,
            {
              ...(state[_.worksheet.worksheetId] || dummyEmptyWorksheetState),
              id: _.worksheet.worksheetId,
              name: _.worksheet.worksheetName,
              workbookId: _.workbook.id,
            },
          ])
      );

      return {
        ...state,
        ...worksheetsById,
      };
    case SET_WORKBOOK:
      const newResult = { ...state };
      for (const worksheet of action.payload.worksheets) {
        newResult[worksheet.worksheetId] = state[worksheet.worksheetId] || {
          ...dummyEmptyWorksheetState,
          id: worksheet.worksheetId,
          name: worksheet.worksheetName,
          workbookId: action.payload.id,
        };
      }
      return newResult;
    case LOAD_WORKSHEET:
      return {
        ...state,
        [action.worksheetId]: {
          ...worksheetReducer(state[action.worksheetId] || dummyEmptyWorksheetState, action),
        },
      };
    default:
      if (!action.worksheetId || !state[action.worksheetId]) {
        return state;
      }

      return {
        ...state,
        [action.worksheetId]: worksheetReducer(
          state[action.worksheetId] || dummyEmptyWorksheetState,
          action
        ),
      };
  }
};
