import { createSelector } from 'reselect';

import { getActiveWorksheet } from '../../common-selectors/get-active-worksheet';
import { getActiveVessel } from '../../common-selectors/get-active-vessel';
import { getActiveCalculationResult } from '../../common-selectors/get-active-calculation-result';

export const selector = createSelector(
  getActiveWorksheet,
  getActiveVessel,
  getActiveCalculationResult,
  (worksheet, activeVessel, calculationResult) => ({
    activeVessel,
    marketSegmentId: worksheet.marketSegmentId,
    worksheetId: worksheet.id,
    euaPrice: worksheet.eua.euaPrice,
    euaYear: worksheet.eua.euaYear,
    voyageLegs: worksheet.voyage.legs,
    openDate: activeVessel?.openPosition.openDate,
    carbonBreakdown:
      calculationResult?.carbonBreakdown ??
      getEmptyCarbonBreakdown(worksheet.eua.euaYear, worksheet.voyage.legs),
    includeInCosts: worksheet.eua.carbonCostIncludeInCosts,
    isCrslEuaPrice: worksheet.eua.isCrslEuaPrice,
    euaPriceUpdatedDate: worksheet.eua.euaPriceUpdated,
    isRefinitiv: worksheet.eua.isRefinitiv,
  })
);

export const getEmptyCarbonBreakdown = (euaYear, voyageLegs) => ({
  euaYears: [
    {
      year: euaYear,
      euaPercentage: 0,
    },
  ],
  legs: voyageLegs.map(() => ({
    byArea: [
      {
        euaFactor: 0,
        emissionsArea: 0,
        emissionsMT: 0,
        emissionsCost: 0,
      },
      {
        euaFactor: 0,
        emissionsArea: 1,
        emissionsMT: 0,
        emissionsCost: 0,
      },
    ],
    byYear: [
      {
        year: euaYear,
        requiredEuas: 0,
        euasDue: 0,
        emissionsMT: 0,
        emissionsCost: 0,
        emissionsEuaCost: 0,
      },
    ],
    summary: {
      emissionsMT: 0,
      emissionsCost: 0,
      emissionsEuaCost: 0,
      requiredEuas: 0,
    },
    totalEuasDue: 0,
    emissionsEuaCostByVoyage: 0,
  })),
  summary: {
    emissionsMT: 0,
    emissionsCost: 0,
    emissionsEuaCost: 0,
    requiredEuas: 0,
  },
  totalVoyageCost: 0,
});
