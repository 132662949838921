import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selector } from './selectors';
import Toggle from 'components/toggle';
import { IconTooltip } from 'components/icon-tooltip';
import { iconEnum } from 'components/icons';
import {
  scrubberTypeUpdated,
  deadWeightUpdated,
  draftUpdated,
  draftUnitChanged,
  tpcmiUpdated,
  immersionUnitChanged,
  grainUpdated,
  grainUnitChanged,
  constantsUpdated,
  parcelVoyageToggleUpdated,
} from 'actions/worksheet/vessel';

import type {
  ScrubberTypeUpdatedAction,
  DeadWeightUpdatedAction,
  DraftUpdatedAction,
  TpcmiUpdatedAction,
  GrainUpdatedAction,
  ConstantsUpdatedAction,
  ParcelVoyageToggleUpdatedAction,
} from './types';

import NumericInput, { validationDecorationStyles } from 'components/numeric-input';
import DropDown from 'components/dropdown';
import DraftUnitTypes, {
  DRAFT_NUMBER_OF_DECIMAL_PLACES,
  convertDraft,
} from 'constants/enums/draft-units';
import ImmersionUnitTypes, {
  IMMERSION_NUMBER_OF_DECIMAL_PLACES,
  convertImmersion,
} from 'constants/enums/immersion-units';
import GrainUnitTypes, {
  GRAIN_NUMBER_OF_DECIMAL_PLACES,
  convertGrain,
} from 'constants/enums/grain-units';
import { Subheader } from 'components/headers';

import './styles.scss';
import { getDynamicVesselValidationRules } from 'modules/worksheet/business-model/validation';
import { marketSegmentIds } from 'constants/market-segments';

type Props = {
  vesselIdName: {
    vesselId: string,
    vesselName: string,
    isBalticVessel: boolean,
  },
  openPosition: {
    locationId: string,
    locationName: string,
  },
  openDate: {
    start: DateTime,
    end: DateTime,
    displayFormat: string,
  },
  deadWeight: number,
  draft: number,
  tpcmi: number,
  grain: number,
  constants: number,
  scrubberTypeUpdated: ScrubberTypeUpdatedAction,
  deadWeightUpdated: DeadWeightUpdatedAction,
  draftUpdated: DraftUpdatedAction,
  tpcmiUpdated: TpcmiUpdatedAction,
  grainUpdated: GrainUpdatedAction,
  constantsUpdated: ConstantsUpdatedAction,
  parcelVoyageToggleUpdated: ParcelVoyageToggleUpdatedAction,
};

export const deadweightMaxPrecision = 3;

export class VesselDetail extends React.PureComponent<Props> {
  onDraftUnitChangedHandler = (draftUnit) => {
    const props = this.props;
    const draft = convertDraft(props.vessel.draft, props.vessel.draftUnit, draftUnit);
    const payload = { draft, draftUnit };

    props.draftUnitChanged(payload, this.props.worksheetId, this.props.vessel.id);
  };

  onImmersionUnitChangedHandler = (immersionUnit) => {
    const props = this.props;
    const immersion = convertImmersion(
      props.vessel.tpcmi,
      props.vessel.immersionUnit,
      immersionUnit
    );
    const payload = { immersion, immersionUnit };

    props.immersionUnitChanged(payload, this.props.worksheetId, this.props.vessel.id);
  };

  onGrainUnitChangedHandler = (grainUnit) => {
    const props = this.props;
    const grain = convertGrain(props.vessel.grain, props.vessel.grainUnit, grainUnit);
    const payload = { grain, grainUnit };

    props.grainUnitChanged(payload, this.props.worksheetId, this.props.vessel.id);
  };

  onDraftUpdated = (event) => {
    this.props.draftUpdated(event, this.props.worksheetId, this.props.vessel.id);
  };

  onDeadWeightUpdated = (event) => {
    this.props.deadWeightUpdated(event, this.props.worksheetId, this.props.vessel.id);
  };

  onTpcmiUpdated = (event) => {
    this.props.tpcmiUpdated(event, this.props.worksheetId, this.props.vessel.id);
  };

  onOpenPositionUpdated = (event) => {
    this.props.openPositionUpdated(event, this.props.worksheetId, this.props.vessel.id);
  };

  onGrainUpdated = (event) => {
    this.props.grainUpdated(event, this.props.worksheetId, this.props.vessel.id);
  };

  onConstantsUpdated = (event) => {
    this.props.constantsUpdated(event, this.props.worksheetId, this.props.vessel.id);
  };

  onScrubberTypeUpdatedHandler = (event) => {
    this.props.scrubberTypeUpdated(event, this.props.worksheetId, this.props.vessel.id);
  };

  onParcelVoyageToggleChanged = (event) => {
    this.props.parcelVoyageToggleUpdated(!event, this.props.worksheetId, this.props.vessel.id);
  };

  render() {
    const props = this.props;
    const vesselValidationRules = getDynamicVesselValidationRules(
      props.vessel,
      props.shouldAutoCalculateIntake
    );
    const isWetCargo = props.worksheetInvariantProps.marketSegmentId === marketSegmentIds.wetCargo;

    return (
      <div className="vessel-detail-multi-vessel-container">
        <div className="vessel-detail-multi-vessel-subheader">
          <Subheader text="Vessel Details" className="margin-bottom-small" />
        </div>

        <div className="vessel-detail-multi-vessel-controls">
          <div className="vessel-detail-multi-vessel-controls--left">
            <div className="vessel-property vessel-property--right-margin">
              <label className="vessel-property__label">DWT</label>
              <NumericInput
                value={props.vessel.deadWeight}
                diagnosticId="VesselDetails/DWT"
                className="vessel-detail-multi-vessel__very-small-input"
                onInputChange={this.onDeadWeightUpdated}
                maxDecimalDigits={deadweightMaxPrecision}
                {...vesselValidationRules.deadWeight.ruleUsageParams}
                validationDecorationStyle={validationDecorationStyles.borderAndLabel}
              />
            </div>
            <div className="vessel-property vessel-property--right-margin">
              <span className="vessel-property__label">
                Draft
                <DropDown
                  id="draft-unit-types"
                  items={DraftUnitTypes}
                  diagnosticId="VesselDetails/DraftUnit"
                  selectedItem={props.vessel.draftUnit}
                  onChange={this.onDraftUnitChangedHandler}
                  asLabel
                />
              </span>
              <NumericInput
                value={props.vessel.draft}
                diagnosticId="VesselDetails/Draft"
                className="vessel-detail-multi-vessel__very-small-input"
                onInputChange={this.onDraftUpdated}
                maxDecimalDigits={DRAFT_NUMBER_OF_DECIMAL_PLACES}
                {...vesselValidationRules.draft.ruleUsageParams}
                validationDecorationStyle={validationDecorationStyles.borderAndLabel}
              />
            </div>
            <div className="vessel-property vessel-property--right-margin vessel-property--top-margin">
              <span className="vessel-property__label vessel-property__label--immersion">
                <DropDown
                  id="immersion-unit-types"
                  items={ImmersionUnitTypes}
                  diagnosticId="VesselDetails/ImmersionUnit"
                  selectedItem={props.vessel.immersionUnit}
                  onChange={this.onImmersionUnitChangedHandler}
                  asLabel
                />
              </span>
              <NumericInput
                value={props.vessel.tpcmi}
                diagnosticId="VesselDetails/TPC"
                className="vessel-detail-multi-vessel__small-input"
                onInputChange={this.onTpcmiUpdated}
                maxDecimalDigits={IMMERSION_NUMBER_OF_DECIMAL_PLACES}
                {...vesselValidationRules.tpcmi.ruleUsageParams}
                validationDecorationStyle={validationDecorationStyles.borderAndLabel}
              />
            </div>
            <div className="vessel-property vessel-property--right-margin">
              <span className="vessel-property__label">
                {isWetCargo ? 'Cubic Capacity' : 'Grain'}
                <DropDown
                  id="grain-unit-types"
                  items={GrainUnitTypes}
                  diagnosticId="VesselDetails/GrainUnitTypes"
                  selectedItem={props.vessel.grainUnit}
                  onChange={this.onGrainUnitChangedHandler}
                  asLabel
                />
              </span>
              <NumericInput
                value={props.vessel.grain}
                diagnosticId="VesselDetails/Grain"
                className="vessel-detail-multi-vessel__medium-input"
                onInputChange={this.onGrainUpdated}
                maxDecimalDigits={GRAIN_NUMBER_OF_DECIMAL_PLACES}
                {...vesselValidationRules.grain.ruleUsageParams}
                validationDecorationStyle={validationDecorationStyles.borderAndLabel}
              />
            </div>
            <div className="vessel-property">
              <label className="vessel-property__label">Constants (MT)</label>
              <NumericInput
                value={props.vessel.constants}
                diagnosticId="VesselDetails/Constants"
                className="vessel-detail-multi-vessel__small-input"
                onInputChange={this.onConstantsUpdated}
                maxValue={999999999}
                {...vesselValidationRules.constants.ruleUsageParams}
                validationOptions={{
                  keepInvalidTextForUserToCorrect: true,
                  propagateInvalidValueIfFiniteNumber: true,
                }}
                validationDecorationStyle={validationDecorationStyles.borderAndLabel}
              />
            </div>
          </div>

          {isWetCargo ? (
            <div className="vessel-detail-multi-vessel-controls--right">
              <div className="vessel-detail-multi-vessel-controls--right-toggle">
                <Toggle
                  id="isParcelVoyage"
                  onChange={() => this.onParcelVoyageToggleChanged(props.vessel.isParcelVoyage)}
                  checked={props.vessel.isParcelVoyage}
                  label="Parcel Voyage"
                  diagnosticId="Vessel/ParcelVoyage"
                />
              </div>
              <div className="vessel-detail-multi-vessel-controls--right-info">
                <IconTooltip
                  icon={iconEnum.InfoOutline}
                  className="vessel-detail-multi-vessel-controls--right-info-tooltip"
                >
                  <span>
                    Selecting parcel voyage will assume <br />
                    the vessel is only being utilised on a part cargo basis
                  </span>
                </IconTooltip>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deadWeightUpdated,
      draftUpdated,
      draftUnitChanged,
      tpcmiUpdated,
      immersionUnitChanged,
      grainUpdated,
      grainUnitChanged,
      constantsUpdated,
      scrubberTypeUpdated,
      parcelVoyageToggleUpdated,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselDetail);
