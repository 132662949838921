import React from 'react';
import type { NumericRange } from 'typings/core-types';
import './styles.scss';

/* If the `min` and `max` are the same, this component will take care of displaying the scalar value. Otherwise, it will use the content from `children`. */
export function NumericRangeDisplayWithCustomRender({
  value,
  formatNumberOrNull,
  children,
}: {
  value: NumericRange,
  formatNumberOrNull: (number: number | null) => string,
}) {
  if (value.min === value.max) return <span>{formatNumberOrNull(value.min)}</span>;
  else return children;
}
