import {
  SENSITIVITY_ACTIVE_CHANGED,
  SENSITIVITY_CHANGED,
  SENSITIVITY_VALUE_CHANGED,
  SENSITIVITY_CLEAR_DATA,
  SENSITIVITY_UPDATE_DATA,
  SENSITIVITY_FIRST_FUEL_GRADE_CHANGED,
  SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED,
  SENSITIVITY_SECOND_FUEL_GRADE_CHANGED,
  SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED,
} from 'constants/action-types/sensitivity-table';
import { TC_TO_FREIGHT, FREIGHT_TO_TC } from 'modules/sensitivity-table/sensitivity-types';
import { FUEL_GRADE_380CST, FUEL_GRADE_LSMGO } from 'constants/enums/fuel-grades';
import { SET_STAGE_RIGHT_PANEL } from 'constants/action-types/stage';
import { INDIVIDUAL_SUMMARY_PANEL } from 'constants/enums/stage-panels';
const initialState = {
  isActive: false,
  activeSensitivities: [FREIGHT_TO_TC],
  grossTimeCharterRateSensitivity: 100,
  grossFreightRateSensitivity: 0.5,
  firstFuelGrade: FUEL_GRADE_380CST,
  firstFuelSensitivity: 0,
  secondFuelGrade: FUEL_GRADE_LSMGO,
  secondFuelSensitivity: 0,
  results: [],
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case SENSITIVITY_ACTIVE_CHANGED:
      return {
        ...state,
        isActive: action.payload,
      };
    case SET_STAGE_RIGHT_PANEL:
      return {
        ...state,
        isActive: action.payload === INDIVIDUAL_SUMMARY_PANEL ? state.isActive : false,
      };
    case SENSITIVITY_UPDATE_DATA:
      return {
        ...state,
        results: state.results.concat([action.payload]),
      };
    case SENSITIVITY_CLEAR_DATA:
      return {
        ...state,
        results: [],
      };
    case SENSITIVITY_CHANGED:
      return {
        ...state,
        activeSensitivities: [action.payload],
      };
    case SENSITIVITY_VALUE_CHANGED:
      return {
        ...state,
        grossTimeCharterRateSensitivity:
          action.payload.sensitivity === TC_TO_FREIGHT
            ? action.payload.value
            : state.grossTimeCharterRateSensitivity,
        grossFreightRateSensitivity:
          action.payload.sensitivity === FREIGHT_TO_TC
            ? action.payload.value
            : state.grossFreightRateSensitivity,
      };
    case SENSITIVITY_FIRST_FUEL_GRADE_CHANGED:
      return {
        ...state,
        firstFuelGrade: action.payload,
      };
    case SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED:
      return {
        ...state,
        firstFuelSensitivity: action.payload,
      };
    case SENSITIVITY_SECOND_FUEL_GRADE_CHANGED:
      return {
        ...state,
        secondFuelGrade: action.payload,
      };
    case SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED:
      return {
        ...state,
        secondFuelSensitivity: action.payload,
      };
    default:
      return state;
  }
};
