import isNil from 'lodash/isNil';
import * as actionTypes from 'constants/action-types/worksheet/speed-and-consumptions';
import type {
  SpeedAndConsAction,
  FuelGradeUpdatedAction,
} from 'modules/vessel/speed-and-consumptions/types';
import {
  ECO_BALLAST,
  ECO_LADEN,
  ECO_SAILING,
  SAILING,
  PORT_WORKING,
  LOADING,
  DISCHARGING,
  SpeedAndConsTypes,
  getSpeedAndConTypeById,
} from 'constants/enums/speed-and-con-types';
import {
  FUEL_GRADE_380CST,
  FUEL_GRADE_MDO,
  FUEL_GRADE_LSMGO,
  FUEL_GRADE_VLSFO,
} from 'constants/enums/fuel-grades';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import {
  VESSEL_SCRUBBER_CHANGED,
  VESSEL_APPLY_NEW_DETAILS,
} from 'constants/action-types/worksheet/vessel';
import { isScrubberFitted, isOpenLoopOrUnknownScrubberFitted } from 'constants/enums/scrubber';
import { isWet, isTanker, MarketSegmentId } from 'constants/market-segments';
import { SPEEDCONS_RESET } from "constants/action-types/worksheet/speed-and-consumptions";

export const defaultSpeedAndCon: IPerPhaseTypeSpeedConsItem = {
  speed: 0,
  consumption: 0,
  fuelGrade: FUEL_GRADE_380CST.key,
  generatorConsumption: 0,
  generatorFuelGrade: FUEL_GRADE_MDO.key,
  auditInfo: {
    modifiedBy: '',
    modifiedDate: '',
  },
  isActive: true,
};

const shouldSpeedAndConBeActive = (speedAndConTypeId) =>
  speedAndConTypeId !== ECO_BALLAST.id &&
  speedAndConTypeId !== ECO_LADEN.id &&
  speedAndConTypeId !== ECO_SAILING.id;

export const speedAndConsumptionsInitialState = SpeedAndConsTypes.reduce(
  (obj, speedAndCon) => {
    obj[speedAndCon.key] = buildSpeedAndConFor(speedAndCon.id);
    obj[speedAndCon.key].isActive = shouldSpeedAndConBeActive(speedAndCon.id);

    return obj;
  },
  {
    zoneSpecific: {
      seca: {
        fuelGradeId: FUEL_GRADE_LSMGO.key,
      },
      sludgeDischargeBan: {
        fuelGradeId: null,
      },
      secaAndSludgeDischargeBan: {
        fuelGradeId: null,
      },
    },
    scrubberConsumption: 0,
    isTankerIndexVessel: false,
  }
);

export default (
  state: object = speedAndConsumptionsInitialState,
  action: SpeedAndConsAction
): ISpeedAndConsumptionsViewModel => {
  switch (action.type) {
    case actionTypes.SPEEDCONS_APPLY_NEW_DETAILS:
      return {
        ...applyNewSpeedAndCons(action),
        zoneSpecific: state.zoneSpecific,
        scrubberConsumption: state.scrubberConsumption,
        isTankerIndexVessel: action.payload.isTankerIndexVessel,
      };
    case SPEEDCONS_RESET:
      return resetSpeedAndCons(state);
    case VESSEL_APPLY_NEW_DETAILS:
      return applyDefaultsForScrubberType(/* scrubberTypeId: */ action.payload.scrubber.typeId);
    case VESSEL_SCRUBBER_CHANGED:
      return applyDefaultsForScrubberType(/* scrubberTypeId: */ action.payload);
    case actionTypes.SPEEDCONS_SPEED_CHANGED:
      return updateSpeed(state, action);
    case actionTypes.SPEEDCONS_FUELGRADE_CHANGED:
      return updateFuelGrade(state, action);
    case actionTypes.SPEEDCONS_MAIN_FUELGRADE_CHANGED:
      return updateMainFuelGrade(state, action);
    case actionTypes.SPEEDCONS_ZONESPECIFIC_SECA_FUELGRADE_CHANGED:
      return {
        ...state,
        zoneSpecific: {
          ...state.zoneSpecific,
          seca: { ...state.zoneSpecific.seca, fuelGradeId: action.payload },
        },
      };
    case actionTypes.SPEEDCONS_ZONESPECIFIC_SLUGDISCHARGE_FUELGRADE_CHANGED:
      return {
        ...state,
        zoneSpecific: {
          ...state.zoneSpecific,
          sludgeDischargeBan: {
            ...state.zoneSpecific.sludgeDischargeBan,
            fuelGradeId: action.payload,
          },
        },
      };
    case actionTypes.SPEEDCONS_ZONESPECIFIC_SECA_AND_SLUGDISCHARGE_FUELGRADE_CHANGED:
      return {
        ...state,
        zoneSpecific: {
          ...state.zoneSpecific,
          secaAndSludgeDischargeBan: {
            ...state.zoneSpecific.secaAndSludgeDischargeBan,
            fuelGradeId: action.payload,
          },
        },
      };
    case actionTypes.SPEEDCONS_CONSUMPTION_CHANGED:
      return updateConsumption(state, action);
    case actionTypes.SPEEDCONS_ACTIVE_CHANGED:
      return updateActiveSpeedAndCons(state, action);
    case actionTypes.SPEEDCONS_SCRUBBER_CONSUMPTION_CHANGED:
      return {
        ...state,
        scrubberConsumption: action.payload,
      };
    default:
      return state;
  }

  function applyDefaultsForScrubberType(scrubberTypeId: ?ScrubberTypeId) {
    if (isScrubberFitted(scrubberTypeId)) {
      return {
        ...state,
        zoneSpecific: {
          ...state.zoneSpecific,
          seca: {
            fuelGradeId: FUEL_GRADE_380CST.key,
          },
          ...(isOpenLoopOrUnknownScrubberFitted(scrubberTypeId)
            ? {
                sludgeDischargeBan: {
                  fuelGradeId:
                    state.zoneSpecific.sludgeDischargeBan.fuelGradeId || FUEL_GRADE_VLSFO.key,
                },
                secaAndSludgeDischargeBan: {
                  fuelGradeId:
                    state.zoneSpecific.secaAndSludgeDischargeBan.fuelGradeId ||
                    FUEL_GRADE_LSMGO.key,
                },
              }
            : {
                sludgeDischargeBan: {
                  fuelGradeId: null,
                },
                secaAndSludgeDischargeBan: {
                  fuelGradeId: null,
                },
              }),
        },
      };
    }

    return {
      ...state,
      scrubberConsumption: 0,
      zoneSpecific: {
        ...state.zoneSpecific,
        seca: {
          fuelGradeId: FUEL_GRADE_LSMGO.key,
        },
        sludgeDischargeBan: {
          fuelGradeId: null,
        },
        secaAndSludgeDischargeBan: {
          fuelGradeId: null,
        },
      },
    };
  }
};

function buildSpeedAndConFor(
  speedAndConTypeId,
  payload,
  marketSegmentId
): ISpeedAndConsumptionEntryViewModel {
  const {
    speed,
    consumption,
    fuelGrade,
    generatorConsumption,
    generatorFuelGrade,
    auditInfo,
    defaultFuelGradeChangeNotRequired,
  } = payload || defaultSpeedAndCon;

  let { isActive } = payload || defaultSpeedAndCon;

  if (
    marketSegmentId &&
    !isWet(marketSegmentId) &&
    !isTanker(marketSegmentId) &&
    (speedAndConTypeId === SAILING.id || speedAndConTypeId === ECO_SAILING.id)
  ) {
    isActive = false;
  }

  return {
    type: parseInt(speedAndConTypeId, 10),
    speed: speed || defaultSpeedAndCon.speed,
    consumption: consumption || defaultSpeedAndCon.consumption,
    fuelGrade: fuelGrade || defaultSpeedAndCon.fuelGrade,
    generatorConsumption: generatorConsumption || defaultSpeedAndCon.generatorConsumption,
    generatorFuelGrade: generatorFuelGrade || defaultSpeedAndCon.generatorFuelGrade,
    auditInfo: {
      modifiedBy:
        auditInfo && auditInfo.modifiedBy
          ? auditInfo.modifiedBy
          : defaultSpeedAndCon.auditInfo.modifiedBy,
      modifiedDate:
        auditInfo && auditInfo.modifiedDate
          ? auditInfo.modifiedDate
          : defaultSpeedAndCon.auditInfo.modifiedDate,
    },
    isActive: isNil(isActive) ? shouldSpeedAndConBeActive(speedAndConTypeId) : isActive,
    defaultFuelGradeChangeNotRequired: defaultFuelGradeChangeNotRequired,
  };
}

export function getSpeedAndConsumptionViewModelFromSpeedAndConsDto(
  speedAndConsDto: ISpeedAndConsumptionContainer,
  marketSegmentId: MarketSegmentId
): ISpeedAndConsumptionsViewModel {
  return SpeedAndConsTypes.reduce(
    (obj, speedAndCon) => {
      obj[speedAndCon.key] = buildSpeedAndConFor(
        speedAndCon.id,
        speedAndConsDto[speedAndCon.key],
        marketSegmentId
      );

      return obj;
    },
    {
      zoneSpecific: {
        seca: {
          fuelGradeId: speedAndConsDto.zoneSpecific.seca.fuelGradeId,
        },
        sludgeDischargeBan: {
          fuelGradeId:
            (speedAndConsDto.zoneSpecific.sludgeDischargeBan &&
              speedAndConsDto.zoneSpecific.sludgeDischargeBan.fuelGradeId) ||
            null,
        },
        secaAndSludgeDischargeBan: {
          fuelGradeId:
            (speedAndConsDto.zoneSpecific.secaAndSludgeDischargeBan &&
              speedAndConsDto.zoneSpecific.secaAndSludgeDischargeBan.fuelGradeId) ||
            null,
        },
      },
      scrubberConsumption: speedAndConsDto.scrubberConsumption,
      isTankerIndexVessel: speedAndConsDto.isTankerIndexVessel,
    }
  );
}

function updateSpeed(state: any, action: any) {
  const newState = { ...state };
  const speedAndCon = newState[getSpeedAndConTypeById(action.payload.speedAndConType).key];
  speedAndCon.speed = action.payload.speed;
  return newState;
}

function updateFuelGrade(state: any, action: FuelGradeUpdatedAction) {
  const newState = { ...state };
  const speedAndCon = newState[getSpeedAndConTypeById(action.payload.speedAndConType).key];
  if (action.payload.isMainEngine) {
    speedAndCon.fuelGrade = action.payload.fuelGrade.key;
  } else {
    speedAndCon.generatorFuelGrade = action.payload.fuelGrade.key;
  }

  return newState;
}

function updateMainFuelGrade(state: any, action: MainFuelGradeUpdatedAction) {
  const newState = { ...state };
  const conditionKeys = [
    'ballast',
    'laden',
    'ecoLaden',
    'ecoBallast',
    'idle',
    'manoeuvring',
    'canal',
    'loading',
    'discharging',
    'sailing',
    'ecoSailing',
  ];

  conditionKeys
      .filter((key) => !!newState[key])
      .forEach((key) => {
        if (action.payload.isMainEngine) {
          newState[key] = {
            ...newState[key],
            fuelGrade: action.payload.key,
          };
        } else {
          newState[key] = {
            ...newState[key],
            generatorFuelGrade: action.payload.key,
          };
        }
      });

  return newState;
}

function updateConsumption(state: any, action: any) {
  const newState = { ...state };
  const speedAndCon = newState[getSpeedAndConTypeById(action.payload.speedAndConType).key];
  if (action.payload.isMainEngine) {
    speedAndCon.consumption = action.payload.consumption;
  } else {
    speedAndCon.generatorConsumption = action.payload.consumption;
  }
  return newState;
}

function applyNewSpeedAndCons(action: any): ISpeedAndConsumptionsViewModel {
  let defaultSpeedAndCons = mapValues(
    keyBy(SpeedAndConsTypes, (_) => _.key),
    (speedAndCon) => ({
      ...buildSpeedAndConFor(
        speedAndCon.id,
        action.payload.speedAndCons == null
          ? null
          : // NOTE #79570 : Build Speed And Cons for LOADING / DISCHARGING using the  Working SpeedAndCons (speedAndCon.id == 9)
            // No concept of Loading / Discharging in Vessel API yet. So using Working for both loading and discharging -
            action.payload.speedAndCons[
              !isWet(action.payload.marketSegmentId) &&
              !isTanker(action.payload.marketSegmentId) &&
              (speedAndCon.id === LOADING.id || speedAndCon.id === DISCHARGING.id)
                ? PORT_WORKING.id
                : speedAndCon.id
            ]
      ),
      isActive:
        (speedAndCon.id === SAILING.id || speedAndCon.id === ECO_SAILING.id) &&
        !isWet(action.payload.marketSegmentId) &&
        !isTanker(action.payload.marketSegmentId)
          ? false
          : shouldSpeedAndConBeActive(speedAndCon.id),
    })
  );

  defaultSpeedAndCons = applyFuelGradesByScrubber(defaultSpeedAndCons, action.payload.scrubber);

  return defaultSpeedAndCons;
}

function resetSpeedAndCons(state: any) {
  return {
    ...state,
    ballast: defaultSpeedAndCon,
    canal: defaultSpeedAndCon,
    discharging: defaultSpeedAndCon,
    ecoBallast: defaultSpeedAndCon,
    ecoLaden: defaultSpeedAndCon,
    ecoSailing: defaultSpeedAndCon,
    idle: defaultSpeedAndCon,
    laden: defaultSpeedAndCon,
    loading: defaultSpeedAndCon,
    manoeuvring: defaultSpeedAndCon,
    sailing: defaultSpeedAndCon,
  }
}

function updateActiveSpeedAndCons(state: any, action: any) {
  const newState = { ...state };
  const { payload } = action;

  payload.nonActiveSpeedAndCons.forEach((nonActiveSpeedAndCon) => {
    const speedAndCon = newState[getSpeedAndConTypeById(nonActiveSpeedAndCon).key];
    speedAndCon.isActive = false;
  });

  const speedAndCon = newState[getSpeedAndConTypeById(payload.activeSpeedAndCon).key];
  speedAndCon.isActive = true;

  return newState;
}

function applyFuelGradesByScrubber(defaultSpeedAndCons, scrubber) {
  // we don't need this func anymore - new sandc api is doing
  // will be removed later
  Object.keys(defaultSpeedAndCons).forEach((objectKey) => {
    let speedAndConType = defaultSpeedAndCons[objectKey];
    if (!speedAndConType.defaultFuelGradeChangeNotRequired) {
      if (scrubber == null || !isScrubberFitted(scrubber.typeId)) {
        speedAndConType.fuelGrade = FUEL_GRADE_VLSFO.key;
        speedAndConType.generatorFuelGrade = FUEL_GRADE_LSMGO.key;
      } else if (isScrubberFitted(scrubber.typeId)) {
        speedAndConType.fuelGrade = FUEL_GRADE_380CST.key;
        speedAndConType.generatorFuelGrade = FUEL_GRADE_MDO.key;
      }
    }
  });

  return defaultSpeedAndCons;
}
