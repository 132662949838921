import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cargo from './cargo/cargo';
import {
  setCargoFromDto,
  stowageUpdated,
  specificGravityUpdated,
  stowageUnitUpdated,
  grossVoyageRateUpdated,
  brokerCommissionVoyageRateUpdated,
  addressCommissionVoyageRateUpdated,
} from 'actions/worksheet/cargoes';
import { getStowageUnitTypeById } from 'constants/enums/stowage-factor-units';

import type {
  CargoUpdated,
  StowageUpdated,
  SpecificGravityUpdated,
  StowageUnitUpdated,
  GrossVoayageRateUpdated,
  BrokerCommissionVoyageRateUpdated,
  AddressCommissionVoyageRateUpdated,
} from 'actions/worksheet/cargoes';
import { selector } from './selector';
import AdditionalIncomes from './additional-incomes/additional-incomes';

type Props = {
  cargoUpdated: CargoUpdated,
  specificGravityUpdated: SpecificGravityUpdated,
  stowageUpdated: StowageUpdated,
  stowageUnitUpdated: StowageUnitUpdated,
  grossVoayageRateUpdated: GrossVoayageRateUpdated,
  brokerCommissionVoyageRateUpdated: BrokerCommissionVoyageRateUpdated,
  addressCommissionVoyageRateUpdated: AddressCommissionVoyageRateUpdated,
};

const Cargoes = (props: Props) => {
  const onStowageUpdated = useCallback(
    (...args) => {
      props.stowageUpdated(...args, props.worksheetId);
    },
    [props.worksheetId]
  );

  const onSpecificGravityUpdated = useCallback(
    (...args) => {
      props.specificGravityUpdated(...args, props.worksheetId);
    },
    [props.worksheetId]
  );

  const onCargoTypeUpdated = useCallback(
    (...args) => {
      props.setCargoFromDto(...args, props.worksheetId);
    },
    [props.worksheetId]
  );

  const onStowageUnitUpdated = useCallback(
    (...args) => {
      props.stowageUnitUpdated(...args, props.worksheetId);
    },
    [props.worksheetId]
  );

  const onGrossVoyageRateUpdated = useCallback(
    (...args) => {
      props.grossVoyageRateUpdated(...args, props.worksheetId);
    },
    [props.worksheetId]
  );

  const onBrokerCommissionVoyageRateUpdated = useCallback(
    (...args) => {
      props.brokerCommissionVoyageRateUpdated(...args, props.worksheetId);
    },
    [props.worksheetId]
  );

  const onAddressCommissionVoyageRateUpdated = useCallback(
    (...args) => {
      props.addressCommissionVoyageRateUpdated(...args, props.worksheetId);
    },
    [props.worksheetId]
  );

  return (
    <div className="worksheet-section-wrapper worksheet-section-wrapper--cargo">
      <h2>Cargo</h2>
      <div className="worksheet-section-wrapper__components">
        {props.cargoes.map((cargo, index) => (
          <Cargo
            key={cargo.id}
            {...cargo}
            shouldAutoCalculateIntake={props.shouldAutoCalculateIntake}
            stowageUnit={getStowageUnitTypeById(cargo.stowageUnit)}
            onCargoTypeUpdated={onCargoTypeUpdated}
            onStowageUpdated={onStowageUpdated}
            onSpecificGravityUpdated={onSpecificGravityUpdated}
            onStowageUnitUpdated={onStowageUnitUpdated}
            onGrossVoyageRateUpdated={onGrossVoyageRateUpdated}
            onBrokerCommissionVoyageRateUpdated={onBrokerCommissionVoyageRateUpdated}
            onAddressCommissionVoyageRateUpdated={onAddressCommissionVoyageRateUpdated}
          />
        ))}
      </div>
      <AdditionalIncomes />
    </div>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCargoFromDto,
      stowageUpdated,
      specificGravityUpdated,
      stowageUnitUpdated,
      grossVoyageRateUpdated,
      brokerCommissionVoyageRateUpdated,
      addressCommissionVoyageRateUpdated,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Cargoes);
