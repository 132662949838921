import {
  mapRouteResultToRouteViewModel,
  enrichRouteViewModelWithExcludedWaypoints,
} from 'reducers/worksheet/voyage';
import { createEmptyRouteViewModel } from 'reducers/worksheet/voyage/voyage-leg-builder';
import { isValidSailingGeolocationsPair } from 'actions/worksheet/voyage';
import { NonVoidTryFunctionUtils } from 'utilities/functions/try-functions/non-void-try-function';
import type { NonVoidTryFunctionResult } from 'utilities/functions/try-functions/non-void-try-function';

import { getRouteFromTo } from 'api/clients/route';

export type CalculateInboundRouteVariantViewModelUnsuccessfulResult = (
  | RouteCalculationUnsuccessfulResult
  /* Adding `UnsuccessfulResultFromCapturingThrownError` alternative because we are going to catch exceptions and returning them (to allow an atomic decision when all results are known) */
  | UnsuccessfulResultFromCapturingThrownError
) &
  InboundRouteVariantIdentity & {
    /**
      The waypoints that were requested to be avoid (or empty array if none were requested)
     */
    waypoints: Array<AvoidedWaypoint>,
  };

export async function tryCalculateInboundRouteVariantViewModelFromTo(
  fromLocationGeoCoordsOrNull: Geolocation | null,
  toLocationGeoCoordsOrNull: Geolocation | null,
  options: {
    waypointsToExclude: IWaypointIdentity[],
    avoidSecaZones: boolean,
    marketSegmentId: string,
  },
  cancelToken?: CancelToken,
  graphVersion?: number,
): NonVoidTryFunctionResult<
  InboundRouteVariantViewModel,
  CalculateInboundRouteVariantViewModelUnsuccessfulResult,
> {
  let routeViewModelWithoutAvoidedWaypoints: RouteViewModel;
  if (!isValidSailingGeolocationsPair(fromLocationGeoCoordsOrNull, toLocationGeoCoordsOrNull))
    routeViewModelWithoutAvoidedWaypoints = createEmptyRouteViewModel(graphVersion);
  else {
    let routeResult:
      | RouteCalculationSuccessfulResult
      | RouteCalculationUnsuccessfulResult
      | UnsuccessfulResultFromCapturingThrownError;
    try {
      routeResult = await getRouteFromTo(
        fromLocationGeoCoordsOrNull,
        toLocationGeoCoordsOrNull,
        options.waypointsToExclude.map((_) => _.locationId),
        options.avoidSecaZones,
        options.marketSegmentId,
        cancelToken
      );
    } catch (error) {
      routeResult = NonVoidTryFunctionUtils.createUnsuccessfulResult(error);
      // Not rethrowing because this call is meant to be part of a compound action, where error handling decision (including logging) is taken after collecting all errors from all component actions.
    }

    if (NonVoidTryFunctionUtils.isUnsuccessfulResult(routeResult)) {
      routeViewModelWithoutAvoidedWaypoints = {
        ...routeResult,
        // Need to initialize the `waypoints` array so that `enrichRouteViewModelWithExcludedWaypoints` can add the avoided waypoints to it.
        waypoints: [],
      };
    } else routeViewModelWithoutAvoidedWaypoints = mapRouteResultToRouteViewModel(routeResult);
  }

  // This is #AddingPointsToAvoidToSingleVoyageEntry
  const routeViewModelResult = enrichRouteViewModelWithExcludedWaypoints({
    routeViewModel: routeViewModelWithoutAvoidedWaypoints,
    waypointsRequestedToExclude: options.waypointsToExclude,
  });

  return {
    fromLocationGeoCoords: fromLocationGeoCoordsOrNull,
    ...routeViewModelResult,
  };
}
