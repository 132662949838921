import React from 'react';
import { WarningIcon } from './../../icons';
import './styles.scss';
import classNames from 'classnames';

const ErrorBox = ({ className, children, ...props }) => (
  <div role="alert" {...props} className={classNames('error-box', className)}>
    <WarningIcon
      aria-label="Error"
      className="notice-box__type-icon"
      remainingnotifications={
        props && props.additionalprops ? props.additionalprops.remainingNotifications : 0
      }
    />
    <span className="notice-box__content">{children}</span>
  </div>
);

export default ErrorBox;
