import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export const Panel = (props) => {
  const classes = classNames('panel', props.className);
  return <div className={classes}>{props.children}</div>;
};

export const PanelSection = (props) => {
  const classes = classNames('panel-section', props.className);
  return <div className={classes}>{props.children}</div>;
};

export const PanelSectionElement = (props) => {
  const classes = classNames('panel-section-element', props.className);
  return <div className={classes}>{props.children}</div>;
};
