import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showCarbonFactorTableModal } from '../../actions/carbon';
import { Header } from '../../components/headers';
import { Modal } from '../../components/modal';
import { Grid } from '../../components/grid';
import { IconButton } from '../../components/button';
import { iconEnum } from '../../components/icons';
import { Overlay } from '../overlay';
import { selector } from './selector';
import './styles.scss';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const CarbonFactorTableModal: FC = ({
  carbonFactors,
  isCarbonFactorTableModalVisible,
  showCarbonFactorTableModal,
}) => {
  const gridOptions = {
    rowHeight: 32,
    headerHeight: 32,
    animateRows: true,
    defaultColDef: {
      suppressMovable: true,
      resizable: false,
    },
    rowClass: 'carbon-factor-row',
    getRowClass: (params) => {
      if (params.node.rowIndex % 2 === 0) {
        return 'carbon-factor-row-even';
      }
    },
    columnDefs: [
      {
        headerName: 'Fuel Type',
        field: 'fuelTypeName',
        width: 300,
      },
      {
        headerName: 'Fuel Grade Code',
        field: 'shortName',
        width: 150,
      },
      {
        headerName: 'Factor',
        field: 'factor',
        width: 150,
      },
    ],
  };

  return (
    <>
      <Modal isVisible={isCarbonFactorTableModalVisible}>
        <div className="carbon-factor-table-modal">
          <div className="carbon-factor-table-modal--header">
            <Header
              text="Carbon Factors Reference"
              className="carbon-factor-table-modal--header__title"
            />
            <IconButton
              icon={iconEnum.Close}
              className="carbon-factor-table-modal--header__close-btn"
              onClick={() => showCarbonFactorTableModal(false)}
              diagnosticId="CarbonFactorTable/CloseTable"
            />
          </div>
          <Grid
            getRowId={(params) => params.data.id}
            rowData={carbonFactors}
            gridOptions={gridOptions}
            className="carbon-factor-table-modal__grid"
            reactiveCustomComponents
          />
        </div>
      </Modal>
      <Overlay
        onClick={() => showCarbonFactorTableModal(false)}
        hidden={!isCarbonFactorTableModalVisible}
      />
    </>
  );
};

const mapStateToProps = selector;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showCarbonFactorTableModal,
    },
    dispatch
  );
};

const CarbonFactorTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarbonFactorTableModal);

export default CarbonFactorTableContainer;
