import {
  SET_ACTIVE_WORKSHEET_ID,
  WORKBOOK_REMOVE_WORKSHEET,
} from 'constants/action-types/workbook';

export const activeWorksheetId = (state = null, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_WORKSHEET_ID:
      return action.payload.worksheetId;
    case WORKBOOK_REMOVE_WORKSHEET:
      return state === action.payload.worksheetId ? null : state;
    default:
      return state;
  }
};
