import React, { Component, Fragment } from 'react';
import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import round from 'lodash/round';
import { search } from 'api/clients/cargo-type';
import Downshift from 'downshift';
import type { ApiCargo, Cargo, CargoMenuItem } from './types';
import { debounceDefaults } from 'constants/defaults/debounce';
import { STOWAGE_NUMBER_OF_DECIMAL_PLACES } from 'constants/enums/stowage-factor-units';
import { trackEvent, eventDestination, trackChangeEvent } from 'diagnostics/calc-trackevents';

import './styles.scss';
import { isTanker } from '../../constants/market-segments';

type Props = {
  cargo: Cargo,
  onChange: Function,
  marketSegmentId: string,
};

type State = {
  cargoes: Array<CargoMenuItem>,
  cargo: Cargo,
};

class CargoAutocomplete extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { cargoes: [], cargo: props.cargo };
    this.searchCargoes = debounce(this.searchCargoes, debounceDefaults.wait, {
      leading: debounceDefaults.leading,
      maxWait: debounceDefaults.maxWait,
    });
  }

  cancelTokenSource: CancelTokenSource = null;
  cache: Object = {};

  emptyCargo: Cargo = {
    id: null,
    name: '',
    alias: '',
    stowageMeters: 0,
    stowageFeet: 0,
    parentCargoTypeName: '',
  };

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return (
      this.props.cargo.cargoTypeId !== nextProps.cargo.cargoTypeId ||
      (isNil(this.props.cargo.cargoTypeId) && isNil(nextProps.cargo.cargoTypeId)) ||
      this.state !== nextState
    );
  }

  searchCargoes = async (searchTerm: string): void => {
    if (this.cancelTokenSource !== null) {
      this.cancelTokenSource.cancel('Cancelled!');
    }

    if (!this.isSearchTermValid(searchTerm)) {
      this.clearCargoesInState();
      return;
    }

    // check if we've cached results for this search term before
    const cachedCargoes = this.cache[searchTerm];
    if (cachedCargoes) {
      this.setState({ cargoes: cachedCargoes });
      return;
    }

    this.cancelTokenSource = axios.CancelToken.source();

    try {
      const cargoTypes = await search(searchTerm, this.cancelTokenSource);
      const cargoes: Array<CargoMenuItem> = this.mapResponse(cargoTypes);
      this.setState({ cargoes: cargoes });
      this.cache[searchTerm] = cargoes;
    } catch (err) {
      if (axios.isCancel(err)) {
        trackEvent(
          'CargoAutocomplete/searchCargoes',
          'Worksheet Cargo Search or Autocomplete Cancelled',
          {
            searchTerm,
          },
          {},
          eventDestination.ANALYSIS
        );
        return;
      }
      this.clearCargoesInState();
      // TODO #feedbackToUIForAutocompleteState
      throw err;
    }
  };

  mapResponse = (apiCargoes: Array<ApiCargo>): Array<CargoMenuItem> => {
    const cargoes = apiCargoes.map((apiCargo) => ({
      cargoTypeId: apiCargo.id,
      name: apiCargo.name,
      stowageMeters: toNumber(apiCargo.stowageFactorMeters || 0),
      stowageFeet: toNumber(apiCargo.stowageFactorFeet || 0),
      parentCargoType: apiCargo.parentCargoTypeName,
      aliases: apiCargo.aliases,
    }));

    return cargoes;
  };

  isSearchTermValid(searchTerm: string): boolean {
    return (
      isNil(searchTerm) === false &&
      isEmpty(searchTerm) === false &&
      searchTerm !== this.props.cargo.name
    );
  }

  onBlurHandler = (event: SyntheticFocusEvent<HTMLInputElement>): void => {
    const inputValue = event && event.target && event.target.value;

    if (isEmpty(inputValue)) {
      event.nativeEvent.preventDownshiftDefault = true;
      this.props.onChange(this.emptyCargo);
    } else if (inputValue !== this.props.cargo.name) {
      event.nativeEvent.preventDownshiftDefault = true;

      const unverifiedCargo = {
        cargoTypeId: null,
        name: inputValue,
      };

      this.props.onChange(unverifiedCargo);
    }

    if (this.props.cargo.name !== inputValue) {
      trackChangeEvent(this.props.diagnosticId, {
        oldValue: this.props.cargo.name,
        newValue: inputValue,
      });
    }
  };

  onFocusHandler = (event: SyntheticFocusEvent<HTMLInputElement>): void => {
    event.target.select();
    this.clearCargoesInState();
  };

  onChangeHandler = (item: CargoMenuItem): void => {
    if (!item.stowageUnit && this.props.cargo.stowageUnit) {
      item.stowageUnit = this.props.cargo.stowageUnit;
    }
    this.props.onChange(item);
    this.clearCargoesInState();
  };

  onKeyDownHandler = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
    selectHighlightedItem: Function,
    isOpen: boolean
  ): void => {
    const keyCode = event?.key;

    if (isNil(keyCode) === false && keyCode === 'Tab') {
      selectHighlightedItem();
      this.clearCargoesInState();
    }
    if (isNil(keyCode) === false && keyCode === 'Escape' && !isOpen) {
      event.nativeEvent.preventDownshiftDefault = true;
    }
  };

  clearCargoesInState = (): void => {
    this.setState({ cargoes: [] });
  };

  getMenuItemCssClass = (highlightedIndex: number, index: number): string => {
    return highlightedIndex === index
      ? 'cargo-autocomplete__menu-item cargo-autocomplete__menu-item--highlighted'
      : 'cargo-autocomplete__menu-item';
  };

  itemToString = (item: Cargo | null): string => {
    return isNil(item) || isNil(item.name) ? '' : item.name;
  };

  getStowageFactorWithUnits = (stowageFactor: number, useMetricUnits: boolean): React.Node => {
    if (isNil(stowageFactor) || stowageFactor === 0) {
      return null;
    }

    const units = this.getStowageFactorUnits(useMetricUnits);

    return (
      <Fragment>
        {round(stowageFactor, STOWAGE_NUMBER_OF_DECIMAL_PLACES).toFixed(
          STOWAGE_NUMBER_OF_DECIMAL_PLACES
        )}
        {units}
      </Fragment>
    );
  };

  getStowageFactorUnits = (useMetricUnits: boolean): React.Node => {
    return useMetricUnits ? (
      <span className="cargo-autocomplete__menu-item--stowage-units">m&sup3;/MT</span>
    ) : (
      <span className="cargo-autocomplete__menu-item--stowage-units">ft&sup3;/MT</span>
    );
  };
  componentDidUpdate(prevProps: Props) {
    // Check if this.props.port has changed
    if (
      prevProps.cargo.name !== this.props.cargo.name ||
      prevProps.cargo.id !== this.props.cargo.id
    ) {
      // Update the state with the new port value
      this.setState({ cargo: this.props.cargo });
    }
  }
  render() {
    return (
      <Downshift
        onChange={this.onChangeHandler}
        onInputValueChange={this.searchCargoes}
        selectedItem={this.state.cargo}
        itemToString={this.itemToString}
      >
        {({ getInputProps, getItemProps, isOpen, highlightedIndex, selectHighlightedItem }) => (
          <div className="cargo-autocomplete">
            <input
              {...getInputProps({
                placeholder: 'Enter cargo',
                onBlur: this.onBlurHandler,
                onFocus: this.onFocusHandler,
                onKeyDown: (event) => this.onKeyDownHandler(event, selectHighlightedItem, isOpen),
              })}
              className="cargo-autocomplete__input"
              maxLength={160}
            />
            {isOpen && this.state.cargoes.length > 0 ? (
              <div className="cargo-autocomplete__menu" data-test="menu">
                {this.state.cargoes.map((cargo: CargoMenuItem, index: number) => (
                  <div
                    {...getItemProps({ item: cargo })}
                    key={cargo.id}
                    className={this.getMenuItemCssClass(highlightedIndex, index)}
                  >
                    <div className="cargo-autocomplete__menu-item--part cargo-autocomplete__menu-item--name">
                      <div className="cargo-autocomplete__menu-item--name-inner">{cargo.name}</div>
                    </div>
                    <div className="cargo-autocomplete__menu-item--part cargo-autocomplete__menu-item--stowage">
                      <div className="cargo-autocomplete__menu-item--stowage-inner">
                        {this.getStowageFactorWithUnits(cargo.stowageMeters, true)}
                      </div>
                    </div>
                    <div className="cargo-autocomplete__menu-item--part cargo-autocomplete__menu-item--stowage">
                      <div className="cargo-autocomplete__menu-item--stowage-inner">
                        {this.getStowageFactorWithUnits(cargo.stowageFeet, false)}
                      </div>
                    </div>
                    {!isTanker(this.props.marketSegmentId) && (
                      <>
                        <div className="cargo-autocomplete__menu-item--part cargo-autocomplete__menu-item--parent-type">
                          <div className="cargo-autocomplete__menu-item--parent-type-inner">
                            {cargo.parentCargoType}
                          </div>
                        </div>
                        <div className="cargo-autocomplete__menu-item--part cargo-autocomplete__menu-item--aliases">
                          <div className="cargo-autocomplete__menu-item--aliases-inner">
                            {cargo.aliases}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

export default CargoAutocomplete;
