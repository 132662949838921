import { createWorksheet } from './create-worksheet';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

export type CreateWorksheetForCargoOrderResponse = CreateWorksheetResponse;

export type CreateWorksheetForCargoOrderVessel = {
  id: number,
  deadweight?: number,
  draft?: number,
  tpc?: number,
  openPosition?: CreateWorksheetVesselOpenPosition,
  ballastSpeed?: number,
  ballastConsumption?: number,
  ladenSpeed?: number,
  ladenConsumption?: number,
};

type CreateWorksheetForCargoOrderCargoType = {
  id: number,
  name: string,
};

type CreateWorksheetForCargoOrderVoyageEntry = {
  voyageEntryType: VoyageEntryType,
  locationId: string,
};

export type CreateWorksheetForCargoOrderRequest = {
  worksheetId: string,
  vessels: CreateWorksheetForCargoOrderVessel[],
  cargoType: CreateWorksheetForCargoOrderCargoType,
  cargoQuantity: number,
  voyageEntries: CreateWorksheetForCargoOrderVoyageEntry[],
  grossVoyageRate?: number,
};

export const createWorksheetForCargoOrder =
  (
    request: CreateWorksheetForCargoOrderRequest,
    createdFromProgramId: ProgramId,
    createdFromOriginId: OriginId
  ) =>
  async (dispatch, getState): CreateWorksheetForCargoOrderResponse => {
    validateRequest(request);

    return await dispatch(createWorksheet(request, createdFromProgramId, createdFromOriginId));
  };

function validateRequest(request: CreateWorksheetForCargoOrderRequest): void {
  const { worksheetId, vessels, cargoType, cargoQuantity, voyageEntries } = request;

  if (isNil(worksheetId)) {
    throw new Error('`worksheetId` property must be provided');
  }

  if (isNil(vessels) || vessels.length === 0) {
    throw new Error('`vessels` property needs to be a non-empty array');
  }

  if (isNil(cargoType)) {
    throw new Error('`cargoType` property must be provided');
  }

  if (!isNumber(cargoQuantity)) {
    throw new Error('`cargoQuantity` property must be provided');
  }

  if (isNil(voyageEntries) || voyageEntries.length === 0) {
    throw new Error('`voyageEntries` property needs to be a non-empty array');
  }
}
