import { createSelector } from 'reselect';
import { getCostTypeById } from 'constants/enums/cost-types';

const expenseTransform = (cost) => ({
  ...cost,
  costType: getCostTypeById(cost.costType),
});

export const selector = createSelector(
  (state) => state.activeWorksheetId,
  (state) => state.worksheetsById[state.activeWorksheetId].additionalExpenses.preDefined,
  (state) => state.worksheetsById[state.activeWorksheetId].additionalExpenses.others,
  (worksheetId, preDefined, others) => ({
    worksheetId,
    preDefinedExpenses: preDefined.map(expenseTransform),
    otherExpenses: others.map(expenseTransform),
  })
);
