import { createSelector } from 'reselect';

export const selector = createSelector(
  (state) => state.carbonFactors,
  (state) => state.appMetadata.isCarbonFactorTableModalVisible,
  (carbonFactors, isCarbonFactorTableModalVisible) => ({
    carbonFactors: carbonFactors.map((carbonFactor) => ({
      ...carbonFactor,
      factor: carbonFactor.factor.toFixed(4),
    })),
    isCarbonFactorTableModalVisible,
  })
);
