import isNil from 'lodash/isNil';
import type {
  GetIdentityPrimitiveFunc,
  GetNonNilIdentityPrimitiveFunc,
} from 'utilities/iterable/aggregation-typings';

export class Geolocation implements IPoint {
  constructor(latitude, longitude) {
    this.latitude = latitude;
    this.longitude = longitude;
  }

  static isValid = (location: IPoint) =>
    !isNil(location) && !isNil(location.latitude) && !isNil(location.longitude);

  static areEqual = (location1: IPoint, location2: IPoint) =>
    location1.latitude === location2.latitude && location1.longitude === location2.longitude;

  static areEqualNullSafe(location1: IPoint | null, location2: IPoint | null) {
    if (location1 === location2) return true;
    if (location1 === null || location2 === null) return false;
    return Geolocation.areEqual(location1, location2);
  }

  static fromObject = (objectWithLocationInfo: IPoint): Geolocation =>
    new Geolocation(objectWithLocationInfo.latitude, objectWithLocationInfo.longitude);

  static fromObjectOrNullIfEmpty = (objectWithLocationInfo: IPoint): Geolocation | null => {
    return Geolocation.isEmpty(objectWithLocationInfo)
      ? null
      : Geolocation.fromObject(objectWithLocationInfo);
  };

  static isEmpty = (location: IPoint) => isNil(location.latitude) && isNil(location.longitude);

  static getIdentityPrimitiveNullSafe: GetIdentityPrimitiveFunc<IPoint | null> = (
    objectWithLocationInfoOrNull: IPoint | null
  ) => {
    return objectWithLocationInfoOrNull === null
      ? objectWithLocationInfoOrNull
      : Geolocation.getIdentityPrimitive(objectWithLocationInfoOrNull);
  };

  static getIdentityPrimitive: GetNonNilIdentityPrimitiveFunc<IPoint> = (
    objectWithLocationInfo: IPoint
  ) => {
    return JSON.stringify(Geolocation.fromObjectOrNullIfEmpty(objectWithLocationInfo));
  };
}
