import React, { FC } from 'react';
import './styles.scss';
import Divider from '../../../components/divider';
import { ComparisonCheckbox } from '../comparison-table-setter-checkbox';
import { CheckboxState } from '../comparison-table-setter-content';
import classNames from 'classnames';
import { iconEnum } from 'components/icons';
import { IconButton } from 'components/button';

export type Item = {
  columnId: string,
  name: string,
  parentId: string,
};

interface IComparisonTableSetterOptions {
  items: Item[];
  idsToRender?: string[];
  indentLevel?: number;
  onClick?: (id: string) => void;
  getStateForId: (id: string) => CheckboxState;
  getExpandStateForId?: (id: string) => boolean;
  onExpand?: (id: string) => void;
}
export const ComparisonTableSetterOptions: FC<IComparisonTableSetterOptions> = ({
  items,
  idsToRender = [],
  indentLevel = 0,
  onClick,
  getStateForId,
  onExpand,
  getExpandStateForId,
}) => {
  if (!idsToRender.length) {
    idsToRender = items.filter((i) => !i.parentId).map((i) => i.columnId);
  }
  const getChildNodes = (parentId: number, isExpanded: boolean) => {
    const nodeItems = items.filter((i) => i.parentId === parentId);
    if (!nodeItems.length) return null;

    return (
      <div className={`subcategories-section-${isExpanded ? 'visible' : 'hidden'}`}>
        <ComparisonTableSetterOptions
          items={items}
          idsToRender={nodeItems.map((i) => i.columnId)}
          indentLevel={indentLevel + 1}
          onClick={onClick}
          getStateForId={getStateForId}
          getExpandStateForId={getExpandStateForId}
        />
      </div>
    );
  };

  return (
    <>
      {idsToRender?.map((id, index, idsToRender) => {
        const item = items.find((i) => i.columnId === id);
        const lastItem = index === idsToRender.length - 1;
        const checkboxState = getStateForId(id);
        const isExpanded = getExpandStateForId(id);

        return (
          <div key={item.columnId}>
            <div
              className={classNames('category', {
                subcategory: indentLevel !== 0,
              })}
              style={{ paddingBottom: lastItem && !isExpanded ? '0' : '' }}
            >
              <ComparisonCheckbox
                label={item.name}
                id={item.columnId}
                onClick={() => onClick(item.columnId)}
                isChecked={checkboxState === CheckboxState.CHECKED}
                isIndeterminate={checkboxState === CheckboxState.INDETERMINATE}
              />
              {indentLevel === 0 && (
                <IconButton
                  icon={isExpanded ? iconEnum.ChevronUp : iconEnum.ChevronDown}
                  onClick={() => onExpand(item.columnId)}
                />
              )}
            </div>
            {getChildNodes(item.columnId, isExpanded)}
            {item.hasDivider && (
              <div className="option-divider">
                <Divider />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
