import React, { FC, useRef, useEffect } from 'react';
import './styles.scss';

type checkboxProps = {
  label: string,
  isChecked?: boolean,
  isIndeterminate?: boolean,
  onClick?: () => void,
  id: string,
};

export const ComparisonCheckbox: FC<checkboxProps> = ({
  label,
  isChecked,
  isIndeterminate,
  onClick,
  id,
}) => {
  const checkboxRef = useRef();

  useEffect(() => {
    checkboxRef.current.indeterminate = isIndeterminate;
  }, [isIndeterminate]);

  return (
    <div className="ve-checkbox">
      <input ref={checkboxRef} type="checkbox" id={id} onChange={onClick} checked={isChecked} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
