import React from 'react';
import { SearchWorksheetMenu } from '../../../search-worksheet-menu';
import { iconEnum } from 'components/icons';

export const WorksheetMenuRenderer = (props, loadMyWorkbooksFun, closePanelFun) => {
  const worksheetId = props.data.worksheetId;
  const workbookId = props.data.workbookId;
  const workbook = {
    marketSegmentId: props.data.marketSegmentID,
    userId: props.data.userId,
    name: props.data.name,
    speedAndConsumptionMode: props.data.speedAndConsumptionMode,
  };

  return (
    <SearchWorksheetMenu
      icon={iconEnum.MoreVert}
      workbook={workbook}
      workbookId={workbookId}
      worksheetId={worksheetId}
      loadMyWorkbooksFun={loadMyWorkbooksFun}
      closePanelFun={closePanelFun}
      data={props.data}
      sourceWorksheetBelongsToColleague={props.sourceWorksheetBelongsToColleague}
    />
  );
};
