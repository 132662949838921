import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import { UPDATE_WORKSHEET_SHOW_UNUSED_BUNKER_PRICE } from 'constants/action-types/worksheet/bunkers';

const initialState = false;

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return action.payload.showUnusedBunkers;
    case UPDATE_WORKSHEET_SHOW_UNUSED_BUNKER_PRICE: {
      return action.payload.showUnusedBunkers;
    }
    default:
      return state;
  }
};
