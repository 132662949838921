import axios from 'axios';
import * as async from 'async';
import debounce from 'lodash/debounce';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import calculatableActions from './actions-to-trigger-calculate';
import {
  SET_CALCULATION_RESULTS,
  SET_CALCULATIONS_STATUS,
} from 'constants/action-types/calculation-summary';
import { debounceDefaultsSpreadable } from 'constants/defaults/debounce';
import { CalculationStatus } from 'constants/enums/calculation-status';
import type { WorksheetSnapshotSyncAfterware } from 'middleware/worksheet-afterware/types';
import { tryBuildRequestAndGetRateLimitedCalculationResult } from 'api/clients/calculation';
import { mapToCharterTypes } from '../../../constants/enums/comparison-view-mode';
import { SWITCH_COMPARISON_VIEW_MODE } from 'constants/action-types/worksheet-comparison';
import { ViewModesItems } from 'constants/enums/comparison-view-mode';
import VError from 'verror';

/**
 * Cancellation tokens dictionary
 * keys are worksheet ids
 * values are cancellation tokens
 */
const cancelTokenSources = {};

const autoCalculateEstimateAfterware: WorksheetSnapshotSyncAfterware = {
  isSyncRequired(newState: IAppState, action): boolean {
    return calculatableActions.includes(action.type);
  },
  getWorksheetIdForRequiredSync(newState, action) {
    return action.worksheetId;
  },
  sync: (newWorksheetState: IWorksheetViewModel, newGlobalState) => async (dispatch) => {
    await dispatch(calculateEstimate(newWorksheetState.id));
  },
};

export const calculateEstimate = (worksheetId) => async (dispatch, getState) => {
  const state: IAppState = getState();
  const worksheet = state.worksheetsById[worksheetId];
  if (!worksheet) {
    return;
  }

  if (worksheetId === state.activeWorksheetId) {
    await calculateEstimateOfActiveWorksheetDebounced(state, dispatch);
  } else {
    await calculateEstimateImmediately(worksheet, state, dispatch);
  }
};

/**
 * This function will only work on 'activeWorksheetId' (thus is devoid of the `worksheet` parameter), because it has a `debounce` behavior and debounce will discard old calls irrespective of the input paramenters. (see #ArgumentlessFunctionToSafeGuardDebounce)
 */
const calculateEstimateOfActiveWorksheetDebounced = debounce(
  (state, dispatch) =>
    calculateEstimateImmediately(state.worksheetsById[state.activeWorksheetId], state, dispatch),
  ...debounceDefaultsSpreadable
);

const calculateEstimateImmediately = async (worksheet, globalState, dispatch) => {
  const worksheetId = worksheet.id;
  let currentViewMode = undefined;
  if (!globalState.worksheetComparison.viewMode) {
    if (worksheet.vessels[0].grossTimeCharter && !worksheet.cargoes[0].cargoRate.grossVoyageRate) {
      currentViewMode = ViewModesItems[1];
    } else {
      currentViewMode = ViewModesItems[0];
    }
    dispatch({
      type: SWITCH_COMPARISON_VIEW_MODE,
      payload: currentViewMode,
    });
  } else {
    currentViewMode = globalState.worksheetComparison.viewMode;
  }
  dispatch({
    type: SET_CALCULATIONS_STATUS,
    payload: CalculationStatus.LOADING,
    worksheetId: worksheetId,
  });

  if (cancelTokenSources[worksheetId]) {
    cancelTokenSources[worksheetId].cancel('Cancelled!');
  }

  const { token: cancelToken } = (cancelTokenSources[worksheetId] = axios.CancelToken.source());
  try {
    const workbook = globalState.workbooksById[worksheet.workbookId];
    const carbonFactors = globalState.carbonFactors.map((carbonFactor) => ({
      fuelGrade: carbonFactor.fuelGrade,
      factor: carbonFactor.factor,
    }));

    // #TODOConsiderSmartVesselChangeDetection, consider means of figuring out changes to specific vessels and executing calculations for those respectively
    const calculationResults = await async.map(
      worksheet.vessels,
      async.asyncify(async (vessel) => {
        const result = await tryBuildRequestAndGetRateLimitedCalculationResult(
          workbook.speedAndConsumptionsMode,
          mapToCharterTypes(currentViewMode.key),
          worksheet,
          globalState.referenceData.waypoints,
          vessel,
          worksheet.voyage.shouldAutoCalculateIntake,
          cancelToken,
          carbonFactors,
          undefined,
          undefined,
          workbook.isEditable
        );
        return {
          // This `id` is the id of the calculation. Just vessel's `entryId` won't be sufficient once we make more than one calculation per vessel (trying out different speed&cons etc., AKA permutations), but for now they are all that's needed.
          id: vessel.entryId,
          // Regardless of the choice of calculation Id above, we need a reference to get the vessel data
          vesselEntryId: vessel.entryId,
          ...result,
        };
      })
    );

    dispatch({
      type: SET_CALCULATION_RESULTS,
      payload: {
        calculationResults: calculationResults,
      },
      worksheetId: worksheet.id,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      trackEvent(
        'Middleware/calculate',
        'Middleware Calculate Cancelled',
        {
          worksheetId: worksheet.id,
        },
        {},
        eventDestination.ANALYSIS
      );
      return;
    }

    throw new VError(
      {
        cause: error,
        worksheetId: worksheetId,
      },
      'Calculate estimate failed due to unknown error. See `cause`.'
    );
  } finally {
    if (cancelTokenSources[worksheetId] && cancelTokenSources[worksheetId].token === cancelToken)
      delete cancelTokenSources[worksheetId];
  }
};

export default autoCalculateEstimateAfterware;
