import { REFERENCE_DATA_WAYPOINTS_LOADED } from 'constants/action-types/reference-data';
import { canals, piracyZones, secaZones, straits } from 'constants/data/waypoints';

export const loadWaypointsData = () => (dispatch) => {
  return new Promise((resolve) => {
    resolve({
      data: { canals, piracyZones, secaZones, straits },
    });
  }).then((waypoints) => {
    dispatch(waypointsLoaded(waypoints.data));
  });
};

function waypointsLoaded(payload) {
  return {
    type: REFERENCE_DATA_WAYPOINTS_LOADED,
    payload,
  };
}
