/** Allowed public internet domains:
   SECURITY CRITICAL: Only trusted authorities here.
   */
export const allowedPublicInternetDomains = [
  /*
  NOTE: When adding fields here do not add a '.' at the beginning, but:
    **SECURITY CRITICAL: Always use `ensureDomainStartsWithDot` when
      checking for subdomains.**
  */
  'sea.live' /* NOTE: Checking just the top level 'sea.live' essentially allows connecting from any app and any environment. This might seem pretty permissive, but note that every action is still subject to authentication and access control against the logged in user on that environment. Moreover note that in the '#hash' approach for the entry URL we wouldn't be able to restrict anything. The benefit of this 'assume trust' approach is that new projects are able to be developed and tested independently. If new reasons to restrict it appear, do consider limiting it only on production.
   */,
  /* `clarksons.co.uk` especially allows employees (usually developers) to develop internal products, or share a 'work in progress' version of an app they're working on the intranet for others to give feedback on. */
  'clarksons.co.uk',
];

/** Extra domains for development only:
   SECURITY CRITICAL: Only put domains here that can never appear to public internet users.
   They should be 'reserved' by the DNS standard [e.g. here](https://tools.ietf.org/id/draft-chapin-rfc2606bis-00.html)
  */
export const allowedReservedPrivateDomains = ['localhost', 'test', 'local', 'example'];
