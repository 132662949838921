export type WorksheetErrorType =
  | 'DOES_NOT_EXIST_IN_WORKBOOK'
  | 'DOES_NOT_EXIST'
  | 'NO_WORKSHEETS_IN_WORKBOOK'
  | 'REDIRECT_TO_FALLBACK';

export const WorksheetErrorTypes = {
  DOES_NOT_EXIST_IN_WORKBOOK: 'DOES_NOT_EXIST_IN_WORKBOOK',
  DOES_NOT_EXIST: 'DOES_NOT_EXIST',
  NO_WORKSHEETS_IN_WORKBOOK: 'NO_WORKSHEETS_IN_WORKBOOK',
  REDIRECT_TO_FALLBACK: 'REDIRECT_TO_FALLBACK',
};
