import {
  ADDITIONAL_INCOME_DESCRIPTION_UPDATED,
  ADDITIONAL_INCOME_INCOME_UPDATED,
  ADDITIONAL_INCOME_ADDED,
  ADDITIONAL_INCOME_REMOVED,
} from 'constants/action-types/worksheet/cargoes/additional-incomes';

export function additionalIncomeDescriptionChanged(worksheetId, index, description) {
  return {
    worksheetId,
    type: ADDITIONAL_INCOME_DESCRIPTION_UPDATED,
    payload: {
      description: description,
      index: index,
    },
  };
}

export function additionalIncomeIncomeChanged(worksheetId, index, income) {
  return {
    worksheetId,
    type: ADDITIONAL_INCOME_INCOME_UPDATED,
    payload: {
      income: income,
      index: index,
    },
  };
}

export function additionalIncomeAdded(worksheetId) {
  return {
    worksheetId,
    type: ADDITIONAL_INCOME_ADDED,
  };
}

export function additionalIncomeRemoved(index, worksheetId) {
  return {
    worksheetId,
    type: ADDITIONAL_INCOME_REMOVED,
    payload: index,
  };
}
