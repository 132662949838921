import classNames from 'classnames';
import CalcInput from 'components/calc-input';
import {
  speedMaxPrecision,
  speedMaxValue,
  speedMinValue,
} from 'modules/vessel/speed-and-consumptions/components/speed-input';
import {
  consumptionMaxPrecision,
  consumptionMaxValue,
  consumptionMinValue,
} from 'modules/vessel/speed-and-consumptions/components/moving-speed-and-cons';

const SpeedConsInputSection = ({
  title,
  speedValue,
  consValue,
  genValue,
  onInputChange,
  withGenerator,
}) => (
  <>
    <div className="worksheet-section__property">
      <label className="worksheet-section__property-label">&nbsp;</label>
      <div className={classNames('modal-label__container')}>
        <div className="text">{title}</div>
      </div>
    </div>
    {['Speed', 'Cons'].map((type) => (
      <div className="worksheet-section__property" key={type}>
        <label className="worksheet-section__property-label">{type}</label>
        <CalcInput
          systemSpecifiedValue={type === 'Speed' ? speedValue : consValue}
          onInputChange={(value) => onInputChange(type.toLowerCase(), value, true)}
          className="edit-input"
          maxDecimalDigits={type === 'Speed' ? speedMaxPrecision : consumptionMaxPrecision}
          minValue={type === 'Speed' ? speedMinValue : consumptionMinValue}
          maxValue={type === 'Speed' ? speedMaxValue : consumptionMaxValue}
          showEditButton
          unit={type === 'Speed' ? 'kn' : 'MT/d'}
          name={`${title} ${type}`}
          isMandatory={type === 'Speed'}
        />
      </div>
    ))}
    {withGenerator && (
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Gen Cons</label>
        <CalcInput
          systemSpecifiedValue={genValue}
          onInputChange={(value) => onInputChange('GenCons', value, false)}
          className="edit-input"
          maxDecimalDigits={consumptionMaxPrecision}
          minValue={consumptionMinValue}
          maxValue={consumptionMaxValue}
          showEditButton
          unit={'MT/d'}
          name={`Gen Cons`}
        />
      </div>
    )}
  </>
);

export default SpeedConsInputSection;
