export const SALT = { key: 1, label: 'Salt', value: 1.0 };
export const BRACKISH = { key: 2, label: 'Brackish', value: 1.011 };
export const FRESH = { key: 3, label: 'Fresh', value: 1.025 };

export const getSalinityTypeById = (typeId) => {
  switch (typeId) {
    case SALT.key:
      return SALT;
    case BRACKISH.key:
      return BRACKISH;
    case FRESH.key:
      return FRESH;
    default:
      return {};
  }
};

export const SalinityTypes = [SALT, BRACKISH, FRESH];
