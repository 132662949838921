import React from 'react';
import { TableHeader } from 'components/table';
import BunkerTableCell from '../bunker-ports-table-cell';
import userContext from 'user-context';
import { isDry } from 'constants/market-segments';

const BunkerPortsTableHeader = ({ isBunkerPricesPanelReadOnly, marketSegmentId }) => (
  <TableHeader className="bunker-ports-table__table-header">
    <BunkerTableCell className="bunker-ports-table--location-name">Port Location</BunkerTableCell>
    {
      /* #PreventUndesiredNoDivisionFallbackOnZoneCodes - the data source service doesn't allow to specify division (always takes it from user), and falls back to one that is undesired for external client companies. We rather chose to not show it then. */
      userContext.userInfo.isInHouseCompany && isDry(marketSegmentId) && (
        <BunkerTableCell className="bunker-ports-table--zone">Zone</BunkerTableCell>
      )
    }
    <BunkerTableCell className="bunker-ports-table--delivery">Delivered</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">380 CST</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">180 CST</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">VLSFO 0.5</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">MGO</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">MDO</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">LSMGO 0.1</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">ULSFO 0.1</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--price">LNG</BunkerTableCell>
    <BunkerTableCell className="bunker-ports-table--date-issued">Date issued</BunkerTableCell>
    {!isBunkerPricesPanelReadOnly && (
      <BunkerTableCell className="bunker-ports-table--import-header">
        Apply to Flat fee
      </BunkerTableCell>
    )}
  </TableHeader>
);

export default BunkerPortsTableHeader;
