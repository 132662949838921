import { combineReducers } from 'redux';
import AppMetadataReducer from './app-metadata';
import { userStateReducer, userPreferenceReducer } from './user-state/user-state';
import { activeWorkbookId } from './user-state/active-workbook-id';
import { activeWorksheetId } from './user-state/active-worksheet-id';
import { activeVesselEntryIdReducer } from './user-state/active-vessel-entry-id';
import WaypointsReducer from './reference-data/waypoints';
import BalticIndexVesselsReducer from './reference-data/baltic-index-vessels';
import BalticTankerIndexVesselsReducer from './reference-data/baltic-tanker-index-vessels';
import { calculationsByWorksheetIdReducer } from './calculations';
import { workbooksReducer } from './workbooks';
import BunkerPortsReducer from './bunker-ports';
import IntakeCalculatorReducer from './intake-calculator';
import CalculationSensitivityReducer from './sensitivity-table';
import { worksheetComparisonReducer } from './worksheet-comparison';
import { worksheetsReducer } from './worksheets';
import CarbonFactorReducer from './carbon';
import { comparisonTableSettingsReducer } from './worksheet-comparison/comparison-table-settings';
import { comparisonTableColumnsDefsReducer } from './worksheet-comparison/comparison-table-columns-defs';
const rootReducer = combineReducers({
  activeWorkbookId,
  activeWorksheetId,
  userState: userStateReducer,
  userPreference: userPreferenceReducer,
  activeVesselEntryId: activeVesselEntryIdReducer,
  appMetadata: AppMetadataReducer,
  worksheetsById: worksheetsReducer,
  calculationsByWorksheetId: calculationsByWorksheetIdReducer,
  calculationSensitivity: CalculationSensitivityReducer,
  referenceData: combineReducers({
    waypoints: WaypointsReducer,
    balticIndexVessels: BalticIndexVesselsReducer,
    balticTankerIndexVessels: BalticTankerIndexVesselsReducer,
  }),
  intakeCalculator: IntakeCalculatorReducer,
  bunkerPorts: BunkerPortsReducer,
  workbooksById: workbooksReducer,
  worksheetComparison: worksheetComparisonReducer,
  carbonFactors: CarbonFactorReducer,
  comparisonTableSettings: comparisonTableSettingsReducer,
  comparisonTableColumnsDefs: comparisonTableColumnsDefsReducer,
});

export default rootReducer;
