import isNil from 'lodash/isNil';

export function ensuredNotNil(valueToCheck, options?: { errorMessagePostamble: string }) {
  if (isNil(valueToCheck))
    throw Error(
      `Expected a non-nil value but found nil.${
        options && options.errorMessagePostamble ? `:\n\t${options.errorMessagePostamble}` : ''
      }`
    );

  return valueToCheck;
}
