/**
 * These location type ids are defined in a system known as SeaNet.
 * These are the only location types that are supported for vessels' open location and locations in the voyage.
 */
export const LocationTypes = Object.freeze({
  Port: '46607D43-3649-4CD9-8B1B-D24112DDBE99',
  NonCommercialPort: 'ACF99237-1B57-484C-B33A-26D9F8A6537A',
  NonPortVoyageLocation: 'A7D0853B-2B8D-4F6B-843E-FAAED569C559',
  Waypoint: 'DF4C9AFA-478E-46B9-92CC-5693FE65C739',
});

type LocationTypeId =
  | '46607D43-3649-4CD9-8B1B-D24112DDBE99' //Port
  | 'ACF99237-1B57-484C-B33A-26D9F8A6537A' //NonCommericalPort
  | 'A7D0853B-2B8D-4F6B-843E-FAAED569C559' //NonPortVoyageLocation
  | 'DF4C9AFA-478E-46B9-92CC-5693FE65C739'; //Waypoint

export const isLocationOfType = (locationTypeId: string, targetLocationTypeId: LocationTypeId) => {
  if (typeof locationTypeId !== 'string') {
    return false;
  }

  return locationTypeId.toUpperCase() === targetLocationTypeId;
};

/**
 * @description Check if the location type id is supported in Sea/Calc.
 * @param {string} locationTypeId
 */
export const isLocationTypeSupported = (locationTypeId: string): boolean => {
  return Object.values(LocationTypes).some((supportedLocationTypeId) =>
    isLocationOfType(locationTypeId, supportedLocationTypeId)
  );
};
