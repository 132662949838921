import { format, toDate } from 'date-fns';
import isNil from 'lodash/isNil';

import { formatNumber, formatNumberWithAutoMantissa } from 'utilities/number';
import guid from 'utilities/guid';
import { formatMoney } from 'utilities/number/units-precisions/money';

const berthColumn = {
  key: 'berth',
  label: 'Berth',
  minWidth: 150,
  maxWidth: 300,
  tooltip: true,
};
const cargoColumn = {
  key: 'cargo',
  label: 'Cargo',
  minWidth: 135,
  maxWidth: 300,
  tooltip: true,
};
const costColumn = {
  key: 'cost',
  label: 'Costs ($)',
  minWidth: 80,
  maxWidth: 100,
  rightAlign: true,
  highlight: true,
};
const lastUpdatedColumn = {
  key: 'lastUpdated',
  label: 'Last Updated',
  minWidth: 110,
  maxWidth: 110,
};
const maxBeamColumn = {
  key: 'maxBeam',
  label: 'Max Beam (m)',
  minWidth: 110,
  maxWidth: 110,
  rightAlign: true,
};
const maxDwtColumn = {
  key: 'maxDwt',
  label: 'Max DWT',
  minWidth: 105,
  maxWidth: 105,
  rightAlign: true,
};
const maxDraftColumn = {
  key: 'maxDraft',
  label: 'Draft (m)',
  minWidth: 105,
  maxWidth: 105,
  rightAlign: true,
  highlight: true,
};
const maxLoaColumn = {
  key: 'maxLoa',
  label: 'Max LOA (m)',
  minWidth: 105,
  maxWidth: 105,
  rightAlign: true,
};
const operationColumn = {
  key: 'operation',
  label: 'Operation',
  minWidth: 120,
  maxWidth: 120,
};
const sourceColumn = {
  key: 'source',
  label: 'Source',
  minWidth: 110,
  maxWidth: 110,
  isForInHouseUserOnly: true,
};
const vesselDwtColumn = {
  key: 'vesselDwt',
  label: 'DWT',
  minWidth: 70,
  maxWidth: 70,
  rightAlign: true,
};

const PortCostColumns = [
  berthColumn,
  cargoColumn,
  vesselDwtColumn,
  costColumn,
  operationColumn,
  sourceColumn,
  lastUpdatedColumn,
];

const PortDraftColumns = [
  berthColumn,
  cargoColumn,
  maxDraftColumn,
  maxDwtColumn,
  maxLoaColumn,
  maxBeamColumn,
  sourceColumn,
  lastUpdatedColumn,
];

const NO_RESTRICTION = 'No restriction';

const formatOperation = (load: boolean, discharge: boolean): string => {
  const loadText = load ? 'Load' : '';
  const textSeparator = load && discharge ? '/' : '';
  const dischargeText = discharge ? 'Discharge' : '';

  return `${loadText}${textSeparator}${dischargeText}`;
};

const formatCost = (input: number): string => (isNil(input) ? '' : formatMoney(input));

const formatDateDDMMMYYYY = (date: string): string =>
  isNil(date) ? '' : format(toDate(date), 'dd MMM yyyy');

const formatRestriction = (restriction) =>
  restriction && restriction.hasData
    ? restriction.isRestricted
      ? formatNumberWithAutoMantissa(restriction.value)
      : NO_RESTRICTION
    : '';

const formatCargoes = (cargoes) => (cargoes ? cargoes.map((cargo) => cargo.name).join('/') : '');
const lastUpdatedFilter = new Date('2022-01-01');
const mapPortCosts = (portData) => {
  const portCosts = [];

  for (let port of portData) {
    const { berth, arcCargoes, isForLoading, isForDischarging, sourceName, sourceUpdatedDate } =
      port;

    for (let portDataCost of port.portCosts) {
      const { dwt, cost } = portDataCost;

      if (
        arcCargoes == null ||
        arcCargoes.length === 0 ||
        (!isForLoading && !isForDischarging) ||
        isNil(dwt) ||
        dwt === 0 ||
        new Date(sourceUpdatedDate) < lastUpdatedFilter
      ) {
        continue;
      }

      let portCost = {
        id: guid(),
        [berthColumn.key]: berth,
        [cargoColumn.key]: formatCargoes(arcCargoes),
        [costColumn.key]: formatCost(cost),
        [vesselDwtColumn.key]: formatNumber(dwt, 0),
        [lastUpdatedColumn.key]: formatDateDDMMMYYYY(sourceUpdatedDate),
        [operationColumn.key]: formatOperation(isForLoading, isForDischarging),
        [sourceColumn.key]: sourceName,
      };

      portCosts.push(portCost);
    }
  }

  return portCosts;
};

const mapPortDrafts = (portData) => {
  const portDrafts = [];

  for (let port of portData) {
    const { portRestrictions, berth, arcCargoes, sourceName, sourceUpdatedDate } = port;

    if (
      isNil(portRestrictions) ||
      isNil(portRestrictions.draft) ||
      !portRestrictions.draft.hasData ||
      new Date(sourceUpdatedDate) < lastUpdatedFilter
    ) {
      continue;
    }

    let portDraft = {
      id: guid(),
      [berthColumn.key]: berth,
      [cargoColumn.key]: formatCargoes(arcCargoes),
      [lastUpdatedColumn.key]: formatDateDDMMMYYYY(sourceUpdatedDate),
      [maxBeamColumn.key]: formatRestriction(portRestrictions.beam),
      [maxDraftColumn.key]: formatRestriction(portRestrictions.draft),
      [maxDwtColumn.key]: formatRestriction(portRestrictions.dwt),
      [maxLoaColumn.key]: formatRestriction(portRestrictions.loa),
      [sourceColumn.key]: sourceName,
    };

    portDrafts.push(portDraft);
  }

  return portDrafts;
};

export { PortCostColumns, PortDraftColumns, mapPortCosts, mapPortDrafts, NO_RESTRICTION };
