import React from 'react';
import { ArrowDropDownIcon, ArrowDropRightIcon } from 'components/icons';
import classNames from 'classnames';

import './styles.scss';

const ExpandCollapseButton = React.forwardRef((props, ref) => {
  const { expanded, onClick, children, className, ...restProps } = props;
  return (
    <button
      data-testid={props['data-testid']}
      ref={ref}
      className={classNames('expand-collapse-button', className)}
      onClick={onClick}
      {...restProps}
    >
      {expanded ? (
        <ArrowDropDownIcon className="expand-collapse-button__icon" />
      ) : (
        <ArrowDropRightIcon className="expand-collapse-button__icon" />
      )}
      {children}
    </button>
  );
});

export default ExpandCollapseButton;
