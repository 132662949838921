import React, { Fragment } from 'react';
import LabeledInput from '../labeled-input';
import Label from 'components/label';
import VesselAutoComplete from 'components/vessel-autocomplete';
import NumericInput from 'components/numeric-input';
import DropDown from 'components/dropdown';
import DraftUnitTypes, {
  DRAFT_NUMBER_OF_DECIMAL_PLACES,
  convertDraft,
  METERS,
} from 'constants/enums/draft-units';
import ImmersionUnitTypes, {
  IMMERSION_NUMBER_OF_DECIMAL_PLACES,
  TPC,
  convertImmersion,
} from 'constants/enums/immersion-units';
import GrainUnitTypes, {
  CUBIC_METERS,
  GRAIN_NUMBER_OF_DECIMAL_PLACES,
  convertGrain,
} from 'constants/enums/grain-units';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getActiveWorkbookInvariantProps } from 'common-selectors/get-active-workbook-invariant-props';
import { isTanker, isDry } from 'constants/market-segments';
import classNames from 'classnames';

const VesselInputs = ({ vessel, actions, balticIndexVessels, marketSegmentId }) => {
  const onDraftUnitChangedHandler = (draftUnit) => {
    const draft = convertDraft(vessel.draft, vessel.draftUnit, draftUnit);
    const payload = { draft, draftUnit };
    actions.vesselDraftUnitChanged(payload);
  };

  const onImmersionUnitChangedHandler = (immersionUnit) => {
    const immersion = convertImmersion(vessel.tpcmi, vessel.immersionUnit, immersionUnit);
    const payload = { immersion, immersionUnit };
    actions.immersionUnitChanged(payload);
  };

  const onGrainUnitChangedHandler = (grainUnit) => {
    const grain = convertGrain(vessel.grain, vessel.grainUnit, grainUnit);
    const payload = { grain, grainUnit };
    actions.grainUnitChanged(payload);
  };

  return (
    <Fragment>
      <div className="intake-calc__row intake-calc__row-first">
        <LabeledInput
          className={classNames('intake-calc__vessel', {
            'tankers-intake': isTanker(marketSegmentId),
          })}
        >
          <Label className="labeled-input--label">Vessel</Label>
          <VesselAutoComplete
            marketSegmentId={marketSegmentId}
            vessel={vessel.vesselIdName}
            onChange={(newVessel) =>
              actions.vesselChanged({
                vessel: newVessel,
                oldVessel: vessel.vesselIdName,
                marketSegmentId: marketSegmentId,
                selectedUnits: {
                  draftUnit: vessel.draftUnit,
                  grainUnit: vessel.grainUnit,
                  immersionUnit: vessel.immersionUnit,
                },
              })
            }
            balticIndexVessels={balticIndexVessels}
            diagnosticId="IntakeCalculator/Vessel"
          />
        </LabeledInput>
        <LabeledInput className="intake-calc__deadweight">
          <Label className="labeled-input--label">DWT</Label>
          <NumericInput
            value={vessel.deadweight}
            onInputChange={actions.deadweightChanged}
            maxDecimalDigits="3"
            minValue="0"
            maxValue="999999999.999"
            mandatory
            diagnosticId="IntakeCalculator/VesselDeadweight"
          />
        </LabeledInput>
        <LabeledInput className="intake-calc__vessel-draft">
          <span className="labeled-input--label">
            Draft
            <DropDown
              id="draftUnit"
              items={DraftUnitTypes}
              selectedItem={vessel.draftUnit}
              onChange={onDraftUnitChangedHandler}
              asLabel
              diagnosticId="IntakeCalculator/VesselDraftUnit"
            />
          </span>
          <NumericInput
            value={vessel.draft}
            onInputChange={actions.vesselDraftChanged}
            maxDecimalDigits={DRAFT_NUMBER_OF_DECIMAL_PLACES}
            minValue="0"
            maxValue={convertDraft(99.99, METERS, vessel.draftUnit)}
            mandatory
            diagnosticId="IntakeCalculator/VesselDraft"
          />
        </LabeledInput>
      </div>
      <div className="intake-calc__row">
        <LabeledInput
          className={classNames('intake-calc__grain', {
            'tankers-intake__small': isTanker(marketSegmentId),
          })}
        >
          <span className="labeled-input--label">
            {isDry(marketSegmentId) ? 'Grain' : 'Cubic Capacity'}
            <DropDown
              id="grainUnit"
              items={GrainUnitTypes}
              selectedItem={vessel.grainUnit}
              onChange={onGrainUnitChangedHandler}
              asLabel
              diagnosticId="IntakeCalculator/VesselGrainUnit"
            />
          </span>
          <NumericInput
            value={vessel.grain}
            onInputChange={actions.grainChanged}
            maxDecimalDigits={GRAIN_NUMBER_OF_DECIMAL_PLACES}
            minValue="0"
            maxValue={convertGrain(999999999.999, CUBIC_METERS, vessel.grainUnit)}
            mandatory
            diagnosticId="IntakeCalculator/VesselGrain"
          />
        </LabeledInput>
        <LabeledInput className="intake-calc__tpcmi">
          <span className="labeled-input--label labeled-input--label--immersion">
            <DropDown
              id="immersionUnit"
              items={ImmersionUnitTypes}
              selectedItem={vessel.immersionUnit}
              onChange={onImmersionUnitChangedHandler}
              asLabel
              diagnosticId="IntakeCalculator/VesselImmersionUnit"
            />
          </span>
          <NumericInput
            value={vessel.tpcmi}
            onInputChange={actions.tpcmiChanged}
            maxDecimalDigits={IMMERSION_NUMBER_OF_DECIMAL_PLACES}
            minValue="0"
            maxValue={convertImmersion(9999.99, TPC, vessel.immersionUnit)}
            mandatory
            diagnosticId="IntakeCalculator/VesselImmersion"
          />
        </LabeledInput>
      </div>
    </Fragment>
  );
};

export default connect(
  /*data props:*/ createStructuredSelector({
    marketSegmentId: (state) => getActiveWorkbookInvariantProps(state).marketSegmentId,
  }),
  /*action props:*/ null
)(VesselInputs);
