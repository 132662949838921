import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selector } from './selector';
import './styles.scss';

export class CalculationSummaryEstimate extends Component {
  render() {
    return (
      <div className="summary-section summary-section--border">
        <h2>Voyage Estimate ($)</h2>
        <div className="summary-estimate-data">
          <div className="summary-estimate-data__header">
            <div className="summary-estimate-data__header-cell" />
            <div className="summary-estimate-data__header-cell">From Time Charter Rate</div>
            <div className="summary-estimate-data__header-cell">From Voyage Rate</div>
          </div>
          <div className="summary-estimate-data__row">
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-label">
              NTC
            </div>
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-value">
              {this.props.timeCharter.netTimeCharter}
            </div>
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-value">
              {this.props.voyageRate.showVoyageRate &&
                this.props.voyageRate.netTimeCharterCalculated}
            </div>
          </div>
          <div className="summary-estimate-data__row">
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-label">
              GTC
            </div>
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-value summary-estimate-data__row-cell-value--active">
              {this.props.timeCharter.grossTimeCharter}
            </div>
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-value">
              {this.props.voyageRate.showVoyageRate &&
                this.props.voyageRate.grossTimeCharterCalculated}
            </div>
          </div>
          <div className="summary-estimate-data__row">
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-label">
              Gross VR
            </div>
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-value">
              {this.props.timeCharter.showTimeCharter &&
                this.props.timeCharter.grossVoyageRateCalculated}
            </div>
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-value summary-estimate-data__row-cell-value--active">
              {this.props.voyageRate.grossVoyageRate}
            </div>
          </div>
        </div>

        <div className="summary-estimate-data summary-estimate-footer-data">
          <div className="summary-estimate-data__row summary-estimate-data__row-border">
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-label">
              P&amp;L Estimate (Per Day)
            </div>
            <div className="summary-estimate-data__row-cell-sm summary-estimate-data__row-cell-value">
              {this.props.voyageRate.voyageProfitOrLossEstimate}
            </div>
          </div>
        </div>

        <div className="summary-estimate-data">
          <div className="summary-estimate-data__row">
            <div className="summary-estimate-data__row-cell summary-estimate-data__row-cell-label">
              P&amp;L Estimate (Total Voyage)
            </div>
            <div className="summary-estimate-data__row-cell-sm summary-estimate-data__row-cell-value">
              {this.props.voyageRate.totalDaysVoyageProfitOrLossEstimate}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

export default connect(mapStateToProps)(CalculationSummaryEstimate);
