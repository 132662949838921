import { SET_COLUMNS_DEFS } from 'constants/action-types/worksheet-comparison';

const initialState: IWorksheetComparisonColumnsDefs = {
  columnsDefs: [],
};

export const comparisonTableColumnsDefsReducer = (
  state: IWorksheetComparisonColumnsDefs = initialState,
  action
) => {
  switch (action.type) {
    case SET_COLUMNS_DEFS:
      const columnsDefs = action.payload
        ?.slice(1)
        .flatMap((header, mainIndex: index, columnDefs) => [
          {
            name: header.headerName,
            field: '',
          },
          header.children.map((subHeader, index, children) => {
            return {
              name: subHeader.headerName,
              field: subHeader.field,
              hasDivider: index === children.length - 1 && mainIndex !== columnDefs.length - 1,
            };
          }),
        ])
        .flatMap((option) => option);

      return {
        ...state,
        columnsDefs: columnsDefs,
      };
    default:
      return state;
  }
};
