import React, { PureComponent } from 'react';
import classNames from 'classnames';

import './styles.scss';

export class Title extends PureComponent {
  render() {
    return <div className="title">{this.props.text}</div>;
  }
}

export class Header extends PureComponent {
  render() {
    const classes = classNames('header', this.props.className);
    return <div className={classes}>{this.props.text}</div>;
  }
}

export class Subheader extends PureComponent {
  render() {
    const classes = classNames('sub-header', this.props.className);
    return <div className={classes}>{this.props.text}</div>;
  }
}
