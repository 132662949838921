import { charterTypes } from '../charter-types';

export interface IViewMode {
  key: number;
  label: string;
}
export const ViewModesEnum = {
  VOYAGE_RATE_TO_TIME_CHARTER: 0x1,
  TIME_CHARTER_TO_VOYAGE_RATE: 0x2,
};

export const ViewModesItems = [
  {
    key: ViewModesEnum.VOYAGE_RATE_TO_TIME_CHARTER,
    label: 'Voyage to Time Charter',
  },
  {
    key: ViewModesEnum.TIME_CHARTER_TO_VOYAGE_RATE,
    label: 'Time Charter to Voyage',
  },
];

/**
 * Returns whether the provided `value` has the provided `mode` activated.
 * The function uses bitwise operations to perform the check.
 * @see {ViwewModesItem}
 * @param {number} value the value to be tested
 * @param {number} mode the mode to check if it is active or not
 */
export const isViewModeActive = (value: number, mode: number) => {
  return (value & mode) > 0;
};

export const getViewMode = (value: number) => ViewModesItems.find((item) => item.key === value);

export const mapToCharterTypes = (key): charterTypes[] => {
  switch (key) {
    case ViewModesEnum.VOYAGE_RATE_TO_TIME_CHARTER:
      return [charterTypes.voyage];
    case ViewModesEnum.TIME_CHARTER_TO_VOYAGE_RATE:
      return [charterTypes.time];
    default:
      throw new Error(`Cannot map ${key} to a charterType`);
  }
};
