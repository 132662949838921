import { veApi } from 'api';
import numbro from 'numbro';
import { REFERENCE_DATA_BALTIC_INDEX_VESSELS_LOADED } from 'constants/action-types/reference-data';
import { AxiosError } from 'axios';

export const loadBalticIndexVessels = () => (dispatch) => {
  return veApi
    .get('/vessel/balticindex')
    .then((response) => {
      const sortedVessels = sortVessels(response);
      const vessels = sortedVessels.map(parseVessel);

      dispatch(balticIndexVesselsLoaded(vessels));
    })
    .catch((error: AxiosError) => {
      dispatch(balticIndexVesselsLoaded([]));
    });
};

const sortVessels = (response) => {
  return response.data.vessels.sort((x, y) => {
    return x.deadweight - y.deadweight;
  });
};

const parseVessel = (vessel) => {
  return {
    vesselId: vessel.vesselId,
    vesselName: vessel.displayName,
    deadweight: numbro(vessel.deadweight).format({
      thousandSeparated: true,
    }),
    isBalticVessel: true,
  };
};

function balticIndexVesselsLoaded(payload) {
  return {
    type: REFERENCE_DATA_BALTIC_INDEX_VESSELS_LOADED,
    payload,
  };
}
