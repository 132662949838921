export const VESSEL_CHANGED: string = 'VESSEL_CHANGED';
export const VESSEL_OPEN_POSITION_CHANGED: string = 'VESSEL_OPEN_POSITION_CHANGED';
export const VESSEL_OPEN_POSITION_IS_IN_SECA_CHANGED: string =
  'VESSEL_OPEN_POSITION_IS_IN_SECA_CHANGED';
export const VESSEL_OPEN_POSITION_IS_IN_EEA_CHANGED: string =
  'VESSEL_OPEN_POSITION_IS_IN_EEA_CHANGED';
export const VESSEL_NEXT_PORT_OF_CALL_IS_IN_EEA_CHANGED: string =
  'VESSEL_NEXT_PORT_OF_CALL_IS_IN_EEA_CHANGED';
export const VESSEL_OPEN_DATE_CHANGED: string = 'VESSEL_OPEN_DATE_CHANGED';
export const VESSEL_DEADWEIGHT_CHANGED = 'VESSEL_DEADWEIGHT_CHANGED';
export const VESSEL_DRAFT_CHANGED: string = 'VESSEL_DRAFT_CHANGED';
export const VESSEL_DRAFT_UNIT_CHANGED: string = 'VESSEL_DRAFT_UNIT_CHANGED';
export const VESSEL_PARCEL_VOYAGE_TOGGLE_CHANGED: string = 'VESSEL_PARCEL_VOYAGE_TOGGLE_CHANGED';
export const VESSEL_TPCMI_CHANGED: string = 'VESSEL_TPCMI_CHANGED';
export const VESSEL_IMMERSION_UNIT_CHANGED: string = 'VESSEL_IMMERSION_UNIT_CHANGED';
export const VESSEL_GRAIN_CHANGED: string = 'VESSEL_GRAIN_CHANGED';
export const VESSEL_GRAIN_UNIT_CHANGED: string = 'VESSEL_GRAIN_UNIT_CHANGED';
export const VESSEL_CONSTANTS_CHANGED: string = 'VESSEL_CONSTANTS_CHANGED';
export const VESSEL_BUILD_DATE_CHANGED: string = 'VESSEL_BUILD_DATE_CHANGED';
export const VESSEL_SHIPYARD_CHANGED: string = 'VESSEL_SHIPYARD_CHANGED';
export const VESSEL_BUILD_COUNTRY_CHANGED: string = 'VESSEL_BUILD_COUNTRY_CHANGED';
export const VESSEL_APPLY_NEW_DETAILS: string = 'VESSEL_APPLY_NEW_DETAILS';
export const VESSEL_APPLY_NEW_OPEN_POSITION: string = 'VESSEL_APPLY_NEW_OPEN_POSITION';
export const VESSEL_SCRUBBER_CHANGED: string = 'VESSEL_SCRUBBER_TYPE_CHANGED';
export const VESSELS_ADD = 'VESSELS_ADD';
export const VESSELS_REMOVE = 'VESSELS_REMOVE';
export const VESSELS_MOVED = 'VESSELS_MOVED';
