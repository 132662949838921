import { compare as createNaturalSortCompareFn } from 'natural-orderby';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';

const naturalSortCompare = createNaturalSortCompareFn({ order: 'asc' });

/**
 * Compare function that defines the natural sort order
 * Includes guards to prevent comparison between non-string values
 * Null values are always the smallest
 *
 * Based on Array.sort comparison function
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * Defaults to ascending order sorting
 * Mind that undefined values will always end up at the end of the array, regardless ordering logic
 * Equally, undefined values will never be passed to the compare function during Array.sort
 *
 * @param {a} string or null value
 * @param {b} string or null value
 */
export const compareStringsUsingNaturalSortWithNullAsSmallest = (
  a: string | null,
  b: string | null
) => {
  if (!isString(a) && !isNull(a))
    throw new Error(`Comparing '${a}' and '${b}': '${a}' is not a string`);
  if (!isString(b) && !isNull(b))
    throw new Error(`Comparing '${a}' and '${b}': '${b}' is not a string`);

  if (isNull(a) && isNull(b)) return 0; // both values are null
  if (isNull(a)) return -1; // a is less than b
  if (isNull(b)) return 1; // b is less than a

  return naturalSortCompare(a, b);
};
