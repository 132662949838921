import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';
import reducer from './reducers';
import autoSaveAfterware from 'middleware/worksheet-afterware/auto-save';
import autoCalculateEstimateAfterware from 'middleware/worksheet-afterware/calculate-estimate';
import autoCalculateSensitivityAfterware from 'middleware/worksheet-afterware/calculate-sensitivity';
import noAwait from 'utilities/functions/no-await';
import { prepareForExplicitlyOrchestratedExecution } from 'middleware/worksheet-afterware/execution-by-explicit-orchestration';

const composeEnhancers = composeWithDevTools({
  /*
   `trace` is useful to see the stack traces of actions in the Redux dev tools - [feature overview](https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Features/Trace.md).
   TODO see if `trace` can be enabled permanently and doesn't slow down production -  may find help in [this documentation](https://github.com/zalmoxisus/redux-devtools-extension#14-using-in-production). For now, just leaving it here commented out as a tip.

  trace: true,
  traceLimit: 25,
  */
});

const createAppStore = () => internalCreateAppStore({});

/**
 * Internal use only. Expose only overloads with restricted parameters and concrete purpose.
 */
const internalCreateAppStore = ({
  initialState,
  middlewares,
}: {
  /** Specify only if overriding. Defaults to `undefined` */
  initialState?: IAppState | undefined,
  /** Specify only if overriding. Defaults to full app middleware stack. */
  middlewares?: Middleware[],
}) =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, ...(middlewares || [worksheetSyncActionsMiddleware])))
  );

const worksheetSyncActionsMiddleware = (store) => (next) => async (action) => {
  let lowerMiddlewaresResult = next(action);

  const actionsToOrchestrate = prepareForExplicitlyOrchestratedExecution({
    afterwaresByMethodName: {
      saveWorksheet: autoSaveAfterware,
      calculateEstimate: autoCalculateEstimateAfterware,
      calculateSensitivity: autoCalculateSensitivityAfterware,
    },
    context: {
      action: action,
      newState: store.getState(),
      dispatch: store.dispatch,
    },
  });

  await runOrScheduleForEndIfBatching(
    /* Note on safety of deferring this function: The function is #ActionEnsuredSafeForDeferral through implementation of each of the the actions executed within. See #AfterwaresPreventionOfDataLoss_ThroughOldWorksheetData_Implementation #AfterwaresPreventionOfDataLoss_ThroughWrongActiveWorksheetOverwrite_Implementation
    /* deferralSafeAction: */ async () => {
      // Orchestration of the afterwares happens here. This is where we decide the order and parallelism/sequentiality of the afterwares and whether callers of the dispatch should wait for any of the below promises (any `await` here will also make `await dispatch` wait for it to finish).

      // Await on the save (which will make callers of `dispatch` wait for it to succeed). This one is critical - we don't want the caller of the dispatch to proceed so that it doesn't create new saves which get into race conditions with the previous.
      const saveWorksheetPromise = actionsToOrchestrate.saveWorksheet();
      await saveWorksheetPromise;

      // Run all afterwares in parallel (by not `await`ing before calling a next one) as the worksheet has been saved to the database now
      const estimateCalculationPromise = actionsToOrchestrate.calculateEstimate();
      const sensitivityCalculationPromise = actionsToOrchestrate.calculateSensitivity();

      noAwait(estimateCalculationPromise);
      noAwait(sensitivityCalculationPromise);
    },
    /* actionId: */ 'invokeSnapshotSynchronizationAfterwares' /* use the same id to keep just one function - the actions perform a sync to the state current at the moment of the call (they have #AfterwaresPreventionOfDataLoss_ThroughOldWorksheetData), so each only needs to be invoked once. */
  );

  // Return the lower middleware's result (if any), since the 'synchronization afterwares' above don't actually produce any useful return value under their promises.
  return lowerMiddlewaresResult;
};

export async function runBatchSyncingOnlyAtEnd<TResult: ?any>(
  wrappedAction: () => Promise<TResult> | TResult
): Promise<TResult> {
  if (!batchContext)
    batchContext = {
      batchReentryCount: 0,
      actionsScheduledForBatchEnd: new Map(),
    };
  else ++batchContext.batchReentryCount;

  const result = await wrappedAction();

  if (--batchContext.batchReentryCount > 0) return;

  // End of the batch reached. Call all the deferred actions

  const localBatchContext = batchContext;
  // Clear the context, which also signals that the actions we are about to call should run immediately
  batchContext = null;

  for (const actionScheduledForBatchEnd of localBatchContext.actionsScheduledForBatchEnd.values()) {
    await actionScheduledForBatchEnd();
  }

  return result;
}

type ActionId = mixed;
type DeferrableAction = () => Promise<mixed>;
let batchContext: ?{
  batchReentryCount: number,
  actionsScheduledForBatchEnd?: Map<ActionId, DeferrableAction>,
} = null;

/**
 * @param {*} deferralSafeAction - The function to execute or schedule. It needs to be 'safe for deferral' (#ActionEnsuredSafeForDeferral) for #AfterwaresPreventionOfDataLoss_ThroughOldWorksheetData, that is, it should expect that the state may change since it was scheduled, so it should capture only the ids of the entities it needs to operate on, but not their state.
 * @param {*} scheduleItemKey - Allows ensuring only one action of a given type is registered: use the same value to overwrite an existent value, or a unique value to add a new one.
 */
async function runOrScheduleForEndIfBatching(
  deferralSafeAction: DeferrableAction,
  scheduleItemKey: ActionId
) {
  if (batchContext) {
    batchContext.actionsScheduledForBatchEnd.set(scheduleItemKey, deferralSafeAction);
  } else {
    await deferralSafeAction();
  }
}

const store = createAppStore();

/** For testing use only */
export function createAppStoreForTesting(
  { initialState, middlewares } = {
    initialState: undefined,
    middlewares: undefined,
  }
) {
  return internalCreateAppStore({
    middlewares: middlewares,
    initialState: initialState,
  });
}

/* Holding onto the store as a variable and exporting it essentially makes our store a singleton with only a single instance for whole page lifetime. Considerations:
 * ["This is an OK practice"](https://github.com/reduxjs/redux/issues/776#issuecomment-142249153)
 * regarding the concern for having many stores, they can be held under different named variables, but also one should consider if it is really needed - [Redux does not recommend having many unless you have valid reasons](https://redux.js.org/faq/store-setup/#can-or-should-i-create-multiple-stores-can-i-import-my-store-directly-and-use-it-in-components-myself).
 */
export default store;
