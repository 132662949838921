import rateLimit from 'p-limit';

/**
 * Delay execution by the given delay
 * @param delayInMilliseconds
 */
export async function delay(delayInMilliseconds: number = 0) {
  return new Promise((r) => setTimeout(r, delayInMilliseconds));
}

type AsyncCallable<Args, ReturnValue> = (...params: Args) => Promise<ReturnValue>;

export function rateLimited<Args, ReturnValue>(
  maxConcurrency,
  callable: AsyncCallable<Args, ReturnValue>
): AsyncCallable<Args, ReturnValue> {
  const runRateLimitedFn = rateLimit(maxConcurrency);
  return async function (...params) {
    return await runRateLimitedFn(() => callable(...params));
  };
}

type AsyncZeroArgsFn<ReturnValue> = () => Promise<ReturnValue>;

export type DeferringAsyncRunner = (AsyncZeroArgsFn<ReturnValue>) => Promise<ReturnValue>;

export function createRunnerInQueueOneAtATime(): DeferringAsyncRunner {
  return rateLimit(1);
}
