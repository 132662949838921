import React, { useState } from 'react';
import { connect } from 'react-redux';
import selector from './selector';
import ScrollablePanel from 'components/scrollable-panel';
import { WorksheetInfoContainer as WorksheetInfo } from 'modules/worksheet-info';
import Cargoes from 'modules/cargoes';
import Voyage from 'modules/voyage';
import Bunkers from 'modules/bunkers';
import AdditionalExpenses from 'modules/additional-expenses';
import { Panel, PanelSection, PanelSectionElement } from 'components/panel';
import Divider, { GoldenDivider } from 'components/divider';
import { isFeatureEnabled } from 'config/feature-control';

import './styles.scss';

import Rates from 'modules/rates';
import { Header } from 'components/headers';
import { VesselsGrid } from './components/vessels-grid';
import { isTanker } from '../../constants/market-segments';
import GeneralInformation from '../general-information';
import TimeCharter from '../time-charter';
import Commission from '../commission';
import { TankerRatesContainer } from 'modules/tanker-rates';

import AdditionalFreightIncomes from 'modules/additional-freight-incomes';

import BunkerPrices from '../bunker-prices';
import OtherCosts from '../other-costs';
import Eua from '../eua';

export const Worksheet = ({ worksheet, calculationResults, activeMarketSegmentId }) => {
  const [isTooltipActive, setTooltipActive] = useState(true);
  return (
    <>
      <PanelSection>
        <WorksheetInfo setTooltipActive={() => setTooltipActive(!isTooltipActive)} />
      </PanelSection>
      {isTanker(activeMarketSegmentId) ? (
        <div className="worksheet__panel-wrapper">
          <ScrollablePanel>
            <Panel>
              <PanelSection className="worksheet-section--general-TC">
                <PanelSectionElement>
                  <GeneralInformation />
                </PanelSectionElement>
                <PanelSectionElement>
                  <TimeCharter />
                </PanelSectionElement>
              </PanelSection>
              <Divider className="transparent-divider" />
              <PanelSection className="worksheet-section--route">
                <PanelSectionElement>
                  <Voyage />
                </PanelSectionElement>
              </PanelSection>
              <Divider className="transparent-divider" />
              <PanelSection className="worksheet-section--rates">
                <PanelSectionElement className="worksheet-section--tanker">
                  <TankerRatesContainer />
                </PanelSectionElement>
                <PanelSectionElement className="worksheet-section--commission">
                  <Commission />
                </PanelSectionElement>
              </PanelSection>
              <PanelSection className="worksheet-section--addition-freight">
                <PanelSectionElement className="worksheet-section--freight-income">
                  <AdditionalFreightIncomes />
                </PanelSectionElement>
                <PanelSectionElement className="worksheet-section--bunker-prices">
                  <BunkerPrices />
                </PanelSectionElement>
                <PanelSectionElement className="worksheet-section--other-costs">
                  <OtherCosts />
                </PanelSectionElement>
              </PanelSection>
              <Divider className="transparent-divider" />
              <PanelSection>
                <PanelSectionElement>
                  <Eua />
                </PanelSectionElement>
              </PanelSection>
            </Panel>
          </ScrollablePanel>
        </div>
      ) : (
        <div className="worksheet__panel-wrapper">
          <ScrollablePanel>
            <Panel>
              <PanelSection className="worksheet-section">
                <Header text="Vessel" />
                <VesselsGrid
                  worksheetId={worksheet.id}
                  vessels={worksheet.vessels}
                  calculationResults={calculationResults}
                />
              </PanelSection>
              <GoldenDivider />
              <PanelSection className="worksheet-section worksheet-section--cargo-rates">
                <Cargoes />
                <Rates />
              </PanelSection>
              <GoldenDivider />
              <PanelSection>
                <Voyage />
              </PanelSection>
              <GoldenDivider />
              <PanelSection>
                <Bunkers />
              </PanelSection>
              <GoldenDivider />
              <PanelSection>
                <AdditionalExpenses />
              </PanelSection>
              {isFeatureEnabled('isEuaSectionVisible') && (
                <>
                  <GoldenDivider />
                  <PanelSection>
                    <Eua />
                  </PanelSection>
                </>
              )}
            </Panel>
          </ScrollablePanel>
        </div>
      )}
    </>
  );
};

export default connect(selector, /* actionCreators: */ null)(Worksheet);
