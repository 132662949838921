/**
 *
 * @returns - a wrapper function that calls `storePromise` after every invocation of `asyncFunctionToWrap`, allowing `storePromise` so save the promise in some state.
 */
export default function storePromiseOnCall(
  asyncFunctionToWrap: () => Promise,
  storePromise: (Promise) => void
): Function<Promise> {
  return async function (...args) {
    const promise = asyncFunctionToWrap.apply(this, args);
    storePromise(promise);
    return await promise;
  };
}
