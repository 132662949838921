export type ScrubberType = {
  id: ScrubberTypeId,
  label: string,
};

export const ScrubberTypesEnum: { [symbol: string]: ScrubberType } = {
  BLANK: {
    id: null,
    label: '&nbsp;',
  },
  NOSCRUBBER: {
    id: 1,
    label: 'No Scrubber',
  },
  UNKNOWN_TYPE: {
    id: 2,
    label: 'Unknown Type',
  },
  OPENLOOP_TYPE: {
    id: 3,
    label: 'Open Loop',
  },
  CLOSEDLOOP_TYPE: {
    id: 4,
    label: 'Closed Loop',
  },
  HYBRID_TYPE: {
    id: 5,
    label: 'Hybrid',
  },
  MEMBRANE_TYPE: {
    id: 6,
    label: 'Membrane',
  },
};

export function isScrubberFitted(typeId?: ScrubberTypeId): boolean {
  return (
    typeId !== null &&
    typeId !== ScrubberTypesEnum.NOSCRUBBER.id &&
    typeId !== ScrubberTypesEnum.BLANK.id
  );
}

export function isOpenLoopOrUnknownScrubberFitted(typeId?: ScrubberTypeId): boolean {
  return (
    typeId === ScrubberTypesEnum.OPENLOOP_TYPE.id || typeId === ScrubberTypesEnum.UNKNOWN_TYPE.id
  );
}

/**
 * This function assumes that the `typeId` provided exists in `ScrubberTypesEnum`
 */
export function GetScrubberType(typeId?: ScrubberTypeId): ScrubberType {
  return Object.values(ScrubberTypesEnum).find((e) => e.id === typeId);
}
