import React, { PureComponent } from 'react';
import NumericInput, {
  validationDecorationStyles,
  validationLevels,
} from 'components/numeric-input';
import DropDown from 'components/dropdown';
import StowageUnitTypes, {
  CUBIC_METERS_PER_METRIC_TON,
  STOWAGE_NUMBER_OF_DECIMAL_PLACES,
  SPECIFIC_GRAVITY_NUMBER_OF_DECIMAL_PLACES,
  convertStowage,
} from 'constants/enums/stowage-factor-units';
import round from 'lodash/round';
import { isTanker, MarketSegmentId, isDry } from 'constants/market-segments';
import classNames from 'classnames';

export type Props = {
  stowage: number,
  stowageUnit: {},
  marketSegmentId: MarketSegmentId,
  stowageUnitDiagnosticId: String,
  stowageDiagnosticId: String,
  onStowageUpdated: Function,
  onStowageUnitUpdated: Function,
  onSpecificGravityUpdated: Function,
  shouldAutoCalculateIntake: Boolean,
};

export class StowageAndSpecificGravityInput extends PureComponent<Props> {
  minValue() {
    if (!this.props.shouldAutoCalculateIntake) {
      return 0;
    } else {
      return {
        value: 0.001 /* a requirement from auto intake calculation, see auto-calc-intake/index.js's `validateInputs` */,
        getAttemptedValueIsInvalidMessage: (attemptedValueInfo, ruleUsageParams, component) =>
          'This field should be greater than 0',
        validationLevel: validationLevels.guidance,
      };
    }
  }

  handleOnStowageUnitUpdated = (value) => {
    if (this.props.onStowageUnitUpdated) {
      this.props.onStowageUnitUpdated(value);
    }
  };

  handleOnStowageUpdated = (value) => {
    if (this.props.onStowageUpdated) {
      this.props.onStowageUpdated(value);
    }
  };

  handleOnSpecificGravityUpdated = (value) => {
    if (this.props.onSpecificGravityUpdated) {
      this.props.onSpecificGravityUpdated(value);
    }
  };

  render() {
    let htmlSpan;
    let htmlDropdown;
    let htmlInput;
    if (isDry(this.props.marketSegmentId)) {
      htmlDropdown = (
        <DropDown
          id="stowageUnit"
          items={StowageUnitTypes}
          selectedItem={this.props.stowageUnit}
          onChange={this.handleOnStowageUnitUpdated}
          asLabel
          diagnosticId="StowageAndSpecificGravityInput/ChangeStowageUnit"
        />
      );
      htmlSpan = <span className="worksheet-section__property-label">Stowage {htmlDropdown} </span>;

      htmlInput = (
        <NumericInput
          value={round(this.props.stowage, STOWAGE_NUMBER_OF_DECIMAL_PLACES)}
          className="worksheet-section__property__large-input"
          onInputChange={this.handleOnStowageUpdated}
          maxDecimalDigits={STOWAGE_NUMBER_OF_DECIMAL_PLACES}
          minValue={this.minValue()}
          maxValue={convertStowage(99.999, CUBIC_METERS_PER_METRIC_TON, this.props.stowageUnit)}
          diagnosticId={this.props.stowageDiagnosticId}
          validationDecorationStyle={validationDecorationStyles.borderAndLabel}
        />
      );
    } else if (!isDry(this.props.marketSegmentId)) {
      htmlSpan = <span className="worksheet-section__property-label">Specific Gravity</span>;
      htmlInput = (
        <NumericInput
          value={round(this.props.specificGravity, SPECIFIC_GRAVITY_NUMBER_OF_DECIMAL_PLACES)}
          className={classNames('worksheet-section__property__large-input', {
            'tankers-intake-input': isTanker(this.props.marketSegmentId),
          })}
          onInputChange={this.handleOnSpecificGravityUpdated}
          maxDecimalDigits={SPECIFIC_GRAVITY_NUMBER_OF_DECIMAL_PLACES}
          minValue={this.minValue()}
          maxValue={99.999}
          diagnosticId="StowageAndSpecificGravityInput/UpdateSpecificGravity"
        />
      );
    }

    return (
      <>
        {htmlSpan}
        {htmlInput}
      </>
    );
  }
}
