export type ReduxAction = { type: string };

export function buildAction(type, payload = undefined): ReduxAction {
  const action = { type };

  if (payload !== undefined) {
    action.payload = payload;
  }

  return action;
}
