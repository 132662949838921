export function joinStringList(list, separator, lastSeparator) {
  var output = '';

  if (list.length === 1) {
    output = list[0];
  } else if (list.length === 2) {
    output = list.join(lastSeparator);
  } else if (list.length > 2) {
    output = list.slice(0, -1).join(separator) + lastSeparator + list.slice(-1);
  }

  return output;
}
