import { useCallback } from 'react';
import { useDOMEvent } from 'hooks/dom-event';
import debounce from 'lodash/debounce';
import { debounceDefaults } from 'constants/defaults/debounce';

/**
 * @see {useDOMEvent}
 */
export function useDebouncedDOMEvent(
  eventTargetRef,
  eventType,
  callback,
  debounceOpts = debounceDefaults,
  eventOpts = undefined
) {
  const defaultDebounceOptions = debounceOpts || debounceDefaults;
  const debouncedEventCallback = useCallback(
    debounce(callback, defaultDebounceOptions.wait, {
      leading: defaultDebounceOptions.leading,
      maxWait: defaultDebounceOptions.maxWait,
    }),
    [callback]
  );
  useDOMEvent(eventTargetRef, eventType, debouncedEventCallback, eventOpts);
}
