import React, { Component } from 'react';
import Downshift from 'downshift';
import isNil from 'lodash/isNil';
import { trackChangeEvent } from 'diagnostics/calc-trackevents';
import classNames from 'classnames';

import './styles.scss';
import { singleOrThrow } from 'utilities/iterable';

export type Props = {
  /// The id for the input field
  id: mixed,
  /// class name to be combined with 'dropdown-with-label'
  className?: String,
  /// The key of the item currently selected.
  selectedItemKey?: DropDownItemKey,
  /// Optional: alternative to `selectedItemKey` - the item currently selected
  selectedItem?: Components.DropDownItem | null,
  /// items to be displayed within the dropdown
  items: Array<Components.DropDownItem>,
  /// executed when dropdown has changed
  onChange: Function,
  /// renders whatever is passed to it as `children`
  children?: any,
  isTankersDropdown?: boolean,
};

class Dropdown extends Component<Props> {
  getMenuItemCssClass = (highlightedIndex: number, index: number, prefix: String): string => {
    if (prefix) {
      return highlightedIndex === index
        ? `${prefix}dropdown__menu-item ${prefix}dropdown__menu-item--highlighted`
        : `${prefix}dropdown__menu-item`;
    }

    return highlightedIndex === index
      ? 'dropdown__menu-item dropdown__menu-item--highlighted'
      : 'dropdown__menu-item';
  };

  itemToString = (item: Item | null): string => {
    return isNil(item) ? '' : item.label;
  };

  onKeyDownHandler = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
    selectHighlightedItem: Function,
    isOpen: boolean
  ): void => {
    const keyCode = event?.key;

    if (isNil(keyCode) === false && keyCode === 'Tab') {
      selectHighlightedItem();
    }
    if (isNil(keyCode) === false && keyCode === 'Escape' && !isOpen) {
      event.nativeEvent.preventDownshiftDefault = true;
    }
  };

  handleOnChange = (value) => {
    const selectedItem = this.selectedItem;
    if (value.key !== this.props.selectedItemKey) {
      this.props.diagnosticId &&
        trackChangeEvent(this.props.diagnosticId, {
          oldValue: selectedItem,
          newValue: value,
        });
    }
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  get selectedItem() {
    return this.props.selectedItemKey !== undefined
      ? singleOrThrow(this.props.items.filter((item) => item.key === this.props.selectedItemKey))
      : this.props.selectedItem;
  }

  renderMenu = ({
    getInputProps,
    getToggleButtonProps,
    getItemProps,
    isOpen,
    highlightedIndex,
    selectHighlightedItem,
  }) => {
    const {
      items,
      asLabel,
      style,
      className,
      useIconSymbols,
      iconSymbolClassName,
      disabled,
      classNamePrefix,
      isTankersDropdown,
      useExtendedLabel,
      useSeparatorLine,
    } = this.props;
    const selectedItem = this.selectedItem;
    const iconSymbol = '<b>' + selectedItem.iconSymbol + '</b>';
    let prefix = '';
    if (classNamePrefix) {
      prefix = classNamePrefix + '-';
    }
    let defaultClass = prefix + (asLabel ? 'dropdown-label' : 'dropdown');

    if (!selectedItem) throw new Error('selected item cannot be falsy');

    return (
      <div className={classNames(defaultClass, className)} style={style}>
        <button
          {...getToggleButtonProps()}
          {...getInputProps({
            onKeyDown: (event) => this.onKeyDownHandler(event, selectHighlightedItem, isOpen),
          })}
          className={classNames(prefix + 'dropdown__display', {
            'tanker-dropdown': isTankersDropdown,
          })}
          data-testid={this.props['data-testid']}
          ref={this.props.buttonElementRef}
          disabled={disabled}
        >
          {useIconSymbols && (
            <span
              className={iconSymbolClassName}
              style={{
                background: selectedItem.background,
                color: selectedItem.color,
              }}
              dangerouslySetInnerHTML={{
                __html: iconSymbol,
              }}
            />
          )}

          {!useIconSymbols && !useExtendedLabel && (
            <span
              dangerouslySetInnerHTML={{
                __html: selectedItem.previewLabel || selectedItem.label,
              }}
            />
          )}

          {!useIconSymbols && useExtendedLabel && (
            <span
              dangerouslySetInnerHTML={{
                __html: selectedItem.extendedLabel,
              }}
            />
          )}

          <i className="has-icon icon--arrow-drop-down dropdown__display--icon" />
        </button>
        {isOpen && items.length > 0 ? (
          <div className={classNames(prefix + 'dropdown__menu')} data-test="menu">
            {!useIconSymbols &&
              items.map((item: Item, index: number) => (
                <React.Fragment key={item.key}>
                  <div
                    {...getItemProps({ item })}
                    data-testid="dropdown-menu-item"
                    key={item.key}
                    className={this.getMenuItemCssClass(highlightedIndex, index, prefix)}
                    dangerouslySetInnerHTML={{
                      __html: item.label,
                    }}
                  />
                  {useSeparatorLine && index === 1 && (
                    <div className="dropdown__menu-item-separator" />
                  )}
                </React.Fragment>
              ))}
            {useIconSymbols &&
              items.map((item: Item, index: number) => (
                <div
                  {...getItemProps({ item })}
                  key={item.key + 'top'}
                  className={this.getMenuItemCssClass(highlightedIndex, index, prefix)}
                >
                  <span
                    key={item.key + 'symbol'}
                    style={{
                      background: item.background,
                    }}
                    className={iconSymbolClassName}
                  >
                    <b>{item.iconSymbol}</b>
                  </span>
                  <div
                    data-testid="dropdown-menu-item"
                    key={item.key}
                    dangerouslySetInnerHTML={{
                      __html: item.label,
                    }}
                  />
                </div>
              ))}
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <Downshift
        onChange={this.handleOnChange}
        selectedItem={this.selectedItem}
        itemToString={this.itemToString}
        id={this.props.id}
      >
        {this.renderMenu}
      </Downshift>
    );
  }
}

export default Dropdown;
