import React from 'react';
import classNames from 'classnames';
import { WarningIcon } from 'components/icons';
import { PrimaryButton } from 'components/button';

import './styles.scss';

export function FallbackError(props) {
  const emailRecipient = 'support@sea.live';
  const { emailTitle, emailBody: errorInfo } = props.supportEmailOptions;
  const emailBody = `${errorInfo}. The unknown error occured at ${window.location.href}.`;

  return (
    <div className={classNames('fallback-error', props.className)}>
      <WarningIcon className="fallback-error__warning-icon" />
      <h1 className="fallback-error__title">{props.title}</h1>
      <span className="fallback-error__message">{props.children}</span>
      <a
        href={`mailto:${encodeURI(emailRecipient)}?subject=${encodeURIComponent(
          emailTitle
        )}&body=${encodeURIComponent(emailBody)}`}
        className="fallback-error__action"
      >
        <PrimaryButton diagnosticId="FallbackError/ContactSupport">CONTACT SUPPORT</PrimaryButton>
      </a>
    </div>
  );
}
