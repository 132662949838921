import guid from 'utilities/guid';
import {
  PRE_DEFINED_EXPENSE_COST_UPDATED,
  PRE_DEFINED_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_DESCRIPTION_UPDATED,
  OTHER_EXPENSE_COST_UPDATED,
  OTHER_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_ADDED,
  OTHER_EXPENSE_REMOVED,
} from 'constants/action-types/worksheet/additional-expenses';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import maxBy from 'lodash/maxBy';

const initialState = {
  preDefined: [
    {
      id: guid(),
      description: 'CVE ($)',
      cost: 0,
      costType: 1,
    },
    {
      id: guid(),
      description: 'ILOHC ($)',
      cost: 0,
      costType: 1,
    },
    {
      id: guid(),
      description: 'Miscellaneous ($)',
      cost: 0,
      costType: 1,
    },
  ],
  others: [
    {
      id: guid(),
      description: '',
      cost: 0,
      costType: 1,
    },
  ],
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return getAdditionalExpensesViewModelFromWorksheetDto(action.payload);
    case PRE_DEFINED_EXPENSE_COST_UPDATED:
      return {
        ...state,
        preDefined: (() => {
          let preDefined = state.preDefined.slice();
          preDefined[action.payload.index].cost = action.payload.cost;
          return preDefined;
        })(),
      };
    case PRE_DEFINED_EXPENSE_COST_TYPE_UPDATED:
      return {
        ...state,
        preDefined: (() => {
          let preDefined = state.preDefined.slice();
          preDefined[action.payload.index].costType = action.payload.costType;
          return preDefined;
        })(),
      };
    case OTHER_EXPENSE_DESCRIPTION_UPDATED:
      return {
        ...state,
        others: (() => {
          let others = state.others.slice();
          others[action.payload.index].description = action.payload.description;
          return others;
        })(),
      };
    case OTHER_EXPENSE_COST_UPDATED:
      return {
        ...state,
        others: (() => {
          let others = state.others.slice();
          others[action.payload.index].cost = action.payload.cost;
          return others;
        })(),
      };
    case OTHER_EXPENSE_COST_TYPE_UPDATED:
      return {
        ...state,
        others: (() => {
          let others = state.others.slice();
          others[action.payload.index].costType = action.payload.costType;
          return others;
        })(),
      };
    case OTHER_EXPENSE_ADDED:
      const lastExpense = maxBy(state.others, 'sortOrder');
      return {
        ...state,
        others: state.others.slice().concat({
          id: guid(),
          description: '',
          cost: 0,
          costType: 1,
          sortOrder: lastExpense && lastExpense.sortOrder ? lastExpense.sortOrder + 1 : 0,
        }),
      };
    case OTHER_EXPENSE_REMOVED:
      return {
        ...state,
        others: (() => {
          let others = state.others.slice();
          others.splice(action.payload, 1);
          return others.length > 0
            ? others
            : [
                {
                  id: guid(),
                  description: '',
                  cost: 0,
                  costType: 1,
                  sortOrder: 3,
                },
              ];
        })(),
      };
    default:
      return state;
  }
};

/**
 * Part of the inverse of `mapWorksheetViewModelToAdditionalExpensesCommonModel`
 */
export const getAdditionalExpensesViewModelFromWorksheetDto: (
  worksheet: Data.IWorksheet
) => Redux.IAdditionalExpenses = ({ additionalExpenses }) => {
  return {
    preDefined: additionalExpenses.filter((_) => _.isPredefinedExpense === true).map(mapExpense),
    others: additionalExpenses.filter((_) => _.isPredefinedExpense === false).map(mapExpense),
  };

  function mapExpense({ isPredefinedExpense, ...expense }) {
    return { ...expense };
  }
};
