import { AppInsights } from 'applicationinsights-js';
import { applicationInsights } from 'config';
import isNil from 'lodash/isNil';

export const setupAppInsights = (config): void => {
  const { authenticatedUserId } = config || {};

  AppInsights.downloadAndSetup({
    // The key of your Application Insights resource in Azure
    instrumentationKey: applicationInsights.instrumentationKey,
    connectionString: applicationInsights.connectionString,
    // Default 500 - controls how many ajax calls will be monitored per page view.
    // Set to -1 to monitor all ajax calls on the page.
    maxAjaxCallsPerView: -1,

    // If true, the SDK will add two headers ('Request-Id' and 'Request-Context') to all
    // CORS requests to correlate outgoing AJAX dependencies with corresponding requests on the server side.
    // Default false.
    enableCorsCorrelation: false,
  });

  if (authenticatedUserId) {
    // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setAuthenticatedUserContext
    AppInsights.setAuthenticatedUserContext(authenticatedUserId.toString());
  }
};

/**
 * Log a user action or other occurrence.
 * @param   component     Name of the component associated with the tracked event.
 * @param   event         Name of the event to identify this event in the portal.
 * @param   properties    map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
 * @param   measurements  map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
 */
export const trackEvent = (
  component: string,
  event: string,
  properties?: { [name: string]: string },
  measurements?: { [name: string]: number }
): any => {
  if (isNil(component)) {
    return;
  }

  AppInsights.trackEvent(`WebApp/${component}/${event}`, properties, measurements);
  AppInsights.flush();
};

/**
 * Logs that a page or other item was viewed.
 * @param   name          The string you used as the name in `startTrackPage`. Defaults to the document title.
 * @param   url           String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
 * @param   properties    map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
 * @param   measurements  map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
 * @param   duration      number - the number of milliseconds it took to load the page. Defaults to undefined. If set to default value, page load time is calculated internally.
 */
export const trackPageView = (
  name?: string,
  url?: string,
  properties?: { [name: string]: string },
  measurements?: { [name: string]: number },
  duration?: number
): any => {
  AppInsights.trackPageView(name, url, properties, measurements, duration);
};

/**
 * Starts the timer for tracking a page load time. Use this instead of `trackPageView` if you want to control when the page view timer starts and stops,
 * but don't want to calculate the duration yourself. This method doesn't send any telemetry. Call `stopTrackPage` to log the end of the page view
 * and send the event.
 * @param   name  A string that idenfities this item, unique within this HTML document. Defaults to the document title.
 */
export const startTrackPage = (name?: string): any => {
  AppInsights.startTrackPage(name);
};

/**
 * Stops the timer that was started by calling `startTrackPage` and sends the pageview load time telemetry with the specified properties and measurements.
 * The duration of the page view will be the time between calling `startTrackPage` and `stopTrackPage`.
 * @param   name          The string you used as the name in `startTrackPage`. Defaults to the document title.
 * @param   url           String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
 * @param   properties    map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
 * @param   measurements  map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
 */
export const stopTrackPage = (
  name?: string,
  url?: string,
  properties?: { [name: string]: string },
  measurements?: { [name: string]: number }
): any => {
  AppInsights.stopTrackPage(name, url, properties, measurements);
};
