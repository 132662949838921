import { externalVesselApi } from 'api';
import { getFuelGradeFor } from '../../../constants/enums/fuel-grades';
import stringEnum, { StringEnum } from 'utilities/enum/string-enum';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { rateLimited } from 'utilities/functions';
import {
  LADEN,
  ECO_LADEN,
  BALLAST,
  ECO_BALLAST,
  PORT_IDLE,
  PORT_MANOEUVRING,
  PORT_WORKING,
  SAILING,
  ECO_SAILING,
} from '../../../constants/enums/speed-and-con-types';

export interface ExternalVesselApiSpeedAndConsumption {
  vesselSpeedAndConsumptionId: number;
  vesselSpeedAndConsumptionActionId: number;
  vesselId: number;
  departmentId: number;
  speed: number;
  consumption: number;
  fuelGradeId: number;
  fuelGradeName: string;
  generatorConsumption: number;
  generatorFuelGradeId: number;
  generatorFuelGradeName: string;
  actionName: string;
  speedApplicable: true;
  updatedDate: string;
  updatedBy: string;
}
// Note: #79570 - These Speed and Consumptions are the types from Vessel API (vesselSpeedAndConsumptionActionId). In Calc, we have Loading and Discharging instead of Working - see (speed-and-con-types/index.js)
const speedAndConsumptionTypesArray = ['ballast', 'laden', 'working', 'idle', 'manoeuvring'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
export type SpeedAndConsumptionType = 'ballast' | 'laden' | 'working' | 'idle' | 'manoeuvring';
export const speedAndConsumptionTypes: StringEnum<SpeedAndConsumptionType> = stringEnum(
  speedAndConsumptionTypesArray
);

export interface ExternalVesselApiSpeedAndConsumptionGroupedByType {
  [SpeedAndConsumptionType]: ExternalVesselApiSpeedAndConsumption[];
}

let vesselApiMaxConcurrency = 10;
export const getVesselApiMaxConcurrency = () => {
  return vesselApiMaxConcurrency;
};

export const setVesselApiMaxConcurrency = (newVesselApiMaxConcurrency: number) => {
  vesselApiMaxConcurrency = newVesselApiMaxConcurrency;
  getSpeedAndConsumptionsForVesselIdRateLimited = rateLimited(
    vesselApiMaxConcurrency,
    getSpeedAndConsumptionsForVesselId
  );
};

export let getSpeedAndConsumptionsForVesselIdRateLimited = rateLimited(
  vesselApiMaxConcurrency,
  getSpeedAndConsumptionsForVesselId
);

export async function getSpeedAndConsumptionsForVesselId(
  vesselId: number
): ExternalVesselApiSpeedAndConsumption[] {
  const response: {
    count: number,
    results: ExternalVesselApiSpeedAndConsumption[],
  } = await externalVesselApi.get(`/api/vesselspeedandconsumption?vesselId=${vesselId}`);
  return response.data.results.filter(
    (_) => getFuelGradeFor(_.fuelGradeId) && getFuelGradeFor(_.generatorFuelGradeId)
  );
}

export function mapVesselSpeedAndConsumptionActionIdToSpeedAndConsumptionType(typeId: number) {
  switch (typeId) {
    case LADEN.id:
    case ECO_LADEN.id:
      return speedAndConsumptionTypes.laden;
    case BALLAST.id:
    case ECO_BALLAST.id:
      return speedAndConsumptionTypes.ballast;
    case PORT_IDLE.id:
      return speedAndConsumptionTypes.idle;
    case PORT_MANOEUVRING.id:
      return speedAndConsumptionTypes.manoeuvring;
    case PORT_WORKING.id:
      return speedAndConsumptionTypes.working;
    case SAILING.id:
    case ECO_SAILING.id:
      return speedAndConsumptionTypes.sailing;
    default:
      throw new Error(`Unsupport speed and consumption type ${typeId}`);
  }
}

export interface ExternalVesselApiSpeedAndConsumptionSet {
  ballast: ExternalVesselApiSpeedAndConsumption;
  laden: ExternalVesselApiSpeedAndConsumption;
  idle: ExternalVesselApiSpeedAndConsumption;
  loading: ExternalVesselApiSpeedAndConsumption;
  discharging: ExternalVesselApiSpeedAndConsumption;
  manoeuvring: null | ExternalVesselApiSpeedAndConsumption;
}

export function mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
  externalSpeedAndConsumption: ExternalVesselApiSpeedAndConsumption
): ISpeedAndConsumptionEntry {
  return {
    speed: externalSpeedAndConsumption.speed,
    consumption: externalSpeedAndConsumption.consumption,
    fuelGrade: externalSpeedAndConsumption.fuelGradeId,
    generatorConsumption: externalSpeedAndConsumption.generatorConsumption,
    generatorFuelGrade: externalSpeedAndConsumption.generatorFuelGradeId,
    auditInfo: {
      modifiedBy: externalSpeedAndConsumption.updatedBy,
      modifiedDate: externalSpeedAndConsumption.updatedDate,
    },
  };
}

export function groupExternalSpeedAndConsumptionsByType(
  externalSpeedAndConsumptions: ExternalVesselApiSpeedAndConsumption[]
): null | ExternalVesselApiSpeedAndConsumptionGroupedByType {
  const externalSpeedAndConsumptionsGroupedByType = groupBy(externalSpeedAndConsumptions, (_) =>
    mapVesselSpeedAndConsumptionActionIdToSpeedAndConsumptionType(
      _.vesselSpeedAndConsumptionActionId
    )
  );

  return externalSpeedAndConsumptionsGroupedByType;
}

export function getMissingRequiredSpeedAndConsumptionsTypes(
  externalSpeedAndConsumptionsGroupedByType: ExternalVesselApiSpeedAndConsumptionGroupedByType
): null | ExternalVesselApiSpeedAndConsumption {
  return Object.keys(speedAndConsumptionTypes)
    .filter(
      (speedAndConsumptionType) => speedAndConsumptionType !== speedAndConsumptionTypes.manoeuvring
    )
    .filter(
      (speedAndConsumptionType) =>
        !externalSpeedAndConsumptionsGroupedByType[speedAndConsumptionType]
    );
}

export function createPermutationsOfSpeedAndConsumptionsAsSpeedAndConsumptionSets(
  externalSpeedAndConsumptionsGroupedByType: ExternalVesselApiSpeedAndConsumptionGroupedByType
): null | ExternalVesselApiSpeedAndConsumptionSet[] {
  if (!externalSpeedAndConsumptionsGroupedByType) {
    throw new Error('externalSpeedAndConsumptionsGroupedByType is null');
  }

  const permutationsOfDynamicSpeedAndConsumptions = [];
  for (const ballastTypeSpeedAndConsumption of externalSpeedAndConsumptionsGroupedByType.ballast) {
    for (const ladenTypeSpeedAndConsumption of externalSpeedAndConsumptionsGroupedByType.laden) {
      permutationsOfDynamicSpeedAndConsumptions.push({
        ballast: ballastTypeSpeedAndConsumption,
        laden: ladenTypeSpeedAndConsumption,
      });
    }
  }

  const latestStaticSpeedAndConsumption = {
    idle:
      externalSpeedAndConsumptionsGroupedByType.idle &&
      externalSpeedAndConsumptionsGroupedByType.idle.length &&
      orderBy(
        externalSpeedAndConsumptionsGroupedByType.idle,
        (_) => new Date(_.updatedDate),
        'desc'
      )[0],
    loading:
      externalSpeedAndConsumptionsGroupedByType.working &&
      externalSpeedAndConsumptionsGroupedByType.working.length &&
      orderBy(
        externalSpeedAndConsumptionsGroupedByType.working,
        (_) => new Date(_.updatedDate),
        'desc'
      )[0],
    discharging:
      externalSpeedAndConsumptionsGroupedByType.working &&
      externalSpeedAndConsumptionsGroupedByType.working.length &&
      orderBy(
        externalSpeedAndConsumptionsGroupedByType.working,
        (_) => new Date(_.updatedDate),
        'desc'
      )[0],
    manoeuvring:
      externalSpeedAndConsumptionsGroupedByType.manoeuvring &&
      externalSpeedAndConsumptionsGroupedByType.manoeuvring.length &&
      orderBy(
        externalSpeedAndConsumptionsGroupedByType.manoeuvring,
        (_) => new Date(_.updatedDate),
        'desc'
      )[0],
  };

  const speedAndConsumptionSets = permutationsOfDynamicSpeedAndConsumptions.map((_) => ({
    ballast: _.ballast,
    laden: _.laden,
    ...latestStaticSpeedAndConsumption,
  }));
  return speedAndConsumptionSets;
}
