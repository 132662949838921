/**
 * For some reason it's not exported by AppInsights, i.e. `import * from 'applicationinsights-js'` doesn't include the severity levels
 */
// Source https://github.com/Microsoft/ApplicationInsights-node.js/blob/50dec0941d5e73de6b7ffe081f66ab739bd62876/Declarations/Contracts/Generated/SeverityLevel.ts#L7
export default {
  Verbose: 0,
  Information: 1,
  Warning: 2,
  Error: 3,
  Critical: 4,
};
