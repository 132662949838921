import React from 'react';
import classNames from 'classnames';

const LabeledInput = (props) => {
  const classes = classNames('labeled-input', props.className);

  return <div className={classes}>{props.children}</div>;
};

export default LabeledInput;
