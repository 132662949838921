import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { Panel, PanelSection } from 'components/panel';
import { Table, TableBody } from 'components/table';
import { Header } from 'components/headers';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

import BunkerTableHeader from './components/bunker-ports-table-header';
import BunkerTableRow from './components/bunker-ports-table-row';
import BunkerPortsFilterInput from './components/bunker-ports-filter-input';

import { setStageLeftAs } from 'actions/stage';
import { WORKSHEET_PANEL } from 'constants/enums/stage-panels';
import { selector } from './selector';
import { applyPricesToBunkersOnBoard } from 'actions/bunker-ports';

import './styles.scss';

export class BunkerPortsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      filteredBunkerPorts: [...this.props.bunkerPorts],
    };
  }

  buildBunkerTableRows = () => {
    const rows = this.state.filteredBunkerPorts.map((bunkerPort) => (
      <BunkerTableRow
        key={bunkerPort.bunkerId}
        applyPrice={this.applyPrice(bunkerPort)}
        isBunkerPricesPanelReadOnly={this.props.isBunkerPricesPanelReadOnly}
        marketSegmentId={this.props.worksheetInvariantProps.marketSegmentId}
        {...bunkerPort}
      />
    ));
    return rows;
  };

  handleChange = (event) => {
    this.setState({ filter: event.target.value }, this.filterBunkerPorts);
  };

  closeBunkerPortsTable = () => this.props.setStageLeftAs(WORKSHEET_PANEL);

  applyPrice = (bunkerPort) => () => {
    this.props.applyPricesToBunkersOnBoard(bunkerPort, this.props.worksheetId);
    this.closeBunkerPortsTable();
  };

  filterBunkerPorts = () => {
    const filteredBunkerPorts = this.props.bunkerPorts.filter(this.matchFilterToBunkerPort);

    this.setState({ filteredBunkerPorts });
  };

  matchFilterToBunkerPort = (bunkerPort) => {
    const filter = this.state.filter.toLowerCase().trim();

    if (isNil(filter) || isEmpty(filter)) {
      return true;
    } else {
      return (
        bunkerPort.locationName.toLowerCase().startsWith(filter) ||
        bunkerPort.zone.toLowerCase().startsWith(filter)
      );
    }
  };

  render() {
    return (
      <Panel>
        <PanelSection>
          <div className="bunker-ports-table__header">
            <Header
              text={
                this.props.isBunkerPricesPanelReadOnly
                  ? 'Bunker prices ($)'
                  : 'Import bunker prices ($)'
              }
            />
            <BunkerPortsFilterInput filter={this.state.filter} onChange={this.handleChange} />
            <IconButton
              icon={iconEnum.Close}
              className="bunker-ports-table__header--close-btn"
              onClick={this.closeBunkerPortsTable}
              diagnosticId="BunkerPortsTable/CloseTable"
            />
          </div>
          <Table className="bunker-ports-table">
            <BunkerTableHeader
              isBunkerPricesPanelReadOnly={this.props.isBunkerPricesPanelReadOnly}
              marketSegmentId={this.props.worksheetInvariantProps.marketSegmentId}
            />
            <TableBody className="bunker-ports-table__body">
              {this.buildBunkerTableRows()}
            </TableBody>
          </Table>
        </PanelSection>
      </Panel>
    );
  }
}

const mapStateToProps = selector;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setStageLeftAs,
      applyPricesToBunkersOnBoard,
    },
    dispatch
  );
};

const BunkerPortsTableContainer = connect(mapStateToProps, mapDispatchToProps)(BunkerPortsTable);

export default BunkerPortsTableContainer;
