import React from 'react';
import { isFeatureEnabled } from '../../../../../config/feature-control';
import LegContainer from '../../leg-container';
import PreviousPortOfCall from '../../previous-port-of-call';

interface PreviousPortOfCallWetCargoProps {
  handleOpenPositionPrevPortOfCallIsInEeaUpdated: (isInEea: boolean) => void;
  openPosition: IOpenPosition;
}

const PreviousPortOfCallWetCargo = ({
  handleOpenPositionPrevPortOfCallIsInEeaUpdated,
  openPosition,
}: PreviousPortOfCallWetCargoProps) => {
  if (!isFeatureEnabled('wetCargoLitePortsOfCall')) {
    return null;
  }

  return (
    <LegContainer legClassName="previous-port-of-call" dotClassName="dot solid-secondary-dot">
      <div className="port-of-call-top-container">
        <PreviousPortOfCall
          handleOpenPositionPrevPortOfCallIsInEeaUpdated={
            handleOpenPositionPrevPortOfCallIsInEeaUpdated
          }
          openPosition={openPosition}
          oldView={true}
        />
      </div>
    </LegContainer>
  );
};

export default PreviousPortOfCallWetCargo;
