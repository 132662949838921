import React from 'react';
import { IconTooltip } from 'components/icon-tooltip';
import { iconEnum } from 'components/icons';
import { speedAndConsumptionsModes } from 'api/clients/calculation';
import type { SpeedAndConsumptionsMode } from 'api/clients/calculation';

interface WorksheetSpeedAndConsumptionsModeLabelTooltipProps {
  speedAndConsumptionsMode: SpeedAndConsumptionsMode;
}

export function WorksheetSpeedAndConsumptionsModeLabelTooltip(
  props: WorksheetSpeedAndConsumptionsModeLabelTooltipProps
) {
  const { speedAndConsumptionsMode } = props;

  const getWorksheetSpeedAndConsumptionsModeLabel = (
    speedAndConsumptionsMode: SpeedAndConsumptionsMode
  ) => {
    switch (speedAndConsumptionsMode) {
      case speedAndConsumptionsModes.findCheapest:
        return 'Worksheet set to cheapest mode';
      case speedAndConsumptionsModes.manual:
        return 'Worksheet set to manual mode';
      default:
        throw new Error(
          `Could not run getWorksheetSpeedAndConsumptionsModeLabel due to unknown speedAndConsumptionsMode: ${speedAndConsumptionsMode}`
        );
    }
  };

  const getWorksheetSpeedAndConsumptionsModeTooltipText = (
    speedAndConsumptionsMode: SpeedAndConsumptionsMode
  ) => {
    switch (speedAndConsumptionsMode) {
      case speedAndConsumptionsModes.findCheapest:
        return 'Uses speed and consumptions from Vessel API and performs permutations on the ballast and laden pairs to find the cheapest by voyage rate gross. Note: Entered speed and consumptions are ignored!';
      case speedAndConsumptionsModes.manual:
        return 'Uses the speed and consumptions data entered against each vessel when calculating rates.';
      default:
        throw new Error(
          `Could not run getWorksheetSpeedAndConsumptionsModeTooltipText due to unknown speedAndConsumptionsMode: ${speedAndConsumptionsMode}`
        );
    }
  };

  return (
    <div className="worksheet-info__speed-and-consumption-mode-label">
      <IconTooltip
        icon={iconEnum.InfoOutline}
        className="worksheet-info__speed-and-consumption-mode-label-tooltip"
      >
        <div className="worksheet-info__speed-and-consumption-mode-label-tooltip-text">
          {getWorksheetSpeedAndConsumptionsModeTooltipText(speedAndConsumptionsMode)}
        </div>
      </IconTooltip>
      <span className="worksheet-info__speed-and-consumption-mode-label-text">
        {getWorksheetSpeedAndConsumptionsModeLabel(speedAndConsumptionsMode)}
      </span>
    </div>
  );
}
