import axios from 'axios';
import { cloudUrl } from 'config';
import isNil from 'lodash/isNil';
import * as exceptionSink from 'diagnostics/unhandled-exception-sink';
import logger from 'diagnostics/logger';

const veSecurityRightId: number = 30;
const adminSecurityRightId: number = -1;
const hasAccess: number = 1;

export const authorise = async (token): boolean => {
  if (isNil(token)) {
    return nilAuthorisationResult;
  }

  const encodedToken: string = encodeURIComponent(token);

  try {
    let getCurrentUserUrl = `${cloudUrl}/api/1_3/Security/GetCurrentUser?token=${encodedToken}`;
    const response: AxiosResponse = await axios.get(getCurrentUserUrl);

    const { SecurityRights, SystemUserId, CompanyId } = response.data;

    if (!SecurityRights) return nilAuthorisationResult;

    const securityRight = SecurityRights[veSecurityRightId] || SecurityRights[adminSecurityRightId];

    if (!(securityRight && securityRight.includes(hasAccess))) {
      logger.warn('The user exists but does not have security right to the app.');
      return nilAuthorisationResult;
    }

    return {
      isAuthorised: true,
      systemUserId: SystemUserId,
      isInHouseCompany: CompanyId === 1 /* Clarksons */,
    };
  } catch (e) {
    exceptionSink.send(e);
    return nilAuthorisationResult;
  }
};

const nilAuthorisationResult = {
  isAuthorised: false,
};
