import React from 'react';
import classNames from 'classnames';

import './styles.scss';

export const CenteredContent = React.memo(({ className, children, ...props }) => {
  return (
    <div className={classNames('centered-content', className)} {...props}>
      {children}
    </div>
  );
});

export default CenteredContent;
