import { netFromGross, grossFromNet } from 'utilities/rates-converter';
import {
  RATE_BALLAST_BONUS_CHANGED,
  RATE_TIME_CHARTER_CHANGED,
} from 'constants/action-types/worksheet/vessel/rates';

export const grossTimeCharterUpdated =
  (grossTimeCharter, worksheetId, vesselEntryId) => async (dispatch, getState) => {
    const appState = getState();
    const worksheet = appState.worksheetsById[worksheetId];
    const totalCommission =
      worksheet.rates.brokerCommissionTimeCharter + worksheet.rates.addressCommissionTimeCharter;
    const netTimeCharter = netFromGross(grossTimeCharter, totalCommission);

    await dispatch({
      worksheetId: worksheet.id,
      vesselEntryId,
      payload: {
        grossTimeCharter,
        netTimeCharter,
        brokerCommissionTimeCharter: worksheet.rates.brokerCommissionTimeCharter,
        addressCommissionTimeCharter: worksheet.rates.addressCommissionTimeCharter,
      },
      type: RATE_TIME_CHARTER_CHANGED,
    });
  };

export const netTimeCharterUpdated =
  (netTimeCharter, worksheetId, vesselEntryId) => async (dispatch, getState) => {
    const appState = getState();
    const worksheet = appState.worksheetsById[worksheetId];
    const totalCommission =
      worksheet.rates.brokerCommissionTimeCharter + worksheet.rates.addressCommissionTimeCharter;
    const grossTimeCharter = grossFromNet(netTimeCharter, totalCommission);

    await dispatch({
      worksheetId: worksheet.id,
      vesselEntryId,
      payload: {
        grossTimeCharter,
        netTimeCharter,
        brokerCommissionTimeCharter: worksheet.rates.brokerCommissionTimeCharter,
        addressCommissionTimeCharter: worksheet.rates.addressCommissionTimeCharter,
      },
      type: RATE_TIME_CHARTER_CHANGED,
    });
  };

export function ballastBonusUpdated(payload, worksheetId, vesselEntryId) {
  return {
    worksheetId,
    vesselEntryId,
    payload,
    type: RATE_BALLAST_BONUS_CHANGED,
  };
}
