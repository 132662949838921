import { LOAD_WORKSHEET, SET_WORKSHEET_STATUS } from 'constants/action-types/workbook';
import { WorksheetStatus } from 'constants/enums/worksheet-status';

const status = (state = null, action) => {
  if (action.type === LOAD_WORKSHEET) {
    return WorksheetStatus.LOADED;
  } else if (action.type === SET_WORKSHEET_STATUS) {
    return action.payload;
  }
  return state;
};

export default status;
