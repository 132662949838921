import React, { Component } from 'react';
import classNames from 'classnames';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { CopyWorkbookButton } from '../copy-workbook-button';
import './styles.scss';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import * as RedirectManager from 'utilities/redirect-manager';

export class SearchWorksheetMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, visible: false };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  toggleMenu = () => {
    this.setState({ visible: !this.state.isOpen, isOpen: !this.state.isOpen });
    if (!this.state.isOpen) {
      trackEvent(
        'WorksheetInfo',
        'Copy / Move Menu Activated',
        {
          sourceWorksheetBelongsToColleague: this.props.sourceWorksheetBelongsToColleague,
        },
        {},
        eventDestination.ANALYSIS
      );
    }
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  hideMenu = () => {
    this.setState({ visible: false });
  };

  storeURLInLocalStorage = (url) => {
    RedirectManager.storeURL(url);
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }

    //Click is outside the menu element, and so close the menu.
    if (this.state.isOpen) {
      this.closeMenu();
    }
  };

  trackMenuWorksheet = (diagnosticId, menuOption) => {
    const properties = {
      diagnosticId: diagnosticId,
      search: {
        resultCategories: 'All',
        searchQuery: this.props.data.searchTerm,
        worksheetId: this.props.data.worksheetId,
        workbookId: this.props.data.workbookId,
        matchingFields: {
          worksheetName: this.props.data.matchesWorksheetName,
          cargo: this.props.data.matchesCargos,
          vessel: this.props.data.matchesVessels,
          loadPort: this.props.data.matchesLoadPort,
          dischargePort: this.props.data.matchesDischargePort,
        },
        searchResultMatchNumber: this.props.data.position,
        searchResultTotalResults: this.props.data.totalCount,
        queryTime_ms: this.props.data.queryTime_ms,
        menuOption: menuOption,
      },
    };
    trackEvent(
      'SearchWorksheetMenu',
      'Menu Item View Worksheet',
      properties,
      {},
      eventDestination.ANALYSIS
    );
    if (menuOption === 'viewWorksheet') {
      this.props.closePanelFun();
    }
  };

  onNodeMounted = (node) => (this.node = node);

  render() {
    const classes = classNames({ 'menu-open': this.state.isOpen }, this.props.className);
    const url = `workbook/${this.props.workbookId}/worksheet/${this.props.worksheetId}`;
    const searchMenuClasses = classNames('search-menu', {
      'search-menu-hide': !this.state.visible,
    });
    return (
      <div ref={this.onNodeMounted}>
        <IconButton
          icon={this.props.icon || iconEnum.MoreVert}
          className={classes}
          disabled={this.props.disabled}
          onClick={this.toggleMenu}
          diagnosticId="SearchWorksheetMenu/ToggleMenu"
        />
        {this.state.isOpen ? (
          <div className={searchMenuClasses}>
            <div className="search-menu-content">
              <div className="search-menu-item">
                <a
                  className="search-menu-link-button"
                  href={'#/' + url}
                  onClick={() =>
                    this.trackMenuWorksheet(
                      'WorksheetSearch/MenuItemViewWorksheet',
                      'viewWorksheet'
                    )
                  }
                  onMouseDown={() => this.storeURLInLocalStorage(url)}
                >
                  View Worksheet
                </a>
              </div>
              <div
                className="search-menu-item"
                onClick={() =>
                  this.trackMenuWorksheet('WorksheetSearch/MenuItemCopyWorksheet', 'copyWorksheet')
                }
              >
                <CopyWorkbookButton
                  copyOnlyThisWorksheet={this.props.worksheetId}
                  workbook={this.props.workbook}
                  onCopied={this.props.loadMyWorkbooksFun}
                  hideFun={this.hideMenu}
                  afterClick={this.closeMenu}
                  showLink={true}
                  linkText="Copy to a new workbook"
                  linkStyle="search-menu-link-button"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
