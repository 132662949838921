import isNil from 'lodash/isNil';
import { getConfig } from 'config';
import { setupApiClients } from 'api';
import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import { setupAppInsights } from 'diagnostics/calc-trackevents';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { authorise } from './authorisation';
import userContext from './../user-context';
import { veApi } from 'api';
import { addVersionCheckInterceptorToClient } from 'api/version-check-interceptor';
import * as AuthFacade from './AuthFacade';

const localStorageKey = 'path';

export const bootstrap = async (app) => {
  // Ensure all unhandled promise rejections that make it all the way to the browser runtime are not lost - See: https://stackoverflow.com/a/28004999/4356868
  window.addEventListener('unhandledrejection', (promiseRejectionEvent) => {
    unhandledExceptionSinkSend(promiseRejectionEvent.reason);
    // Prevent display on the console here. The sink decides what to do with it, so this could be a duplicate.
    promiseRejectionEvent.preventDefault();
  });

  const config = await getConfig();

  await AuthFacade.initialise(config);
  const token = await AuthFacade.getToken();

  if (isNil(token)) {
    storeUrlInLocalStorage();
    AuthFacade.redirectToLogin();
  } else {
    cleanUrl();
    setupApiClients();

    const registerWebApiVersionMismatchHandler = addVersionCheckInterceptorToClient(veApi);

    const { isAuthorised, ...userInfo } = await authorise(token);
    userContext.userInfo = userInfo;
    if (!isAuthorised) {
      AuthFacade.redirectToLogin();
      return;
    }

    setupAppInsights({ authenticatedUserId: userInfo.systemUserId });
    app({ ...config, registerWebApiVersionMismatchHandler });
  }

  trackEvent(
    'WebApp',
    'WebApp Load App Init Info',
    {
      // In AppInsights, these values can be seen in: Azure Console > AppInsight Resource Instance > Sessions > 'Split by'
      browserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // NOTE - `browserTimeZone` won't be needed once AppInsights addressed [this feature request](https://feedback.azure.com/forums/357324-azure-monitor-application-insights/suggestions/38891104-include-user-s-browser-time-zone-in-the-session-in)
    },
    {},
    eventDestination.ANALYSIS
  );
};

const tokenKey = 'token';

export const cleanUrl = (): void => {
  if (window.history.pushState) {
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    params.delete(tokenKey);

    let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    if (!params.entries().next().done) {
      newUrl = `${newUrl}?${params.toString()}`;
    }

    if (window.location.hash) {
      newUrl = `${newUrl}${window.location.hash}`;
    }

    if (localStorage.getItem(localStorageKey)) {
      let localStorageUrl = localStorage.getItem(localStorageKey);
      localStorage.removeItem(localStorageKey);
      window.history.pushState(null, '', localStorageUrl);
    } else {
      window.history.pushState(null, '', newUrl);
    }
  }
};

const storeUrlInLocalStorage = (): void => {
  let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const params: URLSearchParams = new URLSearchParams(window.location.search);
  if (!params.entries().next().done) {
    newUrl = `${newUrl}?${params.toString()}`;
  }
  localStorage.setItem(localStorageKey, `${newUrl}${window.location.hash}`);
};
