import { createSelector } from 'reselect';

import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { getActiveVessel } from 'common-selectors/get-active-vessel';
import { bunkersOnBoardId } from 'constants/enums/bunkers';
import { getFuelGradeById } from 'constants/enums/fuel-grades';

export const selector = createSelector(getActiveWorksheet, getActiveVessel, (worksheet, vessel) => {
  const mainFuelGradeId = vessel.speedAndConsumptions.ballast.fuelGrade;
  const generatorFuelGradeId = vessel.speedAndConsumptions.ballast.generatorFuelGrade;
  const secaFuelGradeId = vessel.speedAndConsumptions.zoneSpecific.seca.fuelGradeId;
  const bunker = worksheet.bunkerExpenses.bunkers.find((bunker) => bunker.key === bunkersOnBoardId);
  return {
    worksheetId: worksheet.id,
    mainFuelGradeId,
    secaFuelGradeId,
    generatorFuelGradeId,
    mainFuelLabel: getFuelGradeById(mainFuelGradeId).label,
    secaFuelLabel: getFuelGradeById(secaFuelGradeId).label,
    generatorFuelLabel: getFuelGradeById(generatorFuelGradeId).label,
    mainFuelPrice: getFuelPrice(bunker, mainFuelGradeId),
    secaFuelPrice: getFuelPrice(bunker, secaFuelGradeId),
    generatorFuelPrice: getFuelPrice(bunker, generatorFuelGradeId),
  };
});

const getFuelPrice = (bunker: IBunkersViewModel, fuelGradeId: number) =>
  bunker.fuelGrades.find((fg) => fg.key === fuelGradeId).price;
