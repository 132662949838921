import React from 'react';
import EuaLabel from '../eua-label';
import classNames from 'classnames';
import LegContainer from '../../leg-container';
import { isLoadOrDischargePort } from '../../../../../constants/enums/voyage-leg';

interface TimelineLegProps {
  openPosition: IOpenPosition;
  voyage: IVoyage;
  leg: IVoyageLeg;
  index: number;
  isLast: boolean;
}

const TimelineLeg = ({ openPosition, voyage, index, isLast, leg }: TimelineLegProps) => {
  return (
    <LegContainer
      euaLabel={<EuaLabel index={index} voyage={voyage} openPosition={openPosition} />}
      legClassName={classNames('voyage-leg', 'voyage-time-leg', 'timeline-voyage-leg', {
        'voyage-leg-last': isLast,
      })}
      dotClassName={classNames('dot', {
        'solid-dot': isLoadOrDischargePort(leg.type),
      })}
    />
  );
};

export default TimelineLeg;
