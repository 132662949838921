const LOAD_PORT = 'LOAD_PORT';
const DISCHARGE_PORT = 'DISCHARGE_PORT';
const VIA_PORT = 'VIA_PORT';
const BUNKER_PORT = 'BUNKER_PORT';
const VESSEL_OPEN_LOCATION = 'VESSEL_OPEN_LOCATION';
const UNKNOWN = 'UNKNOWN';

const markerTypes = {
  LOAD_PORT,
  DISCHARGE_PORT,
  VIA_PORT,
  BUNKER_PORT,
  VESSEL_OPEN_LOCATION,
  UNKNOWN,
};

export default markerTypes;
