import React from 'react';
import { findDisplayItemByMarketSegmentId } from 'constants/market-segments';
import * as RedirectManager from 'utilities/redirect-manager';

export const WorkbookNameRenderer = (props) => {
  const workbook = props.data;
  const url = `workbook/${workbook.id}`;
  const name = props.value;

  const marketSegmentDisplayItem = findDisplayItemByMarketSegmentId(workbook.marketSegmentId);

  const symbol = marketSegmentDisplayItem.iconSymbol;
  const background = marketSegmentDisplayItem.background;
  const colour = marketSegmentDisplayItem.color;
  const storeURLInLocalStorage = () => {
    RedirectManager.storeURL(url);
  };
  return (
    <span>
      <a
        tabIndex="0"
        href={'#/' + url}
        className="workbook-card__cell workbook-card__cell--name"
        onMouseDown={storeURLInLocalStorage}
      >
        <span className={'search-panel__symbol'} style={{ background: background, color: colour }}>
          <b>{symbol}</b>
        </span>{' '}
        {name}
      </a>
    </span>
  );
};
