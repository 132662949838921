import {
  BUNKER_QUANTITY_CHANGED,
  BUNKER_LOAD_PRICE,
  BUNKER_PRICE_CHANGED,
  SET_WORKSHEET_BUNKER_PRICES,
} from 'constants/action-types/worksheet/bunkers';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import {
  VOYAGE_LOCATION_DELETED,
  VOYAGE_TYPE_CHANGED,
} from 'constants/action-types/worksheet/voyage';
import { APPLY_PRICES_TO_BUNKERS_ON_BOARD } from 'constants/action-types/bunker-ports';
import { emptyGuid } from 'utilities/guid';
import cloneDeep from 'lodash/cloneDeep';
import {
  FUEL_GRADE_180CST,
  FUEL_GRADE_380CST,
  FUEL_GRADE_LSMGO,
  FUEL_GRADE_ULSFO,
  FUEL_GRADE_VLSFO,
  FUEL_GRADE_MDO,
  FUEL_GRADE_MGO,
  FUEL_GRADE_LNG,
} from 'constants/enums/fuel-grades';
import { BUNKER } from 'constants/enums/voyage-leg';
import { bunkersOnBoardId } from 'constants/enums/bunkers';

const emptyBunker = (key: WorksheetBunkersItemKey): IBunkersViewModel => {
  return {
    key,
    fuelGrades: [
      { key: FUEL_GRADE_380CST.key, quantity: 0, price: 0 },
      { key: FUEL_GRADE_180CST.key, quantity: 0, price: 0 },
      { key: FUEL_GRADE_MDO.key, quantity: 0, price: 0 },
      { key: FUEL_GRADE_MGO.key, quantity: 0, price: 0 },
      { key: FUEL_GRADE_LSMGO.key, quantity: 0, price: 0 },
      { key: FUEL_GRADE_ULSFO.key, quantity: 0, price: 0 },
      { key: FUEL_GRADE_VLSFO.key, quantity: 0, price: 0 },
      { key: FUEL_GRADE_LNG.key, quantity: 0, price: 0 },
    ],
    priceIssuedDate: null,
  };
};

const initialState: BunkerExpenses = {
  bunkers: [emptyBunker(emptyGuid)],
};

export default (state: BunkerExpenses = initialState, action): BunkerExpenses => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return getBunkersViewModelFromBunkersDto(action.payload.bunkers);
    case BUNKER_QUANTITY_CHANGED:
      return handleFuelGradeQuantityChanged(state, action.payload);
    case BUNKER_PRICE_CHANGED:
      return handleFuelGradePriceChanged(state, action.payload);
    case SET_WORKSHEET_BUNKER_PRICES:
      return { bunkers: action.payload };
    case VOYAGE_TYPE_CHANGED:
      return handleVoyageLegChanged(state, action);
    case VOYAGE_LOCATION_DELETED:
      return handleBunkerDeleted(state, action);
    case BUNKER_LOAD_PRICE:
      return handleBunkerLoadPrice(state, action);
    case APPLY_PRICES_TO_BUNKERS_ON_BOARD:
      return applyPriceToBunkersOnBoard(state, action);
    default:
      return state;
  }
};

const applyPriceToBunkersOnBoard = (state: BunkerExpenses, action): BunkerExpenses => {
  const {
    cst380Price,
    cst180Price,
    mdoPrice,
    mgoPrice,
    lsMgoPrice,
    vlsfoFiveTenthPercPrice,
    ulsfoOneTenthPercPrice,
    lngPrice,
    dateIssued,
    locationName,
    locationId,
  } = action.payload;

  let bunker = getBunkerById(state, bunkersOnBoardId);

  getFuelGradeById(bunker, FUEL_GRADE_380CST.key).price = cst380Price || 0;
  getFuelGradeById(bunker, FUEL_GRADE_180CST.key).price = cst180Price || 0;
  getFuelGradeById(bunker, FUEL_GRADE_MDO.key).price = mdoPrice || 0;
  getFuelGradeById(bunker, FUEL_GRADE_MGO.key).price = mgoPrice || 0;
  getFuelGradeById(bunker, FUEL_GRADE_LSMGO.key).price = lsMgoPrice || 0;
  getFuelGradeById(bunker, FUEL_GRADE_ULSFO.key).price = ulsfoOneTenthPercPrice || 0;
  getFuelGradeById(bunker, FUEL_GRADE_VLSFO.key).price = vlsfoFiveTenthPercPrice || 0;
  getFuelGradeById(bunker, FUEL_GRADE_LNG.key).price = lngPrice || 0;
  bunker.priceIssuedDate = dateIssued;
  bunker.locationName = locationName;
  bunker.locationId = locationId;

  return { ...state };
};

const handleBunkerLoadPrice = (state: BunkerExpenses, action): BunkerExpenses => {
  const { voyageLegId, newFuelGradePrices, priceIssuedDate, delivery, isTanker } = action.payload;
  const bunker = state.bunkers.find((bunker) => bunker.key === voyageLegId);

  if (!bunker) {
    return state;
  }

  if (action.payload.isTanker) {
    const bunkerOnBoard = state.bunkers.find((bunker) => bunker.key === bunkersOnBoardId);
    state.bunkers.filter((bunker) => bunker.key !== bunkersOnBoardId).forEach((bunker) => {
      bunker.fuelGrades.forEach((fuelGrade) => {
        fuelGrade.price = bunkerOnBoard.fuelGrades.find(
          (newFuelGrade) => newFuelGrade.key === fuelGrade.key
        ).price;
      });
    });
  } else {
    bunker.fuelGrades.forEach((fuelGrade) => {
      fuelGrade.price = newFuelGradePrices.find(
        (newFuelGrade) => newFuelGrade.key === fuelGrade.key
      ).price;
    });
  }

  bunker.priceIssuedDate = priceIssuedDate;
  bunker.delivery = delivery;

  return { ...state };
};

export const getBunkersViewModelFromBunkersDto = (bunkers): BunkerExpenses => {
  return {
    bunkers: bunkers.map((bunker) => ({
      key: bunker.voyageLegId,
      fuelGrades: [
        {
          key: FUEL_GRADE_380CST.key,
          quantity: bunker.csT380.quantity,
          price: bunker.csT380.price,
        },
        {
          key: FUEL_GRADE_180CST.key,
          quantity: bunker.csT180.quantity,
          price: bunker.csT180.price,
        },
        {
          key: FUEL_GRADE_MDO.key,
          quantity: bunker.mdo.quantity,
          price: bunker.mdo.price,
        },
        {
          key: FUEL_GRADE_MGO.key,
          quantity: bunker.mgo.quantity,
          price: bunker.mgo.price,
        },
        {
          key: FUEL_GRADE_LSMGO.key,
          quantity: bunker.lsmgo.quantity,
          price: bunker.lsmgo.price,
        },
        {
          key: FUEL_GRADE_ULSFO.key,
          quantity: bunker.ulsfo ? bunker.ulsfo.quantity : 0,
          price: bunker.ulsfo ? bunker.ulsfo.price : 0,
        },
        {
          key: FUEL_GRADE_VLSFO.key,
          quantity: bunker.vlsfo ? bunker.vlsfo.quantity : 0,
          price: bunker.vlsfo ? bunker.vlsfo.price : 0,
        },
        {
          key: FUEL_GRADE_LNG.key,
          quantity: bunker.lng ? bunker.lng.quantity : 0,
          price: bunker.lng ? bunker.lng.price : 0,
        },
      ],
      locationName: bunker.locationName || '',
      locationId: bunker.locationId || null,
      priceIssuedDate: bunker.priceIssuedDate || null,
    })),
  };
};

const handleFuelGradeChanged = (
  state: BunkerExpenses,
  payload,
  fuelGradeUpdater
): BunkerExpenses => {
  const newState = cloneDeep(state);
  const { bunkerId, fuelGradeId } = payload;

  if (payload.isTanker) {
    state.bunkers.forEach((oldBunker) => {
      let bunker = getBunkerById(newState, oldBunker.key);
      let fuelGrade = bunker && getFuelGradeById(bunker, fuelGradeId);

      if (fuelGrade) {
        fuelGradeUpdater(newState, bunker, fuelGrade, payload);
        return newState;
      }

      return state;
    });
    return newState;
  }

  let bunker = getBunkerById(newState, bunkerId);
  let fuelGrade = bunker && getFuelGradeById(bunker, fuelGradeId);

  if (fuelGrade) {
    fuelGradeUpdater(newState, bunker, fuelGrade, payload);
    return newState;
  }

  return state;
};

const handleFuelGradeQuantityChanged = (state: BunkerExpenses, payload): BunkerExpenses => {
  return handleFuelGradeChanged(state, payload, (newState, bunker, fuelGrade, { newQuantity }) => {
    fuelGrade.quantity = newQuantity;
  });
};

const handleFuelGradePriceChanged = (state: BunkerExpenses, payload): BunkerExpenses => {
  return handleFuelGradeChanged(state, payload, (newState, bunker, fuelGrade, { newPrice }) => {
    fuelGrade.price = newPrice;
    /**
     * We reset the locationId when the user changes the price of any fuel grade.
     * The purpose is to prevent an update of prices for BOB through the refresh bunker(s) buttons functionality.
     * It affects BOB in particular as this would have a valid locationId; other bunkers will have null already - and their locationId is retrived from the voyage (see getLocationId function).
     */
    bunker.locationId = null;
  });
};

const bunkerExists = (state: BunkerExpenses, bunkerId: WorksheetBunkersItemKey): boolean =>
  !!getBunkerById(state, bunkerId);

const getBunkerById = (
  state: BunkerExpenses,
  bunkerId: WorksheetBunkersItemKey
): IBunkersViewModel => state.bunkers.find((bunker) => bunker.key === bunkerId);

const getFuelGradeById = (bunker: IBunkersViewModel, fuelGradeId: FuelGradeId): IFuelGradeEntry =>
  bunker.fuelGrades.find((fuelGrade) => fuelGrade.key === fuelGradeId);

const handleVoyageLegChanged = (state: BunkerExpenses, action): BunkerExpenses => {
  const voyageEntryId: VoyageEntryId = action.portId;
  const isVoyageTypeBunker = action.payload.type === BUNKER.key;

  let changedState = isVoyageTypeBunker
    ? tryAddBunker(state, voyageEntryId)
    : tryDeleteBunker(state, voyageEntryId);

  return changedState ? changedState : state;
};

const tryAddBunker = (
  state: BunkerExpenses,
  bunkerId: WorksheetBunkersItemKey
): BunkerExpenses | false => {
  return (
    !bunkerExists(state, bunkerId) && {
      bunkers: [...state.bunkers, emptyBunker(bunkerId)],
    }
  );
};

const handleBunkerDeleted = (state: BunkerExpenses, action): BunkerExpenses =>
  tryDeleteBunker(state, action.portId) || state;

const tryDeleteBunker = (
  state: BunkerExpenses,
  bunkerId: WorksheetBunkersItemKey
): BunkerExpenses | false => {
  return (
    bunkerExists(state, bunkerId) && {
      bunkers: state.bunkers.filter((bunker) => bunker.key !== bunkerId),
    }
  );
};
