import React from 'react';
import './styles.scss';
import { trackClickEvent } from '../../diagnostics/calc-trackevents';

export function Checkbox({ id, label, onChange, value, disabled, diagnosticId, isReadonly }) {
  const onClick = React.useCallback(
    (evt) => {
      onChange(value);
      checkboxChangeClickEvent(diagnosticId);
    },
    [value, onChange]
  );

  const checkboxChangeClickEvent = (diagnosticId) => {
    trackClickEvent(diagnosticId);
  };

  return (
    <div className="ve-checkbox" data-status={value} data-disabled={disabled}>
      <input
        disabled={disabled || isReadonly}
        type="checkbox"
        id={id || 'basic-checkbox-id'}
        onChange={onClick}
        checked={value}
      />
      <label htmlFor={id || 'basic-checkbox-id'}>{label}</label>
    </div>
  );
}
