import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selector } from 'modules/bunker-prices/selector';
import { bunkerPriceChanged } from 'actions/worksheet/bunkers';
import { Header } from 'components/headers';
import { bunkersOnBoardId } from 'constants/enums/bunkers';

import 'modules/bunker-prices/styles.scss';
import CalcInput from 'components/calc-input';
import { isFeatureEnabled } from '../../config/feature-control';

export const BunkerPrices: FC = ({
  worksheetId,
  mainFuelGradeId,
  secaFuelGradeId,
  generatorFuelGradeId,
  mainFuelLabel,
  secaFuelLabel,
  generatorFuelLabel,
  mainFuelPrice,
  secaFuelPrice,
  generatorFuelPrice,
  bunkerPriceChanged,
}) => {
  const onBunkerPriceChanged = (fuelGradeId, newPrice) => {
    const payload = {
      bunkerId: bunkersOnBoardId,
      fuelGradeId,
      newPrice,
      isTanker: true, // this component is only used in wet lite so we can have true here
    };
    bunkerPriceChanged(payload, worksheetId);
  };

  return (
    <div>
      <Header text="Bunker Prices" />
      <div>
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">{secaFuelLabel}</label>
          <div>
            <CalcInput
              userSpecifiedValue={secaFuelPrice}
              isEditable
              unit="$"
              unitPrecedesValue
              maxDecimalDigits={2}
              trimDecimal={false}
              className="bunker-price"
              onInputChange={(newPrice) => onBunkerPriceChanged(secaFuelGradeId, newPrice)}
              name={secaFuelLabel.toString()}
            />
          </div>
        </div>
        {mainFuelGradeId !== secaFuelGradeId && (
          <>
            <div className="worksheet-section__property">
              <label className="worksheet-section__property-label">{mainFuelLabel}</label>
              <div>
                <CalcInput
                  userSpecifiedValue={mainFuelPrice}
                  isEditable
                  unit="$"
                  unitPrecedesValue
                  maxDecimalDigits={2}
                  trimDecimal={false}
                  className="bunker-price"
                  onInputChange={(newPrice) => onBunkerPriceChanged(mainFuelGradeId, newPrice)}
                  name={mainFuelLabel.toString()}
                />
              </div>
            </div>
          </>
        )}
        {isFeatureEnabled('wetCargoLiteMultiLeg') &&
          generatorFuelGradeId !== mainFuelGradeId &&
          generatorFuelGradeId !== secaFuelGradeId && (
            <>
              <div className="worksheet-section__property">
                <label className="worksheet-section__property-label">{generatorFuelLabel}</label>
                <div>
                  <CalcInput
                    userSpecifiedValue={generatorFuelPrice}
                    isEditable
                    unit="$"
                    unitPrecedesValue
                    maxDecimalDigits={2}
                    trimDecimal={false}
                    className="bunker-price"
                    onInputChange={(newPrice) =>
                      onBunkerPriceChanged(generatorFuelGradeId, newPrice)
                    }
                    name={mainFuelLabel.toString()}
                  />
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ bunkerPriceChanged }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BunkerPrices);
