import React, { Component } from 'react';
import { Grid } from 'components/grid';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { getDefaultGridOptions } from './default-grid-options';
import { loadMyWorkbooks } from 'actions/workbook';
import './styles.scss';
import ProgressBar, { Size } from 'components/progress-bar';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
export class WorksheetsGrid extends Component {
  constructor(props) {
    super(props);
    const gridOptions = getDefaultGridOptions(
      props,
      this.props.loadMyWorkbooks,
      this.props.closePanel
    );

    this.state = {
      gridOptions,
    };

    gridOptions.onGridReady = ({ api }) => {
      this.agGridApi = api;
      this.updateGrid(this.agGridApi);
    };
  }
  render() {
    return (
      <div>
        {this.props.loading ? (
          <div className="progress-bar">
            <ProgressBar size={Size.MEDIUM} />
          </div>
        ) : (
          <Grid
            getRowId={(params) => params.data.worksheetId}
            className={'grid-height ' + this.props.classNames}
            gridOptions={this.state.gridOptions}
            rowData={this.props.worksheets}
            suppressRowTransform={true}
            overlayLoadingTemplate={'<span></span>'}
            overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No match found</span>'}
            reactiveCustomComponents
          />
        )}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.agGridApi) return;
    this.updateGrid(this.agGridApi);
  }

  updateGrid(agGridApi) {
    if (!agGridApi.destroyCalled) {
      if (this.props.defaultMessage) {
        agGridApi.showLoadingOverlay();
      }
      agGridApi.forEachNode((node, index) => {
        if (node.data.worksheetId === this.props.worksheetId) {
          node.setSelected(true);
        }
      });
      if (agGridApi.getDisplayedRowCount() !== 0) {
        agGridApi.sizeColumnsToFit();
      }
    }
  }
}

const mapStateToProps = createSelector(getActiveWorksheetId, (worksheetId) => ({
  worksheetId,
}));

export default connect(mapStateToProps, {
  loadMyWorkbooks,
})(WorksheetsGrid);
