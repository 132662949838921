import React, { Component, createRef } from 'react';

import isNil from 'lodash/isNil';
import classNames from 'classnames';

import './styles.scss';

export class EditableLabel extends Component {
  constructor(props) {
    super(props);

    this.textbox = createRef();

    this.state = {
      isEditing: this.props.isEditing || false,
      text: this.props.text || '',
    };
  }

  toggleEdit = (e) => {
    if (this.props.isDisabled) {
      return;
    }

    e.stopPropagation();
    if (this.state.editing) {
      this.cancel();
    } else {
      this.edit();
    }
  };

  edit = () => {
    this.setState(
      {
        isEditing: true,
      },
      () => {
        this.textbox.current.focus();
        this.textbox.current.select();
      }
    );
  };

  cancel = () => {
    this.setState({
      isEditing: false,
    });
  };

  handleSave = () => {
    const isValueValid = this.isValueValid();
    const newState = {
      isEditing: false,
    };

    if (isValueValid === false) {
      newState.text = this.props.text;
    }

    this.setState(newState, () => {
      if (isValueValid && this.hasValueChanged() && this.props.onChange) {
        this.props.onChange(this.state.text);
      }
    });
  };

  hasValueChanged = () => {
    return this.props.value !== this.state.text;
  };

  isValueValid = () => {
    const { text } = this.state;

    if (isNil(text)) {
      return false;
    }

    const trimmedText = text.trim();
    return trimmedText.length !== 0;
  };

  handleChange = (e) => {
    this.setState({
      text: e.target.value,
    });
  };

  handleRevert = () => {
    this.setState({
      text: this.props.text,
    });
    this.cancel();
  };

  handleKeyDown = (e) => {
    const { key } = e;
    switch (key) {
      case 'Enter':
        this.handleSave();
        break;
      case 'Escape':
        this.handleRevert();
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    if (this.textbox.current) {
      this.textbox.current.focus();
      this.textbox.current.select();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.text !== prevProps.text && this.state.text !== this.props.text) {
      this.setState(() => ({ text: this.props.text }));
    }
  }

  render() {
    const { inputClassName, labelClassName } = this.props;

    if (this.state.isEditing) {
      const inputClasses = classNames('editable-label__textbox', inputClassName);

      return (
        <input
          type="text"
          data-testid={this.props['data-testid'] && `${this.props['data-testid']}__input`}
          className={inputClasses}
          ref={this.textbox}
          value={this.state.text}
          onChange={this.handleChange}
          onBlur={this.handleSave}
          onKeyDown={this.handleKeyDown}
        />
      );
    }

    const labelClasses = classNames('editable-label', labelClassName);

    return (
      <label
        className={labelClasses}
        aria-readonly={this.props.isDisabled}
        onClick={this.toggleEdit}
        data-testid={this.props['data-testid'] && `${this.props['data-testid']}__label`}
      >
        {this.state.text}
      </label>
    );
  }
}
