import { compare as createNaturalSortCompareFn } from 'natural-orderby';

import { WorkbookHeader } from 'modules/search-panel/components/workbooks-grid/components/workbook-header';
import { WorkbookNameRenderer } from 'modules/search-panel/components/workbooks-grid/components/workbook-name-renderer';
import { WorkbookWorksheetCountRenderer } from 'modules/search-panel/components/workbooks-grid/components/workbook-worksheet-count-renderer';
import { UpdatedDateRenderer } from 'modules/search-panel/components/workbooks-grid/components/updated-date-renderer';

import UpdatedDateFormatter from 'modules/search-panel/components/workbooks-grid/grid-value-formatters/updated-date-formatter';
import { WorkbookNameCellEditor } from 'modules/search-panel/components/workbooks-grid/components/workbook-name-cell-editor';

export const nameColumnId = 'name';

export const getDefaultGridOptions = (loadMyWorkbooksFunc) => {
  const suppressDefaultAgGridEvents = (params) => {
    const key = params.event.key;
    return key === 'Enter' || key === 'Tab';
  };

  return {
    rowHeight: 32,
    headerHeight: 32,
    animateRows: true,
    suppressCellFocus: true,
    suppressHorizontalScroll: true,
    accentedSort: true,
    defaultColDef: {
      sortable: true,
      resizable: false,
      filter: false,
      suppressMovable: true,
      headerComponent: WorkbookHeader,
    },
    columnDefs: [
      {
        headerName: 'Name',
        colId: nameColumnId,
        field: 'name',
        cellRenderer: WorkbookNameRenderer,
        width: 331,
        comparator: createNaturalSortCompareFn(),
        suppressKeyboardEvent: (params) => {
          return suppressDefaultAgGridEvents(params);
        },
        editable: true,
        cellEditor: WorkbookNameCellEditor,
        cellEditorParams: { onSuccess: loadMyWorkbooksFunc },
      },
      {
        headerName: 'Sheets',
        valueGetter: 'data.worksheets.length',
        cellRenderer: WorkbookWorksheetCountRenderer,
        width: 51,
      },
      {
        headerName: 'Last update',
        field: 'updatedDate',
        valueFormatter: UpdatedDateFormatter,
        getQuickFilterText: () => '', // Exclude the date from being filtered because the date contains '01', and users have many worksheets named like that due to cloning and initial absence of the rename feature.
        cellRenderer: UpdatedDateRenderer,
        width: 71,
        sort: 'desc',
      },
    ],
    sortingOrder: ['asc', 'desc'],
    getRowClass: () => 'workbook-card__row',
  };
};
