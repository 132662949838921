//-- Run the below query against the Sea/net production database and replace the locations below.
//-- Please confirm that you want all the ECA zones selected; currently it is filtering on < 0.5.
//-- If there are ECA zones which you do not wish to be included the query will have to be ameneded.

//-- Find the ports within the locations
//-- SELECT Concat('/*', pl.displayname, '; ', cl.displayname, '*/ ''',
//-- cl.locationid, ''','
//-- ) AS code
//-- FROM   location pl
//-- INNER JOIN locationtolocationid ltl
//--         ON pl.locationid = ltl.parentlocationid
//-- INNER JOIN location cl
//--         ON cl.locationid = ltl.childlocationid
//-- INNER JOIN locationtype plt
//--         ON pl.locationtypeid = plt.locationtypeid
//-- INNER JOIN locationtype clt
//--         ON cl.locationtypeid = clt.locationtypeid
//-- WHERE  pl.locationid IN (SELECT l.locationid]
//--                   FROM   location l
//--                          JOIN locationtype lt
//--                            ON l.locationtypeid = lt.locationtypeid
//--                          JOIN extensionfield ef
//--                            ON ef.locationtypeid = lt.locationtypeid
//--                          JOIN locationextensiondata led
//--                            ON led.extensionfieldid = ef.extensionfieldid
//--                               AND led.locationid = l.locationid
//--                   WHERE  lt.displayname = 'ECA (active)'
//--                          AND l.isvoid = 0
//--                          AND ef.displayname = 'SOx Limit Percent'
//--                          AND Cast(data AS DECIMAL) < 0.5)
//-- AND clt.isvoid = 0
//-- AND cl.isvoid = 0
//-- AND clt.displayname = 'Ports'
//-- ORDER  BY pl.displayname,
//--    cl.displayname

export default [
  /*Baltic Sea; Aalborg*/ 'EDEE9042-6134-444D-957D-CD4B33E96BCF',
  /*Baltic Sea; Aarhus*/ 'C0942F79-EDE8-4D26-9705-9DD9469EC93F',
  /*Baltic Sea; Aarosund*/ '9F085FA3-C93B-41DF-BAF7-9A5CEC7C01EA',
  /*Baltic Sea; Abenra*/ '3145C43F-F156-4143-8CC9-8EC3A8C238D3',
  /*Baltic Sea; Aeroskobing*/ '69EC2700-9ADB-40F9-BC1E-4AE47B102021',
  /*Baltic Sea; Ahus*/ '714C51B4-91CF-4107-83F4-6CAA1045E1A0',
  /*Baltic Sea; Allinge*/ '7276F184-179A-4B90-9D42-CA242D7FF86C',
  /*Baltic Sea; Anholt*/ 'D3569EB5-FA01-4BE8-8BB7-246B0793176C',
  /*Baltic Sea; Asnaes*/ 'B1F21EC6-398E-4D73-87C4-DB5DC2DFD9D6',
  /*Baltic Sea; Assens*/ '95E70FC9-FF1E-4B27-9BB4-537E7D447118',
  /*Baltic Sea; Augustenborg*/ '0C8CCE98-E29A-40E2-8918-3B3B051EB1F9',
  /*Baltic Sea; Avedore*/ '961BB5B6-8394-44B5-AB33-0EC96216C862',
  /*Baltic Sea; Backviken*/ 'F7175F6A-C7DC-44DF-A537-93C198E462C6',
  /*Baltic Sea; Bagenkop*/ '3F8C01ED-8A75-46DF-AE66-A8EFAD3A1952',
  /*Baltic Sea; Ballen*/ '219317F9-10A4-4FC1-93B5-045624B4C4C5',
  /*Baltic Sea; Balsta*/ '4A4DB670-9E84-4CFC-97CB-F90C1F70DD7D',
  /*Baltic Sea; Baltiysk*/ '5C85DF9F-4097-4829-B0B3-C72979A07886',
  /*Baltic Sea; Bandholm*/ '8DDF410A-7DF9-4F08-9872-A1BABAD960CB',
  /*Baltic Sea; Barhoft*/ 'BB84A4A4-2908-4B2B-8F5B-78B54C170ECC',
  /*Baltic Sea; Barth*/ '959A7447-E8D7-4B2A-B375-6BA8609352CB',
  /*Baltic Sea; Berghamn Port*/ '5BB5B84D-6730-483F-9F43-35BF64C651F8',
  /*Baltic Sea; Bergkvara*/ '9BAF2692-5B27-4328-AEA7-F4C9E9FA2C97',
  /*Baltic Sea; Bogense*/ 'AA0CBE58-3748-4677-A765-D682CB9D0860',
  /*Baltic Sea; Borgholm*/ '6D802C5E-5BF8-4F2E-A297-3F7D2B3B37CA',
  /*Baltic Sea; Brusnichnoye*/ 'AC184207-40E9-446A-BCD5-BD82DEC6E32B',
  /*Baltic Sea; Burg Auf Fehmarn*/ 'B4FCF7E2-EB62-4021-8D41-F767F0C2C1CF',
  /*Baltic Sea; Butinge Terminal*/ '58D2F7A1-C5E1-4430-BD88-F297EF573599',
  /*Baltic Sea; Byxelkrok*/ '8FF856FA-84AA-4D2C-8171-E34CE961029A',
  /*Baltic Sea; Copenhagen*/ 'FD7CB546-1B16-427B-94C4-F3E2BB614375',
  /*Baltic Sea; Dalaro*/ '7E09429C-3C12-4C15-8553-34E8E6C72DA2',
  /*Baltic Sea; Darlowo*/ '05716FCE-E36C-447A-83B7-A7F23AA9B616',
  /*Baltic Sea; Degerhamn*/ 'CC7E2494-7427-4CD4-83B9-728BEEB191E1',
  /*Baltic Sea; Dirhami*/ '996C5F1F-DBE9-48A8-AA86-1A30A26F2DE9',
  /*Baltic Sea; Docksta*/ 'CCDEE303-6FDB-4ACE-BA8C-5891AE6E6441',
  /*Baltic Sea; Donso*/ '9449DDA1-25C5-452C-9BF1-620D39AF7791',
  /*Baltic Sea; Dragor*/ '53E87BCE-BC84-4CBB-9A97-15ADC26F6FD5',
  /*Baltic Sea; Ebeltoft Vig*/ '16100DCB-C73B-4C75-A6C8-9340481AFB65',
  /*Baltic Sea; Eckernforde*/ '302E9F02-594D-4A20-A525-21FFCA76874A',
  /*Baltic Sea; Egernsund*/ 'E2637623-FA50-4951-92E4-7DF6A30E5573',
  /*Baltic Sea; Endelave*/ 'EA60518C-A534-45E1-9809-88A3FAC22339',
  /*Baltic Sea; Ensted*/ '98C8DB46-EB95-46A4-847C-599085667443',
  /*Baltic Sea; Faborg*/ 'A3E0EB74-7AE9-4938-90EA-7D7946EFE7A5',
  /*Baltic Sea; Fakse Ladeplads Havn*/ '1E0736C6-D1BC-4820-9CE4-945E8AF65AF0',
  /*Baltic Sea; Falkenberg*/ '7A518F5C-83B1-48D9-A166-BF35E40687C3',
  /*Baltic Sea; Farosund*/ 'EDFF5D29-4381-4812-8E00-92C6CFC2D977',
  /*Baltic Sea; Fittja*/ '92DF1F38-E3F9-4546-8CC8-52183102FD87',
  /*Baltic Sea; Flensburg*/ 'BDB65C19-EBA3-443E-BC7D-9BF0237BCC51',
  /*Baltic Sea; Forsmark*/ '5497A41C-4AA1-4588-8C52-2ED77E3A66D6',
  /*Baltic Sea; Fredericia*/ '9EBFE537-6D57-46E3-849B-2CE2FE57CAD8',
  /*Baltic Sea; Frederikshavn*/ 'FC7AC867-7566-4CEE-86FF-09D8333AF9CA',
  /*Baltic Sea; Frederiksvaerk*/ '6DDAF176-58D1-4CE3-9A9D-9213110566F8',
  /*Baltic Sea; Furusund*/ '3B7EC7E7-0952-4A05-8752-7891A5F2EF69',
  /*Baltic Sea; Galtby*/ '22C81EE3-48CE-4293-B125-CC4D98F09F61',
  /*Baltic Sea; Gavle*/ 'B1D332A0-3506-46BF-89E3-8AC58EDD2C7D',
  /*Baltic Sea; Gdansk*/ '88421FFF-EA06-446D-B1BE-34CEEDB9667F',
  /*Baltic Sea; Gdynia*/ 'BECE6898-6004-49CF-BF19-CC57F3CEF341',
  /*Baltic Sea; Gedser*/ '118F3813-B93E-4774-BFB0-86F23E05BE91',
  /*Baltic Sea; Gelting*/ '421FBA02-1E69-4B35-BEED-C2F07DE36493',
  /*Baltic Sea; Gilleleje*/ '2031B117-4821-4E3F-8984-95AF418E2CD5',
  /*Baltic Sea; Glatved*/ 'F33252FE-6609-48D4-959F-6F661B9A4658',
  /*Baltic Sea; Gothenburg*/ '977AA687-6306-4605-AE21-DE0033E99214',
  /*Baltic Sea; Graasten*/ '46F9F5BC-6626-44E1-BE1D-E4CBCB0B9456',
  /*Baltic Sea; Grenaa Havn*/ '7B8F9FE9-2B77-4817-9AE6-6A5A5819001B',
  /*Baltic Sea; Grisslehamn*/ 'D246A402-E373-4405-84F3-A9AC2CE535D4',
  /*Baltic Sea; Gromitz*/ 'D633C392-25AA-4BDD-8DEC-F447EBB83AB6',
  /*Baltic Sea; Gudhjem*/ 'E1BC5B2A-ADC1-488A-8AE0-5C53108270D2',
  /*Baltic Sea; Guldborg*/ '4B869AA9-E999-4C79-B444-8020D10C70D4',
  /*Baltic Sea; Haapsalu*/ '6EFD4BA3-7209-4F55-87A9-B8A93EB7FD07',
  /*Baltic Sea; Haderslev*/ 'AE4C15CD-06A4-4AB2-B614-7A56BE9E130D',
  /*Baltic Sea; Hadsund*/ 'DC41A89E-5E70-46CB-AEAA-A1D33503D563',
  /*Baltic Sea; Hallstavik*/ 'C0C3C6D6-B189-48D2-AE86-5A94DA5F893D',
  /*Baltic Sea; Halmstad*/ 'FAD2DFE4-A9AC-4E58-842F-71FAB23530B7',
  /*Baltic Sea; Hals*/ '10A0C1CA-4897-4B58-A710-605A451400DE',
  /*Baltic Sea; Halsskov*/ '0964F949-E589-4A45-A25F-67355B8E1FFE',
  /*Baltic Sea; Hamina*/ '27C0FDF7-5A82-4E4F-9935-03B8769F3F49',
  /*Baltic Sea; Hammerhavn*/ 'F4039012-6DB3-4C53-BB69-03F2EEC36A6B',
  /*Baltic Sea; Hanko*/ '0EFD972E-00EB-401E-84D0-E67E725EABD0',
  /*Baltic Sea; Hardeshoj*/ 'A8A05F6D-5931-4101-88A7-A42EE4C22C0B',
  /*Baltic Sea; Hargshamn*/ 'C1BDE71F-85BF-4912-9139-976A7693D9F3',
  /*Baltic Sea; Harnosand*/ 'A86A03CD-E6A7-41F4-BB43-210F4EA32E02',
  /*Baltic Sea; Hasle*/ '9779A655-CFD0-4979-B6A9-2FC84C28873B',
  /*Baltic Sea; Hasselby*/ '696EF92A-0ECA-4379-89CC-A098CF4EE61F',
  /*Baltic Sea; Heiligenhafen*/ '8C213141-C2E7-4DDE-B150-C8AE9D9DF59E',
  /*Baltic Sea; Helsingborg*/ '075DADCD-64EC-4D0C-83E8-35D66192351C',
  /*Baltic Sea; Helsingor*/ '27725126-AB9B-4762-95E8-EBBF379B7C5D',
  /*Baltic Sea; Helsinki*/ 'BC177207-D410-4D1C-8CB3-A25C0935DACA',
  /*Baltic Sea; Heltermaa*/ 'A8632A0E-573D-4E4C-94B3-9E0A8E122B8C',
  /*Baltic Sea; Heringsdorf*/ '0C730EBC-94CB-46ED-B335-9B516A98AC5E',
  /*Baltic Sea; Hobro*/ 'FE8D8D71-7B5B-4341-9913-2018438A4396',
  /*Baltic Sea; Hoganas*/ 'EEC9799A-7947-4AA8-B20D-8158E8EE117D',
  /*Baltic Sea; Holbaek*/ 'BB1EC629-CD31-4549-848B-CD2E4D48EED8',
  /*Baltic Sea; Horsens*/ 'D398E636-1E62-4ED7-9EF0-CB33D709B6C2',
  /*Baltic Sea; Hou Harbour*/ '5B5473D7-5197-4EDC-B9C9-95E096E68CF1',
  /*Baltic Sea; Hudiksvall*/ '385C16D3-ED1A-416F-A8CC-CAC361D13760',
  /*Baltic Sea; Hundested*/ 'C2D2E4A9-E39A-4D2C-9BF6-4325F3A58929',
  /*Baltic Sea; Husum*/ '9323D29D-BDCE-44F8-A1F4-1FB77C9D4C3E',
  /*Baltic Sea; Iggesund*/ '124A7272-C5C1-49B3-A2BC-BF4489E99BEE',
  /*Baltic Sea; Inkoo*/ '5A9F9FF1-9DEF-4A7B-88F8-4F3F41A12558',
  /*Baltic Sea; Juelsminde*/ '9881698B-CDED-4FA1-A0FF-28259A189055',
  /*Baltic Sea; Kaliningrad*/ 'B9263C1F-41CC-4856-B473-5D0E48406376',
  /*Baltic Sea; Kalmar*/ '391CC2D2-36A6-4CF1-BCDA-ACA9654DF043',
  /*Baltic Sea; Kalmarsand*/ 'A07F08F5-0A22-4F09-BA62-AD3063B23520',
  /*Baltic Sea; Kalundborg*/ 'DBFF5F91-49D8-468D-9468-8A215B9131F6',
  /*Baltic Sea; Kanonerskiy Island*/ 'A4245838-E5B4-4592-B72E-18BF9AC5406E',
  /*Baltic Sea; Kantvik*/ 'A032BA2F-E701-47CC-ABC7-35CDC3148353',
  /*Baltic Sea; Kapellskar*/ '06E437BC-0FE4-43BC-A35D-D32D70EE7CD8',
  /*Baltic Sea; Kappeln*/ 'FCC5BEA3-4902-4B10-91FD-AC73F26DCE2E',
  /*Baltic Sea; Kappelshamn*/ 'C034D955-AA0A-4EAB-8C6D-24CBA0D27D76',
  /*Baltic Sea; Karlshamn*/ '7F205D00-3C3F-43B0-9C00-4C5E2ED12E00',
  /*Baltic Sea; Karlskrona*/ '3B6F9985-4C5B-449C-AC83-FFCD9819A565',
  /*Baltic Sea; Karrebaeksminde*/ '4C760195-1272-4F2E-AA86-74F0EA19ED29',
  /*Baltic Sea; Kaskinen*/ '5D3BC7BC-7E06-441A-BB5D-31DE60A45101',
  /*Baltic Sea; Kastrup*/ '0E1D7597-2111-4239-B664-0D2F260C7052',
  /*Baltic Sea; Kemi*/ 'B4D54630-BE1B-482C-891C-3B07E2616E4E',
  /*Baltic Sea; Kerteminde*/ '0AB72442-9CE5-460F-A6A7-AD39BF00B8BC',
  /*Baltic Sea; Kiel*/ '5D3E59CF-98CE-4691-9DE2-E61C1B538498',
  /*Baltic Sea; Klagshamn*/ '9A057B6C-2F1C-4466-915C-7260E7D03D2E',
  /*Baltic Sea; Klaipeda*/ 'BEAA52AD-D3E4-47EF-80FF-0D30F18498BA',
  /*Baltic Sea; Klintehamn*/ '9577528E-C4C4-4D14-B77C-7701A04283BF',
  /*Baltic Sea; Klintholm*/ '1BF2827A-117F-4F52-AD0B-5FD12C0B2425',
  /*Baltic Sea; Koge*/ '52ED2A0F-BCF4-45C1-A146-745673887420',
  /*Baltic Sea; Kokkola*/ '81607933-399F-4906-B190-C4DA294033EF',
  /*Baltic Sea; Kolby Kaas*/ 'DF78C88D-2FD0-4A91-BDD3-CB3BB8F390D2',
  /*Baltic Sea; Kolding*/ '35FB9B28-5291-4A6B-ACB5-4BF87EA7FDCD',
  /*Baltic Sea; Kolobrzeg*/ '9ED0349D-F40A-4EAE-9DD4-3E8A2DBD50D6',
  /*Baltic Sea; Kongsdal*/ 'DAE01A63-76A9-4E58-A192-5E5C2351E87B',
  /*Baltic Sea; Korsor*/ 'DCB9C8B4-5852-4D07-9B69-70DED5B35850',
  /*Baltic Sea; Kotka*/ '219698CB-387F-4E32-BC08-1240173C5E40',
  /*Baltic Sea; Koverhar*/ 'FEACA2F4-651E-4B47-A6C6-CEE140E9C64B',
  /*Baltic Sea; Kristiinankaupunki*/ 'BAB48B04-E5C7-48B8-8BBD-AE91046E47C5',
  /*Baltic Sea; Kronshtadt*/ 'E2E68FAA-DB16-4FAB-8F03-00CDF86C7636',
  /*Baltic Sea; Kroslin*/ '8247A643-FE26-48BD-BA7B-D57AC05D2C85',
  /*Baltic Sea; Kuivastu*/ '714BE5D2-58A8-4254-8584-21BF72D37B72',
  /*Baltic Sea; Kunda*/ '4BA86622-658F-4C19-BD67-F0B3AF4E3E70',
  /*Baltic Sea; Kyndbyvaerkets Havn*/ 'F9B68606-0B8E-43E2-B93F-FF44A78C19CF',
  /*Baltic Sea; Laboe*/ 'AA0FC365-47C1-4975-81AE-FDADFFA46862',
  /*Baltic Sea; Ladebow*/ '2DE657DD-E3A3-437D-8A81-91165832A722',
  /*Baltic Sea; Laeso*/ 'E2F8C837-66AF-44E6-9E80-D18F12ACA7A6',
  /*Baltic Sea; Landskrona*/ '373A9C6F-138C-43B9-93AB-258E192DEB2B',
  /*Baltic Sea; Landsort*/ 'A80BE470-DAFA-4DA1-BE96-7D56387F1958',
  /*Baltic Sea; Langror*/ '989F62C3-4B86-4BFA-8E6D-E2DFB97073B7',
  /*Baltic Sea; Lapaluoto*/ '0DB4C3C2-5492-401A-8651-01773CC5B82E',
  /*Baltic Sea; Lappvik*/ '11ADAD10-2D4F-4584-81E2-86B1AF45B995',
  /*Baltic Sea; Lauterbach*/ '4F744543-CFE1-4F3D-BF31-02BC9FF1F5C3',
  /*Baltic Sea; Leba*/ 'AE9C0F2B-3FF0-4D3F-BA39-C3256ABFCEEC',
  /*Baltic Sea; Lehtma*/ 'B4CE49E0-89D5-4BEF-9FA4-2BA3E0774290',
  /*Baltic Sea; Liepaja*/ '168E55AE-EBA4-4E30-BBE3-CB23513F2009',
  /*Baltic Sea; Liljeholmsviken*/ '66D19401-6176-449E-A275-6F2C6032B6DA',
  /*Baltic Sea; Limhamn*/ '1E5089C3-C1E2-4D54-912E-452F32869C03',
  /*Baltic Sea; Lindo*/ 'F6CEE51B-29E1-4AA2-ACC7-F69A5CB5A452',
  /*Baltic Sea; Ljusne*/ 'CB7D5BE0-B399-4F26-AA6A-C77773DAB656',
  /*Baltic Sea; Ljustero*/ '0547E870-ACA7-489C-89E5-74156449974C',
  /*Baltic Sea; Loksa*/ '490A57F5-6D1A-48AE-8572-5C043AB222C5',
  /*Baltic Sea; Lomma*/ '083A0F50-B49D-4D6D-A6D8-C7D634E81A72',
  /*Baltic Sea; Lomonosov*/ '004813B5-3D7E-47B1-846C-9C7083B92E99',
  /*Baltic Sea; Loviisa*/ '8BF2BCDC-AF9A-4C74-97B3-667861FF39BC',
  /*Baltic Sea; Lubeck*/ '3A86E47E-32BA-4328-BD62-1342FB370D43',
  /*Baltic Sea; Lubeck-travemunde*/ '76D75DAA-0DCD-4215-8E64-784F98438DB4',
  /*Baltic Sea; Lubmin*/ 'AA0DAB9A-A502-4630-95B5-8ABEFB1152D9',
  /*Baltic Sea; Lulea*/ 'EB1C185A-A6C0-4474-89B9-261EF394EB0C',
  /*Baltic Sea; Luvia*/ '2E8C58D8-CAFC-4CD8-ACE2-F46BDA48D139',
  /*Baltic Sea; Lyngs Odde*/ '944AC92F-FA75-491D-B498-DA251BCC6391',
  /*Baltic Sea; Malmo*/ 'E51FF242-01DA-4DEB-964B-A3BC35EC1802',
  /*Baltic Sea; Mantyluoto*/ '3F8133E1-8396-44B3-8B92-D472F82A737C',
  /*Baltic Sea; Mariager*/ 'E5B2E906-991B-498B-9E96-46B88119B72C',
  /*Baltic Sea; Mariehamn*/ '778BA948-1B25-4C2C-B938-4F2F4A595B23',
  /*Baltic Sea; Marstal*/ '1CA7A766-DDA0-4DEE-BB08-6F6363EEFA43',
  /*Baltic Sea; Martinniemi*/ '2DC0FADB-8A0C-492E-9D80-1FE87D7275C0',
  /*Baltic Sea; Masnedsund*/ '5E3ACFBE-313A-49DD-ACCD-46D2D83D27A1',
  /*Baltic Sea; Merikarvia*/ 'DE7FBD38-6437-42DA-BD53-9688548AB093',
  /*Baltic Sea; Mersrags*/ '6D605AE4-A0CA-48AE-9741-D160C89A985A',
  /*Baltic Sea; Middelfart*/ 'EEE1BCEE-3890-48F3-A147-91564D85ADD0',
  /*Baltic Sea; Miiduranna*/ '98E244F2-789A-49FF-9185-24FB898DC36B',
  /*Baltic Sea; Mjosund*/ 'C6AEA810-B314-4A77-9ADE-D5A22AB8E700',
  /*Baltic Sea; Monsteras*/ '2E6078F1-D274-47B3-B294-584C2AC2B614',
  /*Baltic Sea; Montu*/ '5A29ABEE-6D27-4C2F-B01A-B83B8EA95172',
  /*Baltic Sea; Mukran*/ '7649C4ED-7F5E-41D7-ABCA-8588A130E214',
  /*Baltic Sea; Munksund*/ '0456708F-D2DF-43D3-9EF3-833116BFDD14',
  /*Baltic Sea; Muuga*/ '07A15DAA-5CE7-4A40-BB0A-98111E964FDD',
  /*Baltic Sea; Naantali*/ '5F474CD5-2E77-41EF-9CE5-3BE7641DF5A0',
  /*Baltic Sea; Naestved*/ 'B40151FE-E94B-41C6-8878-0EA360DA4E0F',
  /*Baltic Sea; Nakskov*/ 'DBF7B098-F644-4047-BA0F-64659F91F44D',
  /*Baltic Sea; Nauvo*/ '9C23F90C-6C20-4AF6-A379-E9B8CB76A77C',
  /*Baltic Sea; Nekso*/ 'E66225E4-A6C1-41BB-B19E-405B9DEAACC2',
  /*Baltic Sea; Neustadt Hafn*/ 'B125E891-FAD1-41AE-B792-B961C7205125',
  /*Baltic Sea; Norresundby*/ '93B7ED7E-E91B-4872-B09C-7D04F37C80AA',
  /*Baltic Sea; Norrkoping*/ 'D121EE25-7D2F-44F9-A4BE-BD92892B7C91',
  /*Baltic Sea; Norrsundet*/ '19DAF670-23CD-48E0-B1D0-A5CF7D1F1FEF',
  /*Baltic Sea; Norrtale*/ 'DF612929-087C-41A4-9818-CE95D72246C0',
  /*Baltic Sea; Nyborg*/ 'B416F5C7-E9B0-48A8-8B2B-96B13F852C29',
  /*Baltic Sea; Nyhamn*/ 'EFE9D168-188F-428D-9526-12C172667805',
  /*Baltic Sea; Nykobing (Falster)*/ '966D2C6D-E4CA-402E-B2E6-64695B6B3954',
  /*Baltic Sea; Nykoping*/ 'DD27599D-F648-4DE1-A890-9044C72940B8',
  /*Baltic Sea; Nynashamn*/ '9D6E268B-BF40-4223-98FE-CB97573CC92F',
  /*Baltic Sea; Oaxen*/ 'C7BF7926-0236-44F0-A877-9E0306784FEA',
  /*Baltic Sea; Ockero*/ '27E8434B-97D8-4665-8855-0C60C709AEA4',
  /*Baltic Sea; Odden*/ 'EA7D15A3-E438-4131-968B-B7D736F50F00',
  /*Baltic Sea; Odense*/ 'AE5B4A03-4CBF-47F0-A895-74895071E526',
  /*Baltic Sea; Olkiluoto*/ 'A41575C6-902D-44B0-96F0-171922E55913',
  /*Baltic Sea; Olpenitz*/ '3D850C7F-9A02-4EE0-980E-765A4316D072',
  /*Baltic Sea; Omo*/ '93D21EEF-5638-4006-ACCE-6D353F446C37',
  /*Baltic Sea; Oregrund*/ 'D236EA1C-E8F7-47FD-8212-0F648994558E',
  /*Baltic Sea; Orehoved*/ 'E164F197-4AC6-457E-8FE3-6C29E131F555',
  /*Baltic Sea; Ornskoldsvik*/ 'F2F3EB8B-D827-49D8-B5B9-2C2B4DFE063C',
  /*Baltic Sea; Orth*/ '34022A06-EFED-4E4F-989A-576BFDF49C69',
  /*Baltic Sea; Oskarshamn*/ '9E55F686-B7D0-451E-A5F8-08B1D1BCC7EA',
  /*Baltic Sea; Ostrand*/ '65D1AFE5-88CE-4357-9BCE-0C51A03CD3B6',
  /*Baltic Sea; Oulu*/ '4E238754-EF76-4676-BC05-14B05698CA06',
  /*Baltic Sea; Oxelosund*/ '64A40C23-91D3-4BA3-BF49-B628512CBABE',
  /*Baltic Sea; Paldiski*/ '884E4CB9-2CD1-4334-82B4-2893A5793DDB',
  /*Baltic Sea; Parainen*/ '6C4EBF9C-1B85-42A7-9683-730C76E505A6',
  /*Baltic Sea; Parnu*/ '47B71E32-735E-4992-8500-607D472C290A',
  /*Baltic Sea; Pavilosta*/ '28A98D34-ABDA-4AF5-9A74-86F67152993D',
  /*Baltic Sea; Peenemunde*/ 'E50BEAA0-6350-455D-9866-DAF87CF37A51',
  /*Baltic Sea; Petrodvorets*/ 'B0B618C9-1375-4DF8-A23A-23DFD1A1603F',
  /*Baltic Sea; Pietarsaari*/ '9B266B00-D954-4D64-900C-B998F51D9B60',
  /*Baltic Sea; Pionerskiy*/ '7498CB39-A85C-44B0-8E8F-9AFD6DFEE74E',
  /*Baltic Sea; Pitea*/ 'A0633D62-D214-418B-8D42-6D1DCCFF4FAD',
  /*Baltic Sea; Police*/ '2ACEFAEA-83AF-4416-AF7C-674841ABFE95',
  /*Baltic Sea; Pori*/ 'B1C7CB3D-F958-4AAB-B9B4-E9E9F8122BDB',
  /*Baltic Sea; Port Bronka*/ 'F9F1AF57-8CB7-403B-9B6E-2A77FF503E3E',
  /*Baltic Sea; Porvoo*/ 'C95F9F15-C15F-4EE3-8EDF-EC272A71D6AC',
  /*Baltic Sea; Prangli Is*/ '9A3055F1-6CF4-4144-AC2B-08027C27E339',
  /*Baltic Sea; Primorsk*/ 'C0EDD907-257C-4D0D-BEC5-1745900BF09D',
  /*Baltic Sea; Puttgarden*/ '83767144-1A37-4E0F-B8C6-CFA37499F821',
  /*Baltic Sea; Raa*/ 'F4DE4463-B7C2-4211-B809-C27635AFC2A4',
  /*Baltic Sea; Raahe*/ '50123EA9-AFF3-4C98-B478-8E05302C5DCC',
  /*Baltic Sea; Rahja*/ '8E760D78-BF45-426A-8270-B400F3188AE4',
  /*Baltic Sea; Randers*/ 'D1F0FD73-F48F-47DA-88D9-9209188AB888',
  /*Baltic Sea; Rauma*/ '300F275A-63BE-4BCB-A061-6AF47957557B',
  /*Baltic Sea; Rendsburg*/ '0159154E-5CC0-4B77-9701-08C2113ACCF5',
  /*Baltic Sea; Riga*/ 'D246A390-2B5C-44C8-819E-A5461A3D0C9C',
  /*Baltic Sea; Ringsted*/ '92AAE438-9170-4663-ADE1-03E2EF6FB60F',
  /*Baltic Sea; Rodby Havn*/ '6D4A9EAE-0D6C-467F-A004-A58A1A9B7ABF',
  /*Baltic Sea; Rodvig*/ '7F5DE4C2-33E5-4A60-B241-8B0E96997B71',
  /*Baltic Sea; Rohukula*/ 'B4C9B864-9C02-49A5-9FCD-8A7BC9D3974E',
  /*Baltic Sea; Roja*/ 'DFE4F2E5-CDFB-4102-A1EC-6A181D9872D8',
  /*Baltic Sea; Ronehamn*/ '0B1775D4-E632-4417-A1FE-38D335C80920',
  /*Baltic Sea; Ronne*/ '4E866D81-7973-4EE1-9C85-C0E6EEE5E61D',
  /*Baltic Sea; Ronneby*/ 'E078E325-B457-4541-B012-347974A998C3',
  /*Baltic Sea; Ronnskar*/ '1BBC6A3B-5509-4F7D-A087-02A19DEA0DC5',
  /*Baltic Sea; Roomassaare*/ 'EF2D34C7-5D0F-4105-A0E9-731617A8C3FC',
  /*Baltic Sea; Rostock*/ 'A9074CB7-D211-4775-9753-3757C4DF2366',
  /*Baltic Sea; Roytta*/ 'CBDB4544-BEA7-41FE-812A-DF25757C537F',
  /*Baltic Sea; Rudkobing*/ '0BA96709-49F9-4DF2-A6BF-A77EE73FDE2D',
  /*Baltic Sea; Rundvik*/ '89C3817D-B9D4-4432-871C-0BC169C96EA9',
  /*Baltic Sea; Saaremaa Harbour*/ '1B9F6E8E-8261-4227-AC7D-008C1F737EF8',
  /*Baltic Sea; Saeby*/ 'A8832A8F-BAA9-48C6-BA32-610AB58F1D95',
  /*Baltic Sea; Salacgriva*/ 'B5E94BC7-43DF-4213-9268-9A3357110112',
  /*Baltic Sea; Sandhamn*/ 'C60D4A01-E9EB-4A1E-805C-D964F017B6B9',
  /*Baltic Sea; Sassnitz*/ '7B8C95AC-B0F3-4CFF-9818-B691167736DA',
  /*Baltic Sea; Schacht-Audorf*/ '8B16E378-63FC-4D82-BF7A-8BF3558CDD11',
  /*Baltic Sea; Schleswig*/ '014842FF-1EBF-47CC-84F7-F7807A69D17F',
  /*Baltic Sea; Schlutup*/ '4DFF232B-6B47-49B2-B91F-20ECEC2404BB',
  /*Baltic Sea; Sillamae*/ 'DD8BA547-3E62-4CAC-BCF6-907FB7299DEE',
  /*Baltic Sea; Simpevarp*/ '31A7E119-83B6-4849-9B90-0CE90BFD35EF',
  /*Baltic Sea; Simrishamn*/ 'C84949BB-6DAA-489C-B3D2-F850A0C740BF',
  /*Baltic Sea; Skaelskor*/ '7721AB78-B1DC-4B30-9300-EA2221335491',
  /*Baltic Sea; Skaerbaek*/ '34FA5AC7-A9E1-4CEC-8CB8-3276183FC051',
  /*Baltic Sea; Skagen Havn*/ '9E34F461-4345-4FDF-99CC-DB9FA00EADC5',
  /*Baltic Sea; Skelleftea*/ '3012745B-C250-452A-A74F-AB9311927238',
  /*Baltic Sea; Skoldvik*/ '4E5AD64F-F0DC-415E-9264-9AC362D9504C',
  /*Baltic Sea; Skulte*/ '25DCB107-13DC-4C5D-BB2A-451CB5AB7D4E',
  /*Baltic Sea; Skuru*/ '0B309DB7-9080-4E9B-8519-12B34D53AA57',
  /*Baltic Sea; Skutskar-harnas*/ 'E9493C3F-A666-452C-B522-8CB295A16A42',
  /*Baltic Sea; Slite*/ '56819753-A6DF-4B62-BB01-B761008C223D',
  /*Baltic Sea; Snaptun*/ '8A367C49-C90D-4B16-9D51-4BA99FDDAD71',
  /*Baltic Sea; Soby*/ '070E2637-7370-43B7-BFB5-86C417911222',
  /*Baltic Sea; Soderhamn*/ '08D11CDD-56F2-48A0-9098-3AF09A6DB1E2',
  /*Baltic Sea; Sodertalje*/ 'D8B1709F-4617-4715-8C96-6048F26E09EC',
  /*Baltic Sea; Solvesborg*/ 'D38830FA-3C5A-4AFB-8F66-703E6C9936DF',
  /*Baltic Sea; Sonderborg*/ 'ED48020A-3BC6-49AB-AD94-0737A3B995A3',
  /*Baltic Sea; Soraker*/ '66630F87-BAEA-4AE9-BBDD-955D46CD0BE2',
  /*Baltic Sea; Soru*/ 'C9D9B473-6BFE-4DF1-8B3C-03DD0391D33A',
  /*Baltic Sea; St Petersburg*/ '6AC20693-65B1-4CF3-8FDF-EE5CCA05838E',
  /*Baltic Sea; Stavsnas*/ 'F1652006-30A3-402B-8307-B5C6FDAD259B',
  /*Baltic Sea; Stevns Pier*/ 'F3465EE2-3B37-4D65-AE8F-93967CA45ECE',
  /*Baltic Sea; Stigsnaesvaerkets Havn*/ '935E56A2-B322-4122-9428-121BC17E59E9',
  /*Baltic Sea; Stockholm*/ '6F155D41-5E6F-4F25-BA89-55CCF68F0232',
  /*Baltic Sea; Stockholm Norvik*/ '54FE998A-525A-45CC-94DB-3F947D636EEC',
  /*Baltic Sea; Stocksund*/ '3260CF5B-604D-4BA1-8FCB-2264B08C78F7',
  /*Baltic Sea; Storugns*/ '8E34699C-7476-4534-8264-BA3778E0A9F3',
  /*Baltic Sea; Straa*/ 'A9EABFE6-F879-49E3-AEDA-92588DA48756',
  /*Baltic Sea; Stralsund*/ 'E591480D-64AA-48C8-BE1F-EF1CAA4B9A1C',
  /*Baltic Sea; Strandby*/ '72D413B5-0299-432A-ABD2-4FCF3242D3B0',
  /*Baltic Sea; Strangnas*/ 'D150D5D9-6337-475F-A8F1-2F852B473DBA',
  /*Baltic Sea; Strib Havn*/ '262A19A4-95D2-444F-9362-81ADAE3058D1',
  /*Baltic Sea; Stromma*/ '66B0B827-E2A7-4333-B923-728BEC8F8D43',
  /*Baltic Sea; Stubbekobing*/ '8897DB2C-8683-4739-BCC5-DB390E009D67',
  /*Baltic Sea; Studstrup*/ 'B5E6953A-FC2F-4AAF-9D0C-38E3CE7FA487',
  /*Baltic Sea; Studsvik*/ '8CC70BD8-78B7-4348-A161-ABCEFB470D4D',
  /*Baltic Sea; Sundsvall*/ 'CF7A9C04-A9FF-4608-8B4C-E846264E6EC8',
  /*Baltic Sea; Svaneke*/ '3C61012A-D985-48C4-B1AE-906B6E6ECA08',
  /*Baltic Sea; Svendborg*/ '2873C7AC-053F-4FC3-8149-29D06BD6F97F',
  /*Baltic Sea; Svetlyy*/ 'AB7A8CA4-CAF0-4D16-93C2-4E6462783F4C',
  /*Baltic Sea; Swinoujscie*/ '6A716D1B-A080-4B5E-97B3-A512C65F664F',
  /*Baltic Sea; Szczecin*/ 'C804E00D-4A7B-4E36-872D-8ED04E4624DB',
  /*Baltic Sea; Taalintehdas*/ '76B878C2-24CB-4CF6-ABF7-B26D5E560F82',
  /*Baltic Sea; Taarbaek*/ 'A3901E08-8EBB-418B-A001-C553BE6EF72F',
  /*Baltic Sea; Tahkoluoto*/ '20E4A4AA-44B6-444E-B870-0B37E1B4E7E7',
  /*Baltic Sea; Tallinn*/ 'E1646F3F-F74B-4A14-8655-55E0541E5B2A',
  /*Baltic Sea; Tammisaari*/ 'E7B7DE1A-09EA-4EC7-830B-6819438D2B3E',
  /*Baltic Sea; Teijo*/ '150078AB-1955-404A-80FA-E07E7A36EC25',
  /*Baltic Sea; Tejn*/ '7B677E35-CEBB-4B5F-93CA-BC7BF8EB5CE6',
  /*Baltic Sea; Tolkkinen*/ 'F9BB5A64-48AC-4E30-AB68-EA9FEE5A565A',
  /*Baltic Sea; Traslovslage*/ 'E980FCBB-E0E9-4157-B66C-1B5009A8DB12',
  /*Baltic Sea; Trelleborg*/ '65B1B4E0-44B5-42B5-8CC7-5FE30B82BEFA',
  /*Baltic Sea; Tuborg Havn*/ 'B6835182-7F43-4EF2-A4A3-E8EA9283DE39',
  /*Baltic Sea; Tupavuori*/ '7AA1E028-1E0E-4207-9D08-C4C6DF933FCA',
  /*Baltic Sea; Turku*/ '92238B1A-2B18-4076-9020-FC751E66B027',
  /*Baltic Sea; Ueckermunde*/ 'E04B8E23-D8D8-4E8A-8FE5-81C9DF60093C',
  /*Baltic Sea; Umeahamn*/ 'F34ABB2C-79FF-49FF-88F6-2EC0B0E37690',
  /*Baltic Sea; Ustka*/ 'ED55F27C-9CBD-4256-8031-9E75965C4675',
  /*Baltic Sea; Ust-Luga*/ '9C58CB35-39A3-4A57-A51E-E9EA2BF59471',
  /*Baltic Sea; Uusikaupunki*/ '81EF02B4-9025-4CB1-A22B-CDF764FEF048',
  /*Baltic Sea; Vaasa*/ '4A2A4C71-95DC-4E7D-B313-2DCDA811B67D',
  /*Baltic Sea; Valdemarsvik*/ 'DA4A662C-601A-4750-AED5-0B59AC539E5B',
  /*Baltic Sea; Valkom*/ '74CD0BAE-2F85-4564-BF95-73FE685034C9',
  /*Baltic Sea; Vallvik*/ '69518AA4-3EA1-4A05-846D-4697BD10CF75',
  /*Baltic Sea; Varberg*/ 'A9E6CBBB-E7D2-4F75-A57E-1B9EBFBC6C26',
  /*Baltic Sea; Vastervik*/ 'E974FA7B-F4EE-4BED-955B-0ED8E7F6FC1E',
  /*Baltic Sea; Vaxholm*/ 'C1143EC4-4EBA-45F1-B961-E5D37594AED9',
  /*Baltic Sea; Vedbaek*/ 'B3C85D21-B513-45ED-85E3-B4442736C6FB',
  /*Baltic Sea; Veere*/ '2FD91CBE-F722-4A09-B77D-9FBD840D0AB7',
  /*Baltic Sea; Veitsiluoto*/ '5753434B-29FD-4547-9122-3ED5C523DB03',
  /*Baltic Sea; Vejle*/ '29090FF0-2884-45E7-BF9B-A584B68D6CED',
  /*Baltic Sea; Ventspils*/ 'E6CFBEF0-7CD7-467C-B887-4C7C50361179',
  /*Baltic Sea; Vierow*/ 'D85C74EF-F3DA-4D9A-A489-2B4317BACB3F',
  /*Baltic Sea; Virtsu*/ 'A8A381EA-09D8-4697-92DB-3315A78C6050',
  /*Baltic Sea; Visby*/ '5391618B-4C6D-46FB-838F-BEE3F5997F89',
  /*Baltic Sea; Vordingborg*/ '6FF3AAE6-52F3-4042-9CE0-C30D360A81AC',
  /*Baltic Sea; Vyborg*/ 'C96D472C-7BD8-41B7-97DA-6C6B0AFE1F49',
  /*Baltic Sea; Vysotsk*/ 'AF1417F5-9EC2-4C86-A3B5-473B36081811',
  /*Baltic Sea; Wismar*/ '6C51BAAB-E37C-4B9B-8979-86AFF4317DA1',
  /*Baltic Sea; Wladyslawowo*/ '2B6DC61E-8E54-4923-8DB3-F0FA6F62D319',
  /*Baltic Sea; Wolgast*/ '479DD914-97EF-4B57-B262-B3FFD0396072',
  /*Baltic Sea; Ystad*/ 'EB1BA780-3D02-4D26-B559-4991BFE05E99',
  /*Hainan; Basuo*/ '49F0E9CD-191F-4B96-8535-719DDA2329F1',
  /*Hainan; Haikou*/ 'BD69CA4C-D0EA-4F85-8A62-8AE0A9294C85',
  /*Hainan; Macun*/ '201B341C-5F30-46BC-BC16-B8C9E91EB901',
  /*Hainan; Qinglan*/ '1169F1FB-3D12-4B8E-9DA3-DC7E9AB52C44',
  /*Hainan; Sanya*/ '807EBDC4-263A-449E-A2E7-853945C39E9B',
  /*Hainan; Shisuo*/ '6AEDBE53-21C5-4853-9167-F2A9D7B07860',
  /*Hainan; Yangpu*/ '3EA198E5-4D54-4B1E-9F1A-DA9D66D9E3A9',
  /*Hainan; Yu Lin*/ 'BA660474-2C29-46F2-8FED-47576642E03A',
  /*Iceland; Akranes*/ '6D0E2BAF-9A8F-4284-BE0A-578FC9552506',
  /*Iceland; Akureyri*/ '02281960-A7F4-4028-8B82-FC426D24DEA3',
  /*Iceland; Bildudalur*/ 'E5E6CAF1-644A-49AA-9F9D-4F4781C2CBE0',
  /*Iceland; Bolungavik*/ '2DBF484A-9586-4319-B5D2-F5CCCDAB94B5',
  /*Iceland; Budir*/ '095E7FD0-6EEC-4232-B183-A4054D29A2EE',
  /*Iceland; Dalvik*/ '949337CD-9A96-4D77-BC63-4027F7614F10',
  /*Iceland; Djupivogur*/ '1AD0FE1A-DBCB-4ADD-9C36-8F2F328AD71B',
  /*Iceland; Eskifjordur*/ '327C9711-1766-46DD-9584-9F52E7B17111',
  /*Iceland; Flatey*/ 'BC7A5CF1-6392-4532-9769-5ECBA437AFAF',
  /*Iceland; Flateyri*/ '1900C8DD-E9C1-4C27-AE1D-75FF9463C6F0',
  /*Iceland; Grenivik*/ '0F1624CD-38F2-4610-889C-2B239E484F15',
  /*Iceland; Grindavik*/ '743AFDDE-65EA-4161-9576-56760A5FA402',
  /*Iceland; Grundarfjordur*/ 'E2CEE206-214E-436B-8914-FC736E414380',
  /*Iceland; Grundartangi*/ 'C785D5E1-CE64-4881-95E1-96E62A49D7EF',
  /*Iceland; Gufunes*/ '8BCD681B-635D-4EF4-9F26-5F5935E2FB9D',
  /*Iceland; Hafnarfjordur*/ '91BCE42D-6009-441D-9851-EC69F4C6DF4B',
  /*Iceland; Helguvik*/ '4ABD245B-633E-4668-AF29-DC6B1234A237',
  /*Iceland; Hjalteyri*/ '8531E27C-A2C4-4909-869E-3D564DD8BDA1',
  /*Iceland; Hofdhakaupstadur*/ '25F8D546-08CF-4573-8365-92C3F41C1EDB',
  /*Iceland; Hofn*/ '05E69102-D04A-41D3-AF7B-E40F14380478',
  /*Iceland; Holmavik*/ 'C42A3192-2989-4637-953F-CCD7E8AD2EDA',
  /*Iceland; Hrisey*/ '69FAC479-8018-44E3-91FC-0814E73CCE8C',
  /*Iceland; Husavik*/ 'B5A21887-51D1-47B9-B946-DFAA277AE75F',
  /*Iceland; Hvaleyri*/ 'CF2D020C-2BF7-4AF3-9A95-3CCC5DA400C0',
  /*Iceland; Hvammstangi*/ '9C9EEEBF-4FD8-4C9C-A93C-C8753A00601C',
  /*Iceland; Isafjordur*/ 'DAE084CF-0BE0-4CF9-B5B3-E65C628EBDB1',
  /*Iceland; Keflavik*/ 'B84A1C1D-060D-4E8E-AF24-8086735E3897',
  /*Iceland; Kopavogur*/ '460B7642-B645-46E3-B42A-1CC71CE2C372',
  /*Iceland; Krossanes*/ 'ABA19DE7-F41C-4890-B308-58D28C297C60',
  /*Iceland; Midsandur*/ '3CB56966-A734-4301-9148-562B411B1692',
  /*Iceland; Neskaupstadur*/ '6078A950-9E94-4CE0-A387-72DA2C8A6217',
  /*Iceland; Olafsfjorhurd*/ 'B0B1B618-FD36-4877-AC02-5660583DD04F',
  /*Iceland; Olafsvik*/ '88CB1B9E-77E3-4A08-A0B9-CE20835DC028',
  /*Iceland; Patrekshofn*/ '5EE6A5C7-1600-4E2B-967B-5BD960619E58',
  /*Iceland; Raufarhofn*/ 'E09CCBA7-95E8-4055-ACCC-5FB253D3A88A',
  /*Iceland; Reydarfjordur*/ '37E31FD7-A9C2-4840-9D46-FD3C2D23867B',
  /*Iceland; Reykjavik*/ 'A9A69712-5E34-4CAE-BACB-E6069D0DA019',
  /*Iceland; Rifshofn*/ '62ACF9C4-925A-4909-9A1B-0FF407DF71AA',
  /*Iceland; Sandgerdhi*/ '1AC89186-BA52-41E0-945C-71188601C4C8',
  /*Iceland; Sandur*/ '3848870D-2819-4F17-A455-7E7BE29F6D9D',
  /*Iceland; Saudarkrokur*/ '09483E53-E980-4338-A5EA-37BA4FFF1616',
  /*Iceland; Seydhisfjordur*/ 'C7E7C707-68F6-48C5-8098-24C0D96916C5',
  /*Iceland; Siglufjorhurd*/ '7F05C58B-40C6-4FF4-A5EA-D1F6988CC37E',
  /*Iceland; Straumsvik*/ 'D8583917-06BA-431C-B1AD-1BAB24A9F115',
  /*Iceland; Stykkisholmur*/ 'EFE97AF7-5C2D-45B3-8C77-7FF5BB780521',
  /*Iceland; Svalbardhseyri*/ 'B27F4431-72F1-45FE-901E-F7274C9C45E4',
  /*Iceland; Thingeyri*/ '315C3712-466C-4F3C-95D2-7E27E12C3664',
  /*Iceland; Thorlakshofn*/ '15F3D617-97CB-49AB-BFB1-DEBFBB2B7DD7',
  /*Iceland; Thorshofn*/ '71ABD061-B3BA-4392-BE62-C3466C6934A1',
  /*Iceland; Vestmannaeyjar*/ 'E81BBD73-C9E0-4E7A-A555-F83752710590',
  /*Iceland; Vopnafjordur*/ '80C73953-06C5-4CE7-8AA7-52C16EC7D119',
  /*North American; Alameda*/ 'DC5961D0-6C84-43F6-BAC6-541B7E8C8771',
  /*North American; Alaska*/ '1FB8ACEE-2418-431D-B681-AB1914A0055A',
  /*North American; Albany*/ '172952D6-60A2-438E-AD0E-E7B26F4CFD99',
  /*North American; Alexandria*/ '39B27D5F-C247-4F48-973B-0CC0F766AEE7',
  /*North American; Alliance*/ 'D415AB5A-1C6E-4064-9DAF-C82AD425B780',
  /*North American; Allyns Point*/ 'A6E50C25-9442-42DE-A764-DE35C895E7C9',
  /*North American; Alsen*/ 'D852F801-31AD-410E-872F-B1E44BCC1EAC',
  /*North American; Ama*/ '19BB1ADD-EC42-4113-BF08-34F12B4C6A17',
  /*North American; Amelia*/ '3A8DF06B-7B07-449E-947A-3EC0D3F1797F',
  /*North American; Anacortes*/ '57332325-9A4C-4A8E-BD00-523D36E795E9',
  /*North American; Angoon*/ 'AC1E97C6-0A4C-4216-8FC1-8C4F2F4124E1',
  /*North American; Annapolis*/ 'BCA3EA9A-9652-48F7-97DF-DA0A40AB2761',
  /*North American; Antioch*/ '24179BCF-579A-4022-B2A8-C32FA76FAE60',
  /*North American; Arabi*/ '4F287EAC-6E15-4E16-8366-01DAD3B1D6A1',
  /*North American; Aransas Pass*/ 'A9BCC2FE-DF84-4B79-8AB8-52EB7A7CCFB1',
  /*North American; Argentia*/ '1FA91D99-7081-4C6F-8FA9-2ACB678F075C',
  /*North American; Astoria*/ '1134A82A-AD5E-4C12-8EE0-B3A825B6A134',
  /*North American; Aulds Cove*/ '19FCA58D-8B21-4502-90F1-26C05C0533DE',
  /*North American; Avalon*/ 'F4F3888A-8A03-4C83-9299-9DCFDAA2A4CD',
  /*North American; Avila*/ 'B3BE6659-3018-4495-BB51-9EB2533D7A66',
  /*North American; Avondale*/ '92D570D0-EC12-4445-A604-884C2BC708B8',
  /*North American; Baddeck*/ 'C0CEC8D2-C373-4023-BB19-06133127CBA9',
  /*North American; Baie Comeau*/ '57BBAC95-E8FD-4ED1-A42E-B76906CC2D7A',
  /*North American; Baie Verte*/ '58544B4C-5C36-4749-A23E-65B633FBF2A4',
  /*North American; Bainbridge*/ '42FD57FD-CC48-42A5-BF6B-8F860D3E7B43',
  /*North American; Baltimore*/ '296787DA-C2ED-4005-83F0-69132372E01B',
  /*North American; Bamberton*/ '0389F849-FE37-446B-B851-0FA27DA46D1B',
  /*North American; Bar Harbor*/ 'A6B6CA5F-6CAD-42E8-A2C6-2A4CF535D041',
  /*North American; Barataria*/ 'DAF94F21-60A8-48B6-9A14-D58D0B353ED0',
  /*North American; Bartlett Cove*/ 'E36CE4EA-CD52-45F0-8225-1B42D9661C20',
  /*North American; Bass Harbor*/ '2859BBF6-54E8-4AFF-950F-E9F1CDC7EB8F',
  /*North American; Bath*/ 'EE676412-2751-448B-A3E6-F3A622A71466',
  /*North American; Baton Rouge*/ '265FD212-6A7B-429E-B26A-1866B408E4D1',
  /*North American; Bay Bulls*/ '32B3BE22-2C7B-49C4-A9F4-96831787C29A',
  /*North American; Bay de Verde*/ 'AFB03AF7-A4DB-4200-8256-C6E53E2079E6',
  /*North American; Bay Roberts Harbor*/ '727F2067-9CA6-4079-BF89-6DCB9ED73081',
  /*North American; Bayou La Batre*/ '2D218B1D-3F76-44FB-A113-481142F7E47F',
  /*North American; Baytown*/ 'A356859F-6301-4443-AB05-0BBC5D394CF2',
  /*North American; Beaufort*/ '92754102-F034-4D83-AEC5-8B4195EF83EC',
  /*North American; Beaumont*/ '470EEE3B-7E5B-4CA9-B190-53E58D8F0F34',
  /*North American; Beaver Cove*/ '0846E9A0-CE0C-4EC7-9B8B-6F76AA37A4C4',
  /*North American; Belfast*/ 'A36DC5AC-67FD-4BF0-8D80-CA2B6932091D',
  /*North American; Belle Chasse*/ '1EF24ADC-FA2C-407D-B742-D4DBCE12087D',
  /*North American; Belle Harbour*/ '8DAEB318-D39F-48DF-B2A9-CA50DB96986D',
  /*North American; Belledune*/ 'A2B1EB61-CBA4-43AF-A21B-9724D4F0053B',
  /*North American; Belleoram*/ '7BEFAA99-0494-4109-9CD1-A0C9EB8548C1',
  /*North American; Bellingham*/ '55026C19-EDB9-4B14-9937-82D03680A0B3',
  /*North American; Benicia*/ 'C228B0E8-F793-48D4-82BB-2B89D641C94E',
  /*North American; Bienville*/ '6D7A85BC-28D7-4CB9-B82C-A60D2277F618',
  /*North American; Biloxi*/ '9B8CC207-FAD5-4D17-B4DD-00016A21393C',
  /*North American; Blanc Sablon*/ '02AF2A8F-BDA4-4A6F-8AA9-559FC327EC62',
  /*North American; Block Island*/ '4C5E4DCC-5A2F-4204-A581-0F350CBFC9D4',
  /*North American; Bonavista Harbor*/ '3F48F547-39E9-4F3E-950C-FF046FF3E78B',
  /*North American; Boothbay*/ 'AE55620C-C849-41D5-A085-D40B87BB9728',
  /*North American; Boothbay Harbor*/ '4F2D1902-45BD-403B-9683-448E8F34E0E3',
  /*North American; Boothville*/ '1D1AFFBD-5AE6-4366-9C94-58C144C740F9',
  /*North American; Boston*/ 'FEBFEBD1-CC06-4031-B369-7A5BDF628FF4',
  /*North American; Botwood Harbor*/ 'C5A78563-8609-48D1-87AF-73A39DD6D9E1',
  /*North American; Braithwaite*/ '9B6B6B61-F806-411E-8652-B1D8281D4BDB',
  /*North American; Brayton Point*/ '4CE89A65-8040-4667-855A-71301631ED57',
  /*North American; Bremerton*/ 'BA727B1C-6FA1-45A7-8BEE-34A19542EE40',
  /*North American; Bridgeport*/ 'AFD551F4-12E3-4A80-AB99-C89C60278FF5',
  /*North American; Bristol*/ '1D1266C5-A304-456D-8F2C-16F6B15D872E',
  /*North American; Bristol Harbour*/ 'D19416E8-C439-44C9-91EE-DDE08831B63E',
  /*North American; Brookings*/ 'BF97ABC6-9427-4CB1-9BEF-B5DD1F44A639',
  /*North American; Brunswick*/ 'E530841E-A5C6-4C7C-81A6-74B324641C21',
  /*North American; Buchanan*/ 'FBFF9F5C-714B-47AA-B06E-C3A2ED1F0543',
  /*North American; Bucksport*/ '61347122-1F51-44F0-B13F-9F93596B2A95',
  /*North American; Buras*/ '538B4D48-6F03-419A-B53C-62D81BAACB67',
  /*North American; Burgeo*/ 'EDCC151A-09CA-4B50-A799-F8E3538B2A5C',
  /*North American; Burlington*/ '59071797-6705-44DF-B848-CC80A46148FC',
  /*North American; Burnside*/ '1147329E-C9F4-4C9A-B429-9CB1BFFD1DB9',
  /*North American; Cacouna*/ 'F7A82306-C7BE-4610-8EAD-AA27C7113326',
  /*North American; Cambridge*/ '112AB1F6-8B9B-4984-AA02-BA7440AAC979',
  /*North American; Camden*/ '3EF5705F-17D9-4B99-BC0D-D991A0722922',
  /*North American; Cameron*/ '9FC2D0AA-B8CE-4744-93AE-1500BF423BE4',
  /*North American; Campbell River*/ '1AA3A430-D607-4FFE-B4C9-39D6D1139E18',
  /*North American; Cap aux Meules*/ '32DCAB1E-401F-4C5E-8D87-5A04BD3A32CF',
  /*North American; Cape Charles*/ 'FB3DF7D2-F034-4686-9027-C7EFA62C9C94',
  /*North American; Cape Henlopen*/ '3752AB17-17A4-4850-B1F1-E6BB66BA06D7',
  /*North American; Cape May*/ 'CFBC63B8-ABF6-494F-A09F-485C180B3D95',
  /*North American; Carbonear Bay*/ 'BFAB0418-8F4A-46D5-87DF-2FAB7B0569E4',
  /*North American; Carleton*/ 'B3815F50-00F9-4238-A133-122A71E6DE68',
  /*North American; Carmanville*/ '804A4B9B-3814-44B6-BD1B-77156D743E0E',
  /*North American; Carpinteria*/ '098A322C-34AD-403B-A9EC-A4D06953E052',
  /*North American; Cartwright Harbor*/ 'CAE6EE43-C63D-46D1-BFCF-314D6A36BA13',
  /*North American; Castine*/ '770568AB-0DAA-4ABA-ACE9-9B6B83578C7D',
  /*North American; Catalina*/ 'BE85AB25-1C64-4ABA-85FD-92986E7864F6',
  /*North American; Catskill*/ 'B64C1CAD-B383-47F7-91A6-4C6D34CD5F96',
  /*North American; Cementon*/ '1490A9F6-6F2B-4070-B159-AF6B91B202AC',
  /*North American; Chalmette*/ 'F6BC9A5E-E8E7-4B2E-8494-195EA9EC9B9D',
  /*North American; Chandler*/ '772D5180-FEAE-4E1A-BA9A-6D54AB8CE2B1',
  /*North American; Channelview*/ 'B92A480A-A039-4CC1-8CF7-DA7BA4F243E3',
  /*North American; Charleston*/ '8FAAA754-5BFE-47C5-AA26-16BDE02FEFFB',
  /*North American; Charlottetown*/ 'E77878DA-53C9-46B1-BDBB-769945EB7431',
  /*North American; Chatham*/ '53A274C7-070A-4155-80C8-ADAC6A04BFB7',
  /*North American; Chauvin*/ 'C150C63A-FBA6-4B0E-B0D9-7B323D2B0E1B',
  /*North American; Chemainus*/ 'AEF0181C-719F-42A7-B953-566A5DC5B13A',
  /*North American; Chenega*/ 'C8E68C7E-2183-41FA-AD2B-89A17CD500ED',
  /*North American; Cherry Point*/ '6792146F-A385-48FB-BFBA-69FB11F0514E',
  /*North American; Chesapeake*/ '964FA4A9-BFAF-436B-A3DD-43728151CEF6',
  /*North American; Chester*/ 'FC80A153-90C5-42DB-A968-AAB84A3ECE0B',
  /*North American; Cheticamp*/ '65EC5F5E-FE15-4297-B20B-195C93A7E4CC',
  /*North American; City Island*/ 'B89E4AA3-8F53-41EC-BA8F-61A4E9104B21',
  /*North American; Clarenville*/ '4CD3FDD3-ED76-402E-865F-C52510120734',
  /*North American; Clatskanie*/ '1D472BDF-F952-4E56-887F-9A69604B4CBA',
  /*North American; Claymont*/ '059ECA25-1C28-4354-A7E1-977758863FC5',
  /*North American; Coden*/ '4C4AC917-BDCD-4ADA-93E9-32C1141EDBAA',
  /*North American; Coeymans*/ '00B35EA4-9D23-4B04-9B68-CBCB81F3390F',
  /*North American; Coffman Cove*/ 'B853E994-5737-4059-B3E4-CF4C0910BB10',
  /*North American; Come By Chance Harbor*/ 'FCFA71FC-BAAD-4A53-8151-84989C92B9A6',
  /*North American; Comox Harbor*/ 'D86239A3-54DF-443C-B254-2D65EF99B898',
  /*North American; Concordia*/ '706E4B7A-F06F-4FA4-9222-5A677A74BB5A',
  /*North American; Contrecoeur*/ '649102CC-2F40-4FC2-9599-15EED2923C9B',
  /*North American; Convent*/ 'EF2B4BE7-B18F-4D0F-88BE-7A693FDF4033',
  /*North American; Coos Bay*/ 'C2C9E1C0-0358-4BA7-BEB4-A5D4CE878B54',
  /*North American; Cordova*/ 'B56942C8-822A-404E-AE7A-3801211410B7',
  /*North American; Corner Brook*/ 'FCC88C4C-3EAA-43E6-99C8-32F696329FEA',
  /*North American; Cornwall*/ '6BA7086F-54A6-4DD9-B75E-5497E6BFC6ED',
  /*North American; Corpus Christi*/ '65E78794-5228-459E-98CC-7A30D31F121F',
  /*North American; Country Harbour*/ '4E2DD7D5-A618-402C-AB9F-6BD53DE58056',
  /*North American; Cove Point*/ '51D76046-8BDB-446F-948E-B1FB91821C11',
  /*North American; Cowichan Bay*/ 'B1FAD596-E444-4A44-9813-5851E5AB1E31',
  /*North American; Coxsackie*/ '172B9A2C-4DA5-43E8-AE94-DBBBF97F5EF1',
  /*North American; Craig*/ '4F8B06AF-7191-4B2E-9B94-BFE0B98F648F',
  /*North American; Crockett*/ '081E66A5-FC6D-4527-A7B7-A6B4E73A845C',
  /*North American; Crofton*/ 'E5E76159-845A-4716-8ADC-C408ABC3A7B0',
  /*North American; Crown Point*/ '9D938960-9FF0-4472-93A8-DB6084D4D380',
  /*North American; Cut Off*/ '9A7DA88B-E886-4D39-80D9-B207FCABD6E7',
  /*North American; Dalhousie*/ '088254CE-BCA7-49F3-8890-EA42A2171EC3',
  /*North American; Dania*/ 'B268FEC1-3CBF-4F8E-83A7-4432BFF04A1C',
  /*North American; Darrow*/ '88A17110-1F4F-48ED-8DA4-129BABAB5531',
  /*North American; Dartmouth*/ '04717735-BD27-4CB8-B0FF-652DC78CE39F',
  /*North American; Davant*/ '23FF82F5-1BAE-4248-836C-8ED4143CCB1F',
  /*North American; Davisville Depot*/ '85BE6622-AF65-49BC-9957-F3F07F0C49D8',
  /*North American; Daytona Beach*/ '6C724A3C-A168-4E48-AB4C-F5B35D24A66C',
  /*North American; Delair*/ '080F217E-EEF3-4F2E-B5AD-C0288683F54A',
  /*North American; Delaware*/ '64826D54-FD5C-449C-B3EC-B7084DA9A089',
  /*North American; Destrehan*/ 'E83BE6D4-9518-43ED-91EC-3E0EB54AB1E0',
  /*North American; Digby*/ '941A827C-3F91-45EA-8506-3E8144DB7533',
  /*North American; Dildo*/ '092B318E-BDAC-4555-B0C9-90F3082BF1E9',
  /*North American; Donaldsonville*/ 'EF71B2AD-7D98-479B-B813-9863260AFF42',
  /*North American; Drift River*/ '4568BFB3-3D1E-4419-8FF1-6015D7AE9291',
  /*North American; Dulac*/ '6DF7F28B-E734-4375-B179-B0E8AB16C465',
  /*North American; Duncan Bay*/ '4F1D1891-CC4E-4DC1-B2B4-94A8247D19F0',
  /*North American; Eastern Passage*/ 'B2368CC0-A690-47AB-816F-E2667F3C6581',
  /*North American; Eastport*/ 'FBCB9352-DC99-450A-A6FA-51C772980375',
  /*North American; Eddystone*/ '09F607E3-8EC1-42FF-BFE3-85B90C926A34',
  /*North American; Edmonds*/ 'A0E5F0A7-BD44-4635-B877-CFB40232FB19',
  /*North American; El Segundo*/ '1C1F703E-9484-4BEF-BA56-44E37D0A9803',
  /*North American; Elba Island*/ '999EC1C9-6486-4870-A06F-2335EAC15CA2',
  /*North American; Ellwood*/ 'DA0AC813-687E-4574-9EF8-55F918D53B7C',
  /*North American; Empire*/ 'EC5FEAF2-073B-4BD3-9EAD-389D14768219',
  /*North American; Esquimalt Harbor*/ '29F0FFF1-0CFD-48E4-9C8E-7738F364E14A',
  /*North American; Everett*/ '55A1E7CF-E462-4F53-8767-3F96A1EAE991',
  /*North American; Fairhaven*/ '351BC45E-E457-4C33-9F54-FA6FF39A25A4',
  /*North American; Fairless Hills*/ '65121004-7265-44B1-9BFE-1B1DF974EFFC',
  /*North American; Fall River*/ '82E0BF3C-A8D1-4391-87C7-9F682E43F41F',
  /*North American; Fermeuse*/ '2D575899-76E2-4898-9D20-4C99AF785C6F',
  /*North American; Fernandina Beach*/ '7CB0A758-3148-477D-B4C5-5C69EF112B94',
  /*North American; Ferndale*/ 'EAEEDA89-BDCC-4BAB-B501-202BFE1FC498',
  /*North American; Forestville*/ '1A539F4F-8203-420E-A880-B37A104AF214',
  /*North American; Fort Lauderdale*/ '65049E08-BF08-450B-B9EC-9373A7F64930',
  /*North American; Fort Myers*/ '0BE6A5C1-3F14-4D2B-8B77-F19324AB5A88',
  /*North American; Fort Pierce*/ 'CA5DB5D2-2D2D-4764-B25F-9BC87512F020',
  /*North American; Fortune*/ '32A84544-E4CA-4117-B4BA-AE8E7B445411',
  /*North American; Fox Harbour*/ '649D5E51-B6C4-4545-A6EE-B5550AFEB1C9',
  /*North American; Fraser Mills*/ 'CD4C1315-C446-4E1F-93A1-228C7B4E6A76',
  /*North American; Freeland*/ 'D31D5F08-0EC2-48E6-B7DD-9DBF43980C6E',
  /*North American; Freeport*/ '43B44380-0552-4939-8C08-3415BC29DC8C',
  /*North American; Friday Harbor*/ '06F2B97A-6D41-420C-91F8-FE56BF65F25E',
  /*North American; Galliano*/ 'C53D0E52-48D9-446B-8768-27136966E28C',
  /*North American; Galveston*/ '8D8D7062-9D67-4503-93CC-80916F3912D8',
  /*North American; Garyville*/ 'E1F349E6-8560-4C40-817B-04C030E40E80',
  /*North American; Gaspe*/ 'CDE33896-2DFB-4CB3-8F3D-26F9D93A357E',
  /*North American; Geismar*/ '7940F684-A3E1-4606-8E05-7DA5A55BA085',
  /*North American; Georgetown*/ '82916AAB-B899-4CA4-A676-8236E7860ABF',
  /*North American; Georgetown*/ '503C8E25-CF65-49C3-A2AC-5AC662B27940',
  /*North American; Gibsons*/ 'DC23DBEF-6EF3-4FF6-9F73-4E7C323CD10F',
  /*North American; Gloucester*/ 'C07963BB-FD24-412D-9666-682D332F2153',
  /*North American; Gold River*/ 'A50D3B39-D551-43C4-B84B-47319E763EA3',
  /*North American; Golden Meadow*/ '28F9E290-5430-4DC0-8A07-EFF76C9E5A37',
  /*North American; Good Hope*/ '7E71D5A5-8DC9-4374-9C8D-431D3B6C8970',
  /*North American; Goose Bay*/ 'FFF8328B-0F6F-4FA8-964F-D78F3BF50783',
  /*North American; Gramercy*/ 'F5F91B3E-ACAE-40EA-9111-F85565631382',
  /*North American; Grand Bank*/ '76CD3EEB-0679-421C-BF77-30151B569FE4',
  /*North American; Grande Anse*/ '3A331063-BF32-440F-B840-5B95E4514777',
  /*North American; Grays Harbor*/ 'F168C37E-C191-4F00-87DE-85AE3B680E2D',
  /*North American; Green Cove Springs*/ 'B6F00162-CEA2-46EE-AD41-28D860D86C5C',
  /*North American; Greenport*/ '809AC517-7479-418F-8FAF-D58DF67508BE',
  /*North American; Gretna*/ '55617FB5-C6FE-4321-BA5A-B35F7810AD7C',
  /*North American; Groton*/ 'B43D90E1-0317-4BA9-92A6-61E740B0C673',
  /*North American; Gulf Gateway*/ '4528392A-9914-4EE2-9ACB-E0932031B5B6',
  /*North American; Gulfport*/ 'E4EE8FF5-16BC-4636-A2D8-200FCC053D29',
  /*North American; Gustavus*/ '39351891-CEA9-4FA7-A0EF-ABAC288FE636',
  /*North American; Hahnville*/ '9623A382-EE30-40EA-9E96-508F62342994',
  /*North American; Haines*/ '68D22D2C-53AD-476D-B4A9-7C88C95FBC87',
  /*North American; Halifax*/ '3D5B6246-B615-40A1-938B-0D8738AE355F',
  /*North American; Hana*/ '88EC5E80-E595-4E61-94E6-590ACFC7FBC2',
  /*North American; Harbor Grace*/ '03A0A418-7DCE-47DE-84DF-24D8802E4C34',
  /*North American; Harbor Island*/ '8FC152C3-BD8D-47F5-A4B0-9286D285C2BF',
  /*North American; Harmac*/ 'DC9B59D3-9B4C-4E86-BDCB-F4A189383C4A',
  /*North American; Harvey*/ '78966EFE-5F24-4F17-9271-4DA958145047',
  /*North American; Havre St. Pierre*/ 'CB9D7602-3DA8-44E2-9864-772239207209',
  /*North American; Hawk Inlet*/ '0AC8D2F0-1B3A-4069-B8C1-FDE819812EE0',
  /*North American; Hempstead Harbour*/ 'D5B96081-D372-4A2F-B146-2BF2CD41BBBB',
  /*North American; Hilo*/ '799B75FB-5364-425C-B6DE-7FCD378B9396',
  /*North American; Hingham*/ 'E8BC157D-F58F-46F5-991A-83A666569866',
  /*North American; Hollis*/ '05C22876-2D92-45AF-9592-713DCB0B3E18',
  /*North American; Holyrood*/ '13DD9728-2830-421A-9B43-2F5316383458',
  /*North American; Homer*/ '44EDE322-837F-41B4-B4E0-41D1CC30405C',
  /*North American; Honolulu*/ '198A2785-EA73-456C-A9EB-C946080C104A',
  /*North American; Hoonah*/ '780F47F8-D178-4120-9F68-D1548786BC44',
  /*North American; Hopedale Harbor*/ 'E43D69E0-B7A9-4896-AC3E-5BF351094FE7',
  /*North American; Hopewell*/ '66F1DD21-4C81-413E-AB7E-EE6A4E53A247',
  /*North American; Houma*/ 'FEE55853-2AB8-4CA7-A4C7-AEA0C45FDC9C',
  /*North American; Houston*/ '6B575E86-E4B6-4138-8FDB-453DD058EDAD',
  /*North American; Humboldt Bay*/ 'CD09E61C-021C-42BB-9E88-338EA66C1568',
  /*North American; Hyde Park*/ '7CE1A594-F660-424E-82D4-F0C0856DA58A',
  /*North American; Iberia*/ '515A2867-8C15-4194-9015-D1A3E47015A4',
  /*North American; Jacksonville*/ '832A9264-5F4D-4A9B-8FD5-61A923D304B4',
  /*North American; Jamestown*/ 'D2AACDEC-3CF0-4275-A14F-BB3535A75FC1',
  /*North American; Jefferson*/ '73F91DB0-70EB-44FA-BE3E-C6625CE711BF',
  /*North American; Jensen Beach*/ '6A94FE52-9DA0-4BB3-9FB0-99454928E393',
  /*North American; Juneau*/ '6062512F-06A6-49C8-85A3-C18B2BFFB6CE',
  /*North American; Jupiter*/ 'DA6EF2A1-FA07-4F2C-BFC4-842239A30EE2',
  /*North American; Kahului*/ '4EA50645-604A-47CC-9721-316BE454C446',
  /*North American; Kailua*/ '0500AC1D-CB4D-4FE0-A376-D2CD57CAE39E',
  /*North American; Kake*/ '24CE05FE-E2B9-4F73-AC9F-FB4F4DC3E3F4',
  /*North American; Kalaeloa Barbers Point Harbor*/ 'E0F84E00-C9F0-4CDB-9F9C-FB6EC6D1B3E5',
  /*North American; Kalama*/ '469B0D61-7F90-47C5-BCD6-FE8B76B6BF73',
  /*North American; Kaumalapau Harbor*/ '9015BA7C-4EF8-4D25-8DC8-024BB0211974',
  /*North American; Kaunakakai*/ '325896B4-F437-4716-AD8B-46A29731665D',
  /*North American; Kawaihae*/ '28146955-333F-4E8E-B702-C60BB39EED7E',
  /*North American; Kenai*/ '49B2323D-066E-42EA-AD34-8FC61B15BC52',
  /*North American; Ketchikan*/ 'A5942424-3774-4B4A-A7CC-33D54D7A1BD4',
  /*North American; Key West*/ '8D6712A1-F959-445A-B008-1CB927485046',
  /*North American; Keystone*/ '3C7BA5E6-69D3-4676-8C70-548AB056ED55',
  /*North American; Kings Bay*/ 'E386333A-4CCC-45DB-8AAC-BD44FAF46ED3',
  /*North American; Kingston*/ '217774A6-3A48-4309-8F16-294E79D858C8',
  /*North American; Kirkland*/ '14EC882E-A5C4-4310-88B2-F72BDB52630A',
  /*North American; Kitimat*/ '82971E72-90F9-40FE-BFD4-97DF5421AC48',
  /*North American; Krotz Springs*/ 'A5889C68-3F68-4829-B204-EDF0C13097ED',
  /*North American; La Conner*/ '6AABBA47-9886-42EF-BA69-5AFF07DB4830',
  /*North American; La Place*/ '6CB9C0AA-32C5-4B24-A22E-3DB5C3C7BF08',
  /*North American; La Scie Harbor*/ '7DE40959-FA50-4A01-A3BE-508C9C69FA82',
  /*North American; Ladysmith*/ '2A6A7B7A-3C1D-44C0-8DA9-DB1418491407',
  /*North American; Lafitte*/ 'F1690F45-76BE-42F0-8079-F6FA8C17B0AC',
  /*North American; Lahaina*/ '345EA912-955D-45E4-AF90-8EFFE2D6DE13',
  /*North American; Lake Charles*/ '9A1D027B-CB76-46C6-86B4-8A861DEDD10D',
  /*North American; Lake Worth*/ '787751CB-94D4-4D1D-B03F-05BA0FFE4380',
  /*North American; Lanoraie*/ '6EDF2CBA-B44C-4DE7-ACDB-8740AB96A1F2',
  /*North American; Larose*/ '2B2A0475-4E7F-45A2-A743-046438C363C0',
  /*North American; Lauzon*/ '23473FEF-193B-41F2-A6D5-A325897639D4',
  /*North American; Les Escoumins Harbor*/ 'F1E4DAF0-61D9-4C3E-A6D7-3F35DC3969CD',
  /*North American; Les Mechins*/ 'FB5F2E43-3F21-4A98-8D36-865BD33679A1',
  /*North American; Levis*/ 'F29F8196-4708-4313-9D90-10811E84A7D1',
  /*North American; Lewisporte*/ 'EDC119CA-CD20-4E63-ADFA-DF3E277CE2AB',
  /*North American; Little Burin Harbor*/ 'A47BAA9A-9E2E-4F07-9961-BFF5378DE381',
  /*North American; Little Narrows*/ 'D78F3B16-3868-4330-8D05-EA4DB3FA889E',
  /*North American; Little River*/ '9ED27BE8-BDF5-4AE8-A8C5-E3CFAC1A6966',
  /*North American; Liverpool*/ 'BF502251-BA9A-496B-80A1-CD6C0D61421B',
  /*North American; Lockport*/ 'B07082F4-9FF5-4154-A56B-A763797C031E',
  /*North American; Long Beach*/ '8728BEDE-18EE-4808-BD89-4C3082F54073',
  /*North American; Long Harbour*/ '450FEC88-8127-4F23-B797-F1A2F89D1D91',
  /*North American; Long Pond*/ '1E24080E-C602-477E-9C69-B2E644D428B5',
  /*North American; Longview*/ '422582CC-A4CA-469A-9510-E4980F7BC60E',
  /*North American; LOOP*/ '11EAD23A-CA37-4561-92A6-C19184B7B547',
  /*North American; Los Angeles*/ 'A1B1D22D-FABC-4EC1-B339-B69EF5AD9A4C',
  /*North American; Louisburg*/ '133DA3FB-7576-4EB4-94A8-181714E5BCBC',
  /*North American; Lower Cove*/ '8DFE2EB7-1020-4494-A0E1-2045A7370ADE',
  /*North American; Lunenburg*/ '2E01501A-1103-4DBC-AF2A-0D8F9CA131E6',
  /*North American; Magpetco*/ '5E745976-DBAD-4824-A35C-C6D4B432D715',
  /*North American; Mahukona*/ '038D855F-CC07-4809-8BFC-7A67F8A6FF7E',
  /*North American; Mamaroneck*/ 'D2A36292-0254-42C2-9AF6-0A13C5FCF88C',
  /*North American; Manchester*/ '42A184E9-6868-4CBB-98BF-C93B983377B3',
  /*North American; Maple Ridge*/ '76D2D7B5-0FE2-4682-850C-041AD0833A0F',
  /*North American; March Point*/ '895D45A3-9B7B-4EB0-81C1-2B6C25829A80',
  /*North American; Marcus Hook*/ '6171F599-252B-4AFA-9631-9826103388B5',
  /*North American; Marina del Rey*/ '48E5E3B0-249F-45E0-9FB5-E739F232B4AB',
  /*North American; Marrero*/ 'CE5B5A77-A738-417B-A32A-5646D4D00BDF',
  /*North American; Martinez*/ '5814A716-EED8-4D94-8FB0-3B2EED3FBA73',
  /*North American; Marystown*/ '851BB621-E21E-4F93-AC28-5EB6270B8F2F',
  /*North American; Masset Harbor*/ '2E1D5F0C-ADF5-4A67-9AE2-F1BA0D4AD9B4',
  /*North American; Matane*/ 'DABF2C05-EAC6-4D80-ACEA-9C60CE728894',
  /*North American; Melville*/ 'A340288F-3A31-4EC7-9FB9-8D02CE996492',
  /*North American; Meraux*/ 'A2CF8B1E-8D38-455E-A834-27C032E4B58E',
  /*North American; Meteghan*/ 'FE2EC6B7-64DC-40C4-A2D9-F36A97CAA9F8',
  /*North American; Metlakatla*/ 'AF07B465-716E-467E-ADB7-5945FAF39129',
  /*North American; Miami*/ 'DC1464CF-9297-4085-90E2-0EB1DEE23614',
  /*North American; Michoud*/ '4DD361A2-34C5-41FA-BFFE-4944547AFABA',
  /*North American; Milton*/ 'F9D5B211-F211-49DF-AEB5-E710CFCA1D22',
  /*North American; Mobile*/ '35E095D1-6916-4133-8547-17568E28E776',
  /*North American; Monterey*/ 'C39A9B44-161B-4478-8E5F-481E2E304097',
  /*North American; Montreal*/ '78B0B045-C562-4670-A2C5-9C35AF4F1DF8',
  /*North American; Morehead City*/ '0E5910B7-A378-4DE9-8506-11D8E5E5B25E',
  /*North American; Morgan City*/ '31C32BFE-D61A-466F-9CB8-E6C502761F1B',
  /*North American; Morrisburg*/ 'EEC6D764-D9CA-4E8D-80CF-F5E181D8B77F',
  /*North American; Morrisville*/ '3CA0DFAA-ECE3-4508-B633-BEAB198D354C',
  /*North American; Morro Bay*/ '6CECF163-D583-44E7-B24C-C3096D1F27B9',
  /*North American; Moss Landing*/ '940F5A7A-0E2E-4123-970E-E8F6DFCC3B14',
  /*North American; Moss Point*/ 'AE294A13-1860-4D70-B4B2-58103AAD83A4',
  /*North American; Mukilteo*/ 'DB71A3CF-15B0-41DE-B11C-F8A30894CD6E',
  /*North American; Mulgrave*/ '58C93854-4456-4236-8AB5-91DF6583A635',
  /*North American; Myrtle Grove*/ 'DA0970EE-5163-43C1-9048-8C68318E9DE1',
  /*North American; Nain*/ 'D8241B97-AB0A-4257-B595-04D35DC0B811',
  /*North American; Nanaimo*/ '4AC84B22-B360-4D57-9325-821667992F9C',
  /*North American; Nantucket*/ '4D1B12C7-EBE2-4DB4-80E0-D0935EB84EFD',
  /*North American; Napa*/ '503EA49B-8691-4BD4-9295-EE2110E9D9DF',
  /*North American; Nawiliwili Bay*/ '55E5915B-84C9-4002-963B-B5F448D32386',
  /*North American; Nederland Terminal*/ 'FBD701E6-AC52-4E7A-BBCF-ABA03A4A335D',
  /*North American; New Bedford*/ '4911E2B7-6379-4E3C-B5D6-7EAC97332CD8',
  /*North American; New Castle*/ 'C38ABAE7-AD0C-49BA-9BE0-02855CF3DC4D',
  /*North American; New Hampshire*/ '39BCC929-F3D3-40B9-B30B-D31346A2FA77',
  /*North American; New Haven*/ '979B4E47-B9D6-4540-A6ED-70BE638B723C',
  /*North American; New London*/ '87A7AA94-D42A-4B5C-B68D-ABDCC4DB7210',
  /*North American; New Orleans*/ '264591F4-E430-4CA6-BFA2-11E5AD301CA9',
  /*North American; New York/New Jersey*/ '4E200B0B-1CDD-40CF-B6D2-8CD5E206CA6B',
  /*North American; Newburgh*/ 'CC5D7CD6-C1ED-4E1E-B43F-F82B263D2CB5',
  /*North American; Newburyport*/ '3B0DE5A1-A12D-47D3-8589-836A9D5E1EBC',
  /*North American; Newcastle*/ '8685FACF-8461-4C44-AF8A-2CF6A004167F',
  /*North American; Newport*/ '2C437724-8FD4-48BC-87E1-695376B5616F',
  /*North American; Newport Beach*/ '3B859C97-9487-45D4-A3C1-A7F62131303E',
  /*North American; Newport News*/ 'E25C327A-E6A9-4FCE-B181-A459483C4E28',
  /*North American; Newport Yaquina Bay*/ '478043D5-22CA-41B6-A25E-959E2905A30A',
  /*North American; Nikishka*/ '654FCD58-E33E-438C-9B15-39676997E60C',
  /*North American; Nikiski*/ '8B0E86AD-27A8-4041-AA3D-9D186F9B4554',
  /*North American; Norco*/ 'F551ADC0-34D4-473E-AB3A-37E4851CB966',
  /*North American; North Sydney*/ 'E192D887-8C7F-48CA-9AFB-F43652283979',
  /*North American; Northeast Harbor*/ '35B00544-AA2A-45E1-B623-A6DC87FBB5E3',
  /*North American; Northport*/ '1F8F2A64-C622-4C14-A73C-D471EF23C1CA',
  /*North American; Northville*/ 'FA3745AC-E5BD-4C4D-90FD-2E33FFBC97A8',
  /*North American; Oakland*/ '5EFD4F8A-68B8-452A-9C8A-AACB6FFA8A2D',
  /*North American; Ocean Falls*/ '16D73A1F-5570-4D01-9F3E-F9625A933BE5',
  /*North American; Ocean Springs*/ 'E80DE869-0DA9-4DD6-A374-1EE2DA348CF6',
  /*North American; Olympia*/ 'A93B16D6-1C31-463E-8D3F-F5B34514A9B6',
  /*North American; Orange*/ '6FDF9064-8B30-4BE3-869B-45494CA75C57',
  /*North American; Ostrica*/ '13C1B698-A29B-4872-8CCC-CD2E3B57AF92',
  /*North American; Oyster Bay*/ 'A7AE2C6C-3DFA-4053-BFA4-066D237EF23E',
  /*North American; Palatka*/ 'E010EF8E-A8F0-4642-941B-E82AC384844B',
  /*North American; Palm Beach*/ 'F5DBD30E-363F-47A4-B39D-74D39EE7B046',
  /*North American; Panama City*/ '53B85727-683A-49AC-8EAD-D72B8FFF8711',
  /*North American; Pascagoula*/ '01C917F0-86E1-4729-BB43-7C3CC504A701',
  /*North American; Paspebiac*/ 'FA136B08-27A4-410C-B8D0-7A7C2F912B45',
  /*North American; Patterson*/ '1F20E7A4-1195-47FE-B27D-670EBE0A5ED3',
  /*North American; Paulina*/ '8A5BC1B9-8717-4DBD-91C0-65688AC9480B',
  /*North American; Paulsboro*/ 'C03EC8CF-2BB7-4176-AFE3-B860100ABB51',
  /*North American; Pearlington*/ 'B9ECA23D-75FA-4018-9896-7CE2B047675A',
  /*North American; Peekskill*/ '5639015A-A3C2-416E-BA0E-AEAFA6F542F2',
  /*North American; Pelican*/ '3A27BA3C-6CF4-4D1D-9C00-3D695C23BD3D',
  /*North American; Pennsauken*/ '38EF96E3-A1CC-4EA3-98F3-00A56D6F1268',
  /*North American; Pensacola*/ '017A6F21-F0DD-4AF5-86C6-3D4B82EAE188',
  /*North American; Petersburg*/ 'CB2E2E88-759B-4931-90D9-45FE65FB46B4',
  /*North American; Philadelphia*/ '46789E55-6C87-44EE-B99A-51672418FA6A',
  /*North American; Pictou*/ 'B9425025-BB7F-4365-80D0-1FF45A2434CF',
  /*North American; Pilottown*/ '5E38913B-774E-49F0-B1AB-F030E97EB75D',
  /*North American; Piney Point*/ '4667CDB9-D217-4EBC-AB71-64EECC715B61',
  /*North American; Pittsburg*/ 'F6AB8B22-AE35-4E2E-9B14-4BA026ED15B7',
  /*North American; Plaquemines*/ 'C2FB876E-7801-4EA4-B30C-950EFBA30CEE',
  /*North American; Plumper Sound*/ '2B4D0D2A-60B9-4DC9-BF89-97FEC58A6813',
  /*North American; Plymouth*/ '7D162CEE-0A2B-4BA7-920F-7A0E9E629D9C',
  /*North American; Point Comfort*/ '62694A30-213C-48EF-8CBB-3C4ACBD27E8A',
  /*North American; Point Judith*/ '5177D842-DE4F-4444-8481-8069127C0E76',
  /*North American; Point Richmond*/ 'CA1189FC-1BD0-453D-8419-0FFDDE27D38D',
  /*North American; Point Tupper*/ 'D47ACF83-2F9A-41EA-AE18-F3E3E61DC2F7',
  /*North American; Point Wells*/ '7DF158A5-A2A1-4A65-807C-1F5E2E6F9E4A',
  /*North American; Pointe au Pic*/ '37E4E1BF-EC08-44B6-8BDB-77B90C7A667C',
  /*North American; Pointe aux Trembles*/ 'D1E9EEB1-5F97-4084-BE28-E95B1231C0A4',
  /*North American; Port Alberni*/ 'AB6ADDB0-C9C1-41BF-8073-410F3CC67E6C',
  /*North American; Port Alfred*/ '19121C51-34CD-4EF9-A1FE-57406FEE4800',
  /*North American; Port Alice*/ 'E17365AC-73CF-4193-8584-15E969AD1A73',
  /*North American; Port Allen*/ '3D19814F-8345-4006-8564-FDC9E5170D20',
  /*North American; Port Angeles*/ 'A11E0B78-1B88-47DD-B39E-084976AD6A70',
  /*North American; Port Aransas*/ '5F8856C9-B806-4E3C-8E3C-29A8105FADA5',
  /*North American; Port Arthur*/ 'F7B83932-9CC3-45B9-9747-26ABA1BC33E8',
  /*North American; Port Aux Basques*/ '34F69599-A5BA-4D42-943E-9AC9D68F51A7',
  /*North American; Port Bayside Nb*/ 'ED5DCF0D-3EE5-4264-8286-847FDFCA67AE',
  /*North American; Port Canaveral*/ '77A02927-F2BC-4F34-BDCD-42636CB5A3B7',
  /*North American; Port Cartier*/ '8FE2A3B6-51D5-43C2-932E-65B32BF218EA',
  /*North American; Port Chester*/ 'D9FE1121-A32C-4A29-BA79-9D8C0DEBA7DA',
  /*North American; Port Coquitlam*/ 'B68E71B3-FE59-4640-883D-2FE52EF685A7',
  /*North American; Port Daniel Gascons*/ '2B399798-C4B0-4B56-92DA-4D460DC32D7F',
  /*North American; Port De Becancour*/ '5F062269-5FB3-4B03-B671-AB17C39015F9',
  /*North American; Port Edward*/ 'CCFDCF59-6A8E-4992-BE73-8469CFBA06CC',
  /*North American; Port Everglades*/ '8985D703-BDF0-4BF2-A950-1F1D93739176',
  /*North American; Port Fourchon*/ '290A5553-1B9B-4284-9194-39947B92CE27',
  /*North American; Port Gamble*/ '3912241D-9F00-492D-977F-29F663161E04',
  /*North American; Port Hardy*/ '10C7E35F-08F5-4240-AAFE-A181A99A999F',
  /*North American; Port Hastings*/ '434EA0BA-FC02-4B4C-AACA-28C4E2195D89',
  /*North American; Port Hawkesbury*/ 'F6E45997-14A2-4784-B410-CBF52A24E102',
  /*North American; Port Hope Simpson*/ '306BC61B-E576-4912-8CD2-50B1165A0F83',
  /*North American; Port Hueneme*/ '97446920-D009-41C5-9502-540F60DA7884',
  /*North American; Port Ingleside*/ 'F7EFCDAA-2FB0-4CE4-9880-768A671EC8E5',
  /*North American; Port Isabel*/ 'B55B1D5B-A1B6-4867-BB3C-69B426430620',
  /*North American; Port Jefferson*/ '2FC8E675-63EA-441A-94E0-336C1EB9B300',
  /*North American; Port Laudania*/ '0E960F60-41C5-4937-86D8-8EAC65EF0BAD',
  /*North American; Port Lavaca*/ '33360B73-9F48-4441-99EB-439767AA3CE9',
  /*North American; Port Manatee*/ '7CDC99BD-F3DD-4BE5-9788-F04A6C47223C',
  /*North American; Port Mcneill*/ '8B066E4E-AB4D-4A75-9FCF-561226C0B104',
  /*North American; Port Mellon*/ 'D01D7919-DDFD-46A2-AE24-D8BD248284C5',
  /*North American; Port Menier*/ '7D09AA2E-E78F-43AE-B7E0-4E85BDAC60F6',
  /*North American; Port Neches*/ 'C039CC54-737A-4AF6-A785-B1543F00D56B',
  /*North American; Port O'connor*/ '9C143DB0-A3BA-4279-9DEA-8BBD6BB0380C',
  /*North American; Port of Camden*/ '96F3530B-F071-4F94-AAE1-E6CF9F45A022',
  /*North American; Port Royal*/ 'D00315DB-CF80-4195-B97C-F21AEBE3CB9B',
  /*North American; Port San Luis*/ '9354A3F9-DA2C-458F-A144-5067EBB0E64F',
  /*North American; Port Saunders*/ '773EDC9C-45F9-4CD5-A103-85FB072B4C2C',
  /*North American; Port St Joe*/ '70C67B6C-3155-4800-A1BF-531486EC17EC',
  /*North American; Port Sulphur*/ '75087934-54EB-414E-A492-2FC90E4A048B',
  /*North American; Port Townsend*/ 'C0204069-0D7E-4356-B058-801C2827ACA9',
  /*North American; Port Union*/ '15DBB53F-F2F6-4086-9B7D-9CBA8C2C76D6',
  /*North American; Port Wentworth*/ 'D84FF577-CC39-49BF-B751-E5545183BD79',
  /*North American; Portland*/ '00AB6E45-FC43-4428-980B-E676E8ECDCD6',
  /*North American; Portland Maine*/ '78126B98-3A0A-4BE2-B9ED-BB32D49F9EDF',
  /*North American; Portsmouth*/ 'F966C624-07EA-44EB-8EB1-6609D5481AA3',
  /*North American; Poughkeepsie*/ 'ED26836C-CAF9-4FFE-AC70-12D58D3AA2B4',
  /*North American; Powell River*/ '7B727038-D27F-4986-A609-339AE0DDB053',
  /*North American; Prince Rupert*/ '47428A7A-F1AD-4B98-AC85-2F7B6DC3752C',
  /*North American; Providence*/ '944891CF-C3C9-43EF-8F87-B07E4EB747D1',
  /*North American; Provincetown*/ '2CF60F3B-9582-492D-93E3-9F3143166416',
  /*North American; Pugwash*/ '6F29001A-76A7-4114-978B-74AFB37C3603',
  /*North American; Quantico*/ 'D0018E59-1690-400D-8274-0C37AEA966AA',
  /*North American; Quebec*/ '5CA0DC21-57F7-4FC2-8A3A-EFD592468B2E',
  /*North American; Quonset Point*/ '9E4F8A72-C097-4FC6-87DE-8E3C5E5FB62E',
  /*North American; Rainier*/ 'DED5BA4B-421A-486F-9D55-7CEB8261FBE6',
  /*North American; Ramea Island*/ 'EF31BC22-A878-4491-9035-94A53C28ABA0',
  /*North American; Redwood City*/ '97EA6069-9713-4BB2-A795-A218B683E93E',
  /*North American; Reedsport*/ '4B007BF5-37BC-40CF-B736-0FC376485E43',
  /*North American; Reedy Point*/ '88FD841B-9A5A-4167-B72F-28C41A559ACC',
  /*North American; Rensselaer*/ 'F2585FAE-3594-42F9-816C-684FAAFB7687',
  /*North American; Repauno*/ 'D9567334-2D0C-4845-8E58-CFEDC84F115D',
  /*North American; Reserve*/ '26683840-99C0-4F13-8133-412098FCB86E',
  /*North American; Richmond*/ '9F386F5C-BD51-457B-99AC-0E3767C16389',
  /*North American; Richmond*/ '213A9265-5320-46F7-A571-B7DE54976F83',
  /*North American; Rigolet*/ '7559EBE0-0B84-4DAC-84E4-B9F3C74E71C9',
  /*North American; Rimouski*/ '7FFE303E-37E5-4299-97F1-3885F9204312',
  /*North American; Rio Vista*/ 'CB8AE99D-94F1-4455-BE37-03168212CDC7',
  /*North American; Riverhead*/ '384ED567-33D4-4F40-B7F2-F60709BA797B',
  /*North American; Riverport*/ 'F48A6462-4FE3-412E-B720-A84E7E05CAB0',
  /*North American; Riviere Du Loup*/ '8ACB71DA-73D6-4088-883F-6B1F9DEB0618',
  /*North American; Roberts Bank*/ '937E290E-90FC-469D-93CD-18DBDF7C2217',
  /*North American; Rockland*/ 'C511BD45-4618-42B6-BEE3-56ED90B9D906',
  /*North American; Rodeo*/ '7BFD585A-7726-4A14-8CC8-8E7A19DC6520',
  /*North American; Roseton*/ '6B6E3366-F741-40E6-80B7-A878AAD1F95E',
  /*North American; Rupert Inlet*/ '4CD82275-5FAC-4B40-B902-61A71CC21680',
  /*North American; Sabine Pass*/ '0E656AF5-401B-44FC-B2EB-C1735A6332CE',
  /*North American; Sag Harbour*/ '44C0B800-9595-4EDD-A509-6FD5F0487D62',
  /*North American; Saint John*/ '200E362F-2C8B-4E76-85FA-99B5182A722A',
  /*North American; Salem*/ '4B3A43BD-8CEC-461D-8CD6-85D6CB492B4D',
  /*North American; Salem Harbour*/ '3CD9E91E-BC85-4768-8379-FEFC88F8D584',
  /*North American; Salisbury*/ '18DAB1C0-1A29-4D56-AD8F-D6B4A9432474',
  /*North American; San Diego*/ '1FB77017-C630-4AA5-99FD-3EC4FFFD4225',
  /*North American; San Francisco*/ '24F83F53-1F92-47B7-9046-5CBD79283BAD',
  /*North American; Sandwich*/ '1439449E-2198-456C-8837-60C0067FBB0B',
  /*North American; Sandy Point*/ '3B689B21-47B9-4BAF-8AA4-9BCF0EA69297',
  /*North American; Santa Barbara*/ '217B1DFD-5360-4C58-8127-1E8D39ED661D',
  /*North American; Sausalito*/ 'AA1B1F48-0CD9-4D1E-9F0C-F77F51563538',
  /*North American; Savannah*/ 'BB449FFD-0859-4323-A80D-62BB94DA0582',
  /*North American; Searsport*/ 'E84C0A8F-363E-4087-A5ED-1E8299635369',
  /*North American; Seattle*/ '29982DCB-1268-4E41-977B-0E8E26B0E307',
  /*North American; Sechelt*/ 'ABEF8F1F-DB4C-4CE8-8750-A7C9FE7D9913',
  /*North American; Selby*/ '165FCE03-D8D7-4B27-8A5F-483CB7F057B2',
  /*North American; Seldovia*/ '6F62E7A0-9F7D-4B15-98B5-B85ECEC7C055',
  /*North American; Sept-Iles*/ '99A5D650-0FFC-423D-B7CC-C0BEE92F7B04',
  /*North American; Seward*/ '0A3632A6-A5F2-4CF2-8E86-8C6D905FDBA5',
  /*North American; Shediac*/ 'CD44E245-6404-45F2-9205-E625222BF926',
  /*North American; Sheet Harbour*/ '0B008DB7-C2B8-474F-9CBD-FCBB5FD1F5B5',
  /*North American; Shippegan*/ '4A871ECA-4E2D-483A-A039-541AE8252A61',
  /*North American; Sidney*/ 'D42ADCCB-729B-40A8-B8E9-30456A31E0EC',
  /*North American; Sitka*/ '8FC1291D-D8FF-407D-A579-E0DE9193B18E',
  /*North American; Skagway*/ 'CA36EB51-A979-469D-93E5-0C8CAE052D7A',
  /*North American; Slidell*/ '5BC3CB64-320D-433B-BBDA-DCF9F9E8EE38',
  /*North American; Smiths Bluff*/ '85A70464-C603-43B8-945D-8218C47CD56C',
  /*North American; Somerset*/ '6F2AF99E-A384-4078-BD55-F27CF9167D9D',
  /*North American; Sorel*/ '26E09C77-8572-4BDB-9710-C43E213D28CD',
  /*North American; Souris*/ '521D1218-2ACC-4A6B-A57F-B3656C8FBD48',
  /*North American; South Dartmouth*/ '685DC34E-91D4-4CF2-A8C7-92380EAAD231',
  /*North American; South Louisiana*/ 'E821EA9C-3214-4A96-B979-0E4034B93BB8',
  /*North American; South Vallejo*/ 'F028ADA5-4799-4E2D-A6DC-70E0F9B9EEB7',
  /*North American; Southport*/ 'FB3A24E2-A880-48A0-930D-79AA95A6C805',
  /*North American; Southwest Pass*/ 'EAB20B14-B732-4632-82F2-BCF54AC59C4C',
  /*North American; Springdale*/ '6F5119E8-7C2C-4B19-91F2-4B9B1B11728B',
  /*North American; Squamish*/ 'A3F1D07A-BABE-4F81-931D-F4E5C0BC949F',
  /*North American; St Augustine*/ '0CD7EAC9-BAA3-40A9-B848-42E33DDED7F5',
  /*North American; St Rose*/ '96C64480-69E0-4666-B3B0-8366881DB461',
  /*North American; St. Andrews*/ 'E682B6CA-6EF8-408D-8238-0E6020346FF9',
  /*North American; St. Anthony Harbor*/ '48B9F9BF-4C57-495D-BD34-F476D6754EA3',
  /*North American; St. Barbe Harbour*/ '7C4A8A7F-2731-468F-AD5E-0DBB6B4F05ED',
  /*North American; St. Gabriel*/ 'EDB6068D-4F24-4240-B6CB-EC14738592CA',
  /*North American; St. James*/ '7C1C54A4-8121-4697-9278-F7D07A0501FA',
  /*North American; St. John's*/ 'FA4A4349-837B-4FC7-ACD5-167371D92A27',
  /*North American; St. Peter's*/ '29329740-D665-4A86-99EB-C52956C5DA70',
  /*North American; St. Petersburg*/ 'FA087278-6726-481A-9952-A88808B34263',
  /*North American; St. Romuald*/ 'EFEDEBD3-FBC2-47FA-82C1-B1E0BE020BC6',
  /*North American; Stamford*/ '4566981E-2F5B-43AE-BD29-6D6D77A47730',
  /*North American; Ste. Croix*/ '35865FFF-0D4F-4D1C-AB04-79B019D85B33',
  /*North American; Stephenville*/ 'F3994182-E3BC-4A7A-97EE-FFBB81BC9984',
  /*North American; Stewart*/ '6843B53F-4E18-442A-BC88-CCBF214D32EF',
  /*North American; Stockton*/ '380CB459-6156-41C0-9338-9619FB024729',
  /*North American; Stony Point*/ '285CBC6E-7DB9-432C-9EC7-400EC355F570',
  /*North American; Summerside*/ '637B3FFC-7123-49C5-ADC6-E3131DD0C5A9',
  /*North American; Sunshine*/ 'D9C334DB-3B4E-432B-8B6D-C11110D611DC',
  /*North American; Sydney*/ '0E2353DB-3267-4EDB-9707-2CA661D24999',
  /*North American; Tacoma*/ 'CCB8B378-262B-4169-9038-637E7BD53AB4',
  /*North American; Tadoussac*/ '0A118C55-F3B6-4B58-BE46-4E49ABCCF4C3',
  /*North American; Taft*/ '81FF135C-4551-4EFA-B4CB-04F575E5D893',
  /*North American; Tahsis*/ '82430F11-F56B-4F2D-B6C4-EA8E55C35415',
  /*North American; Tampa Bay*/ 'FBE5BE4D-A729-4223-AE4D-D3A844A109B5',
  /*North American; Tenakee Springs*/ 'CF47CCEC-0C2E-4652-BA69-255B16AA7E0F',
  /*North American; Texas City*/ 'DB8B6D75-759B-4B5D-8199-28CB23375401',
  /*North American; Theodore*/ '6CC04DBD-1E6D-495A-8F16-D66CBD394986',
  /*North American; Thorne Bay*/ '9070466B-93E4-4E63-A843-37C66ACF0198',
  /*North American; Tiverton*/ 'EF14AEC2-1C84-4BD4-8CF1-0E8C5C06AF98',
  /*North American; Tiverton*/ 'F80D5F13-BB75-48BD-AF6B-79AFC849FC67',
  /*North American; Tofino*/ 'BCD8D803-7BFF-4896-948A-9980592AEAAC',
  /*North American; Tolstoi Bay*/ 'B2377596-7864-4315-984D-1D800F8772CF',
  /*North American; Tomkins Cove*/ '0B98F36A-BF24-4A90-9372-5EC7E1597D10',
  /*North American; Tracy*/ '2F4552D6-29DA-4942-BC37-69D17DDC9131',
  /*North American; Trenton*/ '18BD5FE3-E821-435C-81E6-C5328666F94E',
  /*North American; Trois Rivieres*/ '6057891B-F194-41EF-9062-029AAD6DD721',
  /*North American; Troutdale*/ '83994EB3-EED9-4E66-A27D-64D291723046',
  /*North American; Troy*/ '61833CC9-9508-428F-AAEE-405AF52103FF',
  /*North American; Tsawwassen*/ '0BF91C8D-2FE2-46FB-9C43-C3E3812825CC',
  /*North American; Tullytown*/ 'EE5AD63B-4C4E-434E-87D7-667235789CFF',
  /*North American; Turf Point*/ 'F304D314-ACF1-4CB2-8CA5-BB247A6EF558',
  /*North American; Twillingate Harbor*/ '28225E64-ED50-461B-BEA4-A1A8791BE100',
  /*North American; Ucluelet*/ '4360A372-4648-46ED-896E-E374B3CA0758',
  /*North American; Union Bay*/ 'BAAF9A8F-FE4D-4C14-95B9-2F82D4A3547A',
  /*North American; Valdez*/ '23C5132E-DA09-4067-AC2D-2044032DF24E',
  /*North American; Valleyfield*/ 'FB81EAD6-2174-45FD-B762-364B6AFBEDA8',
  /*North American; Vancouver*/ '6F37F418-7470-406F-9522-3E2E2C90248E',
  /*North American; Vancouver*/ '850F92C7-AC5F-46EC-9DC6-F29529D7488F',
  /*North American; Ventura*/ 'AADFEB38-3114-45CA-AC92-4F10CFD8DA66',
  /*North American; Victoria Harbour*/ 'C24B2F73-76D0-404D-A5FA-AA223B2831E2',
  /*North American; Vineyard Haven*/ 'A2A454C6-78B1-4D1E-8F42-6E9399479579',
  /*North American; Violet*/ 'F8E18DE1-3EE9-42A8-87C3-2381807CF9A0',
  /*North American; Virginia*/ '1D1202DF-A302-461B-8CFE-A0E96E8F4064',
  /*North American; Virginia Beach*/ 'BFC15426-4D5F-4925-AFA8-BAFC05FB4B3F',
  /*North American; Voiseys Bay*/ 'B026DE63-8057-43F8-8B30-42919BF39E96',
  /*North American; Wabana*/ '49B8B54B-0E6F-4B6B-8CAC-DAF68C5DDB8D',
  /*North American; Waggaman*/ '81611F09-C51C-4E13-964F-372C2E8F5EBD',
  /*North American; Warren*/ '9ABE4F46-5928-42C6-B17F-FD7DCB992086',
  /*North American; West Palm Beach*/ 'F906BC9C-5BDE-4EF3-89C3-C4D1A31556A9',
  /*North American; Westport*/ '9B39BA67-015A-42FF-8824-3C49DB2F91C7',
  /*North American; Westwego*/ '0634CA47-60D3-489C-A30E-A5C86AA41300',
  /*North American; Whiffen Head*/ 'D3C136CE-A229-4105-B0C4-9D95E17D2034',
  /*North American; Whittier*/ 'BDF83FDF-2596-4385-B9E6-AC26DFB74678',
  /*North American; Willbridge*/ 'C4C88E27-656E-4281-B91E-CC28912102A6',
  /*North American; Wilmington DE*/ '067AFB97-070E-4912-8FD3-8B1672BFD51D',
  /*North American; Wilmington NC*/ '83A263CE-CB4C-41AB-A807-556A82DF0B1A',
  /*North American; Wiscasset*/ '3FDB8402-99F0-4243-AC7C-E1AA8B9E62A9',
  /*North American; Witless Bay*/ '584B0F2D-6A35-4772-9073-1E50537CFEED',
  /*North American; Woods Hole*/ '8B81CB6C-DC11-4C75-827B-2C230705A1BB',
  /*North American; Wrangell*/ 'D94C15EB-9C3F-4C3E-B9FD-85BC6B9BA8E5',
  /*North American; Yakutat*/ '260CF9F3-B0E0-4108-A04A-7D1E475802BC',
  /*North American; Yarmouth*/ '29D530D6-62B3-4BB0-B39A-0977CF74BE5F',
  /*North American; Yarmouth*/ '3374A6D0-7621-4D30-8E28-F899A94FE3FC',
  /*North American; Yonkers*/ '9EA42A44-17FC-4B4D-B182-3700E77CD660',
  /*North American; Yorktown*/ '9B189718-B95C-4671-B0FC-9DD9BD72840F',
  /*North American; Zeballos*/ '3635C9B6-743D-41EA-B6AF-B15F6987D164',
  /*North Sea; Aakra*/ '2755C91A-2A74-412B-BC7B-1CBC58C02C78',
  /*North Sea; Aalborg*/ 'EDEE9042-6134-444D-957D-CD4B33E96BCF',
  /*North Sea; Aalsmeer*/ '74DA3593-486F-4C02-AC82-15519BDCA625',
  /*North Sea; Aalvik*/ '1766B1FD-1525-4372-9A48-F7E0C9FF083E',
  /*North Sea; Aardal*/ 'CAC5FDE3-69CD-446B-9882-01760B834D1A',
  /*North Sea; Abbenfleth*/ 'EF8C9324-7494-4767-BA14-178B346663C8',
  /*North Sea; Abelsnes*/ '4995BD78-DAEB-4646-B421-5277618D25A9',
  /*North Sea; Aber Wrac'h*/ '1AEAC59C-5CE5-4B3A-A451-2FEBE71345EC',
  /*North Sea; Aberdeen*/ 'DA541AE2-4E3E-440F-AD5F-F8EA5DDD09C3',
  /*North Sea; Aggersund*/ 'CA60C385-04A9-477B-A854-2F417901C1CF',
  /*North Sea; Agnefest*/ '94B9F180-F8D2-44BA-B884-8CA02D279BBB',
  /*North Sea; Agotnes*/ '9BC8C98C-3ABA-4E4D-80E9-6F89FAFB6223',
  /*North Sea; Akkrum*/ 'A3EBF5C3-8929-45E0-8EC0-E5AA60FBCEF4',
  /*North Sea; Akrahamn*/ 'A59313A8-0C3B-4216-8050-BACB9F9216B5',
  /*North Sea; Alblasserdam*/ 'B3ADA007-6C19-4F49-9225-B1C538EC8878',
  /*North Sea; Alderney*/ '92ED24BE-D423-45BB-A4DA-345D4B8B1CC0',
  /*North Sea; Alphen aan den Rijn*/ '7F9F28B4-E198-4B59-82E7-D3FD3DA1E27E',
  /*North Sea; Ameide*/ '0F4474B8-C22A-485D-A6A5-DAA2BEAADEDB',
  /*North Sea; Ameland*/ '313426C6-2141-476D-8DE1-A923779E3754',
  /*North Sea; Amrum Island*/ 'F26200CF-1119-4D0A-97E1-54A8E91CA4FD',
  /*North Sea; Amsterdam*/ '1273A405-4A72-401A-9E59-A65EB5B59856',
  /*North Sea; Andijk*/ '3DB94612-24BF-4F64-B29B-EC370C48677C',
  /*North Sea; Antifer*/ '3F833932-3B79-4D9A-A324-D5D78C86794B',
  /*North Sea; Antwerp*/ 'A30D60A4-D96E-47E7-81E7-03800591867C',
  /*North Sea; Arbroath*/ 'A10FC948-5C32-49DE-8C69-871A4309B8E1',
  /*North Sea; Ardalstangan*/ 'AEFAFC22-FE87-47FD-A029-C62B18F53721',
  /*North Sea; Arendal*/ '484A4F7E-E107-4ED9-BBDF-042D9A45A583',
  /*North Sea; Askoy*/ '12310772-2B42-47C3-9137-06BA952F863F',
  /*North Sea; Askvoll*/ '16092DF6-5FDC-45D0-B239-59BA0DA91872',
  /*North Sea; Asvall*/ 'C19B3318-1EF9-4E29-BFE6-8986335FF3D6',
  /*North Sea; Austevoll*/ '7299336D-6A42-4449-9BE3-F615F15308B0',
  /*North Sea; Avaldsnes*/ '84E0E2F7-325E-4CEC-90BE-85A0B82CBDAA',
  /*North Sea; Axel Sassing*/ '79761B1F-A99E-4DBE-8A9B-707106FF59C2',
  /*North Sea; Baasrode*/ '5B586462-C7AF-4CF7-96EB-6075592500F8',
  /*North Sea; Balholm*/ '97A09179-D056-4A07-BEF0-E6895FD861AC',
  /*North Sea; Baltasound*/ '57EEA99D-CA2A-48B2-99E4-3153E8231EEE',
  /*North Sea; Baltrum*/ '67A7C03C-3435-40E7-B253-F8B28D4E1E3D',
  /*North Sea; Bamble*/ 'BD8546A1-EC45-49F0-A8BB-4E7F94BC9B60',
  /*North Sea; Bardenfleth*/ 'BD2270F8-C0F2-4E67-A425-0BC717A32E17',
  /*North Sea; Barrow on Humber*/ 'DD0F3CB0-45CD-4AE0-A603-8AA54379D7B5',
  /*North Sea; Barton on Humber*/ 'D1B7AC12-934B-4FA3-B1D0-E28B7AD29545',
  /*North Sea; Bedhampton*/ 'BACC072F-1BFA-4273-9562-CD5A6E3297A6',
  /*North Sea; Beidenfleth*/ '92C70C00-2F7D-4D34-BDA9-96BEC11D6E7D',
  /*North Sea; Bekkjarvik*/ '28E2C2DF-D2CD-4965-B66B-A0996B6C31AB',
  /*North Sea; Bensersiel*/ '24DBC5DB-489C-4929-9030-D98BC65E9EFE',
  /*North Sea; Bergambacht*/ 'C528B5BA-9795-47DC-B726-C495073A3F55',
  /*North Sea; Bergen*/ '1BCE9909-D7AA-47D5-B377-CFCF1E309727',
  /*North Sea; Bergen-op-Zoom*/ '457C8D72-CF7D-4F23-BAB1-EB224E29723E',
  /*North Sea; Bergum*/ '22315BA6-66CF-444E-9CA8-091A67EA9C2C',
  /*North Sea; Berwick-upon-tweed*/ '97E651E3-2FB7-446D-9CB5-2C2E2EC4E84F',
  /*North Sea; Beryl Terminal*/ '4F2B60A0-B64C-4527-9287-FD1ACFCD9AD2',
  /*North Sea; Beverwijk*/ '414D4E54-9E5A-43CD-A3E4-A496A90180C5',
  /*North Sea; Blexen*/ '9463EF4E-46EC-409F-B00E-2A05A4B6A328',
  /*North Sea; Blumenthal*/ 'FD36FD58-DD97-4836-9BC8-A39DF0343DF6',
  /*North Sea; Blyth*/ '25BB4806-E955-40B5-9920-E55918A7932B',
  /*North Sea; Bohus*/ '56909109-46E1-4711-8B2F-C61D26124936',
  /*North Sea; Bokn*/ '1BCF82DF-882B-44CF-BFCC-D9369ED74805',
  /*North Sea; Bolnes*/ '902F759C-C877-4CF0-A006-459451B0D2D6',
  /*North Sea; Boom*/ 'CA02BD8E-7695-48C3-AFDA-5CC4096908E5',
  /*North Sea; Borg Harbour*/ 'FC481C66-279F-4024-87E1-D89ACF21A33C',
  /*North Sea; Borhaug*/ 'E3F0BE18-C699-4DED-A95D-73DB0B972B7B',
  /*North Sea; Borkum*/ '0E5C7F08-E9A6-490F-86F7-61D9930E943C',
  /*North Sea; Borssele*/ '9C4EEF0E-1129-46A9-94A4-DE0808E9FB6B',
  /*North Sea; Boston UK*/ '025AA9C6-87D5-4C1E-92E7-0481262264E5',
  /*North Sea; Boulogne-sur-mer*/ '133FFBC8-1EB8-4B92-9A83-24B4A8D53D38',
  /*North Sea; Bovagen*/ '476517D3-0594-4FDA-B4BB-D84F262B30E2',
  /*North Sea; Braefoot Bay Terminal*/ 'F489292E-692C-4393-8170-F478A897EBAD',
  /*North Sea; Brake*/ '277F8C46-E73C-4754-906C-DCB4DB499104',
  /*North Sea; Bremanger*/ '20478C9D-D007-41C6-BD73-57D385AEB5BC',
  /*North Sea; Bremanger Quarry*/ '5228C9E9-AC0B-4922-94A4-4FA675636B49',
  /*North Sea; Bremen*/ '9A72D57D-AACB-4278-AA1B-E0A9596EA0A1',
  /*North Sea; Bremerhaven*/ '0E5B8ADA-327C-4423-8EEA-A70D907C1989',
  /*North Sea; Breskens*/ '2C13C656-167B-40B8-8950-AB823A5DD0FB',
  /*North Sea; Bressay*/ '8A26A991-9420-4E7F-A4B3-5113EF00350F',
  /*North Sea; Brevik*/ '135308AD-ED52-4A65-A57B-6B06359F4D3B',
  /*North Sea; Bridlington*/ '8460BBFF-49DE-413B-81B7-7A14825D8088',
  /*North Sea; Brightlingsea*/ 'FA2F598B-0150-40AD-BB11-43563094667E',
  /*North Sea; Brixham Harbor*/ '0732DBE4-86F6-4AEA-AF2E-92819DEDDD25',
  /*North Sea; Brofjorden*/ '7056EFD0-6DDF-41B3-84FA-8F0115714312',
  /*North Sea; Brokdorf*/ 'C6A24F39-3E02-4C8E-9EE9-F65317FDB101',
  /*North Sea; Bruges*/ '9B3D2E29-A546-4594-9D07-A38BE5A277D7',
  /*North Sea; Bruinisse*/ '2159B2BE-3F36-4C35-97E7-FC59F2828C60',
  /*North Sea; Brunsbuttel*/ '794C2A0D-8F43-43E1-9D2F-04769AA33742',
  /*North Sea; Bryggja*/ '1A5707B9-1763-4A2F-85B4-A0E3510F30C3',
  /*North Sea; Buckie Harbor*/ 'BAF7A3BF-A249-462A-819D-2422CE4C2AB4',
  /*North Sea; Buggenhout*/ '27863413-8E65-4D63-8222-F913DF695D3D',
  /*North Sea; Buitenhuizen*/ 'E9F3C518-ACC7-4321-9562-276BA297EE09',
  /*North Sea; Burcht*/ 'FB55CE76-A69B-48CA-B707-B4CD4AAA7CFC',
  /*North Sea; Burg*/ 'F3F39BD1-8546-4651-A1FD-90A95DCAEADC',
  /*North Sea; Burghead*/ '9CE3F585-8DB8-4C37-B3A7-DFDCE59B2B54',
  /*North Sea; Burntisland*/ '76288AD0-DF72-4765-98C5-E5B01731E234',
  /*North Sea; Burton upon Stather*/ 'AD08704C-9B21-449A-876C-FE42719F1E33',
  /*North Sea; Busum*/ 'CBB77D4E-B4B7-4C7D-973A-ED3B85787D13',
  /*North Sea; Bygstad*/ '4CF5410E-F57C-4428-B4FB-B76F2851AD97',
  /*North Sea; Caen*/ 'B819E128-D5DE-4FE8-83A2-EFC26A33643C',
  /*North Sea; Calais*/ 'D991E5EA-3AF3-439B-9BB3-37C741D0D905',
  /*North Sea; Canvey Island*/ 'AE7B9C2F-C054-4D8A-BE9E-131059AACA2D',
  /*North Sea; Capelle aan den Yssel*/ 'E463EBE6-F92D-4308-A324-66057EBDC4C8',
  /*North Sea; Carolinensiel*/ '4C90AB97-A0B0-49DC-9BC9-5E62203A63ED',
  /*North Sea; Carteret*/ '7C86B1C3-ABBA-4CF7-B95D-013F7AE52ED2',
  /*North Sea; Chatham Docks*/ 'AE81367A-BE76-4600-AC27-402B6A07B018',
  /*North Sea; Cherbourg*/ '4697F5DF-76EA-45F6-B2D2-61CD5E165C61',
  /*North Sea; Clacton*/ '7AAEFBAF-FC4B-4D82-8B52-EBA059A2B49F',
  /*North Sea; Cliffe*/ 'B6A533D0-3227-4818-9040-75CF5DB55599',
  /*North Sea; Cowes Harbor*/ '7B7EBB78-145E-4186-9797-492FD1FCDAD3',
  /*North Sea; Creeksea*/ 'AD96B4FF-7FDF-4B39-AF11-F9A1BF3CF5F2',
  /*North Sea; Cromarty*/ 'E271F25B-3DCF-49FF-9B65-61F0CFDDFCD5',
  /*North Sea; Crombie*/ 'F2156343-258E-4BF9-8BAD-A1C577814353',
  /*North Sea; Culemborg*/ 'B7285FF8-6764-4DDE-B3F3-0FE64AC97F74',
  /*North Sea; Cullivoe*/ 'DA8D709A-C4BF-4457-88E9-9AA0559A8B01',
  /*North Sea; Cuxhaven*/ '2BA4489A-F341-4EFB-9DDB-F150E480BE83',
  /*North Sea; Dagebull*/ '5818D83B-FEC3-495D-B379-53D5EB99D551',
  /*North Sea; Dalen*/ '167DED1F-0D01-49F6-83A7-FE5E5A2CFD01',
  /*North Sea; Dartford*/ '0BEA9AD1-41E2-4507-AE7E-CF969A9DAC90',
  /*North Sea; Dartmouth Harbor*/ '5E91FA52-EB74-42EE-A3B9-7354D4B3A8E4',
  /*North Sea; Delft*/ 'F66CFA91-7198-4FE7-9CD3-E5741EE6C1CC',
  /*North Sea; Delfzijl*/ '558A6D79-744E-4707-8759-AF00637B8599',
  /*North Sea; Den Bommel*/ '596C0442-59BC-4961-852A-8A1E00762B3F',
  /*North Sea; Den Helder*/ '42B9AD68-5E22-4A92-B570-525EDA518902',
  /*North Sea; Den Oever*/ 'E9A21137-BCBF-4FC1-8D2F-98370DA643D6',
  /*North Sea; Dendermonde*/ '56568C9C-BF83-4CE0-ADDD-7E5A2B76FCE3',
  /*North Sea; Devonport*/ 'F201D57A-C276-4750-8AB0-43F3E1AA7174',
  /*North Sea; Dielette*/ '689315D6-75AB-42EE-BF11-F4E5D0DF688F',
  /*North Sea; Dieppe*/ '56BA2910-6557-4527-815A-3D0F86D039D0',
  /*North Sea; Dintelmond*/ 'A311CF4C-BDF1-410B-B360-E3F83D1D8085',
  /*North Sea; Dirdal*/ 'BBBEC710-C5FD-4C68-8DE6-5C85E3300CBA',
  /*North Sea; Ditzum*/ '4DB3B351-F447-423A-85D6-C69EA64EC493',
  /*North Sea; Dolvik*/ 'FCB27A39-E89E-48D6-BEC1-FE1228E733F9',
  /*North Sea; Domburg*/ 'A9B6A2B5-711A-446A-8ED3-C27A70687B35',
  /*North Sea; Dordrecht*/ '68E69AE2-F5D4-4085-BDAE-A6BF3CBE908B',
  /*North Sea; Dover*/ '70565153-0678-4171-8A82-BE0B7D7A9702',
  /*North Sea; Drachten*/ '4D8E8418-689E-4184-9021-AB133ED74598',
  /*North Sea; Drammen*/ '00137D09-5B38-459F-9879-A665141E583C',
  /*North Sea; Druten*/ '58861998-83A3-4571-9C9C-414F9395A09A',
  /*North Sea; Duclair*/ '94181CC5-2ECE-4820-9DAE-905207604E2C',
  /*North Sea; Dundee*/ '3750DDD7-F680-4085-A68A-5C821EEC3530',
  /*North Sea; Dunkirk*/ '355460A0-DF5B-4DD1-BFD8-B1108D988A99',
  /*North Sea; Dusavik*/ '6D7EC573-37F8-4552-8E09-BAB8B82F1126',
  /*North Sea; Eemshaven*/ '64E3F841-78E4-4EB2-993D-BF029D961592',
  /*North Sea; Egersund*/ '63A9A042-4FD0-4941-A2F5-65E27D3985B8',
  /*North Sea; Eidfjord*/ 'C96C6477-8A39-4106-B64C-31EE91F02E8E',
  /*North Sea; Eikefet*/ 'FCDFC958-1C88-412B-B8A1-8EA34C6CEE8C',
  /*North Sea; Eikefjord*/ '28BC2539-DD5C-4E52-858B-68B550E6D7DE',
  /*North Sea; Einswarden*/ 'BA559EDA-85CB-4EC4-8215-972DCDD082A4',
  /*North Sea; Eling*/ 'B4066B2A-1ED0-4422-9042-26CDBC988668',
  /*North Sea; Elsfleth*/ '0D431DC5-5E9D-46BE-9048-C9826C78BA22',
  /*North Sea; Emden*/ '1856B7B5-D448-455E-9B64-231B1ED3D2C4',
  /*North Sea; Engene*/ 'EC1B6687-DF92-4465-B68B-D31970036B3A',
  /*North Sea; Enkhuizen*/ 'E5C0D3D4-F846-47AA-9AD4-BC62CCD67C33',
  /*North Sea; Esbjerg*/ '4471C76A-B76A-400A-A7A3-63417E72D549',
  /*North Sea; Espevaer*/ '5E9EA326-F143-44FB-B624-DEA38BF097DF',
  /*North Sea; Espevik*/ '6842C26A-D191-43CC-965B-A06DB0B14DE5',
  /*North Sea; Etne*/ 'A3299B9E-4ED4-4CEF-97BE-D586C216E516',
  /*North Sea; Everingen*/ '5034A4AD-5A51-4E6E-B5AB-B13BB9BB37C6',
  /*North Sea; Eydehamn*/ '1310C268-10B1-4E0D-8D2E-5B2F26514395',
  /*North Sea; Eyemouth*/ '624743C1-6916-4651-85BA-C92FDB008442',
  /*North Sea; Fagerstrand*/ 'A2E71D9D-1CB7-4248-9208-523E3BDF91B0',
  /*North Sea; Farge*/ '7BA8A8F7-B3F1-454B-8CF8-4A3C98F73F2F',
  /*North Sea; Farmsum*/ 'B2DA753E-97E0-4D52-A63C-FF3FB8731E86',
  /*North Sea; Farsund*/ 'F8BC3AF2-CA6F-474E-A9DC-14AF913454E5',
  /*North Sea; Fawley*/ '99FDCBA9-088B-413C-A116-A9DEFF035346',
  /*North Sea; Fecamp*/ '9DDAB912-1EE0-47FE-9D0E-0114BFE57472',
  /*North Sea; Feda*/ '383A034E-4351-42A5-A163-45762619D3AD',
  /*North Sea; Fedje*/ '3AA5AAE8-674A-4EC6-8A33-7D42A4D15909',
  /*North Sea; Felixstowe*/ 'A06DEAFF-F28A-4F1F-BFDD-C6C7A0059A7D',
  /*North Sea; Fischerhutte*/ 'AC38E6F6-7879-4D26-9778-4DCAB5FF18B9',
  /*North Sea; Fishbourne*/ '2B4A6447-96C1-4B09-B58C-EF14D64A7A95',
  /*North Sea; Fitjar*/ '39F166A2-F84F-4AB6-BB2A-698210778B80',
  /*North Sea; Fjallbacka*/ '73A2C515-4F80-49FB-AA38-42000A9735C9',
  /*North Sea; Flaam*/ 'D98BB5A6-50DD-42C4-BEB2-95A101AB6851',
  /*North Sea; Flatoy*/ '01886E31-DBEC-4658-91DA-EAA58673450F',
  /*North Sea; Flekkefjorden*/ '0DA911EF-119A-44E7-A9CF-982B66DAB39C',
  /*North Sea; Flixborough*/ '4487BE29-B45F-41CE-8C98-FBB8C54014FF',
  /*North Sea; Floro*/ '49E33D66-9010-4854-8331-7D4CBEEAC841',
  /*North Sea; Flushing*/ 'E318233D-2C22-4CB5-8C8F-A935B529B764',
  /*North Sea; Flushing East*/ '2072476C-5567-4B83-8542-D110F11F550E',
  /*North Sea; Folkestone Harbor*/ '3C44AC87-81C7-484B-9CEB-87378A940190',
  /*North Sea; Fonnes*/ '8B8B87A1-8180-4D0E-906B-A8FBFD002D91',
  /*North Sea; Forde*/ 'F33E343A-6041-492B-8A74-AD366EC82371',
  /*North Sea; Foresvik*/ '2A236F95-4C66-47AD-B594-31A7501B7A29',
  /*North Sea; Forsand*/ 'A36C12F5-A04B-43A0-A8D5-E9611BEED7D0',
  /*North Sea; Forus*/ '51ADB63D-BD39-4876-8939-08B2821682F2',
  /*North Sea; Fowey*/ 'BB15DB9B-8439-4A5B-9AA7-7FD1C93A34AD',
  /*North Sea; Foxhol*/ '28C5B978-9E2B-4996-B3FD-F59AE3F1C651',
  /*North Sea; Franeker*/ 'A07E215B-2EFF-4F2F-82A7-A879385E4CF7',
  /*North Sea; Fraserburgh Harbor*/ '864329B0-2806-4E6C-85B8-6DFCEA86689C',
  /*North Sea; Fredrikstad*/ '192FD546-206D-49A0-A5D2-32540B5D8915',
  /*North Sea; Friedrichstadt*/ '5A3A2757-6216-4007-9105-BAC4E5633DE9',
  /*North Sea; Fur*/ 'E30EA8D4-C6D6-4AE5-8AAB-AABCA43EB83C',
  /*North Sea; Fusa*/ 'D2AF8B08-6B05-443D-99BE-468B2AA9DF0C',
  /*North Sea; Ganspe*/ '25B172D4-DFF1-4CF3-B630-B28EEEB0AB82',
  /*North Sea; Garnes*/ 'BACAD68D-4DFF-444A-80A9-ACD705A7AA92',
  /*North Sea; Gaupne*/ 'B16472A4-5491-419A-B8B9-33C628064451',
  /*North Sea; Geertruidenberg*/ 'EA309452-92EA-4BBB-82D4-037C36AD61D4',
  /*North Sea; Ghent*/ '0024BA19-3C81-4E04-9BA2-E54FE8CBAA90',
  /*North Sea; Gillingham*/ '464FCF75-5402-45F6-8878-06CCFE878198',
  /*North Sea; Gjevingstangholmen*/ 'A29D78F0-C289-4A8E-8E85-837A83A08816',
  /*North Sea; Gluckstadt*/ '64212B4E-05DE-43BF-80F4-43B6C6D429D0',
  /*North Sea; Goes*/ '7E13AC2A-B36E-4F64-80FA-4B542E4E7F04',
  /*North Sea; Gonfreville-l'Orcher*/ 'E3D1E661-B1F9-4524-90D9-3030D7E9B180',
  /*North Sea; Goole*/ 'C74C3F76-A897-44CE-9CB4-42C2641BCE6E',
  /*North Sea; Gorey*/ '8DBD685B-A589-4D6D-A100-F30D6E2D5209',
  /*North Sea; Gorinchem*/ 'DAA35AAE-3FED-49F8-AF9C-24835CC5EDA4',
  /*North Sea; Gosport*/ '72722D7B-7B7B-4802-959A-797700F30BFC',
  /*North Sea; Gota*/ 'D6B3AD86-226A-4747-BA50-B5F5F6438F63',
  /*North Sea; Gouda*/ 'AF172C7B-78D9-47C4-8832-874CD0773F3D',
  /*North Sea; Grand Couronne*/ 'C4731CA9-2461-4E2C-BAB6-EF85B544A2D7',
  /*North Sea; Grangemouth*/ 'C013FE36-8B64-4480-BF0F-FC8EF21DC504',
  /*North Sea; Granton*/ 'FC3DEED1-F212-4192-B2BE-82DEEBDB5001',
  /*North Sea; Granville*/ '57A30168-185C-4F41-90B8-00703A07CA61',
  /*North Sea; Gravelines*/ 'B87E9941-683C-4AE2-A074-4CB6AC415A91',
  /*North Sea; Gravesend*/ '272FA678-ADC6-4AA3-BE20-654EA21F22C9',
  /*North Sea; Greaker*/ '8956149A-0E5A-4C16-A52C-59D03B1A4FA1',
  /*North Sea; Great Oakley*/ '4D9BD1C8-6781-40A7-B2C9-EAE892CC5EFD',
  /*North Sea; Great Yarmouth*/ '245DBDAB-B575-44B2-A8B5-B48776078BC6',
  /*North Sea; Grebbestad*/ '5EC43F63-79F0-4B3F-BC63-B901D2EC1DA0',
  /*North Sea; Grimsby*/ '47319BE5-A7C5-4081-8789-D6F49C2E1BAD',
  /*North Sea; Grimstad*/ '0C051174-414D-4E7B-977C-3B16973ED2BC',
  /*North Sea; Grobbendonk*/ '1B2AEF23-3424-460D-A050-4C86D9BA99CF',
  /*North Sea; Groningen*/ 'A7EE4321-F1CF-44F0-A770-5AABB1198B11',
  /*North Sea; Groot-Ammers*/ 'EFBBE9D3-0C81-4100-B4D6-2A2ED7C0221D',
  /*North Sea; Grouw*/ '0E8B4BB1-C2D7-458D-8654-65259DB6867A',
  /*North Sea; Grove Wharf*/ 'CC16051E-8A44-47BC-9B1F-D9E9148D71A0',
  /*North Sea; Grovehurst Jetty*/ '6101FA86-074C-49E7-A4D4-B55CFA035C76',
  /*North Sea; Gryphon Terminal*/ 'D88C6A1C-4394-488C-A71F-C60FA19A5389',
  /*North Sea; Gudvangen*/ '52CE7FAA-3DD4-4AD5-84F0-9F25E8FD1CD9',
  /*North Sea; Gunness Wharf*/ 'E068BD35-333C-4E1A-9842-AC11EF54C8DC',
  /*North Sea; Haarlem*/ '5064C211-9646-45C7-BA7E-85236C3D5BA1',
  /*North Sea; Haavik*/ '6004E148-D29B-4080-9073-FEF7DA00AFD0',
  /*North Sea; Halden*/ '2323FBEA-744F-43A7-8DD1-2819491DEA25',
  /*North Sea; Halhjem*/ '00E37A2F-5C24-49D9-91A7-FBC2FD2852B2',
  /*North Sea; Halsvik*/ 'F5694DF8-581E-43B8-AB1D-319BFCA65316',
  /*North Sea; Hamble*/ '7A3B3F8E-0498-4662-90C3-253DB081B2EF',
  /*North Sea; Hamburg*/ 'E8B8F1F5-DB33-483F-BF23-063772B8F28B',
  /*North Sea; Hanstholm*/ '709E5F37-9CB7-4ABF-A1F2-87C89DB9CA54',
  /*North Sea; Hansweert*/ '9C048169-6F4E-430A-91D3-ACFD142687EE',
  /*North Sea; Hardinxveld*/ 'DA2D0210-A954-49FD-A880-606CC3097A62',
  /*North Sea; Harfleur*/ '93F272DA-2201-490C-973E-D15E2F502767',
  /*North Sea; Harlingen*/ 'F02EE1AF-1122-4091-BED6-8266666A8ABE',
  /*North Sea; Hartlepool*/ 'C397039A-7B60-46AE-A1E8-3EF4E9AA5CA8',
  /*North Sea; Harwich*/ '5EA191D1-C945-4CCA-A6F3-77C8FF6B89D4',
  /*North Sea; Hastings*/ '303B4440-1229-4A66-9F00-13ABBEF165B1',
  /*North Sea; Haugesund*/ 'BE633A46-DDC2-4E72-83FF-8903C7D91202',
  /*North Sea; Havneby*/ '9F158AF8-17EE-406A-970E-17C8CDEFA9FF',
  /*North Sea; Heemstede*/ '3669B58A-D38E-4723-91AC-C653C21B8160',
  /*North Sea; Heerewaarden*/ '6BFB6EBB-C7CF-46EF-A8A7-0D8D584AF3B8',
  /*North Sea; Heerjansdam*/ 'DC727427-7F00-4D38-9FAD-ED1EDA9F815E',
  /*North Sea; Helgoland*/ '13535560-6856-423D-8304-C47A1EE22838',
  /*North Sea; Hellevoetsluis*/ 'B6F4EF3E-9D42-4880-A742-3E86B0BC0491',
  /*North Sea; Helvig*/ 'E0A991BD-EF2E-4DCA-9F6F-4F9C6ABA5B89',
  /*North Sea; Hemiksem*/ 'E2A06D1C-3E5F-4BD4-B6F1-ABF7952A31FD',
  /*North Sea; Hendrik Ido Ambacht*/ '3FAD0F04-A89E-493B-8CD9-617C88BE6163',
  /*North Sea; Heroya*/ 'C13F7BEE-456F-441E-9F34-752F43005DED',
  /*North Sea; Heroysund*/ '943C8E7F-3496-462E-A105-89FD64A0BBBD',
  /*North Sea; Herre*/ 'CE3CCD61-A3C0-4B8C-A08A-7A0D3729EB54',
  /*North Sea; Hessle*/ '9254D2CE-4D79-4B6D-A945-872225C5FDF9',
  /*North Sea; Heusden*/ 'C78E170C-5B4A-46B3-AAEF-53F0B840A187',
  /*North Sea; Hillegom*/ 'D4E71A00-9C4C-4714-916F-50ABAD71320D',
  /*North Sea; Hilleren*/ 'FC3A6F15-9056-41A1-9713-89AE748A38DD',
  /*North Sea; Hillevag*/ '0A1C51FA-1B4A-4B6D-B1F9-6201FC92EF44',
  /*North Sea; Hingene*/ '0252B6C8-9C0E-4D49-87F1-F323EC9EBCCF',
  /*North Sea; Hirtshals*/ 'A38F24CD-DAC7-41CB-B6C9-6BE34CFE6ADF',
  /*North Sea; Hjelmaas*/ '4DEF38AD-04BD-40D4-BCAE-9D11C2BF5BB8',
  /*North Sea; Hoboken*/ '7FCE24F9-DC99-4F92-B0FF-6BC4B7EC73EF',
  /*North Sea; Hochdonn*/ '068BAAC7-7D2D-4023-A3FF-61E29AC49A84',
  /*North Sea; Holm*/ 'E86CA7EE-2542-4A67-AC6F-15D0B488FA56',
  /*North Sea; Holmer Siel*/ '52ED64A9-A0A5-4DA0-BB98-4AA0F8A05B97',
  /*North Sea; Holmestrand*/ '466E4F16-D541-490C-B649-BDB505993389',
  /*North Sea; Honfleur*/ 'E2D3FF5E-B0F6-4556-A596-79605BD411FD',
  /*North Sea; Hoogezand*/ '2D0BDF0B-13DA-4D84-A5CA-262E089BCB4D',
  /*North Sea; Hornum*/ 'F1A81639-34C4-42AB-9700-76F25B7029A8',
  /*North Sea; Horten*/ '1D250999-F578-420D-81AA-5BE155F14F1E',
  /*North Sea; Horumersiel*/ 'E2659040-02CE-4FC9-A973-55509358B15A',
  /*North Sea; Hound Point*/ 'DF5559F3-BE09-4326-8BD0-199882E34B06',
  /*North Sea; Howdendyke*/ 'CE6C3B45-8247-44E9-9375-D8DDC85441E8',
  /*North Sea; Hoyanger*/ '76418AD9-8B0F-45D8-9005-E6BCAE4CDD40',
  /*North Sea; Hoylandsbygdi*/ '4F404AAD-BD3C-452A-B5F1-1B7FE39BEF7B',
  /*North Sea; Hoylandssundet*/ '44E71D20-70F6-4EA4-ABC1-5A652A1EAC6B',
  /*North Sea; Hoynes*/ 'CA8AA9E9-9EEE-430C-A44A-756BD4E34A3B',
  /*North Sea; Hull*/ '6613E274-AB17-4896-A003-9B80AFC5C179',
  /*North Sea; Humbeek*/ '36CC4076-A5FA-4B2D-AD4C-9C5BF37D5A4C',
  /*North Sea; Hunnebostrand*/ 'F0059A86-0BB1-4E93-98AF-27BAB993B577',
  /*North Sea; Hurum*/ 'E6499614-1483-46AC-9B56-DFE869E9BD7A',
  /*North Sea; Husnes*/ '848A4DC6-BBE8-43DB-A4B1-4893D76E7E78',
  /*North Sea; Husoy*/ '6D10A4DA-3226-4B03-BC45-DAB12D34D363',
  /*North Sea; Husum*/ '366456AC-6B8A-48C0-A098-B19E2BA923DE',
  /*North Sea; Hvalpsund*/ '9F323C10-2E1D-4033-B1DC-0077E8408EB2',
  /*North Sea; Hvide Sande*/ 'B440BC2F-472C-4738-9226-16808F498302',
  /*North Sea; Hyen*/ 'B908938F-E584-4A0D-8B89-FE7E7F1E9032',
  /*North Sea; Ijmuiden*/ '43C83672-5F40-4412-89A2-323BFCD594C3',
  /*North Sea; Immingham*/ '88897752-A6EB-44D5-A3E3-03154CE774BB',
  /*North Sea; Invergordon*/ 'D795D354-A472-4424-A153-CD8E5A59D24C',
  /*North Sea; Inverkeithing*/ '7D19587F-58E0-4391-A00A-ACC123E5F394',
  /*North Sea; Inverness*/ '2FABC623-732F-40CB-A1FF-431AF0C0735A',
  /*North Sea; Ipswich*/ '437DD982-27BE-491D-9E60-41832A6955B1',
  /*North Sea; Isle Of Grain*/ 'DD6849B2-8904-4A8B-831A-BE6366CC4CE8',
  /*North Sea; Itzehoe*/ 'A0ACAE97-5334-42FC-8CF5-2C93C2D4D5C4',
  /*North Sea; Jelsa*/ 'B436BA44-2D11-47FF-AACF-677505EE56E5',
  /*North Sea; Jossingfjord*/ '39BF3E74-C78E-4834-BE3F-FDF37A8D443F',
  /*North Sea; Juist*/ '0733FDCE-8E64-4D00-8025-9EF82708C1CE',
  /*North Sea; Kaag*/ 'A27A0B3A-0F0D-47BA-8964-179A1236535C',
  /*North Sea; Kalvaag*/ 'C175C89E-1B91-4C41-86D8-6590B0850C1B',
  /*North Sea; Kambo*/ '43E59969-3306-4F3E-B62D-71C0041E254D',
  /*North Sea; Kapelle op den Bos*/ 'CF7DB624-145B-42B3-B9C9-AA6D9667A1EE',
  /*North Sea; Karsto*/ '04839532-96DE-45F1-82F4-4EC9BBC93551',
  /*North Sea; Kaupanger*/ 'C604EBD6-D6D1-4374-9806-23D5748C37FC',
  /*North Sea; Keadby*/ 'B7E6550B-AF24-40EE-B667-42148D0AC7D5',
  /*North Sea; Kinderdijk*/ 'A43F0E89-D79B-44AF-A429-1D409D7CBBFF',
  /*North Sea; King's Lynn*/ 'D8B14D5C-754D-4FB9-A766-59E030491071',
  /*North Sea; Kingsnorth*/ 'CB4F5C5F-C6B2-4E95-871C-8E3158838B27',
  /*North Sea; Kirkcaldy*/ '18D8A3B4-5F39-4F13-B0A9-17D73F50A305',
  /*North Sea; Kirkwall*/ '4401C117-6AD7-4DBC-8C29-889969D4218D',
  /*North Sea; Kjelstraum*/ 'A8C8A73A-6C73-4905-B412-05A7B1F4ECAC',
  /*North Sea; Kjoepmannskjaer*/ '1D37C404-09DD-4EEB-B07B-6A19DD87E4A0',
  /*North Sea; Knarrevik*/ '6896AA5C-025C-4D4E-A05A-E1D940BFD77C',
  /*North Sea; Knarvik*/ 'D9DFC1FE-4B69-4783-B2B2-4528BB823778',
  /*North Sea; Kollsnes*/ 'FF069198-DF0C-43F0-BD92-6C9A0FA94053',
  /*North Sea; Koog aan den Zaan*/ 'F417FF77-BE7D-46F1-92FE-E6F27ED65BF2',
  /*North Sea; Kootstertille*/ '82595DB5-4C44-4003-B3CA-E14166D36BCA',
  /*North Sea; Kopervik*/ '7D4CBEEA-3514-45A9-A1B4-4C12AAE602B3',
  /*North Sea; Kragero*/ '4BEC9DBB-EA3E-4044-9ADD-7B421CFD04DF',
  /*North Sea; Krautsand*/ 'CA1EB032-3229-4C74-8F69-D3B761EEE06B',
  /*North Sea; Kreekkrak*/ '88C9C9C0-1FF3-4C3A-8111-1A93547C97A2',
  /*North Sea; Krimpen aan den Yssel*/ '3E358EFE-9E38-411A-BBC2-9B9DBE8F90F9',
  /*North Sea; Kristiansand*/ '997CF42A-AC03-4E8A-8793-2CEF00548FD9',
  /*North Sea; Kruibeke*/ 'C9EFE5A6-7D9F-4870-96D2-09E3181F18D9',
  /*North Sea; Kungshamn*/ '3F922E93-185D-42AD-80DD-C046ACFDAEFA',
  /*North Sea; La Mailleraye*/ '03AE5010-9FC5-49DA-8C19-78A44E22DFD4',
  /*North Sea; Laerdalsoyri*/ '7267770C-5DF3-4186-AC00-20AE22D9CEB4',
  /*North Sea; Laksevaag*/ '63CB5AD9-58A2-441E-87E7-226BBB5D5EB1',
  /*North Sea; Langeoog*/ 'F9FE7B22-6346-4ED3-810D-934A5B081D40',
  /*North Sea; Langesund*/ 'F43E9894-9549-4AD5-B280-D91CF8CC7556',
  /*North Sea; Larkollen*/ 'FF578077-C606-4CD4-B5D7-A8B6D81C6BC1',
  /*North Sea; Larvik*/ '68F6F4DE-CFBB-4BA1-8792-D408D1F6AD69',
  /*North Sea; Lauwersoog*/ '1B0AEC7D-CE0D-48FF-8182-496CA2C88110',
  /*North Sea; Le Havre*/ 'CDC6520A-A2FA-4D68-86A4-4C8DA7F30F05',
  /*North Sea; Le Legue*/ 'AC972765-5E07-48FF-AA5C-90C2BCB46FEE',
  /*North Sea; Le Trait*/ '9C4212BA-BD40-4345-8FA2-1C122FB47273',
  /*North Sea; Le Treport*/ '5AAC6EA6-A85A-4511-B3DB-DE3338A1B2A0',
  /*North Sea; Leer*/ '80A0D352-54F8-46AE-84EC-B2017E956C1C',
  /*North Sea; Leeuwarden*/ '25B89802-5125-426B-BBC5-8FB4DEEBCE26',
  /*North Sea; Leiden*/ '26C24332-EE38-4A7F-9D04-BBC182AAD13D',
  /*North Sea; Leirvik*/ '0FE6E479-0B7C-4D97-A2C8-2CA4887BFF4F',
  /*North Sea; Leirvik, Hyllestad*/ '55E590AC-9A97-4CDB-9ED0-34C3077A9FA4',
  /*North Sea; Leith*/ '3FC251A7-0574-4671-B8CA-A0359F9A1B0A',
  /*North Sea; Lekkerkerk*/ 'DD9A147A-3CD1-42F1-968C-CF9F1F3BDDC1',
  /*North Sea; Lelystad*/ 'FF19E6D1-738E-4C96-8840-710E57719683',
  /*North Sea; Lemmer*/ '0CD16C2C-5688-4624-8682-0F9190F25328',
  /*North Sea; Lemvig*/ '6130DD4F-C544-4E17-BFC9-139C9A1F0DB3',
  /*North Sea; Lemwerder*/ '5767617A-E3EA-4F7D-B3A8-ED56E296FF75',
  /*North Sea; Lerwick*/ 'F3DEF025-8586-4182-A3A4-4DAAD61C0ABB',
  /*North Sea; Lilla Edet*/ '22A73297-83FE-4EDD-A7FD-57A39424735F',
  /*North Sea; Lillebonne*/ 'CADF84EB-2A21-40B6-9765-B78727ADC434',
  /*North Sea; Lillesand*/ '7EB8C0AF-D51D-4849-8BD9-9FE9BB51BA5D',
  /*North Sea; Limekilns*/ '742EBF0F-7861-48DC-9709-5C09CE82088A',
  /*North Sea; Lisserbroek*/ '89BA9921-E787-4827-8A88-F5DF882A18A4',
  /*North Sea; List*/ '49D8FABC-D70B-4D9C-92B9-52800A424588',
  /*North Sea; Littlehampton*/ 'ED097A50-5AAA-4592-AA7B-0FFDC4E1FDD5',
  /*North Sea; Lodose*/ '12E854C5-3794-47D5-9CF1-1E55C6575E9F',
  /*North Sea; Lofallstrand*/ '3A366D35-6CBE-42E2-AA2F-B00B41217636',
  /*North Sea; Logstor*/ '79615F01-C754-45CA-B918-0D011E6396F8',
  /*North Sea; London*/ 'C026E414-AC83-4E6D-832A-BBF8174818A3',
  /*North Sea; London Gateway*/ 'FEAE698F-38D4-4642-987E-0E4D1AD47995',
  /*North Sea; Longannet*/ '145A9065-58A5-4106-A9F5-2936BD17755B',
  /*North Sea; Lowestoft*/ 'ECF92227-E07D-4F41-AF32-FA26F9B6E8FB',
  /*North Sea; Lymington*/ '8F58AC2D-0A4D-462F-9102-08A9A88DDA9C',
  /*North Sea; Lyness*/ '99BD5981-6C4D-42A5-BA78-B0A6E09CE2A9',
  /*North Sea; Lysaker*/ '1D10A1C8-83C4-4C3A-BE02-D8F3AED2B15D',
  /*North Sea; Lysekil*/ 'B063F321-C28B-464B-9CF7-08581DC8AA6D',
  /*North Sea; Maarssen*/ '12218515-5106-492A-9147-8C895448D7D0',
  /*North Sea; Macduff*/ 'B82F9308-CC75-49A3-9E7F-60ED9BC1ED62',
  /*North Sea; Made*/ 'FD7E776E-A5C7-4B21-A17C-EF4C18174DC4',
  /*North Sea; Magero*/ '1789F930-D3C8-40DD-A155-3D695B257896',
  /*North Sea; Makkum*/ '99B8D2FB-AC97-4552-BF1D-90A7EDDDA062',
  /*North Sea; Maloy*/ '37E59D04-8D60-450E-A58D-15BDDB58D27A',
  /*North Sea; Mandal*/ 'D3E84074-F12E-4A13-A130-33657A96267E',
  /*North Sea; Marchwood*/ '12020483-496A-48DF-894A-E07225D8E1D0',
  /*North Sea; Mardyck*/ '347B02DE-E0C9-4136-9FCB-70ADD883A845',
  /*North Sea; Marstrand*/ '52E253C6-C8BE-44C0-8FF4-9B7424A3E89E',
  /*North Sea; Masfjorden*/ '55E624A8-9B13-43D5-9887-716F720EC80D',
  /*North Sea; Medemblik*/ '774FBD7B-FBDA-4F7B-AD94-EA6E4230E2F2',
  /*North Sea; Mekjarvik*/ '15A3EF02-C9D1-45CC-824A-10F59A673603',
  /*North Sea; Melle*/ '2DB4415F-F0E0-47AD-A33D-5063AEFD8155',
  /*North Sea; Melsomvik*/ '95A5F165-8D98-4FEB-BF7B-DCDC9CB50A29',
  /*North Sea; Menstad*/ '7B693A9C-5901-4D08-9EB9-28A566D6A0E2',
  /*North Sea; Merksem*/ '26B748B6-D59A-40ED-AFEC-500CD14A8097',
  /*North Sea; Methil*/ 'A544E788-E2D9-4FE7-9C62-E66612F17632',
  /*North Sea; Middelburg*/ '5A96E28E-9F85-47E8-8CA2-DF5D2E190A71',
  /*North Sea; Middelharnis*/ 'E8031A72-BABF-4046-B177-437C15B56298',
  /*North Sea; Middlesbrough*/ 'B349F962-0F06-4FE4-833B-7AFA31C25B3F',
  /*North Sea; Mistley*/ '248FB537-2EC4-4FC3-A240-3C2B1FEFE6D4',
  /*North Sea; Moerdijk*/ '2839FB4B-5640-4732-96F4-8350727A5CAC',
  /*North Sea; Molstrevag*/ '93D68D87-2153-4BEB-BBEC-79C14E8364B4',
  /*North Sea; Mongstad*/ 'D66D6A9F-3C82-4B5F-8283-8338FD2D4AFB',
  /*North Sea; Monnickendam*/ 'CE12B980-C63D-472A-A296-EC46151DF95E',
  /*North Sea; Montrose*/ '1FDA9B44-701A-4376-8B77-00D047A4427D',
  /*North Sea; Moss*/ 'BE5A35F0-9CC4-4539-A155-40E6FA1F78E5',
  /*North Sea; Mossholmen*/ 'B358D9C3-2020-4585-AE6E-DA686320BA87',
  /*North Sea; Mosterhamn*/ '518E5590-48D5-43E3-B81A-252FF85E9CC8',
  /*North Sea; Munkedalhamn*/ '9631A944-2C9B-490D-95C0-38AE863CD6F3',
  /*North Sea; Neap House*/ '32CFC105-FD3A-415E-84E0-898C9813AE1A',
  /*North Sea; Neuenfelde*/ 'B8BE32A4-5D7E-4D1A-9A1B-14E0ACD6F060',
  /*North Sea; Neuhaus*/ '5AB0DAF6-3509-4DA2-8CD3-D7C7EFD1796A',
  /*North Sea; New Holland*/ '3B201AB5-86EA-4BAA-B6A2-4FE57363EA62',
  /*North Sea; Newburgh*/ 'E0941756-FEFD-43A7-86DA-26BBDD7E38CD',
  /*North Sea; Newhaven*/ 'FCDA9705-3FE0-45FF-B661-1163EF20F7E0',
  /*North Sea; Niel*/ 'DD234E6D-76C3-42B9-BD34-EE5F7F0D47F5',
  /*North Sea; Nieuw Lekkerland*/ '3AC0E35C-42C9-48EB-8E03-021090785E2F',
  /*North Sea; Nieuwerkerk aan den Yssel*/ '1D34F2EC-278B-4F46-AEC6-2B4563B2ED55',
  /*North Sea; Nieuwpoort*/ '38AAA9FA-4D13-4F71-A837-43A962E9AA39',
  /*North Sea; Nigg*/ '721F517B-25AC-4C9B-85EC-D550AC3D0B8F',
  /*North Sea; Nigtevecht*/ '74EAAF27-4A84-4EFB-9E6E-03BA34D70DD6',
  /*North Sea; Nol*/ '5A5B3076-502A-4FDC-96F5-86AA551216D8',
  /*North Sea; Nordby*/ 'E3F26F24-1E0A-4C6F-9FEF-FC44BDDFBA95',
  /*North Sea; Norddeich*/ '5C8BA5D2-FA76-43BE-B1A6-E283D4B3E0A5',
  /*North Sea; Nordenham*/ 'A54E0868-76E8-40A7-97C7-48DCF3E2B484',
  /*North Sea; Norderney*/ '924C6BD6-2E18-4790-9BA0-C7836049E6FC',
  /*North Sea; Nordstrand*/ '71F4680D-3E81-4EE6-A7AE-3DD63C370B81',
  /*North Sea; Nordveitgrend*/ '01113505-F824-4758-813B-45A6176CD158',
  /*North Sea; Norheimsund*/ '39AB9596-27B3-44EE-BA01-B8EC5B84B064',
  /*North Sea; Norresundby*/ '93B7ED7E-E91B-4872-B09C-7D04F37C80AA',
  /*North Sea; North Killingholme*/ '880C7C83-518A-4CDE-A5F9-01880ADCA51C',
  /*North Sea; North Shields*/ 'E9E18547-AFAF-4C12-8C84-87ED7CB5A638',
  /*North Sea; Numansdorp*/ '5EB671BD-0B11-49D0-AD41-110C180492AE',
  /*North Sea; Nykobing (Mor)*/ 'C7362E06-181B-423D-96A8-D6C991CDCEB8',
  /*North Sea; Nystrand*/ '2CB4793B-1B71-4472-BE23-AA11927A85DA',
  /*North Sea; Oakham Ness*/ '332F4C76-225E-4AB0-BE07-F5C21579B091',
  /*North Sea; Oanes*/ '1B89DD37-218E-4B00-BD5F-635D220DD5AF',
  /*North Sea; Odda*/ '8A676D14-1A72-4466-88E3-6C55FA5C096E',
  /*North Sea; Oddesund Nord*/ '7AD6D6E2-1408-4852-8E11-DACCA6833C5F',
  /*North Sea; Oldenburg*/ '3AFECAF6-98DC-4F6B-B732-BE6DB0E76162',
  /*North Sea; Oldersum*/ '13BAE4D4-7689-4E79-A785-58AB66894262',
  /*North Sea; Olen*/ 'F8842734-17DF-4383-9233-F0BEDA104018',
  /*North Sea; Omastrand*/ 'A1501BA2-B209-45B5-8727-787EC30D4FC3',
  /*North Sea; Onarheim*/ '56A50F77-9918-412B-995D-D37827C41018',
  /*North Sea; Oostende*/ 'B9983465-DA5C-4703-83BC-48DEC42D65D0',
  /*North Sea; Oosterhout*/ '1EB69E24-D433-4E2C-B3A3-C6948FCA1EA0',
  /*North Sea; Oostmahorn*/ '118FF4D2-C966-4C3F-B74E-B91EBC286FFA',
  /*North Sea; Oslebshausen*/ '6B6DB153-07F9-40A3-8F59-B9B355B84853',
  /*North Sea; Oslo*/ '132B29FD-7B45-44FC-9434-0E389F1E5EB3',
  /*North Sea; Oss*/ '664976FC-003C-4BA6-AD73-BE5046DAE574',
  /*North Sea; Ostermoor*/ '56C8B4BC-08E3-4EF3-AA92-2784F3862116',
  /*North Sea; Otterndorf*/ '165B64FD-6DD8-49CB-AA67-7DCD69D2F265',
  /*North Sea; Ouderkerk*/ 'E754FD4E-A353-49A9-9E90-2CB876DFDD94',
  /*North Sea; Ouistreham*/ '76E54F78-9A4B-43D3-A4B6-BB5A38928D33',
  /*North Sea; Oulton Broad*/ '45BFFF26-493C-4E75-92B7-D1EEC219E540',
  /*North Sea; Ouwerkerk*/ '1AB2D7FA-4D57-4634-89CA-B70619D8D8C5',
  /*North Sea; Oystese*/ '1602FD74-1C9B-4C0C-A060-356FED84FF2A',
  /*North Sea; Paluden*/ 'F528E8D9-C611-4A79-99D4-DF1D42B19439',
  /*North Sea; Papenburg*/ '3AD7CD3B-7B66-479E-AE0F-0BE94F9F61DF',
  /*North Sea; Papendrecht*/ 'D33469F3-5941-428C-A3FD-D6FE299F02BF',
  /*North Sea; Paull*/ '0907F497-4CD1-4960-930D-A89259F83CD1',
  /*North Sea; Perth*/ '70EFCB82-C401-4F58-A056-6094AFF9DBE3',
  /*North Sea; Peterhead*/ '47CA817D-45D2-423C-9A69-1D608731F5B3',
  /*North Sea; Petit Couronne*/ 'B15ED1FA-8631-4B2F-BFAF-19EF09CFF270',
  /*North Sea; Plymouth*/ '7EE926D9-DFBE-47DF-B494-8C69CE48FFFA',
  /*North Sea; Pontrieux*/ 'AB884E4A-A832-407C-8073-4A8BB6BD353A',
  /*North Sea; Poole Harbor*/ 'B6CFA8CD-D4E9-4D7C-B558-C00BDCF9A7D4',
  /*North Sea; Port Edgar*/ '737A8731-97F9-4C65-842B-2BE500992A0F',
  /*North Sea; Port Jerome*/ 'AC6A87CC-BB84-48F3-9EBC-46EF2B194BF6',
  /*North Sea; Portland*/ 'D947D8A8-FBD6-40E5-AC52-F5DB7A070334',
  /*North Sea; Portsmouth*/ '772FEEA4-55C5-4EF8-BF71-5FE042CF526D',
  /*North Sea; Purfleet*/ 'E7A89325-C08F-4026-9A62-E94CEBF10A10',
  /*North Sea; Puttershoek*/ 'FD76CA95-FBE7-42F6-A9FD-65DAFB981C8A',
  /*North Sea; Puurs*/ 'C9EED178-20ED-4C3F-AD25-6C8F1B09123F',
  /*North Sea; Queenborough*/ 'B4C4D1D8-562D-47CC-B412-6523D6BBE5F2',
  /*North Sea; Raamsdonksveer*/ '612906E9-9BA4-4CBE-B6F3-6FABBFDD1E32',
  /*North Sea; Radicatel*/ '5F85CFD4-2AE3-4040-9023-7309C99ED830',
  /*North Sea; Rafnes*/ 'D1D1BB98-3F30-4F12-9250-94C16915AE9A',
  /*North Sea; Ramsgate*/ '8C2C000D-DB7A-421A-84CA-6D72C4317595',
  /*North Sea; Ramsvik*/ 'DF2B3B28-A37E-4027-A94C-13373D09CF64',
  /*North Sea; Randaberg*/ 'D82123E7-C028-4417-868A-1667172AFC67',
  /*North Sea; Raudeberg*/ 'B69A0E70-BFA0-470D-933D-3DDCC5054CAC',
  /*North Sea; Redcar*/ 'A5634217-BBEC-49AE-9033-4096EFB87809',
  /*North Sea; Rekefjord*/ '27A2FC48-5577-4DA8-9695-1FBD4AD6C528',
  /*North Sea; Rhenen*/ 'EBF0D037-7368-46CA-990C-EDD132B7174E',
  /*North Sea; Ridderkerk*/ '40D953B7-C474-43AF-B162-C218E24C8AD5',
  /*North Sea; Ridham Dock*/ '3F3369AC-FF0D-4C51-A316-83798578A22D',
  /*North Sea; Rijswijk*/ '5E17046D-4D9A-4E97-A218-5AD7F6D16D01',
  /*North Sea; Risavika*/ '6822B8AF-3343-464B-8663-704D5C9901BE',
  /*North Sea; Risnes*/ '1EEBACE6-D045-4325-AEC0-4C01070F44A3',
  /*North Sea; Risor*/ '5F735F91-395E-4A9E-8DE6-460B330EE3D0',
  /*North Sea; Rochester*/ '7BDE2A26-CD66-4D13-9CAA-AFEAE33F85F6',
  /*North Sea; Roodevaart*/ '59821B1B-8170-4035-9597-66EC1A9BEC35',
  /*North Sea; Roosendaal*/ 'E069D455-E0B2-4A2E-9C2B-54E072FBED24',
  /*North Sea; Roro*/ 'BDE3D352-BF1C-446C-87A1-3B5B0469CDA4',
  /*North Sea; Roscoff*/ '6E9D584B-F8FC-4212-BF66-1AF6DA3597D1',
  /*North Sea; Rosendal*/ 'AB93D31E-9339-4C80-B29B-25295E0F00E3',
  /*North Sea; Rosfjord*/ 'E9B5EEF7-B18C-4C47-83A5-6343CBA5792D',
  /*North Sea; Rosyth*/ 'E89DF37E-99A8-43F9-85EE-3A1844B9BC29',
  /*North Sea; Rotterdam*/ 'B08CB4F6-CB76-439F-BF41-92C070CE8E92',
  /*North Sea; Rouen*/ '15D5F6CD-690B-4738-BD34-D63674B8BA05',
  /*North Sea; Rubbestadneset*/ '548851E3-D58A-4F34-8184-2C6A9F045D64',
  /*North Sea; Rupelmonde*/ '31ED1A71-7A1B-429A-A452-C41127F4EB38',
  /*North Sea; Ruthenstrom*/ 'C45A470B-7A14-4228-9463-ECF364373D5C',
  /*North Sea; Ryde*/ 'FB4FDF42-672C-44B1-BCEB-55BAEDDAC00E',
  /*North Sea; Rye Harbour*/ '974103C6-3E0F-49A0-9315-7FDAA1AFD4E3',
  /*North Sea; Ryvingen*/ '7CF4B616-1A26-4722-BA32-02EB38B76567',
  /*North Sea; Ryxo*/ '04FAB3BB-E9C7-40C7-8821-408E832B3F80',
  /*North Sea; Saetrepollen*/ '35485126-9B00-4103-A5E8-07D5A4116020',
  /*North Sea; Sagvag*/ 'E790A922-B1C5-458C-84BC-36AE6C1195BB',
  /*North Sea; Saint Romain de Colbosc*/ '6D81CA90-F9FA-4327-A04E-2CB12EA8427F',
  /*North Sea; Saint-Malo*/ '713B41B4-6FA3-4562-909D-3019A0E5CC89',
  /*North Sea; Salcombe Harbor*/ 'E8A661B8-4852-4EA5-A56B-3E15227ECF9A',
  /*North Sea; Saltend*/ 'FCFCBC51-A75C-463F-9B58-A3CA6E07E41F',
  /*North Sea; Salthella*/ 'C961DF4A-8F3B-427D-9ABD-6F6A2100EE0E',
  /*North Sea; Sandane*/ '78146454-2CED-441D-AC9E-4CF2A2F252C4',
  /*North Sea; Sanday*/ '3FD2F471-4CF6-44E4-83F1-596471BD1501',
  /*North Sea; Sande*/ 'E61F4F22-89AD-4924-B683-AFA090C6C10C',
  /*North Sea; Sandefjorden*/ '0E2C9A69-4846-48A5-84E7-C3F048ACB14D',
  /*North Sea; Sandnes*/ 'A6C1D29B-4483-4A8D-A2E2-C13E09EF5184',
  /*North Sea; Sandvikvag*/ '5E9FE0B9-57AC-4E3C-B211-B3D521502E7B',
  /*North Sea; Sandwick*/ '99A8514B-57ED-464B-B301-0CEF073B2F3A',
  /*North Sea; Sark*/ '6F5D5BE4-21B7-410E-8106-321B7943DF33',
  /*North Sea; Sarpsborg*/ '4767D5D5-7807-4183-AE8A-2B333675285F',
  /*North Sea; Sas van Ghent*/ '44C1B0A5-2F2B-4E09-8C6F-0377FD590276',
  /*North Sea; Sauda*/ '6609BFF9-9CCA-4421-B679-7AF958C6B82F',
  /*North Sea; Scalloway*/ 'BA7E8C95-D62F-4CA3-BC32-DF29E0AC68B8',
  /*North Sea; Scapa Bay*/ 'A6043CB5-6A93-4133-9F8D-AAD295483CDC',
  /*North Sea; Scarborough*/ 'B783CFFE-FAE0-414F-8487-42ECF6F91696',
  /*North Sea; Schelle*/ 'C3F467DE-EBB8-4DF1-A603-98FC9C3BE155',
  /*North Sea; Schelluinen*/ '0E42115F-85AB-47FD-872D-57B4D40A9D9B',
  /*North Sea; Scheveningen*/ '5F095B5B-5F51-47F0-9F8E-390407975CCC',
  /*North Sea; Schiermonnikoog*/ '9D4B698D-1F16-40F9-BDC3-99574FBA0B3C',
  /*North Sea; Schoten*/ '6C46BDF7-EF65-4881-9D06-5D6E98CC5E2C',
  /*North Sea; Schulau*/ 'A47500C9-AF22-44B0-A87B-2B522B7A2136',
  /*North Sea; Scrabster*/ 'FCA43001-CD25-4E3C-B4A2-8B34DE9518E9',
  /*North Sea; Seaham*/ 'B1EA0DA2-233C-49CD-B8CF-83ECBB07DA33',
  /*North Sea; 'sGravendeel*/ '49A109CE-A0AA-4AF0-971B-97DDA672DB0E',
  /*North Sea; Shapinsay*/ '24DBCF51-4C3C-4BEA-83D3-AD7E6DB630C8',
  /*North Sea; Sheerness*/ '04E9BD75-BB00-4E94-8D64-75A12FD58BC6',
  /*North Sea; Shell Haven*/ '73373C4A-C9F8-439D-AB86-478C298B8E37',
  /*North Sea; 'sHertogenbosch*/ '9BF0BA0E-4A32-4BDD-B984-94E00200A3DE',
  /*North Sea; Shoreham*/ '67BC258D-810C-4165-8D96-539979E1DC61',
  /*North Sea; Sint-Kruis-Winkel*/ 'CD678539-1F2F-4D85-BB1B-6BD970FACC74',
  /*North Sea; Sirevaag*/ 'C0E0B47B-B2DA-449E-AA89-E951775165FC',
  /*North Sea; Skaalevik*/ 'B5356304-332A-47D7-B9D8-235B44AE0F0E',
  /*North Sea; Skanevik*/ '39019327-A1E6-4361-BF45-DBE165A582D6',
  /*North Sea; Skarhamn*/ '81A73321-2F7B-4658-A8FD-604741484ACA',
  /*North Sea; Skien*/ '0F382AB6-0EB3-4196-87D3-EF910998DCE1',
  /*North Sea; Skive*/ 'DD602F7C-4945-4588-B020-840BB9DC7C4E',
  /*North Sea; Skjolden*/ '77FD8F17-B62E-4504-AB70-5BDE99B3E5E5',
  /*North Sea; Skudeneshavn*/ 'B5941B3B-C2A5-4811-AB64-7BA2B3025C31',
  /*North Sea; Slagen*/ '3195B781-F22D-4906-B6E3-7E8C12FA544B',
  /*North Sea; Slemmestad*/ '73B57AE0-FD4E-4F96-B9A4-2BB4C32E05D9',
  /*North Sea; Sliedrecht*/ 'B28CF7B2-98CD-475D-830B-F3431AE1C11E',
  /*North Sea; Slikkerveer*/ '5BD8F3E6-26E3-46FB-A059-AA93CA8846A8',
  /*North Sea; Sluiskil*/ '726A5B95-3DCF-4802-9188-A3F3EEED1F68',
  /*North Sea; Smallgains Creek*/ 'C8CA09CE-0842-4602-AF57-210DEA66E815',
  /*North Sea; Smogen*/ '8D5F2167-C215-4B60-8CBB-6C00D509BAEC',
  /*North Sea; Sogndal*/ '0DD6D2C6-9D37-4B18-8524-48DFA8227969',
  /*North Sea; Sogne*/ '325DBAB0-942D-4A1C-B1A8-2D64C41865F7',
  /*North Sea; South Shields*/ '7F62222E-C91F-45C4-BD05-28904CFEA548',
  /*North Sea; Southampton*/ '6ED3C64C-F11F-4B46-A3B2-2AC866F9D947',
  /*North Sea; Spaarndam*/ 'BB39DE26-EB2E-4AA9-8DC8-A5B23C8AFF49',
  /*North Sea; Spiekeroog*/ '466DCF2A-FDE3-476A-8428-236873FAE537',
  /*North Sea; St Helier Harbor*/ 'C6F9EB8B-F144-4D92-B0CE-68C114E521F2',
  /*North Sea; St Peter Port*/ '22053B7B-42F2-4C08-A3BE-1EDC235E103F',
  /*North Sea; St. Annaland*/ '97157EF1-B072-443F-A13D-DF8E3C5CE505',
  /*North Sea; St. Margaret's Hope*/ '4D488338-E8C4-4294-B3B7-9AF8EC478575',
  /*North Sea; St. Wandrille*/ 'BD0CFE4F-DCC9-47CF-815E-98B04A45A5CA',
  /*North Sea; Stade*/ '618D0A89-4996-48B9-A5D2-55D555D6FFC4',
  /*North Sea; Stade Seaport*/ '6831C68A-AD71-4B03-99A5-C3F7F0C8ED44',
  /*North Sea; Stadersand*/ '2FB4C16D-D960-4452-8016-4FCF7A34FC29',
  /*North Sea; Statfjord Terminal*/ 'FB81FDCE-A35B-4B88-BAC6-4DEB8EEA9FDE',
  /*North Sea; Stavanger*/ '4C174A25-39A5-48EE-9108-A87D8CCC2F99',
  /*North Sea; Stavenisse*/ '01B20C13-F07F-4970-851B-F82AB0AE19F8',
  /*North Sea; Staveren*/ '5C21789D-4393-4BAF-8C21-08393D9C4DE9',
  /*North Sea; Stellendam*/ '42408EA8-BF7F-4EEF-AE3E-A5CAA8E5095E',
  /*North Sea; Stenungsund*/ '6820BD3A-8D6E-424C-860D-B8B0D9265FC6',
  /*North Sea; Storasund*/ '52D4C3B2-1ADC-45F1-8B57-932BA5220573',
  /*North Sea; Stord*/ 'CE86B63A-282F-4EC2-8F36-2682EDC7CA2A',
  /*North Sea; Stromness*/ 'B857D56E-AFE0-4933-803D-8F0BC183E5A9',
  /*North Sea; Stromstad*/ '36DE2C5E-5EB9-426C-8FED-3EE78F8A72EB',
  /*North Sea; Stronsay*/ '45DF6A17-98C7-4370-B262-B3961C7AFC7C',
  /*North Sea; Stroobos*/ 'A783A06F-FCC9-4544-A02C-DD2859F55DFE',
  /*North Sea; Struer*/ 'AA20F1AC-E9DF-4364-8695-792DE438FB6B',
  /*North Sea; Sture*/ 'CAB7F410-9D80-4E1D-8E08-306D65B12C37',
  /*North Sea; Sullom Voe*/ '1B5E346B-FB87-4AB2-9ABD-ED67292751D4',
  /*North Sea; Sunderland*/ '78B3301B-362A-4EFD-8876-778F67925E10',
  /*North Sea; Surte*/ 'FDAC54EA-01D5-4E61-BBC3-A5B2CF40F884',
  /*North Sea; Sutton Bridge*/ 'F17612BD-215B-46D2-A5ED-C09627906ECE',
  /*North Sea; Svelgen*/ '1A0CE8F7-440C-4087-A652-CFB81C6CE901',
  /*North Sea; Svelvik*/ 'AEC51402-B06A-402C-BFBA-6423E6606030',
  /*North Sea; Tananger*/ '95CE3EBD-3935-4787-9F01-58C965796FBD',
  /*North Sea; Tau*/ '688496BF-056D-48CD-A319-9961C7C2FBC0',
  /*North Sea; Tayport*/ '0299940E-5145-4CC9-A0CA-810B05C33096',
  /*North Sea; Teesport*/ 'EC15AF8F-4707-427C-BBCF-736ACDD7AFA2',
  /*North Sea; Teignmouth Harbor*/ 'F1A141E2-C7BE-4128-AFAE-ED1EC89D3A47',
  /*North Sea; Telavag*/ '901BF191-2FA5-4E56-AC3F-BBDF26C9D4AB',
  /*North Sea; Temse*/ 'A1DC0AB8-C7EE-4264-9216-AAF386A356B4',
  /*North Sea; Terneuzen*/ '77411247-2A59-4D7F-8975-36DA0AEDA064',
  /*North Sea; Terschelling*/ '350CB171-C58C-4F76-AF0E-5448C790F9B7',
  /*North Sea; Texel*/ 'BE3D9666-38BF-41C0-AE5F-898A448DD669',
  /*North Sea; Thames Oilport*/ '22548BFB-A82F-40F3-853B-4636FF753C6D',
  /*North Sea; Thamesport*/ '314E49B7-D906-4C2F-8A6E-E603ED3F281B',
  /*North Sea; Thisted*/ '782F274C-3267-4E8D-8939-7D9C4F06D678',
  /*North Sea; Tholen*/ '6CDF7E20-F42E-4066-A19D-DB81A1C2C28C',
  /*North Sea; Thorsminde*/ '81434B3A-D5D6-4FDC-9D3D-70666B7513ED',
  /*North Sea; Thurrock*/ '62105EB0-3A1F-4F6F-A017-B23FC5236230',
  /*North Sea; Thyboron*/ '303F83E3-3AF1-4B1F-B5D5-D78BD48BA342',
  /*North Sea; Tiel*/ 'F49D20AD-A8FC-4436-A70C-A615D1680EFA',
  /*North Sea; Tielrode*/ 'A877CA08-0FE5-409C-83A6-79F87492CC36',
  /*North Sea; Tilbury*/ 'D640A9D3-FFF3-4966-A502-24B511BB320C',
  /*North Sea; Tisselt*/ 'F52EAAA4-B899-47D0-A35E-82DC982A1BD5',
  /*North Sea; Tofte*/ '0CE40E3E-AC60-4D90-9C97-9882F4556DAA',
  /*North Sea; Tofts Voe*/ '200471AB-60DF-4541-B318-5DD2407C3455',
  /*North Sea; Tonning*/ 'C7A5DD67-CB30-45AE-BA03-8867AE2B9DD6',
  /*North Sea; Tonsberg*/ '4F449F7F-02DC-45F7-9488-1E64D5FEE412',
  /*North Sea; Torquay*/ '7F70D299-020F-47BE-862C-E0AAD83FA818',
  /*North Sea; Treguier*/ 'BEF196FE-5EF2-4162-97DD-8EC2F6D0C4D1',
  /*North Sea; Trollhattan*/ 'C9C29DAA-A2C2-4A42-9D32-1740058C8E72',
  /*North Sea; Trosvik*/ 'C35E8094-91E8-4D09-925C-A54E2057540A',
  /*North Sea; Tvedestrand*/ '6131F0F3-47D0-440C-8397-01FAC4C059C1',
  /*North Sea; Tyne*/ '1776C608-80D2-445B-B1EA-27BD2C1EB6BB',
  /*North Sea; Tyssebotn*/ '83B3EE01-2781-4D44-971A-1E54393D3DFD',
  /*North Sea; Tyssedal*/ '6032EDE1-F2F4-421F-A8B1-03C37A027ECD',
  /*North Sea; Uddevalla*/ 'F6C409B5-A3A1-438C-A800-877ABA3C22A2',
  /*North Sea; Ulvik*/ '4E22DBD3-8367-4799-8246-11A5CCABBA77',
  /*North Sea; Urk*/ '66976AF5-6857-46EE-B4EC-C91F3DDA2E80',
  /*North Sea; Uskedal*/ 'F3E90214-FA11-43CE-AC36-AF735A64C31C',
  /*North Sea; Utersen*/ '6C3DC58C-D936-40A4-8A81-DBE02348436E',
  /*North Sea; Utrecht*/ '31EBC668-34D3-4F1D-B525-DD31906DB8C4',
  /*North Sea; Vadheim*/ '98F8797F-ABD2-429B-92A5-3225162EE1F1',
  /*North Sea; Vaksdal*/ '6EB19CBE-2D83-415D-AA73-3E3DA9DF0E61',
  /*North Sea; Valevaag*/ '354C3AE4-1C64-4754-826E-34B4753024CD',
  /*North Sea; Valloy*/ 'DCB2AC5C-FBAB-4237-87E2-143B4964C9E2',
  /*North Sea; Vasterlanda*/ 'EFED69DC-43D9-4BCF-882D-0121102E3729',
  /*North Sea; Vats*/ '320A6206-1C0F-428E-ADF6-3E3E075CF601',
  /*North Sea; Velsen*/ 'D275491E-5111-4A36-A41E-B4D9919D8B73',
  /*North Sea; Vettas*/ 'A7AE57D4-7C84-4E45-9902-21EE222A98E8',
  /*North Sea; Vidlin Voe*/ '21D98CCC-E523-4FDD-ADCF-860A198EF3F8',
  /*North Sea; Vigsnes*/ '9C85A209-93D3-4DD6-976B-BFB920C27DD2',
  /*North Sea; Vik*/ 'B7950DFB-CE9D-4548-92A4-BFE464EE4F47',
  /*North Sea; Vikedal*/ '7A2EB694-CDDF-4D28-A15F-10959F3E8379',
  /*North Sea; Villequier*/ '3FF8166E-177B-4D95-A38D-94AA17EA6794',
  /*North Sea; Vlissingen*/ '4256AC57-05F7-4A79-A607-71C1B08D6D65',
  /*North Sea; Wallhamn*/ 'E1402E39-1B74-47D7-82E0-4BA0DD975C3E',
  /*North Sea; Walsoorden*/ '6873DC09-79AA-4448-AE88-1384DC725051',
  /*North Sea; Wangerooge*/ 'F1F01C5D-120E-48F8-9A27-120108FB8956',
  /*North Sea; Warmond*/ '13B034D6-0DDA-4D7C-BD8A-00FDBA562E0A',
  /*North Sea; Waterhuizen*/ '8EC790D2-D89A-452D-BA21-0FAABF13AF75',
  /*North Sea; Wells-next-the-Sea*/ 'A28584FB-FF72-439D-8B6D-BDB3347C2EBC',
  /*North Sea; Wemeldinge*/ 'ADE87276-821E-4A36-9D70-401F059D4F67',
  /*North Sea; Werkendam*/ '7F125D6E-1385-43DE-8E12-2C955B74EA18',
  /*North Sea; Wesermunde*/ 'A3FFE742-95B7-4E4E-8D01-88F61C218A4F',
  /*North Sea; Westerbroek*/ '481551E3-799A-4525-A21F-A7FB1CDF4781',
  /*North Sea; Wewelsfleth*/ '0269BAD6-023B-4C23-99C8-62F6C78EDCAC',
  /*North Sea; Weymouth Harbor*/ '4482D3B5-90B8-4252-8917-71DDCE61CF3C',
  /*North Sea; Whitby*/ '550B3FE5-F71F-42AA-9F21-4AB54838EA25',
  /*North Sea; Whitstable*/ '0DBDACE2-9014-4641-A4D1-9F16AA4582E1',
  /*North Sea; Wick*/ 'B609CBEB-3DFE-450D-9185-E4D0653058BD',
  /*North Sea; Wielingen*/ '22A7D97F-7449-41A7-B464-A77D97540EB2',
  /*North Sea; Wieringerwerf*/ 'CBD55917-0CBB-4088-9793-06664A44698A',
  /*North Sea; Wilhelmshaven*/ '04414FCA-E823-4F64-9972-34A71CEE910D',
  /*North Sea; Willebroek*/ 'E19FFEB4-F55C-4A05-967C-CDD180160A8D',
  /*North Sea; Willemstad*/ '4A7F1ABC-457C-4ACE-B3F9-6385B989F683',
  /*North Sea; Wintham*/ '003B4C40-BD4F-4AC5-8149-B3EF5182836E',
  /*North Sea; Wischhafen*/ 'C2A9D6B2-94B3-4026-9349-FD4BA707F5EF',
  /*North Sea; Wolphaartsdijk*/ '4D45E4D8-836C-450F-91EB-04E2424B003B',
  /*North Sea; Wondelgem*/ '7A5906F3-E23D-4FE3-A0AF-BF01383EACB7',
  /*North Sea; Workum*/ '0BF04505-8AA4-4E47-82D0-62BFC213147F',
  /*North Sea; Wormerveer*/ '9A610E1B-DBDC-4359-BC9A-7C2FBBB80E04',
  /*North Sea; Woubrugge*/ 'BF9B7584-CF37-40BA-9983-78B05A46F25A',
  /*North Sea; Woudsend*/ '8C1C8CE2-E9C9-46C3-AC9E-EDA690DE04F7',
  /*North Sea; Wyk*/ 'AE8CDEC4-8EC7-4D3C-AB16-CA4D991A13C2',
  /*North Sea; Yarmouth Harbor*/ 'DAAC7D28-18EF-4860-B4CC-A0E077EE64EF',
  /*North Sea; Yerseke*/ 'A24DAE20-E5DC-4D48-975B-908E3A5CE03C',
  /*North Sea; Zaandam*/ 'B57EA8BF-BCF7-4F50-A774-FA857BB1C4DE',
  /*North Sea; Zaltbommel*/ '525A09C3-81B1-41D9-970B-D5FED57B8693',
  /*North Sea; Zandvoorde*/ '943669D2-4F04-4B33-BBC9-0FD5A8D61343',
  /*North Sea; Zandvoort*/ '1C2FB88D-F6C7-47C6-92F6-804D4D69E5FA',
  /*North Sea; Zeebrugge*/ 'C69EA2F7-5224-4DB5-B19A-6352196BEB0E',
  /*North Sea; Zierikzee*/ '82DC18A4-3806-4C74-91A6-C86C04D3575C',
  /*North Sea; Zoutkamp*/ '58E1E63F-DE00-413A-A969-87BD77279E2B',
  /*North Sea; Zuilichem*/ 'A8AD7620-09AE-421C-8808-72CB965A74BD',
  /*North Sea; Zwijndrecht*/ '51F4AC08-3AB5-489A-9B6D-33CF714FDF3F',
  /*North Sea; Zwyndrecht*/ '757D47E2-A9ED-4DA8-A8CE-1A97966A70E9',
  /*South Korea; Busan*/ '65A94341-2EFB-4B55-8DC5-DE280DA8BE4E',
  /*South Korea; Chinhae*/ 'F837A10A-3BEA-41F8-8B88-DC59EAEE0904',
  /*South Korea; Dangjin*/ 'C12DB7E5-921B-4218-A2F4-9D8D7AC79D9D',
  /*South Korea; Gwangyang*/ '079BAE63-1E76-460A-A727-11ECB2CA4F7F',
  /*South Korea; Hadong*/ 'E9834F7E-7332-4D7B-9F9B-43F15436EBAC',
  /*South Korea; Incheon*/ '629D04E1-0B5D-4EC8-B274-E1ADFC095464',
  /*South Korea; Okpo*/ '85A7A4CD-F084-49B8-82DF-99C24D73526F',
  /*South Korea; Onsan*/ '8273C031-95A6-4F7B-A541-A7DC5F7A135B',
  /*South Korea; Pyeongtaek*/ '09094ED0-60F9-4B69-8DA0-0E39670A71F6',
  /*South Korea; Suyong*/ '77CA55EC-A256-4547-B3E0-F3D587B26A9D',
  /*South Korea; Ulsan*/ '0633C052-55E8-448B-92E8-B3B140B8D0EA',
  /*South Korea; Yeosu*/ '5F5F75CB-EF7C-4CA9-A3BE-E9B7BD6FCDDB',
  /*U.S. Caribbean; Charlotte Amalie*/ 'A2A21D0B-751C-4212-B129-8FA973F8F619',
  /*U.S. Caribbean; Christiansted*/ '52609510-FB5D-456A-BF0C-5BB1EB4FA250',
  /*U.S. Caribbean; Cruz Bay*/ '3BA25CFC-CB0F-4869-9C6C-8A9260F58CD7',
  /*U.S. Caribbean; Frederiksted*/ 'EAE40AD0-307A-4081-8DFB-68CF00B4AFC0',
  /*U.S. Caribbean; Guayanilla*/ '5EF4D317-B294-479B-861B-422800078285',
  /*U.S. Caribbean; Las Mareas*/ '698B583E-CE84-426E-AE83-033B0590FC48',
  /*U.S. Caribbean; Playa De Ponce*/ 'E6518DD9-D8E0-41F4-B3BD-4F1724E72C9F',
  /*U.S. Caribbean; Port Alucroix*/ '32D4BD42-F512-4A9F-9D66-7CB74269890F',
  /*U.S. Caribbean; San Juan*/ '20DBFF91-5FEE-43EC-835E-E7E9CC41DD76',
  /*U.S. Caribbean; St Croix*/ '77769767-CF75-4CF0-A986-746521CB3ACC',
  /*U.S. Caribbean; St. Thomas*/ '44D8E1E0-3402-41D6-936E-288CBE9F2412',
  /*U.S. Caribbean; Yabucoa*/ 'FDBE9310-C32D-4210-90F7-01BE197794DC',
  /*Xijiang River; Guigang*/ 'D66184FD-232E-4E00-B94A-B344E9037FF8',
  /*Xijiang River; Liudu*/ 'BD506805-01A1-4F80-BBEC-F501D80226AC',
  /*Xijiang River; Nanning*/ '3EA7A7E8-89C5-42F2-8FBB-CC605E23FFD5',
  /*Xijiang River; Pingnan*/ '60BE99CF-CCBA-42F2-B971-33C80B2CF8B7',
  /*Xijiang River; Wuzhou*/ 'A4AF0290-8DFB-4C72-83B2-03325DE67F82',
  /*Xijiang River; Zhaoqing*/ 'A48ED851-7126-4F57-A62B-0831EBDE8989',
  /*Yangtze River; Ankingcheng*/ 'C73D1A9C-054C-4C0A-A136-DDFE9F740AF6',
  /*Yangtze River; Changshu*/ 'FA552EB5-12B2-4D2E-986D-416C20123CEE',
  /*Yangtze River; Changzhou*/ 'DF3C6297-C64A-4B41-8524-849AAC42D2E4',
  /*Yangtze River; Chizhou*/ '9EA2F58A-31AF-4BE2-84D9-500B5B82B8C6',
  /*Yangtze River; Chongqing*/ 'EBDA1D92-4027-4C6E-9443-DDA1C9462F84',
  /*Yangtze River; Digang*/ 'CB72A515-61B3-4232-A24E-71361F4B9C86',
  /*Yangtze River; Ezhou*/ '78E41856-EA42-4122-ADBA-76B86A2FDF40',
  /*Yangtze River; Gaogang*/ '7D38FB2B-C933-433F-9268-BC3ED74774E3',
  /*Yangtze River; Huanggang*/ '2F7F8214-A94B-4299-A47D-51924D3D6238',
  /*Yangtze River; Huangshi*/ '2D2EF490-EE7C-4552-8CF6-C64807D593B8',
  /*Yangtze River; Huangzhou*/ '62E72D05-189C-45EB-880D-0A9AD1ED2A01',
  /*Yangtze River; Jiangpu*/ 'E22B1DE1-E49F-4610-BF9E-064E88EF0CFB',
  /*Yangtze River; Jiangyin*/ '8CD255C1-7D37-416C-9F1E-D8CFEA53114C',
  /*Yangtze River; Jingjiang*/ '8E4FE8A7-C96C-4941-906D-17F369F3D3EC',
  /*Yangtze River; Jingzhou*/ '9B0B6517-5114-4614-9AF4-1C5F4A880443',
  /*Yangtze River; Kiukiang*/ '21B131D9-564B-4265-9B77-3590A3B4F5C4',
  /*Yangtze River; Lanxi*/ 'E85CFC55-38A3-4A03-A277-47695CBA2C22',
  /*Yangtze River; Liujiawei*/ '81F911D0-9102-4002-9C58-3EB558877DDF',
  /*Yangtze River; Luzhou*/ '0144D7A0-57DF-413E-BFD5-B5FE02AE526D',
  /*Yangtze River; Maanshan*/ '888CCECC-1B32-4434-B287-DFA5E73F2C13',
  /*Yangtze River; Nanjing*/ 'B6B0B6AD-F3F6-4793-84FD-1C93BCE7568D',
  /*Yangtze River; Nantong*/ '6CC46FFB-1DBE-4BBD-8F8E-3B22E2EC48E6',
  /*Yangtze River; Ruichang*/ '9BF5A82A-DFB4-430C-90AC-A68B91BB15C8',
  /*Yangtze River; Taicang*/ 'A2904BC9-6C71-41DA-91CF-9C035017B426',
  /*Yangtze River; Taixing*/ '6E730A8F-E381-4EF1-B563-F184A7C4849F',
  /*Yangtze River; Taizhou*/ 'F9C77A9D-6852-4DA4-B79D-12321B93D725',
  /*Yangtze River; Tongling*/ '762955F4-5B59-4D6A-B572-4BCFBE0E1232',
  /*Yangtze River; Wu Hu*/ 'FED5020D-BD4D-4A93-BB7B-E641FC290DC4',
  /*Yangtze River; Wuhan*/ '7DACC9F6-E45B-4F21-819D-0506C25C8684',
  /*Yangtze River; Wusueh*/ 'EEFE1FCC-55FB-4144-BD5A-D6DD253A9E7F',
  /*Yangtze River; Yangzhong*/ 'C58DB7CA-D140-4EB0-B34B-8363FC77C13E',
  /*Yangtze River; Yangzhou*/ 'ED28E7F4-19CD-48EA-BC43-5F4F17E1DE1B',
  /*Yangtze River; Yibin*/ 'C9CE0C96-23F3-4436-AF9E-B237833410C1',
  /*Yangtze River; Yichang*/ '0BA63C31-6236-4DDE-AC3F-C471F44DA6B1',
  /*Yangtze River; Yizheng*/ '730F2DC3-C32D-43B6-86C1-DFF2E2344DD3',
  /*Yangtze River; Yueyang*/ '1099BB95-0158-4184-9669-751A0CA6B407',
  /*Yangtze River; Zhangjiagang*/ 'B81D10BB-1671-4B05-97ED-04A564BED50C',
  /*Yangtze River; Zhenjiang*/ '39FBBFB5-B38A-4A8E-B323-4C1D34963F7A',
].map((locationId) => ({ id: locationId }));
