import uniq from 'lodash/uniq';
import { isProductionEnvironment } from './environments/is-production-environment';
import * as unhandledExceptionSink from 'diagnostics/unhandled-exception-sink';

/**
 * Allows to define computed properties as getters in a `class` syntax,
 * while still making them copyable by the `{...obj}` spread syntax (by the virtue of turning the getters `enumerable: true`).
 * Otherwise [such getters won't be copied because they're not enumerable](https://stackoverflow.com/questions/34517538/setting-an-es6-class-getter-to-enumerable/34517882#34517882)
 */
export function enrichWithGettersFromClass(objectToEnrich, classWithComputedProperties) {
  const propertiesObject = Object.getOwnPropertyDescriptors(classWithComputedProperties.prototype);
  for (const propName of Object.keys(propertiesObject).filter((_) => _ !== 'constructor'))
    Object.defineProperty(objectToEnrich, propName, {
      ...propertiesObject[propName],
      enumerable: true,
    });
}

export type PropertySourceId = 'objectToEnrich' | 'extraPropertySource';

export function withExtraPropertiesFromFn<ObjectToEnrich>(
  objectToEnrich: ObjectToEnrich,
  getExtraPropertiesSourceObject: (objectToEnrich: ObjectToEnrich) => ExtraPropertiesObject,
  {
    onConflictUse,
    valueAccessedViaExtraPropertyListener,
  }: {
    onConflictUse:
      | PropertySourceId
      | 'throwThisIsUnexpectedError'
      | undefined /* Undefined will throw an exception suggesting to use `onConflictUse` */,
    valueAccessedViaExtraPropertyListener: (propertyKey) => void,
  } = { onConflictUse: undefined }
): ObjectToEnrich &
  ExtraPropertiesObject & {
    getPropertySourceId: (string) => PropertySourceId,
  } {
  const proxyIntrospectionProperties = {
    getPropertySourceId: (propertyKey: string): PropertySourceId => {
      const objectWithProperty = getObjectWithProperty(propertyKey);
      if (objectWithProperty === null)
        throw new Error(`No object has the property \`${getStringForPropertyKey(propertyKey)}\``);
      if (objectWithProperty === objectToEnrich) return 'objectWithProperty';
      else return 'extraPropertySource';
    },
  };

  const resultProxy = new Proxy(objectToEnrich, {
    // See [`Proxy` MDN docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy)
    get(objectToEnrich, propertyKey, receiver) {
      if (propertyKey in proxyIntrospectionProperties)
        return proxyIntrospectionProperties[propertyKey];
      const objectWithProperty = getObjectWithProperty(propertyKey);
      if (objectWithProperty !== null) {
        if (valueAccessedViaExtraPropertyListener && objectWithProperty !== objectToEnrich)
          valueAccessedViaExtraPropertyListener(propertyKey);
        return objectWithProperty[propertyKey];
      } else if (propertyKey in proxyIntrospectionProperties) {
        return proxyIntrospectionProperties[propertyKey];
      } else if (
        [
          Symbol.iterator,
          Symbol.toStringTag,
          'toJSON',
          '@@__IMMUTABLE_ITERABLE__@@' /* `$$typeof` gets tested by Jest */,
          '@@__IMMUTABLE_RECORD__@@' /* `$$typeof` gets tested by Jest */,
          'asymmetricMatch' /* `asymmetricMatch` gets tested by Jest */,
          'nodeType' /* `nodeType` gets tested by Jest */,
          '$$typeof' /* `$$typeof` gets tested by react tests */,
          'cheerio' /* `cheerio` gets tested by snapshot tests */,
          '_isMockFunction' /* `_isMockFunction` gets tested by snapshot tests */,
          'areEquivPropertyTracking' /* `areEquivPropertyTracking` gets tested by Ag-Grid */,
        ].includes(propertyKey)
      )
        return undefined;
      else {
        const error = new Error(
          `No object has the property \`${getStringForPropertyKey(propertyKey)}\``
        );
        if (isProductionEnvironment()) {
          unhandledExceptionSink(error);
          return undefined;
        } else throw error;
      }
    },
    set(target, propertyKey, value, receiver) {
      if (propertyKey in proxyIntrospectionProperties) throw new Error();

      const objectWithProperty = getObjectWithProperty(propertyKey);

      if (valueAccessedViaExtraPropertyListener && objectWithProperty !== objectToEnrich)
        valueAccessedViaExtraPropertyListener(propertyKey);

      objectWithProperty[propertyKey] = value;
      return true; // [_"Return `true` to indicate that assignment succeeded"_] https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/handler/set#Return_value
    },
    getOwnPropertyDescriptor(objectToEnrich, propertyKey) {
      return Reflect.getOwnPropertyDescriptor(getObjectWithProperty(propertyKey), propertyKey);
    },
    ownKeys(objectToEnrich) {
      return uniq(
        Object.values(getAllObjectsBySourceName())
          .map((item) => Reflect.ownKeys(item))
          .flat()
      );
    },
    has(objectToEnrich, propertyKey) {
      return Object.values(getAllObjectsBySourceName()).some((item) =>
        Reflect.has(item, propertyKey)
      );
    },
  });

  return resultProxy;

  function getObjectWithProperty(propertyKey): mixed | null {
    const allObjectsBySourceName = getAllObjectsBySourceName();
    const objectsWithTheProperty = Object.values(allObjectsBySourceName).filter((item) =>
      Reflect.has(item, propertyKey)
    );
    if (objectsWithTheProperty.length === 0) return null;
    if (objectsWithTheProperty.length === 1) return objectsWithTheProperty[0];

    switch (onConflictUse) {
      case 'objectToEnrich':
        return allObjectsBySourceName.objectToEnrich;
      case 'extraPropertySource':
        return allObjectsBySourceName.extraPropertiesSourceObject;
      case 'throwThisIsUnexpectedError':
        throw new Error(
          `Found conflicting property ${propertyKey} which exists in both objects, while this was explicitly indicated as not expected.`
        );
      case undefined:
        throw new Error(
          `Found conflicting property ${propertyKey} which exists in both objects. Specify \`{onConflictUse}\` to behavior.`
        );
      default:
        throw new Error('Unknown value of `onConflictUse`=' + onConflictUse);
    }
  }

  function getAllObjectsBySourceName() {
    return {
      objectToEnrich: objectToEnrich,
      extraPropertiesSourceObject: getExtraPropertiesSourceObject(objectToEnrich),
    };
  }
}

/**
  This function needs to be used in interpolated strings instead of `${propertyKey}` to prevent error of `Cannot convert a Symbol value to a string`
 */
function getStringForPropertyKey(propertyKey) {
  return propertyKey.toString();
}
