export type VoyageEntryTypeId = number;

export type VoyageEntryType = {
  key: VoyageEntryTypeId,
  label: string,
  extendedLabel: string,
};

export const BALLAST_FROM: VoyageEntryType = {
  key: 1,
  label: 'Ballast',
  extendedLabel: 'Ballast Location',
};
export const LOAD: VoyageEntryType = {
  key: 2,
  label: 'Load',
  extendedLabel: 'Load Location',
};
export const DISCHARGE: VoyageEntryType = {
  key: 3,
  label: 'Discharge',
  extendedLabel: 'Discharge Location',
};
export const BUNKER: VoyageEntryType = {
  key: 4,
  label: 'Bunker',
  extendedLabel: 'Bunker Location',
};
export const VIA: VoyageEntryType = {
  key: 5,
  label: 'Via',
  extendedLabel: 'Via Location',
};
export const BALLAST_TO: VoyageEntryType = {
  key: 6,
  label: 'Ballast To',
  extendedLabel: 'Ballast To Location',
};

export const getVoyageLegTypeById = (typeId: VoyageEntryTypeId): VoyageEntryType => {
  switch (typeId) {
    case BALLAST_FROM.key:
      return BALLAST_FROM;
    case LOAD.key:
      return LOAD;
    case DISCHARGE.key:
      return DISCHARGE;
    case BUNKER.key:
      return BUNKER;
    case VIA.key:
      return VIA;
    case BALLAST_TO.key:
      return BALLAST_TO;
    default:
      return {};
  }
};

const LegTypes: Array<VoyageEntryType> = [LOAD, DISCHARGE, BUNKER, VIA];

export const isLoadPort = (type: VoyageEntryType) => type === LOAD;
export const isDischargePort = (type: VoyageEntryType) => type === DISCHARGE;
export const isLoadOrDischargePort = (type: VoyageEntryType) =>
  isLoadPort(type) || isDischargePort(type);

export default LegTypes;
