import numbro from 'numbro';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import VError from 'verror';
import { isUndefined } from 'lodash';

export function formatNumberStrict(
  value: number,
  { noOfDecimalPlaces }: { noOfDecimalPlaces: number }
) {
  if (typeof value !== 'number' || isNaN(value))
    throw new VError({ info: { value } }, '`formatNumberStrict` requires `value` to be a number');
  if (typeof noOfDecimalPlaces !== 'number')
    throw new VError(
      { info: { noOfDecimalPlaces } },
      '`formatNumberStrict` requires `noOfDecimalPlaces` to be a number'
    );

  return formatNumber(value, noOfDecimalPlaces);
}

export function formatNumber(value: any, mantissa: number = 2) {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa,
  });
}

export const formatNumberWithNullAsEmpty = (value: number | null, mantissa: number = 2) => {
  if (!isNumber(value) && !isNull(value))
    throw new Error(`Cannot format '${value}': should be number | null`);

  return value === null ? '' : formatNumber(value, mantissa);
};

export const formatNumberWithNullAndZeroAsEmpty = (value: number | null, mantissa: number = 2) => {
  if (!isNumber(value) && !isNull(value))
    throw new Error(`Cannot format '${value}': should be number | null`);

  return isNull(value) || value === 0 ? '' : formatNumber(value, mantissa);
};

export const formatNumberWithNullAndUndefinedAsEmpty = (
  value: number | null | undefined,
  mantissa: number = 2
) => {
  if (!isNumber(value) && !isNull(value) && !isUndefined(value))
    throw new Error(`Cannot format '${value}': should be number | null | undefined`);

  return isNull(value) || isUndefined(value) ? '' : formatNumber(value, mantissa);
};

/**
 * Formats a number by adding thousands separators where appropriate and show
 * decimal places without truncation or rounding.
 * @example
 * formatNumberWithAutoMantissa(10); // returns '10'
 * formatNumberWithAutoMantissa(10.1); // returns '10.1'
 * formatNumberWithAutoMantissa(10.133); // returns '10.133'
 * formatNumberWithAutoMantissa(1000.133); // returns '1,000.133'
 *
 * @param {number} value the number to format
 * @returns the number with thousands separators applied and a mantissa applied
 * based on the decimal places that the number already has
 * (i.e. no rounding up/down, all decimal places are present)
 */
export function formatNumberWithAutoMantissa(value) {
  return numbro(value).format({
    thousandSeparated: true,
  });
}

/**
 * This has been introduced as `toFixed` returns a string
 * @param {number} value the numeric value to round
 * @param {number} noOfDecimalPlaces no. of decimal places to include/keep
 */
export function round(value, noOfDecimalPlaces = 0) {
  if (typeof value !== 'number' || isNaN(value))
    throw new VError({ info: { value } }, '`round` requires a number');

  return +value.toFixed(noOfDecimalPlaces);
}

/* Binary floating point-arithmetics is subject to rounding errors when converting to decimal-floating point (eg. 0.2+0.1=0.30000000000000004) (more on this [here](https://en.wikipedia.org/wiki/Floating-point_arithmetic#Representable_numbers,_conversion_and_rounding)).
Use this function to shave-off typical errors observed in arithmetics. */
export function roundFloatArithmeticsError(value) {
  return round(
    value,
    /* noOfDecimalPlaces: */ 12 /* 12 is taken from observation - we observed `8.881784197001252e-14` of which `.toFixed(13)` would not be enough, as it would give "0.0000000000001" */
  );
}

/**
 * Introduced so that values are rounded up when given their decimal points.
 * When noOfDecimalPlaces = 2, This will return figure 343.8414166418262 as 343.85, and 2075.9980271386717 as 2076.
 * @param {number} value
 * @param {number} noOfDecimalPlaces
 */
export function roundCeiling(value, noOfDecimalPlaces) {
  if (typeof value !== 'number' || isNaN(value))
    throw new VError({ info: { value } }, '`roundCeiling` requires a number');

  return Math.ceil(value * Math.pow(10, noOfDecimalPlaces)) / Math.pow(10, noOfDecimalPlaces);
}

/**
 * Compare function for numeric values
 * Includes guards to prevent comparison between non-numeric values
 * Null values are always the smallest
 *
 * Based on Array.sort comparison function
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * Defaults to ascending order sorting
 * Mind that undefined values will always end up at the end of the array, regardless ordering logic
 * Equally, undefined values will never be passed to the compare function during Array.sort
 *
 * @param {a} numeric or null value
 * @param {b} numeric or null value
 */
export const compareNumbersWithNullAsSmallest = (a: number | null, b: number | null) => {
  if (!isNumber(a) && !isNull(a))
    throw new Error(`Comparing '${a}' and '${b}': '${a}' is not a number`);
  if (!isNumber(b) && !isNull(b))
    throw new Error(`Comparing '${a}' and '${b}': '${b}' is not a number`);

  if (isNull(a) && isNull(b)) return 0; // both values are null
  if (isNull(a)) return -1; // a is less than b
  if (isNull(b)) return 1; // b is less than a

  return a - b;
};

/**
 * @param {number} value - the number to check
 * @param {number} maximumPrecision - the maximum precision (inclusive of itself) that is accepted
 */
export function isPrecisionGreaterThan(value: number, maximumPrecision: number) {
  if (!isNumber(value)) {
    throw new Error(
      `The argument \`value\` must be of type number. Instead '${value}' was passed to \`isPrecisionGreaterThan\`.`
    );
  }

  if (maximumPrecision <= 0) {
    throw new Error(
      `The \`maximumPrecision\` of '${maximumPrecision}' has to be be greater than 0.`
    );
  }

  if (!Number.isInteger(maximumPrecision)) {
    throw new Error(`The \`maximumPrecision\` of '${maximumPrecision}' has to be an integer.`);
  }

  if (Number.isInteger(value)) {
    return false;
  }

  return value.toString().split('.')[1].length > maximumPrecision;
}
