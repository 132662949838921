import { createSelector } from 'reselect';
import { getActiveVessel } from 'common-selectors/get-active-vessel';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';

export const selector = createSelector(
  (state) => state.appMetadata.isSpeedAndConsEditModalVisible,
  (state) => getActiveVessel(state),
  (state) => getActiveWorksheetId(state),
  (state) => getActiveWorksheet(state),
  (isSpeedAndConsEditModalVisible, activeVessel, worksheetId, activeWorksheet) => {
    let generatorState = activeWorksheet?.showEditGeneratorSpeedAndCons ?? false;
    return {
      isSpeedAndConsEditModalVisible,
      activeVessel,
      worksheetId,
      generatorState,
    };
  }
);
