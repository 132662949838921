import filter from 'lodash/filter';
import { emptyGuid } from 'utilities/guid';
import { createSelector } from 'reselect';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { getActiveWorkbookId } from 'common-selectors/get-active-workbook-id';

export const selector = createSelector(
  getActiveWorksheet,
  getActiveWorkbookId,
  (state, ownProps) => state.workbooksById[ownProps.router.params.workbookId],
  (state, ownProps) =>
    (state.workbooksById[ownProps.router.params.workbookId] &&
      state.workbooksById[ownProps.router.params.workbookId].isEditable) ||
    false,
  (state, ownProps) =>
    (state.workbooksById[ownProps.router.params.workbookId] &&
      state.workbooksById[ownProps.router.params.workbookId].name) ||
    'N/A',
  (state) => filter(state.worksheetsById, (e) => e.workbookId === state.activeWorkbookId),
  (activeWorksheet, activeWorkbookId, activeWorkbook, isEditable, workbookName, worksheets) => {
    const worksheetId = activeWorksheet.id;
    const worksheetName = activeWorksheet.name;
    const nextWorksheetId = findNextWorksheetId(worksheetId, worksheets);
    return {
      worksheetId: worksheetId,
      worksheetName: worksheetName,
      activeWorksheet: activeWorksheet,
      workbookId: activeWorkbookId,
      activeWorkbook: activeWorkbook,
      nextWorksheetId: nextWorksheetId,
      isEditable: isEditable,
      workbookName: workbookName,
    };
  }
);

const findNextWorksheetId = (currentWorksheetId, worksheets) => {
  if (!worksheets || worksheets.length < 2) {
    return emptyGuid;
  }

  const currentIndex = worksheets.findIndex((w) => w.id === currentWorksheetId);

  if (currentIndex < 0) {
    return emptyGuid;
  }

  if (currentIndex === 0) {
    return worksheets[currentIndex + 1].id;
  }

  return worksheets[currentIndex + 1 < worksheets.length ? currentIndex + 1 : currentIndex - 1].id;
};
