import { CancelTokenSource } from 'axios';
import { arcApi, veApi } from 'api';
import portsInSeca from 'constants/data/ports/ports-in-seca-zones';
import tankersPortsInSeca from 'constants/data/ports/tankers-only-ports-in-seca-zones';
import { marketSegmentIds } from 'constants/market-segments';
export async function isPortInSeca(
  portId,
  marketSegmentId: string = 'undefined',
  cancelToken?: CancelToken
) {
  let combinedPortsInSeca;

  // TODO - replace this with a Location API call once the version that offers the feature is out
  if (marketSegmentId === marketSegmentIds.Tankers) {
    combinedPortsInSeca = tankersPortsInSeca.concat(portsInSeca);
  } else {
    combinedPortsInSeca = portsInSeca;
  }
  return combinedPortsInSeca.map((_) => _.id.toLowerCase()).includes(`${portId}`.toLowerCase());
}

type LocationResponse = {
  locationId: string,
  locationTypeId: string,
  locationName: string,
  isInSeca: Boolean,
  isInEea: Boolean,
  zone: string | null,
  country: string | null,
  longitude: number | null,
  latitude: number | null,
};

export async function getLocationById(locationId: string): Promise<LocationResponse> {
  const url = `/api/1/0/location/${locationId}`;
  const location = (await arcApi.get(url)).data;
  return {
    locationId: location.locationId,
    locationTypeId: location.locationTypeId,
    locationName: location.name,
    isInSeca: await isPortInSeca(location.locationId),
    isInEea: location.isInEea,
    zone: location.zone,
    country: location.country,
    longitude: location.longitude,
    latitude: location.latitude,
  };
}

export type PortMenuItem = {
  locationId: string,
  locationName: string,
  locationAliases: string,
  zone: string,
  country: string,
  isInEea: boolean,
  countryCode: string,
  latitude: number,
  longitude: number,
};

export async function searchPorts(
  searchTerm: string,
  cancelTokenSource: CancelTokenSource | null = null
): Promise<PortMenuItem> {
  const url = `/location/search?query=${searchTerm}`;

  const response = await veApi.get(url, {
    cancelToken: cancelTokenSource && cancelTokenSource.token,
  });

  return response.data.map((apiPort) => {
    return {
      locationId: apiPort.id,
      locationName: apiPort.name,
      locationAliases: apiPort.aliases,
      zone: apiPort.zone,
      isInEea: apiPort.isInEea,
      country: apiPort.country,
      countryCode: apiPort.countryCode,
      latitude: apiPort.latitude,
      longitude: apiPort.longitude,
    };
  });
}
