import { veApi } from 'api';
import { isFeatureEnabled } from 'config/feature-control';
import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import { emptyGuid } from 'utilities/guid';

export const SeriesId = '547474';

export async function getEuaPrice() {
  try {
    var response;
    if (isFeatureEnabled('refinitivEuaPrices')) {
      response = await veApi.get(`/euaprices/refinitiv/${SeriesId}`);
    } else {
      response = await veApi.get(`/euaprices/${SeriesId}`);
    }
    return getPriceData(response);
  } catch (error) {
    unhandledExceptionSinkSend(error);
    return buildEmptyBunkerPriceResponse();
  }
}

function buildEmptyBunkerPriceResponse() {
  return {
    Id: emptyGuid,
    EuaPrice: null,
    Currency: 'USD',
    TimeStamp: null,
  };
}

const getPriceData = (response) => {
  if (!response || !response.data) {
    return buildEmptyBunkerPriceResponse();
  }
  return response.data;
};
