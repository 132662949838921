import React, { FC } from 'react';
import { IconButton } from 'components/button';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import NewGuid from 'utilities/guid';
import classNames from 'classnames';

interface IIconTooltip {
  className?: string;
  disabled?: boolean;
  place?: string;
  iconClassName?: string;
  icon: string;
}
export const IconTooltip: FC<IIconTooltip> = ({
  className,
  disabled,
  place,
  iconClassName,
  children,
  icon,
}) => {
  let guid = NewGuid();
  const classes = classNames(className);
  const iconClasses = classNames(iconClassName);

  return (
    <>
      <div data-tooltip-id={`tooltip-${guid}`} className={classes}>
        <IconButton className={iconClasses} icon={icon} disabled={disabled} onClick={() => null} />
      </div>
      {!disabled && (
        <ReactTooltip
          place={place || 'bottom'}
          id={`tooltip-${guid}`}
          className="ve-tooltip-default"
          noArrow
        >
          {children}
        </ReactTooltip>
      )}
    </>
  );
};
