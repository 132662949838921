import React, { Component } from 'react';
import { PanelSection } from 'components/panel';
import Label from 'components/label';
import UserAutocomplete from 'components/user-autocomplete';
import Async from 'react-async';
import ProgressBar from 'components/progress-bar';
import { ErrorPanel } from 'components/error-panel';
import { searchWorkbooks } from 'api/clients/workbook';
import TeamWorkbooksGrid from './team-workbooks-grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { loadMyWorkbooks } from 'actions/workbook';
import Toggle from 'components/toggle';
import './styles.scss';
export class TeamWorkbooks extends Component {
  state = {
    searchCriteriaUser: null,
    shouldShowTeamsWorkbooks: false,
  };

  onToggleWorkbooksMode = (newValue) => {
    this.setState({
      shouldShowTeamsWorkbooks: newValue,
    });

    this.props.onToggleWorkbooksMode(newValue);
  };

  onUserSearchChange = (newValue) => {
    this.setState({
      searchCriteriaUser: newValue,
      searchWorkbooksPromise:
        newValue &&
        searchWorkbooks({
          userId: newValue.id,
        }),
    });
  };

  onSearchResolved = (workbooks) => (
    <PanelSection className="team-workbooks__list">
      <TeamWorkbooksGrid
        workbooks={workbooks}
        activeWorkbookId={this.props.activeWorkbookId}
        loadMyWorkbooks={this.props.loadMyWorkbooks}
        title={this.props.title}
      />
    </PanelSection>
  );

  render() {
    return (
      <>
        <div className="search-panel__tool-bar">
          <div className="search-panel__tool-bar-container">
            <div className="search-panel__tool-bar-toggle">
              <Toggle
                checked={this.state.shouldShowTeamsWorkbooks}
                onChange={this.onToggleWorkbooksMode}
                label="Search for a colleague"
                className="search-panel__toggle"
                diagnosticId="WorkbookPanel/SearchForAColleague"
              />
            </div>
          </div>
          <div
            style={{
              display: !this.state.shouldShowTeamsWorkbooks && 'none',
            }}
          >
            <div className="search-panel__tool-bar-user-selection">
              <div className="team-workbooks team-workbooks__search">
                <UserAutocomplete
                  value={this.state.searchCriteriaUser}
                  onChange={this.onUserSearchChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: !this.state.shouldShowTeamsWorkbooks && 'none',
          }}
        >
          <div className="search-panel-tab-wrapper-search-tab__content_inner">
            <div className="team-workbooks">
              <Async promise={this.state.searchWorkbooksPromise}>
                <Async.Pending>
                  <ProgressBar />
                </Async.Pending>
                <Async.Fulfilled>{this.onSearchResolved}</Async.Fulfilled>
                <Async.Rejected>
                  <div
                    hidden // TODO remove this when the appearance is approved. See #ErrorsHiddenUntilApproved for other places affected. For context see https://clarksonscloud.visualstudio.com/Voyage-Estimation/_workitems/edit/15112
                  >
                    <ErrorPanel>
                      Sorry. There's been a problem with your search. Please check your connection,
                      try again, try refreshing the page. If the problem persists, contact support.
                    </ErrorPanel>
                  </div>
                </Async.Rejected>
              </Async>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.activeWorkbookId,
  (activeWorkbookId) => ({
    activeWorkbookId,
  })
);
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadMyWorkbooks }, dispatch);
}

const TeamWorkbooksContainer = connect(mapStateToProps, mapDispatchToProps)(TeamWorkbooks);

export default TeamWorkbooksContainer;
