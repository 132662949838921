import {
  VESSEL_OPEN_POSITION_CHANGED,
  VESSEL_OPEN_POSITION_IS_IN_SECA_CHANGED,
  VESSEL_OPEN_POSITION_IS_IN_EEA_CHANGED,
  VESSEL_NEXT_PORT_OF_CALL_IS_IN_EEA_CHANGED,
  VESSEL_OPEN_DATE_CHANGED,
  VESSEL_SCRUBBER_CHANGED,
  VESSEL_APPLY_NEW_OPEN_POSITION,
  VESSELS_ADD,
  VESSELS_REMOVE,
  VESSELS_MOVED,
  VESSEL_APPLY_NEW_DETAILS,
  VESSEL_DEADWEIGHT_CHANGED,
  VESSEL_DRAFT_CHANGED,
  VESSEL_DRAFT_UNIT_CHANGED,
  VESSEL_GRAIN_CHANGED,
  VESSEL_GRAIN_UNIT_CHANGED,
  VESSEL_TPCMI_CHANGED,
  VESSEL_IMMERSION_UNIT_CHANGED,
  VESSEL_CONSTANTS_CHANGED,
  VESSEL_PARCEL_VOYAGE_TOGGLE_CHANGED,
} from 'constants/action-types/worksheet/vessel';

import {
  SPEEDCONS_ACTIVE_CHANGED,
  SPEEDCONS_APPLY_NEW_DETAILS,
  SPEEDCONS_CONSUMPTION_CHANGED,
  SPEEDCONS_FUELGRADE_CHANGED,
  SPEEDCONS_MAIN_FUELGRADE_CHANGED,
  SPEEDCONS_ZONESPECIFIC_SECA_FUELGRADE_CHANGED,
  SPEEDCONS_ZONESPECIFIC_SLUGDISCHARGE_FUELGRADE_CHANGED,
  SPEEDCONS_ZONESPECIFIC_SECA_AND_SLUGDISCHARGE_FUELGRADE_CHANGED,
  SPEEDCONS_SPEED_CHANGED,
  SPEEDCONS_SCRUBBER_CONSUMPTION_CHANGED,
} from 'constants/action-types/worksheet/speed-and-consumptions';

import { RATE_COMMISSION_TIME_CHARTER_CHANGED } from 'constants/action-types/worksheet/rates';

import {
  RATE_TIME_CHARTER_CHANGED,
  RATE_BALLAST_BONUS_CHANGED,
} from 'constants/action-types/worksheet/vessel/rates';

import {
  CARGO_GROSS_VOYAGE_RATE_CHANGED,
  CARGO_BROKER_COMMISSION_VOYAGE_RATE_CHANGED,
  CARGO_ADDRESS_COMMISSION_VOYAGE_RATE_CHANGED,
  CARGO_STOWAGE_CHANGED,
  CARGO_STOWAGE_UNIT_CHANGED,
} from 'constants/action-types/worksheet/cargoes';

import {
  VOYAGE_TYPE_CHANGED,
  VOYAGE_LOCATION_CHANGED,
  VOYAGE_GEAR_CHANGED,
  VOYAGE_CARGO_QUANTITY_CHANGED,
  VOYAGE_LOAD_DISCHARGE_RATE_CHANGED,
  VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED,
  VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED,
  VOYAGE_DELAY_CHANGED,
  VOYAGE_DELAY_UNIT_CHANGED,
  VOYAGE_TURN_TIME_CHANGED,
  VOYAGE_TURN_TIME_UNIT_CHANGED,
  VOYAGE_WEATHER_FACTOR_CHANGED,
  VOYAGE_PORT_COST_CHANGED,
  VOYAGE_IS_IN_SECA_CHANGED,
  VOYAGE_IS_IN_EEA_CHANGED,
  VOYAGE_LOCATION_DELETED,
  VOYAGE_LOCATION_ADDED,
  VOYAGE_ROUTE_VARIANTS_SET,
  VOYAGE_SET_PORTS_IN_SECA,
  VOYAGE_LEG_MOVED,
  VOYAGE_TOGGLE_AUTO_INTAKE_CALC,
  VOYAGE_DRAFT_CHANGED,
  VOYAGE_DRAFT_UNIT_CHANGED,
  VOYAGE_SALINITY_CHANGED,
  VOYAGE_DELAY_CHANGED_ALL_LEGS,
  VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS,
  VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED,
  VOYAGE_LEGS_LOADED,
  VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS,
} from 'constants/action-types/worksheet/voyage';

import {
  CANAL_BALLAST_COST_CHANGED,
  CANAL_LADEN_COST_CHANGED,
  CANAL_BALLAST_WAITING_TIME_CHANGED,
  CANAL_LADEN_WAITING_TIME_CHANGED,
  CANAL_BALLAST_TRANSIT_TIME_CHANGED,
  CANAL_LADEN_TRANSIT_TIME_CHANGED,
} from 'constants/action-types/worksheet/canals';

import {
  PRE_DEFINED_EXPENSE_COST_UPDATED,
  PRE_DEFINED_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_DESCRIPTION_UPDATED,
  OTHER_EXPENSE_COST_UPDATED,
  OTHER_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_ADDED,
  OTHER_EXPENSE_REMOVED,
} from 'constants/action-types/worksheet/additional-expenses';

import {
  ADDITIONAL_INCOME_INCOME_UPDATED,
  ADDITIONAL_INCOME_ADDED,
  ADDITIONAL_INCOME_REMOVED,
} from 'constants/action-types/worksheet/cargoes/additional-incomes';

import {
  BUNKER_QUANTITY_CHANGED,
  BUNKER_PRICE_CHANGED,
  BUNKER_LOAD_PRICE,
  SET_WORKSHEET_BUNKER_PRICES,
} from 'constants/action-types/worksheet/bunkers';

import { LOAD_WORKSHEET } from 'constants/action-types/workbook';

import { APPLY_PRICES_TO_BUNKERS_ON_BOARD } from 'constants/action-types/bunker-ports';
import { COMMISSION_CHANGED } from 'constants/action-types/worksheet/commission';
import {
  TANKER_RATE_TYPE_CHANGED,
  TANKER_FLAT_RATE_CHANGED,
  TANKER_WORLD_SCALE_CHANGED,
  TANKER_GROSS_VOYAGE_RATE_CHANGED,
  TANKER_LUMP_SUM_CHANGED,
} from 'constants/action-types/worksheet/tanker-rates';
import {
  CARBON_COST_INCLUDE_IN_COSTS_CHANGED,
  EUA_PRICE_CHANGED,
  EUA_YEAR_CHANGED,
} from '../../../constants/action-types/worksheet/eua';

const calculatableActions = [
  VESSEL_OPEN_POSITION_CHANGED,
  VESSEL_OPEN_POSITION_IS_IN_SECA_CHANGED,
  VESSEL_OPEN_POSITION_IS_IN_EEA_CHANGED,
  VESSEL_NEXT_PORT_OF_CALL_IS_IN_EEA_CHANGED,
  VESSEL_OPEN_DATE_CHANGED,
  VESSEL_APPLY_NEW_OPEN_POSITION,
  VESSEL_APPLY_NEW_DETAILS,
  VESSEL_DEADWEIGHT_CHANGED,
  VESSEL_PARCEL_VOYAGE_TOGGLE_CHANGED,
  VESSEL_DRAFT_CHANGED,
  VESSEL_DRAFT_UNIT_CHANGED,
  VESSEL_GRAIN_CHANGED,
  VESSEL_GRAIN_UNIT_CHANGED,
  VESSEL_TPCMI_CHANGED,
  VESSEL_IMMERSION_UNIT_CHANGED,
  VESSEL_CONSTANTS_CHANGED,
  SPEEDCONS_ACTIVE_CHANGED,
  SPEEDCONS_APPLY_NEW_DETAILS,
  SPEEDCONS_CONSUMPTION_CHANGED,
  SPEEDCONS_FUELGRADE_CHANGED,
  SPEEDCONS_MAIN_FUELGRADE_CHANGED,
  SPEEDCONS_ZONESPECIFIC_SECA_FUELGRADE_CHANGED,
  SPEEDCONS_ZONESPECIFIC_SLUGDISCHARGE_FUELGRADE_CHANGED,
  SPEEDCONS_ZONESPECIFIC_SECA_AND_SLUGDISCHARGE_FUELGRADE_CHANGED,
  SPEEDCONS_SPEED_CHANGED,
  RATE_TIME_CHARTER_CHANGED,
  RATE_BALLAST_BONUS_CHANGED,
  RATE_COMMISSION_TIME_CHARTER_CHANGED,
  CARGO_GROSS_VOYAGE_RATE_CHANGED,
  CARGO_BROKER_COMMISSION_VOYAGE_RATE_CHANGED,
  CARGO_ADDRESS_COMMISSION_VOYAGE_RATE_CHANGED,
  CARGO_STOWAGE_CHANGED,
  CARGO_STOWAGE_UNIT_CHANGED,
  VOYAGE_SET_PORTS_IN_SECA,
  VOYAGE_TYPE_CHANGED,
  VOYAGE_LOCATION_CHANGED,
  VOYAGE_GEAR_CHANGED,
  VOYAGE_CARGO_QUANTITY_CHANGED,
  VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS,
  VOYAGE_LOAD_DISCHARGE_RATE_CHANGED,
  VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED,
  VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED,
  VOYAGE_DELAY_CHANGED,
  VOYAGE_DELAY_CHANGED_ALL_LEGS,
  VOYAGE_DELAY_UNIT_CHANGED,
  VOYAGE_TURN_TIME_CHANGED,
  VOYAGE_TURN_TIME_UNIT_CHANGED,
  VOYAGE_WEATHER_FACTOR_CHANGED,
  VOYAGE_PORT_COST_CHANGED,
  VOYAGE_IS_IN_SECA_CHANGED,
  VOYAGE_IS_IN_EEA_CHANGED,
  VOYAGE_LOCATION_DELETED,
  VOYAGE_LOCATION_ADDED,
  VOYAGE_ROUTE_VARIANTS_SET,
  VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED,
  SET_WORKSHEET_BUNKER_PRICES,
  CANAL_BALLAST_COST_CHANGED,
  CANAL_LADEN_COST_CHANGED,
  CANAL_BALLAST_WAITING_TIME_CHANGED,
  CANAL_LADEN_WAITING_TIME_CHANGED,
  CANAL_BALLAST_TRANSIT_TIME_CHANGED,
  CANAL_LADEN_TRANSIT_TIME_CHANGED,
  PRE_DEFINED_EXPENSE_COST_UPDATED,
  PRE_DEFINED_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_DESCRIPTION_UPDATED,
  OTHER_EXPENSE_COST_UPDATED,
  OTHER_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_ADDED,
  OTHER_EXPENSE_REMOVED,
  BUNKER_QUANTITY_CHANGED,
  BUNKER_PRICE_CHANGED,
  BUNKER_LOAD_PRICE,
  LOAD_WORKSHEET,
  VOYAGE_LEG_MOVED,
  VOYAGE_LEGS_LOADED,
  APPLY_PRICES_TO_BUNKERS_ON_BOARD,
  SPEEDCONS_SCRUBBER_CONSUMPTION_CHANGED,
  VESSEL_SCRUBBER_CHANGED,
  VESSELS_ADD,
  VESSELS_REMOVE,
  VESSELS_MOVED,
  VOYAGE_TOGGLE_AUTO_INTAKE_CALC,
  VOYAGE_DRAFT_CHANGED,
  VOYAGE_DRAFT_UNIT_CHANGED,
  VOYAGE_SALINITY_CHANGED,
  ADDITIONAL_INCOME_INCOME_UPDATED,
  ADDITIONAL_INCOME_ADDED,
  ADDITIONAL_INCOME_REMOVED,
  COMMISSION_CHANGED,
  TANKER_RATE_TYPE_CHANGED,
  TANKER_FLAT_RATE_CHANGED,
  TANKER_WORLD_SCALE_CHANGED,
  TANKER_GROSS_VOYAGE_RATE_CHANGED,
  TANKER_LUMP_SUM_CHANGED,
  EUA_PRICE_CHANGED,
  EUA_YEAR_CHANGED,
  CARBON_COST_INCLUDE_IN_COSTS_CHANGED,
  VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS,
];

export default calculatableActions;
