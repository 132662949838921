import { useDebouncedDOMEvent } from 'hooks/debounced-event';
import { useDOMEvent } from 'hooks/dom-event';
import { debounceDefaults } from 'constants/defaults/debounce';
import { useRef } from 'react';

// No unit tests are required for these ones as they're wrappers around other hooks
export function useDebouncedWindowResize(callback, debounceOpts = debounceDefaults) {
  const ref = useRef(global['window']);
  useDebouncedDOMEvent(ref, 'resize', callback, debounceOpts);
}

export function useWindowResize(callback, debounceOpts = debounceDefaults) {
  const ref = useRef(global['window']);
  useDOMEvent(ref, 'resize', callback, debounceOpts);
}
