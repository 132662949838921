import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { selector } from './selector';
import SummaryDataRow from '../summary-data-row';

type Props = {
  carbonCostPerDay: Number,
  carbonCostPerMT: Number,
  carbonCostTotal: Number,
};
export class CarbonCost extends Component<Props> {
  render() {
    return (
      <div className="carbon-cost-section">
        <span className="carbon-cost-label">
          <span className="carbon-cost-label-text">Carbon Cost</span>
        </span>

        <div className="carbon-cost-data">
          <SummaryDataRow
            data={this.props.carbonCostPerDay}
            title="Per Day"
            unit="$"
            unitPrecedesValue
          />
          <SummaryDataRow
            data={this.props.carbonCostPerMT}
            title="Per MT (cargo)"
            unit="$"
            unitPrecedesValue
          />
          <SummaryDataRow
            data={this.props.carbonCostTotal}
            title="Total (voyage)"
            unit="$"
            unitPrecedesValue
          />
          <SummaryDataRow data={this.props.euasDueLeg1} title="EUAs Due Leg 1" unitPrecedesValue />
          <SummaryDataRow data={this.props.euasDueLeg2} title="EUAs Due Leg 2" unitPrecedesValue />
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

export const CarbonCostContainer = connect(mapStateToProps)(CarbonCost);
