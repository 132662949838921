import React from 'react';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import * as RedirectManager from 'utilities/redirect-manager';

export const WorksheetNameRenderer = (props, closePanel, message) => {
  const workbook = props.data.workbookId;
  const worksheet = props.data.worksheetId;
  const url = `workbook/${workbook}/worksheet/${worksheet}`;
  const name = props.value;
  const storeURLInLocalStorage = () => {
    RedirectManager.storeURL(url);
  };
  const trackWorksheetOpenEvent = () => {
    const properties = {
      diagnosticId: 'WorksheetSearch/ResultClicked',
      search: {
        resultCategories: 'All',
        searchQuery: props.data.searchTerm,
        worksheetId: worksheet,
        workbookId: workbook,
        matchingFields: {
          worksheetName: props.data.matchesWorksheetName,
          cargo: props.data.matchesCargos,
          vessel: props.data.matchesVessels,
          loadPort: props.data.matchesLoadPort,
          dischargePort: props.data.matchesDischargePort,
        },
        searchResultMatchNumber: props.data.position,
        searchResultTotalResults: props.data.totalCount,
        queryTime_ms: props.data.queryTime_ms,
        menuOption: 'none',
      },
    };
    trackEvent(
      'WorksheetRowRenderer',
      'Worksheet opened',
      properties,
      {},
      eventDestination.ANALYSIS
    );
    closePanel();
  };
  return (
    <span>
      <a
        tabIndex="0"
        href={'#/' + url}
        className="workbook-card__cell workbook-card__cell--name"
        onClick={trackWorksheetOpenEvent}
        onMouseDown={storeURLInLocalStorage}
      >
        {name && name[0] ? name : message}
      </a>
    </span>
  );
};
