import * as generatorFnUtils from 'utilities/iterable/generator-fn-utils';
import isNil from 'lodash/isNil';
import groupBy from 'lodash/groupBy';
import { isEmptyGuid } from 'utilities/guid';

import { Geolocation, isLocationTypeSupported } from 'utilities/location';
import { singleOrThrow } from '../../../utilities/iterable';

export type FromLocationWithApplicableVesselsInfo = {
  locationInfo: null | LocationBasicInfo,
  vessels: Set<IVesselViewModel>,
};

export type VoyageEntryLocationsWithInboundRoutesInfo = {
  fromLocations: Array<FromLocationWithApplicableVesselsInfo>,
  legWithToLocation: {
    id: VoyageEntryId,
    toLocationInfo: LocationBasicInfo,
  },
};

export type VoyageEntryLocationsInfoWithDetails = {
  fromToInfo: VoyageEntryLocationsWithInboundRoutesInfo,
  voyageEntryDetails: IVoyageLegViewModel,
};

export const getAllLegsWithFromToInfo: ({
  vessels: Array<IVesselViewModel>,
  legs: Array<IVoyageLegViewModel>,
}) => Array<VoyageEntryLocationsInfoWithDetails> = generatorFnUtils.wrapFnWithArrayConvert(
  function* ({ vessels, legs }) {
    const openLocationFromLocationsWithVessels = Object.values(
      groupBy(
        vessels.map((vessel) => ({
          openPositionLocationInfoOrNull: getOpenPositionLocationInfoOrNull(vessel.openPosition),
          vessel: vessel,
        })),
        (locationWithVessel) =>
          Geolocation.getIdentityPrimitiveNullSafe(
            locationWithVessel.openPositionLocationInfoOrNull &&
              locationWithVessel.openPositionLocationInfoOrNull.geoCoords
          )
      )
    ).map((group) => ({
      openPositionLocationInfoOrNull: group[0].openPositionLocationInfoOrNull,
      vessels: new Set(group.map((groupItem) => groupItem.vessel)),
    }));

    let nextFromLocations: Array<FromLocationWithApplicableVesselsInfo> =
      openLocationFromLocationsWithVessels.map((_) => ({
        locationInfo: _.openPositionLocationInfoOrNull,
        vessels: _.vessels,
      }));

    for (const toLeg of legs) {
      const toLocationGeoCoords = Geolocation.fromObjectOrNullIfEmpty(toLeg);
      const toLocationInfo =
        toLocationGeoCoords === null
          ? null
          : {
              geoCoords: toLocationGeoCoords,
              locationId: toLeg.locationId,
              name: toLeg.name,
            };
      yield {
        fromToInfo: {
          fromLocations: nextFromLocations,
          legWithToLocation: {
            id: toLeg.id,
            toLocationInfo: toLocationInfo,
          },
        },
        voyageEntryDetails: toLeg,
      };

      nextFromLocations = [
        {
          locationInfo: toLocationInfo,
          vessels: new Set(
            /* Specify all vessels in all but the first item in the sequence because presently we only support different routes per vessels to the first voyage entry (we only support vessels having a different open location but not, for example, different preferences for waypoints to avoid).*/
            vessels
          ),
        },
      ];
    }
  }
);

export type SingleVesselVoyageEntryRoute = {
  fromLocationInfo: LocationBasicInfo | null,
  toLocationInfo: LocationBasicInfo | null,
  inboundRouteVariant: InboundRouteVariantViewModel | null,
  voyageEntryDetails: IVoyageLegViewModel,
};

export const getAllLegsWithInboundRouteForVessel: ({
  worksheet: IWorksheetViewModel,
  vessel: IVesselViewModel,
}) => Array<SingleVesselVoyageEntryRoute> = ({ worksheet, vessel }) =>
  getAllLegsWithFromToInfo({
    vessels: worksheet.vessels,
    legs: worksheet.voyage.legs,
  }).map((_) => {
    const fromLocationInfoWithVessels = singleOrThrow(
      _.fromToInfo.fromLocations.filter((_) => _.vessels.has(vessel))
    );

    return {
      voyageEntryDetails: _.voyageEntryDetails,
      fromLocationInfo:
        /* An empty location item of 'fromLocations' was useful to still carry the `vessels` member, but within the context of a specific vessel, it's of no use, hence the output type signifies this with the entire `fromLocationInfo` being `null`*/
        fromLocationInfoWithVessels.locationInfo,
      toLocationInfo: _.fromToInfo.legWithToLocation.toLocationInfo,
      inboundRouteVariant:
        _.voyageEntryDetails.inboundRoute.variants.find((_) =>
          Geolocation.areEqualNullSafe(
            _.fromLocationGeoCoords,
            fromLocationInfoWithVessels.locationInfo &&
              fromLocationInfoWithVessels.locationInfo.geoCoords
          )
        ) || null, // `null` can happen when route calculation is still pending. That's because location edition is implemented as two-step action - route edition and then separate route calculation. This may be improved in https://dev.azure.com/clarksonscloud/Voyage-Estimation/_workitems/edit/69258
    };
  });

export function isValidOpenPositionLocationType(locationTypeId: ?string): boolean {
  /**
   * We are optimistically returning that the open position is of a valid
   * location type for existing worksheets that have open positions without
   * the location type id. All newly added vessels with open positions will
   * contain a location type and will be subject to the actual check.
   */
  if (isNil(locationTypeId) || isEmptyGuid(locationTypeId)) {
    return true;
  }

  return isLocationTypeSupported(locationTypeId);
}

function isValidOpenPositionLocation(openPosition: IOpenPositionViewModel): boolean {
  return (
    Geolocation.isValid(openPosition) &&
    isValidOpenPositionLocationType(openPosition.locationTypeId)
  );
}

export function getOpenPositionLocationOrNull(
  openPosition: IOpenPositionViewModel
): Geolocation | null {
  const locationInfoOrNull = getOpenPositionLocationInfoOrNull(openPosition);
  return locationInfoOrNull && locationInfoOrNull.geoCoords;
}

export function getOpenPositionLocationInfoOrNull(
  openPosition: IOpenPositionViewModel
): LocationBasicInfo {
  /* Explanation:
 #QuirkOfPersistingCoordinatesForInvalidLocationTypes - the current implementation ignores (at the time of writing it is done silently, that is, without indicating to the user) some locations of too large areas (e.g. countries like China), and treats them as if the vessel had `null` (e.g. the vessel will not have an open location and will start the voyage at the first VoyageEntry) */
  if (isValidOpenPositionLocation(openPosition) === false) return null;

  return {
    locationId: openPosition.id,
    name: openPosition.name,
    geoCoords: Geolocation.fromObject(openPosition),
  };
}
