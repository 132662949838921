import { createSelector } from 'reselect';

import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { DAYS, HOURS } from 'constants/enums/time';
import { getActiveCalculationResult } from '../../common-selectors/get-active-calculation-result';
import { DISCHARGE, LOAD } from '../../constants/enums/voyage-leg';

export const selector = createSelector(
  getActiveWorksheet,
  getActiveCalculationResult,
  (worksheet, calculationResult) => ({
    worksheetId: worksheet.id,
    waitingDays:
      worksheet.voyage.totalWaitingDays ??
      worksheet.voyage.legs.reduce((acc, curr) => acc + getDelayInDays(curr), 0),
    portDays: worksheet.voyage.legs.reduce((acc, curr) => acc + curr.loadDischargeRate, 0),
    ballastDays: calculationResult?.time.totalDaysBallast ?? 0,
    ladenDays: calculationResult?.time.totalDaysLaden ?? 0,
    totalDays: calculationResult?.time.totalDays ?? 0,
  })
);

const getDelayInDays = ({ delay, delayUnit }) => {
  switch (delayUnit) {
    case HOURS.key:
      return delay / 24;
    case DAYS.key:
      return delay;
    default:
      return 0;
  }
};
