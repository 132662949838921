import * as iterableUtils from 'utilities/iterable/index';

/**
Generators, apart from memory performance gains (can be used in streaming etc) also make function's code easier to understand (especially by reducing the possible mutations to the result variable - the only one is 'append to the end').
In JavaScript though until [this proposal](https://github.com/tc39/proposal-iterator-helpers) gets implemented, Iterables are missing a lot of basic functionality like `map` or `filter` (unlike in .NET's `IEnumerable` for example).
Converting to an array is essentially an easy way to get all of the array's functionality, whereas adding this at the definition of the generator function essentially means using the generator for the 'understandability' benefit, without the performance benefit.
```
const someBusinessLogicFn = generatorFnUtils.wrapFnWithArrayConvert(function *() {
  ...
  yield myBusinessConcerns.Blah();
  ...
})
```
*/
export function wrapFnWithArrayConvert<Item>(generatorFn: () => Iterable<Item>): () => Array<Item> {
  return function callGeneratorAndConverToArray() {
    return [...generatorFn.apply(this, arguments)];
  };
}

/**
A drop-in replacement for:
```
let result = [];
for (...) {
 result.push(x);
}
```
that reduces state changes (variable assignment and mutations). Usage:
```
const result = generatorFnUtils.arrayFromGeneratorFn(function *() {
  for (...) {
    yield x;
  }
});
```
*/
export function arrayFromGeneratorFn<Item>(generatorFn: () => Iterable<Item>): () => Array<Item> {
  // Admittedly, not a huge complexity that this function is encapsulating, using the function makes the intents clearer.
  return [...generatorFn()];
}

/**
A drop-in replacement for:
```
let result = [];
for (...) {
 await someAsyncFunction();
 result.push(x);
}
```
that reduces state changes (variable assignment and mutations). Usage:
```
const result = generatorFnUtils.arrayPromiseFromAsyncGeneratorFn(function *() {
  for (...) {
    await someAsyncFunction();
    yield x;
  }
});
```

It also allows to write code that is easy to switch between sequential and parallel: for prototyping or debugging it might be better to write sequentially, and then one can just turn all `yield await someExpression()` into `yield someExpression()`.
*/
export async function arrayPromiseFromAsyncGeneratorFn<Item>(
  generatorFn: () => AsyncIterable<Item>
): () => Promise<Array<Item>> {
  return await iterableUtils.asyncIterableToArrayPromise(generatorFn());
}
