import React from 'react';
import { isFeatureEnabled } from '../../../../config/feature-control';
import classNames from 'classnames';

interface EuaLabelProps {
  index: number;
  voyage: IVoyage;
  openPosition: any;
}

const EuaLabel = ({ index, voyage, openPosition }: EuaLabelProps) => {
  const voyageLegs = voyage.legs;
  const previousLeg = voyageLegs[index - 1];
  const previousIsInEea = previousLeg ? previousLeg.isInEea : openPosition.prevPortOfCallIsInEea;
  const currentIsInEea = voyageLegs[index].isInEea;

  const isWetCargoLitePortsOfCall = isFeatureEnabled('wetCargoLitePortsOfCall');

  const renderValue = () => {
    if (isWetCargoLitePortsOfCall) {
      return (
        <>
          <div>Leg {index + 1}</div>
          <div>{(previousIsInEea ? 50 : 0) + (currentIsInEea ? 50 : 0)}% EUA</div>
        </>
      );
    }

    return (
      <>
        <div>
          Leg {index + 1} {(previousIsInEea ? 50 : 0) + (currentIsInEea ? 50 : 0)}% EUA
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames({
        'eua-label-right': !isWetCargoLitePortsOfCall,
        'eua-label-left': isWetCargoLitePortsOfCall,
      })}
    >
      {renderValue()}
    </div>
  );
};

export default EuaLabel;
