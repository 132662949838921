import {
  PRE_DEFINED_EXPENSE_COST_UPDATED,
  PRE_DEFINED_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_DESCRIPTION_UPDATED,
  OTHER_EXPENSE_COST_UPDATED,
  OTHER_EXPENSE_COST_TYPE_UPDATED,
  OTHER_EXPENSE_ADDED,
  OTHER_EXPENSE_REMOVED,
} from 'constants/action-types/worksheet/additional-expenses';

export type PreDefinedExpenseCostChanged = (
  payload: number,
  index: number
) => {
  type: string,
  payload: {
    description: number,
    index: number,
  },
};

export type PreDefinedExpenseCostTypeChanged = (
  payload: number,
  index: number
) => {
  type: string,
  payload: {
    description: number,
    index: number,
  },
};

export type OtherExpenseDescriptionChanged = (
  payload: string,
  index: number
) => {
  type: string,
  payload: {
    description: string,
    index: number,
  },
};

export type OtherExpenseCostChanged = (
  payload: number,
  index: number
) => {
  type: string,
  payload: {
    description: number,
    index: number,
  },
};

export type OtherExpenseCostTypeChanged = (
  payload: number,
  index: number
) => {
  type: string,
  payload: {
    description: number,
    index: number,
  },
};

export type OtherExpenseAdded = () => {
  type: string,
};

export type OtherExpenseRemoved = (index: number) => {
  type: string,
  payload: number,
};

export function preDefinedExpenseCostChanged(payload, index, worksheetId) {
  return {
    worksheetId,
    type: PRE_DEFINED_EXPENSE_COST_UPDATED,
    payload: {
      cost: payload,
      index: index,
    },
  };
}

export function preDefinedExpenseCostTypeChanged(payload, index, worksheetId) {
  return {
    worksheetId,
    type: PRE_DEFINED_EXPENSE_COST_TYPE_UPDATED,
    payload: {
      costType: payload.key,
      index: index,
    },
  };
}

export function otherExpenseDescriptionChanged(payload, index, worksheetId) {
  return {
    worksheetId,
    type: OTHER_EXPENSE_DESCRIPTION_UPDATED,
    payload: {
      description: payload,
      index: index,
    },
  };
}

export function otherExpenseCostChanged(payload, index, worksheetId) {
  return {
    worksheetId,
    type: OTHER_EXPENSE_COST_UPDATED,
    payload: {
      cost: payload,
      index: index,
    },
  };
}

export function otherExpenseCostTypeChanged(payload, index, worksheetId) {
  return {
    worksheetId,
    type: OTHER_EXPENSE_COST_TYPE_UPDATED,
    payload: {
      costType: payload.key,
      index: index,
    },
  };
}

export function otherExpenseAdded(worksheetId) {
  return {
    worksheetId,
    type: OTHER_EXPENSE_ADDED,
  };
}

export function otherExpenseRemoved(index, worksheetId) {
  return {
    worksheetId,
    type: OTHER_EXPENSE_REMOVED,
    payload: index,
  };
}
