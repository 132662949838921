import React from 'react';
import DropDown, { Props as BaseDropDownProps } from 'components/dropdown';
import classNames from 'classnames';

type Props = {
  /// class name to be combined with 'dropdown-with-label'
  className: String,
  /// label header text that is displayed above the dropdown
  label: string,
} & BaseDropDownProps;

export const DropDownWithLabel = ({ className, label, children, ...rest }): Props => {
  return (
    <div className={classNames('dropdown-with-label', className)}>
      <label>{label}</label>
      <DropDown {...rest} />
      {children}
    </div>
  );
};
