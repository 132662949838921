import isNil from 'lodash/isNil';

export type SwitchCase<SwitchValue: mixed, ResultType: mixed> = (
  switchValue: SwitchValue
) => ResultType;

/** A version of `switch` statement that can be used in expressions, allowing to produce a more concise and declarative code
 * _(Essentially an equivalent of C# 8.0's [switch expressions](https://docs.microsoft.com/en-us/dotnet/csharp/whats-new/csharp-8#switch-expressions))_
 */
export default function switchExpr<SwitchValue: mixed, ResultType: mixed>(
  switchValue: SwitchValue,
  cases: { [SwitchValue]: SwitchCase<SwitchValue, ResultType> },
  defaultCase?: SwitchCase<SwitchValue, ResultType>
) {
  if (isNil(switchValue)) throw new TypeError('The `cases` argument must be non-nil');
  if (isNil(cases[switchValue])) {
    if (defaultCase) return defaultCase(switchValue);
    else throw new TypeError(`No case provided for  \`switchValue\` of ${switchValue}`);
  }

  return cases[switchValue](switchValue);
}
