import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from '../../components/headers';
import { bindActionCreators } from 'redux';
import './styles.scss';
import { TankerRatesEnum } from 'constants/enums/tanker-rates';
import { selector } from './selector';
import {
  rateTypeChanged,
  flatRateChanged,
  worldscaleChanged,
  grossVoyageRateChanged,
  lumpSumChanged,
} from './../../actions/worksheet/tanker-rates/index';
import { DropDownWithLabel } from './../../components/dropdown-with-label/index';
import { grossVoyageRateMaxPrecision } from 'modules/cargoes/cargo/cargo.js';
import {
  WorldScale,
  DollarsPerMetricTonne,
  LumpSum,
} from './../../constants/enums/tanker-rates/index';
import CalcInput from '../../components/calc-input';
type Props = {
  worksheetId: WorksheetId,
  cargoes: {
    id: string,
    cargoRate: {
      rateType: Number,
      flatRate: Number,
      worldscale: Number,
      grossVoyageRate: Number,
      lumpSum: Number,
    },
  }[],
  rateTypeChanged: Function,
  flatRateChanged: Function,
  worldscaleChanged: Function,
  lumpSumChanged: Function,
  grossVoyageRateChanged: Function,
};

export class TankerRates extends Component<Props> {
  onFlatRateUpdated = (newFlatRate) => {
    this.props.flatRateChanged(newFlatRate, this.props.worksheetId);
  };
  onRateTypeChanged = (newRateType) => {
    this.props.rateTypeChanged(newRateType, this.props.worksheetId);
  };
  onWorldScaleUpdated = (value) => {
    this.props.worldscaleChanged(value, this.props.worksheetId);
  };
  onLumpSumUpdated = (value) => {
    this.props.lumpSumChanged(value, this.props.worksheetId);
  };
  onGrossVoyageRateUpdated = (value) => {
    this.props.grossVoyageRateChanged(value, this.props.worksheetId);
  };
  render() {
    let item = this.props.cargoes[0].cargoRate;
    return (
      <div className="tanker-rates">
        <Header text="Rate" />
        <div className="tanker-rates__section">
          <div>
            <DropDownWithLabel
              className="tanker-rates__section-field"
              label="Type"
              items={TankerRatesEnum}
              selectedItemKey={item.rateType}
              onChange={this.onRateTypeChanged}
              diagnosticId="Tanker/RateType"
            />
          </div>
          {item.rateType === DollarsPerMetricTonne.key && (
            <div className="worksheet-section__property">
              <label className="worksheet-section__property-label">Gross Voyage Rate</label>
              <CalcInput
                userSpecifiedValue={item.grossVoyageRate}
                className="tanker-rates__gvr-fields"
                diagnosticId="Tanker/GVR"
                onInputChange={this.onGrossVoyageRateUpdated}
                maxDecimalDigits={grossVoyageRateMaxPrecision}
                trimDecimal={false}
                minValue="0"
                maxValue="999.99"
                isEditable
                unit={'$/MT'}
                name={'Gross Voyage Rate'}
                isMandatory
              />
            </div>
          )}
          {item.rateType === WorldScale.key && (
            <div className="worksheet-section__property">
              <label className="worksheet-section__property-label">Flat Rate</label>
              <CalcInput
                userSpecifiedValue={item.flatRate}
                className="tanker-rates__flat-rate-fields"
                diagnosticId="Tanker/Flat Rate(%)"
                onInputChange={this.onFlatRateUpdated}
                maxDecimalDigits={2}
                trimDecimal={false}
                minValue="0"
                maxValue="999999999.99"
                isEditable
                unit={'$/MT'}
                name={'Flat Rate'}
                isMandatory
              />
            </div>
          )}
          {item.rateType === WorldScale.key && (
            <div className="worksheet-section__property">
              <label className="worksheet-section__property-label">Worldscale</label>
              <CalcInput
                userSpecifiedValue={item.worldscale}
                className="tanker-rates__worldscale-fields"
                maxDecimalDigits={2}
                minValue="0"
                onInputChange={this.onWorldScaleUpdated}
                isEditable
                unit="%"
                name="Worldscale"
                isMandatory
              />
            </div>
          )}
          {item.rateType === LumpSum.key && (
            <div className="worksheet-section__property">
              <label className="worksheet-section__property-label">L/S</label>
              <CalcInput
                userSpecifiedValue={item.lumpSum}
                className="tanker-rates__ls-fields"
                maxDecimalDigits={2}
                minValue="0"
                trimDecimal={false}
                onInputChange={this.onLumpSumUpdated}
                isEditable
                unit="$"
                unitPrecedesValue
                name="L/S"
                isMandatory
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      rateTypeChanged,
      flatRateChanged,
      worldscaleChanged,
      lumpSumChanged,
      grossVoyageRateChanged,
    },
    dispatch
  );
};

export const TankerRatesContainer = connect(mapStateToProps, mapDispatchToProps)(TankerRates);
