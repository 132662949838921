import { getCarbonFactor } from '../../api/clients/carbon';
import { LOAD_CARBONFACTOR, SHOW_CARBON_FACTOR_TABLE_MODAL } from 'constants/action-types/carbon';
import { buildAction } from 'utilities/redux';
import { trackEvent } from '../../diagnostics/calc-trackevents';

export const loadCarbonFactor = () => async (dispatch) => {
  const carbonFacors = await getCarbonFactor();
  dispatch(buildAction(LOAD_CARBONFACTOR, carbonFacors));
};
export const showCarbonFactorTableModal = (value = true, area: string = 'CarbonBreakdown') => {
  if (value) {
    trackEvent(`${area}/carbon-factors`, 'Carbon Factors Displayed', {
      eventSource: `${area}/carbon-factors`,
    });
  }
  return {
    payload: value,
    type: SHOW_CARBON_FACTOR_TABLE_MODAL,
  };
};
