import React from 'react';
import './styles.scss';
import Size from './size';

type Props = { size: Size };

const ProgressBar = ({ size }: Props) => {
  let classNames = ['striped-loader'];
  if (size) {
    classNames.push(`striped-loader--${size.key}`);
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
    </div>
  );
};

export default ProgressBar;

export { Size };
