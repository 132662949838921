import {
  isDischargePort,
  isLoadOrDischargePort,
  isLoadPort,
} from '../../constants/enums/voyage-leg';

export const getPortsTotalLoadAndDischarge = (ports) => {
  let totalLoad = 0;
  let totalDischarge = 0;

  if (!ports) {
    return { totalLoad, totalDischarge };
  }
  for (const port of ports) {
    if (isLoadPort(port.type)) {
      totalLoad += port.cargoQuantity;
      continue;
    }
    if (isDischargePort(port.type)) {
      totalDischarge += port.cargoQuantity;
      continue;
    }
  }
  return { totalLoad, totalDischarge };
};

export const getEuaLabel = (index, voyageLegs, prevPortOfCallIsInEea, nextPortOfCallIsInEea) => {
  let firstPrevPortOfCall = null;
  for (let i = index - 1; i >= 0; i--) {
    const port = voyageLegs[i];

    if (isLoadOrDischargePort(port.type)) {
      firstPrevPortOfCall = port;
      break;
    }
  }

  let firstNextPortOfCall = null;
  for (let i = index; i < voyageLegs.length; i++) {
    const port = voyageLegs[i];

    if (isLoadOrDischargePort(port.type)) {
      firstNextPortOfCall = port;
      break;
    }
  }

  const prevPortOfCall = firstPrevPortOfCall ? firstPrevPortOfCall?.isInEea : prevPortOfCallIsInEea;

  const nextPortOfCall = firstNextPortOfCall ? firstNextPortOfCall?.isInEea : nextPortOfCallIsInEea;

  return `${(prevPortOfCall ? 50 : 0) + (nextPortOfCall ? 50 : 0)}%`;
};

export const getIndexOfLastLoadOrDischargePort = (ports) => {
  if (!ports) {
    return -1;
  }
  let targetIndex = -1;
  for (let i = 0; i < ports.length; i++) {
    if (isLoadOrDischargePort(ports[i].type)) {
      targetIndex = i;
    }
  }
  return targetIndex;
};
