import forIn from 'lodash/forIn';
import {
  CANAL_BALLAST_COST_CHANGED,
  CANAL_LADEN_COST_CHANGED,
  CANAL_BALLAST_WAITING_TIME_CHANGED,
  CANAL_LADEN_WAITING_TIME_CHANGED,
  CANAL_BALLAST_TRANSIT_TIME_CHANGED,
  CANAL_LADEN_TRANSIT_TIME_CHANGED,
} from 'constants/action-types/worksheet/canals';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import { canals as canalsReferenceData } from 'constants/data/waypoints';
import guid from 'utilities/guid';

const initialState = {};
forIn(
  canalsReferenceData,
  (canal, canalId) =>
    (initialState[guid()] = {
      canalId,
      name: canal.name,
      ballastCost: 0,
      ladenCost: 0,
      ballastWaitingTime: 0,
      ladenWaitingTime: 0,
      ballastTransitTime: 0,
      ladenTransitTime: 0,
      operationalRestrictionIds: new Set(),
    })
);

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return getCanalsViewModelFromWorksheetDto(action.payload);
    case CANAL_BALLAST_COST_CHANGED:
      const ballastCostState = { ...state };
      ballastCostState[action.id].ballastCost = action.payload;
      return ballastCostState;
    case CANAL_LADEN_COST_CHANGED:
      const ladenCostState = { ...state };
      ladenCostState[action.id].ladenCost = action.payload;
      return ladenCostState;
    case CANAL_BALLAST_WAITING_TIME_CHANGED:
      const ballastWaitingState = { ...state };
      ballastWaitingState[action.id].ballastWaitingTime = action.payload;
      return ballastWaitingState;
    case CANAL_LADEN_WAITING_TIME_CHANGED:
      const ladenWaitingState = { ...state };
      ladenWaitingState[action.id].ladenWaitingTime = action.payload;
      return ladenWaitingState;
    case CANAL_BALLAST_TRANSIT_TIME_CHANGED:
      const ballastTransitState = { ...state };
      ballastTransitState[action.id].ballastTransitTime = action.payload;
      return ballastTransitState;
    case CANAL_LADEN_TRANSIT_TIME_CHANGED:
      const ladenTransitState = { ...state };
      ladenTransitState[action.id].ladenTransitTime = action.payload;
      return ladenTransitState;
    default:
      return state;
  }
};

export const getCanalsViewModelFromWorksheetDto = ({ canals }) => {
  if (canals) {
    return canals.reduce((obj, canal) => {
      const operationalRestrictionIds = new Set(canal.operationalRestrictionIds || []);
      obj[canal.id] = {
        operationalRestrictionIds,
        canalId: canal.canalId,
        name: canalsReferenceData[canal.canalId].name,
        ballastCost: canal.ballastCost,
        ladenCost: canal.ladenCost,
        ballastWaitingTime: canal.ballastWaitingTime,
        ladenWaitingTime: canal.ladenWaitingTime,
        ballastTransitTime: canal.ballastTransitTime,
        ladenTransitTime: canal.ladenTransitTime,
      };

      return obj;
    }, {});
  }

  return initialState;
};

/**
 * The function is used to transform the redux object into the
 * equivalent worksheet dto. This is the dto received from the API.
 * The reasoning is to slowly transform the redux store to store only the DTOs.
 * TODO: This should be removed once the redux store is storing the DTOs.
 */
export const transformToWorksheetCanals = ({ canals: canalsInRedux }) => {
  return Object.keys(canalsInRedux).forEach((id) => {
    const { name, ...rest } = canalsInRedux[id];

    return {
      id,
      ...rest,
    };
  });
};
