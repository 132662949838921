import { singleOrThrow } from '../iterable';

// Sets respective route variant for multi vessel and its various open Locations
export const getInboundRouteVariant = (voyageEntry, activeVessel) => {
  const { variants } = voyageEntry.inboundRoute;
  const openPositionLatitude = activeVessel?.openPosition?.latitude;
  const openPositionLongitude = activeVessel?.openPosition?.longitude;

  if (variants.length === 1) {
    return singleOrThrow(variants);
  }

  const validVariants = variants.filter(
    (variant) =>
      variant.fromLocationGeoCoords &&
      variant.fromLocationGeoCoords.latitude !== null &&
      variant.fromLocationGeoCoords.longitude !== null
  );

  const inboundRouteVariant = validVariants.find(
    (variant) =>
      variant.fromLocationGeoCoords.latitude === openPositionLatitude &&
      variant.fromLocationGeoCoords.longitude === openPositionLongitude
  ) || { totalDistance: 0, secaDistance: 0 };

  return inboundRouteVariant;
};
