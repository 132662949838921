export type UserInfo = {
  systemUserId: number,
  /**
   * `true` means employee working for the same capital group as Sea/
   * `false` means a 3rd party who purchased a license to use the app.
   */
  isInHouseCompany: boolean,
};

class UserContext {
  get systemUserId() {
    return this.userInfo.systemUserId;
  }

  get userInfo(): UserInfo {
    if (!this._userInfo)
      throw new Error(
        `\`userInfo\` accessed before it is set. This should never happen. If
        you added new bootstrap code that requires user information, make sure
        you place it in the right order in respect to populating userInfo. If
        you are testing the code, make sure you set or mock the user
        information.`
      );
    return this._userInfo;
  }

  set userInfo(newValue: UserInfo) {
    this._userInfo = newValue;
  }
  _userInfo: UserInfo = undefined;
}

export default new UserContext();
