import { veApi } from 'api';

export interface ComparisonTableStatesResult {
  columnId: string;
  state: boolean;
}
export async function getComparisonTableState(options: {
  cancelToken: CancelToken,
}): ComparisonTableStatesResult[] {
  try {
    const response = await veApi.get(`/userPreference/comparison-table-state`, {
      cancelToken: options && options.cancelToken,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function updateComparisonTableState(
  comparisonTableStateDetails,
  options: {
    cancelToken: CancelToken,
  }
): ComparisonTableStatesResult {
  try {
    const response = await veApi.post(
      `/userPreference/comparison-table-state`,
      comparisonTableStateDetails,
      {
        cancelToken: options && options.cancelToken,
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
}
