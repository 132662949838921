import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Label from 'components/label';
import ProgressBar, { Size } from 'components/progress-bar';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import WORKBOOK_STATE from '../../workbook-states';
import ErrorMessages from './error-messages';

const WorkbookItemPlaceholder = (props) => {
  const classes = classNames('search-list__new', props.className);

  return (
    <div tabIndex="-1" className={classes}>
      {props.children}
    </div>
  );
};

export default class NewWorkbookItem extends Component {
  handleFinalNameChosen = (finalChosenName) => {
    var trimmedName = finalChosenName.trim();
    const newWorkbookName = isEmpty(trimmedName) ? this.props.originalName : trimmedName;

    this.props.confirmWorkbookRename(newWorkbookName);
  };

  showProgressBar = () => (
    <WorkbookItemPlaceholder>
      <ProgressBar size={Size.MEDIUM} />
    </WorkbookItemPlaceholder>
  );

  showErrorMessage = (createWorkbookState) => {
    const errorMessage = ErrorMessages[createWorkbookState];

    return (
      <WorkbookItemPlaceholder className="search-list__new--error-message">
        <Label>{errorMessage}</Label>
        <IconButton
          icon={iconEnum.Cancel}
          onClick={this.props.dismissError}
          diagnosticId="NewWorkbookItem/DismissError"
        />
      </WorkbookItemPlaceholder>
    );
  };

  showRenameInput = () => (
    <WorkbookItemPlaceholder>
      <NameChoiceInput
        autoFocus
        onFinalNameChosen={this.handleFinalNameChosen}
        initialName={this.props.originalName}
        className="search-list__new--name"
      />
    </WorkbookItemPlaceholder>
  );

  render() {
    const { createWorkbookState } = this.props;

    switch (createWorkbookState) {
      case WORKBOOK_STATE.PENDING:
        return this.showProgressBar();
      case WORKBOOK_STATE.ERROR_CREATING:
        return this.showErrorMessage(WORKBOOK_STATE.ERROR_CREATING);
      case WORKBOOK_STATE.ERROR_RENAMING:
        return this.showErrorMessage(WORKBOOK_STATE.ERROR_RENAMING);
      case WORKBOOK_STATE.READY_TO_RENAME:
        return this.showRenameInput();
      default:
        return null;
    }
  }
}

class NameChoiceInput extends React.Component<{
  initialName: string,
  onFinalNameChosen: (finalName) => void,
  autoFocus: boolean,
  className: string,
}> {
  constructor(props) {
    super(props);
    this.state = {
      currentInputValue: props.initialName,
    };
  }

  handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      this.props.onFinalNameChosen(this.state.currentInputValue);
    } else if (e.key === 'Escape') {
      this.props.onFinalNameChosen(this.props.initialName);
    }
  };

  handleBlur = () => {
    this.props.onFinalNameChosen(this.state.currentInputValue);
  };

  handleInputChange = (e) => {
    this.setState({ currentInputValue: e.target.value });
  };

  render() {
    return (
      <input
        autoFocus={this.props.autoFocus}
        className={this.props.className}
        onKeyUp={this.handleKeyUp}
        onBlur={this.handleBlur}
        onChange={this.handleInputChange}
        value={this.state.currentInputValue}
      />
    );
  }
}
