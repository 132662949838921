import React from 'react';
import * as RedirectManager from 'utilities/redirect-manager';

export const UpdatedDateRenderer = (props) => {
  const workbook = props.data;
  const url = `workbook/${workbook.id}`;
  const formattedDate = props.valueFormatted;
  const storeURLInLocalStorage = () => {
    RedirectManager.storeURL(url);
  };
  return (
    <a
      tabIndex="0"
      href={'#/' + url}
      className="workbook-card__cell workbook-card__cell--updated-date"
      onMouseDown={storeURLInLocalStorage}
    >
      <time dateTime={props.value}>{formattedDate}</time>
    </a>
  );
};
