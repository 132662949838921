import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { selector } from './selector';
import './styles.scss';

export class AdditionalIncomeSummary extends Component {
  renderDataCell = (children, rightAlign, calculatedCell) => {
    const cellClass = 'summary-income-data__row-cell';
    return (
      <div
        className={classNames(cellClass, {
          'summary-income-data__row-cell--right': rightAlign,
          'summary-income-data__row-cell--calculated': calculatedCell,
        })}
      >
        {children}
      </div>
    );
  };

  renderFooterRow = () => {
    return (
      <div className="summary-income-data__footer">
        {this.renderFooterRowCell('Total Net Freight')}
        {this.renderFooterRowCell(this.props.income.totalIncomeNet, true)}
        {this.renderFooterRowCell('')}
        {this.renderFooterRowCell('')}
        {this.renderFooterRowCell('')}
      </div>
    );
  };

  renderTable = () => {
    let dataRow = (
      <>
        <div className="summary-income-data__row">
          {this.renderDataCell('Freight')}
          {this.renderDataCell(this.props.income.freightIncomeGross, true)}
          {this.renderDataCell('')}
          {this.renderDataCell('')}
          {this.renderDataCell('')}
        </div>
        <div className="summary-income-data__row">
          {this.renderDataCell('Additional Freight')}
          {this.renderDataCell(this.props.income.additionalIncomeGross, true)}
          {this.renderDataCell('')}
          {this.renderDataCell('')}
          {this.renderDataCell('')}
        </div>
        <div className="summary-income-data__row">
          {this.renderDataCell('Total Freight')}
          {this.renderDataCell(this.props.income.totalIncomeGross, true, true)}
          {this.renderDataCell('')}
          {this.renderDataCell('')}
          {this.renderDataCell('')}
        </div>
        {this.renderFooterRow()}
      </>
    );

    return dataRow;
  };

  renderFooterRowCell = (content, rightAlign) => {
    const classes = classNames('summary-income-data__footer-cell', {
      'summary-income-data__footer-cell--right': rightAlign,
    });

    return <div className={classes}>{content}</div>;
  };

  render() {
    return (
      <div className="summary-income-section">
        <h2>Freight Income ($)</h2>
        <div className="summary-income-data">{this.renderTable()}</div>
      </div>
    );
  }
}

const mapStateToProps = selector;

export default connect(mapStateToProps)(AdditionalIncomeSummary);
