import React, { useEffect } from 'react';
import { LightbulbIcon } from 'components/icons';
import ViewWorkbooksButton from '../view-workbooks-button';
import { trackPageView, eventDestination } from 'diagnostics/calc-trackevents';
import { Helmet } from 'react-helmet';

import './../styles.scss';
import { useTestId } from 'components/testable';

export function WorkbookNotFoundPlaceholder() {
  const testId = useTestId('workbook-not-found');
  useEffect(
    function trackingEffect() {
      trackPageView('Workbook Not Found', undefined, {}, {}, undefined, eventDestination.ANALYSIS);
    },
    [] // No dependencies - fire only once - on mount
  );

  return (
    <div className="no-document-placeholder" data-testid={testId}>
      <LightbulbIcon aria-label="Information" className="no-document-placeholder__type-icon" />
      <Helmet>
        <title>Sea/calc - Workbook Does Not Exist</title>
      </Helmet>
      <section className="no-document-placeholder__user-message">
        <header data-testid={testId && `${testId}__header`}>The workbook doesn't exist</header>
        <p>
          Please select "VIEW WORKBOOKS" to select another workbook as this workbook doesn't exist
        </p>
        <footer>
          <ViewWorkbooksButton diagnosticId="workbook-not-found-placeholder/view-workbooks-button" />
        </footer>
      </section>
    </div>
  );
}

export default WorkbookNotFoundPlaceholder;
