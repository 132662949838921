import React from 'react';
import { SplashScreen } from 'modules/splash-screen';
import ProgressBar, { Size } from 'components/progress-bar';

import './styles.scss';

export const AppInitialisingSplashScreen = () => (
  <SplashScreen>
    <div className="splash-screen__message">Please wait while we initialise</div>
    <ProgressBar size={Size.XLARGE} />
  </SplashScreen>
);
