import { getVoyageLegTypeById } from 'constants/enums/voyage-leg';
import { getGearTypeById } from './gear-types';
import { getSalinityTypeById } from 'constants/enums/salinity-types';
import { getLoadDischargeRateUnitTypeById } from './load-discharge-rate-unit-types';
import { getTimeUnitEnumById } from 'constants/enums/time';
import { getDraftUnitTypeById } from 'constants/enums/draft-units';
import { createSelector } from 'reselect';
import { singleOrThrow } from 'utilities/iterable';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';
import { getActiveVessel } from '../../common-selectors/get-active-vessel';
import round from 'lodash/round';
import { formatNumber } from 'utilities/number';
import { LOAD, DISCHARGE } from 'constants/enums/voyage-leg';
import { getActiveCalculationResult } from '../../common-selectors/get-active-calculation-result';
import { CalculationStatus } from 'constants/enums/calculation-status';

interface IVoyagePhaseWithRouteGraphVersion {
  locationName: string;
  type: string;
  legGroupIndex: number;
  routeRetrievedWithGraphVersion: number;
  routeRetrievedFromRoutingApiOn: Date;
}

export const selector = createSelector(
  getActiveWorksheetId,
  (state) => getActiveWorksheet(state).vessels,
  (state) => getActiveWorksheet(state).voyage.draftUnit,
  (state) => getActiveWorksheet(state).voyage.avoidSecaZones,
  (state) => getActiveWorksheet(state).voyage.shouldAutoCalculateIntake,
  (state) => getActiveWorksheet(state).voyage.legs,
  (state) => getActiveWorksheet(state).cargoes,
  getActiveWorksheetInvariantProps,
  (state) =>
    state.workbooksById &&
    state.workbooksById[state.activeWorkbookId] &&
    state.workbooksById[state.activeWorkbookId].isEditable,
  (state) => getActiveVessel(state),
  (state) => state.worksheetsById[state.activeWorksheetId].bunkerExpenses,
  (state) => getActiveWorksheet(state).voyage,
  (state) => state.appMetadata.routeGraphVersion,
  (state) => getActiveCalculationResult(state),
  (
    worksheetId,
    vessels,
    draftUnit,
    avoidSecaZones,
    shouldAutoCalculateIntake,
    legs,
    cargoes,
    worksheetInvariantProps,
    isEditable,
    activeVessel,
    bunkerExpenses,
    voyage,
    routeGraphVersion,
    calculationResult
  ) => {
    const cargoCodes = getCargoCodesFromCargoes(cargoes);
    const voyageLegRouteGraphVersionCollection = updateVoyageLegRouteGraphVersion(
      legs,
      routeGraphVersion
    );

    let maxLiftCalcResultStatus = calculationResult
      ? calculationResult.calculationStatus !== CalculationStatus.LOADING
      : false;

    let calculatedVoyageLegMaxLiftQtyCollection;

    if (maxLiftCalcResultStatus && calculationResult.additionalResults) {
      if (calculationResult.additionalResults.maxLiftVoyagePhases) {
        var maxLiftVoyagePhases = calculationResult.additionalResults.maxLiftVoyagePhases.map(
          (phase) => {
            return {
              legGroupIndex: phase.legGroupIndex,
              locationName: phase.locationName,
              maxDraftMeters: phase.maxDraftMeters,
              maxLiftQty: phase.maxLiftQty ? formatNumber(round(phase.maxLiftQty), 0) : 0,
              salinityFactor: phase.salinityFactor,
              type: phase.type,
            };
          }
        );
        calculatedVoyageLegMaxLiftQtyCollection = maxLiftVoyagePhases;
      }
    }

    const formatedLeg = {
      worksheetId,
      activeVessel,
      vessels,
      voyage: {
        draftUnit: getDraftUnitTypeById(draftUnit),
        avoidSecaZones: avoidSecaZones,
        shouldAutoCalculateIntake: shouldAutoCalculateIntake,
        legs: legs.map((leg, index) => ({
          ...leg,
          loadDischargeConsumptionsOverrideQty: leg.loadDischargeConsumptionsOverrideQty,
          type: getVoyageLegTypeById(leg.type),
          gear: getGearTypeById(leg.gear),
          salinity: getSalinityTypeById(leg.salinity),
          cargoCode: singleOrThrow(cargoCodes),
          rateUnit: getLoadDischargeRateUnitTypeById(leg.rateUnit),
          delayUnit: getTimeUnitEnumById(leg.delayUnit),
          turnAroundTimeUnit: getTimeUnitEnumById(leg.turnAroundTimeUnit),
          maxLiftQty: getMaxLiftFromCollection(
            shouldAutoCalculateIntake,
            index,
            leg.type,
            activeVessel,
            calculatedVoyageLegMaxLiftQtyCollection
          ),
          inboundRoute: updateInboundRoute(
            leg.name,
            leg.inboundRoute,
            voyageLegRouteGraphVersionCollection[index]
          ),
        })),
      },
      cargoCodes,
      worksheetInvariantProps,
      isEditable,
      routeGraphVersion,
      calculationStatus: calculationResult
        ? calculationResult.calculationStatus
        : CalculationStatus.LOADING,
      calculationResult,
    };
    return formatedLeg;
  }
);

const getMaxLiftFromCollection = (
  shouldAutoCalculateIntake,
  index,
  legType,
  activeVessel,
  calculatedVoyageLegMaxLiftQtyCollection
) => {
  if (shouldAutoCalculateIntake && legType === LOAD.key && activeVessel) {
    if (calculatedVoyageLegMaxLiftQtyCollection && calculatedVoyageLegMaxLiftQtyCollection[index]) {
      return calculatedVoyageLegMaxLiftQtyCollection[index].maxLiftQty;
    } else {
      return 'na';
    }
  }
  return '';
};

const updateInboundRoute = (locationName, inboundRoute, voyageLegRouteGraphVersion) => {
  let updatedInboundRoute = { ...inboundRoute };

  updatedInboundRoute.variants.forEach((v) => {
    const shouldUpdate =
      !!locationName &&
      (v.routeRetrievedWithGraphVersion === null ||
        v.routeRetrievedWithGraphVersion === undefined ||
        !v.routeRetrievedFromRoutingApiOn) &&
      voyageLegRouteGraphVersion.routeRetrievedWithGraphVersion !== null &&
      voyageLegRouteGraphVersion.routeRetrievedWithGraphVersion !== undefined;

    const shouldSetNull = !locationName;
    if (shouldUpdate) {
      v.routeRetrievedFromRoutingApiOn = new Date();
      v.routeRetrievedWithGraphVersion = voyageLegRouteGraphVersion.routeRetrievedWithGraphVersion;
    } else if (shouldSetNull) {
      v.routeRetrievedFromRoutingApiOn = null;
      v.routeRetrievedWithGraphVersion = null;
    }
  });

  return updatedInboundRoute;
};

const updateVoyageLegRouteGraphVersion = (legs, routeGraphVersion) => {
  let voyagePhaseWithRouteGraphVersionCollection: IVoyagePhaseWithRouteGraphVersion = [];
  let legGroupIndex = 0;
  legs.forEach((leg, index) => {
    const shouldUpdateVerAndDate =
      routeGraphVersion !== -1 &&
      (leg.inboundRoute.variants[0].routeRetrievedWithGraphVersion === null ||
        leg.inboundRoute.variants[0].routeRetrievedWithGraphVersion === undefined) &&
      leg.inboundRoute.variants[0].routeRetrievedFromRoutingApiOn === null;
    const routeRetrievedWithGraphVersion = shouldUpdateVerAndDate
      ? routeGraphVersion
      : leg.inboundRoute.variants[0].routeRetrievedWithGraphVersion;
    const routeRetrievedFromRoutingApiOn = shouldUpdateVerAndDate
      ? new Date()
      : leg.inboundRoute.variants[0].routeRetrievedFromRoutingApiOn;
    const voyagePhaseWithRouteGraphVersion: IVoyagePhaseWithRouteGraphVersion = {
      locationName: leg.name,
      type: leg.type,
      legGroupIndex: legGroupIndex,
      routeRetrievedWithGraphVersion: routeRetrievedWithGraphVersion,
      routeRetrievedFromRoutingApiOn: routeRetrievedFromRoutingApiOn,
    };

    voyagePhaseWithRouteGraphVersionCollection.push(voyagePhaseWithRouteGraphVersion);

    if (leg.type === DISCHARGE.key) {
      const nextLoadPortIndex = legs.findIndex((l, inx) => l.type === LOAD.key && inx > index);

      const nextDischargePortIndex = legs.findIndex(
        (l, inx) => l.type === DISCHARGE.key && inx > index
      );

      if (nextDischargePortIndex > nextLoadPortIndex || nextDischargePortIndex === -1) {
        legGroupIndex++;
      }
    }
  });

  for (let grpInx = 0; grpInx <= legGroupIndex; grpInx++) {
    const vleg = voyagePhaseWithRouteGraphVersionCollection.filter(
      (l) =>
        l.legGroupIndex === grpInx &&
        l.routeRetrievedWithGraphVersion &&
        l.routeRetrievedWithGraphVersion > 0 &&
        l.routeRetrievedFromRoutingApiOn
    );

    if (vleg && vleg.length > 0) {
      voyagePhaseWithRouteGraphVersionCollection.forEach((rleg) => {
        if (rleg.legGroupIndex === grpInx) {
          rleg.routeRetrievedWithGraphVersion = vleg[0].routeRetrievedWithGraphVersion;
          rleg.routeRetrievedFromRoutingApiOn = vleg[0].routeRetrievedFromRoutingApiOn;
        }
      });
    }
  }

  return voyagePhaseWithRouteGraphVersionCollection;
};

const getCargoCodesFromCargoes = (cargoes) => {
  return cargoes.map((cargo) => {
    return { key: cargo.id, label: cargo.code };
  });
};

export default selector;
