import React, { Component } from 'react';
import classNames from 'classnames';
import guid from 'utilities/guid';

import './styles.scss';

const RadioGroupContext = React.createContext({ name: guid() });

export class RadioGroup extends Component {
  render() {
    const { name, onChange, selectedValue } = this.props;

    return (
      <RadioGroupContext.Provider value={{ name, selectedValue, onChange }}>
        {this.props.children}
      </RadioGroupContext.Provider>
    );
  }
}

export class RadioButton extends Component {
  render() {
    const { className, value } = this.props;
    const { name, selectedValue, onChange } = this.context;
    const id = `radio-button-${name}-${value}`;
    const classes = classNames('radio-button', className);
    const optionalProps = {};

    if (selectedValue !== undefined) {
      optionalProps.checked = value === selectedValue;
    }
    if (typeof onChange === 'function') {
      // @todo remove the binding into a method
      optionalProps.onChange = onChange.bind(null, value);
    }

    return (
      <div className={classes}>
        <input
          type="radio"
          id={id}
          name={name}
          className="radio-button__input"
          {...optionalProps}
        />
        <label htmlFor={id} className="radio-button__label">
          {value}
        </label>
        <div className="radio-button__check" />
      </div>
    );
  }
}

RadioButton.contextType = RadioGroupContext;
