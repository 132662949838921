import React from 'react';
import { FallbackError } from 'components/error-boundary';
import { SplashScreen } from 'modules/splash-screen';

import './styles.scss';

export function AppFallbackError(props) {
  const supportEmailOptions = {
    emailTitle: 'Sea/calc application error',
    emailBody: `Sea/calc application has encountered an error.`,
  };

  return (
    <SplashScreen>
      <FallbackError
        className="app-fallback-error"
        title="Sea/calc has encountered an error"
        supportEmailOptions={supportEmailOptions}
      >
        Sorry for the inconvenience. Please contact support if you require assistance.
      </FallbackError>
    </SplashScreen>
  );
}
