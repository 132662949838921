import round from 'lodash/round';
import { fromTpcToTpi, fromTpiToTpc } from 'utilities/units-converter';

export const TPC = { key: 1, label: 'TPC (MT/cm)' };
export const TPI = { key: 2, label: 'TPI (MT/i)' };

export const getImmersionUnitTypeById = (typeId) => {
  switch (typeId) {
    case TPC.key:
      return TPC;
    case TPI.key:
      return TPI;
    default:
      throw new Error(`Unable to parse invalid immersion unit type id ${typeId}`);
  }
};

const ImmersionUnitTypes = [TPC, TPI];

export const IMMERSION_NUMBER_OF_DECIMAL_PLACES = 2;

export const convertImmersion = (immersion, fromImmersionUnit, toImmersionUnit) => {
  return fromImmersionUnit === toImmersionUnit
    ? immersion
    : toImmersionUnit === TPC
      ? round(fromTpiToTpc(immersion), IMMERSION_NUMBER_OF_DECIMAL_PLACES)
      : round(fromTpcToTpi(immersion), IMMERSION_NUMBER_OF_DECIMAL_PLACES);
};

export default ImmersionUnitTypes;
