export default [
  /*China; Baoshan*/ '3731B7DD-C75C-48BB-9AC3-E8DD5316F883',
  /*China; Basuo*/ '49F0E9CD-191F-4B96-8535-719DDA2329F1',
  /*China; Bayuquan*/ '95F876F7-91F3-4717-947E-2D56AA4F17EC',
  /*China; Beihai*/ 'F78FA2FF-234F-47E6-BD99-F5E1075C900A',
  /*China; Beijiao*/ '0EE6A1B6-9139-4FE4-96EE-9C2F5EC9B8CD',
  /*China; Beilun*/ '0C220C5D-4064-41B5-92CB-16A7DF7C1E03',
  /*China; Bili*/ 'CC4ABD06-22C4-44FC-8C7B-FADBEE9F00B1',
  /*China; Bili*/ '6E82A6A7-748B-42A6-AA33-B809242F927B',
  /*China; Binhai*/ 'D8900198-5DF8-4AA2-976B-7AAF8897525B',
  /*China; Binzhou*/ 'C33FDB5C-19FB-44E3-ADB8-92F96BD4B3B7',
  /*China; Bohexin*/ 'C72B9668-FC36-4FF0-B82E-AFFC6FFD1997',
  /*China; Bohexin*/ '861BF8A6-06F7-4E9B-A5F8-5A159E8AE192',
  /*China; Caofeidian*/ '27A2AE05-DE55-499F-B88A-29498C3CC308',
  /*China; Caojing*/ 'DECBE99D-8358-4C32-A062-CD5A34237C2D',
  /*China; Changxingdao*/ '33A23BB6-F473-4FE0-BA13-5C16D1A2A8E9',
  /*China; Chaoyang*/ '1BF5A056-A4F8-4A05-9710-EE1EF66F61C0',
  /*China; Chaozhou*/ '93C7830F-1575-4802-99EE-CB2CCD8734AE',
  /*China; Chenjiagangzhen*/ 'EA7C0ABD-30D8-4D4A-B738-5230BD137BD6',
  /*China; Chiwan*/ '2B26AFA1-087C-4BF3-B943-B70F57E117D1',
  /*China; Chuansha*/ 'C71B2E15-6824-4022-A2C9-CD94066BA748',
  /*China; Da Chan Bay*/ 'BCF97019-204F-410A-8154-1156F21DCE37',
  /*China; Dafeng*/ 'B7E3A214-752D-4F37-B988-F73B41E576E0',
  /*China; Dagu Tanggu*/ 'C79D2D97-36AD-4F03-AF2A-1815F3D2387A',
  /*China; Daishan*/ '6DAD222C-643D-4C10-81F7-8D1D2AE11D71',
  /*China; Dalian*/ 'EDF828ED-C336-4983-B4C5-641923403D46',
  /*China; Damen*/ '60EE076F-AB08-462C-BC8D-C4096F3536B9',
  /*China; Dandong*/ '9AACF048-1968-43C5-944D-240CE30EB9BF',
  /*China; Dantou*/ 'F432B899-AFB4-4CB6-B4BB-9098245C8ACC',
  /*China; Dantou*/ '17B21ED8-9113-4530-8184-E76F93B6C3E6',
  /*China; Dapeng*/ 'A6ED3BAE-55D5-46EC-9096-F66BA1FD4EC7',
  /*China; Dawuwan*/ '5E0BA8AC-638F-403C-A1F8-C4B7539B6282',
  /*China; Domen*/ 'EBA48FFE-6E7E-48DA-91B6-BE25265B40AA',
  /*China; Dongguan*/ '4749AA38-F369-4630-A5C4-3DEF6C1C71CA',
  /*China; Dongjiakou*/ 'C55510B1-BB8B-4E2A-9A92-192FACCA7A0E',
  /*China; Dongshan*/ '5E33F290-59A3-43DF-8F13-251AB4BD3EA8',
  /*China; Dongwu*/ '9D32BCCF-02D0-4CFC-B885-D5DF76031091',
  /*China; Dongying*/ 'A1F9FCA3-718E-40A5-B482-5B327B4206B2',
  /*China; Dongzao*/ '2A5FD37F-A79B-4152-A40F-31FA02DB2DDA',
  /*China; Dongzao*/ 'CC666134-800A-46AF-BD3C-8BBA0E76B290',
  /*China; Dushan*/ 'FA960567-A3C7-47D8-A71D-EBBD04DE06C1',
  /*China; Dushan*/ '77134A74-8A3F-4A41-8CB8-112FFA72A28D',
  /*China; Fangcheng*/ 'ED4CB10E-4C8B-436A-816C-E432E416E141',
  /*China; Funing*/ '564A879E-14FC-4251-A5CF-002EAB00BF35',
  /*China; Funing*/ '1184D4F3-DA38-4ECB-AE49-A38F0F2890C3',
  /*China; Fuzhou*/ 'DBCE5D25-32CF-4B18-AE7B-4711EF113C49',
  /*China; Ganyu*/ '4D7747CE-4F18-4924-8877-B9E49BD08429',
  /*China; Ganyu*/ '81D38E10-02C2-409F-819F-F95E4DA44697',
  /*China; Guangdong Terminal*/ 'A31F476E-847F-434A-923F-1D8B622F5B10',
  /*China; Guishan*/ '7EFBDE91-26B4-4839-8D83-10A227527BC3',
  /*China; Gulei*/ 'F4F6A985-E9C3-4ABB-B735-276457416195',
  /*China; Haian*/ 'E08F9A10-FB34-4726-819C-D6C2D3AE36CD',
  /*China; Haifeng*/ 'F4B5B5DB-008B-41E1-97EF-1FD72D035E74',
  /*China; Haikou*/ 'BD69CA4C-D0EA-4F85-8A62-8AE0A9294C85',
  /*China; Haimen*/ '56B48DBF-8788-45A6-9056-22F7245D1725',
  /*China; Haixinsha*/ '8873FC09-078B-433E-9E82-9F5DA37C9FE0',
  /*China; Haiyang*/ '19A11B9D-7BD5-45CC-AAB0-750AE0D19D94',
  /*China; Haiyang*/ 'FC5E7A8C-7AF3-464E-BF66-055C87729BA3',
  /*China; Hangzhou*/ '6A6BB26B-DB76-44C3-82D6-41298CA25D26',
  /*China; Hong Kong*/ '605E1E8A-170E-400F-B0EA-0C90BC71BA92',
  /*China; Hongwan*/ '760DDB0F-B0B5-4A7D-A7C8-5BEA122D9D81',
  /*China; Hongwan*/ '907AF556-1537-411C-A53F-DA49BBDE4A55',
  /*China; Houshi*/ 'CFDEA7AB-DEE7-44AC-9CFE-7A4C13BB8824',
  /*China; Huanghua*/ '21D372EB-6ADD-457D-BCC4-501489AE5CEF',
  /*China; Huangpu*/ 'ECFD3557-08C9-4417-AA58-243A73A9539E',
  /*China; Huangyan*/ 'AE7409F4-5085-4F96-A0B1-A3821E1A0A44',
  /*China; Huizhou*/ 'A8FF23F2-48D4-4568-B782-59DC66FFB6AE',
  /*China; Huludao Gang*/ '44B3B6C9-0F68-4C7E-8DDD-A3DA10E3C819',
  /*China; Humen*/ 'A2AFE6AD-CEB2-422B-8F78-134EBF801304',
  /*China; Jiangjunmao*/ '8ED4CEBA-FDBC-4866-B435-DF8AA05E96F0',
  /*China; Jiangjunmao*/ '4CF79AC2-1A0A-42D1-BEE8-9345D02D98B5',
  /*China; Jiangmen*/ 'A33D58A8-0367-48E6-B95E-05C0C518E1A0',
  /*China; Jiangyin Fujian*/ '89F86893-C99F-4EC7-BB70-12022D1D6CD9',
  /*China; Jiaojiang*/ '629A97F4-7E5D-4D8E-A629-28A06E391D4F',
  /*China; Jiaxing*/ '3A9706DC-7C94-42E6-8EB8-A65382FE47F4',
  /*China; Jiaxing Power Station*/ '839A8969-8ACE-455C-A545-6FBC9FFD9B0C',
  /*China; Jida*/ '5A2073FF-54D9-4748-9D60-396EBE6B73F6',
  /*China; Jida*/ '865BECE5-ED71-4400-98D9-649A2FBC913B',
  /*China; Jing Tang*/ '04907204-3BC0-4B59-AB65-D63C27036294',
  /*China; Jinghai*/ '4D1D305A-3431-4AFF-BE74-CC7E1300B78D',
  /*China; Jinshan*/ 'FF8939D1-3A7B-4C88-A653-92E143C4F453',
  /*China; Jinzhou*/ 'EE395A13-598D-49E2-B507-15248FA643B9',
  /*China; Jiuzhou*/ 'CC72612F-2D95-438D-B5EA-97EEB3EA1334',
  /*China; Kemen*/ 'C85C569B-90C0-4860-AACB-E092F0E44CD5',
  /*China; Kemen*/ '7B02BB06-A465-487B-B123-6182CBB6B57E',
  /*China; Kinmen*/ '7BEDF9FC-C17B-4615-954D-90E49BE330F8',
  /*China; Laizhou*/ '99715624-6297-4272-8A1E-08BBA6032811',
  /*China; Lanbei*/ '1B4B028C-4144-493A-A151-10A1EF8406EE',
  /*China; Landbridge*/ '9E0B05B6-2810-4810-8622-AF2968C28E36',
  /*China; Lanshan*/ '71FDA710-4019-4741-8A36-0B14723FA397',
  /*China; Laowei*/ 'D4AC4E38-F4FE-4385-A0E9-4373ABD5F4CF',
  /*China; Lasawei*/ 'E6C82000-D358-49E8-987C-611EB914887D',
  /*China; Lianhuashan*/ '100A6693-238E-403F-8A21-A9A24852C3FE',
  /*China; Lianyungang*/ '1DB1B961-4466-4EB1-BB17-D7CAFDF219CB',
  /*China; Lidao*/ '59D78898-751F-4021-9A67-2877531DF5C7',
  /*China; Linhai*/ '31F70D4B-DDCB-4552-84A0-12BAD77BC4E1',
  /*China; Liuheng*/ '4C8F5A4C-0965-4359-8788-C7395FAA59CA',
  /*China; Longhai*/ 'E9BF2F7C-9CC8-4EFA-9E84-C8A638B0877B',
  /*China; Longkou*/ 'D3464590-A1AC-4F37-B0D5-83DD447F48B9',
  /*China; Longyan*/ 'FCAD74FD-169E-4044-95D9-77240671F44D',
  /*China; Longyang*/ '19BA8869-D5A7-47C4-A1E1-9618EB454F2A',
  /*China; Lufeng*/ '3C722B44-4AAF-4CBC-B8A0-C78290742B89',
  /*China; Luhuashan*/ 'C5F1484E-1FC5-479F-B2D3-5FD094510560',
  /*China; Luojing*/ 'A68546BD-D2CD-4B94-91AE-A3B1D8F5AFB5',
  /*China; Lushun*/ '1D8FFAB0-008A-4D90-9E2A-B9D1B7664B30',
  /*China; Lvsi*/ 'E8877938-EE28-4688-B727-22DFAF3790EC',
  /*China; Macau*/ '256C5B68-5CFF-49DC-828D-13DE1765DA1E',
  /*China; Macun*/ '201B341C-5F30-46BC-BC16-B8C9E91EB901',
  /*China; Maoming*/ 'A3170A2D-86E6-4077-AA68-3DEC36D49DE2',
  /*China; Matsu Island*/ '4547709C-446A-4092-9207-43C337EB3BE8',
  /*China; Mawan*/ '90EFBC29-7112-4B4B-A703-8B2EA7697B4B',
  /*China; Mazhou Island*/ '39B3070E-AE7E-4C4A-A225-26A3FEC0F443',
  /*China; Meishan*/ '40CF8490-851F-44E9-88D0-55EC20A17F68',
  /*China; Meishan*/ '1CC8EAAE-1540-4F73-B4FD-4F0E2FFB2F07',
  /*China; Meizhou*/ 'DF165894-C394-444C-BF59-C47A793B6D55',
  /*China; Nansha*/ '3597DAFD-477C-47EE-BBF1-30B0E011C238',
  /*China; Ningbo*/ 'FBD2BB38-3B41-4CE2-B9C8-FA123D5A4E26',
  /*China; Ningde*/ 'E7E487CA-9988-45AA-9263-E1AB2D16CAE3',
  /*China; Panjin*/ '7D1B80B2-8996-4051-B45C-54CFDE7F5E3D',
  /*China; Panyu*/ '80671709-4189-4347-BB31-CEF550DE81E0',
  /*China; Penglai*/ '381E2693-429A-4B4D-A0A2-67E1910B3B9D',
  /*China; Pinghu*/ '2DC4C204-0EE5-41C2-84E2-FAD0595C5510',
  /*China; Pingtan*/ '468C1221-8BBE-4695-8091-4EBA87960B89',
  /*China; Qidong*/ 'CAD7964D-76A1-45D3-8E80-EF8C59609098',
  /*China; Qingdao*/ '861AA4D3-7C98-4EC1-9BAB-910E8B237492',
  /*China; Qinglan*/ '1169F1FB-3D12-4B8E-9DA3-DC7E9AB52C44',
  /*China; Qinhuangdao*/ '6264655B-A58F-46A8-B54A-09EFC9C7C0D1',
  /*China; Qinzhou*/ '807C8B20-0611-42A4-AF17-55F3F1F842BB',
  /*China; Quanzhou*/ '06056CBB-21CC-4404-8F1D-69958F2A6F4B',
  /*China; Quanzhou Houzhu*/ 'FE303EE8-31D7-4C4A-9E86-E3EFBBA2D358',
  /*China; Quanzhou Shenhu*/ 'F1E4B64A-9CB7-484F-B88F-88BC3EB5206A',
  /*China; Rizhao*/ '8764EC3B-A544-481E-88A4-EBEADF61186B',
  /*China; Rongqi*/ '7E8EB87C-D1EE-4E7C-B07B-6C40DED41BA5',
  /*China; Ruian*/ '6692C34F-F939-46C7-90E6-388124C88551',
  /*China; Rushan*/ '192F7D62-A9C2-4489-9D89-E46973A7B49F',
  /*China; Saiqi*/ 'F047429A-FFDB-423B-ACDF-CF43476FF9F2',
  /*China; Sanbaimen*/ '106D3BC4-3FDA-4B8B-82DE-4D65BD2DA40A',
  /*China; Sanmen*/ '14A3FE5A-6129-4D5C-BC10-0933D4AC3A8C',
  /*China; Sanya*/ '807EBDC4-263A-449E-A2E7-853945C39E9B',
  /*China; Shanghai*/ '09B47D77-FA7D-4AE1-9ECA-0939779C35C2',
  /*China; Shanghai Nangang*/ 'A08822B1-1281-413D-8926-E84659496B73',
  /*China; Shanhaiguan*/ '37E2A904-83B2-442D-B479-E9B9C578AC2F',
  /*China; Shantou*/ '7150D9F5-2323-4FD5-9BE9-C41625A6F27D',
  /*China; Shanwei*/ 'F1051DEF-1D02-4364-95F8-6E2C129929A4',
  /*China; Shek Wu*/ '4FF99B60-96E9-46C4-A945-66CD7894B8BD',
  /*China; Shekou*/ '25C2A2C6-9C92-438E-94E3-21735CFB67F2',
  /*China; Shengsi*/ 'B7F51665-791B-41FA-88A0-1FF2517A2977',
  /*China; Sheyang*/ '8FF5D63B-53FC-4C6A-A8E0-FF26AD502FBB',
  /*China; Sheyang*/ 'F47E3497-569B-4991-BA40-17209E18B47A',
  /*China; Shidao*/ 'A279D687-EE4E-4899-83A9-F39F4C14E009',
  /*China; Shipu*/ 'D3C6823A-B867-41DA-8899-9432EF173B5C',
  /*China; Shiqiao*/ '3E4AEAEC-048D-4721-BD41-457C4726A8BB',
  /*China; Shisuo*/ '6AEDBE53-21C5-4853-9167-F2A9D7B07860',
  /*China; Shuidong*/ '3EA79F21-3650-4668-8F97-023438863782',
  /*China; Shunde*/ '3D306622-2B99-4339-8CBE-82B65666B1A7',
  /*China; Songjiang*/ '4CE629E7-6982-4E41-B0FD-C1D7BAA85AA6',
  /*China; Songmen*/ '84587C79-44C5-44EA-83DE-243D71334D31',
  /*China; Songxia*/ 'A514F81C-FC64-4F7C-BECB-871878EEFB4D',
  /*China; Suizhong*/ '0597666C-6711-46A1-8BDD-9AB85B638C9F',
  /*China; Taicang*/ 'A2904BC9-6C71-41DA-91CF-9C035017B426',
  /*China; Taiping*/ 'D3C9D7D7-3320-44D4-B835-81802DD410CC',
  /*China; Taishan*/ '4D005020-84C1-4316-9B2E-8DFA98C925EF',
  /*China; Tianjin*/ '9A3967B0-403A-4DB2-94B2-6FB346C7CECA',
  /*China; Tieshan*/ '2040D615-EB61-4828-BE5F-14DA4CC8556B',
  /*China; Toumen*/ '5A2B6483-689C-4FA0-8237-5DC87CD4DA6B',
  /*China; Wanning*/ '7CA74CFB-1359-44C4-B29D-F16E5D48B416',
  /*China; Wanning*/ '31645414-9EE4-46D8-810B-E767DD676A5D',
  /*China; Weifang*/ '6E6D954A-B8CE-473F-BF84-F7D0D278AD3F',
  /*China; Weihai*/ '483D6B80-9A6E-41DD-87AF-3E922C5CEA22',
  /*China; Weitou*/ '63617ADF-A7B8-4CB5-8F66-3383D2EA6672',
  /*China; Weizhou Island*/ 'F851D05D-B6E0-4C06-9AF1-C73B468C4FBA',
  /*China; Wenzhou*/ 'CA905B9E-BFE7-4174-BE07-B7064A90FB84',
  /*China; Western Yantai*/ '6F9DA583-36A0-42B8-AD3C-95889F42A047',
  /*China; Xiabaishi*/ '38B6F630-97A4-4939-A29B-662CC782EDD1',
  /*China; Xiamen*/ '46004FFF-C904-4B5C-9365-9ACB9F1AFB26',
  /*China; Xiangshan*/ '2EB23129-6279-42ED-BDEA-2CCDEB731BF0',
  /*China; Xiangshui*/ 'B79020CE-A4F4-46C0-88FC-0AA7E8124A2C',
  /*China; Xiaolan*/ '696E2C83-082E-4B77-969E-F75EAC45DCC3',
  /*China; Xiaomen*/ '5CC65E39-57BC-4BEA-982E-3C7C68E60E22',
  /*China; Xiashi*/ '6773EB8E-580A-4009-839F-61C3D225B265',
  /*China; Xinhezhen*/ 'EDA710CC-7183-436A-869A-3A601BF6F99A',
  /*China; Xinhui*/ 'BF5EAE9A-020A-4410-8D84-546A030AE077',
  /*China; Xinsha*/ '259B9B54-CBD0-4630-81B6-2374B1D35799',
  /*China; Xintang*/ 'FA00BF61-66A8-4231-8C5A-C5C22331B262',
  /*China; Xiuyu*/ '8BC16E0F-88FE-4FEE-8048-C7DC7ED0F1E9',
  /*China; Xuwei*/ '44E2BE83-C6EF-4A98-9E5A-A9DF3BD4F715',
  /*China; Xuwen*/ '14F852EC-2321-4588-9280-44EDA3294B71',
  /*China; Xuwen*/ '685A8AB1-6A3E-4E93-83C3-883AB083A0F2',
  /*China; Yancheng*/ 'F1028BF3-81CB-40B0-98F4-BEEE21798FC4',
  /*China; Yangjiang*/ '38CDDA9C-A7DA-41BE-841A-123F593585B3',
  /*China; Yangkou*/ 'AB2E831D-A683-4B98-A11E-7CD17E602950',
  /*China; Yangpu*/ '3EA198E5-4D54-4B1E-9F1A-DA9D66D9E3A9',
  /*China; Yangshan*/ '6A000BA4-2570-4033-A5AD-6984EAB6A8FC',
  /*China; Yantai*/ 'D2BACAC2-B51E-4DC1-9D0C-D043DE68A047',
  /*China; Yantian*/ '3BDA67FB-B436-4C1F-817F-856379709C13',
  /*China; Yingkou*/ '13D580FB-3DFF-4769-8C37-949669782AAC',
  /*China; Yu Lin*/ 'BA660474-2C29-46F2-8FED-47576642E03A',
  /*China; Yueqing Bay*/ '5B4B0535-ABAF-4A4B-80B4-F7CCB3ACF4E6',
  /*China; Zhangzhou*/ '2F10B55D-B277-4EC5-A78D-D3B657D27D80',
  /*China; Zhanjiang*/ 'C366A091-6576-4E1C-A254-71E260383BB7',
  /*China; Zhapu*/ '242C7F81-8196-496F-B973-062E7DFD355F',
  /*China; Zhenhai*/ '07243D6F-DF4C-4293-AD44-76B380501D73',
  /*China; Zhongshan*/ '377EA232-BE4F-40C7-9549-7E682F368163',
  /*China; Zhoushan*/ '5DD771C2-450B-4D29-8395-611472FACB34',
  /*China; Zhuanghe*/ '8B3AF18F-B27C-4DD0-9894-C776A2F17F46',
  /*China; Zhuangyuan Ao*/ 'FAE89BFA-4F05-4813-9EE1-2680420C09BF',
  /*China; Zhuhai*/ '74765295-7759-4154-B82E-937947EA68BB',
].map((locationId) => ({
  id: locationId,
}));
