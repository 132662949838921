import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { getActiveWorkbookId } from 'common-selectors/get-active-workbook-id';
import { ErrorBoundary, FallbackError } from 'components/error-boundary';

import './styles.scss';

export class WorksheetErrorBoundary extends Component {
  render() {
    return (
      <ErrorBoundary
        fallbackRender={(_) => {
          const supportEmailOptions = {
            emailTitle: 'Sea/calc worksheet error',
            emailBody: `An error occurred with worksheet id: ${this.props.worksheetId} in the workbook id: ${this.props.workbookId}.`,
          };

          return (
            <FallbackError
              className="worksheet-fallback-error"
              title="This worksheet has encountered an error"
              supportEmailOptions={supportEmailOptions}
            >
              Sorry for the inconvenience. Please contact support if you require assistance.
            </FallbackError>
          );
        }}
      >
        {this.props.children}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = createSelector(
  getActiveWorksheetId,
  getActiveWorkbookId,
  (worksheetId, workbookId) => ({ worksheetId, workbookId })
);

export default connect(mapStateToProps)(WorksheetErrorBoundary);
