import React from 'react';
import { TableHeader } from 'components/table';
import { TC_TO_FREIGHT } from '../../sensitivity-types';
import DataCell from '../data-cell';

const renderTCToFreightHeader = (firstFuelGrade, secondFuelGrade) => [
  <DataCell key="sensitivity-table-header-gross-tc">Gross TC ($)</DataCell>,
  <DataCell key="sensitivity-table-header-net-tc">Net TC ($)</DataCell>,
  <DataCell key="sensitivity-table-header-gross-vr">Gross VR ($/MT)</DataCell>,
  <DataCell key="sensitivity-table-header-first-fuel-grade">{firstFuelGrade.label}</DataCell>,
  <DataCell key="sensitivity-table-header-second-fuel-grade">{secondFuelGrade.label}</DataCell>,
];

const renderFreightToTCHeader = (firstFuelGrade, secondFuelGrade) => [
  <DataCell key="sensitivity-table-header-gross-vr">Gross VR ($/MT)</DataCell>,
  <DataCell key="sensitivity-table-header-net-tc">Net TC ($)</DataCell>,
  <DataCell key="sensitivity-table-header-gross-tc">Gross TC ($)</DataCell>,
  <DataCell key="sensitivity-table-header-first-fuel-grade">{firstFuelGrade.label}</DataCell>,
  <DataCell key="sensitivity-table-header-second-fuel-grade">{secondFuelGrade.label}</DataCell>,
];

const HeaderRow = ({ activeSensitivity, firstFuelGrade, secondFuelGrade }) => (
  <TableHeader>
    {activeSensitivity === TC_TO_FREIGHT
      ? renderTCToFreightHeader(firstFuelGrade, secondFuelGrade)
      : renderFreightToTCHeader(firstFuelGrade, secondFuelGrade)}
  </TableHeader>
);

export default HeaderRow;
