import React from 'react';

const StaticSpeedAndConsHeader = () => {
  return (
    <div className="table-header">
      <span className="speed-and-cons-multi-vessel__static--type">Port Status</span>
      <span className="speed-and-cons-multi-vessel__static--info" />
      <span className="speed-and-cons-multi-vessel__static--consumption">Con (MT/d)</span>
      <span className="status-specific-fuel-grade">Fuel Grade</span>
      <span className="speed-and-cons-multi-vessel__static--generator">Gen (MT/d)</span>
      <span className="status-specific-fuel-grade">Fuel Grade</span>
    </div>
  );
};

export default StaticSpeedAndConsHeader;
