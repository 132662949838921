import React from 'react';
import { TableRow } from 'components/table';
import Cell from '../bunker-ports-table-cell';
import isNil from 'lodash/isNil';
import numbro from 'numbro';
import { format } from 'date-fns';
import userContext from 'user-context';
import { SecondaryButton } from 'components/button';
import { FileDownloadIcon } from 'components/icons';
import { isDry } from 'constants/market-segments';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

const formatPrice = (price) => {
  return isNil(price)
    ? '―'
    : numbro(price).format({
        thousandSeparated: true,
        mantissa: 2,
      });
};

const formatDate = (date) => (isNil(date) ? '' : format(date, 'dd MMM yyyy'));

const BunkerPortsTableRow = ({
  locationName,
  zone,
  cst380Price,
  cst180Price,
  mdoPrice,
  mgoPrice,
  lsMgoPrice,
  ulsfoOneTenthPercPrice,
  vlsfoFiveTenthPercPrice,
  lngPrice,
  dateIssued,
  applyPrice,
  isBunkerPricesPanelReadOnly,
  marketSegmentId,
  delivery,
}) => (
  <TableRow className="bunker-ports-table__row">
    <Cell className="bunker-ports-table--location-name">{locationName}</Cell>
    {
      /* #PreventUndesiredNoDivisionFallbackOnZoneCodes - the data source service doesn't allow to specify division (always takes it from user), and falls back to one that is undesired for external client companies. We rather chose to not show it then. */
      userContext.userInfo.isInHouseCompany && isDry(marketSegmentId) && (
        <Cell className="bunker-ports-table--zone">{zone}</Cell>
      )
    }
    <Cell className="bunker-ports-table--delivery">
      {delivery === 'D' && <IconButton icon={iconEnum.Tick} onClick={() => {}} />}
    </Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(cst380Price)}</Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(cst180Price)}</Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(vlsfoFiveTenthPercPrice)}</Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(mgoPrice)}</Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(mdoPrice)}</Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(lsMgoPrice)}</Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(ulsfoOneTenthPercPrice)}</Cell>
    <Cell className="bunker-ports-table--price">{formatPrice(lngPrice)}</Cell>
    <Cell className="bunker-ports-table--date-issued">{formatDate(dateIssued)}</Cell>
    {!isBunkerPricesPanelReadOnly && (
      <SecondaryButton
        onClick={applyPrice}
        className="bunker-ports-table--import"
        diagnosticId="BunkerPortsTable/Download"
      >
        <FileDownloadIcon />
        PRICES
      </SecondaryButton>
    )}
  </TableRow>
);

export default BunkerPortsTableRow;
