import React from 'react';
import { FlagIcon } from './../../icons';
import './styles.scss';
import classNames from 'classnames';

const WarningBox = ({ className, children, ...props }) => (
  <div role="alert" {...props} className={classNames('warning-box', className)}>
    <FlagIcon
      aria-label="Warning"
      className="notice-box__type-icon"
      remainingnotifications={
        props && props.additionalprops ? props.additionalprops.remainingNotifications : 0
      }
    />
    <span className="notice-box__content">{children}</span>
  </div>
);

export default WarningBox;
