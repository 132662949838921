import { SET_ACTIVE_WORKBOOK_ID } from 'constants/action-types/workbook';

export const activeWorkbookId = (state = null, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_WORKBOOK_ID:
      return action.payload.workbookId;
    default:
      return state;
  }
};
