import React, { useState, useMemo, useCallback, useRef } from 'react';
import './styles.scss';
import { NoticesEmitter, NoticeViewModel } from './emitter-contract';
import {
  DISMISS_ALL_NOTICES,
  DISMISS_WORKSHEET_NOTICES_EXCEPT_THIS,
  DISMISS_THIS_WORKSHEET_NOTICES,
  GENERIC_NOTICES,
  DISMISS_NOTICE_BY_ID,
} from 'constants/enums/emit-notices';

type EmitterContext = React.Context<NoticesEmitter>;
type NoticesContext = React.Context<NoticeViewModel[]>;

/**
 * Allows to emit a notification that the user should see.
 *
 * Usage via `<NoticesEmitterContext.Consumer>` ([React docs](https://reactjs.org/docs/context.html#contextconsumer))
 * or via `MyClass.contextType = NoticesEmitterContext` ([React docs](https://reactjs.org/docs/context.html#classcontexttype))
 * For example use code, see tests in {@link notices-emitter-context.test}
 *
 * For a component to use in JSX code for rendering, see {@link emit-notice}. See there also for more details about
 * how the notification behaves.
 */
// eslint-disable-next-line
export const NoticesEmitterContext: EmitterContext = React.createContext(null);
export const NoticesListContext: NoticesContext = React.createContext([]);

export function NoticesBoundary({ children, onNotice, onNoticesChange, onNoticesEmitterCreated }) {
  const [notices, setNotices] = useState([]);
  const countRef = useRef(0);

  const emit = useCallback(
    (notice) => {
      // create new notice to avoid mutation
      // this is to avoid parent context overriding
      // child notice props
      const newNotice = { ...notice };
      if (newNotice.type === DISMISS_ALL_NOTICES) {
        setNotices((notices) => {
          return [];
        });
      } else if (newNotice.type === DISMISS_WORKSHEET_NOTICES_EXCEPT_THIS) {
        setNotices((notices) => {
          return notices.filter(
            (n) => n.worksheetId === newNotice.worksheetId || isGenericNotice(n.type)
          );
        });
      } else if (newNotice.type === DISMISS_THIS_WORKSHEET_NOTICES) {
        setNotices((notices) => {
          return notices.filter((n) => n.worksheetId && n.worksheetId !== newNotice.worksheetId);
        });
      } else if (newNotice.type === DISMISS_NOTICE_BY_ID) {
        setNotices((notices) => {
          return notices.filter((n) => n.emitNoticeId !== newNotice.noticeIdToDismiss);
        });
      } else {
        setNotices((notices) => {
          let noticeExists = false;
          const newNotices = [...notices];
          if (newNotice.updateWithNoticeId) {
            const replaceIndex = newNotices.findIndex(
              (n) => n.emitNoticeId === newNotice.updateWithNoticeId
            );
            if (replaceIndex !== -1) {
              noticeExists = true;
              const replaceNotice = newNotices.filter(
                (n) => n.emitNoticeId === newNotice.updateWithNoticeId
              )[0];
              replaceNotice.type = newNotice.type;
              replaceNotice.children = newNotice.children;
              newNotices.splice(replaceIndex, 1, replaceNotice);
              return newNotices;
            }
          }

          if (!noticeExists) {
            const replaceIndex = newNotices.findIndex(
              (n) => n.emitNoticeId === newNotice.emitNoticeId
            );

            if (replaceIndex !== -1) {
              const replaceNotice = newNotices.filter(
                (n) => n.emitNoticeId === newNotice.emitNoticeId
              )[0];
              replaceNotice.type = newNotice.type;
              replaceNotice.children = newNotice.children;
              newNotices.splice(replaceIndex, 1, replaceNotice);
              return newNotices;
            } else {
              if (!newNotice.emitNoticeId) {
                newNotice.emitNoticeId = countRef.current;
                countRef.current++;
              }

              if (!newNotice.dismiss) {
                newNotice.dismiss = () => {
                  dismiss(newNotice.emitNoticeId, false);
                };
              }

              if (!newNotice.dismissNonActiveWorksheetMessages) {
                newNotice.dismissNonActiveWorksheetMessages = () => {
                  dismiss(newNotice.emitNoticeId, true);
                };
              }

              return [...notices, newNotice];
            }
          }
        });

        onNotice && onNotice(notice);
      }
    },
    // let react know we don't depend on anything, so only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isGenericNotice = (noticeType: string) => {
    return GENERIC_NOTICES.findIndex((gn) => gn === noticeType) !== -1;
  };

  const dismiss = useCallback((id, dismissNonActiveWorksheetMessages) => {
    setNotices((notices) => {
      const activeNotice = notices.filter((n) => n.emitNoticeId === id)[0];
      const worksheetId = activeNotice?.worksheetId;
      if (dismissNonActiveWorksheetMessages) {
        return notices.filter(
          (n) => (n.emitNoticeId !== id && n.worksheetId === worksheetId) || !n.worksheetId
        );
      } else {
        return notices.filter((n) => n.emitNoticeId !== id);
      }
    });
  }, []);

  const emitter = useMemo(
    () => ({
      emit,
      dismiss,
    }),
    // let react know we don't depend on anything, so only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // allow listeners to receive a copy of notices when they change
  React.useEffect(() => {
    onNoticesChange && onNoticesChange(notices);
  }, [notices, onNoticesChange]);

  React.useEffect(
    () => {
      onNoticesEmitterCreated && onNoticesEmitterCreated(emitter);
    },
    // let react know we don't depend on anything, so only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <NoticesEmitterContext.Provider value={emitter}>
      <NoticesListContext.Provider value={notices}>{children}</NoticesListContext.Provider>
    </NoticesEmitterContext.Provider>
  );
}

export default NoticesEmitterContext;
