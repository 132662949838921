import { createSelector } from 'reselect';

import { getActiveWorksheet } from '../../common-selectors/get-active-worksheet';
import { getActiveCalculationResult } from '../../common-selectors/get-active-calculation-result';

export const selector = createSelector(
  getActiveWorksheet,
  getActiveCalculationResult,
  (worksheet, calculationResult) => ({
    worksheetId: worksheet.id,
    brokerCommission: worksheet.rates.brokerCommissionTimeCharter,
    grossFreight: calculationResult?.voyageRate.totalIncomeGross ?? 0,
  })
);
