import { AxiosInstance } from 'axios';
import packageInfo from '../../package.json';
import semver from 'semver';

export const contractVersionHeaderKey = 'CONTRACT-VERSION';

type RegisterVersionMismatchHandler = (mismatchDetectedCallback: () => void) => void;

export function addVersionCheckInterceptorToClient(
  axiosInstance: AxiosInstance
): RegisterVersionMismatchHandler {
  let isVersionMismatch = false;
  let versionMismatchCallback = null;

  axiosInstance.defaults.headers[contractVersionHeaderKey] = packageInfo.version;

  function invokeCallbackIfVersionMismatched(response) {
    if (!response.headers[contractVersionHeaderKey.toLowerCase()]) {
      return;
    }

    const remoteVersion = response.headers[contractVersionHeaderKey.toLowerCase()];
    const localVersion = packageInfo.version; // Allow remote version to include patch increments (bug fixes) but not new features.

    if (!semver.satisfies(remoteVersion, localVersion)) {
      isVersionMismatch = true;
      versionMismatchCallback && versionMismatchCallback(true);
    }
  }

  function handleResponse(response) {
    invokeCallbackIfVersionMismatched(response);
    return response;
  }

  function handleError(error) {
    if (error.response) {
      invokeCallbackIfVersionMismatched(error.response);
    }

    throw error;
  }

  axiosInstance.interceptors.response.use(handleResponse, handleError);

  return (callback) => {
    if (versionMismatchCallback) {
      throw new Error('A version mismatch callback is already registered');
    }

    if (typeof callback !== 'function') {
      throw new Error('Please provide a callback for version mismatch');
    }

    versionMismatchCallback = callback;

    if (isVersionMismatch) {
      callback(true);
    }
  };
}
