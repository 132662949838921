import { veApi, sandcApi } from 'api';
import { rateLimited } from 'utilities/functions';
import type { MarketSegmentId } from 'constants/market-segments';
import { isDry, isWet } from 'constants/market-segments';
import { ISpeedAndCons } from 'constants/speed-and-cons';
import {
  getSpeedAndConTypeIdByKey,
  PORT_WORKING,
  getSpeedAndConTypeById,
  LADEN,
  BALLAST,
  ECO_LADEN,
  ECO_BALLAST,
  LOADING,
  DISCHARGING,
  SAILING,
  ECO_SAILING,
} from 'constants/enums/speed-and-con-types';
import { getFuelGradeByCode } from 'constants/enums/fuel-grades';
import { isFeatureEnabled } from "../../../config/feature-control";

export type VesselSearchResultItemDto = {
  vesselId: number,
  imo: number,
  buildYear: number,
  deadweight: number,
  flagCode: string,
  displayName: string,
};

const sucessStatusCode = 200;
let getVesselByIdMaxConcurrency = 10;

export const concurrencySettings = {
  getVesselById: {
    get maxConcurrency() {
      return getVesselByIdMaxConcurrency;
    },
    set maxConcurrency(newValue) {
      getVesselByIdRateLimited = rateLimited(getVesselByIdMaxConcurrency, getVesselById);
      getVesselByIdMaxConcurrency = newValue;
    },
  },
};

const getVesselById = async ({
  vesselId,
  marketSegmentId,
}: {
  marketSegmentId: MarketSegmentId,
  vesselId: string,
}) => {
  const url = `/vessel/${vesselId}?marketSegmentId=${encodeURIComponent(marketSegmentId)}`;

  const sandcApiurl = `/speed-and-consumptions/vesselId/${vesselId}/marketSegmentId/${encodeURIComponent(
    marketSegmentId
  )}`;
  const [vesselResponse, sandcResponse] = await Promise.all([
    veApi.get(url),
    sandcApi.get(sandcApiurl, {
      headers: {
        'VpsFeatureToggle': isFeatureEnabled('vpsSpeedAndConsumption'),
      },
    }),
  ]);

  if (
    sandcResponse &&
    sandcResponse.data &&
    sandcResponse.data.length &&
    vesselResponse.data &&
    vesselResponse.data.vessel
  ) {
    mergeLatestSandCWithVEApiResponse(sandcResponse.data, vesselResponse);
  }

  return vesselResponse.data.vessel;
};

const getVesselHistoryById = async ({
  vesselId,
  sandTypeId,
  marketSegmentId,
}: {
  vesselId: number,
  sandTypeId: number,
  marketSegmentId: MarketSegmentId,
}) => {
  let typeId = sandTypeId;

  if (isDry(marketSegmentId)) {
    typeId = getSpeedAndConTypeById(sandTypeId) === LOADING ? Number(PORT_WORKING.id) : sandTypeId;
  }

  const sandcApiurl = `/speed-and-consumptions/history/vesselId/${vesselId}/type/${typeId}/marketSegmentId/${encodeURIComponent(
    marketSegmentId
  )}`;

  const sandcResponse = await sandcApi.get(sandcApiurl, {
    headers: {
      'VpsFeatureToggle': isFeatureEnabled('vpsSpeedAndConsumption'),
    },
  });

  if (sandcResponse.status === sucessStatusCode) {
    return sandcResponse.data;
  } else {
    return [];
  }
};

let getVesselByIdRateLimited = rateLimited(getVesselByIdMaxConcurrency, getVesselById);

let getVesselHistoryByIdRateLimited = rateLimited(
  getVesselByIdMaxConcurrency,
  getVesselHistoryById
);

const mergeLatestSandCWithVEApiResponse = (speedAndCons: ISpeedAndCons[], veApiResponse) => {
  for (var inx in speedAndCons) {
    var sc = speedAndCons[inx];
    var scTypeId = getSpeedAndConTypeIdByKey(sc.type).id;

    if (veApiResponse.data.vessel.speedAndCons[scTypeId]) {
      const veApiResponseRow = veApiResponse.data.vessel.speedAndCons[scTypeId];
      veApiResponseRow.speed = sc.speed_KN;
      veApiResponseRow.fuelGrade = getFuelGradeByCode(sc.mainEngineConsumption.fuelGrade).key;
      veApiResponseRow.consumption = sc.mainEngineConsumption.consumption_MT;
      veApiResponseRow.generatorFuelGrade = getFuelGradeByCode(
        sc.generatorConsumption.fuelGrade
      ).key;
      veApiResponseRow.generatorConsumption = sc.generatorConsumption.consumption_MT;
      veApiResponseRow.defaultFuelGradeChangeNotRequired = true;
    } else {
      const veApiResponseNewRow = {
        speed: sc.speed_KN,
        fuelGrade: getFuelGradeByCode(sc.mainEngineConsumption.fuelGrade).key,
        consumption: sc.mainEngineConsumption.consumption_MT,
        generatorFuelGrade: getFuelGradeByCode(sc.generatorConsumption.fuelGrade).key,
        generatorConsumption: sc.generatorConsumption.consumption_MT,
        defaultFuelGradeChangeNotRequired: true,
      };
      veApiResponse.data.vessel.speedAndCons[scTypeId] = veApiResponseNewRow;
    }
  }
  return veApiResponse;
};

export const postSpeedAndCons = async (
  speedAndCons,
  vesselId,
  marketSegmentId: MarketSegmentId,
  isParcelVoyage: boolean
) => {
  var data = speedAndConsToDto(speedAndCons, vesselId, marketSegmentId, isParcelVoyage);
  const sandcResponse = await sandcApi.post(`/speed-and-consumptions`, data);
  return sandcResponse;
};

const shouldIncludeInPostData = (
  speedAndCon,
  marketSegmentId,
  isParcelVoyage: boolean
): boolean => {
  let include = false;
  if (speedAndCon.isActive) {
    if (isWet(marketSegmentId)) {
      if (isParcelVoyage) {
        include =
          speedAndCon.type !== BALLAST.id &&
          speedAndCon.type !== LADEN.id &&
          speedAndCon.type !== ECO_LADEN.id &&
          speedAndCon.type !== ECO_BALLAST.id;
      } else {
        include = speedAndCon.type !== SAILING.id && speedAndCon.type !== ECO_SAILING.id;
      }
    } else {
      include = isDry(marketSegmentId) && speedAndCon.type !== DISCHARGING.id;
    }
  }
  return include;
};

const speedAndConsToDto = (
  speedAndCons,
  vesselId,
  marketSegmentId,
  isParcelVoyage: boolean
): ISpeedAndCons[] => {
  const sandcCollection: ISpeedAndCon[] = [];
  for (var scInx in speedAndCons) {
    var sc = speedAndCons[scInx];
    var include = shouldIncludeInPostData(sc, marketSegmentId, isParcelVoyage);
    if (include) {
      var scType = isDry(marketSegmentId) && sc.type === LOADING.id ? PORT_WORKING.id : sc.type;
      var mainEngineConsumption = {
        consumption_MT: sc.consumption,
        fuelGrade: sc.fuelGrade.key,
      };
      var generatorConsumption = {
        consumption_MT: sc.generatorConsumption,
        fuelGrade: sc.generatorFuelGrade.key,
      };
      sandcCollection.push({
        vesselId: vesselId,
        type: scType,
        speed_KN: sc.speed,
        mainEngineConsumption: mainEngineConsumption,
        generatorConsumption: generatorConsumption,
      });
    }
  }
  return sandcCollection;
};

const getVesselByIdRateLimitedWrapper = function () {
  return getVesselByIdRateLimited.apply(this, arguments);
};

const getVesselHistoryByIdRateLimitedWrapper = function () {
  return getVesselHistoryByIdRateLimited.apply(this, arguments);
};

export { getVesselByIdRateLimitedWrapper as getVesselById };
export { getVesselHistoryByIdRateLimitedWrapper as getVesselHistoryById };

export const getVesselsByFreeText = async ({
  searchTerm,
  marketSegmentId,
  cancelToken,
}: {
  searchTerm: string,
  marketSegmentId: MarketSegmentId,
  cancelToken: CancelToken,
}): Promise<Array<VesselSearchResultItemDto>> => {
  const url = `/vessel/search?query=${encodeURIComponent(
    searchTerm
  )}&marketSegmentId=${encodeURIComponent(marketSegmentId)}`;

  const response = await veApi.get(url, {
    cancelToken: cancelToken,
  });

  return response.data.vessels;
};
