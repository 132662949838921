import React, { Component } from 'react';
import { oidcServerUrl } from 'config';
import * as exceptionSink from 'diagnostics/unhandled-exception-sink';
import * as AuthFacade from 'bootstrap/AuthFacade';
import { setMixpanelTrackEvent } from 'diagnostics/calc-trackevents';
import { headerCdnUrl } from 'config';

class SeaHeader extends Component {
  containerElementRef = React.createRef();
  headerComponent;

  headerScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${headerCdnUrl}/v3/latest/sea-header.js`;
    script.id = 'SeaHeaderScript';

    const head = document.head || document.getElementsByTagName('head')[0];

    head.insertBefore(script, head.firstChild);

    return script;
  };

  componentDidMount() {
    try {
      this.headerScript().onload = () => {
        const config = {
          container: this.containerElementRef.current,
          authority: oidcServerUrl,
          loadCss: true,
          tracking: {
            isEnabled: true,
            properties: {
              debug: true,
            },
          },
          getTokenCallback: AuthFacade.getToken,
          logoutCallback: (event) => {
            if (event.preventDefault) event.preventDefault();
            else event.returnValue = false;
            AuthFacade.redirectToLogout();
          },
        };
        this.headerComponent = new window.Sea.HeaderComponent.Header(config);
        setMixpanelTrackEvent(this.headerComponent.trackEvent);
      };
    } catch (error) {
      exceptionSink.send(error);
      // Not rethrowing, because it would trigger the ErrorBoundary chain which would destroy the Sea/calc UI itself
    }
  }

  render() {
    return <div ref={this.containerElementRef} id="navbar" />;
  }
}

export default SeaHeader;
