import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import { SET_WORKSHEET_NAME } from 'constants/action-types/worksheet/name';

const initialState = '';

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return action.payload.name;
    case SET_WORKSHEET_NAME:
      return action.payload.worksheetName;
    default:
      return state;
  }
};
