import { REFERENCE_DATA_WAYPOINTS_LOADED } from 'constants/action-types/reference-data';

const initialState = {
  canals: {},
  piracyZones: {},
  secaZones: {},
  straits: {},
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case REFERENCE_DATA_WAYPOINTS_LOADED:
      return action.payload;
    default:
      return state;
  }
};
