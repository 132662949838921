import { WorksheetNameRenderer } from '../worksheets-grid/components/worksheet-name-renderer';
import { WorksheetMenuRenderer } from './components/worksheet-menu-renderer/index';
import { UpdatedDateRenderer } from './components/updated-date-renderer';
import { WorksheetHeader } from './components/worksheet-header';
import UpdatedDateFormatter from '../workbooks-grid/grid-value-formatters/updated-date-formatter';
import './styles.scss';

export const nameColumnId = 'name';

export const getDefaultGridOptions = (props, loadMyWorkbooksFun, closePanelFun) => {
  return {
    rowHeight: 32,
    headerHeight: 32,
    animateRows: true,
    suppressCellFocus: true,
    suppressHorizontalScroll: true,
    defaultColDef: {
      resizable: false,
      filter: false,
      suppressMovable: true,
      headerComponent: WorksheetHeader,
    },
    tooltipShowDelay: 1000,
    columnDefs: [
      {
        headerName: 'Worksheet',
        colId: nameColumnId,
        field: 'name',
        cellRenderer: (props) => {
          return WorksheetNameRenderer(props, closePanelFun, '–');
        },
        width: 120,
        tooltipField: 'name',
      },
      {
        headerName: 'Workbook',
        field: 'workbookName',
        cellRenderer: (props) => {
          return WorksheetNameRenderer(props, closePanelFun, '–');
        },
        width: 120,
        tooltipField: 'workbookName',
      },
      {
        headerName: 'Cargo',
        field: 'cargo',
        width: 120,
        cellRenderer: (props) => {
          return WorksheetNameRenderer(props, closePanelFun, '–');
        },
        tooltipField: 'cargo',
      },
      {
        headerName: 'Vessel',
        field: 'vesselNames',
        width: 120,
        cellRenderer: (props) => {
          return WorksheetNameRenderer(props, closePanelFun, '–');
        },
        tooltipField: 'vesselNames',
      },
      {
        headerName: 'Load',
        field: 'loadPortLegLocations',
        width: 120,
        cellRenderer: (props) => {
          return WorksheetNameRenderer(props, closePanelFun, '–');
        },
        tooltipField: 'loadPortLegLocations',
      },
      {
        headerName: 'Discharge',
        field: 'dischargePortLegLocations',
        width: 120,
        cellRenderer: (props) => {
          return WorksheetNameRenderer(props, closePanelFun, '–');
        },
        tooltipField: 'dischargePortLegLocations',
      },
      {
        headerName: 'Last Updated',
        field: 'updatedDate',
        width: 100,
        valueFormatter: UpdatedDateFormatter,
        cellRenderer: (props) => {
          return UpdatedDateRenderer(props, closePanelFun);
        },
      },
      {
        colId: 'actionsId',
        cellRenderer: (props) => {
          return WorksheetMenuRenderer(props, loadMyWorkbooksFun, closePanelFun);
        },
        width: 25,
        sortable: false,
        cellStyle: {
          padding: '0px 0px 3px 0px',
          position: 'relative',
          overflow: 'visible',
        },
      },
    ],
    getRowClass: () => 'workbook-card__row',
  };
};
