import { FRESH, SALT } from 'constants/enums/salinity-types';
import { soxPercentLimits } from 'constants/operational-restrictions/sox-percent-limits';
import type { SoxPercentLimit } from 'constants/operational-restrictions/sox-percent-limits';

//The maximum draft of a location can be probative when calculating the maximum lift for a vessel.
//A large (and unrealistic) number for the maximum draft is used when the real maximum draft is unknown.
//This is to prevent the maximum draft being the limiting factor in calculating the maximum lift.
const unrestrictedMaxDraftMeters: number = 999;

//When a salinity factor is unknown, default to salt water.
const defaultSalinityFactor = SALT.value;

export const canals = {
  '920433d6-7493-46e0-84d0-1289b6802729': {
    name: 'Suez',
    costData: [
      {
        vesselDwt: 16800,
        costBallast: 100887,
        costLaden: 115554,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 17000,
        costBallast: 109440,
        costLaden: 125572,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 18300,
        costBallast: 100634,
        costLaden: 115257,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 22600,
        costBallast: 120982,
        costLaden: 139091,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 28200,
        costBallast: 131690,
        costLaden: 151634,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 32400,
        costBallast: 150234,
        costLaden: 173304,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 34790,
        costBallast: 157881,
        costLaden: 182258,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 37000,
        costBallast: 157645,
        costLaden: 181983,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 38060,
        costBallast: 163452,
        costLaden: 188749,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 39100,
        costBallast: 164565,
        costLaden: 190047,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 49000,
        costBallast: 170660,
        costLaden: 197148,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 55800,
        costBallast: 179311,
        costLaden: 207229,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 57800,
        costBallast: 180442,
        costLaden: 208546,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 58900,
        costBallast: 183271,
        costLaden: 211843,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 60300,
        costBallast: 184107,
        costLaden: 212817,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 61500,
        costBallast: 185788,
        costLaden: 214776,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 87150,
        costBallast: 214830,
        costLaden: 248530,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 93000,
        costBallast: 217643,
        costLaden: 251807,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 106000,
        costBallast: 237450,
        costLaden: 274801,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 117500,
        costBallast: 240188,
        costLaden: 277991,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 150000,
        costBallast: 265136,
        costLaden: 306725,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 160000,
        costBallast: 273426,
        costLaden: 316362,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 170000,
        costBallast: 290815,
        costLaden: 336621,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 175000,
        costBallast: 290504,
        costLaden: 336258,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 177730,
        costBallast: 292049,
        costLaden: 338061,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 180300,
        costBallast: 296973,
        costLaden: 343810,
        lastUpdated: '02 Nov 2018',
      },
      {
        vesselDwt: 182500,
        costBallast: 294727,
        costLaden: 341188,
        lastUpdated: '02 Nov 2018',
      },
    ],
    maxDraftMeters: 20,
    salinityFactor: FRESH.value,
  },
  'ba7c1db1-5590-4d86-b1bd-4e42c2ddeea2': {
    name: 'Corinth',
    costData: [],
    maxDraftMeters: unrestrictedMaxDraftMeters,
    salinityFactor: defaultSalinityFactor,
  },
  '3b020607-0bd6-4040-bdc3-54ef0581d3cd': {
    name: 'Kiel',
    costData: [
      {
        vesselDwt: 18909,
        costBallast: 13342,
        costLaden: 14939,
        lastUpdated: '22 Jun 2016',
      },
      {
        vesselDwt: 26096,
        costBallast: 20185,
        costLaden: 20755,
        lastUpdated: '22 Jun 2016',
      },
      {
        vesselDwt: 31901,
        costBallast: 21325,
        costLaden: 22009,
        lastUpdated: '22 Jun 2016',
      },
      {
        vesselDwt: 39848,
        costBallast: 22921,
        costLaden: 23720,
        lastUpdated: '22 Jun 2016',
      },
      {
        vesselDwt: 56584,
        costBallast: 25544,
        costLaden: 26457,
        lastUpdated: '22 Jun 2016',
      },
    ],
    maxDraftMeters: unrestrictedMaxDraftMeters,
    salinityFactor: defaultSalinityFactor,
  },
  '671e88f4-a1d7-4541-85df-e35afbb181fc': {
    name: 'Panama',
    costData: [
      {
        vesselDwt: 8350,
        cargo: 'Grain',
        cargoQuantity: 7500,
        costBallast: 46980,
        costLaden: 51480,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 28000,
        cargo: 'Grain',
        cargoQuantity: 25000,
        costBallast: 98684,
        costLaden: 111065,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 35000,
        cargo: 'Grain',
        cargoQuantity: 30000,
        costBallast: 112644,
        costLaden: 127481,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 38000,
        cargo: 'Grain',
        cargoQuantity: 35000,
        costBallast: 118057,
        costLaden: 133947,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 45000,
        cargo: 'Grain',
        cargoQuantity: 40000,
        costBallast: 130438,
        costLaden: 148533,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 50000,
        cargo: 'Grain',
        cargoQuantity: 45000,
        costBallast: 141706,
        costLaden: 161305,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 55000,
        cargo: 'Grain',
        cargoQuantity: 50000,
        costBallast: 156995,
        costLaden: 178097,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 56000,
        cargo: 'Grain',
        cargoQuantity: 50000,
        costBallast: 158749,
        costLaden: 180152,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 58000,
        cargo: 'Grain',
        cargoQuantity: 55000,
        costBallast: 162258,
        costLaden: 184263,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 58000,
        cargo: 'Iron Ore',
        cargoQuantity: 55000,
        costBallast: 158749,
        costLaden: 175661,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 60000,
        cargo: 'Iron Ore',
        cargoQuantity: 55000,
        costBallast: 165766,
        costLaden: 179550,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 65000,
        cargo: 'Grain',
        cargoQuantity: 60000,
        costBallast: 172032,
        costLaden: 198197,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 65000,
        cargo: 'Iron Ore',
        cargoQuantity: 60000,
        costBallast: 172032,
        costLaden: 188774,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 74000,
        cargo: 'Coal',
        cargoQuantity: 70000,
        costBallast: 183310,
        costLaden: 224804,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 74000,
        cargo: 'Iron Ore',
        cargoQuantity: 70000,
        costBallast: 183310,
        costLaden: 205375,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 78000,
        cargo: 'Coal',
        cargoQuantity: 70000,
        costBallast: 188322,
        costLaden: 231661,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 78000,
        cargo: 'Iron Ore',
        cargoQuantity: 70000,
        costBallast: 188322,
        costLaden: 212753,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 85000,
        cargo: 'Coal',
        cargoQuantity: 80000,
        costBallast: 197095,
        costLaden: 243661,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 85000,
        cargo: 'Iron Ore',
        cargoQuantity: 80000,
        costBallast: 197095,
        costLaden: 225666,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 92500,
        cargo: 'Coal',
        cargoQuantity: 90000,
        costBallast: 200478,
        costLaden: 253285,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 92500,
        cargo: 'Iron Ore',
        cargoQuantity: 90000,
        costBallast: 200478,
        costLaden: 232958,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 95000,
        cargo: 'Coal',
        cargoQuantity: 90000,
        costBallast: 200478,
        costLaden: 256493,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 95000,
        cargo: 'Iron Ore',
        cargoQuantity: 90000,
        costBallast: 201606,
        costLaden: 235390,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 114000,
        cargo: 'Coal',
        cargoQuantity: 100000,
        costBallast: 209987,
        costLaden: 291860,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 114000,
        cargo: 'Iron Ore',
        cargoQuantity: 100000,
        costBallast: 209987,
        costLaden: 257113,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 170000,
        cargo: 'Coal',
        cargoQuantity: 150000,
        costBallast: 235250,
        costLaden: 329233,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 170000,
        cargo: 'Iron Ore',
        cargoQuantity: 150000,
        costBallast: 235250,
        costLaden: 277103,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 180000,
        cargo: 'Coal',
        cargoQuantity: 170000,
        costBallast: 237756,
        costLaden: 335048,
        lastUpdated: '05 Nov 2018',
      },
      {
        vesselDwt: 180000,
        cargo: 'Iron Ore',
        cargoQuantity: 170000,
        costBallast: 237756,
        costLaden: 279108,
        lastUpdated: '05 Nov 2018',
      },
    ],
    maxDraftMeters: 15.2,
    salinityFactor: FRESH.value,
  },
  '65efc75e-8d6b-49e3-bd88-86f4fbe88882': {
    name: 'Bosporus Strait',
    costData: [
      {
        vesselDwt: 10596,
        costBallast: 6889,
        costLaden: 6889,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 12077,
        costBallast: 7373,
        costLaden: 7373,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 17832,
        costBallast: 8598,
        costLaden: 8598,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 27254,
        costBallast: 10993,
        costLaden: 10993,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 37574,
        costBallast: 13412,
        costLaden: 13412,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 43500,
        costBallast: 14504,
        costLaden: 14504,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 52500,
        costBallast: 16909,
        costLaden: 16909,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 59960,
        costBallast: 17659,
        costLaden: 17659,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 66800,
        costBallast: 20555,
        costLaden: 20555,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 75100,
        costBallast: 21439,
        costLaden: 21439,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 78072,
        costBallast: 22142,
        costLaden: 22142,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 82154,
        costBallast: 23357,
        costLaden: 23357,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 85400,
        costBallast: 24113,
        costLaden: 24113,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 93000,
        costBallast: 28424,
        costLaden: 28424,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 110000,
        costBallast: 29589,
        costLaden: 29589,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 120000,
        costBallast: 32238,
        costLaden: 32238,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 150000,
        costBallast: 38257,
        costLaden: 38257,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 172720,
        costBallast: 40770,
        costLaden: 40770,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 176900,
        costBallast: 45423,
        costLaden: 45423,
        lastUpdated: '05 Sep 2016',
      },
    ],
    maxDraftMeters: unrestrictedMaxDraftMeters,
    salinityFactor: defaultSalinityFactor,
  },
  '1ea3b569-f407-4a00-8cd7-b4cd02f69e9a': {
    name: 'Dardanelles Strait',
    costData: [
      {
        vesselDwt: 10596,
        costBallast: 6889,
        costLaden: 6889,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 12077,
        costBallast: 7373,
        costLaden: 7373,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 17832,
        costBallast: 8598,
        costLaden: 8598,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 27254,
        costBallast: 10993,
        costLaden: 10993,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 37574,
        costBallast: 13412,
        costLaden: 13412,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 43500,
        costBallast: 14504,
        costLaden: 14504,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 52500,
        costBallast: 16909,
        costLaden: 16909,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 59960,
        costBallast: 17659,
        costLaden: 17659,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 66800,
        costBallast: 20555,
        costLaden: 20555,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 75100,
        costBallast: 21439,
        costLaden: 21439,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 78072,
        costBallast: 22142,
        costLaden: 22142,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 82154,
        costBallast: 23357,
        costLaden: 23357,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 85400,
        costBallast: 24113,
        costLaden: 24113,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 93000,
        costBallast: 28424,
        costLaden: 28424,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 110000,
        costBallast: 29589,
        costLaden: 29589,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 120000,
        costBallast: 32238,
        costLaden: 32238,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 150000,
        costBallast: 38257,
        costLaden: 38257,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 172720,
        costBallast: 40770,
        costLaden: 40770,
        lastUpdated: '05 Sep 2016',
      },
      {
        vesselDwt: 176900,
        costBallast: 45423,
        costLaden: 45423,
        lastUpdated: '05 Sep 2016',
      },
    ],
    maxDraftMeters: unrestrictedMaxDraftMeters,
    salinityFactor: defaultSalinityFactor,
  },
  '4cd48e4f-b657-49e2-941a-aa8e07dd591e': {
    // Even though it's a natural strait, [piloting is compulsory](https://en.wikipedia.org/wiki/Strait_of_Magellan), hence it's a waypoint with transit costs, like a canal.
    name: 'Strait of Magellan',
    costData: [
      // Costs are not fed at the moment. Listing the waypoint here is still of value - user can fill the costs himself.
    ],
    maxDraftMeters: unrestrictedMaxDraftMeters,
    salinityFactor: defaultSalinityFactor,
  },
  '35c29b29-155e-4570-aa32-7e32c7cf4624': {
    name: 'St Lawrence Seaway',
    costData: [
      {
        vesselDwt: 17500,
        cargo: 'Coal',
        cargoQuantity: 16000,
        costBallast: 23608,
        costLaden: 23608,
        lastUpdated: '13 Apr 2018',
      },
      {
        vesselDwt: 17500,
        cargo: 'Steel',
        cargoQuantity: 16000,
        costBallast: 69990,
        costLaden: 69990,
        lastUpdated: '16 Apr 2018',
      },
      {
        vesselDwt: 18900,
        cargo: 'Coal',
        cargoQuantity: 17000,
        costBallast: 24159,
        costLaden: 24159,
        lastUpdated: '13 Apr 2018',
      },
      {
        vesselDwt: 18900,
        cargo: 'Steel',
        cargoQuantity: 17000,
        costBallast: 48604,
        costLaden: 48604,
        lastUpdated: '16 Apr 2018',
      },
      {
        vesselDwt: 19800,
        cargo: 'Coal',
        cargoQuantity: 18000,
        costBallast: 25555,
        costLaden: 25555,
        lastUpdated: '13 Apr 2018',
      },
      {
        vesselDwt: 19800,
        cargo: 'Steel',
        cargoQuantity: 18000,
        costBallast: 51437,
        costLaden: 51437,
        lastUpdated: '16 Apr 2018',
      },
      {
        vesselDwt: 24800,
        cargo: 'Coal',
        cargoQuantity: 23000,
        costBallast: 34690,
        costLaden: 34690,
        lastUpdated: '13 Apr 2018',
      },
      {
        vesselDwt: 24800,
        cargo: 'Steel',
        cargoQuantity: 23000,
        costBallast: 67762,
        costLaden: 67762,
        lastUpdated: '16 Apr 2018',
      },
      {
        vesselDwt: 28000,
        cargo: 'Coal',
        cargoQuantity: 25000,
        costBallast: 35469,
        costLaden: 35469,
        lastUpdated: '13 Apr 2018',
      },
      {
        vesselDwt: 28000,
        cargo: 'Steet',
        cargoQuantity: 25000,
        costBallast: 71417,
        costLaden: 71417,
        lastUpdated: '16 Apr 2018',
      },
      {
        vesselDwt: 30700,
        cargo: 'Coal',
        cargoQuantity: 25000,
        costBallast: 38394,
        costLaden: 38394,
        lastUpdated: '13 Apr 2018',
      },
      {
        vesselDwt: 30700,
        cargo: 'Steel',
        cargoQuantity: 25000,
        costBallast: 72641,
        costLaden: 72641,
        lastUpdated: '16 Apr 2018',
      },
    ],
    maxDraftMeters: unrestrictedMaxDraftMeters,
    salinityFactor: defaultSalinityFactor,
  },
};

export const piracyZones = {
  '318f36ed-b910-47d2-8527-1e272baa2286': {
    name: 'JWC Venezuela',
  },
  '4a25c66f-72e0-4c6f-9a70-56f5076fcb77': {
    name: 'JWC Africa (Gulf of Guinea)',
  },
  '206d8994-cfb9-42d6-a860-b1637c8cb408': {
    name: 'Somalia',
  },
  'e27a366e-f557-429f-9ed7-b874e6955a6c': {
    name: 'Somalia 250NM',
  },
  'e7401c7c-5350-44a2-a04b-da8cdf194022': {
    name: 'JWC Indonesia',
  },
  '43e9ecd2-7695-41bb-8eec-e23fc4e003a6': {
    name: 'JWC Indian Ocean 2015 (Gulf of Aden)',
  },
};

export const secaZones: {
  name: string,
  /**
    `true` when the waypoint is no longer returned from Routing API
    (The entry needs to be here still to give the name for the UI for the old worksheets)
   */
  isObsolete: boolean,
  /**
    Unspecified for obsolete points.
   */
  currentSOXLimitPercent?: SoxPercentLimit,
} = {
  '782f2352-605e-4126-9d73-14620fd7ed28': {
    name: 'Hawaii ECA',
    isObsolete: true,
  },
  'a6b341ba-9668-4c50-8f8e-282da6ea18a3': {
    name: 'US & Canada East ECA',
    isObsolete: true,
  },
  'c2789133-d59c-4da0-909d-3d73108462df': {
    name: 'Yangtze River Delta ECA',
    isObsolete: true,
  },
  'a98466e0-3472-45c5-b510-41ecfa13dd91': {
    name: 'Pearl River Delta ECA',
    isObsolete: true,
  },
  '6de26077-7e5d-4d24-afdf-580f043b375f': {
    name: 'US & Canada West ECA',
    isObsolete: true,
  },
  '9ec125cd-131a-4948-be83-6d00e5113ab0': {
    name: 'Caribbean ECA',
    isObsolete: true,
  },
  '0fcba999-fecf-4f82-979a-85a91e6b9ed6': {
    name: 'Bohai Rim ECA',
    isObsolete: true,
  },
  '8fb1a918-4711-44ab-8b77-c1b7a34d0390': {
    name: 'Europe & Baltic ECA',
    isObsolete: true,
  },
  '2b5b5387-bdaa-4639-ab02-22004449dab0': {
    name: 'Baltic Sea ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  '667196c2-e2d1-47ca-bb98-8ad4ae233573': {
    name: 'China ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.imoGlobalLimit,
  },

  '5e5d0e7e-234c-4efa-acd8-b34e162d07c5': {
    name: 'North American ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  '258fc832-258b-4722-a8ab-45425e4c6ea4': {
    name: 'North Sea ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  'a505d35d-7562-493c-ad2d-6758a0dc97db': {
    name: 'U.S. Caribbean ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  '7bdec3fa-320a-4ee4-8374-0cae0ab57434': {
    name: 'Xijiang River ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  '7b4bfef3-2301-4084-8da9-361516caac7f': {
    name: 'Yangtze River ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  'a66def00-7f53-4869-b553-4cc90e02d7bc': {
    name: 'Iceland ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  /*Live Environment - Hainan ECA details*/
  '0aeb48b2-327b-4610-be92-71e918032c02': {
    name: 'Hainan ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  /*Hainan ECA Guid is diffrent in staging env*/
  'c2cc4ca9-a29f-43f2-a226-a1b1759a08ce': {
    name: 'Hainan ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  /*Live Environment - South Korea ECA details*/
  'd293808a-535e-4e1b-aefd-3bc907d67e81': {
    name: 'South Korea ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
  /*South Korea ECA Guid is diffrent in staging env*/
  '2c51b506-9bfc-4707-b665-67e2d43d493e': {
    name: 'South Korea ECA',
    isObsolete: false,
    currentSOXLimitPercent: soxPercentLimits.secaZonesLimit,
  },
};

export const straits = {
  '4cd48e4f-b657-49e2-941a-aa8e07dd591e': {
    name: 'Magellan Strait',
  },
  '944502fc-8518-40a0-ba8d-02e1294fae07': {
    name: 'Sunda Strait',
  },
  '772f98e0-0350-447f-b1c2-7de3c8c55a09': {
    name: 'Cape Horn',
  },
  'b4e5522d-7a71-4af5-a2de-ddd38b52b375': {
    name: 'Cape of Good Hope',
  },
  'f11e64b5-c38e-4f2d-bf07-992d6e86e110': {
    name: 'Torres Strait',
  },
  'a4112ea2-36ed-416e-92b7-92ddc4ba1a7c': {
    name: 'Northeast Passage',
  },
  'f1260f62-8ad6-40a7-aa78-7dd87906a836': {
    name: 'Taiwan Strait',
  },
};

export const routingAvoidZones = {
  '9b1d2edc-cd04-456a-aaef-28d8a76e685d': {
    name: 'Orkney',
  },
  '877b2434-9742-411f-b4ab-1f78b81a94f7': {
    name: 'Strait of Belle Isle (Gulf of St Lawrence)',
  },
};

const secaZoneLocationIds = Object.keys(secaZones).filter(
  (zoneId) =>
    secaZones[zoneId].currentSOXLimitPercent === undefined ||
    secaZones[zoneId].currentSOXLimitPercent < soxPercentLimits.imoGlobalLimit
);
export { secaZoneLocationIds as SECA_ZONES_LOCATION_IDS };

export const isSecaZoneWaypoint = (waypoint) => waypoint.locationId in secaZones;

export interface IWaypoint {
  name: string;
  locationId: UUID;
}

export interface IStrait extends IWaypoint {}
export interface IPiracyZone extends IWaypoint {}
export interface ISecaZone extends IWaypoint {
  isObsolete: boolean;
}

export interface ICanal extends IWaypoint {
  costData?: Array<{
    vesselDwt: number,
    costBallast: number,
    costLaden: number,
    lastUpdated: Date,
  }>;
}

export interface IRoutingAvoidZone extends IWaypoint {}

type KnownWaypoint = IPiracyZone | ISecaZone | IStrait | ICanal | IRoutingAvoidZone;

export function getWaypoint(waypointId): KnownWaypoint {
  const waypointResult = getKnownWaypointOrNull(waypointId);

  if (waypointResult === null) throw new Error(`Cannot find waypoint of ${waypointId}`);
  return waypointResult;
}

export function getKnownWaypointOrNull(waypointId): KnownWaypoint | null {
  return allowedWaypointsList[waypointId] || null;
}

const allowedWaypointsList = {
  ...canals,
  ...piracyZones,
  ...secaZones,
  ...straits,
  ...routingAvoidZones,
};

export function isAllowedWaypoint(waypointId) {
  return getKnownWaypointOrNull(waypointId) !== null;
}
