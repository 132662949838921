import React from 'react';
import { useDebouncedWindowResize } from 'hooks/window-resize';
import { useDOMEvent } from 'hooks/dom-event';

const AllowedDirection = {
  NONE: 0,
  LEFT: 0x1,
  RIGHT: 0x2,
  // LEFT and RIGHT (LEFT | RIGHT)
  BOTH: 0x3,
};

const canSwipeInDirection = (allowedDirections, direction) =>
  (allowedDirections & direction) === direction;

const defaults = {
  mouseWheelIncrement: 10,
  swipeIncrement: 10,
  snapToIncrement: false,
};

// this has been refactored into a separate hook to signify a reusable bit
// of functionality, however, more thought can be put into this perhaps
// to elevate into a separate indepedent hook
function useTranslateMouseWheelToHorizontalScroll(
  containerDomNodeRef,
  callback,
  mouseWheelIncrement
) {
  const handleMouseWheel = React.useCallback(
    (e) => {
      if (e.deltaY > 0) {
        containerDomNodeRef.current.scrollLeft += mouseWheelIncrement;
        callback(e.deltaY);
        return;
      }
      containerDomNodeRef.current.scrollLeft -= mouseWheelIncrement;
      callback(e.deltaY);
    },
    [containerDomNodeRef.current]
  );

  useDOMEvent(containerDomNodeRef, 'mousewheel', handleMouseWheel);
}

export function useHorizontalScroll(containerDomNodeRef, opts = defaults) {
  const { mouseWheelIncrement, swipeIncrement, snapToIncrement } = opts || defaults;
  const [allowedDirections, setAllowedDirections] = React.useState(AllowedDirection.NONE);

  const timeoutRef = React.useRef();

  const updateAllowedDirections = React.useCallback(() => {
    if (!containerDomNodeRef.current) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = containerDomNodeRef.current;

    // allowing for 2px of variation between the two values.
    // this is to account for when users zoom-in/out which introduces
    // sometimes fractions into the values which leads to failing equality.
    if (Math.abs(scrollWidth - clientWidth) < 2) {
      setAllowedDirections(AllowedDirection.NONE);
      return;
    }

    if (scrollLeft === 0) {
      setAllowedDirections(AllowedDirection.RIGHT);
      return;
    }

    if (containerDomNodeRef.current.scrollLeft + clientWidth === scrollWidth) {
      setAllowedDirections(AllowedDirection.LEFT);
      return;
    }

    setAllowedDirections(AllowedDirection.BOTH);
  }, [containerDomNodeRef.current]);

  useDebouncedWindowResize(updateAllowedDirections);

  // this ensures that the allowed directions are checked
  // whenever the hook gets called.
  // TODO: investigate a way such that this is only called
  // when needed. i.e. add dependencies list to trigger selectively.
  React.useEffect(updateAllowedDirections);

  const adjust = React.useCallback(() => {
    if (!containerDomNodeRef.current) {
      return;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (!snapToIncrement) {
        updateAllowedDirections();
        return;
      }
      const halfIncrement = swipeIncrement / 2;
      const offset = containerDomNodeRef.current.scrollLeft % swipeIncrement;
      if (offset !== 0) {
        const newOffset = offset > halfIncrement ? swipeIncrement - offset : -offset;
        if (
          containerDomNodeRef.current.scrollLeft +
            Math.abs(newOffset) +
            containerDomNodeRef.current.clientWidth >
          containerDomNodeRef.current.scrollWidth
        ) {
          containerDomNodeRef.current.scrollLeft = containerDomNodeRef.current.scrollWidth;
        } else {
          containerDomNodeRef.current.scrollLeft += newOffset;
        }
      }
      updateAllowedDirections();
    }, 250);
  }, [snapToIncrement, containerDomNodeRef.current]);

  useTranslateMouseWheelToHorizontalScroll(containerDomNodeRef, adjust, mouseWheelIncrement);

  const swipeLeft = React.useCallback(() => {
    if (!containerDomNodeRef.current) {
      return;
    }
    containerDomNodeRef.current.scrollLeft -= swipeIncrement;
    adjust();
  }, [containerDomNodeRef.current]);

  const swipeRight = React.useCallback(() => {
    if (!containerDomNodeRef.current) {
      return;
    }
    containerDomNodeRef.current.scrollLeft += swipeIncrement;
    adjust();
  }, [containerDomNodeRef.current]);

  return {
    swipeLeft,
    canSwipeLeft: canSwipeInDirection(allowedDirections, AllowedDirection.LEFT),
    swipeRight,
    canSwipeRight: canSwipeInDirection(allowedDirections, AllowedDirection.RIGHT),
  };
}
