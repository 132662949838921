import React, { Component, Fragment } from 'react';
import Async from 'react-async';
import VError from 'verror';

import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import EmitNotice from 'components/notices-emitter/emit-notice';
import SuccessBox from 'components/notice-box/success-box';
import ErrorBox from 'components/notice-box/error-box';
import { appRootNoticesEmitter } from 'modules/app-root';
import {
  COPY_WORKBOOK_SUCCESS_NOTICE,
  COPY_WORKBOOK_FAILURE_NOTICE,
} from 'constants/enums/emit-notices';
import * as veService from './veService';
import * as workbookClient from 'api/clients/workbook';
import storePromiseOnCall from 'utilities/functions/store-promise-on-call';
import { getHostNameFromUrlString } from 'utilities/url';
import LinkButton from 'components/link-button';
export class CopyWorkbookButton extends Component {
  constructor(props) {
    super(props);
    this.copyWorkbook = this.copyWorkbook.bind(this);
  }

  copyWorkbook = storePromiseOnCall(
    async () => {
      const sourceWorkbook = this.props.workbook;
      try {
        const workbook = {
          marketSegmentId: sourceWorkbook.marketSegmentId,
        };
        const destinationWorkbookId = await workbookClient.createWorkbook(workbook);

        const sourceUser = (await veService.getUser(sourceWorkbook.userId)).data;
        const workbookName = `${sourceUser.firstName[0]}${sourceUser.lastName}: ${sourceWorkbook.name} - Copy`;
        // TODO consider moving this copy workbook functionality to it's client
        Object.assign(workbook, {
          id: destinationWorkbookId,
          name: workbookName,
          worksheets: [],
          speedAndConsumptionsMode: sourceWorkbook.speedAndConsumptionsMode,
          sortOrderType: sourceWorkbook.sortOrderType,
        });
        await veService.updateWorkbook(destinationWorkbookId, workbook);

        var worksheetIds;
        if (this.props.copyOnlyThisWorksheet) {
          worksheetIds = [this.props.copyOnlyThisWorksheet];
        } else {
          worksheetIds = sourceWorkbook.worksheets.map((w) => w.worksheetId);
        }
        await veService.copyWorksheetsToWorkbook({
          worksheetIds,
          destinationWorkbookId,
          createdFromProgramId: 'SeaCalcUI',
          createdFromOriginId: getHostNameFromUrlString(document.location.origin),
        });

        const destinationWorkbook = (await veService.getWorkbook(destinationWorkbookId)).data;

        if (typeof this.props.onCopied == 'function') {
          this.props.onCopied();
        }
        return destinationWorkbook;
      } catch (err) {
        throw new VError(
          {
            cause: err,
            info: {
              sourceWorkbookId: sourceWorkbook.id,
            },
          },
          'Failed to copy workbook'
        );
      }
    },
    (copyWorkBookPromise) => {
      this.setState({ copyWorkBookPromise });
      if (typeof this.props.hideFun == 'function') {
        this.props.hideFun();
      }
    }
  );

  render() {
    const { workbook } = this.props;
    return (
      <Fragment>
        <Async promise={this.state && this.state.copyWorkBookPromise}>
          <Async.Rejected>
            <EmitNotice type={COPY_WORKBOOK_FAILURE_NOTICE} emitter={appRootNoticesEmitter}>
              {({ dismiss, additionalProps }) => {
                return (
                  <ErrorBox additionalprops={additionalProps}>
                    <h1>Unsuccessful</h1>
                    {`Failed to add workbook: ${workbook.name} (${workbook.worksheets.length}) as your workbook. Please try
                  again`}
                    <IconButton
                      aria-label="Dismiss"
                      icon={iconEnum.Close}
                      className="notice-box__standard-dismiss-button"
                      onClick={dismiss}
                      diagnosticId="CopyWorkbookButton/DismissError"
                    />
                  </ErrorBox>
                );
              }}
            </EmitNotice>
          </Async.Rejected>
          <Async.Fulfilled>
            {(copiedWorkbook) => {
              if (typeof this.props.afterClick == 'function') {
                this.props.afterClick();
              }
              return (
                <EmitNotice
                  type={COPY_WORKBOOK_SUCCESS_NOTICE}
                  emitter={appRootNoticesEmitter}
                  worksheetId={copiedWorkbook.worksheets[0].worksheetId}
                >
                  {({ dismiss, additionalProps, dismissNonActiveWorksheetMessages }) => (
                    <SuccessBox additionalprops={additionalProps}>
                      <h1>Success</h1>
                      {`Workbook: ${copiedWorkbook.name} containing ${copiedWorkbook.worksheets.length} worksheets was added to your workbooks`}
                      <a
                        className="notice-box__button"
                        href={`#/workbook/${copiedWorkbook.id}`}
                        onClick={dismissNonActiveWorksheetMessages}
                      >
                        OPEN
                      </a>
                      <IconButton
                        aria-label="Dismiss"
                        icon={iconEnum.Close}
                        className="notice-box__standard-dismiss-button"
                        onClick={dismiss}
                        diagnosticId="CopyWorkbookButton/DismissSuccessfulCopy"
                      />
                    </SuccessBox>
                  )}
                </EmitNotice>
              );
            }}
          </Async.Fulfilled>
        </Async>
        {!this.props.showLink && (
          <IconButton
            icon={iconEnum.ContentCopy}
            onClick={this.copyWorkbook}
            diagnosticId="CopyWorkbookButton/Copy"
          />
        )}
        {this.props.showLink && (
          <LinkButton
            className={this.props.linkStyle}
            onClick={this.copyWorkbook}
            diagnosticId="CopyWorkbookButton/Copy"
          >
            {this.props.linkText}
          </LinkButton>
        )}
      </Fragment>
    );
  }
}
