import { inboundRouteReducer } from './inbound-route';

export function voyageEntryReducer(
  currentVoyageEntry: IVoyageLegViewModel,
  action: Action
): IVoyageLegViewModel {
  const newInboundRouteOrSame = inboundRouteReducer(currentVoyageEntry.inboundRoute, action);
  if (newInboundRouteOrSame !== currentVoyageEntry.inboundRoute)
    currentVoyageEntry = {
      ...currentVoyageEntry,
      inboundRoute: newInboundRouteOrSame,
    };

  switch (action.type) {
    // #TODOGroupLegActionsToVoyageEntryReducer - move here `VOYAGE_TYPE_CHANGED`, `VOYAGE_GEAR_CHANGED` etc.
    default:
      return currentVoyageEntry;
  }
}
