import { AppInsights } from 'applicationinsights-js';
import SeverityLevel from './app-insights/app-sights-severity-level';
import VError, { MultiError } from 'verror';
import { flatten } from 'flat';
import { getGlobalContextProps } from './get-global-context-props';

class Logger {
  info = (message, props) => {
    // Note: we're not adding `getGlobalContextProps()` here to keep the traffic to minimum, and not leak too much detail (it was added chiefly to allow developers to diagnose issues)

    console.info(message, props);
    AppInsights.trackTrace(message, props, SeverityLevel.Information);
  };
  warn = (message, props) => {
    props = { ...props, ...getGlobalContextProps() };

    console.warn(message, props);
    AppInsights.trackTrace(message, props, SeverityLevel.Warning);
  };

  /**
   * Alias to `error()`.
   * Simlarly how [console.exception() is an alias for console.error(); they are functionally identical.](https://developer.mozilla.org/en-US/docs/Web/API/console/error) )
   */
  exception = (...args) => {
    this.error.apply(this, args);
  };

  /**
   * Logs the information passed, ensuring there's a stacktrace information
   * If `messageOrError` is an Error object, stack trace will point to the place of original `throw`, otherwise, it will only be a stacktrace of the code that called this logging method (this is the same behavior as `console.error`).
   */
  error = (messageOrError, props) => {
    props = { ...props, ...getGlobalContextProps() };

    console.error(messageOrError, props);
    AppInsights.trackException(
      messageOrError,
      /*handledAt (obsolete)*/ null,
      /* properties: */
      // Flatten all subobjects, to log their properties, instead of the "[object Object]" string.
      flatten({
        ...(messageOrError instanceof Error && {
          // For Errors, add any extra information that could have been added using VError:
          ...VError.info(messageOrError),
          ...(VError.cause(messageOrError) // If there's a cause, then there's an original stacktrace that AppInsights won't see. Log it as a property:
            ? { fullStack: VError.fullStack(messageOrError) }
            : undefined),
        }),
        ...(messageOrError instanceof MultiError
          ? { allErrors: messageOrError.errors() }
          : undefined),
        ...props,
      })
    );
  };
}

export default new Logger();
