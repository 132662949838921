import React from 'react';
import _ from 'lodash';
import type { IDaysBreakdownSummaryState, IPortDaysBreakdown } from './selector';
import {
  formatNumberWithNullAsEmpty,
  formatNumberWithNullAndUndefinedAsEmpty,
} from 'utilities/number';
import { isDry } from 'constants/market-segments';

type IProps = {
  daysBreakdownSummaryState: IDaysBreakdownSummaryState,
};

const DaysBreakdownGrid: React.FC<IProps> = ({ daysBreakdownSummaryState }) => {
  const renderPortDays = (marketSegmentId: MarketSegmentId, portDays: IPortDaysBreakdown) => {
    return isDry(marketSegmentId) ? (
      <td>{formatNumberWithNullAsEmpty(portDays.working)}</td>
    ) : (
      <>
        <td>{formatNumberWithNullAsEmpty(portDays.load)}</td>
        <td>{formatNumberWithNullAsEmpty(portDays.load)}</td>
      </>
    );
  };

  return (
    <table className="table-days-breakdown">
      <thead>
        <tr className="table-days-breakdown__summary-row">
          <th />
          <th colSpan="2">Total Days</th>
          <th colSpan="3">Sea Days</th>
          <th colSpan={isDry(daysBreakdownSummaryState.marketSegmentId) ? '2' : '3'}>Port Days</th>
          <th colSpan="2">SECA Days</th>
        </tr>
        <tr className="table-days-breakdown__row">
          <th>Location</th>

          <th>Sea</th>
          <th>Port</th>

          <th>Ballast</th>
          <th>Laden</th>
          <th>Canal</th>

          {isDry(daysBreakdownSummaryState.marketSegmentId) && <th>Working</th>}
          {!isDry(daysBreakdownSummaryState.marketSegmentId) && <th>Load</th>}
          {!isDry(daysBreakdownSummaryState.marketSegmentId) && <th>Disc</th>}
          <th className="border-right">Extra</th>

          <th>SECA</th>
          <th>NON</th>
        </tr>
      </thead>

      <tbody>
        {_.map(daysBreakdownSummaryState.dayBreakdowns, (item) => {
          return (
            <tr key={item.legId} className="table-days-breakdown__row">
              <td>{item.location}</td>

              <td>{formatNumberWithNullAsEmpty(item.totalDays.sea)}</td>
              <td>{formatNumberWithNullAsEmpty(item.totalDays.port)}</td>

              <td>{formatNumberWithNullAsEmpty(item.seaDays.ballast)}</td>
              <td>{formatNumberWithNullAsEmpty(item.seaDays.laden)}</td>
              <td>{formatNumberWithNullAsEmpty(item.seaDays.canal)}</td>

              {isDry(daysBreakdownSummaryState.marketSegmentId) && (
                <td>{formatNumberWithNullAsEmpty(item.portDays.working)}</td>
              )}
              {!isDry(daysBreakdownSummaryState.marketSegmentId) && (
                <td>{formatNumberWithNullAsEmpty(item.portDays.load)} </td>
              )}
              {!isDry(daysBreakdownSummaryState.marketSegmentId) && (
                <td>{formatNumberWithNullAsEmpty(item.portDays.discharge)} </td>
              )}
              <td className="border-right">{formatNumberWithNullAsEmpty(item.portDays.extra)}</td>

              <td>{formatNumberWithNullAsEmpty(item.secaDays.seca)}</td>
              <td>{formatNumberWithNullAsEmpty(item.secaDays.nonSeca)}</td>
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr className="table-days-breakdown__row">
          <td rowSpan="2">Total</td>

          <td>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.totalDays.sea
            )}
          </td>
          <td>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.totalDays.port
            )}
          </td>

          <td>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.seaDays.ballast
            )}
          </td>
          <td>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.seaDays.laden
            )}
          </td>
          <td>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.seaDays.canal
            )}
          </td>

          {isDry(daysBreakdownSummaryState.marketSegmentId) && (
            <td>
              {formatNumberWithNullAndUndefinedAsEmpty(
                daysBreakdownSummaryState.totals.portDays.working
              )}
            </td>
          )}
          {!isDry(daysBreakdownSummaryState.marketSegmentId) && (
            <td>
              {formatNumberWithNullAndUndefinedAsEmpty(
                daysBreakdownSummaryState.totals.portDays.load
              )}
            </td>
          )}
          {!isDry(daysBreakdownSummaryState.marketSegmentId) && (
            <td>
              {formatNumberWithNullAndUndefinedAsEmpty(
                daysBreakdownSummaryState.totals.portDays.discharge
              )}
            </td>
          )}
          <td className="border-right">
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.portDays.extra
            )}
          </td>

          <td>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.secaDays.seca
            )}
          </td>
          <td>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.totals.secaDays.nonSeca
            )}
          </td>
        </tr>
        <tr className="table-days-breakdown__summary-row footer">
          <td colSpan="2">
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.phaseTotals.totalDays
            )}
          </td>
          <td colSpan="3">
            {formatNumberWithNullAndUndefinedAsEmpty(daysBreakdownSummaryState.phaseTotals.seaDays)}
          </td>
          <td colSpan={isDry(daysBreakdownSummaryState.marketSegmentId) ? '2' : '3'}>
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.phaseTotals.portDays
            )}
          </td>
          <td colSpan="2">
            {formatNumberWithNullAndUndefinedAsEmpty(
              daysBreakdownSummaryState.phaseTotals.secaDays
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DaysBreakdownGrid;
