import React from 'react';
import { PanelSection } from 'components/panel';
import { Subheader } from 'components/headers';
import ProgressBar from 'components/progress-bar';
import { Checkbox } from 'components/checkbox';
import ProgressBarSize from 'components/progress-bar/size';
import { WarningIcon } from 'components/icons';

import './styles.scss';

class Waypoint extends React.PureComponent {
  state = {
    isUpdating: false,
  };

  onChange = async (value) => {
    this.setState({
      isUpdating: true,
    });
    await this.props.onChange(this.props.waypoint.locationId, !this.props.waypoint.avoid);
    this.setState({
      isUpdating: false,
    });
  };

  render() {
    const { waypoint, isObsolete } = this.props;

    return (
      <div
        className="waypoints__waypoint"
        data-unavoidable={waypoint.unavoidable}
        data-obsolete={isObsolete}
      >
        <Checkbox
          onChange={this.onChange}
          value={!waypoint.avoid}
          disabled={
            this.state.isUpdating ||
            (waypoint.unavoidable &&
              /*  Don't disable if `avoid` is true, to allow the user to get out of the 'partly avoided' situation. `unavoidable && avoid` means that the waypoint was actually successfuly avoided in some legs, but unsuccessfuly in others. See #WaypointsAvoidedInOnlySomeLegs for how this can happen.*/
              !waypoint.avoid)
          }
          label={
            this.state.isUpdating ? (
              <span className="waypoint-label">
                <span className="label-wrapper">
                  <WaypointName isObsolete={isObsolete} name={waypoint.name} />
                </span>
                <span className="progress-wrapper">
                  <ProgressBar size={ProgressBarSize.XSMALL} />
                </span>
              </span>
            ) : (
              <WaypointName isObsolete={isObsolete} name={waypoint.name} />
            )
          }
          id={waypoint.locationId}
        />
      </div>
    );
  }
}

const WaypointName = ({ isObsolete, name }) => {
  return (
    <>
      {isObsolete ? <WarningIcon className="voyage-caution-icon" /> : null}
      {name}
    </>
  );
};

const Waypoints = ({ onWaypointChange, waypoints, className }) => {
  const handleWaypointChange = React.useCallback(
    async (waypointLocationId, isChecked) => {
      if (onWaypointChange) {
        return onWaypointChange(waypointLocationId, isChecked);
      }
    },
    [onWaypointChange]
  );

  const showWaypoints = waypoints.length > 0;

  return (
    showWaypoints && (
      <PanelSection className={className}>
        <Subheader className="margin-bottom-micro" text="Travelling through" />
        <div className="waypoints-wrapper">
          {waypoints
            .sort((x, y) => {
              const nameX = x.name.toLowerCase(),
                nameY = y.name.toLowerCase();
              if (nameX < nameY) {
                return -1;
              }
              if (nameX > nameY) {
                return 1;
              }
              return 0;
            })
            .map((waypoint, index) => (
              <Waypoint
                waypoint={waypoint}
                key={waypoint.locationId}
                onChange={handleWaypointChange}
                isObsolete={waypoint.isObsolete}
              />
            ))}
        </div>
      </PanelSection>
    )
  );
};

export default Waypoints;
