import React from 'react';

import { nameColumnId } from 'modules/search-panel/components/workbooks-grid/default-grid-options';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

export const RenameWorkbookNameButton = (props) => {
  const handleRenameWorkbook = () => {
    props.api.stopEditing();
    props.api.startEditingCell({
      rowIndex: props.node.rowIndex,
      colKey: nameColumnId,
    });
  };
  return (
    <IconButton
      icon={iconEnum.ModeEdit}
      onClick={handleRenameWorkbook}
      diagnosticId="RenameWorkbookNameButton/Rename"
    />
  );
};
