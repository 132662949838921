/**
 * Creates a promise with a `controller`, thanks to which the promise's `reject/resolve` can happen in a branch of the call-tree that is different than the promise's constructor call.
 */
export function createControllablePromise(): {
  promise: Promise,
  controller: {
    resolve: (result: any) => void,
    reject: (error: Error) => void,
  },
} {
  var controller;
  const promise = new Promise((resolve, reject) => {
    controller = { resolve: resolve, reject: reject };
  });
  return {
    promise: promise,
    controller: controller,
  };
}
