import * as React from 'react';
import logger from 'diagnostics/logger';

type CleanupFunction = () => void;
type ContentSetter = (content: React.Node) => void;

interface IPortalsManager {
  /**
   * Register a portal into the controller
   * @param {string} name the name of the portal
   * @param {ContentSetter} setContent a setter function to set the content of the portal
   */
  registerPortal(name: string, setContent: ContentSetter): CleanupFunction;
  /**
   * Subscribe to addition of specific portals
   * this is useful in cases where the `<PortalContent>`
   * becomes available before the `<Portal>` itself.
   * @param {string} name
   * @param {React.Node} content the content of the portal
   */
  setPortalContent(name: String, content: React.Node): CleanupFunction;
}

/**
 * Manages registration and replacement of Portal content within the `<PortalContainer>`
 * context.
 */
class PortalsManager implements IPortalsManager {
  /**
   * A key-value dictionary in which the key is the name of the portal
   * and the value is a setter function to set the content of that portal.
   */
  portals: Map<string, ContentSetter>;

  /**
   * A dictionary in which the key is the portal name and the value
   * is a callback to notify the `<PortalContent>` that the corresponding `<Portal>`
   * has been added to the DOM tree and ready to receive content changes.
   * This is a more dynamic approach to address some edge cases in which the `<Portal>`
   * itself become available after the `<PortalContent>`.
   */
  portalContents: Map<string, React.Node>;

  constructor() {
    this.portals = new Map();
    this.portalContents = new Map();
  }

  registerPortal(name: string, setContent: ContentSetter) {
    if (this.portals.has(name)) {
      logger.warn(`Portal ${name} is already registered in this PortalsContainer context. 
      This could be a case of duplication.`);
    }

    this.portals.set(name, setContent);

    if (this.portalContents.has(name)) {
      const portalContent = this.portalContents.get(name);
      setContent(portalContent);
    }

    return () => {
      this.portals.delete(name);
    };
  }

  remove(name: string) {
    delete this.portals.delete(name);
  }

  _updatePortalContent(name: string, content: React.Node) {
    if (this.portals.has(name)) {
      this.portals.get(name)(content);
      return true;
    }
    return false;
  }

  setPortalContent(name: string, portalContent: React.Node) {
    if (this.portalContents.has(name)) {
      throw new Error(`Portal content for ${name} has already been set.`);
    }
    this._updatePortalContent(name, portalContent);
    this.portalContents.set(name, portalContent);
    return () => {
      this.portalContents.delete(name);
      this._updatePortalContent(name, null);
    };
  }
}

const PortalContext: React.Context<IPortalContext> = React.createContext(null);

/**
 * A wrapper that uses React Context API to provide a `PortalManager` instance
 */
export function PortalsContainer({ children }) {
  const ref = React.useRef(new PortalsManager());
  return <PortalContext.Provider value={ref.current}>{children}</PortalContext.Provider>;
}

/**
 * Defines a `Portal`. A portal is just a placeholder component that by default
 * renders whatever is passed to it as `children`, unless its associated content
 * is set by `<PortalContent>`.
 */
export function Portal({ name, children }: { name: string, children?: any }) {
  const [content, setContent] = React.useState(null);
  const context = React.useContext(PortalContext);

  React.useEffect(() => {
    return context.registerPortal(name, setContent);
  }, [context]);

  return content || children || null;
}

/**
 * A wrapper component to replace a given portal's content.
 * Whatever is passed as children to the `PortalContent` will
 * replace the content within the target `Portal`.
 * The component itself doesn't render anything.
 */
export function PortalContent({ name, children }) {
  const context = React.useContext(PortalContext);
  React.useEffect(() => {
    return context.setPortalContent(name, children);
  }, [name, children]);

  return null;
}
