import guid from 'utilities/guid';
import * as actionTypes from 'constants/action-types/worksheet/vessel';
import { applyNewSpeedAndCons, resetSpeedAndCons } from '../speed-and-consumptions';
import { toDate } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { createEmptyVessel } from 'reducers/worksheet/vessels/vessel';
import { VOYAGE_CONSUMPTION_OVERRIDE_QTY_SET } from 'constants/action-types/worksheet/voyage';
import { Vessel } from 'modules/vessel/vessel-detail/types';
import type {
  VesselUpdatedAction,
  OpenPosition,
  OpenPositionUpdatedAction,
  OpenDate,
  OpenDateUpdatedAction,
  DeadweightUpdatedAction,
  DraftUpdatedAction,
  TpcmiUpdatedAction,
  GrainUpdatedAction,
  ConstantsUpdatedAction,
  BuildDateUpdatedAction,
  ShipyardUpdatedAction,
  BuildCountryUpdatedAction,
  ScrubberTypeUpdatedAction,
  ParcelVoyageToggleUpdatedAction,
} from 'modules/vessel-detail/types';
import type { ApplyNewSpeedAndConsPayload } from './types';
import { getVesselById, postSpeedAndCons } from 'api/clients/vessel';
import * as async from 'async';
import { singleOrThrow } from 'utilities/iterable';
import { ScrubberTypesEnum } from 'constants/enums/scrubber';
import { isDry, isTanker, isWet, isUndefined } from 'constants/market-segments';
import { isTankerIndexVessel } from './../../../constants/tanker-classes';

const isVesselNonEmpty = (vessel: Vessel) =>
  vessel.vesselId !== 0 && isEmpty(vessel.vesselName) === false;

export const vesselUpdated =
  ({
    vessel,
    worksheetId,
    vesselEntryId,
    marketSegmentId,
  }: {
    vessel: Vessel,
    worksheetId: WorksheetId,
    vesselEntryId: VesselEntryId,
    marketSegmentId: MarketSegmentId,
  }) =>
  async (dispatch) => {
    await dispatch(selectVessel(vessel, worksheetId, vesselEntryId));
    if (isVesselNonEmpty(vessel)) {
      try {
        const vesselDto = await getVesselById({
          vesselId: vessel.vesselId,
          marketSegmentId: marketSegmentId,
        });
        await dispatch(setAllVesselData(vesselDto, worksheetId, vesselEntryId, marketSegmentId));
      } catch (error) {
        await dispatch(
          applyNewVesselDetails(createEmptyVesselDetails(), worksheetId, vesselEntryId)
        );

        if (
          isUndefined(marketSegmentId) ||
          isDry(marketSegmentId) ||
          isWet(marketSegmentId) ||
          (isTanker(marketSegmentId) && !isTankerIndexVessel(vessel.vesselId))
        ) {
          await dispatch(
            applyNewOpenPosition(createEmptyOpenPosition(), worksheetId, vesselEntryId)
          );
        }
        await dispatch(applyNewSpeedAndCons(createEmptySpeedAndCons(), worksheetId, vesselEntryId));
        throw error;
      }
    } else {
      await dispatch(resetSpeedAndCons(worksheetId, vesselEntryId));
    }
  };

/**
 * 'All Data' is indicated by the name because 'VesselDetails' doesn't include Speed and Cons or Open Position.
 */
export const setAllVesselData =
  (vesselDto, worksheetId, vesselEntryId, marketSegmentId) => async (dispatch) => {
    await dispatch(
      applyNewVesselDetails(mapToVesselDetailsViewModel(vesselDto), worksheetId, vesselEntryId)
    );

    let applyTankerVesselDetailsPayload = {
      speedAndCons: vesselDto.speedAndCons,
      isTankerIndexVessel: vesselDto.isTankerIndexVessel,
    };

    await dispatch(
      applyTankerVesselDetails(applyTankerVesselDetailsPayload, worksheetId, vesselEntryId)
    );

    let applyNewSpeedAndConsPayload: ApplyNewSpeedAndConsPayload = {
      speedAndCons: vesselDto.speedAndCons,
      scrubber: vesselDto.scrubber,
      marketSegmentId: marketSegmentId,
      isTankerIndexVessel: vesselDto.isTankerIndexVessel,
    };

    await dispatch(applyNewSpeedAndCons(applyNewSpeedAndConsPayload, worksheetId, vesselEntryId));

    if (
      isUndefined(marketSegmentId) ||
      isDry(marketSegmentId) ||
      isWet(marketSegmentId) ||
      (isTanker(marketSegmentId) && !isTankerIndexVessel(vesselDto.vesselId))
    ) {
      await dispatch(
        applyNewOpenPosition(
          mapToOpenPositionViewModel(vesselDto.openPosition),
          worksheetId,
          vesselEntryId
        )
      );
    }
  };

export const refreshOpenPosition = (worksheetId, vesselEntryId) => async (dispatch, getState) => {
  const worksheet = getState().worksheetsById[worksheetId];
  const vessel = singleOrThrow(worksheet.vessels.filter((_) => _.entryId === vesselEntryId));
  if (isVesselNonEmpty({ vesselId: vessel.vesselId, vesselName: vessel.name })) {
    const vesselDto = await getVesselById({
      vesselId: vessel.vesselId,
      marketSegmentId: worksheet.marketSegmentId,
    });
    try {
      await dispatch(
        applyNewOpenPosition(
          mapToOpenPositionViewModel(vesselDto.openPosition),
          worksheetId,
          vesselEntryId
        )
      );
    } catch (error) {
      await dispatch(applyNewOpenPosition(createEmptyOpenPosition(), worksheetId, vesselEntryId));
      throw error;
    }
  }
};

export const refreshAllVesselsOpenPosition = (worksheetId) => async (dispatch, getState) => {
  const { worksheetsById } = getState();
  const worksheet = worksheetsById[worksheetId];
  await async.forEach(
    worksheet.vessels,
    async.asyncify(
      async (vessel) => await dispatch(refreshOpenPosition(worksheet.id, vessel.entryId))
    )
  );
};

export const saveSpeedAndCons =
  (speedAndCons, vesselId, worksheetId, isParcelVoyage: boolean) => async (dispatch, getState) => {
    const marketSegmentId = getState().worksheetsById[worksheetId].marketSegmentId;
    return await postSpeedAndCons(speedAndCons, vesselId, marketSegmentId, isParcelVoyage);
  };

export const refreshSpeedAndCons =
  (vessel: Vessel, worksheetId, vesselEntryId) => async (dispatch, getState) => {
    if (isVesselNonEmpty(vessel)) {
      const marketSegmentId = getState().worksheetsById[worksheetId].marketSegmentId;
      const vesselDto = await getVesselById({
        vesselId: vessel.vesselId,
        marketSegmentId: marketSegmentId,
      });
      try {
        let applyNewSpeedAndConsPayload: ApplyNewSpeedAndConsPayload = {
          speedAndCons: vesselDto.speedAndCons,
          scrubber: vesselDto.scrubber,
          marketSegmentId: marketSegmentId,
          isTankerIndexVessel: vesselDto.isTankerIndexVessel,
        };
        await dispatch(
          applyNewSpeedAndCons(applyNewSpeedAndConsPayload, worksheetId, vesselEntryId)
        );
      } catch (error) {
        await dispatch(applyNewSpeedAndCons(createEmptySpeedAndCons(), worksheetId, vesselEntryId));
        throw error;
      }
    }
  };

export function selectVessel(vessel: Vessel, worksheetId, vesselEntryId): VesselUpdatedAction {
  return {
    vesselEntryId,
    worksheetId,
    payload: vessel,
    type: actionTypes.VESSEL_CHANGED,
  };
}

function mapToVesselDetailsViewModel(vesselDto) {
  const { vesselId, name, openPosition, speedAndCons, ...vesselDetails } = vesselDto;
  return vesselDetails;
}

function mapToOpenPositionViewModel(openPosition) {
  const openPositionLocation = createOpenPositionPayload();
  const openDate = createOpenDatePayload();

  if (openPosition !== null) {
    openPositionLocation.locationId = openPosition.locationId;
    openPositionLocation.locationTypeId = openPosition.locationTypeId;
    openPositionLocation.locationName = openPosition.locationName;
    openPositionLocation.zone = openPosition.zone;
    openPositionLocation.country = openPosition.country;
    openPositionLocation.longitude = openPosition.longitude;
    openPositionLocation.latitude = openPosition.latitude;

    openDate.start = toDate(openPosition.start);
    openDate.end = toDate(openPosition.end);
    openDate.displayText = openPosition.displayText;
  }

  return {
    openPosition: openPositionLocation,
    openDate,
  };
}

function createEmptyVesselDetails() {
  return {
    deadweight: 0,
    draft: 0,
    grain: 0,
    tpcmi: 0,
    buildDate: null,
    shipyard: '',
    buildCountry: '',
    scrubber: {
      typeId: ScrubberTypesEnum.NOSCRUBBER.id,
      pendingTypeId: null,
    },
  };
}

function createEmptySpeedAndCons() {
  return {};
}

function createEmptyOpenPosition() {
  return {
    openPosition: createOpenPositionPayload(),
    openDate: createOpenDatePayload(),
  };
}

const applyNewVesselDetails = (payload, worksheetId, vesselEntryId) => {
  const action = {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_APPLY_NEW_DETAILS,
    payload,
  };

  return action;
};

const applyTankerVesselDetails = (payload, worksheetId, vesselEntryId) => {
  const action = {
    worksheetId,
    vesselEntryId,
    type: VOYAGE_CONSUMPTION_OVERRIDE_QTY_SET,
    payload,
  };

  return action;
};

const applyNewOpenPosition = (payload, worksheetId, vesselEntryId) => {
  const action = {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_APPLY_NEW_OPEN_POSITION,
    payload,
  };

  return action;
};

const createOpenPositionPayload = (): OpenPosition => {
  return {
    locationId: null,
    locationTypeId: null,
    locationName: '',
    zone: '',
    country: '',
    longitude: null,
    latitude: null,
  };
};

const createOpenDatePayload = (
  start: ?Date = null,
  end: ?Date = null,
  displayText: string = ''
): OpenDate => {
  return { start, end, displayText };
};

export function scrubberTypeUpdated(
  payload: ?number,
  worksheetId: string,
  vesselEntryId: UUID
): ScrubberTypeUpdatedAction {
  return {
    worksheetId,
    type: actionTypes.VESSEL_SCRUBBER_CHANGED,
    payload,
    vesselEntryId,
  };
}

export function openPositionUpdated(
  payload: OpenPosition,
  worksheetId,
  vesselEntryId
): OpenPositionUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_OPEN_POSITION_CHANGED,
    payload,
  };
}

export function openPositionPrevPortOfCallIsInEeaUpdated(
  payload: Boolean,
  worksheetId,
  vesselEntryId
): OpenPositionUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_OPEN_POSITION_IS_IN_EEA_CHANGED,
    payload,
  };
}

export function nextPortOfCallIsInEeaUpdated(payload: Boolean, worksheetId, vesselEntryId) {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_NEXT_PORT_OF_CALL_IS_IN_EEA_CHANGED,
    payload,
  };
}

export function openDateUpdated(
  payload: OpenDate,
  worksheetId,
  vesselEntryId
): OpenDateUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_OPEN_DATE_CHANGED,
    payload,
  };
}

export function deadWeightUpdated(
  payload: number,
  worksheetId,
  vesselEntryId
): DeadweightUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_DEADWEIGHT_CHANGED,
    payload,
  };
}

export function parcelVoyageToggleUpdated(
  payload: Boolean,
  worksheetId,
  vesselEntryId
): ParcelVoyageToggleUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_PARCEL_VOYAGE_TOGGLE_CHANGED,
    payload,
  };
}

export function draftUpdated(payload: number, worksheetId, vesselEntryId): DraftUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_DRAFT_CHANGED,
    payload,
  };
}

export function draftUnitChanged(payload, worksheetId, vesselEntryId) {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_DRAFT_UNIT_CHANGED,
    payload: { ...payload, draftUnit: payload.draftUnit.key },
  };
}

export function tpcmiUpdated(payload: number, worksheetId, vesselEntryId): TpcmiUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_TPCMI_CHANGED,
    payload,
  };
}

export function immersionUnitChanged(payload, worksheetId, vesselEntryId) {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_IMMERSION_UNIT_CHANGED,
    payload: { ...payload, immersionUnit: payload.immersionUnit.key },
  };
}

export function grainUpdated(payload: number, worksheetId, vesselEntryId): GrainUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_GRAIN_CHANGED,
    payload,
  };
}

export function grainUnitChanged(payload, worksheetId, vesselEntryId) {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_GRAIN_UNIT_CHANGED,
    payload: { ...payload, grainUnit: payload.grainUnit.key },
  };
}

export function constantsUpdated(
  payload: number,
  worksheetId,
  vesselEntryId
): ConstantsUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_CONSTANTS_CHANGED,
    payload,
  };
}

export function buildDateUpdated(
  payload: ?Date,
  worksheetId,
  vesselEntryId
): BuildDateUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_BUILD_DATE_CHANGED,
    payload,
  };
}

export function shipyardUpdated(
  payload: string,
  worksheetId,
  vesselEntryId
): ShipyardUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_SHIPYARD_CHANGED,
    payload,
  };
}

export function buildCountryUpdated(
  payload: string,
  worksheetId,
  vesselEntryId
): BuildCountryUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.VESSEL_BUILD_COUNTRY_CHANGED,
    payload,
  };
}

/**
 * Adds the `vessel` object to the `vessels` collection (the list of vessels in the worksheet).
 *
 * NOTE: the `vessels` is at the start of the name and this is intended. This is #NamePrefixesAsJsNamespacesForFlatFunctions - intends to group actions that are part of a well known design pattern (add/remove) to show that they are components of that pattern, which also give userspace code a readily unambiguous name, so they don't have to alias it (an approach of using file to group these would produce very ambiguous names like 'add' 'removed').
 * Alternatives considered:
 *   * static class methods (this one won't work work with Redux's action binding)
 *   * using `_` , e.g. `vessels_add` - this is not clear enough that it's a namespace, while the exceptional convention is jarring
 *   * using `$`, e.g. `vessels$add` - this might be a bit more indicative, but still not obvious, while very jarring
 *   * using pure reorder of words - put the action verb further down the name, e.g. `vesselsAdd`, hoping the grouping will be noticed and not broken.
 * a decision for the times while we have Redux and cannot de-anemicise our ViewModels was made to use pure reordering of words (more details in commit message).
 */
export function vesselsAdd({ worksheetId, vessel }) {
  return {
    type: actionTypes.VESSELS_ADD,
    worksheetId,
    payload: vessel,
  };
}

/**
 * Removes the `vessel` with  `vesselId` from the `vessels` collection (the list of vessels in the worksheet).
 *
 * NOTE: the `vessels` is at the start of the name and this is intended. See #NamePrefixesAsJsNamespacesForFlatFunctions
 */
export function vesselsRemove({ worksheetId, vesselId }) {
  return {
    type: actionTypes.VESSELS_REMOVE,
    worksheetId,
    payload: vesselId,
  };
}

export function vesselsMoved({ worksheetId, sourceIndex, destinationIndex }) {
  return {
    type: actionTypes.VESSELS_MOVED,
    worksheetId,
    sourceIndex: sourceIndex,
    destinationIndex: destinationIndex,
  };
}

export const addEmptyVessel =
  ({ worksheetId, vesselEntryId }): AsyncAction<VesselEntryId> =>
  async (dispatch) => {
    const vesselEntryId = guid();
    await dispatch(
      vesselsAdd({
        worksheetId: worksheetId,
        vessel: {
          ...createEmptyVessel(),
          entryId: vesselEntryId,
        },
      })
    );

    return vesselEntryId;
  };

export const setAllVesselDataFromDto =
  ({ vesselDto, worksheetId, vesselEntryId }) =>
  async (dispatch) => {
    await dispatch(
      selectVessel(
        {
          vesselId: vesselDto.vesselId,
          vesselName: vesselDto.name,
        },
        worksheetId,
        vesselEntryId
      )
    );
    await dispatch(setAllVesselData(vesselDto, worksheetId, vesselEntryId));
  };
