export const COPY_WORKBOOK_FAILURE_NOTICE = 'COPY_WORKBOOK_FAILURE_NOTICE';
export const COPY_WORKBOOK_SUCCESS_NOTICE = 'COPY_WORKBOOK_SUCCESS_NOTICE';
export const COPY_WORKSHEET_FAILURE_NOTICE = 'COPY_WORKSHEET_FAILURE_NOTICE';
export const COPY_WORKSHEET_SUCCESS_NOTICE = 'COPY_WORKSHEET_SUCCESS_NOTICE';
export const MOVE_WORKSHEET_FAILURE_NOTICE = 'MOVE_WORKSHEET_FAILURE_NOTICE';
export const MOVE_WORKSHEET_SUCCESS_NOTICE = 'MOVE_WORKSHEET_SUCCESS_NOTICE';
export const ROUTING_API_WARNING_NOTICE = 'ROUTING_API_WARNING_NOTICE';
export const UPDATE_VOYAGE_DUE_TO_DEVIATION = 'UPDATE_VOYAGE_DUE_TO_DEVIATION';
export const UPDATE_WORKBOOK_FAILED_OWNED_BY_SOMEONE_ELSE =
  'UPDATE_WORKBOOK_FAILED_OWNED_BY_SOMEONE_ELSE';
export const BUNKER_PRICES_RETRIEVAL_FAILURE_NOTICE = 'BUNKER_PRICES_RETRIEVAL_FAILURE_NOTICE';
export const COMPARISON_SUMMARY_EXPORT_FAILURE_NOTICE = 'COMPARISON_SUMMARY_EXPORT_FAILURE_NOTICE';

// Dimiss and Update Types
// The below types are special cases to programmatically remove or update banners
export const DISMISS_ALL_NOTICES = 'DISMISS_ALL_NOTICES';
export const DISMISS_THIS_WORKSHEET_NOTICES = 'DISMISS_THIS_WORKSHEET_NOTICES';
export const DISMISS_WORKSHEET_NOTICES_EXCEPT_THIS = 'DISMISS_WORKSHEET_NOTICES_EXCEPT_THIS';
export const DISMISS_NOTICE_BY_ID = 'DISMISS_NOTICE_BY_ID';

export const UPDATE_NOTICE = 'UPDATE_NOTICE';

// The below notices should remain in the notice queue, dismis by clicking close button
export const GENERIC_NOTICES: string[] = [
  COPY_WORKBOOK_FAILURE_NOTICE,
  COPY_WORKBOOK_SUCCESS_NOTICE,
  UPDATE_WORKBOOK_FAILED_OWNED_BY_SOMEONE_ELSE,
];

// Speed and Cons
export const SAVE_SPEED_AND_CONS_SUCCESS_NOTICE = 'SAVE_SPEED_AND_CONS_SUCCESS_NOTICE';
export const SAVE_SPEED_AND_CONS_NO_UPDATE_SUCCESS_NOTICE =
  'SAVE_SPEED_AND_CONS_NO_UPDATE_SUCCESS_NOTICE';
export const SAVE_SPEED_AND_CONS_FAILURE_NOTICE = 'SAVE_SPEED_AND_CONS_FAILURE_NOTICE';

export const VIEW_RATES_INFO_NOTICE = 'VIEW_RATES_INFO_NOTICE';
