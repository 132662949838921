import React from 'react';

import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

export const ExportButton = React.memo(({ isDisabled, onClick, className, diagnosticId }) => (
  <IconButton
    icon={iconEnum.ContentExport}
    className={className}
    disabled={isDisabled}
    onClick={onClick}
    diagnosticId={diagnosticId}
  />
));
