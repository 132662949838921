import './styles.scss';

type SpeedConsDisplayItemProps = {
  label: string,
  value: string,
  unit: string,
};

const SpeedConsDisplayItem: React.FC<SpeedConsDisplayItemProps> = ({ label, value, unit }) => {
  return (
    <div className="speed-display-item">
      <span className="speed-display-item-label">{label}:</span>
      <span className="speed-display-item-value">{value}</span>
      <span className="speed-display-item-value-unit">{unit}</span>
    </div>
  );
};

export default SpeedConsDisplayItem;
