import {
  SET_WORKBOOK,
  WORKBOOK_SET_ALL,
  WORKBOOK_REMOVE_WORKSHEET,
  WORKBOOK_REMOVE,
  SET_ACTIVE_WORKSHEET_ID,
  SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE,
  UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK,
} from 'constants/action-types/workbook';
import { OrderType } from '../../utilities/array-object-sort';

const initialState = {};

export const workbooksReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK:
      return {
        ...state,
        [action.payload.workbookId]: {
          ...state[action.payload.workbookId],
          sortOrderType: action.payload.sortOrderType,
        },
      };
    case SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE:
      return {
        ...state,
        [action.payload.workbookId]: {
          ...state[action.payload.workbookId],
          speedAndConsumptionsMode: action.payload.speedAndConsumptionsMode,
        },
      };
    case SET_WORKBOOK:
      const {
        worksheets /* delibrately ignored to prevent accidental access; worksheets associated with this workbook can found within `worksheetsById` */,
        ...workbookViewModel
      } = action.payload;
      if (state[action.payload.id] && state[action.payload.id].sortOrderType) {
        workbookViewModel.sortOrderType = state[action.payload.id].sortOrderType;
      } else {
        workbookViewModel.sortOrderType = OrderType.NONE;
      }
      return {
        ...state,
        [action.payload.id]: {
          ...workbookViewModel,
        },
      };
    case WORKBOOK_SET_ALL:
      return action.payload.reduce((map, workbook) => {
        const {
          worksheets /* delibrately ignored to prevent accidental access; worksheets associated with this workbook can found within `worksheetsById` */,
          ...workbookViewModel
        } = workbook;
        return {
          ...map,
          [workbook.id]: {
            ...workbookViewModel,
          },
        };
      }, state);
    case SET_ACTIVE_WORKSHEET_ID:
      return {
        ...state,
        [action.payload.workbookId]: {
          ...state[action.payload.workbookId],
          lastViewedWorksheetId: action.payload.worksheetId,
        },
      };
    case WORKBOOK_REMOVE_WORKSHEET:
      return removeWorksheetFromWorkbook(state, action.payload);
    case WORKBOOK_REMOVE:
      return removeWorkbook(state, action.payload);
    default:
      return state;
  }
};

const removeWorksheetFromWorkbook = (state, payload) => {
  const { workbookId, nextWorksheetId } = payload;
  const workbook = state[workbookId];

  if (!workbook) {
    return state;
  }

  return {
    ...state,
    [workbookId]: {
      ...workbook,
      lastViewedWorksheetId: nextWorksheetId,
    },
  };
};

const removeWorkbook = (state, workbookId) => {
  if (!state[workbookId]) {
    return state;
  }
  delete state[workbookId];
  return { ...state };
};
