import stringEnumFromPackage from 'string-enum';

/// Gives us both `foos.foo1` access as well as `for (let foo of foos)`
export type StringEnum<T> = Iterable<T> & {
  [T]: T,
  // No more properties of interest, or [at least not in implementation via `string-enum` package] (https://www.npmjs.com/package/string-enum#special-object-properties)
};

// alter the package's name to be camelCase (only Types should be ProperCase) and
// add helpful type annotation
export default function stringEnum<T: string>(...args: Array<T> | [Array<T>]): StringEnum<T> {
  return stringEnumFromPackage.apply(this, args);
}
