/** A more 'intent revealing' and less 'parenthesis magical' version of what can be achieved with the IIFE `(async () => await someAction())()`.
 Usage:
 ```javascript
 PromiseUtils.fromAsyncBlock(async () => await someAction())
 ```
 
*/
export async function fromAsyncBlock(asyncBlock: () => Promise) {
  return await asyncBlock();
}
