import round from 'lodash/round';
import { fromCubicMetersToCubicFeet, fromCubicFeetToCubicMeters } from 'utilities/units-converter';

export type StowageUnit = {
  key: number,
  label: string,
};

export const CUBIC_METERS_PER_METRIC_TON = { key: 1, label: 'm&sup3;/MT' };
export const CUBIC_FEET_PER_METRIC_TON = { key: 2, label: 'ft&sup3;/MT' };

export const getStowageUnitTypeById = (typeId) => {
  switch (typeId) {
    case CUBIC_METERS_PER_METRIC_TON.key:
      return CUBIC_METERS_PER_METRIC_TON;
    case CUBIC_FEET_PER_METRIC_TON.key:
      return CUBIC_FEET_PER_METRIC_TON;
    default:
      throw new Error(`Unable to parse invalid stowage unit type id ${typeId}`);
  }
};

const StowageUnitTypes = [CUBIC_METERS_PER_METRIC_TON, CUBIC_FEET_PER_METRIC_TON];

export const STOWAGE_NUMBER_OF_DECIMAL_PLACES = 3;
export const SPECIFIC_GRAVITY_NUMBER_OF_DECIMAL_PLACES = 3;

export const convertStowage = (stowage, fromStowageUnit, toStowageUnit) => {
  return fromStowageUnit === toStowageUnit
    ? stowage
    : toStowageUnit === CUBIC_METERS_PER_METRIC_TON
      ? round(fromCubicFeetToCubicMeters(stowage), STOWAGE_NUMBER_OF_DECIMAL_PLACES)
      : round(fromCubicMetersToCubicFeet(stowage), STOWAGE_NUMBER_OF_DECIMAL_PLACES);
};

export default StowageUnitTypes;
