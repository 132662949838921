import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ActionBar, { ActionBarItem } from '../action-bar';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { withPullAppGlobals } from 'components/app-globals-registry';
import { PrimaryNavPortal } from './primary-nav-portals';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './styles.scss';

const IconButtonWithGlobals = withPullAppGlobals(IconButton);

export class ActionBarPrimary extends Component {
  render() {
    return (
      <ActionBar className="action-bar__primary" ref={this.props.actionsPortalRef}>
        <ActionBarItem>
          <IconButtonWithGlobals
            icon={iconEnum.Workbook}
            pullFromGlobals={{
              SEARCH_PANEL_TOGGLE_VISIBLITY_GLOBAL: 'onClick',
            }}
            data-tooltip-content="View workbooks"
            data-tooltip-id="view-workbooks"
            diagnosticId="ActionBarPrimary/ViewWorkbooks"
          />
        </ActionBarItem>
        <ReactTooltip place="bottom" id="view-workbooks" className="ve-tooltip-default" noArrow />
        <PrimaryNavPortal />
        {/* NOTE: More items are rendered here using the `actionsPortalRef` */}
      </ActionBar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const ActionBarPrimaryContainer = connect(null, mapDispatchToProps)(ActionBarPrimary);

export default ActionBarPrimaryContainer;
