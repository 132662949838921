import React from 'react';
import { Portal, PortalContent } from 'components/portals';

export function StageRightPortal({ children }) {
  return <Portal name="stage-right">{children}</Portal>;
}

export function StageRightPortalContent({ children }) {
  return <PortalContent name="stage-right">{children}</PortalContent>;
}
