import { getActiveWorkbookId } from 'common-selectors/get-active-workbook-id';
import { getActiveWorkbook } from 'common-selectors/get-active-workbook';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { getActiveVesselEntryId } from 'common-selectors/get-active-vessel-entry-id';
import { getActiveMarketSegmentId } from '../common-selectors/get-active-marketSegmentId';

export function getGlobalContextProps() {
  // Use `require` for a late coupling, not to create a circular reference. Browsers cope with it just fine, but they cause Jest (or Node) to serve `undefineds` under voyageReducer.
  const { default: store } = require('store');

  const state = store.getState();
  if (!state) return {};

  return {
    activeWorkbookId: getActiveWorkbookId(state),
    activeWorkbookCurrentSpeedAndConsumptionsMode: (getActiveWorkbook(state) || {})
      .speedAndConsumptionsMode,
    activeWorksheetId: getActiveWorksheetId(state),
    activeVesselEntryId: getActiveVesselEntryId(state),
    activeStageLeftPanel: state.appMetadata && state.appMetadata.stageLeftPanel,
    activeStageRightPanel: state.appMetadata && state.appMetadata.stageRightPanel,
  };
}

export function getGlobalContextMarketSegmentId() {
  const { default: store } = require('store');

  const state = store.getState();
  if (!state) return {};

  return getActiveMarketSegmentId(state);
}
