import { createSelector } from 'reselect';
import _ from 'lodash';
import { getActiveCalculationResult } from 'common-selectors/get-active-calculation-result';
import { isCanalPhase, isPortPhase, isSeaPhase } from 'constants/enums/phase-type';
import { vesselActivityTypes } from 'constants/enums/vessel-activity-type';
import { getActiveVesselLegsWithInboundRoute } from 'common-selectors/get-active-vessel-legs-with-inbound-route';
import type { MarketSegmentId } from 'constants/market-segments';

export type ITotalDaysBreakdown = {
  port: number,
  sea: number,
};

export type IPortDaysBreakdown = {
  load?: number,
  discharge?: number,
  extra?: number,
  working?: number,
};

export type ISeaDaysBreakdown = {
  ballast: number,
  laden: number,
  canal: number,
};

export type ISECADaysBreakdown = {
  seca: number,
  nonSeca: number,
};

export type IDaysBreakdownSummary = {
  location: string,
  legId: string,
  totalDays: ITotalDaysBreakdown,
  portDays: IPortDaysBreakdown,
  seaDays: ISeaDaysBreakdown,
  secaDays: ISECADaysBreakdown,
};

export type IPhaseTotals = {
  totalDays: number,
  seaDays: number,
  portDays: number,
  secaDays: number,
};

export type ITotals = {
  totalDays: ITotalDaysBreakdown,
  portDays: IPortDaysBreakdown,
  seaDays: ISeaDaysBreakdown,
  secaDays: ISECADaysBreakdown,
};

export type IDaysBreakdownSummaryState = {
  dayBreakdowns: IDaysBreakdownSummary[],
  totals: ITotals,
  phaseTotals: IPhaseTotals,
  marketSegmentId: MarketSegmentId,
};

export const selector = createSelector(
  (state) => state.worksheetsById[state.activeWorksheetId],
  getActiveCalculationResult,
  getActiveVesselLegsWithInboundRoute,
  (worksheet, calculationSummary, activeVesselLegsWithInboundRoute) => {
    let dayBreakdowns = calculateDaysBreakdownSummaryByLeg(worksheet, calculationSummary);
    let totals = calculateTotals(dayBreakdowns);

    let result: IDaysBreakdownSummaryState = {
      dayBreakdowns: dayBreakdowns,
      totals: totals,
      phaseTotals: calculatePhaseTotals(totals, dayBreakdowns.length),
      marketSegmentId: worksheet.marketSegmentId,
    };

    return result;
  }
);

const calculateDaysBreakdownSummaryByLeg = (
  worksheet: IWorksheetViewModel,
  calculationSummary: ICalculationViewModel
): IDaysBreakdownSummary[] => {
  let result: IDaysBreakdownSummary[] = _.map(
    _.groupBy(calculationSummary.phases, 'legId'),
    (phases: IResponseTimeAtPhaseBreakdown[], key) => {
      let portDaysExtra = 0,
        portDaysDischarge = 0,
        portDaysLoad = 0,
        seaDaysCanal = 0,
        seaDaysLaden = 0,
        seaDaysBallast = 0,
        daysInSECA = 0,
        daysNonSECA = 0;

      _.each(phases, (phaseItem) => {
        if (isPortPhase(phaseItem.phaseType)) {
          if (
            phaseItem.vesselActivityType === vesselActivityTypes.maneuvering ||
            phaseItem.vesselActivityType === vesselActivityTypes.idle
          ) {
            portDaysExtra += phaseItem.portDaysManeuvering + phaseItem.portDaysIdle;
          }

          if (phaseItem.vesselActivityType === vesselActivityTypes.discharging) {
            portDaysDischarge += phaseItem.portDaysWorking;
          }

          if (phaseItem.vesselActivityType === vesselActivityTypes.loading) {
            portDaysLoad += phaseItem.portDaysWorking;
          }
        }

        if (isCanalPhase(phaseItem.phaseType)) {
          seaDaysCanal += phaseItem.canalTransit + phaseItem.canalWaiting;
        }

        if (isSeaPhase(phaseItem.phaseType)) {
          if (phaseItem.isLaden) {
            seaDaysLaden += phaseItem.seaDays + phaseItem.seaDaysWeatherFactor;
          }
          if (!phaseItem.isLaden) {
            seaDaysBallast += phaseItem.seaDays + phaseItem.seaDaysWeatherFactor;
          }
        }

        if (phaseItem.inSeca) {
          daysInSECA +=
            phaseItem.seaDays +
            phaseItem.seaDaysWeatherFactor +
            phaseItem.canalTransit +
            phaseItem.canalWaiting +
            phaseItem.portDaysIdle +
            phaseItem.portDaysManeuvering +
            phaseItem.portDaysWorking;
        } else {
          daysNonSECA +=
            phaseItem.seaDays +
            phaseItem.seaDaysWeatherFactor +
            phaseItem.canalTransit +
            phaseItem.canalWaiting +
            phaseItem.portDaysIdle +
            phaseItem.portDaysManeuvering +
            phaseItem.portDaysWorking;
        }
      });

      let location = _.find(worksheet.voyage.legs, (item) => item.id === key);
      let result: IDaysBreakdownSummary = {
        legId: key,
        location: location ? location.name : null,
        portDays: {
          discharge: portDaysDischarge,
          extra: portDaysExtra,
          load: portDaysLoad,
          working: portDaysDischarge + portDaysLoad,
        },
        seaDays: {
          canal: seaDaysCanal,
          ballast: seaDaysBallast,
          laden: seaDaysLaden,
        },
        secaDays: { nonSeca: daysNonSECA, seca: daysInSECA },
        totalDays: {
          port: portDaysDischarge + portDaysLoad + portDaysExtra,
          sea: seaDaysCanal + seaDaysBallast + seaDaysLaden,
        },
      };
      return result;
    }
  );
  return result;
};

const calculateTotals = (dayBreakdowns: IDaysBreakdownSummary[]): ITotals => {
  let result: ITotals = {
    portDays: { discharge: null, extra: null, load: null, working: null },
    seaDays: { canal: null, ballast: null, laden: null },
    secaDays: { nonSeca: null, seca: null },
    totalDays: { port: null, sea: null },
  };

  _.each(dayBreakdowns, (breakdown) => {
    result.totalDays.port += breakdown.totalDays.port;
    result.totalDays.sea += breakdown.totalDays.sea;

    result.portDays.discharge += breakdown.portDays.discharge;
    result.portDays.extra += breakdown.portDays.extra;
    result.portDays.load += breakdown.portDays.load;
    result.portDays.working += breakdown.portDays.working;

    result.seaDays.canal += breakdown.seaDays.canal;
    result.seaDays.ballast += breakdown.seaDays.ballast;
    result.seaDays.laden += breakdown.seaDays.laden;

    result.secaDays.nonSeca += breakdown.secaDays.nonSeca;
    result.secaDays.seca += breakdown.secaDays.seca;
  });

  return result;
};

const calculatePhaseTotals = (totals: ITotals, daysBreakdownsLength: number): IPhaseTotals => {
  return {
    totalDays: daysBreakdownsLength > 0 ? totals.totalDays.sea + totals.totalDays.port : null,
    seaDays:
      daysBreakdownsLength > 0
        ? totals.seaDays.ballast + totals.seaDays.laden + totals.seaDays.canal
        : null,
    portDays:
      daysBreakdownsLength > 0
        ? totals.portDays.load + totals.portDays.discharge + totals.portDays.extra
        : null,
    secaDays: daysBreakdownsLength > 0 ? totals.secaDays.seca + totals.secaDays.nonSeca : null,
  };
};
