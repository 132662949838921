import React from 'react';
import DropDown from 'components/dropdown';
import { Location } from 'modules/voyage/components/location';
import { ALL_FUEL_GRADES_IN_UI_ORDER, getFuelGradeById } from 'constants/enums/fuel-grades';
import LegTypes, { DISCHARGE, LOAD, isLoadOrDischargePort } from 'constants/enums/voyage-leg';
import './styles.scss';
import { MaterialIconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import {
  speedMaxPrecision,
  speedMaxValue,
  speedMinValue,
} from 'modules/vessel/speed-and-consumptions/components/speed-input';
import { singleOrThrow } from 'utilities/iterable';
import CalcInput from 'components/calc-input';
import {
  consumptionMaxPrecision,
  consumptionMaxValue,
  consumptionMinValue,
} from 'modules/vessel/speed-and-consumptions/components/moving-speed-and-cons';
import { isFeatureEnabled } from 'config/feature-control';
import { validationLevels } from 'components/numeric-input';
import { formatNumberWithAutoMantissa } from 'utilities/number';
import CalcInputPlaceHolder from 'components/calc-input-placeholder';
import { addDaysToDate, formatDateDDMMMYYWithNullAsEmpty } from 'utilities/time';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import isNil from 'lodash/isNil';
import { format, toDate } from 'date-fns';

export const VoyageLeg: FC = ({
  activeVessel,
  worksheetId,
  voyageLeg,
  calculationResult,
  marketSegmentId,
  onLocationChange,
  onIsInSecaChange,
  onIsInEeaChange,
  onSpeedChange,
  onSecaDistanceChange,
  onTotalDistanceChange,
  onSecaFuelTypeChange,
  onMainFuelTypeChange,
  onConsumptionChanged,
  onConsumptionOverrideQtyChanged,
  onPortDaysChange,
  onPortCostsChange,
  onLocationDelete,
  onTypeChange,
  onCargoQuantityChanged,
  isLastLoadOrDischargePort,
  totalLoadCargo,
  totalDischargeCargo,
}) => {
  const speedAndCons = activeVessel.speedAndConsumptions;
  const isWorkingPortPhase = voyageLeg.type === LOAD || voyageLeg.type === DISCHARGE;
  const locationId = voyageLeg?.locationId;
  const locationName = voyageLeg?.name;
  const isMainEngine = true;

  const getLegTypeTitle = (type) => {
    switch (type.key) {
      case LOAD.key:
        return 'Load';
      case DISCHARGE.key:
        return 'Discharge';
      default:
        return 'Other';
    }
  };

  // will be used for Field that depends on Type
  const getPlaceHolderClassNames = () => {
    var sufix = '--placeholder';
    let className = 'worksheet-section__property-label';
    return isWorkingPortPhase ? className : className + sufix;
  };

  const getSpeedAndConData = (type) => {
    switch (type.key) {
      case LOAD.key:
        return speedAndCons.ballast;
      case DISCHARGE.key:
        return speedAndCons.laden;
      default:
        return speedAndCons.laden;
    }
  };
  const onSpeedChangedHandler = (speedAndConType) => (speed) => {
    const payload = {
      speedAndConType,
      speed,
    };

    onSpeedChange(payload, worksheetId, activeVessel.entryId);
  };

  const onConsumptionChangedHandler = (speedAndConType) => (consumption) => {
    const payload = {
      speedAndConType,
      isMainEngine,
      consumption,
    };

    onConsumptionChanged(payload, worksheetId, activeVessel.entryId);
  };

  const onConsumptionOverrideQtyChangedHandler = (loadDischargeConsumptionsOverrideQty) => {
    const legId = voyageLeg.id;
    const payload = {
      legId,
      loadDischargeConsumptionsOverrideQty,
      marketSegmentId,
    };
    onConsumptionOverrideQtyChanged(payload, worksheetId);
  };

  const handleLocationDeleted = () => {
    onLocationDelete(voyageLeg.id);
  };

  const inboundRouteVariant = singleOrThrow(voyageLeg.inboundRoute.variants);
  const handleSecaDistanceChanged = (newValue) => {
    onSecaDistanceChange(voyageLeg.id, newValue, inboundRouteVariant);
  };
  const handleTotalDistanceChanged = (newValue) => {
    onTotalDistanceChange(voyageLeg.id, newValue, inboundRouteVariant);
  };

  const getSeaDays = () =>
    calculationResult?.phases
      .filter((phase) => phase.legId === voyageLeg.id && phase.vesselActivityType === 'sailing')
      .reduce((acc, curr) => acc + curr.totalDays, 0) ?? 0;

  const getEta = () => {
    const startDate = activeVessel?.openPosition.openDate.start ?? new Date();
    const uniqueIds = [...new Set(calculationResult?.phases?.map((phase) => phase.legId))];

    const legArrivalDepartureInfo = uniqueIds?.map((id) => {
      const phasesById = calculationResult?.phases?.filter((phase) => phase.legId === id) || [];
      const estimatedDaysAtSea = phasesById
        .filter((phase) => phase.vesselActivityType === 'sailing')
        ?.reduce((acc, curr) => acc + curr.totalDays, 0);
      const portWorkingDays =
        phasesById.find((phase) => phase.legId === id && phase.portDaysWorking !== 0)
          ?.portDaysWorking || 0;
      const waitingDays =
        phasesById.find((phase) => phase.legId === id && phase.portDaysIdle !== 0)?.portDaysIdle ||
        0;
      const etaDate = addDaysToDate(startDate, estimatedDaysAtSea);
      const etdDate = addDaysToDate(etaDate, portWorkingDays + waitingDays);

      return {
        id,
        estimatedDaysAtSea,
        portWorkingDays,
        waitingDays,
        etdDate,
        etaDate,
      };
    });

    legArrivalDepartureInfo?.forEach((leg, index) => {
      if (index !== 0) {
        leg.etaDate = addDaysToDate(
          legArrivalDepartureInfo[index - 1]?.etdDate,
          leg.estimatedDaysAtSea
        );
        leg.etdDate = addDaysToDate(
          legArrivalDepartureInfo[index]?.etaDate,
          leg.portWorkingDays + +leg.waitingDays
        );
      }
    });
    const etaForSpecificLeg =
      legArrivalDepartureInfo?.find((leg) => leg.id === voyageLeg.id)?.etaDate || null;

    return etaForSpecificLeg;
  };

  const getEtaTooltip = (date: Date): string => {
    return isNil(getEta()) ? '' : format(toDate(getEta()), "dd MMM yy 'at' HH:mm:ss");
  };
  const handlePortDaysChanged = (newValue) => {
    onPortDaysChange(voyageLeg, newValue);
  };

  const handlePortCostsChanged = (newValue) => {
    onPortCostsChange(voyageLeg.id, newValue);
  };

  const handleIsInSecaChanged = (payload) => {
    onIsInSecaChange(payload.isInSeca, payload.isInSecaOverridden, voyageLeg.id, worksheetId);
  };

  const handleIsInEeaChanged = () => {
    onIsInEeaChange(!voyageLeg.isInEea, voyageLeg.id, worksheetId);
  };
  const handleTypeChanged = (value) => {
    onTypeChange(voyageLeg.id, value, marketSegmentId);
  };

  const handleCargoSizeChanged = (value) => {
    onCargoQuantityChanged(voyageLeg.id, value);
  };

  const totalLoadAndDischargeCargoDifference = totalLoadCargo - totalDischargeCargo;

  return (
    <div
      style={{ marginLeft: isFeatureEnabled('wetCargoLitePortsOfCall') ? '' : '18px' }}
      className="voyage-leg__container"
    >
      {isFeatureEnabled('wetCargoLitePortsOfCall') && (
        <div className="route-data__row-cell route-data--grip">
          <label className="worksheet-section__property-label">&nbsp;</label>
          <div className="voyage-data__row-cell__grip-icon has-icon icon--grip" />
        </div>
      )}
      <div className="worksheet-section__property">
        {isFeatureEnabled('wetCargoLiteMultiLeg') ? (
          <div className="worksheet-section__property-label worksheet-section__property-label--immersion">
            <DropDown
              id="legType"
              items={LegTypes}
              selectedItem={voyageLeg.type}
              onChange={handleTypeChanged}
              className="legType-label-dropdown"
              asLabel
              useExtendedLabel
              useSeparatorLine
            />
          </div>
        ) : (
          <label className="worksheet-section__property-label">
            {`${getLegTypeTitle(voyageLeg.type)} Location`}
          </label>
        )}
        <Location
          locationId={locationId}
          locationName={locationName}
          isInSeca={voyageLeg.isInSeca}
          isInEea={voyageLeg.isInEea}
          isInSecaOverridden={voyageLeg.isInSecaOverridden}
          marketSegmentId={marketSegmentId}
          isMandatory
          onLocationChange={(newLocationInfo) => onLocationChange(voyageLeg.id, newLocationInfo)}
          onIsInSecaChange={(newValue) => handleIsInSecaChanged(newValue)}
          onIsInEeaChange={handleIsInEeaChanged}
          labelName={`${getLegTypeTitle(voyageLeg.type)} Location`}
          isInEeaVisible={isLoadOrDischargePort(voyageLeg.type)}
        />
      </div>

      {isFeatureEnabled('wetCargoLiteMultiLeg') && (
        <div className="worksheet-section__property">
          <label className={getPlaceHolderClassNames()}>Cargo Size</label>
          {isWorkingPortPhase ? (
            <CalcInput
              userSpecifiedValue={voyageLeg.cargoQuantity}
              tankersMandatory
              maxDecimalDigits={2}
              onInputChange={handleCargoSizeChanged}
              isMandatory
              isNumericType
              validationMessageRefreshValue={totalLoadAndDischargeCargoDifference}
              unit="MT"
              isEditable
              name="Cargo Size"
              className="cargosize-input"
              isPlaceHolder={!isWorkingPortPhase}
              validationOptions={{
                propagateInvalidValueIfFiniteNumber: true,
              }}
              customValidationRules={[
                {
                  validationLevel: validationLevels.error,
                  id: 'dischargedCargoMatchesLoadedQty',
                  shouldFailureStopFurtherRules: false,
                  shouldWarnWhileTyping: false,
                  isValid: () => {
                    return !isLastLoadOrDischargePort || totalLoadCargo === totalDischargeCargo;
                  },
                  getAttemptedValueIsInvalidMessage: () => {
                    return (
                      <div>
                        {`Load ${formatNumberWithAutoMantissa(
                          totalLoadCargo
                        )} and Discharge ${formatNumberWithAutoMantissa(
                          totalDischargeCargo
                        )} do not match.`}

                        <br />
                        {`Difference of ${formatNumberWithAutoMantissa(
                          Math.abs(totalLoadAndDischargeCargoDifference)
                        )}.`}
                      </div>
                    );
                  },
                },
              ]}
            />
          ) : (
            <CalcInputPlaceHolder className="cargosize-input wet-lite-cell-placeholder" />
          )}
        </div>
      )}

      {!isFeatureEnabled('wetCargoLiteMultiLeg') ? (
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Speed</label>
          <CalcInput
            systemSpecifiedValue={getSpeedAndConData(voyageLeg.type)?.speed ?? 0}
            onInputChange={onSpeedChangedHandler(getSpeedAndConData(voyageLeg.type).type)}
            className="speed-input"
            maxDecimalDigits={speedMaxPrecision}
            minValue={speedMinValue}
            maxValue={speedMaxValue}
            showEditButton
            unit="kn"
            name="Speed"
            isMandatory
          />
        </div>
      ) : (
        ''
      )}

      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">SECA Distance</label>
        <CalcInput
          systemSpecifiedValue={inboundRouteVariant.secaDistance}
          onInputChange={handleSecaDistanceChanged}
          className="distance-input"
          maxDecimalDigits={0}
          minValue={0}
          showEditButton
          maxValue={{
            value: inboundRouteVariant.totalDistance,
            name: 'Total Distance',
          }}
          unit="nm"
          name="SECA Distance"
        />
      </div>

      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Total Distance</label>
        <CalcInput
          systemSpecifiedValue={inboundRouteVariant.totalDistance}
          onInputChange={handleTotalDistanceChanged}
          className="distance-input"
          maxDecimalDigits={0}
          minValue={0}
          maxValue={99999}
          unit="nm"
          showEditButton
          name="Total Distance"
        />
      </div>

      {!isFeatureEnabled('wetCargoLiteMultiLeg') ? (
        <>
          <div className="worksheet-section__property">
            <label className="worksheet-section__property-label">Fuel (SECA)</label>
            <DropDown
              id="fuel-seca"
              items={ALL_FUEL_GRADES_IN_UI_ORDER}
              selectedItem={getFuelGradeById(
                activeVessel.speedAndConsumptions.zoneSpecific.seca.fuelGradeId
              )}
              onChange={onSecaFuelTypeChange}
              className="fuel-grade"
              diagnosticId="Location/ChangeMainFuelGrade"
              isTankersDropdown
            />
          </div>
          <div className="worksheet-section__property">
            <label className="worksheet-section__property-label">Fuel (non-SECA)</label>
            <DropDown
              id="fuel-non-seca"
              items={ALL_FUEL_GRADES_IN_UI_ORDER}
              selectedItem={getFuelGradeById(activeVessel.speedAndConsumptions.ballast.fuelGrade)}
              onChange={onMainFuelTypeChange}
              className="fuel-grade"
              diagnosticId="Location/ChangeSecaFuelGrade"
              isTankersDropdown
            />
          </div>
        </>
      ) : (
        ''
      )}

      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Sea Days</label>
        <CalcInput
          systemSpecifiedValue={getSeaDays()}
          className="days-input"
          maxDecimalDigits={2}
          isReadonly
          name="Sea Days"
        />
      </div>

      <div
        className="worksheet-section__property"
        data-tooltip-content={getEtaTooltip()}
        data-tooltip-id="tooltip-eta"
      >
        <label className="worksheet-section__property-label">ETA</label>
        <CalcInput
          systemSpecifiedValue={formatDateDDMMMYYWithNullAsEmpty(getEta())}
          inputMode="text"
          className="eta-input"
          maxDecimalDigits={2}
          isReadonly
          name="ETA"
        />
        <ReactTooltip place="bottom" id="tooltip-eta" className="ve-tooltip-default" noArrow />
      </div>

      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Port Days</label>
        <CalcInput
          userSpecifiedValue={voyageLeg.loadDischargeRate}
          onInputChange={handlePortDaysChanged}
          className="days-input"
          maxDecimalDigits={2}
          minValue={0}
          maxValue={999}
          isEditable
          name="Port Days"
          isMandatory={isWorkingPortPhase}
        />
      </div>

      {!isFeatureEnabled('wetCargoLiteMultiLeg') ? (
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Cons at Sea</label>
          <CalcInput
            systemSpecifiedValue={getSpeedAndConData(voyageLeg.type)?.consumption ?? 0}
            onInputChange={onConsumptionChangedHandler(getSpeedAndConData(voyageLeg.type).type)}
            className="cons-input"
            maxDecimalDigits={consumptionMaxPrecision}
            minValue={consumptionMinValue}
            maxValue={consumptionMaxValue}
            showEditButton
            unit="MT/d"
            name="Cons at Sea"
          />
        </div>
      ) : (
        ''
      )}

      <div className="worksheet-section__property">
        {isFeatureEnabled('wetCargoLiteMultiLeg') ? (
          <label className="worksheet-section__property-label">Port Cons</label>
        ) : (
          <label className="worksheet-section__property-label">{`${getLegTypeTitle(
            voyageLeg.type
          )} Cons`}</label>
        )}
        <CalcInput
          systemSpecifiedValue={voyageLeg.loadDischargeConsumptionsOverrideQty ?? 0}
          onInputChange={onConsumptionOverrideQtyChangedHandler}
          className="cons-input"
          maxDecimalDigits={consumptionMaxPrecision}
          minValue={consumptionMinValue}
          maxValue={consumptionMaxValue}
          showEditButton
          name="Cons"
          unit="MT"
        />
      </div>

      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Port Costs</label>
        <CalcInput
          userSpecifiedValue={voyageLeg.portCost}
          onInputChange={handlePortCostsChanged}
          className="port-costs-input"
          maxDecimalDigits={2}
          trimDecimal={false}
          minValue={0}
          maxValue={9999999.99}
          isEditable
          name="Port Costs"
          unit="$"
          unitPrecedesValue
        />
      </div>

      {isFeatureEnabled('wetCargoLiteMultiLeg') && (
        <div className="worksheet-section__property">
          <MaterialIconButton
            icon={iconEnum.RemoveCircle}
            style={{ display: 'flex', alignItems: 'flex-end' }}
            className="voyage-data--action-delete"
            onClick={handleLocationDeleted}
            diagnosticId="PortRequiresConnect/DeleteLocation"
          />
        </div>
      )}
    </div>
  );
};
