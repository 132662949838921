import {
  receiveOneMessageEvent,
  UsageOptions as ReceiveOneMessageUsageOptions,
} from 'utilities/window-post-message/receive-one-message';
import { isOriginUrlAllowed } from './allowed-origins';
import { sendMessageToWindowOpener } from './send-message-to-window-opener';

/**
 * As per the function name - it returns an 'entry point' request message event for our app that is received from the opener of our window.
 *
 * @param options : Same as `ReceiveOneMessageOptions`, except the `senderPredicate` is not needed (will be ignored if specified).
 *
 * See: `openCalcWithEntryPointViaPostMessage` for this function's counterpart on the 'app entry point invoker' side.
 */
export async function receiveCalcEntryPointMessageEventFromWindowOpener(
  options: ReceiveOneMessageUsageOptions
) {
  // Order is important to avoid a race condition: Attach the listener first, THEN let the other side know we're ready, and only then await the promise.
  const messageEventPromise = receiveOneMessageEvent({
    ...options,
    sourcePredicate: (source) => source === window.opener,
    allowedOriginPredicate: isOriginUrlAllowed,
  });

  sendMessageToWindowOpener({
    targetProgram: 'OPENER_OF__SEA_CALC',
    messageType: 'EVENT__SEA_CALC__READY_TO_RECEIVE_MESSAGES',
    targetOrigin: /*targetOrigin: */ '*', // This is safe - we're merely announcing our readiness to receive the message. The receive of messages will filter the origin.
    data: null,
  });

  return await messageEventPromise;
}
