import isObject from 'lodash/isObject';
import VError from 'verror';

export function getValidObjectFromAxiosJsonResponse(axiosResponse) {
  const data = axiosResponse.data;
  assertAxiosResponseIsValidJson(data);
  return data;
}

function assertAxiosResponseIsValidJson(axiosResponseData) {
  if (
    /* Use lodash `isObject` to make intent clear and [cover `null`](https://stackoverflow.com/a/42250981) */ isObject(
      axiosResponseData
    ) === false
  )
    throw new VError(
      { info: { responseBody: axiosResponseData } },
      `The \`responseBody\` is not an Object. This is likely caused by the service indicating a JSON content-type, but the body not being a valid JSON. See properties of this error for actual data received.`
    );
}
