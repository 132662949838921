import React from 'react';
import classnames from 'classnames';

const CanalHeaderCellData = ({ title }) => (
  <div className="canals-header__data">
    <div className="canals-header__data--title">{title}</div>
    <div className="canals-header__data--breakdown">
      <div className="canals-header__data--breakdown-ballast">Ballast</div>
      <div className="canals-header__data--breakdown-laden">Laden</div>
    </div>
  </div>
);

const CanalsHeader = ({ hasLaden, hasBallast }) => (
  <div
    className={classnames('canals-header', {
      'canals-header--laden': hasLaden,
      'canals-header--ballast': hasBallast,
    })}
  >
    <div className="canals-header__canal">Canal or pilotage</div>
    <CanalHeaderCellData title="Transit (days)" />
    <CanalHeaderCellData title="Waiting (days)" />
    <CanalHeaderCellData title="Cost ($)" />
  </div>
);

export default CanalsHeader;
