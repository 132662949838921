import { exportDetailedCalculationResult } from '../../api/clients/export';
import { getActiveCalculationResult } from '../../common-selectors/get-active-calculation-result';
import { buildDetailedCalculationExportRequest } from '../../api/request-builders/detailed-calculation-export-request-builder';
import { getActiveVessel } from '../../common-selectors/get-active-vessel';
import cloneDeep from 'lodash/cloneDeep';

export const downloadDetailedCalculationExport = () => async (dispatch, getState) => {
  const state = getState();
  const worksheet = state.worksheetsById[state.activeWorksheetId];
  const calculationResult = getActiveCalculationResult(state);
  const vessel = getActiveVessel(state);
  const worksheetCopy = cloneDeep(worksheet);
  // the below code (set api version null to 0) repeated couple of places
  // TODO CODE REFACTOR: create new util function for worksheet and move this below code
  worksheetCopy.voyage.legs.forEach((leg) => {
    leg.inboundRoute.variants.forEach((v) => {
      if (!v.routeRetrievedWithGraphVersion) {
        v.routeRetrievedWithGraphVersion = 0;
      }
    });
  });

  await exportDetailedCalculationResult(
    buildDetailedCalculationExportRequest(worksheetCopy, vessel, calculationResult)
  );
};
