import React from 'react';
import { setLastViewedWorkbook } from 'actions/user-state';
import { loadWorkbook, setActiveWorkbookId } from 'actions/workbook';
import Async from 'react-async';
import { connect } from 'react-redux';
import Workbook from '.';
import CenteredContent from 'components/centered-content';
import WorkbookNotFoundPlaceholder from './no-document-placeholders/workbook-not-found-placeholder';
import ProgressBar from 'components/progress-bar';
import { createSelector } from 'reselect';
import { StageRightPortalContent } from 'modules/stage/right-stage-portal';
import { useTestId } from 'components/testable';
import { fetchComparisonTableSettings } from '../../actions/worksheet-comparison/comparison-table-settings';
export function WorkbookContainer({
  workbookId,
  loadWorkbook,
  setActiveWorkbookId,
  setLastViewedWorkbook,
  fetchComparisonTableSettings,
}) {
  if (!workbookId) {
    throw new Error(`workbookId prop must be defined`);
  }
  const loadWorkbookAsync = React.useCallback(
    async ({ workbookId }) => {
      const workbook = await loadWorkbook(workbookId);
      setActiveWorkbookId(workbookId);
      fetchComparisonTableSettings(workbook.marketSegmentId);

      if (workbook.isEditable) {
        /* No await because updating 'lastViewedWorkbookId' is not critical - user can still use the app. */
        setLastViewedWorkbook(workbookId);
      }
      return workbook;
    },
    [fetchComparisonTableSettings, loadWorkbook, setActiveWorkbookId, setLastViewedWorkbook]
  );

  const testId = useTestId();

  return (
    <Async
      promiseFn={loadWorkbookAsync}
      workbookId={workbookId}
      // key={workbookId} prevents refiring `<Async.Fulfilled>` with an old workbook but a newly unset
      //`worksheetId` (happens on navigating to new workbook) -
      // TODO remove if they address [issue #61](https://github.com/ghengeveld/react-async/issues/61)
      key={workbookId}
    >
      <Async.Fulfilled>
        <Workbook key={workbookId} data-testid={testId} />
      </Async.Fulfilled>
      <Async.Pending>
        <ProgressBar />
        <StageRightPortalContent>
          <ProgressBar />
        </StageRightPortalContent>
      </Async.Pending>
      <Async.Rejected>
        {(err) => {
          if (err.name === 'ERROR_WORKBOOK_NOT_FOUND')
            return (
              <>
                <CenteredContent>
                  <WorkbookNotFoundPlaceholder />
                </CenteredContent>
                <StageRightPortalContent>
                  <CenteredContent />
                </StageRightPortalContent>
              </>
            );
          else throw err;
        }}
      </Async.Rejected>
    </Async>
  );
}

export default connect(
  createSelector(
    (state) => state.appMetadata.stageLeftPanel,
    (state) => state.appMetadata.stageRightPanel,
    (state, ownProps) => {
      return ownProps.workbookId;
    },
    (leftPanel, rightPanel, workbookId) => ({
      leftPanel,
      rightPanel,
      workbookId,
    })
  ),
  /* action props: */ {
    loadWorkbook,
    setLastViewedWorkbook,
    setActiveWorkbookId,
    fetchComparisonTableSettings,
  }
)(WorkbookContainer);
