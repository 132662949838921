import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import { isValidOpenPositionLocationType } from 'modules/voyage/business-model/voyage-locations-sequence';
import { BALLAST_FROM, getVoyageLegTypeById, DISCHARGE } from 'constants/enums/voyage-leg';
import {
  round,
  formatNumberWithAutoMantissa,
  formatNumberWithNullAndZeroAsEmpty,
} from 'utilities/number';
import { isEmptyGuid } from 'utilities/guid';
import { addDaysToDate } from 'utilities/time';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';
import { getActiveVessel } from '../../../common-selectors/get-active-vessel';
import { getActiveVesselLegsWithInboundRoute } from '../../../common-selectors/get-active-vessel-legs-with-inbound-route';

export const selector = createSelector(
  (state) => state.worksheetsById[state.activeWorksheetId],
  getActiveCalculationResult,
  (state) => getActiveVessel(state).openPosition,
  getActiveVesselLegsWithInboundRoute,
  (worksheet, calculationSummary, openPosition, activeVesselLegsWithInboundRoute) => {
    const { totalDistance, time, totalCargoQuantity, totalPortCostsNet } = calculationSummary;

    return {
      legs: formatLegs(
        worksheet,
        activeVesselLegsWithInboundRoute,
        openPosition,
        calculationSummary
      ),
      totalDistance: formatNumberWithNullAndZeroAsEmpty(totalDistance, 0),
      totalDays: formatNumberWithNullAndZeroAsEmpty(time.totalDays, 2),
      totalCargoQuantity: formatCargoQuantity(totalCargoQuantity),
      totalPortCosts: formatNumberWithNullAndZeroAsEmpty(totalPortCostsNet, 2),
    };
  }
);

const hasOpenLocationId = (openPosition) => {
  return (
    isNil(openPosition) === false &&
    isNil(openPosition.id) === false &&
    isEmptyGuid(openPosition.id) === false
  );
};

const formatCargoQuantity = (cargoQuantity) =>
  isNil(cargoQuantity) || cargoQuantity === 0
    ? ''
    : formatNumberWithAutoMantissa(round(cargoQuantity, 3));

const formatLegs = (
  worksheet,
  activeVesselLegsWithInboundRoute,
  openPosition,
  calculationSummary
) => {
  const { voyage } = worksheet;

  const totalDaysAtLegs =
    calculationSummary.time.totalDaysAtLegs && calculationSummary.time.totalDaysAtLegs.length > 0
      ? calculationSummary.time.totalDaysAtLegs
      : null;

  const formattedLegs = [];

  let dateAtLeg: Date = openPosition && openPosition.openDate && openPosition.openDate.start;

  // A bit of a quirk - if the user didn't enter the vessel 'open date' we calculate as if the vessel left now.
  if (
    totalDaysAtLegs /* Quirk in a quirk - we don't want to show the magic if the calculation didn't run, so that it doesn't astonish the user in a worksheet that he hasn't finished filling yet. */
  ) {
    if (!dateAtLeg) dateAtLeg = new Date();
  }

  const isOpenLocationAndFirstPortDifferent = openPosition.id !== voyage.legs[0].locationId;

  if (
    hasOpenLocationId(openPosition) &&
    isValidOpenPositionLocationType(openPosition.locationTypeId) &&
    isOpenLocationAndFirstPortDifferent
  ) {
    formattedLegs.push({
      type: BALLAST_FROM,
      name: openPosition.name,
      date: dateAtLeg,
    });
  }

  activeVesselLegsWithInboundRoute.forEach(({ voyageEntryDetails: leg, inboundRouteVariant }) => {
    const legType = getVoyageLegTypeById(leg.type);

    const timeSpentAtLeg = totalDaysAtLegs && totalDaysAtLegs.find((_) => _.id === leg.id);
    const daysAtLeg = (timeSpentAtLeg && timeSpentAtLeg.totalDays) || 0;
    dateAtLeg = dateAtLeg && addDaysToDate(dateAtLeg, daysAtLeg);

    const cargoQuantityAtVoyageEntry = calculationSummary.cargoQuantityMovedAtVoyageEntries[leg.id];

    let formattedCargoQuantity = '';

    if (!isNil(cargoQuantityAtVoyageEntry)) {
      const cargoQuantity =
        legType === DISCHARGE ? -cargoQuantityAtVoyageEntry : cargoQuantityAtVoyageEntry;

      formattedCargoQuantity = formatCargoQuantity(cargoQuantity);
    }

    formattedLegs.push({
      type: legType,
      name: leg.name,
      date: dateAtLeg,
      distance: formatNumberWithNullAndZeroAsEmpty(
        inboundRouteVariant && inboundRouteVariant.totalDistance,
        0
      ),
      cargoQuantity: formattedCargoQuantity,
      portCosts: formatNumberWithNullAndZeroAsEmpty(leg.portCost, 2),
      days: formatNumberWithNullAndZeroAsEmpty(daysAtLeg, 2),
    });
  });

  return formattedLegs;
};
