/**
 * This status describes the state of the calculation API call.
 */
export const CalculationStatus = {
  LOADING: 0x1,
  LOADED: 0x2,
  FAILED: 0x3,
};

export const isCalculationLoadingStatus = (status) => status === CalculationStatus.LOADING;
export const isCalculationFailureStatus = (status) => status === CalculationStatus.FAILED;
export const isCalculationLoadedStatus = (status) => status === CalculationStatus.LOADED;
