import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  bunkerQuantityChanged,
  bunkerPriceChanged,
  toggleShowUnusedBunkers,
} from 'actions/worksheet/bunkers';
import { selector } from './selector';
import { Header } from 'components/headers';
import LinkButton from 'components/link-button';
import BunkerTable from './components/bunker-table';
import { setStageLeftAs } from 'actions/stage';
import { setBunkerPricesPanelReadOnly, showBunkerPortsTable } from 'actions/bunker-ports';
import type { BunkerSet } from './types';
import Toggle from 'components/toggle';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import './styles.scss';

type Props = {
  worksheetId: WorksheetId,
  bunkers: Array<BunkerSet>,
  fuelGradesUsed: Array<number>,
  bunkerQuantityChanged: Function,
  bunkerPriceChanged: Function,
  showBunkerPortsTable: Function,
  setBunkerPricesPanelReadOnly: Function,
};

export class Bunkers extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onQuantityChangedHandler = this.onQuantityChangedHandler.bind(this);
    this.onPriceChangedHandler = this.onPriceChangedHandler.bind(this);
  }

  onQuantityChangedHandler =
    (bunkerId: string, fuelGradeId: number): Function =>
    (newQuantity: number): void => {
      const payload = {
        bunkerId,
        fuelGradeId,
        newQuantity,
      };
      this.props.bunkerQuantityChanged(payload, this.props.worksheetId);
    };

  onPriceChangedHandler =
    (bunkerId: string, fuelGradeId: number): Function =>
    (newPrice: number): void => {
      const payload = {
        bunkerId,
        fuelGradeId,
        newPrice,
      };
      this.props.bunkerPriceChanged(payload, this.props.worksheetId);
    };

  onToggleShowUnusedBunkersHandler = (toggleState) => {
    trackEvent(
      'Bunker/Costs',
      'Show unused bunkers',
      {
        toggleState,
      },
      {},
      eventDestination.ANALYSIS
    );
    const payload = {
      showUnusedBunkers: toggleState,
    };
    this.props.toggleShowUnusedBunkers(payload, this.props.worksheetId);
  };

  onShowBunkerPortsTableHandler = (isBunkerPricesPanelReadOnly) => {
    this.props.showBunkerPortsTable(isBunkerPricesPanelReadOnly);
  };

  bunkerTableProps = () => {
    return {
      bunkers: this.props.bunkers,
      fuelGradesUsed: this.props.fuelGradesUsed,
      worksheetId: this.props.worksheetId,
      showUnusedBunkers: this.props.showUnusedBunkers,
      showBunkerPortsTable: this.onShowBunkerPortsTableHandler,
      onQuantityChanged: this.onQuantityChangedHandler,
      onPriceChanged: this.onPriceChangedHandler,
    };
  };

  render() {
    return (
      <Fragment>
        <div className="bunkers-section">
          <div className="bunkers-section-left">
            <Header text="Bunker Costs" />
            <div className="bunkers-section-vert-line" />
            <LinkButton
              className="bunkers-section-button"
              diagnosticId="Bunker/ViewPrices"
              /**
               * TODO: consider moving this action to `<BunkerPortsTable>` such that
               * the async (pending/rejected/resolved) behaviour can be managed using `<Async>`.
               */
              onClick={() =>
                this.onShowBunkerPortsTableHandler(/*isBunkerPricesPanelReadOnly*/ true)
              }
            >
              View all bunker prices
            </LinkButton>
          </div>
          <div className="bunkers-section-right">
            <Toggle
              id="show-unused-fuel-prices"
              label="Show unused bunkers"
              diagnosticId="BunkerCosts/ShowUnusedBunkers"
              checked={this.props.showUnusedBunkers}
              onChange={this.onToggleShowUnusedBunkersHandler}
            />
          </div>
        </div>
        <BunkerTable {...this.bunkerTableProps()} />
      </Fragment>
    );
  }
}

const mapStateToProps = selector;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      bunkerQuantityChanged,
      bunkerPriceChanged,
      setStageLeftAs,
      showBunkerPortsTable,
      setBunkerPricesPanelReadOnly,
      toggleShowUnusedBunkers,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Bunkers);
