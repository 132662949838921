export const INTAKE_CALCULATOR_VESSEL_ID_NAME_CHANGED = 'INTAKE_CALCULATOR_VESSEL_ID_NAME_CHANGED';
export const INTAKE_CALCULATOR_VESSEL_CHANGED = 'INTAKE_CALCULATOR_VESSEL_CHANGED';
export const INTAKE_CALCULATOR_DEADWEIGHT_CHANGED = 'INTAKE_CALCULATOR_DEADWEIGHT_CHANGED';
export const INTAKE_CALCULATOR_VESSEL_DRAFT_CHANGED = 'INTAKE_CALCULATOR_VESSEL_DRAFT_CHANGED';
export const INTAKE_CALCULATOR_VESSEL_DRAFT_UNIT_CHANGED =
  'INTAKE_CALCULATOR_VESSEL_DRAFT_UNIT_CHANGED';
export const INTAKE_CALCULATOR_GRAIN_CHANGED = 'INTAKE_CALCULATOR_GRAIN_CHANGED';
export const INTAKE_CALCULATOR_GRAIN_UNIT_CHANGED = 'INTAKE_CALCULATOR_GRAIN_UNIT_CHANGED';
export const INTAKE_CALCULATOR_TPCMI_CHANGED = 'INTAKE_CALCULATOR_TPCMI_CHANGED';
export const INTAKE_CALCULATOR_IMMERSION_UNIT_CHANGED = 'INTAKE_CALCULATOR_IMMERSION_UNIT_CHANGED';
export const INTAKE_CALCULATOR_CARGO_CHANGED = 'INTAKE_CALCULATOR_CARGO_CHANGED';
export const INTAKE_CALCULATOR_STOWAGE_CHANGED = 'INTAKE_CALCULATOR_STOWAGE_CHANGED';
export const INTAKE_CALCULATOR_SPECIFIC_GRAVITY_CHANGED =
  'INTAKE_CALCULATOR_SPECIFIC_GRAVITY_CHANGED';
export const INTAKE_CALCULATOR_STOWAGE_UNIT_CHANGED = 'INTAKE_CALCULATOR_STOWAGE_UNIT_CHANGED';
export const INTAKE_CALCULATOR_PORT_CHANGED = 'INTAKE_CALCULATOR_PORT_CHANGED';
export const INTAKE_CALCULATOR_PORT_DRAFT_CHANGED = 'INTAKE_CALCULATOR_PORT_DRAFT_CHANGED';
export const INTAKE_CALCULATOR_PORT_DRAFT_UNIT_CHANGED =
  'INTAKE_CALCULATOR_PORT_DRAFT_UNIT_CHANGED';
export const INTAKE_CALCULATOR_SALINITY_CHANGED = 'INTAKE_CALCULATOR_SALINITY_CHANGED';
export const INTAKE_CALCULATOR_SALINITY_FACTOR_CHANGED =
  'INTAKE_CALCULATOR_SALINITY_FACTOR_CHANGED';
export const INTAKE_CALCULATOR_CONSTANTS_CHANGED = 'INTAKE_CALCULATOR_CONSTANTS_CHANGED';
export const INTAKE_CALCULATOR_BUNKERS_ON_BOARD_CHANGED =
  'INTAKE_CALCULATOR_BUNKERS_ON_BOARD_CHANGED';
export const INTAKE_CALCULATOR_FRESH_WATER_CHANGED = 'INTAKE_CALCULATOR_FRESH_WATER_CHANGED';
export const INTAKE_CALCULATOR_CALCULATE_MAX_LIFT_FROM_API =
  'INTAKE_CALCULATOR_CALCULATE_MAX_LIFT_FROM_API';
export const INTAKE_CALCULATOR_CLEAR_ALL = 'INTAKE_CALCULATOR_CLEAR_ALL';
export const SET_INTAKE_CALCULATOR_FROM_API = 'SET_INTAKE_CALCULATOR_FROM_API';
