import React, { FC, useRef } from 'react';
import switchExpr from 'utilities/functions/switch-expr';
import ActionBarPrimary from 'modules/action-bar-primary';
import ActionBarSecondary from 'modules/action-bar-secondary';
import ScrollablePanel from 'components/scrollable-panel';
import { connect } from 'react-redux';
import { getStageProps } from './selector';
import classNames from 'classnames';

import {
  INDIVIDUAL_SUMMARY_PANEL,
  INTAKE_CALCULATOR_PANEL,
  COMPARISON_SUMMARY_PANEL,
} from 'constants/enums/stage-panels';
import CalculationSummary from 'modules/calculation-summary';
import IntakeCalculator from 'modules/intake-calculator';
import ComparisonTableContainer from 'modules/worksheet-comparison';

import './styles.scss';
import CarbonFactorTableModal from 'modules/carbon-factor-table-modal';
import WorkbookContainer from 'modules/workbook/workbook-container';
import { StageRightPortal } from './right-stage-portal';
import { isTanker } from '../../constants/market-segments';
import EditSpeedAndConsModal from '../edit-speed-and-cons-modal';
import { useParams } from 'react-router-dom';

export const Stage: FC = ({ rightPanel, activeMarketSegmentId, activeWorkbook }) => {
  const primaryActionsNavPortalRef = useRef();
  let { workbookId } = useParams();

  if (!workbookId) {
    throw new Error(`Stage expects workbookId to be set`);
  }
  return (
    <div className="stage">
      <CarbonFactorTableModal />
      <EditSpeedAndConsModal />
      <div className="stage__left">
        <ActionBarPrimary actionsPortalRef={primaryActionsNavPortalRef} />
        <WorkbookContainer
          // Passing key={workbookId} ensures that we create a new instance of the `WorkbookContainer`
          // component when workbookId changes. This way we can guarantee that the internal
          // state of components within workbook are destroyed gracefully, and
          // never reused/shared for/with other workbooks.
          key={workbookId}
          workbookId={workbookId}
        />
      </div>
      <div
        className={classNames('stage__right', {
          'tankers-right-panel': isTanker(activeMarketSegmentId),
        })}
        data-testid="stage-right"
      >
        <StageRightPortal>
          <ActionBarSecondary />
          <ScrollablePanel hideHorizontalScroll={isTanker(activeMarketSegmentId)}>
            {switchExpr(rightPanel, {
              [INTAKE_CALCULATOR_PANEL]: () => <IntakeCalculator />,
              [COMPARISON_SUMMARY_PANEL]: () => activeWorkbook && <ComparisonTableContainer />,
              [INDIVIDUAL_SUMMARY_PANEL]: () => <CalculationSummary />,
            })}
          </ScrollablePanel>
        </StageRightPortal>
      </div>
    </div>
  );
};

export default connect(getStageProps, null)(Stage);
