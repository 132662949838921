import { SET_USER_STATE, SET_USER_PREFERENCE_STATE } from 'constants/action-types/user-state';

export const userStateReducer = (state = null, action: any) => {
  switch (action.type) {
    case SET_USER_STATE:
      return action.payload;
    default:
      return state;
  }
};
export const userPreferenceReducer = (state = null, action: any) => {
  switch (action.type) {
    case SET_USER_PREFERENCE_STATE:
      return action.payload;
    default:
      return state;
  }
};
