import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selector } from './selector';
import * as RedirectManager from 'utilities/redirect-manager';

class WorkbookRedirector extends PureComponent {
  render() {
    const { lastViewedWorkbookId } = this.props;
    const url = RedirectManager.retrieveURL();
    if (url) {
      return <Navigate to={url} />;
    }
    return <Navigate to={`/workbook/${lastViewedWorkbookId}`} />;
  }
}

const mapStateToProps = selector;

const ConnectedWorkbookRedirector = connect(mapStateToProps)(WorkbookRedirector);

export { ConnectedWorkbookRedirector as WorkbookRedirector };
