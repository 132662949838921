const WorkingDayFactors = [
  {
    id: 'CQD1',
    factor: 1,
    description: 'Customary Quick Despatch',
  },
  {
    id: 'FHEX',
    factor: 1.1667,
    description: 'Friday, Holidays Excl.',
  },
  {
    id: 'FHEX2',
    factor: 1.6,
    description: 'Wednesday 17.00 to Saturday 8.00 Excl.',
  },
  {
    id: 'FHEX3',
    factor: 1.3548,
    description: 'Thursday 12.00 to Saturday 08.00 Excl.',
  },
  {
    id: 'FHEX4',
    factor: 1.3548,
    description: 'Friday 12.00 to Sunday 08.00 Excl.',
  },
  {
    id: 'FHEX5',
    factor: 1.4483,
    description: 'Thursday 16.00 to Saturday 12.00 Excl.',
  },
  {
    id: 'FHEX6',
    factor: 1.68,
    description: 'Thursday 12.00 to Sunday 08.00 Holidays Excl.',
  },
  {
    id: 'FHINC',
    factor: 1,
    description: 'Fridays, Holidays Included',
  },
  {
    id: 'SHEX0',
    factor: 1.1667,
    description: 'Sundays, Holidays Excl.',
  },
  {
    id: 'SHEX1',
    factor: 1.2727,
    description: 'Saturday 12.00 to Sunday 24.00 Excl.',
  },
  {
    id: 'SHEX2',
    factor: 1.3548,
    description: 'Saturday 12.00 to Monday 08.00 Excl.',
  },
  {
    id: 'SHEX3',
    factor: 1.4,
    description: 'Friday 24.00 to Sunday 24.00 Excl.',
  },
  {
    id: 'SHEX4',
    factor: 1.4867,
    description: 'Friday 17.00 to Sunday 24.00 Excl.',
  },
  {
    id: 'SHEX5',
    factor: 1.5,
    description: 'Friday 24.00 to Monday 08.00 Excl.',
  },
  {
    id: 'SHEX6',
    factor: 1.6,
    description: 'Friday 17.00 to Monday 08.00 Excl.',
  },
  {
    id: 'SHEX7',
    factor: 1.28,
    description: 'Saturday 17.00 to Monday 08.00 Excl.',
  },
  {
    id: 'SHEX8',
    factor: 1.36,
    description: 'Saturday 14.00 to Monday 08.00 Excl.',
  },
  {
    id: 'SHINC',
    factor: 1,
    description: 'Sundays, Holidays Included',
  },
  {
    id: 'SSHEX',
    factor: 1.5555,
    description: 'Saturdays, Sundays, Holidays Excl.',
  },
  {
    id: 'TD',
    factor: 1,
    description: 'Total Days',
  },
];

const defaultWorkingDayFactorId = 'SHINC';

export const DEFAULT_FACTOR = WorkingDayFactors.find(
  (factor) => factor.id === defaultWorkingDayFactorId
);

export default WorkingDayFactors;
