import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header } from 'components/headers';
import { Modal } from 'components/modal';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { Overlay } from 'modules/overlay';
import { selector } from 'modules/edit-speed-and-cons-modal/selector';
import {
  consumptionChanged,
  mainFuelGradeChanged,
  generatorChangedInEditSandConsChanged,
  showSpeedAndConsEditModal,
  speedChanged,
  zoneSpecificSecaFuelGradeChanged,
} from 'actions/worksheet/speed-and-consumptions';
import { FUEL_GRADE_380CST, getFuelGradeById } from 'constants/enums/fuel-grades';
import SpeedConsInputSection from 'modules/edit-speed-and-cons-modal/SpeedConsInputSection';
import FuelGradeSection from 'modules/edit-speed-and-cons-modal/FuelGradeSection';
import 'modules/edit-speed-and-cons-modal/styles.scss';
import Toggle from 'components/toggle';
import classNames from 'classnames';
import { trackChangeEvent } from 'diagnostics/calc-trackevents';

type EditSpeedAndConsModalProps = {
  isSpeedAndConsEditModalVisible: boolean,
  showSpeedAndConsEditModal: (isVisible: boolean) => void,
  activeVessel: any,
  worksheetId: string,
  speedChanged: () => void,
  consumptionChanged: () => void,
  zoneSpecificSecaFuelGradeChanged: () => void,
  mainFuelGradeChanged: () => void,
  generatorChangedInEditSandConsChanged: () => void,
};
export const FuelGradeType = {
  SECA: 'seca',
  NON_SECA: 'non-seca',
  GENERATOR: 'generator',
};

export const EditSpeedAndConsModal: React.FC<EditSpeedAndConsModalProps> = ({
  isSpeedAndConsEditModalVisible,
  activeVessel,
  worksheetId,
  generatorState,
  showSpeedAndConsEditModal,
  speedChanged,
  consumptionChanged,
  zoneSpecificSecaFuelGradeChanged,
  mainFuelGradeChanged,
  generatorChangedInEditSandConsChanged,
}) => {
  const speedAndConsumptions = activeVessel?.speedAndConsumptions;

  const GetSecaSelectedItem = (fuelGradeType) => {
    if (activeVessel?.speedAndConsumptions != null) {
      let fuelGradeId;

      switch (fuelGradeType) {
        case FuelGradeType.SECA:
          fuelGradeId = activeVessel.speedAndConsumptions.zoneSpecific.seca.fuelGradeId;
          break;
        case FuelGradeType.NON_SECA:
          fuelGradeId = activeVessel.speedAndConsumptions.ballast.fuelGrade;
          break;
        case FuelGradeType.GENERATOR:
          fuelGradeId = activeVessel.speedAndConsumptions.ballast.generatorFuelGrade;
          break;
        default:
          fuelGradeId = FUEL_GRADE_380CST;
          break;
      }

      return getFuelGradeById(fuelGradeId);
    } else {
      return FUEL_GRADE_380CST;
    }
  };
  const onSpeedChangedHandler = (speedAndConType, speed) => {
    const payload = {
      speedAndConType,
      speed,
    };
    speedChanged(payload, worksheetId, activeVessel.entryId);
  };

  const onConsumptionChangedHandler = (speedAndConType, consumption, isMainEngine) => {
    const payload = {
      speedAndConType,
      isMainEngine,
      consumption,
    };

    consumptionChanged(payload, worksheetId, activeVessel.entryId);
  };

  const handleSeaStatusInputChange = (section, type, value, isMainEngine) => {
    if (['Ballast', 'Laden'].includes(section)) {
      const handler = type === 'speed' ? onSpeedChangedHandler : onConsumptionChangedHandler;
      handler(speedAndConsumptions[section.toLowerCase()].type, value, isMainEngine);
    }
  };

  const handleSecaFuelTypeChanged = (newFuelType) => {
    zoneSpecificSecaFuelGradeChanged(newFuelType.key, worksheetId, activeVessel.entryId);
  };

  const handleMainFuelTypeChanged = (newFuelType, isMainEngine) => {
    mainFuelGradeChanged(
      {
        key: newFuelType.key,
        isMainEngine: isMainEngine,
      },
      worksheetId,
      activeVessel.entryId
    );
  };

  const onToggleGeneratorHandler = (toggleState) => {
    generatorChangedInEditSandConsChanged(toggleState, worksheetId);
    trackChangeEvent('edit-speed-and-cons/generator', {
      oldValue: generatorState,
      newValue: toggleState,
    });
  };

  return (
    <>
      <Modal isVisible={isSpeedAndConsEditModalVisible}>
        <div
          className={classNames({
            'edit-speed-and-cons-modal': true,
            'edit-speed-and-cons-modal-with-generator': generatorState,
          })}
        >
          <div className="edit-speed-and-cons-modal--header">
            <Header
              text="Speed and Consumptions"
              className="edit-speed-and-cons-modal--header__title"
            />
            <IconButton
              icon={iconEnum.Close}
              className="edit-speed-and-cons-modal--header__close-btn"
              onClick={() => showSpeedAndConsEditModal(false)}
              diagnosticId="EditSpeedAndCons/CloseModal"
            />
          </div>
          <div>
            <div className="generator-toggle">
              <Toggle
                id="generator-edit-speed-and-cons"
                label="Generator"
                checked={generatorState}
                onChange={onToggleGeneratorHandler}
              />
            </div>
          </div>
          <Header text="Sea Status" className="edit-speed-and-cons-modal--header__subtitle" />
          <div className={'speed-edit-part'}>
            <SpeedConsInputSection
              title="Ballast"
              speedValue={speedAndConsumptions?.ballast?.speed}
              consValue={speedAndConsumptions?.ballast?.consumption}
              genValue={speedAndConsumptions?.ballast?.generatorConsumption}
              withGenerator={generatorState}
              onInputChange={(type, value, isMaineEngine) =>
                handleSeaStatusInputChange('Ballast', type, value, isMaineEngine)
              }
            />
          </div>
          <div className={'speed-edit-part__second'}>
            <SpeedConsInputSection
              title="Laden"
              speedValue={speedAndConsumptions?.laden?.speed}
              consValue={speedAndConsumptions?.laden?.consumption}
              genValue={speedAndConsumptions?.laden?.generatorConsumption}
              withGenerator={generatorState}
              onInputChange={(type, value, isMaineEngine) =>
                handleSeaStatusInputChange('Laden', type, value, isMaineEngine)
              }
            />
          </div>
          <Header text="Fuel Grade" className="edit-speed-and-cons-modal--header__subtitle" />
          <div className={'speed-edit-part'}>
            <FuelGradeSection
              title="SECA"
              selectedFuelGrade={GetSecaSelectedItem(FuelGradeType.SECA)}
              onChange={(value) => handleSecaFuelTypeChanged(value)}
            />
          </div>
          <div className={'speed-edit-part__second'}>
            <FuelGradeSection
              title="Non-SECA"
              selectedFuelGrade={GetSecaSelectedItem(FuelGradeType.NON_SECA)}
              onChange={(value) => handleMainFuelTypeChanged(value, true)}
            />
          </div>
          {generatorState && (
            <div className={'speed-edit-part__second'}>
              <FuelGradeSection
                title="Generator"
                selectedFuelGrade={GetSecaSelectedItem(FuelGradeType.GENERATOR)}
                onChange={(value) => handleMainFuelTypeChanged(value, false)}
              />
            </div>
          )}
        </div>
      </Modal>
      <Overlay
        onClick={() => showSpeedAndConsEditModal(false)}
        hidden={!isSpeedAndConsEditModalVisible}
      />
    </>
  );
};

const mapStateToProps = selector;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showSpeedAndConsEditModal,
      speedChanged,
      consumptionChanged,
      zoneSpecificSecaFuelGradeChanged,
      mainFuelGradeChanged,
      generatorChangedInEditSandConsChanged,
    },
    dispatch
  );
};

const EditSpeedAndConsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSpeedAndConsModal);

export default EditSpeedAndConsModalContainer;
