import { VOYAGE_ROUTE_VARIANTS_SET } from 'constants/action-types/worksheet/voyage';
import type { VoyageEntryAction } from '../../../../../../../actions/worksheet/voyage/voyage-entry-action-typings';

export function variantsReducer(
  state: InboundRouteVariant[],
  action: VoyageEntryAction
): InboundRouteVariant[] {
  switch (action.type) {
    case VOYAGE_ROUTE_VARIANTS_SET: {
      return action.payload.newRouteVariantViewModels;
    }
    default:
      return state;
  }
}
