import * as iterableUtils from 'utilities/iterable/index';
import * as nonVoidTryFunction from 'utilities/functions/try-functions/non-void-try-function';
import { NonVoidTryFunctionUtils } from 'utilities/functions/try-functions/non-void-try-function';
import VError from 'verror';

/**
 Using `NonVoidTryFunction` style is needed especially when some of the reasons for unsuccessful result are meant to have a supported behavior.
 This especially poses a difficulty in that the other unsupported 'unknown reasons' need to still be logged to developers as errors, so they can fix it. Else they will never know about their occurence.
 The complexity gets even bigger with compound actions (e.g. one user interaction resulting in multiple actions performed), where some of the reasons can be known and some unknown.
 This function makes it easier to ensure no loss of error diagnostics by using a fail-fast behavior for compound actions - the user specifies known reasons and their handlers in `unsuccessfulResultHandlersMap`, while if there is **any** unknown reason among the results, **instead** of calling any handler from `unsuccessfulResultHandlersMap`, an exception will be thrown containing the unknown reasons so that their full information can be logged (this behavior can be customized using `handleUnknownReasonsPresent`).
 */
export function handleEachUnsuccessfulResultTypeThrowIfAnyUnknown<
  Item,
  Result: nonVoidTryFunction.SuccessfulResult | nonVoidTryFunction.UnsuccessfulResult,
>(
  allItems: Iterable<Item>,
  getResult: (Item) => Result,
  unsuccessfulResultHandlersMap: Map<
    nonVoidTryFunction.ReasonForUnsuccessfulResult,
    (unsuccessfulItemsWithThisReason: Array<Item>) => void,
  >,
  handleUnknownReasonsPresent?: (
    unsupportedReasons: Set<nonVoidTryFunction.ReasonForUnsuccessfulResult>,
    allUnsuccessfulItems: Array<Item>,
    allItems: Iterable<Item>,
    createUnexpectedReasonError: () => Error
  ) => void
) {
  const allUnsuccessfulResultsByReasonsMap = iterableUtils.groupByToMap(
    /*iterable: */ allItems
      .map((item) => ({
        item: item,
        reasonUnsuccessful: NonVoidTryFunctionUtils.getReasonIfUnsuccessfulResultOrNull(
          getResult(item)
        ),
      }))
      .filter((_) => _.reasonUnsuccessful !== null),
    /* getGroupIdentityKey: */ (_) => _.reasonUnsuccessful,
    /* getValueFromItem: */ (item) => item.item
  );

  const allUniqueUnsuccessfulResultsSet = new Set(allUnsuccessfulResultsByReasonsMap.keys());

  const unsupportedReasonsSet = new Set(
    iterableUtils.differenceWithSet(
      allUniqueUnsuccessfulResultsSet,
      new Set(unsuccessfulResultHandlersMap.keys())
    )
  );

  if (unsupportedReasonsSet.size !== 0) {
    if (handleUnknownReasonsPresent !== undefined)
      handleUnknownReasonsPresent(
        /* unsupportedReasons: */ unsupportedReasonsSet,
        /*allUnsuccessfulItems: */ [...allUnsuccessfulResultsByReasonsMap.values()].flat(),
        /* allItems: */ allItems,
        /* createUnexpectedReasonError: */ createUnexpectedReasonError
      );
    else {
      throw createUnexpectedReasonError();
    }

    function createUnexpectedReasonError() {
      const unsupportedReasonsArray = [...unsupportedReasonsSet];

      return new VError(
        unsupportedReasonsArray.every((reason) => reason instanceof Error)
          ? {
              cause: VError.errorFromList(unsupportedReasonsArray),
            }
          : {
              info: {
                unsupportedReasons: unsupportedReasonsArray,
              },
            },
        "Unexpected reason for the result being unsuccessful was found. See this error's properties for details."
      );
    }
  } else {
    for (const uniqueValue of allUniqueUnsuccessfulResultsSet) {
      unsuccessfulResultHandlersMap.get(uniqueValue)(
        allUnsuccessfulResultsByReasonsMap.get(uniqueValue)
      );
    }
  }
}
