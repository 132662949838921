import React, { Component } from 'react';
import './styles.scss';
import { CarbonCostContainer } from '../calculation-summary/carbon-cost';
import { VoyTotalsContainer } from '../calculation-summary/voy-totals';
import { VoyageCostContainer } from '../calculation-summary/voyage-cost';

export class TankersSummaryContainer extends Component<Props> {
  render() {
    return (
      <div className="tankers-summary">
        <div className="tankers-summary__section">
          <VoyTotalsContainer />
        </div>

        <VoyageCostContainer />
        <CarbonCostContainer />
      </div>
    );
  }
}
