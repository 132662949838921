import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import userContext from 'user-context';
import { getActiveWorksheet } from '../../common-selectors/get-active-worksheet';

export const selector = createSelector(
  (state) => state.workbooksById,
  (state, props) => (props && props.userId) || userContext.systemUserId,
  (state) => state.worksheetsById,
  (state) => state.activeWorkbookId,
  getActiveWorksheet,
  (workbooksById, userId, worksheetsById, activeWorkbookId, activeWorksheet) => ({
    activeWorkbookId,
    activeWorksheetMarketSegmentId: activeWorksheet.marketSegmentId,
    workbooks: Object.values(workbooksById)
      .filter((wb) => wb.userId === userId)
      .map((workbook) => ({
        ...workbook,
        worksheets: filter(worksheetsById, (worksheet) => worksheet.workbookId === workbook.id),
      })),
  })
);
