import React, { Fragment } from 'react';
import LabeledInput from '../labeled-input';
import Label from 'components/label';
import NumericInput from 'components/numeric-input';
import DropDown from 'components/dropdown';
import { SalinityTypes } from 'constants/enums/salinity-types';
import DraftUnitTypes, {
  DRAFT_NUMBER_OF_DECIMAL_PLACES,
  getDraftUnitTypeById,
  METERS,
  convertDraft,
} from 'constants/enums/draft-units';
import classNames from 'classnames';
import { isTanker } from '../../../../constants/market-segments';

const PortInputs = ({ port, actions, ports, marketSegmentId }) => {
  const onPortChangedHandler = (selectedPort) => {
    const payload = {
      ...selectedPort,
      draft: convertDraft(
        selectedPort.draft,
        getDraftUnitTypeById(selectedPort.draftUnit),
        port.draftUnit
      ),
      draftUnit: port.draftUnit,
    };

    actions.portChanged(payload);
  };

  const onDraftUnitChangedHandler = (draftUnit) => {
    const draft = convertDraft(port.draft, port.draftUnit, draftUnit);
    const payload = { draft, draftUnit };
    actions.portDraftUnitChanged(payload);
  };

  return (
    <Fragment>
      <div className="intake-calc__row intake-calc__row-first">
        <LabeledInput
          className={classNames('intake-calc__location', {
            'tankers-intake-port-location': isTanker(marketSegmentId),
          })}
        >
          <Label className="labeled-input--label">Location</Label>
          <DropDown
            id="location"
            items={ports.map((port) => ({
              ...port,
              key: port.id,
              label: port.name,
            }))}
            selectedItem={{
              ...port,
              key: port.id,
              label: port.name,
            }}
            onChange={onPortChangedHandler}
            diagnosticId="IntakeCalculator/Port"
          />
        </LabeledInput>

        <LabeledInput className="intake-calc__port-draft">
          <span className="labeled-input--label">
            Draft
            <DropDown
              id="draftUnit"
              items={DraftUnitTypes}
              selectedItem={port.draftUnit}
              onChange={onDraftUnitChangedHandler}
              asLabel
              diagnosticId="IntakeCalculator/PortDraftUnit"
            />
          </span>
          <NumericInput
            value={port.draft}
            onInputChange={actions.portDraftChanged}
            maxDecimalDigits={DRAFT_NUMBER_OF_DECIMAL_PLACES}
            minValue="0"
            maxValue={convertDraft(99.99, METERS, port.draftUnit)}
            diagnosticId="IntakeCalculator/PortDraft"
          />
        </LabeledInput>
        <LabeledInput className="intake-calc__salinity">
          <Label className="labeled-input--label">Salinity</Label>
          <div className="intake-calc__salinity--wrapper">
            <div className="intake-calc__salinity--select">
              <DropDown
                id="salinity"
                items={SalinityTypes}
                selectedItem={port.salinity}
                onChange={actions.salinityChanged}
                diagnosticId="IntakeCalculator/PortSalinity"
              />
            </div>
            <NumericInput
              value={port.salinity.value}
              onInputChange={actions.salinityFactorChanged}
              maxDecimalDigits="4"
              minValue="0"
              maxValue="9.9999"
              className={isTanker(marketSegmentId) ? 'salinity-tanker-input' : ''}
              diagnosticId="IntakeCalculator/PortSalinityFactor"
            />
          </div>
        </LabeledInput>
      </div>
    </Fragment>
  );
};

export default PortInputs;
