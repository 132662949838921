const localStorageKey = 'calc_redirect';

export const storeURL = (url) => {
  var redirectObject = { url: url, time: new Date().getTime() };
  localStorage.setItem(localStorageKey, JSON.stringify(redirectObject));
};

export const retrieveURL = () => {
  const redirectJson = localStorage.getItem(localStorageKey);
  if (redirectJson) {
    localStorage.removeItem(localStorageKey);
    const redirectObject = JSON.parse(redirectJson);

    // check the local storage url is valid within the last 5 minutes, if not delete it
    if (Date.now() - redirectObject.time <= 5 * 60 * 1000) {
      return redirectObject.url;
    }
  }
  return null;
};
