import React from 'react';
import classNames from 'classnames';
import './styles.scss';

export const Tab = (props) => {
  const { isActive, className, children, sortOrderType, tabName, ...rest } = props;
  // const [focus, setFocus] = React.useState(null);
  // we need to add elements before and after
  // the DOM element to make sure that the full tab
  // is visible. Otherwise, scrollIntoView, will only
  // look at the left edge of the element
  const anchorLeftRef = React.useRef();
  const anchorRightRef = React.useRef();
  React.useEffect(() => {
    if (anchorLeftRef.current && anchorLeftRef.current.scrollIntoView) {
      if (isActive && sortOrderType !== undefined) {
        anchorRightRef.current.scrollIntoView();
        anchorLeftRef.current.scrollIntoView();
      }
    }
  }, [isActive, sortOrderType, tabName]);

  const classes = classNames('tab', className, {
    'tab--active': isActive,
  });

  return (
    <button className={classes} {...rest}>
      <span className="tab-anchor tab-anchor--left" ref={anchorLeftRef} />
      {children}
      <span className="tab-anchor tab-anchor--right" ref={anchorRightRef} />
    </button>
  );
};
