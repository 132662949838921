import React, { Component } from 'react';

import { Grid } from 'components/grid';
import { getDefaultGridOptions } from 'modules/search-panel/components/workbooks-grid/default-grid-options';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import './styles.scss';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
export default class WorkbooksGrid extends Component {
  constructor(props) {
    super(props);

    const gridOptions = this.props.gridOptions || getDefaultGridOptions();

    gridOptions.onGridReady = ({ api }) => {
      this.agGridApi = api;
      this.updateGrid(this.agGridApi);
    };

    this.state = {
      gridOptions,
    };
  }

  getRowNodeId = (params) => params.data.id;

  render() {
    const { activeWorkbookId } = this.props;
    return (
      <Grid
        getRowId={this.getRowNodeId}
        className="grid-height-determiner"
        gridOptions={this.state.gridOptions}
        rowData={this.props.workbooks}
        quickFilterText={this.props.filterText}
        activeWorkbookId={activeWorkbookId}
        onRowDataUpdated={(params) => this.updateGrid(params.api)}
        reactiveCustomComponents
      />
    );
  }

  componentDidUpdate(prevProps) {
    if (!this.agGridApi) return; // Sometimes this gets fired before grid is ready. We handle both cases by also calling `updateGrid` in `onGridReady`
    this.updateGrid(this.agGridApi);
  }

  updateGrid(agGridApi) {
    agGridApi.forEachNode((node, index) => {
      if (node.data.id === this.props.activeWorkbookId) {
        node.setSelected(true);
      }
    });
    agGridApi.redrawRows();
    agGridApi.sizeColumnsToFit();
  }
}
