import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selector } from './selector';
import isNumber from 'lodash/isNumber';
import { TC_TO_FREIGHT, FREIGHT_TO_TC } from './sensitivity-types';
import {
  sensitivityChanged,
  sensitivityValueChanged,
  firstFuelGradeChanged,
  firstFuelSensitivityChanged,
  secondFuelGradeChanged,
  secondFuelSensitivityChanged,
} from 'actions/sensitivity-table';
import { Table, TableBody } from 'components/table';
import NumericInput from 'components/numeric-input';
import { RadioGroup, RadioButton } from 'components/radio-button';
import DropDown from 'components/dropdown';
import Divider from 'components/divider';
import HeaderRow from './components/header-row';
import DataRow from './components/data-row';
import {
  FUEL_GRADE_180CST,
  FUEL_GRADE_380CST,
  FUEL_GRADE_MGO,
  FUEL_GRADE_LSMGO,
  FUEL_GRADE_VLSFO,
  FUEL_GRADE_ULSFO,
  FUEL_GRADE_LNG,
} from 'constants/enums/fuel-grades';
import ProgressBar, { Size } from 'components/progress-bar';

import './styles.scss';

// cache value
const firstFuelGrades = [FUEL_GRADE_380CST, FUEL_GRADE_180CST, FUEL_GRADE_VLSFO];
const secondFuelGrades = [FUEL_GRADE_MGO, FUEL_GRADE_LSMGO, FUEL_GRADE_ULSFO, FUEL_GRADE_LNG];

const isTimeCharterToFreightCalculationRunning = (grossTimeCharter) => grossTimeCharter === 0;
const isFreightToTimeCharterCalculationRunning = (grossVoyageRate) => grossVoyageRate === 0;

const getActiveSensitivity = (grossVoyageRate, grossTimeCharter, activeSensitivity) => {
  if (
    isTimeCharterToFreightCalculationRunning(grossVoyageRate) &&
    !isFreightToTimeCharterCalculationRunning(grossTimeCharter)
  ) {
    return TC_TO_FREIGHT;
  }
  if (
    isFreightToTimeCharterCalculationRunning(grossTimeCharter) &&
    !isTimeCharterToFreightCalculationRunning(grossVoyageRate)
  ) {
    return FREIGHT_TO_TC;
  }
  return activeSensitivity;
};

export class SensitivityTable extends Component {
  handleSensitivityChange = (value) => {
    let sensitivity;
    switch (value) {
      case TC_TO_FREIGHT.label:
        sensitivity = TC_TO_FREIGHT;
        break;
      case FREIGHT_TO_TC.label:
        sensitivity = FREIGHT_TO_TC;
        break;
      default:
        sensitivity = {};
    }

    this.props.sensitivityChanged(sensitivity);
  };

  onSensitivityValueChangedHandler = (value) => {
    this.props.sensitivityValueChanged(this.props.activeSensitivity, value);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.grossVoyageRate !== this.props.grossVoyageRate ||
      prevProps.grossTimeCharter !== this.props.grossTimeCharter
    ) {
      this.checkAndUpdateSensitivity();
    }
  }

  /**
   * Check that the active sensitivity matches user inputs
   */
  checkAndUpdateSensitivity() {
    const sensitivity = getActiveSensitivity(
      this.props.grossVoyageRate,
      this.props.grossTimeCharter,
      this.props.activeSensitivity
    );
    if (sensitivity !== this.props.activeSensitivity) {
      this.props.sensitivityChanged(sensitivity);
    }
  }

  componentDidMount() {
    this.checkAndUpdateSensitivity();
  }

  getDataRows = (sensitivity) => (calculationResults) => {
    return calculationResults.map((calculationResult) => {
      if (sensitivity === TC_TO_FREIGHT) {
        return [
          calculationResult.timeCharterGross,
          calculationResult.timeCharterNet,
          calculationResult.voyageRateCalculatedGross,
          calculationResult.averageFirstFuelGradePrice,
          calculationResult.averageSecondFuelGradePrice,
        ];
      } else {
        return [
          calculationResult.voyageRateGross,
          calculationResult.timeCharterRateCalculatedNet,
          calculationResult.timeCharterRateCalculatedGross,
          calculationResult.averageFirstFuelGradePrice,
          calculationResult.averageSecondFuelGradePrice,
        ];
      }
    });
  };

  safeNumberCompare = (x, y) => {
    return isNumber(x) || isNumber(y) ? (!isNumber(x) ? -1 : !isNumber(y) ? 1 : x - y) : 0;
  };

  render() {
    const { activeSensitivity, calculationResults } = this.props;
    const dataRows = this.getDataRows(activeSensitivity)(calculationResults);
    dataRows.sort((x, y) => {
      return (
        this.safeNumberCompare(x[0], y[0]) || // Gross VR || TC Gross
        this.safeNumberCompare(x[3], y[3]) || // Fuel Grade 1
        this.safeNumberCompare(x[4], y[4]) // Fuel Grade 2
      );
    });

    const midIndex = Math.floor(dataRows.length / 2);
    return (
      <Fragment>
        <div className="sensitivity__settings">
          <div className="sensitivity__header">Rate</div>
          <RadioGroup
            name="sensitivity-selector"
            selectedValue={this.props.activeSensitivity.label}
            onChange={this.handleSensitivityChange}
          >
            <RadioButton value={TC_TO_FREIGHT.label} className="sensitivity__option" />
            <RadioButton value={FREIGHT_TO_TC.label} className="sensitivity__option" />
          </RadioGroup>
          <div className="sensitivity__value">
            <label className="sensitivity__label">{this.props.activeSensitivity.inputLabel}</label>
            <NumericInput
              value={this.props.sensitivityValue}
              className="sensitivity__input"
              onInputChange={this.onSensitivityValueChangedHandler}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="999999.99"
              diagnosticId="SensitivityTable/ChangeSensitivity"
            />
          </div>
        </div>
        <Divider className="sensitivity__divider" />
        <div className="sensitivity__settings">
          <div className="sensitivity__header">Fuel</div>
          <div className="sensitivity__value sensitivity__value--first-fuel-grade">
            <label className="sensitivity__label">Fuel Grade</label>
            <DropDown
              items={firstFuelGrades}
              selectedItem={this.props.firstFuelGrade}
              onChange={this.props.firstFuelGradeChanged}
              diagnosticId="SensitivityTable/ChangeFirstFuelGradeSensititvity "
            />
          </div>
          <div className="sensitivity__value">
            <label className="sensitivity__label">+/- Price ($)</label>
            <NumericInput
              value={this.props.firstFuelSensitivity}
              className="sensitivity__input sensitivity__input--fuel-value"
              onInputChange={this.props.firstFuelSensitivityChanged}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="9999.99"
              diagnosticId="SensitivityTable/ChangeFirstFuelSensititvity"
            />
          </div>
          <div className="sensitivity__value sensitivity__value--second-fuel-grade">
            <label className="sensitivity__label">Fuel Grade</label>
            <DropDown
              items={secondFuelGrades}
              selectedItem={this.props.secondFuelGrade}
              onChange={this.props.secondFuelGradeChanged}
              diagnosticId="SensitivityTable/ChangeSecondFuelGradeSensititvity"
            />
          </div>
          <div className="sensitivity__value">
            <label className="sensitivity__label">+/- Price ($)</label>
            <NumericInput
              value={this.props.secondFuelSensitivity}
              className="sensitivity__input sensitivity__input--fuel-value"
              onInputChange={this.props.secondFuelSensitivityChanged}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="9999.99"
              diagnosticId="SensitivityTable/ChangeSecondFuelSensititvity"
            />
          </div>
        </div>
        {/* consider breaking this into separate component */}
        <Table className="sensitivity__table">
          <HeaderRow
            activeSensitivity={this.props.activeSensitivity}
            firstFuelGrade={this.props.firstFuelGrade}
            secondFuelGrade={this.props.secondFuelGrade}
          />
          {dataRows.length === 11 || dataRows.length === 0 ? (
            <TableBody>
              {dataRows.map((result, rowIndex) => (
                <DataRow
                  key={`sensitivity-table-row-${rowIndex}`}
                  rowData={result}
                  rowIndex={rowIndex}
                  highlight={rowIndex === midIndex}
                />
              ))}
            </TableBody>
          ) : (
            <div className="sensitivity__progress-bar">
              <ProgressBar size={Size.MEDIUM} />
            </div>
          )}
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sensitivityChanged,
      sensitivityValueChanged,
      firstFuelGradeChanged,
      firstFuelSensitivityChanged,
      secondFuelGradeChanged,
      secondFuelSensitivityChanged,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SensitivityTable);

export { TC_TO_FREIGHT, FREIGHT_TO_TC } from './sensitivity-types';
