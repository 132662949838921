import store from 'store';
import * as routingClient from 'api/clients/route';
import * as calculation from 'api/clients/calculation';
import * as externalVessel from 'api/clients/external-vessel';
import * as vesselClient from 'api/clients/vessel';

/// enables a nice list of hacks, however beware these COULD have side-effects elsewhere
export function enableAllConvenienceHacks() {
  enableReduxFriendlySets();
  enableClientServerRouteResponseComparison();
}

/// source: https://github.com/DavidBruant/Map-Set.prototype.toJSON
/// this method allow redux dev tools to view the contents of sets and not as
/// an empty set i.e. `Set({})`
function enableReduxFriendlySets() {
  // eslint-disable-next-line no-extend-native
  Set.prototype.toJSON = function toJSON() {
    return [...Set.prototype.values.call(this)];
  };
}

export function getAppViewModel() {
  return store.getState();
}

export function getCurrentWorksheetWithCalculationResultsViewModel() {
  return {
    worksheet: getCurrentWorksheetViewModel(),
    calculationResults: getCurrentWorksheetCalculationResultsViewModel(),
  };
}

export function getCurrentWorksheetCalculationResultsViewModel() {
  const appState = getAppViewModel();
  return appState.calculationsByWorksheetId[appState.activeWorksheetId].calculationResults;
}

export function getCurrentWorksheetViewModel() {
  const appState = getAppViewModel();
  return appState.worksheetsById[appState.activeWorksheetId];
}

export function showConcurrencySettings() {
  return {
    routingClient: routingClient.concurrencySettings,
    vesselClient: vesselClient.concurrencySettings,
    calculationApiClient: {
      get maxConcurrency() {
        return calculation.getCalculationRequestMaxConcurrency();
      },
      set maxConcurrency(newValue) {
        return calculation.setCalculationRequestMaxConcurrency(newValue);
      },
    },
    externalVesselApiClient: {
      get maxConcurrency() {
        return externalVessel.getVesselApiMaxConcurrency();
      },
      set maxConcurrency(newValue) {
        return externalVessel.setVesselApiMaxConcurrency(newValue);
      },
    },
  };
}

export function getCurrentWorkbookViewModel() {
  const appState = getAppViewModel();
  return appState.workbooksById[appState.activeWorkbookId];
}

export function getCurrentWorkbookViewModelWithWorksheetsAndCalculationResults() {
  const appState = getAppViewModel();
  const workbook = getCurrentWorkbookViewModel();
  const worksheets = Object.values(appState.worksheetsById).filter(
    (_) => _.workbookId === workbook.id
  );
  const calculationResults = worksheets.flatMap((_) => appState.calculationsByWorksheetId[_.id]);

  return {
    ...workbook,
    worksheets: worksheets,
    calculationResults: calculationResults,
  };
}

export function getCalculationResultForVesselEntryId(vesselEntryId) {
  const appState = getAppViewModel();
  return Object.values(appState.calculationsByWorksheetId)
    .flatMap((_) => _.calculationResults)
    .filter((_) => _.vesselEntryId)
    .find((_) => _.vesselEntryId === vesselEntryId);
}

export function shouldCompareClientServerRouteResponse() {
  if (
    window.calcDebugging != null &&
    window.calcDebugging.compareClientServerRouteResponse != null
  ) {
    return window.calcDebugging.compareClientServerRouteResponse;
  }

  return false;
}

export function enableClientServerRouteResponseComparison() {
  window.calcDebugging = { compareClientServerRouteResponse: true };
}
