import { format } from 'date-fns';
import { veApi } from 'api';
import { saveToFile } from 'utilities/save-blob-to-file';

interface IWorksheetCalculationSummaryPair {
  worksheet: any;
  calulationSummary: ICalculationViewModel;
}

export function exportCalculationComparison(
  workbookName: string,
  worksheetCalculationSummaryPairs: IWorksheetCalculationSummaryPair[]
) {
  return async (dispatch) => {
    const response = await veApi.post(
      '/export/calculation-comparison-export',
      {
        workbookName,
        worksheetCalculationSummaryPairs,
        exportCreatorTimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      { responseType: 'blob' }
    );

    const date = format(new Date(), 'dd MM yyyy');
    const filename = `Voyage Estimator comparison_${date}.xlsx`;
    saveToFile(response.data, filename);
  };
}
