import React, { createRef } from 'react';
import { EditableLabel } from 'components/editable-label';
import { veApi } from 'api';

export class WorkbookNameCellEditor extends React.Component {
  constructor(props) {
    super(props);

    this.textInput = createRef();

    this.state = {
      workbookName: this.props.value,
      workbook: this.props.node.data,
    };
    this.renameWorkbookHandler = this.renameWorkbookHandler.bind(this);
  }
  render() {
    const { workbookName, workbook } = this.state;
    return (
      <EditableLabel
        text={workbookName}
        onChange={this.renameWorkbookHandler(workbook)}
        labelClassName="workbook-card__cell workbook-card__cell--name"
        inputClassName="workbook-card__cell workbook-card__cell--name"
        ref={this.textInput}
        isEditing={true}
      />
    );
  }

  renameWorkbookHandler = (workbook) => (workbookName) => {
    const originalWorkbookName = this.state.workbookName;
    if (workbookName && workbookName !== originalWorkbookName) {
      const updatedWorkbook = { ...workbook, name: workbookName };
      veApi
        .put('/workbook/' + updatedWorkbook.id, {
          workbook: updatedWorkbook,
        })
        .then((response) => {
          this.props.onSuccess();
          this.props.api.stopEditing();
        }); // TODO handle the case when we fail to rename the workbook need UI error handling case
    }
    if (workbookName && workbookName === originalWorkbookName) {
      this.props.api.stopEditing();
    }
  };

  getValue() {
    return this.state.workbookName;
  }
}
