import React, { FC } from 'react';
import { connect } from 'react-redux';

import { selector } from './selector';
import VesselAutoComplete from '../../components/vessel-autocomplete';
import { Header } from '../../components/headers';
import MaritimeDate from '../../components/maritime-date';
import { cargoQuantityChangedAllLegs } from '../../actions/worksheet/voyage';
import { vesselUpdated, openDateUpdated } from '../../actions/worksheet/vessel';

import './styles.scss';
import CalcInput from '../../components/calc-input';
import { isFeatureEnabled } from '../../config/feature-control';

const GeneralInformation: FC = ({
  balticTankerIndexVessels,
  worksheetId,
  marketSegmentId,
  cargoSize,
  vesselId,
  vesselName,
  openDate,
  vesselEntryId,
  vesselUpdated,
  cargoQuantityChangedAllLegs,
  openDateUpdated,
}) => (
  <div>
    <Header text="General Information" />
    <div style={{ display: 'flex' }}>
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Vessel Name / Type</label>
        <VesselAutoComplete
          marketSegmentId={marketSegmentId}
          data-testid="worksheet-vessel-grid-vesselautocomplete-input"
          vessel={{ vesselId, vesselName }}
          onChange={(vessel) => {
            vesselUpdated({
              vessel,
              worksheetId,
              vesselEntryId,
              marketSegmentId,
            });
          }}
          balticIndexVessels={balticTankerIndexVessels}
          diagnosticId="VesselsGrid/ChangeVessel"
          isMandatory
        />
      </div>
      {!isFeatureEnabled('wetCargoLiteMultiLeg') && (
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Cargo Size</label>
          <CalcInput
            userSpecifiedValue={cargoSize}
            tankersMandatory
            maxDecimalDigits={2}
            onInputChange={(newCargoSize) => cargoQuantityChangedAllLegs(newCargoSize, worksheetId)}
            isMandatory
            isNumericType
            unit="MT"
            isEditable
            name="Cargo Size"
          />
        </div>
      )}
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Open Date</label>
        <MaritimeDate
          value={openDate}
          onBlur={(newValue) => openDateUpdated(newValue, worksheetId, vesselEntryId)}
        />
      </div>
    </div>
  </div>
);

const mapStateToProps = selector;

export default connect(mapStateToProps, {
  vesselUpdated,
  openDateUpdated,
  cargoQuantityChangedAllLegs,
})(GeneralInformation);
