import React from 'react';
import { connect } from 'react-redux';
import { updateBunkerPrice } from 'actions/bunker-ports';
import {
  setBunkerPricesPanelReadOnly,
  showBunkerPortsTable,
  refreshBunkersForWorksheet,
} from 'actions/bunker-ports';

import {
  rowClassName,
  tableName,
  tableFlex,
  leftColFixed,
  scrollableCol,
  LocationSection,
  Header,
  HeaderRefreshIcon,
  bunkerRows,
} from './bunker-table';

const BunkerTable = (props: Props) => {
  const { onQuantityChanged, onPriceChanged } = props;

  const flexLeft = () => {
    return (
      <div key={`flex-left`} className={`${leftColFixed}`}>
        <LocationSection {...props} key={`flex-loc-section`} />
      </div>
    );
  };

  const flexScrollable = () => {
    return (
      <div key={`flex-scrollable-main`} className={`${scrollableCol}`}>
        <div key={`flex-scrollable-header`} className={`${rowClassName}`}>
          <Header {...props} />
          <HeaderRefreshIcon {...props} />
        </div>
        {bunkerRows({
          ...props,
          onQuantityChanged,
          onPriceChanged,
        })}
      </div>
    );
  };

  // sample code: fixed right column, its not used ATM
  // const flexRight = () => {
  //   return (
  //     <div className={`${rightColFixed}`}>
  //       <div className={`${rowClassName}`}>
  //         <div className={`${colClassName}`}>Col values</div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      <div key={`${tableName}`} className={`${tableName}`}>
        <div key={`${tableFlex}`} className={`${tableFlex}`}>
          {/* Fixed Data - Left */}
          {flexLeft()}
          {/* Scrollable Data */}
          {flexScrollable()}
          {/* Fixed Data - Right */}
          {/* {flexRight()} */} {/* Not used ATM */}
        </div>
      </div>
    </>
  );
};

export default connect(
  /*data props:*/ null,
  /*action props:*/ {
    updateBunkerPrice,
    showBunkerPortsTable,
    refreshBunkersForWorksheet,
    setBunkerPricesPanelReadOnly,
  }
)(BunkerTable);
