import React from 'react';
import { SearchIcon } from 'components/icons';

const BunkerPortsFilterInput = ({ filter, onChange }) => {
  return (
    <div className="bunker-ports-table__header--filter">
      <SearchIcon className="bunker-ports-table__header--filter-icon" />
      <input
        value={filter}
        onChange={onChange}
        placeholder="Search"
        className="bunker-ports-table__header--filter-input"
      />
    </div>
  );
};

export default BunkerPortsFilterInput;
