import { getDraftUnitTypeById } from 'constants/enums/draft-units';
import { getImmersionUnitTypeById } from 'constants/enums/immersion-units';
import { getGrainUnitTypeById } from 'constants/enums/grain-units';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { createSelector } from 'reselect';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';

export const selector = createSelector(
  getActiveWorksheetId,
  getActiveWorksheetInvariantProps,
  (state) => getActiveWorksheet(state).voyage.shouldAutoCalculateIntake,
  (state, props) => props.vessel,
  (state) => state.referenceData && state.referenceData.balticIndexVessels,
  (worksheetId, worksheetInvariantProps, shouldAutoCalculateIntake, vessel, balticIndexVessels) => {
    return {
      worksheetId,
      worksheetInvariantProps,
      shouldAutoCalculateIntake,
      vessel: vesselSelector(vessel),
      balticIndexVessels: balticIndexVessels || [],
    };
  }
);

export const vesselSelector = createSelector(
  (vessel) => vessel,
  (vesselViewModel: IVesselViewModel) => {
    return {
      ...mapToOpenPositionUIComponentModel(vesselViewModel.openPosition),
      id: vesselViewModel.entryId,
      vesselIdName: {
        vesselId: vesselViewModel.vesselId,
        vesselName: vesselViewModel.name,
        isBalticVessel: vesselViewModel.isBalticVessel || false,
      },
      deadWeight: vesselViewModel.deadWeight,
      draft: vesselViewModel.draft,
      draftUnit: getDraftUnitTypeById(vesselViewModel.draftUnit),
      tpcmi: vesselViewModel.tpcmi,
      immersionUnit: getImmersionUnitTypeById(vesselViewModel.immersionUnit),
      grain: vesselViewModel.grain,
      grainUnit: getGrainUnitTypeById(vesselViewModel.grainUnit),
      constants: vesselViewModel.constants,
      scrubber: vesselViewModel.scrubber,
      isParcelVoyage: vesselViewModel.isParcelVoyage || false,
    };
  }
);

// TODO - try to refactor not to invent a new return type model (without any typing). Just use IOpenPositionViewModel.
export function mapToOpenPositionUIComponentModel(openPositionViewModel: IOpenPositionViewModel) {
  let openPosition = { locationId: null, locationName: '' };
  let openDate = { start: null, end: null, displayFormat: '' };

  if (openPositionViewModel && openPositionViewModel.openDate) {
    openPosition.locationId = openPositionViewModel.id;
    openPosition.locationName = openPositionViewModel.name;

    openDate = openPositionViewModel.openDate;
  }
  return {
    openPosition: openPosition,
    openDate: openDate,
  };
}
