import React from 'react';
import * as RedirectManager from 'utilities/redirect-manager';

export const WorkbookWorksheetCountRenderer = (props) => {
  const workbook = props.data;
  const url = `workbook/${workbook.id}`;
  const worksheetCount = props.value;
  const storeURLInLocalStorage = () => {
    RedirectManager.storeURL(url);
  };
  return (
    <a
      tabIndex="0"
      href={'#/' + url}
      className="workbook-card__cell workbook-card__cell--worksheet-count"
      onMouseDown={storeURLInLocalStorage}
    >
      {worksheetCount}
    </a>
  );
};
