import React from 'react';
import { CopyWorkbookButton } from 'modules/search-panel/components/copy-workbook-button';

export const ActionsRenderer = (props) => {
  const workbook = props.data;

  return (
    <div className="workbook-card__cell--actions">
      <CopyWorkbookButton workbook={workbook} onCopied={props.onCopied} />
    </div>
  );
};
