import { calculationApi } from 'api';
import { rateLimited } from 'utilities/functions';
import { ballastBonusId } from 'modules/additional-expenses/constants';
import { mapWorksheetViewModelToAdditionalExpensesCommonModel } from 'api/request-builders/worksheet-common/additional-expenses';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import sum from 'lodash/sum';
import orderBy from 'lodash/orderBy';
import stringEnum, { StringEnum } from 'utilities/enum/string-enum';
import VError from 'verror';
import axios from 'axios';
import { CalculationStatus } from 'constants/enums/calculation-status';
import * as exceptionSink from 'diagnostics/unhandled-exception-sink';
import {
  createPermutationsOfSpeedAndConsumptionsAsSpeedAndConsumptionSets,
  mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry,
  groupExternalSpeedAndConsumptionsByType,
  getMissingRequiredSpeedAndConsumptionsTypes,
} from 'api/clients/external-vessel';
import { getSpeedAndConsumptionsForVesselIdRateLimited } from 'api/clients/external-vessel';
import { FUEL_GRADE_380CST } from 'constants/enums/fuel-grades';
import { LADEN, BALLAST, ECO_BALLAST, ECO_LADEN } from '../../constants/enums/speed-and-con-types';
import { charterTypes } from '../../constants/enums/charter-types';
import { CalculateSensitivity, CalculateWorksheet } from 'api/clients/calculate';
import { ICalculationWebApiRequest } from 'api/request-builders/calculate-estimate-request-builder';

let calculationRequestMaxConcurrency = 10;
let calculationRequestMaxConcurrencyFromApi = 10;

export const getRateLimitedCalculationResult = async (request, worksheet, { cancelToken }) => {
  const response = await getRateLimitedCalculationResponse(request, {
    cancelToken: cancelToken,
  });

  const customOrderedAdditionalCostItems = getCustomOrderAditionalCostsNetItems(
    response.data.additionalCostsNetItems
  );
  if (customOrderedAdditionalCostItems) {
    response.data.additionalCostsNetItems = customOrderedAdditionalCostItems;
  }

  const calculationResult = response.data;

  enrichCalculationResult(request, calculationResult, worksheet);

  return calculationResult;
};

export const getRateLimitedCalculationResultFromApi = async (request, { cancelToken }) => {
  const response = await getRateLimitedCalculationResponseFromApi(request, {
    cancelToken: cancelToken,
  });
  return response;
};

export const getRateLimitedCalculationSensitivityResult = async (request, cancelToken) => {
  const response = await getRateLimitedCalculationSensitivityResponse(request, {
    cancelToken: cancelToken,
  });

  const calculationResult = response.data;

  return calculationResult;
};

const getCustomOrderAditionalCostsNetItems = (additionalCostsNetItems) => {
  if (!(additionalCostsNetItems && additionalCostsNetItems.length)) {
    return;
  }
  const ballastBonusIndex = additionalCostsNetItems.findIndex(
    (d) => d.id.indexOf('ballastBonus') > -1
  );
  if (ballastBonusIndex === -1 || ballastBonusIndex === 0) return;
  const data = [...additionalCostsNetItems];

  let start = 0;
  let deleteCount = ballastBonusIndex;
  if (data[0].id.indexOf('Canal') > -1) {
    start = 1;
    deleteCount = ballastBonusIndex - 1;
  }

  const additionalItems = data.splice(start, deleteCount);
  return data.concat(additionalItems);
};

//Squeeze this into a single request
//TODO Replace this with the web api path
const getCalculationResponse = async (request, { cancelToken }) => {
  return await calculationApi.post('/api/Calculate', request, {
    cancelToken: cancelToken,
  });
};

const getCalculationResponseFromApi = async (request, { cancelToken }) => {
  return await CalculateWorksheet(request, {
    cancelToken: cancelToken,
  });
};

let getRateLimitedCalculationResponse = rateLimited(
  calculationRequestMaxConcurrency,
  getCalculationResponse
);

let getRateLimitedCalculationResponseFromApi = rateLimited(
  calculationRequestMaxConcurrencyFromApi,
  getCalculationResponseFromApi
);

let getRateLimitedCalculationSensitivityResponse = rateLimited(
  calculationRequestMaxConcurrency,
  CalculateSensitivity
);

const enrichCalculationResult = (request, calculationResult, worksheet) => {
  /* # Below go all enrichments that have to happen even despite an error.
       NOTE: Use judiciously - prefer solutions that advise user that there is an error, rather than present empty data:
  */
  enrichVoyageDistances(request, calculationResult, worksheet);

  if (calculationResult.errors.length !== 0) {
    /* for now we don't need to hydrate validation responses to errors. If there was such a logic needed it would go here.*/

    /* This return facitates the simplicity of the subsequent enrichers, they don't need to care about erroneous scenarios*/
    return;
  }

  /* # Below go all enrichments that only make sense for a successful calculation:*/
  enrichAdditionalCosts(calculationResult, worksheet);
};

const enrichAdditionalCosts = (calculationResult, worksheet) => {
  const expenseNamesById = {
    // Below are cost items that only show in response of the calculation (the information necessary for the Calculation API to determine whether the cost will apply is decomposed into other properties, because it is also used for other purposes):
    // See `CalculationImplicitAdditionalCostItemId` in `ts\` folder - TODO make TypeScript compilation work and replace with `[CalculationImplicitAdditionalCostItemId.Type]: "name"`
    CanalTransitCosts: 'Canal or pilotage',
    [ballastBonusId]: 'Ballast Bonus ($)',

    // Below are cost items that are explicitly represented as 'Additional Costs' in the calculation request:
    ...mapValues(
      keyBy(mapWorksheetViewModelToAdditionalExpensesCommonModel(worksheet), (_) => _.id),
      (_) => _.description
    ),
  };
  for (const additionalCostsNetItem of calculationResult.additionalCostsNetItems) {
    additionalCostsNetItem.name = expenseNamesById[additionalCostsNetItem.id];
  }
};

const enrichVoyageDistances = (request, calculationResult, worksheet) => {
  calculationResult.distances =
    calculationResult.errors.length !== 0
      ? {
          totalDistance: 0,
          secaDistance: 0,
        }
      : {
          totalDistance: sum(request.legs.map((_) => _.distanceNonSeca + _.distanceSeca)),
          secaDistance: sum(request.legs.map((_) => _.distanceSeca)),
        };
};

export const setCalculationRequestMaxConcurrency = (newMaxConcurrency: number) => {
  calculationRequestMaxConcurrency = newMaxConcurrency;
  getRateLimitedCalculationResponse = rateLimited(
    calculationRequestMaxConcurrency,
    getCalculationResponse
  );
};

export const getCalculationRequestMaxConcurrency = () => {
  return calculationRequestMaxConcurrency;
};

const speedAndConsumptionsModesArray = ['manual', 'findCheapest'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556);
export type SpeedAndConsumptionsMode = 'manual' | 'findCheapest';
export const speedAndConsumptionsModes: StringEnum<SpeedAndConsumptionsMode> = stringEnum(
  speedAndConsumptionsModesArray
);

export async function tryBuildRequestAndGetRateLimitedCalculationResult(
  speedAndConsumptionsMode,
  selectedCharterTypes,
  worksheet,
  waypoints,
  vessel,
  shouldAutoCalculateIntake,
  cancelToken,
  carbonFactors,
  cheapestSpeedAndConsumptions,
  calculationResult,
  isEditable
): ICalculationViewModel {
  const calculationWebApiRequest: ICalculationWebApiRequest = {
    autoIntake: shouldAutoCalculateIntake,
    speedAndConsumptionsMode: speedAndConsumptionsMode,
    vesselEntryId: vessel.entryId,
    euaInputData: getEuaInputData(worksheet),
    worksheetId: worksheet.id,
    workbookId: worksheet.workbookId,
    isEditable: isEditable,
    worksheet: worksheet,
  };

  var resultFromApi = await tryBuildRequestAndGetRateLimitedCalculationResultFromWebApi(
    selectedCharterTypes,
    calculationWebApiRequest,
    cancelToken
  );
  return resultFromApi;
}

export async function tryBuildRequestAndGetRateLimitedCalculationResultFromWebApi(
  selectedCharterTypes,
  calculationWebApiRequest: ICalculationWebApiRequest,
  cancelToken
): ICalculationViewModel {
  try {
    switch (calculationWebApiRequest.speedAndConsumptionsMode) {
      case speedAndConsumptionsModes.manual: {
        const calculationResult = await tryRateLimitedGetCalculationResultFromWebApi(
          calculationWebApiRequest,
          cancelToken
        );
        return calculationResult;
      }
      case speedAndConsumptionsModes.findCheapest: {
        const calculationResults = await tryRateLimitedGetCalculationResultFromWebApi(
          calculationWebApiRequest,
          cancelToken
        );
        await isValidVessel(calculationResults);

        const currentVesselCalculationResultWithError = calculationResults.find(
          (_) => _.errors && _.errors.length > 0
        );

        if (currentVesselCalculationResultWithError) {
          return {
            diagnostics: {
              allCalculations: currentVesselCalculationResultWithError,
              allCalculationsOrderedBy: null /* due to error - no need to order */,
              selectedCharterTypes: [...selectedCharterTypes],
            },
            ...currentVesselCalculationResultWithError,
          };
        }

        const isTimeCharterOnly =
          selectedCharterTypes.length === 1 && selectedCharterTypes[0] === charterTypes.time;
        const orderedCurrentVesselCalculationResults = orderBy(
          calculationResults,
          (_) =>
            isTimeCharterOnly
              ? _.timeCharter.voyageRateCalculatedGross
              : _.voyageRate.timeCharterRateCalculatedGross,
          ['asc']
        );

        return {
          diagnostics: {
            allCalculations: orderedCurrentVesselCalculationResults,
            allCalculationsOrderedBy: isTimeCharterOnly
              ? 'timeCharter.voyageRateCalculatedGross'
              : 'voyageRate.timeCharterRateCalculatedGross',
            selectedCharterTypes: [...selectedCharterTypes],
          },
          ...orderedCurrentVesselCalculationResults[0],
        };
      }
      default:
        throw new Error(`Could not run tryBuildRequestAndGetRateLimitedCalculationResult`);
    }
  } catch (error) {
    if (axios.isCancel(error))
      /*We do want to `throw` here even though we pass all other errors to results.Cancellations only happen via `debounce`, because a fresh call with new data is required. As such, we can stop the loop and discard all of these results. Importantly, the reason is not only to save resources, but also because we want to prevent such cancellations being returned as items and be presented to the user.*/
      throw error;

    if (error.name === 'CalculateBuildRequestErrors') {
      return {
        errors: [error],
        calculationStatus: CalculationStatus.FAILED,
      };
    }
    /* followed the reasoning from `tryRateLimitedGetCalculationResult` to capture all errors here and in turn they will placed in the store for UI components to surface to the user */
    exceptionSink.send(error);
    return {
      errors: [error],
      calculationStatus: CalculationStatus.FAILED,
    };
  }
}

async function tryRateLimitedGetCalculationResultFromWebApi(
  calculationWebApiRequest: ICalculationWebApiRequest,
  cancelToken
) {
  var response;
  if (calculationWebApiRequest.speedAndConsumptionsMode === speedAndConsumptionsModes.manual) {
    calculationWebApiRequest.workbookId = undefined;

    response = (
      await getRateLimitedCalculationResultFromApi(calculationWebApiRequest, {
        cancelToken: cancelToken,
      })
    )[0];

    return {
      ...response,
      calculationStatus:
        response.errors && response.errors.length
          ? CalculationStatus.FAILED
          : CalculationStatus.LOADED,
    };
  } else if (
    calculationWebApiRequest.speedAndConsumptionsMode === speedAndConsumptionsModes.findCheapest
  ) {
    calculationWebApiRequest.workbookId = undefined;
    calculationWebApiRequest.findCheapest = true;
    response = await getRateLimitedCalculationResultFromApi(calculationWebApiRequest, {
      cancelToken: cancelToken,
    });
    var responseWithStatus = response.map((_) => ({
      ..._,
      calculationStatus:
        _.errors && _.errors.length ? CalculationStatus.FAILED : CalculationStatus.LOADED,
    }));
    return responseWithStatus;
  }

  return null;
}

export async function getPermutationsOfSpeedAndConsumptionsForVessel(
  vessel: IVesselViewModel
): ISpeedAndConsumptionsViewModel[] {
  /* 
   Due to the assumption that when in find by cheapest mode, all vessels need to have a vesselId so that their speed and consumptions can be acquired, permutations created, and cheapest result then selected. However if a user manually enters a vessel, there's no such vesselId and it cannot be included used in find by cheapest mode. Thus throwing an exception here is a cheap means of blanking this result. TODO it would preferable to have a means of communicating this lack of find by cheapest for this vessel
  */
  if (!vessel.vesselId) {
    throw VError(
      {
        name: 'CalculateBuildRequestErrors',
        info: {
          vesselEntryId: vessel.entryId,
        },
      },
      'No valid vesselId for vessel'
    );
  }
  const externalSpeedAndConsumptions = await getSpeedAndConsumptionsForVesselIdRateLimited(
    vessel.vesselId
  );

  const speedAndConsumptionsGroupedByType = groupExternalSpeedAndConsumptionsByType(
    externalSpeedAndConsumptions
  );

  const missingSpeedAndConsumptionTypes = getMissingRequiredSpeedAndConsumptionsTypes(
    speedAndConsumptionsGroupedByType
  );

  if (missingSpeedAndConsumptionTypes.length) {
    throw new VError(
      {
        name: 'CalculateBuildRequestErrors',
        info: {
          vesselEntryId: vessel.entryId,
          vesselId: vessel.vesselId,
          missingSpeedAndConsumptionTypes,
          speedAndConsumptionsGroupedByType,
          externalSpeedAndConsumptions,
        },
      },
      'Missing required speed and consumption types for vessel'
    );
  }

  const externalSpeedAndConsumptionSets =
    createPermutationsOfSpeedAndConsumptionsAsSpeedAndConsumptionSets(
      speedAndConsumptionsGroupedByType
    );

  const speedAndConsumptions = externalSpeedAndConsumptionSets.map(
    (externalSpeedAndConsumptionSet) => ({
      ...vessel.speedAndConsumptions,
      /* ensure that existing ballast and ecoBallast are both inactive*/
      // TODO refactor speed and consumptions such that view model concerns do not leak over into API
      ballast: { isActive: false },
      ecoBallast: {
        isActive: false,
      },
      /* override either ballast or ecoBallast and set it to active */
      [externalSpeedAndConsumptionSet.ballast.vesselSpeedAndConsumptionActionId === BALLAST.id
        ? BALLAST.key
        : ECO_BALLAST.key]: {
        ...mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
          externalSpeedAndConsumptionSet.ballast
        ),
        isActive: true,
      },
      /* ensure that existing laden and ecoLaden are both inactive*/
      laden: { isActive: false },
      ecoLaden: { isActive: false },
      /* override either ballast or ecoBallast and set it to active */
      [externalSpeedAndConsumptionSet.laden.vesselSpeedAndConsumptionActionId === LADEN.id
        ? LADEN.key
        : ECO_LADEN.key]: {
        ...mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
          externalSpeedAndConsumptionSet.laden
        ),
        isActive: true,
      },
      canal:
        /* no canal speed and consumption type provided by external vesselAPI, so used idle here instead */
        mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
          externalSpeedAndConsumptionSet.idle
        ),
      idle: mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
        externalSpeedAndConsumptionSet.idle
      ),
      loading: mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
        externalSpeedAndConsumptionSet.loading
      ),
      discharging: mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
        externalSpeedAndConsumptionSet.discharging
      ),
      manoeuvring: (externalSpeedAndConsumptionSet.manoeuvring &&
        mapExternalSpeedAndConsumptionToSpeedAndConsumptionEntry(
          externalSpeedAndConsumptionSet.manoeuvring
        )) /* manoeuvring is deemed an optional field however an "empty" speed and consumption is used here to satisfy the calculation */ || {
        speed: 0,
        engineFuelConsumption: 0,
        engineFuelGrade: FUEL_GRADE_380CST.key,
        generatorFuelConsumption: 0,
        generatorFuelGrade: FUEL_GRADE_380CST.key,
      },
    })
  );
  return speedAndConsumptions;
}

export async function isValidVessel(calculationResults): boolean {
  /* 
   Due to the assumption that when in find by cheapest mode, all vessels need to have a vesselId so that their speed and consumptions can be acquired, permutations created, and cheapest result then selected. However if a user manually enters a vessel, there's no such vesselId and it cannot be included used in find by cheapest mode. Thus throwing an exception here is a cheap means of blanking this result. TODO it would preferable to have a means of communicating this lack of find by cheapest for this vessel
  */
  if (
    calculationResults &&
    calculationResults.length &&
    calculationResults[0].errors[0] === 'Invalid vessel id'
  ) {
    throw VError(
      {
        name: 'CalculateBuildRequestErrors',
        info: {
          vesselEntryId: calculationResults[0].vesselEntryId,
        },
      },
      'No valid vesselId for vessel'
    );
  }
  return true;
}

function getEuaInputData(worksheet) {
  const euaRouteByLeg = worksheet.voyage.legs.map((leg) => {
    const segments = [];
    let currentSum = 0;
    let currentFlags = null;
    const route =
      leg.inboundRoute.variants[0].routeAsSequenceOfDistancesThroughOperationalRestrictions ?? [];

    for (let i = 0; i < route.length; i++) {
      const flags = getOperationalRestrictionFlags(route[i].operationalRestrictionIds);
      if (currentFlags === null) {
        currentFlags = flags;
        currentSum = route[i].distance;
      } else if (currentFlags === flags) {
        currentSum += route[i].distance;
      } else {
        segments.push({
          distance: currentSum,
          operationalRestrictionFlags: currentFlags,
        });
        currentFlags = flags;
        currentSum = route[i].distance;
      }

      if (i === route.length - 1) {
        segments.push({
          distance: currentSum,
          operationalRestrictionFlags: currentFlags,
        });
      }
    }

    return {
      legId: leg.id,
      segments,
    };
  });
  return {
    euaYear: worksheet.eua.euaYear,
    euaRouteByLeg,
  };
}

function getOperationalRestrictionFlags(operationalRestrictionIds) {
  let result = 0;
  if (operationalRestrictionIds.has('seca')) {
    result += 1;
  }
  if (operationalRestrictionIds.has('sludgeDischargeBan')) {
    result += 2;
  }
  return result;
}
