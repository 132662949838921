import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { selector } from './selector';
import SummaryDataRow from '../summary-data-row';

type Props = {
  timeCharterRateCalculatedNet: Number,
  dollarPerTonne: Number,
  totalDaysVoyageProfitOrLossEstimate: Number,
};
export class VoyTotals extends Component<Props> {
  render() {
    return (
      <div className="voy-totals-section">
        <span className="voy-totals-label">
          <span className="voy-totals-label-text">Voyage Totals</span>
        </span>

        <div className="voy-totals-data">
          <SummaryDataRow
            data={this.props.timeCharterRateCalculatedNet}
            title="TCE"
            unit="$"
            unitPrecedesValue
            whiteFontCell
          />
          <SummaryDataRow
            data={this.props.dollarPerTonne}
            title="$ / tonne"
            unit="$"
            unitPrecedesValue
            whiteFontCell
          />
          <SummaryDataRow
            data={this.props.totalDaysVoyageProfitOrLossEstimate}
            title="TC Profit"
            unit="$"
            unitPrecedesValue
            whiteFontCell
          />
        </div>
        <div className={'voy-totals-asterisk-label'}>
          <span className={'voy-totals-asterisk-label__text'}>
            *Carbon Cost is not included in the Voyage Totals.
          </span>
        </div>
        <div className={'voy-totals-asterisk-label'}>
          <span className={'voy-totals-asterisk-label__text'}>
            **All voyage estimations include a 5% weather factor (sea margin).
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

export const VoyTotalsContainer = connect(mapStateToProps)(VoyTotals);
