import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import {
  SET_STAGE_LEFT_PANEL,
  SET_STAGE_RIGHT_PANEL,
  SELECTED_RIGHT_PANEL_KEY,
  SET_ROUTE_GRAPH_VERSION,
} from 'constants/action-types/stage';

import { WORKSHEET_PANEL, INDIVIDUAL_SUMMARY_PANEL } from 'constants/enums/stage-panels';
import { SET_BUNKER_PRICES_PANEL_READONLY } from 'constants/action-types/bunker-ports';
import { SHOW_CARBON_FACTOR_TABLE_MODAL } from '../../constants/action-types/carbon';
import { SHOW_SPEED_AND_CONS_EDIT_MODAL } from '../../constants/action-types/worksheet/speed-and-consumptions';

const initialState = {
  stageLeftPanel: WORKSHEET_PANEL,
  stageRightPanel: sessionStorage.getItem(SELECTED_RIGHT_PANEL_KEY) || INDIVIDUAL_SUMMARY_PANEL,
  routeGraphVersion: -1,
  messageQueue: undefined,
  isCarbonFactorTableModalVisible: false,
  isSpeedAndConsEditModalVisible: false,
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_ROUTE_GRAPH_VERSION:
      return {
        ...state,
        routeGraphVersion: action.payload,
      };
    case LOAD_WORKSHEET:
      return {
        ...state,
        isOverlayVisible: false,
        isWorkbookPanelOpen: false,
        stageLeftPanel: WORKSHEET_PANEL,
        isBunkerPricesPanelReadOnly: true,
      };
    case SET_STAGE_LEFT_PANEL:
      return {
        ...state,
        stageLeftPanel: action.payload,
      };
    case SET_STAGE_RIGHT_PANEL:
      return {
        ...state,
        stageRightPanel: action.payload,
      };
    case SET_BUNKER_PRICES_PANEL_READONLY:
      return {
        ...state,
        isBunkerPricesPanelReadOnly: action.payload,
      };
    case SHOW_CARBON_FACTOR_TABLE_MODAL:
      return {
        ...state,
        isCarbonFactorTableModalVisible: action.payload,
      };

    case SHOW_SPEED_AND_CONS_EDIT_MODAL:
      return {
        ...state,
        isSpeedAndConsEditModalVisible: action.payload,
      };
    default:
      return state;
  }
};
