import { operationalRestrictionIds } from 'constants/operational-restrictions';

type SubtotalPredicate = (subtotal: ISubtotalByOperationalRestrictionIdsViewModel) => boolean;

export const isUnrestrictedSubtotal: SubtotalPredicate = (subtotal) =>
  subtotal.operationalRestrictionIds.size === 0;

export const isSecaOnlySubtotal: SubtotalPredicate = (subtotal) =>
  subtotal.operationalRestrictionIds.has(operationalRestrictionIds.seca) &&
  subtotal.operationalRestrictionIds.size === 1;

export const isBannedOnlySubtotal: SubtotalPredicate = (subtotal) =>
  subtotal.operationalRestrictionIds.size === 1 &&
  subtotal.operationalRestrictionIds.has(operationalRestrictionIds.sludgeDischargeBan);

export const isSecaAndBannedSubtotal: SubtotalPredicate = (subtotal) =>
  subtotal.operationalRestrictionIds.size === 2 &&
  subtotal.operationalRestrictionIds.has(operationalRestrictionIds.seca) &&
  subtotal.operationalRestrictionIds.has(operationalRestrictionIds.sludgeDischargeBan);

export const doesSubtotalContainSeca: SubtotalPredicate = (subtotal) =>
  subtotal.operationalRestrictionIds.has(operationalRestrictionIds.seca);

export const doesSubtotalNotContainSeca: SubtotalPredicate = (subtotal) =>
  !doesSubtotalContainSeca(subtotal);

export function sumUserEditedDistancesBy(
  subtotals: ISubtotalByOperationalRestrictionIdsViewModel[],
  shouldIncludeSubtotalInSum: SubtotalPredicate
): number {
  return subtotals
    .filter(shouldIncludeSubtotalInSum)
    .reduce((total, subtotal) => total + subtotal.userEditedDistance, 0);
}

export function sumRoutingApiDistancesBy(
  subtotals: ISubtotalByOperationalRestrictionIdsViewModel[],
  shouldIncludeSubtotalInSum: SubtotalPredicate
): number {
  return subtotals
    .filter(shouldIncludeSubtotalInSum)
    .reduce((total, subtotal) => total + subtotal.routingApiDistance, 0);
}
