import { SET_ACTIVE_VESSEL_ENTRY_ID } from '../../constants/action-types/user-state';

export function setActiveVesselEntryId(newActiveVesselEntryId) {
  return {
    type: SET_ACTIVE_VESSEL_ENTRY_ID,
    payload: {
      newActiveVesselEntryId: newActiveVesselEntryId,
    },
  };
}
