import React, { useState } from 'react';
import { WarningIcon } from 'components/icons';
import './styles.scss';
import classNames from 'classnames';
import { validationLevels } from 'components/numeric-input';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

export const ValidationMessage = ({
  children,
  className,
  validationLevel,
  isDismissable,
  showWarningIcon = true,
  ...props
}) => {
  const [showValidationMessage, setShowValidationMessage] = useState(true);
  return (
    showValidationMessage && (
      <span
        className={classNames(className, {
          'validation-error validation-error--invalid': validationLevel === validationLevels.error,
          'validation-error validation-error--guidance':
            validationLevel === validationLevels.guidance,
        })}
        {...props}
      >
        {showWarningIcon && (
          <WarningIcon
            className={classNames(className, {
              'validation-error__icon--invalid': validationLevel === validationLevels.error,
              'validation-error__icon--guidance': validationLevel === validationLevels.guidance,
            })}
          />
        )}
        <div className="validation-error__content">{children}</div>
        {isDismissable ? (
          <IconButton
            className="validation-error__dismiss-button"
            icon={iconEnum.Close}
            onClick={() => setShowValidationMessage(!showValidationMessage)}
          />
        ) : null}
      </span>
    )
  );
};

export default ValidationMessage;
