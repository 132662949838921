import { Item } from '../comparison-table-setter-options';
import { CheckboxState, ItemState } from './';
import { eventDestination, trackEvent } from '../../../diagnostics/calc-trackevents';

export const updateItemStates = (oldState: ItemState[], items: Item[], clickedId: string) => {
  const newState = oldState.map((i) => ({ ...i }));
  const getItemState = (id: string) => {
    return newState.find((i) => i.columnId === id).state;
  };
  // setters
  const updateParent = (id: string) => {
    const item = items.find((i) => i.columnId === id);
    const parent = items.find((i) => i.columnId === item.parentId);
    if (!parent) return;
    const childIds = items.filter((i) => i.parentId === parent.columnId).map((i) => i.columnId);
    const childStates = childIds.map((childId) => getItemState(childId));
    if (childStates.length === childStates.filter((s) => s === CheckboxState.CHECKED).length) {
      newState.find((i) => i.columnId === parent.columnId).state = CheckboxState.CHECKED;
    } else if (
      childStates.length === childStates.filter((s) => s === CheckboxState.UNCHECKED).length
    ) {
      newState.find((i) => i.columnId === parent.columnId).state = CheckboxState.UNCHECKED;
    } else {
      newState.find((i) => i.columnId === parent.columnId).state = CheckboxState.INDETERMINATE;
    }
    updateParent(parent.columnId);
  };
  const setUnchecked = (id: string) => {
    newState.find((i) => i.columnId === id).state = CheckboxState.UNCHECKED;
    items
      .filter((i) => i.parentId === id)
      .map((i) => i.columnId)
      .forEach((childId) => setUnchecked(childId));
    updateParent(id);
    triggerMixpanelEvent(id);
  };
  const setChecked = (id: string) => {
    newState.find((i) => i.columnId === id).state = CheckboxState.CHECKED;
    items
      .filter((i) => i.parentId === id)
      .map((i) => i.columnId)
      .forEach((childId) => setChecked(childId));
    updateParent(id);
    triggerMixpanelEvent(id);
  };
  const triggerMixpanelEvent = (id) => {
    const isChild = newState.find((i) => i.parentId !== '' && i.columnId === id);

    isChild &&
      trackEvent(
        'ComparisonTable',
        'ComparisonColumnClicked',
        {
          columnName: newState.find((i) => i.columnId === id).name,
          checked: newState.find((i) => i.columnId === id).state,
        },
        {},
        eventDestination.ANALYSIS
      );
  };
  // actual logic
  const itemState = getItemState(clickedId);
  if (itemState === CheckboxState.CHECKED) {
    setUnchecked(clickedId);
  } else {
    setChecked(clickedId);
  }
  return newState;
};

export const updateExpandState = (oldState: ItemState[], clickedId) => {
  const newState = oldState.map((i) => ({ ...i }));
  const getExpandState = (id: string) => {
    return newState.find((i) => i.columnId === id).expanded;
  };
  newState.find((i) => i.columnId === clickedId).expanded = !getExpandState(clickedId);

  trackEvent(
    'ComparisonTable',
    'ComparisonColumnGroupClicked',
    {
      columnGroupName: newState.find((i) => i.columnId === clickedId).name,
      expanded: newState.find((i) => i.columnId === clickedId).expanded,
    },
    {},
    eventDestination.ANALYSIS
  );

  return newState;
};

export const deselectAllItems = (oldState: ItemState[]) => {
  const newState = oldState.map((i) => ({ ...i }));
  newState.map((e) => {
    return (e.state = CheckboxState.UNCHECKED);
  });
  return newState;
};

export const selectAllItems = (oldState: ItemState[]) => {
  const newState = oldState.map((i) => ({ ...i }));
  newState.map((e) => {
    return (e.state = CheckboxState.CHECKED);
  });
  return newState;
};
