import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppInitialisingSplashScreen } from './components/app-initialising-splash-screen';
import { loadUserState, loadUserPreference } from 'actions/user-state';
import { loadMyWorkbooks as loadWorkbooks } from 'actions/workbook';
import { loadWaypointsData } from 'actions/reference-data/waypoints';
import { loadBalticIndexVessels } from 'actions/reference-data/baltic-index-vessels';
import { loadBalticTankerIndexVessels } from 'actions/reference-data/baltic-tanker-index-vessels';
import Async from 'react-async';
import storePromiseOnCall from 'utilities/functions/store-promise-on-call';
import { loadCarbonFactor } from 'actions/carbon';

export class Loader extends Component {
  state = {
    promise: null,
  };

  render() {
    return (
      <Async promise={this.state.promise}>
        <Async.Pending>
          <AppInitialisingSplashScreen />
        </Async.Pending>
        <Async.Rejected>
          {(err) => {
            throw err;
          }}
        </Async.Rejected>
        <Async.Resolved>{this.props.children}</Async.Resolved>
      </Async>
    );
  }

  initialize = storePromiseOnCall(
    async () => {
      await this.props.loadUserState();
      await this.props.loadUserPreference();
      // loadUserState can end up creating the first worbook for user (first time user),
      // so need to run loadWorkbooks after it
      await this.props.loadWorkbooks();
      await Promise.all([
        this.props.loadWaypointsData(),
        this.props.loadBalticIndexVessels(),
        this.props.loadBalticTankerIndexVessels(),
        this.props.loadCarbonFactor(),
      ]);
    },
    /*storePromise*/ (promise) => this.setState({ promise: promise })
  );

  async componentDidMount() {
    await this.initialize();
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadUserState,
      loadUserPreference,
      loadWorkbooks,
      loadWaypointsData,
      loadBalticIndexVessels,
      loadBalticTankerIndexVessels,
      loadCarbonFactor,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Loader);
