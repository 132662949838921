import { getActiveWorkbook } from './get-active-workbook';
import { createStructuredSelectorFromBaseSelector } from 'utilities/redux/create-structured-selector-from-base-selector';

/**
  It can be especially used for optimization instead of the full `getActiveWorkbook`, where the reduced data is all that a component needs (it will keep returning the same instance regardless of Redux creating a new instance due to changes to other properties).
 */
export const getActiveWorkbookInvariantProps: Selector<IAppState, IWorkbookInvariantProps> =
  createStructuredSelectorFromBaseSelector(getActiveWorkbook, {
    id: (workbook) => workbook && workbook.id,
    marketSegmentId: (workbook) => workbook && workbook.marketSegmentId,
    createdDate: (workbook) => workbook && workbook.createdDate,
    userId: (workbook) => workbook && workbook.userId,
    reasonEditsNotAllowed: (workbook) => workbook && workbook.reasonEditsNotAllowed,
    isEditable: (workbook) => workbook && workbook.isEditable,
  });
