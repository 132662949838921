import { calculateMaximumLift } from 'api/clients/calculate';

interface IMaximumLiftResult {
  maximumLift: number | null;
  errors: string[];
}

export async function calculateMaxLiftFromApi(input): IMaximumLiftResult {
  if (!input.deadweight) {
    input.deadweight = 0;
  }
  if (!input.grain) {
    input.grain = 0;
  }
  if (!input.immersion) {
    input.immersion = 0;
  }
  if (!input.vesselDraft) {
    input.vesselDraft = 0;
  }
  return await calculateMaximumLift(input, null);
}
