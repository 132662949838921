import React from 'react';

import NumericInput from 'components/numeric-input';
import TableDropDown from 'components/table-dropdown';
import classNames from 'classnames';
import { isLaden, isBallast } from 'constants/enums/cargo-status';
class CanalsRow extends React.PureComponent {
  onBallastCostChange = (value) => {
    this.props.onBallastCostChange(this.props.canal.id, value);
  };

  onLadenCostChange = (value) => {
    this.props.onLadenCostChange(this.props.canal.id, value);
  };

  onBallastWaitingTimeChange = (value) => {
    this.props.onBallastWaitingTimeChange(this.props.canal.id, value);
  };

  onLadenWaitingTimeChange = (value) => {
    this.props.onLadenWaitingTimeChange(this.props.canal.id, value);
  };

  onBallastTransitTimeChange = (value) => {
    this.props.onBallastTransitTimeChange(this.props.canal.id, value);
  };

  onLadenTransitTimeChange = (value) => {
    this.props.onLadenTransitTimeChange(this.props.canal.id, value);
  };

  render() {
    const props = this.props;
    const { canal, ballastCostColumns, ladenCostColumns, ballastCostData, ladenCostData } = props;
    const {
      name,
      ballastCost,
      ladenCost,
      cargoStatus,
      ballastWaitingTime,
      ladenWaitingTime,
      ballastTransitTime,
      ladenTransitTime,
    } = canal;

    return (
      <div
        className={classNames({
          'canals-row': true,
          'canals-row--laden': isLaden(cargoStatus),
          'canals-row--ballast': isBallast(cargoStatus),
        })}
        data-testid={props['data-testid']}
      >
        <div className="canals-row__canal">{name}</div>
        <div className="canals-row__data">
          <NumericInput
            className={'canals-row__numeric-input--ballast'}
            diagnosticId="Canals/BallastTransitTime"
            value={ballastTransitTime}
            onInputChange={this.onBallastTransitTimeChange}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="99.99"
          />
          <NumericInput
            className={'canals-row__numeric-input--laden'}
            diagnosticId="Canals/LadenTransitTime"
            value={ladenTransitTime}
            onInputChange={this.onLadenTransitTimeChange}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="99.99"
          />
        </div>
        <div className="canals-row__data">
          <NumericInput
            value={ballastWaitingTime}
            diagnosticId="Canals/BallastWaitingTime"
            className={'canals-row__numeric-input--ballast'}
            onInputChange={this.onBallastWaitingTimeChange}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="99.99"
          />
          <NumericInput
            value={ladenWaitingTime}
            diagnosticId="Canals/LadenWaitingTime"
            className={'canals-row__numeric-input--laden'}
            onInputChange={this.onLadenWaitingTimeChange}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="99.99"
          />
        </div>
        <div className="canals-row__data">
          <TableDropDown
            columns={ballastCostColumns}
            diagnosticId="Canals/BallastCost"
            numericInputClassName={'canals-row__numeric-input--ballast'}
            items={ballastCostData}
            value={ballastCost}
            onDropDownChange={this.onBallastCostChange}
            onInputChange={this.onBallastCostChange}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="9999999.99"
            className="canals-row__data__ballast-cost-dropdown"
          />
          <TableDropDown
            columns={ladenCostColumns}
            diagnosticId="Canals/LadenCost"
            numericInputClassName={'canals-row__numeric-input--laden'}
            items={ladenCostData}
            value={ladenCost}
            onDropDownChange={this.onLadenCostChange}
            onInputChange={this.onLadenCostChange}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="9999999.99"
            className="canals-row__data__laden-cost-dropdown"
          />
        </div>
      </div>
    );
  }
}

export default CanalsRow;
