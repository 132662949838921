import { createSelector } from 'reselect';
import { getActiveWorkbook } from 'common-selectors/get-active-workbook';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { isWorksheetLoadingStatus } from 'constants/enums/worksheet-status';

export const getWorkbookProps = createSelector(
  getActiveWorkbook,
  (state) => state.appMetadata.stageLeftPanel,
  getActiveWorksheetId,
  (state) =>
    Object.values(state.worksheetsById).filter(
      (worksheet) => worksheet.workbookId === state.activeWorkbookId
    ),
  (state) => {
    const { activeWorksheetId, worksheetsById } = state;
    const currentWorksheet = worksheetsById[activeWorksheetId];
    if (!currentWorksheet) {
      return false;
    }

    return isWorksheetLoadingStatus(currentWorksheet.status);
  },
  (workbook, stageLeftPanel, activeWorksheetId, worksheets, isWorksheetLoading) => {
    const sortOrderType = workbook.sortOrderType;
    return {
      worksheets,
      activeWorksheetId,
      workbook,
      selectedWorksheetPanel: stageLeftPanel,
      sortOrderType,
      isWorksheetLoading,
    };
  }
);
