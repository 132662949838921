import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selector } from './selector';
import {
  preDefinedExpenseCostChanged,
  preDefinedExpenseCostTypeChanged,
  otherExpenseDescriptionChanged,
  otherExpenseCostChanged,
  otherExpenseCostTypeChanged,
  otherExpenseAdded,
  otherExpenseRemoved,
} from 'actions/worksheet/additional-expenses';

import { Header } from 'components/headers';
import { MaterialIconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import OtherExpense from './other-expense';
import PreDefinedExpense from './pre-defined-expense';
import CostTypes from 'constants/enums/cost-types';
import { compareNumbersWithNullAsSmallest } from '../../utilities/number';

import './styles.scss';

type Props = {
  preDefinedExpenses: Array<AdditionalExpense>,
  otherExpenses?: Array<AdditionalExpense>,
  preDefinedExpenseCostChanged: PreDefinedExpenseCostChanged,
  preDefinedExpenseCostTypeChanged: PreDefinedExpenseCostTypeChanged,
  otherExpenseDescriptionChanged?: OtherExpenseDescriptionChanged,
  otherExpenseCostChanged?: OtherExpenseCostChanged,
  otherExpenseCostTypeChanged?: OtherExpenseCostTypeChanged,
  otherExpenseAdded?: OtherExpenseAdded,
  otherExpenseRemoved?: OtherExpenseRemoved,
};

const PreDefinedExpensesList = ({
  preDefinedExpenses,
  preDefinedExpenseCostChanged,
  preDefinedExpenseCostTypeChanged,
}: Props) => {
  return preDefinedExpenses.map((preDefinedExpense, index) => (
    <PreDefinedExpense
      key={preDefinedExpense.id}
      itemIndex={index}
      preDefinedExpense={preDefinedExpense}
      preDefinedExpenseCostChanged={preDefinedExpenseCostChanged}
      preDefinedExpenseCostTypeChanged={preDefinedExpenseCostTypeChanged}
      costTypes={CostTypes}
      sortOrder={preDefinedExpense.sortOrder}
    />
  ));
};

const OtherExpensesHeader = ({ otherExpenseAdded }) => {
  return (
    <div className="other-expenses__table-header">
      <div className="other-expenses__table-header__cell other-expenses--description">
        Description
      </div>
      <div className="other-expenses__table-header__cell other-expenses--cost">Cost ($)</div>
      <div className="other-expenses__table-header__cell other-expenses--cost-type">Cost Type</div>
      <div className="other-expenses__table-header__cell other-expenses--add">
        <MaterialIconButton
          className="other-expenses--add-btn"
          icon={iconEnum.AddCircle}
          onClick={otherExpenseAdded}
          diagnosticId="AdditionalExpenses/AddOtherExpense"
        />
      </div>
    </div>
  );
};

export const OtherExpensesList = ({
  otherExpenses,
  otherExpenseDescriptionChanged,
  otherExpenseCostChanged,
  otherExpenseCostTypeChanged,
  otherExpenseRemoved,
}) => {
  return otherExpenses.map((otherExpense, index) => (
    <OtherExpense
      key={otherExpense.id}
      itemIndex={index}
      otherExpense={otherExpense}
      otherExpenseDescriptionChanged={otherExpenseDescriptionChanged}
      otherExpenseCostChanged={otherExpenseCostChanged}
      otherExpenseCostTypeChanged={otherExpenseCostTypeChanged}
      otherExpenseRemoved={otherExpenseRemoved}
      costTypes={CostTypes}
      sortOrder={otherExpense.sortOrder}
    />
  ));
};

export const AdditionalExpenses = ({
  worksheetId,
  preDefinedExpenses,
  preDefinedExpenseCostChanged,
  preDefinedExpenseCostTypeChanged,
  otherExpenseAdded,
  otherExpenses,
  otherExpenseDescriptionChanged,
  otherExpenseCostChanged,
  otherExpenseCostTypeChanged,
  otherExpenseRemoved,
}) => {
  const onPreDefinedExpenseCostChanged = useCallback(
    (...args) => preDefinedExpenseCostChanged(...args, worksheetId),
    [worksheetId]
  );

  const onPreDefinedExpenseCostTypeChanged = useCallback(
    (...args) => preDefinedExpenseCostTypeChanged(...args, worksheetId),
    [worksheetId]
  );

  const onOtherExpenseDescriptionChanged = useCallback(
    (...args) => otherExpenseDescriptionChanged(...args, worksheetId),
    [worksheetId]
  );

  const onOtherExpenseCostChanged = useCallback(
    (...args) => otherExpenseCostChanged(...args, worksheetId),
    [worksheetId]
  );

  const onOtherExpenseCostTypeChanged = useCallback(
    (...args) => otherExpenseCostTypeChanged(...args, worksheetId),
    [worksheetId]
  );

  const onOtherExpenseRemoved = useCallback(
    (...args) => otherExpenseRemoved(...args, worksheetId),
    [worksheetId]
  );

  const onOtherExpenseAdded = useCallback(
    (...args) => otherExpenseAdded(worksheetId),
    [worksheetId]
  );

  const compareExpenses = (a: IAdditionalCost, b: IAdditionalCost) =>
    // below defensive code in case the order property is undefined
    compareNumbersWithNullAsSmallest(
      a && a.sortOrder ? a.sortOrder : null,
      b && b.sortOrder ? b.sortOrder : null
    );

  return (
    <div className="additional-expenses-container">
      <Header text="Additional Costs" />
      <PreDefinedExpensesList
        preDefinedExpenses={preDefinedExpenses.sort(compareExpenses)}
        preDefinedExpenseCostChanged={onPreDefinedExpenseCostChanged}
        preDefinedExpenseCostTypeChanged={onPreDefinedExpenseCostTypeChanged}
      />
      <div className="other-expenses">
        <OtherExpensesHeader otherExpenseAdded={onOtherExpenseAdded} />
        <OtherExpensesList
          otherExpenses={otherExpenses.sort(compareExpenses)}
          otherExpenseDescriptionChanged={onOtherExpenseDescriptionChanged}
          otherExpenseCostChanged={onOtherExpenseCostChanged}
          otherExpenseCostTypeChanged={onOtherExpenseCostTypeChanged}
          otherExpenseRemoved={onOtherExpenseRemoved}
        />
      </div>
    </div>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      preDefinedExpenseCostChanged,
      preDefinedExpenseCostTypeChanged,
      otherExpenseDescriptionChanged,
      otherExpenseCostChanged,
      otherExpenseCostTypeChanged,
      otherExpenseAdded,
      otherExpenseRemoved,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalExpenses);
