import React from 'react';
import classNames from 'classnames';

import CenteredContent from 'components/centered-content';
import { WarningIcon } from 'components/icons';

import './styles.scss';

export const ErrorPanel = React.memo(({ className, message, children, ...props }) => {
  return (
    <CenteredContent className={classNames('error-panel', className)} {...props}>
      <WarningIcon className="error-panel__icon" />
      <div>{message || children}</div>
    </CenteredContent>
  );
});
