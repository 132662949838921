import React, { Component } from 'react';
import { withRouter } from 'utilities/router-wrapper';
import classNames from 'classnames';
import { trackPageView, eventDestination } from 'diagnostics/calc-trackevents';
import { Panel, PanelSection } from 'components/panel';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { withLiftAppGlobals } from 'components/app-globals-registry';
import { Overlay } from 'modules/overlay';
import SearchWorkbooksTab from './components/search-workbooks';
import { Tab } from 'components/tab';
import './styles.scss';
import WorksheetFilter from './components/worksheet-filter';

const SearchTabName = {
  WORKBOOKS: 1,
  WORKSHEETS: 2,
};

const defaultActiveTabName = SearchTabName.WORKBOOKS;

export class SearchPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible:
        typeof props.visible !== 'undefined'
          ? props.visible
          : typeof props.initialVisible !== 'undefined'
            ? props.initialVisible
            : false,
      activeTabName: defaultActiveTabName,
      testVar: false,
    };
  }

  get isVisible() {
    return typeof this.props.visible !== 'undefined' ? this.props.visible : this.state.visible;
  }

  toggleVisible = () => {
    if (typeof this.props.onToggle === 'function') {
      this.props.onToggle();
      return;
    }
    this.setState(({ visible }) => ({ visible: !visible }));
  };

  closePanel = () => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
      return;
    }
    this.setState(() => ({ visible: false, testVar: true }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (typeof this.props.visible !== 'undefined') {
      this.setState({ visible: this.props.visible });
    }

    if (prevState.visible !== this.state.visible) {
      trackPageView('Workbook/Manager', undefined, {}, {}, undefined, eventDestination.ANALYSIS);
    }

    // if path changes i.e. route is different, we need to close the panel
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.closePanel();
    }
  }

  setActiveTab = (searchTabName) => {
    this.setState(() => ({ activeTabName: searchTabName }));
  };

  isActiveTab = (searchTabName) => {
    return this.state.activeTabName === searchTabName;
  };

  tabContentClass = (isActive) => {
    return classNames('search-panel-tab-wrapper-search-tab__content', {
      'search-panel-tab-wrapper-search-tab__content-hide': !isActive,
    });
  };

  searchWorkbooksTabContent = () => {
    const isActive = this.isActiveTab(SearchTabName.WORKBOOKS);
    return (
      <div className={this.tabContentClass(isActive)}>
        <SearchWorkbooksTab />
      </div>
    );
  };

  searchWorksheetsTabContent = () => {
    // call worksheet tab component and pass dependencies/ child component if any
    // const TabCom = searchWorkbooksTab({ MyWorkbooks, TeamWorkbooks });

    const isActive = this.isActiveTab(SearchTabName.WORKSHEETS);
    return (
      <div className={this.tabContentClass(isActive)}>
        <WorksheetFilter closePanel={this.closePanel} visible={this.isVisible} />
      </div>
    );
  };

  render() {
    const { 'data-testid': testId } = this.props;
    const classes = classNames('search-panel', {
      'search-panel--active': this.isVisible,
    });

    const tabClass = (tabName) => {
      const isActive = this.isActiveTab(tabName);
      return classNames('search-panel-tab-wrapper-search-tab', {
        'search-panel-tab-wrapper-search-tab--active': isActive,
      });
    };

    return (
      <>
        <div ref={this.onPanelRendered}>
          <Panel className={classes}>
            {/*  TAB Details  */}
            <PanelSection className="search-panel__header">
              <div className="search-panel-tab-wrapper">
                <Tab
                  onClick={() => this.setActiveTab(SearchTabName.WORKBOOKS)}
                  isActive={this.isActiveTab(SearchTabName.WORKBOOKS)}
                  className={tabClass(SearchTabName.WORKBOOKS)}
                >
                  <div>Workbooks</div>
                </Tab>
                <Tab
                  onClick={() => this.setActiveTab(SearchTabName.WORKSHEETS)}
                  isActive={this.isActiveTab(SearchTabName.WORKSHEETS)}
                  className={tabClass(SearchTabName.WORKSHEETS)}
                >
                  <div>Worksheets</div>
                </Tab>
              </div>
              <div className="search-panel__actions">
                <IconButton
                  icon={iconEnum.Close}
                  onClick={this.toggleVisible}
                  data-testid={testId && `${testId}__close`}
                  className="search-panel__actions-close-btn"
                  diagnosticId="SearchPanel/Close"
                />
              </div>
            </PanelSection>

            {/*  TAB content   */}
            {this.searchWorkbooksTabContent()}
            {this.searchWorksheetsTabContent()}
          </Panel>
        </div>
        <Overlay
          hidden={!this.isVisible}
          data-testid={testId && `${testId}__overlay`}
          onClick={this.toggleVisible}
        />
      </>
    );
  }
}

export default withRouter(withLiftAppGlobals(SearchPanel));
