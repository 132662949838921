import React, { useState, createContext, useContext, useRef, useCallback, useEffect } from 'react';
import logger from 'diagnostics/logger';

const AppGlobalsContext = createContext(null);

type CleanupFunction = () => void;

export class AppGlobalsRegistry {
  constructor() {
    this.values = {};
  }

  registerValues(liftToAppGlobals: { [string]: any }): CleanupFunction {
    for (const key in liftToAppGlobals) {
      if (this.values[key]) {
        logger.warn(`Global '${key}' is already registered. The value will be overwritten`);
      }
      this.values[key] = liftToAppGlobals[key];
    }

    return () => {
      for (const key in liftToAppGlobals) {
        delete this.values[key];
      }
    };
  }

  get<T>(name: string): T {
    if (!this.values[name]) {
      throw new Error(`Requested global '${name}' is not registered`);
    }

    return this.values[name];
  }
}

export function AppGlobalsProvider({ children, registry }) {
  const registery = useRef(registry || new AppGlobalsRegistry());

  return (
    <AppGlobalsContext.Provider value={registery.current}>{children}</AppGlobalsContext.Provider>
  );
}

function mapValues(object, cb: (value: any, key: string) => any) {
  return Object.keys(object).reduce((output, key) => {
    output[key] = cb(object[key]);
    return output;
  }, {});
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * A higher-order component to create an app-global aware component that can expose its internal methods
 * as value in the AppGlobalsRegistry.
 * Note: the `WrappedComponent` must be a React class component due to the fact that functional components
 * do not have instances that can be acccessed through `ref`.
 *
 * TODO: consider moving this into one function call i.e. registration and connection to happen by the same
 * function.
 */
export function withLiftAppGlobals(WrappedComponent) {
  const name = `withLiftAppGlobals(${getDisplayName(WrappedComponent)})`;
  // creating an object in order to preserve the function name
  const obj = {
    [name]({ liftToAppGlobals, ...props }: { liftToAppGlobals: { [string]: string } }) {
      const registry = useContext(AppGlobalsContext);
      const cleanup = useRef(null);

      const onRendered = useCallback((componentRef) => {
        if (!componentRef) {
          return;
        }

        cleanup.current = registry.registerValues(
          mapValues(liftToAppGlobals, (sourceMemberName, targetAppGlobal) => {
            if (componentRef[sourceMemberName] === undefined) {
              throw new Error(
                `Error while trying to lift a member to AppGlobal ${targetAppGlobal}: The component '${getDisplayName(
                  WrappedComponent
                )}' doesn't have a member named '${sourceMemberName}'`
              );
            }
            return componentRef[sourceMemberName];
          })
        );
      }, []);

      useEffect(() => {
        return cleanup.current;
      }, []);
      return <WrappedComponent ref={onRendered} {...props} />;
    },
  };
  return obj[name];
}

export function withPullAppGlobals(WrappedComponent) {
  const name = `withPullAppGlobals(${getDisplayName(WrappedComponent)})`;
  const obj = {
    [name]({ pullFromGlobals, ...props }: { pullFromGlobals: { [string]: string } }) {
      const registry = useContext(AppGlobalsContext);
      const [appGlobals, setGlobals] = useState(undefined);

      useEffect(() => {
        const values = {};
        for (const key in pullFromGlobals) {
          // if provided props already have a value for this key,
          // the provided prop takes precedence over pulling from globals
          // and hence we skip setting pulling property from
          if (props[key]) {
            continue;
          }
          if (Object.prototype.hasOwnProperty.call(pullFromGlobals, key)) {
            values[pullFromGlobals[key]] = registry.get(key);
          }
        }
        setGlobals(values);
      }, []);

      return <WrappedComponent {...props} {...appGlobals} />;
    },
  };
  return obj[name];
}

/**
 *
 * Use this to lift values to globals in stateless functional components (because withLiftAppGlobals works only on classes),
 * as well as for situations where the state management of a given child component is lifted to the parent,
 * as per the general React orthodoxy.
 */
export function RegisterAppGlobals({ appGlobals }: { appGlobals: { [string]: any } }) {
  const registry = useContext(AppGlobalsContext);
  useEffect(() => {
    return registry.registerValues(appGlobals);
  }, []);
  return null;
}
