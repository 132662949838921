const trueString: string = 'true';
const falseString: string = 'false';

/**
 * This function will a value and convert it to a boolean value.
 * If it is of type boolean, it will pass it on.
 * If it is of type string, then it will compare (ignoring case)
 * it to 'true' or 'false' and return the corresponding boolean value. *
 * Otherwise, an error is thrown.
 *
 * @param {*} val - a input value to be converted to boolean
 * @return {boolean} - parsed boolean value
 */
export function parseAsBoolean(val: boolean | string): boolean {
  if (typeof val === 'boolean') {
    return val;
  }

  if (typeof val === 'string') {
    const lowerCasedVal = val.toLowerCase();
    if (lowerCasedVal === trueString || lowerCasedVal === falseString) {
      return lowerCasedVal === trueString;
    }
  }

  throw Error(`Tried to parse ${val} as a boolean and failed.`);
}
