import { createSelector } from 'reselect';
import { getActiveCalculationResult } from 'common-selectors/get-active-calculation-result';

export type AdditionalIncomesState = {
  worksheetId: string,
  additionalIncomes: IAdditionalIncomes[],
};

export const selector = createSelector(
  (state) => state.activeWorksheetId,
  (state) => state.worksheetsById[state.activeWorksheetId],
  (worksheetId, worksheet): AdditionalIncomesState => {
    return {
      worksheetId: worksheetId,
      additionalIncomes: worksheet.additionalIncomes,
    };
  }
);
