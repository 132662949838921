import React from 'react';
import { Checkbox } from 'components/checkbox';

export const EEACheckbox: FC = ({
  isInEea,
  onIsInEeaChange,
  isReadonly,
  disabled = false,
  isInEeaVisible = true,
  label = 'EEA',
}) => {
  const handleOnIsInEeaChange = (value) => {
    onIsInEeaChange(value);
  };

  return (
    <>
      {isInEeaVisible ? (
        <Checkbox
          disabled={disabled}
          onChange={handleOnIsInEeaChange}
          id="eea-checkbox"
          value={isInEea}
          label={label}
          isReadonly={isReadonly}
        />
      ) : (
        <></>
      )}
    </>
  );
};
