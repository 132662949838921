import isNil from 'lodash/isNil';
import { validationLevels } from 'components/numeric-input';

type ValidationMessageBase<TAttemptedValueInfo, TComponent> = {
  // See https://github.com/babel/babel-eslint/issues/485
  // eslint-disable-next-line no-use-before-define
  ruleDefinition: ValidationRuleDefinitionBase<TAttemptedValueInfo, TComponent>,
  message: string,
  validationLevel: ValidationLevel,
};

export type GetAttemptedValueIsInvalidMessageFunctionType<
  TAttemptedValueInfo,
  TComponent: React.Component,
> = (
  attemptedValueInfo: TAttemptedValueInfo,
  // See https://github.com/babel/babel-eslint/issues/485
  // eslint-disable-next-line no-use-before-define
  ruleUsageParams: ValidationRuleUsageParamsBase<TAttemptedValueInfo, TComponent>,
  component: TComponent
) => string;

export type ValidationRuleUsageParamsBase<TAttemptedValueInfo, TComponent: React.Component> =
  | {
      getAttemptedValueIsInvalidMessage: GetAttemptedValueIsInvalidMessageFunctionType<
        TAttemptedValueInfo,
        TComponent,
      >,
    }
  | mixed;

export type IsValidFunctionBase<TAttemptedValueInfo, TComponent: React.Component> = (
  attemptedValueInfo: TAttemptedValueInfo,
  ruleUsageParams: ValidationRuleUsageParams<TAttemptedValueInfo, TComponent>,
  component: TComponent
) => boolean;

export type ValidationRuleDefinitionBase<TAttemptedValueInfo, TComponent: React.Component> = {
  /**
   * The property on the component that enables the validation rule and contains the rule usage parameters, that
   * will be passed to the validator.
   * e.g. given a usage of `<MyComponent minValue=3>`, this will enable the validation rule where `propertyName` is `minValue`
   * and `3` will be the 'usage argument' (or your transformation of that - see `expandParamShorthands`).
   * When unspecified, `defaultParamsWhenNoProperty` should be specified and `id` should be considered.
   */
  propertyName?: string,
  /**
   * Setting this to an non-nil value effectively makes the rule 'default-on'.
   * It will run with the params specified here.
   */
  defaultParamsWhenNoProperty: ValidationRuleUsageParams<TAttemptedValueInfo, TComponent>,
  /**
   * Can be specified for rule identification by cross-cutting-concerns code (diagnostics, exception messages, etc.).
   * When unspecified, `propertyName` can be used by that code, but that one is optional.
   */
  id?: string,
  /**
   * Use this for sanity rules that should run first, to make other rules simpler (e.g. so that don't need to keep checking wheter value has a valid structure)
   */
  shouldFailureStopFurtherRules: boolean,
  /**
   * The validator function - the essential code that defines the logic of the validation rule.
   */
  isValid: IsValidFunctionBase<TAttemptedValueInfo, TComponent>,
  /**
   * Optional function that you can specify to allow shorthand usages, e.g. `maxValue=1`, without customization of validation message.
   * If specified, it will be called to obtain the actual argument to be passed to `isValid` and `getAttemptedValueIsInvalidMessage`
   * functions.
   *
   * @param actualUsageParam - the value of validation rule usage arguments, as specified by the component user,
   * e.g. for usage of `maxValue={{ value: 100, someCustomParam: 'hmmh' }}`, it will contain the object `{ value: 100, someCustomParam: 'hmmh' }`.
   */
  expandParamShorthands?: (
    actualUsageParam: ValidationRuleUsageParamsBase<TAttemptedValueInfo, TComponent>
  ) => ValidationRuleUsageParamsBase<TAttemptedValueInfo, TComponent>,
  getAttemptedValueIsInvalidMessage?: GetAttemptedValueIsInvalidMessageFunctionType<
    TAttemptedValueInfo,
    TComponent,
  >,
};

export type getValidationMessagesArgs<TAttemptedValueInfo, TComponent: React.Component> = {
  ruleDefinitions: Array<ValidationRuleDefinitionBase<TAttemptedValueInfo, TComponent>>,
  attemptedValue: TAttemptedValueInfo,
  component: TComponent,
};

export function getValidationMessages(
  args: getValidationMessagesArgs<TAttemptedValueInfo, TComponent>
): Array<ValidationMessageBase<TAttemptedValueInfo, TComponent>> {
  return Array.from(getValidationMessagesIterable.apply(this, arguments));
}

// This function was made iterable especially, for clarity of implementation. Use `getValidationMessages` in the code where it is inconvenient
export function* getValidationMessagesIterable<TAttemptedValueInfo, TComponent: React.Component>({
  ruleDefinitionsToTest,
  attemptedValueInfo,
  component,
}: getValidationMessagesArgs<TAttemptedValueInfo, TComponent>): Iterable<
  ValidationMessageBase<TAttemptedValueInfo, TComponent>,
> {
  const contextsForRulesToTest = ruleDefinitionsToTest
    // Gather all the necessary information context
    .map((ruleDefinition) => ({
      ruleDefinition,
      ruleUsageParams: !isNil(component.props[ruleDefinition.propertyName])
        ? component.props[ruleDefinition.propertyName]
        : ruleDefinition.defaultParamsWhenNoProperty,
    }))
    // Skip optional rules not used on this component usage
    .filter((validationContext) => !isNil(validationContext.ruleUsageParams))
    // Extend shorthand usages, e.g. maxValue=1, where no customization of validation message is needed
    .map(({ ruleUsageParams, ...restOfValidationContext }) => ({
      ruleUsageParams: restOfValidationContext.ruleDefinition.expandParamShorthands
        ? restOfValidationContext.ruleDefinition.expandParamShorthands(ruleUsageParams)
        : ruleUsageParams,
      ...restOfValidationContext,
    }));
  for (const ruleContext of contextsForRulesToTest) {
    const { ruleUsageParams, ruleDefinition } = ruleContext;

    if (ruleDefinition.isValid(attemptedValueInfo, ruleUsageParams, component)) continue;

    const getAttemptedValueIsInvalidMessage =
      ruleUsageParams.getAttemptedValueIsInvalidMessage ||
      ruleDefinition.getAttemptedValueIsInvalidMessage;
    yield {
      ruleDefinition,
      message:
        (getAttemptedValueIsInvalidMessage &&
          getAttemptedValueIsInvalidMessage(attemptedValueInfo, ruleUsageParams, component)) ||
        'The value is invalid.',
      validationLevel:
        ruleUsageParams.validationLevel || ruleDefinition.validationLevel || validationLevels.error,
    };

    if (ruleDefinition.shouldFailureStopFurtherRules) {
      return;
    }
  }
}
