import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';
import { createSelector } from 'reselect';

export const selector = createSelector(
  (state) => state.activeWorksheetId,
  (state) => state.bunkerPorts.bunkerPrices,
  (state) => state.appMetadata.isBunkerPricesPanelReadOnly,
  getActiveWorksheetInvariantProps,
  (worksheetId, bunkerPrices, isBunkerPricesPanelReadOnly, worksheetInvariantProps) => ({
    worksheetId,
    bunkerPorts: bunkerPrices,
    isBunkerPricesPanelReadOnly,
    worksheetInvariantProps,
  })
);
