import { createSelector } from 'reselect';

import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';
import { getActiveWorksheetFirstLeg } from 'common-selectors/get-active-worksheet-first-leg';
import { getActiveVessel } from 'common-selectors/get-active-vessel';

export const selector = createSelector(
  (state) => state.referenceData?.balticTankerIndexVessels,
  getActiveWorksheetInvariantProps,
  getActiveWorksheetFirstLeg,
  getActiveVessel,
  (balticTankerIndexVessels, worksheetInvariantProps, firstLeg, vessel) => ({
    balticTankerIndexVessels,
    worksheetId: worksheetInvariantProps.id,
    marketSegmentId: worksheetInvariantProps.marketSegmentId,
    cargoSize: firstLeg.cargoQuantity,
    vesselId: vessel.vesselId,
    vesselName: vessel.name,
    openDate: vessel.openPosition.openDate,
    vesselEntryId: vessel.entryId,
  })
);
