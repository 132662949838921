import { createSelector } from 'reselect';
import { getStowageUnitTypeById } from 'constants/enums/stowage-factor-units';
import {
  round,
  formatNumberWithAutoMantissa,
  formatNumberWithNullAndZeroAsEmpty,
} from 'utilities/number';
import { singleOrThrow } from 'utilities/iterable';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';
import { getActiveVessel } from '../../../common-selectors/get-active-vessel';

export const selector = createSelector(
  (state) => state.worksheetsById[state.activeWorksheetId],
  (state) => getActiveCalculationResult(state),
  getActiveVessel,
  (worksheet, calculationResult, vessel) => {
    var totalCargoQuantity = calculationResult.totalCargoQuantity;
    var additionalResults = calculationResult.additionalResults;
    const cargo = singleOrThrow(worksheet.cargoes);
    var lowestMaximumLift;
    if (additionalResults && additionalResults.lowestMaximumLift) {
      lowestMaximumLift = {
        lowestMaximumLift: additionalResults.lowestMaximumLift.maximumLift,
        errors: additionalResults.lowestMaximumLift.errors,
      };
    } else {
      lowestMaximumLift = {
        errors: ['Result not available.'],
      };
    }
    return {
      name: cargo.name,
      marketSegmentId: cargo.marketSegmentId,
      quantity:
        totalCargoQuantity != null && totalCargoQuantity !== 0
          ? formatNumberWithAutoMantissa(round(totalCargoQuantity, 3))
          : '',
      stowage: formatNumberWithNullAndZeroAsEmpty(cargo.stowage, 3),
      stowageUnit: getStowageUnitTypeById(cargo.stowageUnit),
      specificGravity: formatNumberWithNullAndZeroAsEmpty(cargo.specificGravity, 3),
      lowestMaximumLiftInVoyage: lowestMaximumLift,
    };
  }
);
