import get from 'lodash/get';

/**
 * From the worksheet view model, where the additional expenses are kept in heterogenous ways,
 * extracts a list of costs classified as additional expenses, each adhering to the uniform model of `Data.IAdditionalCost`
 * Inverse of `getAdditionalExpensesViewModelFromWorksheetDto`
 */
export function mapWorksheetViewModelToAdditionalExpensesCommonModel(
  worksheet: Redux.IWorksheet
): Data.IAdditionalCost[] {
  return [
    // TODO consider making these path accessors even simpler by [bringing in optional chaining to our project](https://medium.com/@adostes/enabling-optional-chaining-in-a-create-react-app-a9f626a515d9)
    ...(get(worksheet, 'additionalExpenses.preDefined') || []).map((expense) => ({
      ...expense,
      isPredefinedExpense: true,
    })),
    ...(get(worksheet, 'additionalExpenses.others') || []).map((expense) => ({
      ...expense,
      isPredefinedExpense: false,
    })),
  ];
}
