import React, { PureComponent } from 'react';
import NumericInput from 'components/numeric-input';
import DropDown from 'components/dropdown';
import { MaterialIconButton } from 'components/button';
import { iconEnum } from 'components/icons';

type Props = {
  itemIndex: number,
  otherExpense: any,
  otherExpenseDescriptionChanged: OtherExpenseDescriptionChanged,
  otherExpenseCostChanged: OtherExpenseCostChanged,
  otherExpenseCostTypeChanged: OtherExpenseCostTypeChanged,
  otherExpenseRemoved: OtherExpenseRemoved,
  costTypes: Array<any>,
  sortOrder: number,
};

export class OtherExpense extends PureComponent<Props> {
  onOtherExpenseDescriptionChanged = (event) => {
    this.props.otherExpenseDescriptionChanged(event.target.value, this.props.itemIndex);
  };
  onOtherExpenseCostChanged: any = (value) => {
    this.props.otherExpenseCostChanged(value, this.props.itemIndex);
  };
  onOtherExpenseCostTypeChanged: any = (value) => {
    this.props.otherExpenseCostTypeChanged(value, this.props.itemIndex);
  };
  onOtherExpenseRemoved: any = () => {
    this.props.otherExpenseRemoved(this.props.itemIndex);
  };

  render() {
    return (
      <div className="other-expenses__table-row">
        <div className="other-expenses--description">
          <input
            value={this.props.otherExpense.description}
            className="calc-input"
            onChange={this.onOtherExpenseDescriptionChanged}
            maxLength={160}
          />
        </div>
        <div className="other-expenses--cost">
          <NumericInput
            value={this.props.otherExpense.cost}
            onInputChange={this.onOtherExpenseCostChanged}
            maxDecimalDigits="2"
            minValue="-999999.99"
            maxValue="999999.99"
            diagnosticId="OtherExpense/ChangeCost"
          />
        </div>
        <div className="other-expenses--cost-type">
          <DropDown
            id="cost-type"
            items={this.props.costTypes}
            selectedItem={this.props.otherExpense.costType}
            onChange={this.onOtherExpenseCostTypeChanged}
            diagnosticId="OtherExpense/ChangeExpenseCostType"
          />
        </div>
        <div className="other-expenses--remove">
          <MaterialIconButton
            icon={iconEnum.RemoveCircle}
            className="other-expenses--remove-btn"
            onClick={this.onOtherExpenseRemoved}
            diagnosticId="OtherExpense/RemoveOtherExpense"
          />
        </div>
      </div>
    );
  }
}

export default OtherExpense;
