export const WorksheetStatus = {
  LOADING: 1,
  LOADED: 2,
  FAILED: 3,
};

export const isWorksheetFailureStatus = (status) => status === WorksheetStatus.FAILED;

export const isWorksheetLoadingStatus = (status) =>
  status === WorksheetStatus.LOADING || status === null;

export const isWorksheetLoadedStatus = (status) => status === WorksheetStatus.LOADED;

export const WorksheetChangeStatus = {
  CLEAN: 1,
  DIRTY: 2,
};

export const isWorksheetCleanChangeStatus = (status) => status === WorksheetChangeStatus.CLEAN;

export const isWorksheetDirtyChangeStatus = (status) => status === WorksheetChangeStatus.DIRTY;
