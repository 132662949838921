import { getActiveWorksheet } from './get-active-worksheet';
import { createSelector } from 'reselect';
import { isTanker } from '../constants/market-segments';

export const getActiveVesselEntryId = createSelector(
  (state) => state.activeVesselEntryId,
  getActiveWorksheet,
  getActiveVesselEntryIdFromWorksheet
);

export function getActiveVesselEntryIdFromWorksheet(activeVesselEntryId, activeWorksheet) {
  if (isTanker(activeWorksheet?.marketSegmentId)) {
    return activeWorksheet?.vessels[0].entryId;
  }
  return activeVesselEntryId ?? activeWorksheet?.vessels[0].entryId;
}
