import React from 'react';
import { EEACheckbox } from 'modules/voyage/components/eea';

export interface PreviousPortOfCallProps {
  handleOpenPositionPrevPortOfCallIsInEeaUpdated: (isInEea: boolean) => void;
  openPosition: any;
  oldView: boolean;
}

const PreviousPortOfCall = ({
  handleOpenPositionPrevPortOfCallIsInEeaUpdated,
  openPosition,
  oldView = false,
}: PreviousPortOfCallProps) => {
  return oldView ? (
    <div className="port-of-call-top">
      <label className="worksheet-section__property-label">Previous Port of Call</label>
      <EEACheckbox
        onIsInEeaChange={handleOpenPositionPrevPortOfCallIsInEeaUpdated}
        id="eea-previous-port-of-call-checkbox"
        isInEea={openPosition?.prevPortOfCallIsInEea}
        label="EEA"
      />
    </div>
  ) : (
    <>
      <EEACheckbox
        onIsInEeaChange={handleOpenPositionPrevPortOfCallIsInEeaUpdated}
        id="eea-checkbox"
        isInEea={openPosition?.prevPortOfCallIsInEea}
        label=""
      />
      <label className="worksheet-section__property-label">Previous Port of Call is in EEA</label>
    </>
  );
};

export default PreviousPortOfCall;
