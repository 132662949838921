import React from 'react';

export function useScrollReset(containerRef) {
  const scrollToTop = React.useCallback(() => {
    containerRef.current && (containerRef.current.scrollTop = 0);
  }, []);

  const [hasScrolled, setHasScrolled] = React.useState(false);

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const handleScroll = (e) => {
      setHasScrolled(containerRef.current.scrollTop > 0);
    };
    containerRef.current.addEventListener('scroll', handleScroll);
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef.current]);

  return [hasScrolled, scrollToTop];
}
