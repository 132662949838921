/**
 * @fileOverview Use this sink to ensure no errors are lost, when the caller of your function does not offer a way to attach
 * handler to the unhandled exceptions from your function.
 * NOTE: Browsers offer [global error handlers](https://stackoverflow.com/a/28004999), so you should only need to use this
 * if your caller prevents the errors from reaching the outermost browser-runtime caller, and that happens in the code you
 * cannot change. If this is not your case, you should just leave logging to the global error handlers, that your application should hook to.
 */

import logger from 'diagnostics/logger';

/**
 * Sends error information to the sink, so that it can be dealt with according to the app's policy.
 * @param e - The error information to send to the sink.
 */
export const send = (e: Error | mixed) => {
  logger.exception(e);
};
