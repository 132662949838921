import React, { FC, useState } from 'react';
import Downshift from 'downshift';
import { connect } from 'react-redux';
import './styles.scss';
import { IconButton } from '../button';
import { iconEnum } from '../icons';
import { selector } from './selector';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { getFormattedUpdatedDate } from '../../modules/search-panel/components/workbooks-grid/grid-value-formatters/updated-date-formatter';
import { findDisplayItemByMarketSegmentId } from '../../constants/market-segments';

type Workbook = {
  name: string,
  worksheets: [],
  updatedDate: Date,
  id: string,
  marketSegmentId: string,
};
type WorkbookProps = {
  workbooks: Workbook[],
  pickSelectedWorkbook: () => void,
  activeWorksheetMarketSegmentId: string,
  activeWorkbookId: string,
};

export const WorkbookAutocomplete: FC<WorkbookProps> = ({
  workbooks,
  pickSelectedWorkbook,
  activeWorksheetMarketSegmentId,
  activeWorkbookId,
}) => {
  const [options, setOptions] = useState([]);
  const cleanupTasks: Array<() => void> = [];

  const marketSegmentFilteredWorkbooks = workbooks.filter(
    (workbook) => workbook.marketSegmentId === activeWorksheetMarketSegmentId
  );
  const possibleTargetWorkbooks = marketSegmentFilteredWorkbooks.filter(
    (workbook) => workbook.id !== activeWorkbookId
  );

  const onChangeHandler = (item: Workbook): void => {
    pickSelectedWorkbook(item?.id, item?.name);
  };

  const onInputChange = (value: string, { closeMenu, selectedItem, clearSelection }) => {
    if (isEmpty(value) && selectedItem) {
      const timeoutHandle = setTimeout(closeMenu, 0); // Close the menu when user erases everything, as no search is happenning. `setTimeout` is needed as otherwiser it doesn't work immediately :(  ( possibly Downshift opens the menu on any value change, in the same handler)
      cleanupTasks.push(() => clearTimeout(timeoutHandle));
      clearSelection();
      return;
    }
    filterOptions(value);
  };

  const filterOptions = (value) => {
    setOptions(
      possibleTargetWorkbooks
        .filter(
          (workbook, index) => !value || workbook.name.toLowerCase().includes(value.toLowerCase())
        )
        .slice(0, 10)
    );
  };

  const itemToString = (item: Workbook | null): string => {
    return isNil(item) || isNil(item.name) ? '' : item.name;
  };

  const onBlurHandler = (event: SyntheticFocusEvent<HTMLInputElement>): void => {
    const inputVal = event && event.target && event.target.value;
    if (isEmpty(inputVal)) {
      event.nativeEvent.preventDownshiftDefault = true;
    }
  };

  return (
    <Downshift
      onChange={(item) => onChangeHandler(item)}
      onInputValueChange={onInputChange}
      itemToString={itemToString}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        selectHighlightedItem,
        inputValue,
        getRootProps,
        clearSelection,
        selectedItem,
      }) => (
        <div className="workbook-autocomplete" {...getRootProps({}, { suppressRefError: true })}>
          <IconButton className="custom-iconButton" icon={iconEnum.Search} />
          <input
            {...getInputProps({
              placeholder: 'Search for your workbook',
              onBlur: (event) => onBlurHandler(event),
              onFocus: (event) => event.target.select(),
              onKeyDown: (event) => {
                if (event.key === 'Tab') {
                  selectHighlightedItem();
                }
              },
            })}
            autoFocus
            maxLength={160}
            className="workbook-autocomplete__input"
          />
          {isOpen ? (
            <div className="workbook-autocomplete__menu" data-test="menu">
              <div className="workbook-autocomplete__menu-header">
                <div className="workbook-autocomplete__header-item header-item-name">Name</div>
                <div className="workbook-autocomplete__header-item header-item-sheets">Sheets</div>
                <div className="workbook-autocomplete__header-item header-item-update">
                  Last updated
                </div>
              </div>
              <div className="workbook-autocomplete__menu-content">
                {options?.length
                  ? options.map((workbook, index) =>
                      renderOption(workbook, highlightedIndex, index, getItemProps)
                    )
                  : renderEmptyState()}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};
const renderOption = (workbook, highlightedIndex, index, getItemProps) => {
  const marketSegmentDisplayItem = findDisplayItemByMarketSegmentId(workbook.marketSegmentId);

  const symbol = marketSegmentDisplayItem.iconSymbol;
  const background = marketSegmentDisplayItem.background;
  const colour = marketSegmentDisplayItem.color;

  return (
    <div
      {...getItemProps({
        item: workbook,
      })}
      key={workbook.id}
      className={`workbook-autocomplete__menu-item ${
        highlightedIndex === index ? 'workbook-autocomplete__menu-item--highlighted' : ''
      }`}
    >
      <div className="workbook-autocomplete__menu-item--symbol-name">
        <span
          className={'workbook-autocomplete__menu-item--symbol'}
          style={{ background: background, color: colour }}
        >
          <b>{symbol}</b>
        </span>
        <div className="workbook-autocomplete__menu-item--name">{workbook.name}</div>
      </div>
      <div className="workbook-autocomplete__menu-item--sheets">{workbook.worksheets.length}</div>
      <div className="workbook-autocomplete__menu-item--update">
        {getFormattedUpdatedDate(workbook.updatedDate)}
      </div>
    </div>
  );
};
const renderEmptyState = () => {
  return <div className="workbook-autocomplete__menu-empty-state">No match found</div>;
};
const mapStateToProps = selector;

const WorkbookAutocompleteContainer = connect(mapStateToProps)(WorkbookAutocomplete);

export default WorkbookAutocompleteContainer;
