//Common panels
export const WORKBOOK_ERRORED = 'WORKBOOK_ERRORED';
export const WORKSHEET_ERRORED = 'WORKSHEET_ERRORED';

//Left stage panels

export const WORKSHEET_PANEL = 'WORKSHEET_PANEL';
export const MAP_PANEL = 'MAP_PANEL';
export const BUNKER_PORTS_PANEL = 'BUNKER_PORTS_PANEL';

//Right stage panels
export const INDIVIDUAL_SUMMARY_PANEL = 'INDIVIDUAL_SUMMARY_PANEL';
export const INTAKE_CALCULATOR_PANEL = 'INTAKE_CALCULATOR_PANEL';
export const COMPARISON_SUMMARY_PANEL = 'COMPARISON_SUMMARY_PANEL';
