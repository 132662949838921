import deepEqualInAnyOrder from 'deep-equal-in-any-order';
const chai = async () => {
  await import('chai').then((result) => {
    result.use(deepEqualInAnyOrder);
  });
};

export function compareClientAndServerOperationalRestrictionCalculations(
  clientSideCalc,
  serverSideCalc
) {
  console.group('Client & Server operational restriction calculation comparison');

  assertEquivalence(clientSideCalc.totalDistance, serverSideCalc.totalDistance, 'totalDistance');
  assertEquivalence(clientSideCalc.path, serverSideCalc.path, 'path');
  assertEquivalence(
    clientSideCalc.waypointsOfSignificance,
    serverSideCalc.waypointsOfSignificance,
    'waypointsOfSignificance'
  );
  assertEquivalence(
    clientSideCalc.routeAsSequenceOfDistancesThroughOperationalRestrictions,
    serverSideCalc.routeAsSequenceOfDistancesThroughOperationalRestrictions,
    'routeAsSequenceOfDistancesThroughOperationalRestrictions'
  );
  assertEquivalence(
    clientSideCalc.subtotalsByOperationalRestrictionIds,
    serverSideCalc.subtotalsByOperationalRestrictionIds,
    'subtotalsByOperationalRestrictionIds'
  );
  assertEquivalence(
    clientSideCalc.reasonIsUnsuccessful,
    serverSideCalc.reasonIsUnsuccessful,
    'reasonIsUnsuccessful'
  );

  console.groupEnd();
}

function assertEquivalence(clientSideProp, serverSideProp, propName) {
  const { expect } = chai;

  try {
    expect(serverSideProp).to.deep.equalInAnyOrder(clientSideProp, propName);
    console.log(`${propName} is ✅`);
  } catch (error) {
    console.warn(`${propName} is ❌`, { error });
  }
}
