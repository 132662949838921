import stringEnum, { StringEnum } from 'utilities/enum/string-enum';

const vesselActivityValues = [
  'idle',
  'maneuvering',
  'bunkering',
  'loading',
  'discharging',
  'canalTransit',
  'canalWaiting',
  'sailing',
];

export type VesselActivityType =
  | 'idle'
  | 'maneuvering'
  | 'bunkering'
  | 'loading'
  | 'discharging'
  | 'canalTransit'
  | 'canalWaiting'
  | 'sailing';

export const vesselActivityTypes: StringEnum<VesselActivityType> = stringEnum(vesselActivityValues);
