import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import classNames from 'classnames';
import { selector } from './selector';
import { iconEnum } from 'components/icons';
import { IconTooltip } from 'components/icon-tooltip';
import { formatNumberWithNullAndUndefinedAsEmpty } from 'utilities/number';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import NewGuid from 'utilities/guid';

class CarbonBreakdownView extends Component {
  renderHeaderRowCell = (content, rightAlign, leftBorder, key) => {
    const classes = classNames(
      'summary-carbon-data__header-cell',
      {
        'summary-carbon-data__header-cell--right': rightAlign,
      },
      {
        'summary-carbon-data__header-cell--border': leftBorder,
      }
    );
    return (
      <div key={key} className={classes}>
        {content}
      </div>
    );
  };

  renderDefaultHeaderRowCell = (content, key) => {
    return this.renderHeaderRowCell(content, false, false, key);
  };

  renderRightHeaderRowCell = (content, key) => {
    return this.renderHeaderRowCell(content, true, false, key);
  };

  renderDataCell = (children, rightAlign, leftBorder, calcIcon, eeoi, key, highlight, small) => {
    let guid = NewGuid();
    const cellClass = eeoi
      ? 'summary-carbon-data__row-cell-eeoi'
      : small
        ? 'summary-carbon-data__row-cell-small'
        : 'summary-carbon-data__row-cell';

    return (
      <div
        key={key}
        className={classNames(
          cellClass,
          {
            'summary-carbon-data__row-cell--right': rightAlign,
          },
          {
            'summary-carbon-data__row-cell--border': leftBorder,
          },
          {
            'summary-carbon-data__row-cell--calc-icon': calcIcon,
          },
          {
            'summary-carbon-data__row-cell--highlight': highlight,
          }
        )}
        data-tooltip-content={children}
        data-tooltip-id={`carbon-breakdown-tooltip-${guid}`}
      >
        {children}
        {eeoi && typeof children === 'string' && (
          <ReactTooltip
            place="bottom"
            id={`carbon-breakdown-tooltip-${guid}`}
            className="ve-tooltip-default"
            noArrow
          />
        )}
      </div>
    );
  };

  renderCO2DefaultDataCell = (children, key) => {
    return this.renderDataCell(children, false, false, false, false, key);
  };

  renderCO2RightDataCell = (children, key) => {
    return this.renderDataCell(children, true, false, false, false, key);
  };

  renderCO2IconDataCell = (children, key) => {
    return this.renderDataCell(children, true, false, true, false, key, false, true);
  };

  renderEEOIDefaultDataCell = (children, key) => {
    return this.renderDataCell(children, false, false, false, true, key);
  };

  renderEEOIRightDataCell = (children, key) => {
    return this.renderDataCell(children, true, false, false, true, key);
  };

  renderEEOITotalDataCell = (children, key) => {
    return this.renderDataCell(children, true, true, false, true, key, true);
  };

  renderHeaderRow = () => {
    return (
      <div className="summary-carbon-data__header">
        {this.renderDefaultHeaderRowCell('Fuel Type', 0)}
        {this.renderRightHeaderRowCell('CO₂ Emissions per day', 1)}
        {this.renderRightHeaderRowCell('CO₂ Emissions Total', 2)}
        {this.renderRightHeaderRowCell('', 3)}
      </div>
    );
  };

  renderCalcTooltip = (item) => {
    return (
      <IconTooltip icon={iconEnum.InfoOutline}>
        <div className="calc-icon-tooltip">
          <div className="formula">CO₂ = Consumption (MT) x CCF</div>
          <div className="calc">
            {`${item.total} = ${formatNumberWithNullAndUndefinedAsEmpty(
              this.props.consumedFuel.hasOwnProperty(item.fuelGradeCode)
                ? this.props.consumedFuel[item.fuelGradeCode]
                : 0
            )} (MT) x ${this.props.carbonFactors[item.fuelGradeCode]} `}
          </div>
        </div>
      </IconTooltip>
    );
  };

  renderDataRows = () => {
    return this.props.carbonBreakdown.map((item, index) => (
      <div key={index} className="summary-carbon-data__row">
        {this.renderCO2DefaultDataCell(item.fuelGradeLabel)}
        {this.renderCO2RightDataCell(item.perDay)}
        {this.renderCO2RightDataCell(item.total)}
        {this.renderCO2IconDataCell(this.renderCalcTooltip(item))}
      </div>
    ));
  };

  renderFooterRowCell = (content, rightAlign, highlight) => {
    const classes = classNames(
      'summary-carbon-data__footer-cell',
      {
        'summary-carbon-data__footer-cell--right': rightAlign,
      },
      {
        'summary-carbon-data__footer-cell--highlight': highlight,
      }
    );

    return <div className={classes}>{content}</div>;
  };

  renderFooterRow = () => {
    return (
      <div className="summary-carbon-data__footer">
        {this.renderFooterRowCell('Total')}
        {this.renderFooterRowCell(this.props.totalCarbonEmissionsDay, true, true)}
        {this.renderFooterRowCell(this.props.totalCarbonEmissions, true, true)}
        {this.renderFooterRowCell('')}
      </div>
    );
  };

  renderCO2Breakdown = () => {
    return (
      <div className="summary-carbon-data">
        {this.renderHeaderRow()}
        {this.renderDataRows()}
        {this.renderFooterRow()}
      </div>
    );
  };

  renderEEOINoteSectionTop = () => {
    return (
      <div className="summary-carbon-data__note-section-top-second">
        <h2>EEOI</h2>
        <span className="summary-carbon-data__note-section-top-left">
          Energy efficiency operational index
        </span>
        <IconTooltip
          icon={iconEnum.InfoOutline}
          className="summary-carbon-data__note-section-tooltip"
        >
          <div className="summary-carbon-data__note-section-tooltip-text">
            <span>Note: CO₂ Intensity grams per tonne mile</span>
            <span>{this.renderEEOINoteSectionBottom()}</span>
          </div>
        </IconTooltip>
      </div>
    );
  };

  renderEEOIEmptyHeaderRow = () => {
    const eeoiHeaderKey = 100;
    let emptySpace = <span>&nbsp;</span>;
    return (
      <div className="summary-carbon-data__header">
        {this.renderRightHeaderRowCell(emptySpace, eeoiHeaderKey + 1)}
        {this.renderRightHeaderRowCell(emptySpace, eeoiHeaderKey + 2)}
        {this.renderRightHeaderRowCell(emptySpace, eeoiHeaderKey + 3)}
        {this.renderRightHeaderRowCell(emptySpace, eeoiHeaderKey + 4)}
        {this.renderDefaultHeaderRowCell(emptySpace, eeoiHeaderKey * 2)}
        {this.renderHeaderRowCell('Total EEOI', true, true, eeoiHeaderKey * 3)}
      </div>
    );
  };

  renderEEOIHeaderRow = () => {
    const eeoiHeaderKey = 100;
    return (
      <div className="summary-carbon-data__header">
        {this.props.eeoiBreakdown.map((item, index) =>
          this.renderRightHeaderRowCell(item.fuelGradeLabel, eeoiHeaderKey + index)
        )}
        {this.renderDefaultHeaderRowCell('', eeoiHeaderKey * 2)}
        {this.renderHeaderRowCell('Total EEOI', true, true, eeoiHeaderKey * 3)}
      </div>
    );
  };

  renderEEOIDataEmptyRow = () => {
    let emptySpace = <span>&nbsp;</span>;
    return (
      <div className="summary-carbon-data__row">
        {this.renderEEOIRightDataCell(emptySpace, 101)}
        {this.renderEEOIRightDataCell(emptySpace, 102)}
        {this.renderEEOIRightDataCell(emptySpace, 103)}
        {this.renderEEOIRightDataCell(emptySpace, 104)}
        {
          <>
            {this.renderEEOIDefaultDataCell('', 200)}
            {this.renderEEOITotalDataCell(this.props.totalEEOI, 201)}
          </>
        }
      </div>
    );
  };

  renderEEOIDataRow = () => {
    return (
      <div className="summary-carbon-data__row">
        {this.props.eeoiBreakdown.map((item, index) =>
          this.renderEEOIRightDataCell(item.value, 100 + index)
        )}
        {
          <>
            {this.renderEEOIDefaultDataCell('', 200)}
            {this.renderEEOITotalDataCell(this.props.totalEEOI, 201)}
          </>
        }
      </div>
    );
  };

  renderEEOINoteSectionBottom = () => {
    return (
      <div className="summary-carbon-data__note-section-bottom">
        <span className="summary-carbon-data__note-section-bottom-left">EEOI (g/MTnm) =</span>
        <div className="summary-carbon-data__note-section-bottom-right">
          <span className="top-text">Total Emissions (g)</span>
          <hr className="divider" />
          <span className="bottom-text">
            Σ<sub>i=laden leg </sub>
            (Cargo Qty (MT)
            <sub>i</sub> x Distance (nm)
            <sub>i</sub>)
          </span>
        </div>
      </div>
    );
  };

  renderEEOI = () => {
    let eeoiHtml;
    if (this.props.eeoiBreakdown.length) {
      eeoiHtml = (
        <>
          {this.renderEEOIHeaderRow()}
          {this.renderEEOIDataRow()}
        </>
      );
    } else {
      eeoiHtml = (
        <>
          {this.renderEEOIEmptyHeaderRow()}
          {this.renderEEOIDataEmptyRow()}
        </>
      );
    }
    return (
      <>
        {this.renderEEOINoteSectionTop()}
        <div className="summary-carbon-data table-space">{eeoiHtml}</div>
      </>
    );
  };

  render() {
    return (
      <div>
        <div className="carbon-breakdown-section">{this.renderCO2Breakdown()}</div>
        <div>{this.renderEEOI()}</div>
      </div>
    );
  }
}

const mapStateToProps = selector;

export default connect(mapStateToProps)(CarbonBreakdownView);
