import { format } from 'date-fns';
import { veApi } from 'api';
import { saveToFile } from 'utilities/save-blob-to-file';

export type ExportDetailedCalculationRequest = object; // TODO expand the definition

export async function exportDetailedCalculationResult(
  request: ExportDetailedCalculationRequest
): void {
  const response = await veApi.post('/export/calculation-detailed-export', request, {
    responseType: 'blob',
  });

  const date = format(new Date(), 'dd MM yyyy');
  const filename = `Voyage Estimator export_${date}.xlsx`;
  saveToFile(response.data, filename);
}
