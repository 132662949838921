import * as async from 'async';
import * as store from 'store';
import VError from 'verror';
import { isEqual, isAfter, format, isValid, toDate } from 'date-fns';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { BALLAST, LADEN } from 'constants/enums/speed-and-con-types';
import { DEFAULT_WORKBOOK_NAME } from 'constants/defaults/names';
import { DRAFT_NUMBER_OF_DECIMAL_PLACES } from 'constants/enums/draft-units';
import { IMMERSION_NUMBER_OF_DECIMAL_PLACES } from 'constants/enums/immersion-units';
import { getMarketSegmentForNewWorkbook } from 'constants/market-segments';

import guid, { isValidGuid } from 'utilities/guid';
import { isLocationTypeSupported } from 'utilities/location';
import { isPrecisionGreaterThan } from 'utilities/number';
import { singleOrThrow } from 'utilities/iterable';

import { getCargoTypeById } from 'api/clients/cargo-type';
import { getLocationById } from 'api/clients/location';
import { getVesselById } from 'api/clients/vessel';
import { addEmptyVessel, setAllVesselDataFromDto } from 'actions/worksheet/vessel';
import { createWorkbookWithEmptyWorksheet, loadWorksheet } from 'actions/workbook';
import { locationAdded, locationChanged } from 'actions/worksheet/voyage';
import { cargoQuantityChanged } from 'actions/worksheet/voyage';
import { grossVoyageRateUpdated } from 'actions/worksheet/cargoes';
import { rebuildAndCalculateRoutesOnAllVoyageEntries } from 'actions/worksheet/voyage/routes';
import { setCargoFromDto } from 'actions/worksheet/cargoes';
import { cargoQuantityMaxPrecision } from 'modules/voyage/components/port';
import { grossVoyageRateMaxPrecision } from 'modules/cargoes/cargo/cargo.js';
import { deadweightMaxPrecision } from 'modules/vessel/vessel-detail';
import {
  speedMaxPrecision,
  speedMinValue,
  speedMaxValue,
} from 'modules/vessel/speed-and-consumptions/components/speed-input';
import {
  consumptionMaxPrecision,
  consumptionMinValue,
  consumptionMaxValue,
} from 'modules/vessel/speed-and-consumptions/components/moving-speed-and-cons';
import {
  deadweightMinValue,
  deadweightMaxValue,
  draftMinValue,
  draftMaxValueMeters,
  tpcmiMinValue,
  tpcmiMaxValueCm,
} from 'modules/worksheet/business-model/validation';
import { legacyAlphabeticalIdentifierStartingValForMultiCargoFeature } from 'reducers/worksheet/cargoes';
import { defaultSpeedAndCon } from 'reducers/worksheet/vessels/speed-and-consumptions';
import { mapVoyageEntryToVoyageLegEnum } from 'exposed-public-api/frontend-sdk/dtos/voyage-entry-type';
import type { MarketSegmentId } from 'constants/market-segments';

export type CreateWorksheetResponse = {
  worksheetId: string,
  workbookId: String,
};

export type CreateWorksheetVesselOpenPosition = {
  openDateFrom: Date,
  openDateTo: Date,
  openLocationId: string,
};

export type CreateWorksheetVessel = {
  id: number,
  deadweight?: number,
  draft?: number,
  tpc?: number,
  openPosition?: CreateWorksheetVesselOpenPosition,
  ballastSpeed?: number,
  ballastConsumption?: number,
  ladenSpeed?: number,
  ladenConsumption?: number,
};

type CreateWorksheetCargoType = {
  id: number,
  name: string,
};

type CreateWorksheetVoyageEntry = {
  voyageEntryType: VoyageEntryType,
  locationId: string,
};

export type CreateWorksheetRequest = {
  worksheetId?: string,
  workbookName?: string,
  vessels?: CreateWorksheetVessel[],
  cargoType?: CreateWorksheetCargoType,
  cargoQuantity?: number,
  voyageEntries?: CreateWorksheetVoyageEntry[],
  grossVoyageRate?: number,
};

export const createWorksheet =
  (
    request: CreateWorksheetRequest,
    createdFromProgramId: ProgramId,
    createdFromOriginId: OriginId
  ) =>
  async (dispatch, getState): CreateWorksheetResponse => {
    return await store.runBatchSyncingOnlyAtEnd(async () => {
      validateRequest(request);

      const {
        worksheetId: worksheetIdForNewWorksheet,
        workbookName,
        vessels,
        cargoType,
        cargoQuantity,
        voyageEntries,
        grossVoyageRate,
      } = request;

      const marketSegmentId = getMarketSegmentForNewWorkbook();

      const vesselDtosById = await fetchVessels(vessels, marketSegmentId);
      const cargoTypeDto = await fetchCargoType(cargoType);
      const locationDtosById = await fetchLocations(vessels, voyageEntries);

      const { worksheetId, workbookId } = await dispatch(
        createWorkbookWithEmptyWorksheet({
          worksheetId: worksheetIdForNewWorksheet,
          workbookName: workbookName || DEFAULT_WORKBOOK_NAME,
          createdFromProgramId: createdFromProgramId,
          createdFromOriginId: createdFromOriginId,
          marketSegmentId,
        })
      );

      await dispatch(loadWorksheet(worksheetId, /**force*/ true));

      await dispatch(addVessels(worksheetId, vessels, locationDtosById, vesselDtosById));

      await dispatch(addCargoType(cargoTypeDto, worksheetId));
      await dispatch(addVoyageEntries(worksheetId, voyageEntries, locationDtosById, cargoQuantity));
      await dispatch(rebuildAndCalculateRoutesOnAllVoyageEntries({ worksheetId }));

      await dispatch(setGrossVoyageRate(worksheetId, grossVoyageRate));

      return {
        workbookId,
        worksheetId,
      };
    });
  };

function validateRequest(request: CreateWorksheetRequest): void {
  if (typeof request !== 'object') {
    throw new Error(`This function must be called with an object argument`);
  }

  const { worksheetId, vessels, cargoType, voyageEntries, grossVoyageRate, cargoQuantity } =
    request;

  if (worksheetId && !isValidGuid(worksheetId)) {
    throw new Error('`worksheetId` property needs to be a valid guid');
  }

  if (vessels) {
    vessels.forEach(validateRequestVessel);
  }

  if (cargoType && (isNil(cargoType.id) || isNil(cargoType.name))) {
    throw new Error('`cargoType` property needs a cargo type id and name provided');
  }

  if (voyageEntries && voyageEntries.some((v) => !isValidGuid(v.locationId))) {
    throw new Error('`voyageEntries` location ids need to be a valid guid');
  }

  if (cargoQuantity && isPrecisionGreaterThan(cargoQuantity, cargoQuantityMaxPrecision)) {
    throw new Error(
      `\`cargoQuantity\` has exceeded the supported precision of ${cargoQuantityMaxPrecision}`
    );
  }

  if (grossVoyageRate && isPrecisionGreaterThan(grossVoyageRate, grossVoyageRateMaxPrecision)) {
    throw new Error(
      `\`grossVoyageRate\` has exceeded the supported precision of ${grossVoyageRateMaxPrecision}`
    );
  }
}

function validateRequestVessel(vessel: CreateWorksheetVessel): void {
  const {
    id,
    deadweight,
    draft,
    tpc,
    openPosition,
    ballastSpeed,
    ballastConsumption,
    ladenSpeed,
    ladenConsumption,
  } = vessel;

  if (isNil(id)) {
    throw new Error('A vessel `id` has to be provided');
  }

  validateDeadweight(deadweight);
  validateDraft(draft);
  validateTpc(tpc);
  validateOpenPosition(openPosition);
  validateSpeed(ballastSpeed, BALLAST);
  validateConsumption(ballastConsumption, BALLAST);
  validateSpeed(ladenSpeed, LADEN);
  validateConsumption(ladenConsumption, LADEN);

  function validateDeadweight(deadweight) {
    if (isNil(deadweight)) {
      return;
    }

    if (isPrecisionGreaterThan(deadweight, deadweightMaxPrecision)) {
      throw new Error(
        `\`deadweight\` has exceeded the supported precision of ${deadweightMaxPrecision}`
      );
    }

    if (deadweight < deadweightMinValue) {
      throw new Error(
        `\`deadweight\` is less than the minimum allowed value ${deadweightMinValue}`
      );
    }

    if (deadweight > deadweightMaxValue) {
      throw new Error(
        `\`deadweight\` has exceeded the maximum allowed value ${deadweightMaxValue}`
      );
    }
  }

  function validateDraft(draft) {
    if (isNil(draft)) {
      return;
    }

    if (isPrecisionGreaterThan(draft, DRAFT_NUMBER_OF_DECIMAL_PLACES)) {
      throw new Error(
        `\`draft\` has exceeded the supported precision of ${DRAFT_NUMBER_OF_DECIMAL_PLACES}`
      );
    }

    if (draft < draftMinValue) {
      throw new Error(`\`draft\` is less than the minimum allowed value ${draftMinValue}`);
    }

    if (draft > draftMaxValueMeters) {
      throw new Error(`\`draft\` has exceeded the maximum allowed value ${draftMaxValueMeters}`);
    }
  }

  function validateTpc(tpc) {
    if (isNil(tpc)) {
      return;
    }

    if (isPrecisionGreaterThan(tpc, IMMERSION_NUMBER_OF_DECIMAL_PLACES)) {
      throw new Error(
        `\`tpc\` has exceeded the supported precision of ${IMMERSION_NUMBER_OF_DECIMAL_PLACES}`
      );
    }

    if (tpc < tpcmiMinValue) {
      throw new Error(`\`tpc\` is less than the minimum allowed value ${tpcmiMinValue}`);
    }

    if (tpc > tpcmiMaxValueCm) {
      throw new Error(`\`tpc\` has exceeded the maximum allowed value ${tpcmiMaxValueCm}`);
    }
  }

  function validateOpenPosition(openPosition) {
    if (isNil(openPosition)) {
      return;
    }

    const { openDateFrom, openDateTo, openLocationId } = openPosition;

    if (isNil(openDateFrom) || isNil(openDateTo) || isNil(openLocationId)) {
      throw new Error(
        `\`openLocationId\`, \`openDateFrom\` and \`openDateTo\` must be defined together`
      );
    }

    if (!isValid(toDate(openDateFrom))) {
      throw new Error(`\`openDateFrom\` must be a valid date`);
    }

    if (!isValid(toDate(openDateTo))) {
      throw new Error(`\`openDateTo\` must be a valid date`);
    }

    if (!isValidGuid(openLocationId)) {
      throw new Error('`openLocationId` property needs to be a valid guid');
    }

    if (!isEqual(openDateFrom, openDateTo) && isAfter(openDateFrom, openDateTo)) {
      throw new Error(`\`openDateFrom\` has to come before \`openDateTo\``);
    }
  }

  function validateSpeed(speed, speedAndConType) {
    if (isNil(speed)) {
      return;
    }

    const speedPropertyName = getSpeedPropertyName(speedAndConType);

    if (isPrecisionGreaterThan(speed, speedMaxPrecision)) {
      throw new Error(
        `\`${speedPropertyName}\` has exceeded the supported precision of ${speedMaxPrecision}`
      );
    }

    if (speed < speedMinValue) {
      throw new Error(
        `\`${speedPropertyName}\` is less than the minimum allowed value ${speedMinValue}`
      );
    }

    if (speed > speedMaxValue) {
      throw new Error(
        `\`${speedPropertyName}\` has exceeded the maximum allowed value ${speedMaxValue}`
      );
    }

    function getSpeedPropertyName(speedAndConType) {
      switch (speedAndConType) {
        case BALLAST:
          return 'ballastSpeed';
        case LADEN:
          return 'ladenSpeed';
        default:
          throw new Error(
            `SpeedAndConType of '${speedAndConType}' is not supported when trying to determine speed property name.`
          );
      }
    }
  }

  function validateConsumption(consumption, speedAndConType) {
    if (isNil(consumption)) {
      return;
    }

    let consumptionPropertyName = getConsumptionPropertyName(speedAndConType);

    if (isPrecisionGreaterThan(consumption, consumptionMaxPrecision)) {
      throw new Error(
        `\`${consumptionPropertyName}\` has exceeded the supported precision of ${consumptionMaxPrecision}`
      );
    }

    if (consumption < consumptionMinValue) {
      throw new Error(
        `\`${consumptionPropertyName}\` is less than the minimum allowed value ${consumptionMinValue}`
      );
    }

    if (consumption > consumptionMaxValue) {
      throw new Error(
        `\`${consumptionPropertyName}\` has exceeded the maximum allowed value ${consumptionMaxValue}`
      );
    }

    function getConsumptionPropertyName(speedAndConType) {
      switch (speedAndConType) {
        case BALLAST:
          return 'ballastConsumption';
        case LADEN:
          return 'ladenConsumption';
        default:
          throw new Error(
            `SpeedAndConType of '${speedAndConType}' is not supported when trying to determine consumption property name.`
          );
      }
    }
  }
}

async function fetchVessels(vessels: CreateWorksheetVessel[], marketSegmentId: MarketSegmentId) {
  if (!vessels) {
    return null;
  }

  const vesselIds = vessels.map((v) => v.id);

  const vesselDtosById = new Map(
    await async.map(
      uniq(vesselIds),
      async.asyncify(async (vesselId) => {
        const vesselDto = await getVesselById({
          vesselId: vesselId,
          marketSegmentId: marketSegmentId,
        });

        if (isEmpty(vesselDto.speedAndCons)) {
          setDefaultSpeedAndConsDto(vesselDto);
        }

        return [vesselId, vesselDto];
      })
    )
  );

  return vesselDtosById;

  function setDefaultSpeedAndConsDto(vesselDto) {
    vesselDto.speedAndCons = {
      [BALLAST.id]: buildDefaultSpeedAndConDto(BALLAST),
      [LADEN.id]: buildDefaultSpeedAndConDto(LADEN),
    };
  }

  function buildDefaultSpeedAndConDto(speedAndConType) {
    return {
      typeId: speedAndConType.id,
      speed: defaultSpeedAndCon.speed,
      consumption: defaultSpeedAndCon.consumption,
      generatorConsumption: defaultSpeedAndCon.generatorConsumption,
      fuelGrade: defaultSpeedAndCon.fuelGrade,
      generatorFuelGrade: defaultSpeedAndCon.generatorConsumption,
      auditInfo: null,
    };
  }
}

async function fetchCargoType(cargoType: CreateWorksheetCargoType) {
  if (!cargoType) {
    return null;
  }

  return await getCargoTypeById(cargoType.name, cargoType.id);
}

async function fetchLocations(
  vessels: CreateWorksheetVessel[],
  voyageEntries: CreateWorksheetVoyageEntry[]
) {
  if (!vessels && !voyageEntries) {
    return;
  }

  const locationIds = [];

  if (voyageEntries) {
    const voyageLocationIds = voyageEntries.map((v) => v.locationId);
    locationIds.push(...voyageLocationIds);
  }

  if (vessels) {
    const openLocationIds = vessels
      .filter((v) => !isNil(v.openPosition) && !isNil(v.openPosition.openLocationId))
      .map((v) => v.openPosition.openLocationId);
    locationIds.push(...openLocationIds);
  }

  const locationDtosById = new Map(
    await async.map(
      uniq(locationIds),
      async.asyncify(async (locationId) => [locationId, await getLocationById(locationId)])
    )
  );

  guardUnsupportedLocationType(
    Array.from(locationDtosById.values()),
    'Unsupported types of locations detected'
  );

  return locationDtosById;
}

function guardUnsupportedLocationType(locations, errorMessage: string) {
  const unsupportedLocations = locations.filter((v) => !isLocationTypeSupported(v.locationTypeId));

  if (unsupportedLocations.length > 0) {
    throw new VError(
      {
        unsupportedLocationIds: unsupportedLocations.map((l) => [l.locationId, l.locationTypeId]),
      },
      errorMessage
    );
  }
}

const addVessels =
  (
    worksheetId: string,
    requestVessels: CreateWorksheetVessel[],
    openLocationDtosById,
    vesselDtosById
  ) =>
  async (dispatch, getState) => {
    if (!requestVessels) {
      return;
    }

    const firstVesselEntryId = getState().worksheetsById[worksheetId].vessels[0].entryId;

    for (let i = 0; i < requestVessels.length; i++) {
      const requestVessel = requestVessels[i];
      const vesselId = requestVessel.id;
      let vesselEntryId = undefined;
      // Handling the initial empty vessel slot that always exist in a newly created worksheet.
      if (i === 0) {
        vesselEntryId = firstVesselEntryId;
      } else {
        vesselEntryId = await dispatch(
          addEmptyVessel({
            worksheetId: worksheetId,
          })
        );
      }

      const vesselDto = cloneAndOverrideVesselDto(
        vesselDtosById.get(vesselId),
        openLocationDtosById,
        requestVessel
      );

      await dispatch(
        setAllVesselDataFromDto({
          vesselDto,
          worksheetId,
          vesselEntryId,
        })
      );
    }
  };

function cloneAndOverrideVesselDto(
  vesselDto,
  openLocationDtosById: Map<string, LocationResponse>,
  requestVessel: CreateWorksheetVessel
) {
  const vesselDtoClone = cloneDeep(vesselDto);

  if (!isNil(requestVessel.deadweight)) {
    vesselDtoClone.deadweight = requestVessel.deadweight;
  }

  if (!isNil(requestVessel.draft)) {
    vesselDtoClone.draft = requestVessel.draft;
  }

  if (!isNil(requestVessel.tpc)) {
    vesselDtoClone.tpcmi = requestVessel.tpc;
  }

  if (!isNil(requestVessel.openPosition)) {
    const { openDateFrom, openDateTo, openLocationId } = requestVessel.openPosition;

    const openLocationDto = openLocationDtosById.get(openLocationId);

    const dateFormat = 'dd/MM/yy';
    const displayText = `${format(openDateFrom, dateFormat)} - ${format(openDateTo, dateFormat)}`;

    const openPosition = {
      locationId: openLocationDto.locationId,
      locationTypeId: openLocationDto.locationTypeId,
      locationName: openLocationDto.locationName,
      latitude: openLocationDto.latitude,
      longitude: openLocationDto.longitude,
      country: openLocationDto.country,
      zone: openLocationDto.zone,
      start: openDateFrom,
      end: openDateTo,
      displayText,
    };

    vesselDtoClone.openPosition = openPosition;
  }

  if (!isNil(requestVessel.ballastSpeed)) {
    vesselDtoClone.speedAndCons[BALLAST.id].speed = requestVessel.ballastSpeed;
  }

  if (!isNil(requestVessel.ballastConsumption)) {
    vesselDtoClone.speedAndCons[BALLAST.id].consumption = requestVessel.ballastConsumption;
  }

  if (!isNil(requestVessel.ladenSpeed)) {
    vesselDtoClone.speedAndCons[LADEN.id].speed = requestVessel.ladenSpeed;
  }

  if (!isNil(requestVessel.ladenConsumption)) {
    vesselDtoClone.speedAndCons[LADEN.id].consumption = requestVessel.ladenConsumption;
  }

  return vesselDtoClone;
}

const addCargoType = (cargoTypeDto, worksheetId: string) => async (dispatch) => {
  if (!cargoTypeDto) {
    return;
  }

  await dispatch(
    setCargoFromDto(
      cargoTypeDto,
      legacyAlphabeticalIdentifierStartingValForMultiCargoFeature,
      worksheetId
    )
  );
};

const addVoyageEntries =
  (
    worksheetId: string,
    voyageEntries: CreateWorksheetVoyageEntry[],
    voyageLocationDtosById,
    cargoQuantity?: number
  ) =>
  async (dispatch, getState) => {
    if (!voyageEntries) {
      return;
    }

    const cargoId = singleOrThrow(getState().worksheetsById[worksheetId].cargoes).id;

    for (let i = 0; i < voyageEntries.length; i++) {
      let voyageEntryId = undefined;

      if (i === 0 || i === 1) {
        // Using the ids from the first two voyage entry slots that always exist in a newly created worksheet.
        voyageEntryId = getState().worksheetsById[worksheetId].voyage.legs[i].id;
      } else {
        voyageEntryId = guid();
        await dispatch(
          locationAdded({
            cargoId: cargoId,
            worksheetId: worksheetId,
          })
        );
      }

      const { locationId, voyageEntryType } = voyageEntries[i];
      const voyageLocationDto = voyageLocationDtosById.get(locationId);

      const locationToAdd = {
        portInSequenceBeingChanged: voyageLocationDto,
        type: mapVoyageEntryToVoyageLegEnum(voyageEntryType),
      };

      await dispatch(locationChanged(locationToAdd, voyageEntryId, worksheetId));

      if (cargoQuantity) {
        await dispatch(cargoQuantityChanged(cargoQuantity, voyageEntryId, worksheetId));
      }
    }
  };

const setGrossVoyageRate = (worksheetId: string, grossVoyageRate?: number) => async (dispatch) => {
  if (!grossVoyageRate) {
    return;
  }

  await dispatch(
    grossVoyageRateUpdated(
      grossVoyageRate,
      legacyAlphabeticalIdentifierStartingValForMultiCargoFeature,
      worksheetId
    )
  );
};
