import React from 'react';
import VesselDetails from 'modules/vessel/vessel-detail';
import SpeedAndConsumptions from 'modules/vessel/speed-and-consumptions';
import './vessel.scss';
import { connect } from 'react-redux';
import { scrubberTypeUpdated } from 'actions/worksheet/vessel';
import selector from './selector';
import { EmissionControl } from './components/emission-control';
import {
  scrubberFuelConsumptionChanged,
  zoneSpecificSecaFuelGradeChanged,
  zoneSpecificBannedSlugDischargeFuelGradeChanged,
  zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged,
} from 'actions/worksheet/speed-and-consumptions';
import { speedAndConsumptionsModes } from 'api/clients/calculation';

export const Vessel = ({
  worksheetId,
  vessel,
  speedAndConsumptionsMode,
  marketSegmentId,
  ...props
}) => {
  return (
    <div className="vessel-panel-multi-vessel-wrapper">
      <div className="vessel-wrapper">
        <VesselDetails vessel={vessel} />
        <SpeedAndConsumptions
          vessel={vessel}
          marketSegmentId={marketSegmentId}
          disabled={speedAndConsumptionsMode !== speedAndConsumptionsModes.manual}
        />
      </div>
      <div className="imo-wrapper">
        <EmissionControl
          vessel={vessel}
          worksheetId={worksheetId}
          scrubberFuelConsumptionChanged={props.scrubberFuelConsumptionChanged}
          scrubberTypeUpdated={props.scrubberTypeUpdated}
          zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged={
            props.zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged
          }
          zoneSpecificBannedSlugDischargeFuelGradeChanged={
            props.zoneSpecificBannedSlugDischargeFuelGradeChanged
          }
          zoneSpecificSecaFuelGradeChanged={props.zoneSpecificSecaFuelGradeChanged}
        />
      </div>
    </div>
  );
};

export default connect(selector, {
  scrubberFuelConsumptionChanged,
  scrubberTypeUpdated,
  zoneSpecificBannedSlugDischargeFuelGradeChanged,
  zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged,
  zoneSpecificSecaFuelGradeChanged,
})(Vessel);
