export const TC_TO_FREIGHT = {
  key: 1,
  label: 'TC to Voyage Rate',
  inputLabel: '+/- Gross TC ($/d)',
};
export const FREIGHT_TO_TC = {
  key: 2,
  label: 'Voyage Rate to TC',
  inputLabel: '+/- Gross VR ($/MT)',
};
