/// E.g. string, boolean, number, or objects with overriden ToString (e.g. JS Date)
type ValueWithIdentityInToString = mixed | { toString: () => string };

/// A semantic markup on a `string` to denote that it represents a set.
type SetAsEquatableString<
  // Ignore unused T. It's a markup type that can still by type checking to ensure types of these match.
  // eslint-disable-next-line no-unused-vars
  T: ValueWithIdentityInToString,
> = string;

/// Useful for calculating groupings by multi-item-values (e.g.  `lodash/groupBy`)
export function getEquatableString<T: ValueWithIdentityInToString>(
  set: Set<T>
): SetAsEquatableString<T> {
  const separator = '_'; // Favor #UnderscoreCharacter_BenefitForUsageInKeyAndIds
  return (
    [...set]
      .map((x) => x.toString().replace(separator, `${separator}${separator}`))
      // Alphabetical sort to favor #StringsRepresentingSets_UseAlphabeticalEnumeration
      .sort()
      .join(separator)
  );
}

/// Useful for comparing sets, this compare is indifferent of order
export function equals(left, right) {
  if (left === null && right === null) return true;
  if (left !== null && right !== null) {
    if (left.size !== right.size) return false;
    for (var item of left) {
      if (!right.has(item)) return false;
    }

    return true;
  }
  return false;
}

//Generates a powerset (https://en.wikipedia.org/wiki/Power_set)
//Given an array of distinct values, generate sets with all distinct combinations of varying length.
export function generatePowerset(values: T[]): Set<T> {
  if (values.length !== new Set(values).size) {
    throw new Error('Generating a powerset requires the input to be a distinct list.');
  }

  const combinations = [[]];

  for (let i = 0; i < values.length; i++) {
    const currentLength = combinations.length;

    for (let j = 0; j < currentLength; j++) {
      combinations.push(combinations[j].concat(values[i]));
    }
  }

  const powerset = combinations.map((combination) => new Set(combination));
  return powerset;
}
