import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import {
  INDIVIDUAL_SUMMARY_PANEL,
  INTAKE_CALCULATOR_PANEL,
  COMPARISON_SUMMARY_PANEL,
} from 'constants/enums/stage-panels';

import ActionBar from 'modules/action-bar';
import { Tab } from 'components/tab';
import { setStageRightAs } from 'actions/stage';

import './styles.scss';
import { selector } from './selector';
import { eventDestination, trackClickEvent } from '../../diagnostics/calc-trackevents';

export class ActionBarSecondary extends Component {
  tabMap = [
    {
      key: INDIVIDUAL_SUMMARY_PANEL,
      name: 'Summary',
      onClick: () => this.showPanel(INDIVIDUAL_SUMMARY_PANEL),
    },
    {
      key: INTAKE_CALCULATOR_PANEL,
      name: 'Intake',
      onClick: () => this.showPanel(INTAKE_CALCULATOR_PANEL),
    },
    {
      key: COMPARISON_SUMMARY_PANEL,
      name: 'Comparison',
      onClick: () => this.showPanel(COMPARISON_SUMMARY_PANEL),
    },
  ];

  showPanel = (panel) => {
    this.props.setStageRightAs(panel);
  };

  render() {
    return (
      <ActionBar className="action-bar__secondary">
        <ActionBarTabs tabMap={this.tabMap} activeTab={this.props.activeTab} />
      </ActionBar>
    );
  }
}

const ActionBarTabs = ({ tabMap, activeTab }) => {
  return tabMap.map(({ key, name, onClick, feature }) => {
    const isActive = activeTab === key;

    const classes = classNames('action-bar__secondary--tab', {
      'action-bar__secondary--tab-active': isActive,
    });

    return (
      <Tab
        key={key}
        isActive={isActive}
        className={classes}
        onClick={() => {
          onClick();
          trackClickEvent(`panel/${name.toString().toLowerCase()}`);
        }}
      >
        {name}
      </Tab>
    );
  });
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setStageRightAs }, dispatch);
}

const ConnectedActionBarSecondary = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionBarSecondary);

export default ConnectedActionBarSecondary;
