import {
  isUrlsHostnameInDomain,
  isUrlsPortDefault,
  isUrlsProtocolWithEncryption,
} from 'utilities/url';
import { allowedPublicInternetDomains, allowedReservedPrivateDomains } from './allowed-domains';

export function isOriginUrlAllowed(originUrl: URL | string) {
  if (typeof originUrl === 'string') originUrl = new URL(originUrl);

  if (
    allowedPublicInternetDomains.some((allowedDomain) =>
      isUrlsHostnameInDomain(originUrl, allowedDomain)
    )
  ) {
    return (
      /* Only allow encrypted public internet traffic. */
      isUrlsProtocolWithEncryption(originUrl) &&
      /* Restrict non-default ports - this isn't strictly needed if the origin authority strictly authorizes the content of non-default ports, but let's restrict them until we learn what the use case is. */
      isUrlsPortDefault(originUrl)
    );
  }

  if (
    allowedReservedPrivateDomains.some((allowedDomain) =>
      isUrlsHostnameInDomain(originUrl, allowedDomain)
    )
  ) {
    /*
    Relaxing the checks (especially port & encryption) to make development easier.

    NOTE: This isn't insecure because these hostnames can never appear on the public internet (they're ['reserved' by the DNS standard](https://tools.ietf.org/id/draft-chapin-rfc2606bis-00.html)), and they would need to not be in order to be able to be an attack vector on other users.
    The only ways for these addresses to appear to the user unwittingly is if he has malware, but in that case, that malware can change the user's DNS and trusted certificate as it pleases, so adding checks on ports or encryption doesn't do much anyways.
    */
    return true;
  }
  return false;
}
