import {
  CANAL_BALLAST_COST_CHANGED,
  CANAL_LADEN_COST_CHANGED,
  CANAL_BALLAST_WAITING_TIME_CHANGED,
  CANAL_LADEN_WAITING_TIME_CHANGED,
  CANAL_BALLAST_TRANSIT_TIME_CHANGED,
  CANAL_LADEN_TRANSIT_TIME_CHANGED,
} from 'constants/action-types/worksheet/canals';

const actionBuilder = (type, payload, id, worksheetId) => ({
  worksheetId,
  type,
  payload,
  id,
});

export function ballastCostChanged(payload, id, worksheetId) {
  return actionBuilder(CANAL_BALLAST_COST_CHANGED, payload, id, worksheetId);
}

export function ladenCostChanged(payload, id, worksheetId) {
  return actionBuilder(CANAL_LADEN_COST_CHANGED, payload, id, worksheetId);
}

export function ballastWaitingTimeChanged(payload, id, worksheetId) {
  return actionBuilder(CANAL_BALLAST_WAITING_TIME_CHANGED, payload, id, worksheetId);
}

export function ladenWaitingTimeChanged(payload, id, worksheetId) {
  return actionBuilder(CANAL_LADEN_WAITING_TIME_CHANGED, payload, id, worksheetId);
}

export function ballastTransitTimeChanged(payload, id, worksheetId) {
  return actionBuilder(CANAL_BALLAST_TRANSIT_TIME_CHANGED, payload, id, worksheetId);
}

export function ladenTransitTimeChanged(payload, id, worksheetId) {
  return actionBuilder(CANAL_LADEN_TRANSIT_TIME_CHANGED, payload, id, worksheetId);
}
