import React, { FC, useState, useEffect } from 'react';
import { IconButton } from '../../components/button';
import { iconEnum } from '../../components/icons';
import VerticalDivider from '../../components/divider/vertical';
import Dropdown from '../../components/dropdown';
import { speedAndConsumptionsModes } from '../../api/clients/calculation';
import { ViewModesItems } from '../../constants/enums/comparison-view-mode';
import Toggle from '../../components/toggle';
import { FeatureToggle } from '../../components/feature-toggles';
import { ExportButton } from '../../components/export-button';
import { connect } from 'react-redux';
import { selector } from './selector';
import { setSpeedAndConsumptionsMode } from '../../actions/worksheet-comparison';
import { IViewMode } from '../../constants/enums/comparison-view-mode';
import { VE } from '../../typings/data-types.d.ts';
import { ComparisonTableColumnsSetter } from '../comparison-table-column-setter';
import { IconTooltip } from 'components/icon-tooltip';
import { VIEW_RATES_INFO_NOTICE } from '../../constants/enums/emit-notices';
import { appRootNoticesEmitter } from '../app-root';
import InfoBox from '../../components/notice-box/info-box';
import EmitNotice from '../../components/notices-emitter/emit-notice';
import {
  adjustColumnsToViewRates,
  triggerUpdateComparisonTableState,
} from '../../actions/worksheet-comparison/comparison-table-settings';
import { updateComparisonTableState } from '../../api/clients/user-preference';
import { SecondaryButton } from '../../components/button';
import { eventDestination, trackEvent } from '../../diagnostics/calc-trackevents';
import { isDry } from 'constants/market-segments';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export interface IComparisonTableUserControls {
  viewMode: IViewMode;
  switchViewMode: (viewMode) => void;
  shouldDisableActions: boolean;
  onBunkerRefreshClick: () => void;
  onExportComparisonSummaryClick: () => void;
  runCalculateEstimateForWorksheetsInComparison: () => void;
  activeWorkbook?: VE.IWorkbook;
  isComparisonTableExpanded: boolean;
  expandComparisonTable: () => void;
  setSpeedAndConsumptionsMode: () => void;
  columnsState?: Record<string, any>[];
  activeWorksheet?: any;
  adjustColumnsToViewRates: () => void;
  tableConfiguration?: Record<string, any>[];
  triggerUpdateComparisonTableState: () => void;
}
export const ComparisonTableUserControls: FC<IComparisonTableUserControls> = ({
  viewMode,
  switchViewMode,
  shouldDisableActions,
  onBunkerRefreshClick,
  onExportComparisonSummaryClick,
  runCalculateEstimateForWorksheetsInComparison,
  activeWorkbook,
  isComparisonTableExpanded,
  expandComparisonTable,
  setSpeedAndConsumptionsMode,
  columnsState,
  activeWorksheet,
  adjustColumnsToViewRates,
  tableConfiguration,
  triggerUpdateComparisonTableState,
}) => {
  const updateSpeedAndConsumptionsMode = async (
    speedAndConsumptionsMode: SpeedAndConsumptionsMode
  ) => {
    await setSpeedAndConsumptionsMode(activeWorkbook, speedAndConsumptionsMode);
  };
  const isCheapestModeOn =
    activeWorkbook.speedAndConsumptionsMode === speedAndConsumptionsModes.findCheapest;

  const isAnyTCColumnSelected = columnsState?.length
    ? columnsState.some((column) => column.parentId === 'TC' && column.state === true)
    : true;
  const isAnyVRColumnSelected = columnsState?.length
    ? columnsState.some((column) => column.parentId === 'VR' && column.state === true)
    : true;
  const viewLabel = viewMode.label;
  const [columnMismatch, setColumnMismatch] = useState(false);

  useEffect(() => {
    if (!isCheapestModeOn) {
      setColumnMismatch(false);
    }
    if (viewLabel === 'Time Charter to Voyage' && isCheapestModeOn && !isAnyTCColumnSelected) {
      setColumnMismatch(true);

      trackEvent(
        'ComparisonTable',
        'ComparisonTableIllegalViewStateChanged',
        {
          illegalState: true,
          viewRates: viewLabel,
        },
        {},
        eventDestination.ANALYSIS
      );
    }

    if (viewLabel === 'Time Charter to Voyage' && isCheapestModeOn && isAnyTCColumnSelected) {
      setColumnMismatch(false);

      trackEvent(
        'ComparisonTable',
        'ComparisonTableIllegalViewStateChanged',
        {
          illegalState: false,
          viewRates: viewLabel,
        },
        {},
        eventDestination.ANALYSIS
      );
    }
  }, [isAnyTCColumnSelected, viewLabel, isCheapestModeOn, columnMismatch]);

  useEffect(() => {
    if (!isCheapestModeOn) {
      setColumnMismatch(false);
    }

    if (viewLabel === 'Voyage to Time Charter' && isCheapestModeOn && !isAnyVRColumnSelected) {
      setColumnMismatch(true);

      trackEvent(
        'ComparisonTable',
        'ComparisonTableIllegalViewStateChanged',
        {
          illegalState: true,
          viewRates: viewLabel,
        },
        {},
        eventDestination.ANALYSIS
      );
    }
    if (viewLabel === 'Voyage to Time Charter' && isCheapestModeOn && isAnyVRColumnSelected) {
      setColumnMismatch(false);

      trackEvent(
        'ComparisonTable',
        'ComparisonTableIllegalViewStateChanged',
        {
          illegalState: false,
          viewRates: viewLabel,
        },
        {},
        eventDestination.ANALYSIS
      );
    }
  }, [isAnyVRColumnSelected, viewLabel, isCheapestModeOn, columnMismatch]);

  const onChangeHandler = async (isChecked) => {
    trackEvent(
      'ComparisonTable',
      'CheapestModeToggleClicked',
      {
        toggled: isChecked,
      },
      {},
      eventDestination.ANALYSIS
    );
    const newSpeedAndConsumptionsMode = isChecked
      ? speedAndConsumptionsModes.findCheapest
      : speedAndConsumptionsModes.manual;

    await updateSpeedAndConsumptionsMode(newSpeedAndConsumptionsMode);
    await runCalculateEstimateForWorksheetsInComparison();
  };
  const onStateChangeHandler = (state) => {
    const prepareStateToUpdate = tableConfiguration
      .filter((el) => el.parentId !== '')
      .map((e) => {
        return {
          columnId: e.columnId,
          state: e.state,
        };
      });

    if (state.isOpen === false) {
      updateComparisonTableState(prepareStateToUpdate);
    }

    trackEvent(
      'ComparisonTable',
      'ComparisonTableColumnSelection',
      {
        treeState: prepareStateToUpdate,
      },
      {},
      eventDestination.ANALYSIS
    );
  };

  return (
    <>
      <div className="ve-comparison-table__user-controls">
        <div className="ve-comparison-table__expand-icon-section">
          <IconButton
            icon={!isComparisonTableExpanded ? iconEnum.ArrowBack : iconEnum.ArrowForward}
            onClick={expandComparisonTable}
            className="ve-comparison-table__expand-icon"
            diagnosticId="ComparisonSummary/ExpandTable"
          />
          <VerticalDivider className="vertical-divider" />
        </div>

        {isDry(activeWorkbook.marketSegmentId) && (
          <>
            <div className="ve-comparison-table__speed-and-consumption-mode">
              <span className="ve-comparison-table__speed-and-consumption-mode--title">
                Cheapest Mode
              </span>
              <Toggle
                id="cheapestMode"
                className="ve-comparison-table__speed-and-consumption-mode--toggle"
                checked={isCheapestModeOn}
                onChange={onChangeHandler}
                label={isCheapestModeOn ? 'On' : 'Off'}
                diagnosticId="ComparisonTable/CheapestModeToggleClicked"
              />
            </div>
            <div className="ve-comparison-table__speed-and-consumption-mode--divider-section">
              <IconTooltip icon={iconEnum.InfoOutline}>
                <div className="ve-comparison-table__speed-and-consumption-mode--tooltip-text">
                  Uses speed and consumptions from Vessel API and performs permutations on the
                  ballast and laden pairs to find the cheapest by voyage rate gross. Note: Entered
                  speed and consumptions are ignored!
                </div>
              </IconTooltip>
              <VerticalDivider className="vertical-divider" />
            </div>
            <div className="ve-comparison-table__speed-and-consumption-mode--dropdown">
              {isCheapestModeOn ? (
                <Dropdown
                  id="viewMode"
                  onChange={async (viewMode) => {
                    switchViewMode(viewMode);
                    if (
                      activeWorkbook.speedAndConsumptionsMode ===
                      speedAndConsumptionsModes.findCheapest
                    ) {
                      /* only need to do this for find cheapest because:
                            1) it obtains the speed and consumptions from the server
                            2) keep things simple (for now) as using previous calculations to select a new cheapest, when the view mode has changed, we will have to think about freshness of data */
                      await runCalculateEstimateForWorksheetsInComparison();
                    }
                  }}
                  items={ViewModesItems.filter((item) => item !== viewMode)}
                  selectedItem={viewMode}
                  diagnosticId="WorksheetCompariton/ChangeViewMode"
                />
              ) : (
                isComparisonTableExpanded && (
                  <div>Turn on to see the cheapest rates for each vessel</div>
                )
              )}
            </div>
          </>
        )}
        <div className="ve-comparison-table__user-actions">
          <div className="ve-comparison-table__columns-setter">
            <ComparisonTableColumnsSetter
              tableConfiguration={tableConfiguration}
              onStateChangeHandler={onStateChangeHandler}
            />
          </div>
          <FeatureToggle feature="worksheetComparisonBunkerRefresh">
            <div
              className="ve-comparison-table__refresh-bunkers"
              data-disabled={shouldDisableActions}
            >
              <span onClick={shouldDisableActions ? undefined : onBunkerRefreshClick}>
                Refresh Bunker Prices
              </span>
              <IconButton
                icon={iconEnum.Refresh}
                className="ve-comparison-table__refresh-bunkers--icon-btn"
                onClick={onBunkerRefreshClick}
                disabled={shouldDisableActions}
                diagnosticId="ComparisonSummary/RefreshBunkers"
              />
            </div>
          </FeatureToggle>

          <div
            className="ve-comparison-table__user-actions-export"
            data-tooltip-content="Export comparison"
            data-tooltip-id="export-comparison"
          >
            <ExportButton
              isDisabled={shouldDisableActions}
              onClick={onExportComparisonSummaryClick}
              diagnosticId="WebApp/ComparisonSummary/ExportComparison"
            />
            <ReactTooltip
              id="export-comparison"
              place="bottom"
              className="ve-tooltip-default"
              style={{ fontSize: '13px' }}
              noArrow
            />
          </div>
        </div>
      </div>
      {columnMismatch && (
        <EmitNotice
          type={VIEW_RATES_INFO_NOTICE}
          emitter={appRootNoticesEmitter}
          dismissOnUnmount={true}
        >
          {({ dismiss, additionalProps }) => (
            <InfoBox additionalprops={additionalProps}>
              <div className="warning-box__text">
                {`Your column selection doesn't match the ${viewLabel} rate.
              Would you like to display the relevant columns to help you find
              the cheapest ${viewLabel} rates?`}
              </div>
              <SecondaryButton
                className="notice-box__button"
                onClick={() => {
                  adjustColumnsToViewRates(viewLabel);
                  dismiss();
                  setColumnMismatch(false);
                  triggerUpdateComparisonTableState();
                }}
              >
                YES
              </SecondaryButton>
              <SecondaryButton className="notice-box__button" onClick={dismiss}>
                NO
              </SecondaryButton>
              <IconButton
                aria-label="Dismiss"
                icon={iconEnum.Close}
                className="notice-box__standard-dismiss-button"
                onClick={dismiss}
                diagnosticId="CopyWorksheetButton/DismissSuccessfulCopy"
              />
            </InfoBox>
          )}
        </EmitNotice>
      )}
    </>
  );
};

const mapStateToProps = selector;

const ComparisonTableUserControlsContainer = connect(mapStateToProps, {
  setSpeedAndConsumptionsMode,
  adjustColumnsToViewRates,
  triggerUpdateComparisonTableState,
})(ComparisonTableUserControls);

export default ComparisonTableUserControlsContainer;
