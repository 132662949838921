import stringEnum, { StringEnum } from 'utilities/enum/string-enum';

const phaseTypeIdsArray = ['sea', 'canal', 'port'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
export type PhaseTypeId = 'sea' | 'canal' | 'port';
export const phaseTypeIds: StringEnum<PhaseTypeId> = stringEnum(phaseTypeIdsArray);

export type PhaseTypeNumericId = $Values<typeof phaseTypeEnum>; // TODO - when moving to TypeScript, use our utility of `ValueOf<typepf phaseTypeEnum>`

export const phaseTypeNumericIds: { [PhaseTypeId]: PhaseTypeNumericId } = {
  [phaseTypeIds.sea]: 1,
  [phaseTypeIds.canal]: 2,
  [phaseTypeIds.port]: 3,
};

export const isCanalPhase = (type: PhaseTypeNumericId) => type === phaseTypeNumericIds.canal;
export const isPortPhase = (type: PhaseTypeNumericId) => type === phaseTypeNumericIds.port;
export const isSeaPhase = (type: PhaseTypeNumericId) => type === phaseTypeNumericIds.sea;
