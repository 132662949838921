export const VOYAGE_TYPE_CHANGED = 'VOYAGE_TYPE_CHANGED';
export const VOYAGE_LOCATION_CHANGED = 'VOYAGE_LOCATION_CHANGED';
export const VOYAGE_LEG_MOVED = 'VOYAGE_LEG_MOVED';
export const VOYAGE_GEAR_CHANGED = 'VOYAGE_GEAR_CHANGED';
export const VOYAGE_DRAFT_CHANGED = 'VOYAGE_DRAFT_CHANGED';
export const VOYAGE_DRAFT_UNIT_CHANGED = 'VOYAGE_DRAFT_UNIT_CHANGED';
export const VOYAGE_SALINITY_CHANGED = 'VOYAGE_SALINITY_CHANGED';
export const VOYAGE_CARGO_QUANTITY_CHANGED = 'VOYAGE_CARGO_QUANTITY_CHANGED';
export const VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS = 'VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS';
export const VOYAGE_LOAD_DISCHARGE_RATE_CHANGED = 'VOYAGE_LOAD_DISCHARGE_RATE_CHANGED';
export const VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED = 'VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED';
export const VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED = 'VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED';
export const VOYAGE_DELAY_CHANGED = 'VOYAGE_DELAY_CHANGED';
export const VOYAGE_DELAY_UNIT_CHANGED = 'VOYAGE_DELAY_UNIT_CHANGED';
export const VOYAGE_DELAY_CHANGED_ALL_LEGS = 'VOYAGE_DELAY_CHANGED_ALL_LEGS';
export const VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS =
  'VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS';
export const VOYAGE_TURN_TIME_CHANGED = 'VOYAGE_TURN_TIME_CHANGED';
export const VOYAGE_TURN_TIME_UNIT_CHANGED = 'VOYAGE_TURN_TIME_UNIT_CHANGED';
export const VOYAGE_WEATHER_FACTOR_CHANGED = 'VOYAGE_WEATHER_FACTOR_CHANGED';
export const VOYAGE_PORT_COST_CHANGED = 'VOYAGE_PORT_COST_CHANGED';
export const VOYAGE_IS_IN_SECA_CHANGED = 'VOYAGE_IS_IN_SECA_CHANGED';
export const VOYAGE_IS_IN_EEA_CHANGED = 'VOYAGE_IS_IN_EEA_CHANGED';
export const VOYAGE_LOCATION_DELETED = 'VOYAGE_LOCATION_DELETED';
export const VOYAGE_LOCATION_ADDED = 'VOYAGE_LOCATION_ADDED';
export const VOYAGE_ROUTE_VARIANTS_SET = 'VOYAGE_ROUTE_VARIANTS_SET';
export const VOYAGE_AVOID_SECA_ZONES_CHANGED = 'VOYAGE_AVOID_SECA_ZONES_CHANGED';
export const VOYAGE_SET_PORTS_IN_SECA = 'VOYAGE_SET_PORTS_IN_SECA';
export const VOYAGE_TOGGLE_AUTO_INTAKE_CALC = 'VOYAGE_TOGGLE_AUTO_INTAKE_CALC';
export const VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED: string =
  'VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED';
export const VOYAGE_CONSUMPTION_OVERRIDE_QTY_SET: string = 'VOYAGE_CONSUMPTION_OVERRIDE_QTY_SET';
export const VOYAGE_LEGS_LOADED = 'VOYAGE_LEGS_LOADED';
