import React, { Fragment, Component } from 'react';
import Filter from '../../filter';
import WorkbooksGrid from '../../workbooks-grid';
import { getDefaultGridOptions } from '../../workbooks-grid/default-grid-options';
import { ActionsRenderer } from '../team-actions-renderer';

export class TeamWorkbooksGrid extends Component {
  constructor(props) {
    super(props);

    const gridOptions = getDefaultGridOptions();
    gridOptions.columnDefs.push({
      colId: 'actionsId',
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        onCopied: this.props.loadMyWorkbooks,
      },
      sortable: false,
      width: 40,
    });
    this.state = {
      freeTextFilter: '',
      gridOptions: gridOptions,
    };
  }

  handleFilterChange = (e) => {
    this.setState({ freeTextFilter: e.target.value });
  };

  render() {
    const { workbooks, activeWorkbookId } = this.props;
    return (
      <Fragment>
        <Filter
          value={this.state.freeTextFilter}
          onChange={this.handleFilterChange}
          title={this.props.title}
        />
        <WorkbooksGrid
          workbooks={workbooks}
          gridOptions={this.state.gridOptions}
          activeWorkbookId={activeWorkbookId}
          filterText={this.state.freeTextFilter}
        />
      </Fragment>
    );
  }
}

export default TeamWorkbooksGrid;
