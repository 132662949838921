import React from 'react';
import { useTestId } from 'components/testable';
import { Helmet } from 'react-helmet';
import { LightbulbIcon } from 'components/icons';
import ViewWorkbooksButton from '../view-workbooks-button';
import { StageRightPortalContent } from 'modules/stage/right-stage-portal';
import CenteredContent from 'components/centered-content';

export function WorkbookEmptyPlaceholder({ workbook, worksheets }) {
  const testId = useTestId('workbook-empty-placeholder');
  const isEmptyWorksheets = !worksheets || worksheets?.length === 0;

  return (
    <div className="no-document-placeholder" data-testid={testId}>
      {isEmptyWorksheets ? (
        <>
          <LightbulbIcon aria-label="Information" className="no-document-placeholder__type-icon" />
          <Helmet>
            <title>Sea/calc - {workbook.name}</title>
          </Helmet>

          <section className="no-document-placeholder__user-message">
            <header data-testid={testId && `${testId}__header`}>
              This workbook: {workbook.name} is empty
            </header>
            <p data-testid={testId && `${testId}__p`}>
              Please select "VIEW WORKBOOKS" to select another workbook{' '}
              {workbook.isEditable
                ? 'or select + to add a blank worksheet to the current workbook'
                : ''}
            </p>
            <footer data-testid={testId && `${testId}__footer`}>
              <ViewWorkbooksButton diagnosticId="workbook-empty-placeholder/view-workbooks-button" />
            </footer>
          </section>
        </>
      ) : null}
      <StageRightPortalContent>
        <CenteredContent />
      </StageRightPortalContent>
    </div>
  );
}
