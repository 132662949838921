import WORKBOOK_STATE from '../../workbook-states';

const { ERROR_CREATING, ERROR_RENAMING } = WORKBOOK_STATE;

const ErrorMessages = {
  [ERROR_CREATING]: 'There was an error creating your workbook.',
  [ERROR_RENAMING]: 'There was an error renaming your workbook.',
};

export default ErrorMessages;
