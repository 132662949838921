export const SPEEDCONS_FUELGRADE_CHANGED: string = 'SPEEDCONS_FUELGRADE_CHANGED';
export const SPEEDCONS_MAIN_FUELGRADE_CHANGED: string = 'SPEEDCONS_MAIN_FUELGRADE_CHANGED';
export const SPEEDCONS_ZONESPECIFIC_SECA_FUELGRADE_CHANGED: string =
  'SPEEDCONS_ZONESPECIFIC_SECA_FUELGRADE_CHANGED';
export const SPEEDCONS_ZONESPECIFIC_SLUGDISCHARGE_FUELGRADE_CHANGED: string =
  'SPEEDCONS_ZONESPECIFIC_SLUGDISCHARGE_FUELGRADE_CHANGED';
export const SPEEDCONS_ZONESPECIFIC_SECA_AND_SLUGDISCHARGE_FUELGRADE_CHANGED: string =
  'SPEEDCONS_ZONESPECIFIC_SECA_AND_SLUGDISCHARGE_FUELGRADE_CHANGED';

export const SPEEDCONS_SPEED_CHANGED: string = 'SPEEDCONS_SPEED_CHANGED';
export const SPEEDCONS_CONSUMPTION_CHANGED: string = 'SPEEDCONS_CONSUMPTION_CHANGED';
export const SPEEDCONS_APPLY_NEW_DETAILS: string = 'SPEEDCONS_APPLY_NEW_DETAILS';
export const SPEEDCONS_RESET = 'SPEEDCONS_RESET';
export const SPEEDCONS_ACTIVE_CHANGED: string = 'SPEEDCONS_ACTIVE_CHANGED';
export const SPEEDCONS_SCRUBBER_CONSUMPTION_CHANGED: string =
  'SPEEDCONS_SCRUBBER_CONSUMPTION_CHANGED';

export const SHOW_SPEED_AND_CONS_EDIT_MODAL: string = 'SHOW_SPEED_AND_CONS_EDIT_MODAL';
export const GENERATOR_CHANGED_IN_EDIT_SPEED_AND_CONS_CHANGED: string = 'GENERATOR_CHANGED_IN_EDIT_SPEED_AND_CONS_CHANGED';
