import React from 'react';

import {
  LoadPortSymbol,
  DischargePortSymbol,
  ViaPortSymbol,
  BunkerPortSymbol,
  VesselOpenLocationSymbol,
} from '../map-symbol';

import './styles.scss';

// cache these elements as we're never going to change them
const loadPortSymbol = <LoadPortSymbol />;
const dischargePortSymbol = <DischargePortSymbol />;
const bunkerPortSymbol = <BunkerPortSymbol />;
const viaPortSymbol = <ViaPortSymbol />;
const vesselOpenLocationSymbol = <VesselOpenLocationSymbol />;

const MapLegend = () => {
  return (
    <div className="map-legend">
      <div>Map Key:</div>
      <Legend symbol={loadPortSymbol} label="Load port" />
      <Legend symbol={dischargePortSymbol} label="Discharge port" />
      <Legend symbol={bunkerPortSymbol} label="Bunker port" />
      <Legend symbol={viaPortSymbol} label="Via port" />
      <Legend symbol={vesselOpenLocationSymbol} label="Open location" />
    </div>
  );
};

const Legend = (props) => {
  const { symbol, label } = props;

  return (
    <div className="legend">
      {symbol}
      <div className="legend__label">{label}</div>
    </div>
  );
};

export default MapLegend;
