import React, { useCallback } from 'react';
import {
  isOpenLoopOrUnknownScrubberFitted,
  isScrubberFitted,
  ScrubberTypesEnum,
} from 'constants/enums/scrubber';
import { ALL_FUEL_GRADES_IN_UI_ORDER } from 'constants/enums/fuel-grades';
import { DropDownWithLabel } from 'components/dropdown-with-label';
import { Subheader } from 'components/headers';
import NumericInput from 'components/numeric-input';

import './styles.scss';

export interface IEmissionControlProps {
  worksheetId: WorksheetId;
  vessel: IVesselViewModel;
  scrubberTypeUpdated: ScrubberTypeUpdatedAction;
  scrubberFuelConsumptionChanged: ScrubberFuelConsumptionChanged;
  zoneSpecificSecaFuelGradeChanged: FuelGradeUpdatedAction;
  zoneSpecificBannedSlugDischargeFuelGradeChanged: FuelGradeUpdatedAction;
  zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged: FuelGradeUpdatedAction;
}

export function EmissionControl({
  worksheetId,
  vessel,
  scrubberTypeUpdated,
  scrubberFuelConsumptionChanged,
  zoneSpecificSecaFuelGradeChanged,
  zoneSpecificBannedSlugDischargeFuelGradeChanged,
  zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged,
}: IEmissionControlProps) {
  const onScrubberConsumptionChanged = useCallback(
    (value) => {
      scrubberFuelConsumptionChanged(value, worksheetId, vessel.entryId);
    },
    [scrubberFuelConsumptionChanged, worksheetId, vessel.entryId]
  );

  const onZoneSpecificSecaFuelGradeChanged = useCallback(
    (newItem) => {
      zoneSpecificSecaFuelGradeChanged(newItem.key, worksheetId, vessel.entryId);
    },
    [zoneSpecificSecaFuelGradeChanged, worksheetId, vessel.entryId]
  );

  const onZoneSpecificBannedSlugDischargeFuelGradeChanged = useCallback(
    (newItem) => {
      zoneSpecificBannedSlugDischargeFuelGradeChanged(newItem.key, worksheetId, vessel.entryId);
    },
    [zoneSpecificBannedSlugDischargeFuelGradeChanged, worksheetId, vessel.entryId]
  );

  const onZoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged = useCallback(
    (newItem) => {
      zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged(
        newItem.key,
        worksheetId,
        vessel.entryId
      );
    },
    [zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged, worksheetId, vessel.entryId]
  );

  const onScrubberChanged = React.useCallback(
    (newType) => {
      scrubberTypeUpdated(newType.id, worksheetId, vessel.entryId);
    },
    [scrubberTypeUpdated, worksheetId, vessel.entryId]
  );

  return (
    <div className="imo-2020-multi-vessel">
      <Subheader text="IMO 2020" className="margin-bottom-small" />
      <div className="imo-2020-multi-vessel__section">
        <DropDownWithLabel
          className="imo-2020-multi-vessel__section-field imo-2020-multi-vessel__section-field--scrubber"
          onChange={onScrubberChanged}
          label={
            <span className="scrubber__label" role="heading">
              {`Scrubber${vessel.scrubber.pendingTypeId != null ? ' (Pending)' : ''}`}
            </span>
          }
          role="combobox"
          items={Object.values(ScrubberTypesEnum).map((scrubberType) => ({
            ...scrubberType,
            key: scrubberType.id,
          }))}
          selectedItem={Object.values(ScrubberTypesEnum).find(
            (_) => _.id === vessel.scrubber.typeId
          )}
          diagnosticId="EmissionControl/ChangeScrubber"
          data-testid="scrubber-dropdown"
        >
          <span className="scrubber__default_scrubber_type_label" role="contentinfo">
            {vessel.scrubber.typeId === ScrubberTypesEnum.UNKNOWN_TYPE.id
              ? 'Defaults to Open Loop'
              : null}
          </span>
        </DropDownWithLabel>
      </div>
      <div className="imo-2020-multi-vessel__section margin-top-small-medium">
        <DropDownWithLabel
          className="imo-2020-multi-vessel__section-field imo-2020-multi-vessel__section-field--seca"
          /* NOTE: Despite the name, this zone is not always referring to the leg distances labelled 'SECA'. It is only so when no other restrictions (like open-loop ban) apply to the vessel. E.g. if the open-loop ban applies to the vessel, this field refers to "SECA and NOT open-loop ban"
               TODO consider hiding this behind a facade or refactor names to be less ambigious */
          label="SECA"
          items={ALL_FUEL_GRADES_IN_UI_ORDER}
          selectedItemKey={vessel.speedAndConsumptions.zoneSpecific.seca.fuelGradeId}
          onChange={onZoneSpecificSecaFuelGradeChanged}
          diagnosticId="EmissionControl/ChangeZoneSpecificSecaFuel"
        />
        {isScrubberFitted(vessel.scrubber.typeId) ? (
          <div className="imo-2020-multi-vessel__section-field imo-2020-multi-vessel__section-field--scrubber-consumption">
            <label id="scrubber-fuel-consumption-label" htmlFor="scrubber-fuel-consumption">
              Extra Sea Con (MT/d)
            </label>
            <NumericInput
              id="scrubber-fuel-consumption"
              value={vessel.speedAndConsumptions.scrubberConsumption}
              onInputChange={onScrubberConsumptionChanged}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="10"
              diagnosticId="EmissionControl/ChangeScrubberConsumption"
            />
          </div>
        ) : null}
      </div>
      {isOpenLoopOrUnknownScrubberFitted(vessel.scrubber.typeId) ? (
        <div className="imo-2020-multi-vessel__section imo-2020-multi-vessel__scrubber-ban margin-top-small-medium">
          <label className="imo-2020-multi-vessel__scrubber-ban-header">Scrubber Ban Areas</label>
          <div className="imo-2020-multi-vessel__section margin-top-medium">
            <DropDownWithLabel
              className="imo-2020-multi-vessel__section-field"
              label="Global cap 0.5"
              items={ALL_FUEL_GRADES_IN_UI_ORDER}
              selectedItemKey={
                vessel.speedAndConsumptions.zoneSpecific.sludgeDischargeBan.fuelGradeId
              }
              onChange={onZoneSpecificBannedSlugDischargeFuelGradeChanged}
              diagnosticId="EmissionControl/ChangeZoneSpecificBannedSlugDischargeFuelGrade"
            />
            <DropDownWithLabel
              className="imo-2020-multi-vessel__section-field"
              label="SECA ban"
              items={ALL_FUEL_GRADES_IN_UI_ORDER}
              selectedItemKey={
                vessel.speedAndConsumptions.zoneSpecific.secaAndSludgeDischargeBan.fuelGradeId
              }
              onChange={onZoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged}
              diagnosticId="EmissionControl/ChangeZoneSpecificSecaAndBannedSlugDischargeFuelGrade"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
