import React, { Fragment, Component } from 'react';
import NumericInput from 'components/numeric-input';
import { StowageAndSpecificGravityInput } from 'components/cargo-stowage-and-specific-gravity-input';
import CargoAutoComplete from 'components/cargo-autocomplete';
import { CUBIC_METERS_PER_METRIC_TON, convertStowage } from 'constants/enums/stowage-factor-units';
import './cargo.scss';

export const grossVoyageRateMaxPrecision: number = 2;

export class Cargo extends Component {
  onCargoTypeUpdated = (cargo) => {
    const stowage =
      this.props.stowageUnit === CUBIC_METERS_PER_METRIC_TON
        ? cargo.stowageMeters
        : cargo.stowageFeet;

    this.props.onCargoTypeUpdated(
      {
        cargoTypeId: cargo.cargoTypeId,
        name: cargo.name,
        stowage: stowage,
        stowageUnit: this.props.stowageUnit,
      },
      this.props.code
    );
  };

  onStowageUpdated = (stowage) => {
    this.props.onStowageUpdated(stowage, this.props.code);
  };

  onSpecificGravityUpdated = (specificGravity) => {
    this.props.onSpecificGravityUpdated(specificGravity, this.props.code);
  };

  onStowageUnitUpdated = (stowageUnit) => {
    const stowage = convertStowage(this.props.stowage, this.props.stowageUnit, stowageUnit);

    this.props.onStowageUnitUpdated({ stowage, stowageUnit }, this.props.code);
  };

  onGrossVoyageRateUpdated = (event) => {
    this.props.onGrossVoyageRateUpdated(event, this.props.code);
  };

  onBrokerCommissionVoyageRateUpdated = (event) => {
    this.props.onBrokerCommissionVoyageRateUpdated(event, this.props.code);
  };

  onAddressCommissionVoyageRateUpdated = (event) => {
    this.props.onAddressCommissionVoyageRateUpdated(event, this.props.code);
  };

  render() {
    const stowageAndSpecificGravityProps = {
      marketSegmentId: this.props.marketSegmentId,
      stowageUnit: this.props.stowageUnit,
      stowage: this.props.stowage,
      specificGravity: this.props.specificGravity,
      onStowageUpdated: this.onStowageUpdated,
      onStowageUnitUpdated: this.onStowageUnitUpdated,
      onSpecificGravityUpdated: this.onSpecificGravityUpdated,
      shouldAutoCalculateIntake: this.props.shouldAutoCalculateIntake,
    };

    return (
      <Fragment>
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Cargo Type</label>
          <CargoAutoComplete
            cargo={{
              cargoTypeId: this.props.cargoTypeId,
              name: this.props.name,
            }}
            onChange={this.onCargoTypeUpdated}
            diagnosticId="Cargo/ChangeCargoType"
          />
        </div>
        <div className="worksheet-section__property">
          <StowageAndSpecificGravityInput {...stowageAndSpecificGravityProps} />
        </div>
        {/* todo: move these into a reusable component */}
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Gross Voyage Rate ($/MT)</label>
          <NumericInput
            value={this.props.cargoRate?.grossVoyageRate}
            diagnosticId="Cargo/GVR"
            className="worksheet-section__property__large-input"
            onInputChange={this.onGrossVoyageRateUpdated}
            maxDecimalDigits={grossVoyageRateMaxPrecision}
            minValue="0"
            maxValue="999.99"
            mandatory
          />
        </div>
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Broker (%)</label>
          <NumericInput
            value={this.props.brokerCommissionVoyageRate}
            diagnosticId="Cargo/Broker(%)"
            className="worksheet-section__property__very-small-input"
            onInputChange={this.onBrokerCommissionVoyageRateUpdated}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="99.99"
          />
        </div>
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Address (%)</label>
          <NumericInput
            value={this.props.addressCommissionVoyageRate}
            diagnosticId="Cargo/Address(%)"
            className="worksheet-section__property__very-small-input"
            onInputChange={this.onAddressCommissionVoyageRateUpdated}
            maxDecimalDigits="2"
            minValue="0"
            maxValue="99.99"
          />
        </div>
      </Fragment>
    );
  }
}

export default Cargo;
