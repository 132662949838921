import { formatNumberStrict, round } from 'utilities/number';

export const moneyNoOfDecimalPlaces = 2;

export function formatMoneyOrNullAsEmpty(value: number | null) {
  return value === null ? '' : formatMoney(value);
}

export function formatMoney(value: number) {
  return formatNumberStrict(value, {
    noOfDecimalPlaces: moneyNoOfDecimalPlaces,
  });
}

export function roundMoney(value) {
  return round(value, /* noOfDecimalPlaces: */ moneyNoOfDecimalPlaces);
}
