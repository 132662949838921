import { createSelector } from 'reselect';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';
import { formatNumberWithNullAndUndefinedAsEmpty } from 'utilities/number';

export const selector = createSelector(
  (state) => getActiveCalculationResult(state),
  (calculationResult) => ({
    income: mapFreightDetails(calculationResult.voyageRate),
  })
);

function mapFreightDetails(voyageRate) {
  return {
    freightIncomeGross: voyageRate.totalIncomeGross
      ? incomeFormat(voyageRate.freightIncomeGross)
      : '',
    additionalIncomeGross: voyageRate.totalIncomeGross
      ? incomeFormat(voyageRate.additionalIncomeGross)
      : '',
    totalIncomeGross: voyageRate.totalIncomeGross ? incomeFormat(voyageRate.totalIncomeGross) : '',
    totalIncomeNet: voyageRate.totalIncomeNet ? incomeFormat(voyageRate.totalIncomeNet) : '',
  };
}

function incomeFormat(value) {
  if (!value) {
    value = 0;
  }
  return formatNumberWithNullAndUndefinedAsEmpty(value);
}
