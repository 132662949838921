import groupBy from 'lodash/groupBy';
import { getKnownWaypointOrNull } from 'constants/data/waypoints';

export type WaypointsWithOccurrencesInLegs = {
  locationId: LocationId,
  waypointData: IReferenceDataWaypoint,
  occurrencesInLegs: Array<IVoyageLegViewModel>,
};

export function getAllUserSignificantWaypointsWithOccurrencesInLegs(
  legs: Iterable<IVoyageLegViewModel>
): Array<WaypointsWithOccurrencesInLegs> {
  const allWaypointsOccurrencesInLegsByWaypointLocationId = groupBy(
    legs.flatMap((_) => _.inboundRoute.variants.flatMap((wp) => wp.waypoints || [])),
    (_) => _.locationId
  );
  return Object.entries(allWaypointsOccurrencesInLegsByWaypointLocationId)
    .map(([locationId, occurrencesInLegs]) => ({
      locationId: locationId,
      waypointData: getKnownWaypointOrNull(locationId),
      occurrencesInLegs: occurrencesInLegs,
    }))
    .filter((grouping) => !!grouping.waypointData);
}

/**
 * Waypoints which should be requested to be excluded in the route calculation request for edits that introduce new legs (any edit that introduces a new [from+to] destinations pair into the worksheet, e.g. destination add/change/removal/reordering, but *not* those edits that just recalculate on the same leg. The distinction is significant in the case of #WaypointsAvoidedInOnlySomeLegs).
 */
export function getWaypointsToExcludeForNewRoutes(legs: Iterable<IVoyageLegViewModel>) {
  return getAllUserSignificantWaypointsWithOccurrencesInLegs(legs)
    .filter((waypoint) =>
      waypoint.occurrencesInLegs.every(
        /* The choice of `every` here is only significant in the case of #WaypointsAvoidedInOnlySomeLegs. Choosing `every` is the safer behavior for new routes, in that if a waypoint is only avoided in some waypoints, it is the safer choice for any edits introducing new leg (if we tried to avoid the waypoint, it could cause #UnsucccessfulDistanceCalc_DueTo_WaypointsAvoidedInOnlySomeLegs . We want to reduce the incidence of it.). */
        (_) => _.avoid
      )
    )
    .map((_) => ({
      locationId: _.locationId,
    }));
}
