import React, { Component, Fragment } from 'react';
import ExpandCollapseButton from './expand-collapse-button';
import classNames from 'classnames';

export default class ExpandCollapse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  onExpandCollapseHandler = () => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
    if (this.props.onClick) {
      this.props.onClick(expanded);
    }
  };

  static getDerivedStateFromProps(props, state) {
    // Had to implement a forceCollapse when list was empty, this was the only way I could do a collapse from parent without moving the `this.state.expanded` to the parent component. #101648
    if (props.forceCollapse && state.expanded) {
      return {
        expanded: false,
      };
    }
    return null;
  }

  render() {
    return (
      <Fragment>
        <div className="expand-collapse-main">
          <ExpandCollapseButton
            className={classNames('expand-collapse', this.props.className)}
            onClick={this.onExpandCollapseHandler}
            expanded={this.state.expanded}
          >
            <div className="expand-collapse__header">{this.props.header}</div>
          </ExpandCollapseButton>
          {this.props.additionalExpandHeaderSection && (
            <div className="expand-collapse-additional-header-section">
              {this.props.additionalExpandHeaderSection}
            </div>
          )}
        </div>
        {this.state.expanded && this.props.children}
      </Fragment>
    );
  }
}
