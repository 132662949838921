import React from 'react';

import {
  LoadIcon,
  DischargeIcon,
  InNegotiationIcon,
  FuelStopIcon,
  ShipIcon,
} from 'components/icons';

import './styles.scss';

export const LoadPortSymbol = () => (
  <MapSymbol icon={<LoadIcon />} classNames="map-symbol--load-port" />
);

export const DischargePortSymbol = () => (
  <MapSymbol icon={<DischargeIcon />} classNames="map-symbol--discharge-port" />
);

export const ViaPortSymbol = () => (
  <MapSymbol icon={<InNegotiationIcon />} classNames="map-symbol--via-port" />
);

export const BunkerPortSymbol = () => (
  <MapSymbol icon={<FuelStopIcon />} classNames="map-symbol--bunker-port" />
);

export const VesselOpenLocationSymbol = () => (
  <MapSymbol icon={<ShipIcon />} classNames="map-symbol--open-location" />
);

const MapSymbol = ({ icon, classNames }) => (
  <div className={`map-symbol ${classNames}`}>{icon}</div>
);
