import axios from 'axios';
import debounce from 'lodash/debounce';
import some from 'lodash/some';
import { debounceDefaults } from 'constants/defaults/debounce';
import * as generatorFnUtils from 'utilities/iterable/generator-fn-utils';
import { buildAction } from 'utilities/redux';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import worksheetActions from '../calculate-estimate/actions-to-trigger-calculate';
import sensitivityActions from './actions-to-trigger-calculate';
import {
  SENSITIVITY_UPDATE_DATA,
  SENSITIVITY_CLEAR_DATA,
} from 'constants/action-types/sensitivity-table';
import { TC_TO_FREIGHT } from 'modules/sensitivity-table';
import type { WorksheetSnapshotSyncAfterware } from 'middleware/worksheet-afterware/types';
import {
  speedAndConsumptionsModes,
  getRateLimitedCalculationSensitivityResult,
} from 'api/clients/calculation';
import { getActiveVesselFromWorksheet } from '../../../common-selectors/get-active-vessel';
import { getActiveVesselEntryIdFromWorksheet } from '../../../common-selectors/get-active-vessel-entry-id';
import { mapToCharterTypes } from '../../../constants/enums/comparison-view-mode';
import VError from 'verror';

let cancelTokenSource: CancelTokenSource = null;

const clearSensitivityCalculation = () => (dispatch) =>
  dispatch(buildAction(SENSITIVITY_CLEAR_DATA));

const addSensitivityCalculationResultAsync =
  (
    worksheet,
    vessel,
    speedAndConsumptionsMode,
    charterTypes,
    waypointsReferenceData,
    request,
    isDefault,
    carbonFactors,
    cheapestSpeedAndConsumptions
  ) =>
  async (dispatch) => {
    try {
      let calculationResult = await getRateLimitedCalculationSensitivityResult(
        request,
        cancelTokenSource.token
      );

      dispatch(
        buildAction(SENSITIVITY_UPDATE_DATA, {
          calculationResult,
          isDefault,
        })
      );
    } catch (err) {
      if (axios.isCancel(err)) {
        trackEvent(
          'Middleware/SensitivityAnalysis',
          'Middleware Sensitivity Analysis Cancelled',
          {},
          {},
          eventDestination.ANALYSIS
        );
        return;
      }

      throw new VError(
        { cause: err },
        'Sensitivity analysis calculation failed due to unknown error. See `cause`.'
      );
    }
  };

const updateWorksheetFuelGradeForCalculation = (worksheet, fuelGradeToUpdate, fuelSensitivity) => {
  worksheet.bunkerExpenses = {
    ...worksheet.bunkerExpenses,
    bunkers: worksheet.bunkerExpenses.bunkers.map((bunker) => ({
      ...bunker,
      fuelGrades: bunker.fuelGrades.map((fuelGrade) => ({
        ...fuelGrade,
        price:
          fuelGrade.key === fuelGradeToUpdate.key
            ? fuelGrade.price + fuelSensitivity
            : fuelGrade.price,
      })),
    })),
  };

  return worksheet;
};

const calculateSensitivityImmediately = async (
  worksheet: IWorksheetViewModel,
  vessel,
  globalState,
  dispatch
) => {
  if (cancelTokenSource != null) {
    cancelTokenSource.cancel('Cancelled!');
  }
  cancelTokenSource = axios.CancelToken.source();

  await dispatch(clearSensitivityCalculation());

  const waypointsReferenceData = globalState.referenceData.waypoints;

  const speedAndConsumptionsMode =
    globalState.workbooksById[worksheet.workbookId].speedAndConsumptionsMode;

  let cheapestSpeedAndConsumptions;

  const allPromises = generatorFnUtils.arrayFromGeneratorFn(function* () {
    const {
      activeSensitivities,
      grossTimeCharterRateSensitivity,
      grossFreightRateSensitivity,
      firstFuelGrade,
      firstFuelSensitivity,
      secondFuelGrade,
      secondFuelSensitivity,
    } = globalState.calculationSensitivity;

    const workbook = globalState.workbooksById[worksheet.workbookId];

    // #TODO_USE_DEEP_COPY_FOR_SENSITIVITY_CALC refactor this to do deep copy or allow calculation receive an extra sensitivity parameter instead
    // this is too risky if the properties are nested, we could end up mutating internal state.
    // see #TODO_SENSITIVITY_MUTATION
    const vesselHigher = { ...vessel };
    const charterTypes = mapToCharterTypes(globalState.worksheetComparison.viewMode.key);

    const sensitivityType = some(activeSensitivities, TC_TO_FREIGHT) ? 1 : 2;
    var calculateSensitivityRequest = {
      worksheetId: worksheet.id,
      vesselEntryId: vessel.entryId,
      calculationType: sensitivityType,
      sensitivity:
        sensitivityType === 1 ? grossTimeCharterRateSensitivity : grossFreightRateSensitivity,
      firstFuelGradeToUpdate: firstFuelGrade.key,
      firstFuelSensitivity: firstFuelSensitivity,
      secondFuelGradeToUpdate: secondFuelGrade.key,
      secondFuelSensitivity: secondFuelSensitivity,
      findCheapest: speedAndConsumptionsMode === speedAndConsumptionsModes.findCheapest,
      isEditable: workbook.isEditable,
      worksheet: worksheet,
    };

    const carbonFactors = globalState.carbonFactors.map((carbonFactor) => ({
      fuelGrade: carbonFactor.fuelGrade,
      factor: carbonFactor.factor,
    }));

    yield dispatch(
      addSensitivityCalculationResultAsync(
        worksheet,
        vesselHigher,
        speedAndConsumptionsMode,
        charterTypes,
        waypointsReferenceData,
        calculateSensitivityRequest,
        true,
        carbonFactors,
        cheapestSpeedAndConsumptions
      )
    );
  });

  await Promise.all(allPromises);
};

const calculateSensitivityDebounced = debounce(
  calculateSensitivityImmediately,
  debounceDefaults.wait,
  {
    leading: debounceDefaults.leading,
    maxWait: debounceDefaults.maxWait,
  }
);

const calculateSensitivity = (worksheet, vessel, globalState) => async (dispatch) => {
  trackEvent(
    'SensitivityAnalysis',
    'Sensitivity Analysis Calculate',
    {},
    {},
    eventDestination.ANALYSIS
  );

  return await calculateSensitivityDebounced(worksheet, vessel, globalState, dispatch);
};

const autoCalculateSensitivityAfterware: WorksheetSnapshotSyncAfterware = {
  isSyncRequired(newState: IAppState, action): boolean {
    return (
      newState.calculationSensitivity.isActive &&
      (sensitivityActions.includes(action.type) ||
        (worksheetActions.includes(action.type) &&
          action.worksheetId === newState.activeWorksheetId))
    );
  },
  getWorksheetIdForRequiredSync(newState, action) {
    return newState.activeWorksheetId;
  },
  sync: (newWorksheetState: IWorksheetViewModel, newGlobalState) => async (dispatch) => {
    /* Cannot use `getActiveVessel`, because it works on globalState, while ours doesn't contain the latest worksheet - only `newWorksheetState` does (see #AfterwaresPreventionOfDataLoss_ThroughWrongActiveWorksheetOverwrite_Implementation) */
    const activeVessel = getActiveVesselFromWorksheet(
      getActiveVesselEntryIdFromWorksheet(
        newGlobalState.activeVesselEntryId,
        // TODO when cleaning up FEATURE_MULTI_VESSEL, replace the whole `getActiveVesselEntryIdFromWorksheet`, and just go directly via `getActiveVesselEntryId(state)` (the `getActiveVesselEntryIdFromWorksheet` won't need to be exported and can just be inlined)
        newWorksheetState
      ),
      newWorksheetState
    );
    if (activeVessel === null) return;
    await dispatch(calculateSensitivity(newWorksheetState, activeVessel, newGlobalState));
  },
};

export default autoCalculateSensitivityAfterware;
