import forIn from 'lodash/forIn';
import unionBy from 'lodash/unionBy';
import isNil from 'lodash/isNil';
import numbro from 'numbro';

import guid from 'utilities/guid';
import { createSelector } from 'reselect';
import { CargoStatus, BALLAST_AND_LADEN } from 'constants/enums/cargo-status';
import { isCanalPhase } from 'constants/enums/phase-type';

export const selector = createSelector(
  (state) => state.activeWorksheetId,
  (state) => state.worksheetsById[state.activeWorksheetId].voyage.legs,
  (state) => state.worksheetsById[state.activeWorksheetId].canals,
  (state) => state.referenceData.waypoints.canals,
  (state) => state.calculationsByWorksheetId[state.activeWorksheetId].calculationResults,
  (
    worksheetId,
    legs,
    canals,
    waypointsCanals,
    calculationResults: Array<ICalculationViewModel>
  ) => {
    return {
      worksheetId,
      canals: getCanals(
        legs,
        canals,
        calculationResults.flatMap((_) => _.phases)
      ),
      canalCostData: getCanalCostData(waypointsCanals),
    };
  }
);

/**
 * Given a list of crossed canals and the phases of the voyage, the function will update
 * the cargoStatus depending on whether the canal was crossed laden or ballast.
 *
 * @remark this function mutates the original array and doesn't create a copy.
 * @param {*} crossedCanals
 * @param {*} phases
 * @returns updated crossed canals
 */
const updateCanalCargoStatus = (crossedCanals, phases) => {
  if (!phases || (phases && phases.some((phase) => !phase))) {
    return crossedCanals;
  }
  for (const canal of crossedCanals) {
    canal.cargoStatus =
      phases
        .filter((phase) => isCanalPhase(phase.phaseType) && phase.canalId === canal.canalId)
        .reduce((prev, curr) => {
          return prev | (curr.isLaden > 0 ? CargoStatus.LADEN : CargoStatus.BALLAST);
        }, 0) || BALLAST_AND_LADEN;
  }
  return crossedCanals;
};

const getCrossedCanals = (legs, canals) => {
  let crossedCanals = [];

  legs
    .flatMap((_) => _.inboundRoute.variants)
    .forEach((inboundRouteVariant) => {
      crossedCanals = unionBy(
        crossedCanals,
        inboundRouteVariant.waypoints
          .filter(
            (waypoint) =>
              Object.keys(canals).some((id) => canals[id].canalId === waypoint.locationId) &&
              !waypoint.avoid
          )
          .map((waypoint) => {
            return {
              canalId: waypoint.locationId,
            };
          }),
        (waypoint) => waypoint.canalId
      );
    });

  return crossedCanals;
};

const getCanalCosts = (canals, crossedCanals) => {
  const canalCosts = [];

  crossedCanals.forEach(({ canalId, cargoStatus }) => {
    const id = Object.keys(canals).find((id) => canals[id].canalId === canalId);
    canalCosts.push({
      id,
      cargoStatus,
      ...canals[id],
    });
  });

  return canalCosts;
};

export const getCanals = (legs, canals, phases) => {
  let crossedCanals = getCrossedCanals(legs, canals);
  crossedCanals = updateCanalCargoStatus(crossedCanals, phases);
  const canalCosts = getCanalCosts(canals, crossedCanals);

  return canalCosts;
};

const formatNumber = (input: number): string =>
  isNil(input)
    ? ''
    : numbro(input).format({
        thousandSeparated: true,
      });

const formatCost = (input: number): string =>
  isNil(input)
    ? ''
    : numbro(input).format({
        thousandSeparated: true,
        mantissa: 2,
      });

export const getCanalCostData = (canals) => {
  const canalCostData = {};

  forIn(
    canals,
    (canal, canalId) =>
      (canalCostData[canalId] = canal.costData.map((cost) => ({
        id: guid(),
        ...cost,
        vesselDwt: formatNumber(cost.vesselDwt),
        cargoQuantity: formatNumber(cost.cargoQuantity),
        costBallast: formatCost(cost.costBallast),
        costLaden: formatCost(cost.costLaden),
      })))
  );

  return canalCostData;
};
