import { rateLimited } from 'utilities/functions';
import sum from 'lodash/sum';
import isNil from 'lodash/isNil';
import * as numberUtilities from 'utilities/number';
import { Geolocation } from 'utilities/location';
import {
  getSubtotalsByOperationalRestrictionIds,
  getRouteAsSequenceOfDistancesThroughOperationalRestrictions,
} from './routing-voyage-zones-intersections-calculation';
import { operationalRestrictionIds } from 'constants/operational-restrictions';
import type { UnsuccessfulResult } from 'utilities/functions/try-functions/non-void-try-function';
import { getRoute } from './get-route';
import { emptyGuid } from 'utilities/guid';
import { compareClientAndServerOperationalRestrictionCalculations } from './proxy-operational-restrictions-calc-from-route-to-backend-diagnostics';
import { shouldCompareClientServerRouteResponse } from 'diagnostics/calc-debugging';
import { toDate } from 'date-fns';

export const RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable =
  'RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable';

/// Voyage Entry, which is also called 'leg' in this app (member of `worksheet.voyage.legs`)
export type VoyageEntryIdentity = {
  id: VoyageEntryId,
};

/**
 * Note: instances of this type may not be fully populated. For a type indicating instances valid for calculation, see `VoyageEntryWithSailing`.
 */
export type LocationInfoAtVoyageEntry = VoyageEntryIdentity & {
  /// A leg in the data model only contains the 'location to' - it doesn't contain the 'location from' - it sits in the previous voyage entry
  toLocation: Geolocation | null,
};

export type GeoPath = Array<[Number, Number]>;

export type RouteCalcResultWaypoint = IWaypointIdentity & {|
  intersections: IVoyageIntersectionThroughArea[],
|};

/**
 * As opposed to the one in `VoyageEntryWithSailing`, this variant of 'leg info' can be invalid (have parts not filled in yet).
 */
export type VoyageEntryLocationsInfo = {
  fromLocation: Geolocation | null,
  legWithToLocation: LocationInfoAtVoyageEntry,
};

/**
 * 'Voyage entry with sailing' distinguishes itself from other voyage entries in that these are determined to require sailing, and is thus 'valid' for route calculation (has all the necessary fields filled in).
 * Notably, some entries may have no sailing just temporarily, e.g. the user has not filled them in yet, but some may have no sailing permanently, e.g. they have the same location as the previous entry (see `isValidSailingGeolocationsPair`).
 */
export type VoyageEntryWithSailing =
  VoyageEntryLocationsInfo & /* This `&` is a type intersection. It's to make the below members mandatory (they exist in `LegLocationsInfo` but are optional).*/ {
    fromLocation: Geolocation,
    legWithToLocation: {
      toLocation: Geolocation,
      /* yes, the interesction works with nested members too - #IntersectionAlsoIntersectsNestedProperties */
    },
  };

export type RouteCalculationSuccessfulResult = {
  totalDistance: number,
  secaDistance: number,
  path: GeoPath,
  waypointsOfSignificance: Array<RouteCalcResultWaypoint>,
  subtotalsByOperationalRestrictionIds: IOperationalRestrictionsDistanceSpan[],
  routeRetrievedFromRoutingApiOn: Date,
  routingApiGraphVersion: number,
};

type RouteCalculationUnsuccessfulResult =
  UnsuccessfulResult<RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable /* can list more reasons here after '|' */>;

let maxConcurrency = 5;

export const concurrencySettings = {
  getRouteFromTo: {
    get maxConcurrency() {
      return maxConcurrency;
    },
    set maxConcurrency(newValue) {
      getRouteFromToRateLimitedAdjustable = rateLimited(
        /* maxConcurrency: */ maxConcurrency,
        getRouteFromToImmediately
      );
      maxConcurrency = newValue;
    },
  },
};

let getRouteFromToRateLimitedAdjustable = rateLimited(
  /* maxConcurrency: */ maxConcurrency,
  getRouteFromToImmediately
);

export const getRouteFromTo = function () {
  return getRouteFromToRateLimitedAdjustable.apply(this, arguments);
};

async function getRouteFromToImmediately(
  fromLocation: Geolocation,
  toLocation: Geolocation,
  waypointsToExcludeIds: LocationId[],
  avoidSecaZones: boolean,
  marketSegmentId: string,
  cancelToken?: CancelToken
): RouteCalculationSuccessfulResult | RouteCalculationUnsuccessfulResult {
  const apiResult = await getRoute(
    fromLocation,
    toLocation,
    waypointsToExcludeIds,
    avoidSecaZones,
    marketSegmentId,
    cancelToken
  );

  var clientSideRouteResponse = buildClientSideRouteResponse(apiResult, waypointsToExcludeIds);
  var serverSideRouteResponse = formatServerSideRouteResponse(apiResult);

  if (shouldCompareClientServerRouteResponse()) {
    compareClientAndServerOperationalRestrictionCalculations(
      clientSideRouteResponse,
      serverSideRouteResponse
    );
  }

  return serverSideRouteResponse;
}

function buildClientSideRouteResponse(apiResult, waypointsToExcludeIds) {
  const waypointsOfSignificance = apiResult.segments.map((segment) => ({
    locationId: segment.zoneId,
    intersections: segment.pointsOfIntersection,
    // Note that, at the moment of writing this, there is also a `segment.name`, but we don't use it for now, as we have our hardcoded list in `constants/data/waypoints`
  }));

  const unsuccessfulWaypointsToExcludeIds = waypointsOfSignificance
    .filter((routeWaypoint) => waypointsToExcludeIds.includes(routeWaypoint.locationId))
    .map((_) => _.locationId);

  if (unsuccessfulWaypointsToExcludeIds.length > 0) {
    return {
      reasonIsUnsuccessful: RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable,
    };
  } else {
    let subtotalsByOperationalRestrictionIds;
    let routeAsSequenceOfDistancesThroughOperationalRestrictions;
    if (!apiResult.segments || apiResult.segments.length === 0) {
      // no segment information, thus there's no concept of seca we can only use routing's total
      // distance with confidence

      routeAsSequenceOfDistancesThroughOperationalRestrictions = [
        {
          operationalRestrictionIds: new Set(),
          distance: apiResult.totalDistanceNm,
        },
      ];

      subtotalsByOperationalRestrictionIds = [
        {
          operationalRestrictionIds: new Set(),
          distance:
            apiResult.totalDistanceNm ||
            /* TODO We want to obsolete the usage of the integer `totalSurfaceNmDistance` in favor of totalDistanceNm. TODO Remove the below fallback `totalSurfaceNmDistance` once `featureToggles.scrubbersWithOpenLoop.sludgeDischargeBanAreas` has been enabled on production. It will also come with the new float `totalDistanceNm` member. */
            apiResult.totalSurfaceNmDistance,
        },
      ];
    } else {
      // TODO - remove this block once `featureToggles.scrubbersWithOpenLoop.sludgeDischargeBanAreas`
      // has been enabled on production
      if (
        !apiResult.segments[0].pointsOfIntersection ||
        apiResult.segments[0].pointsOfIntersection.length === 0
      ) {
        // no segments or pointsOfIntersection return the routing API's distances
        subtotalsByOperationalRestrictionIds = [
          {
            operationalRestrictionIds: new Set([operationalRestrictionIds.seca]),
            distance: apiResult.totalSecaNmDistance,
          },
          {
            operationalRestrictionIds: new Set(),
            distance: apiResult.totalSurfaceNmDistance - apiResult.totalSecaNmDistance,
          },
        ];

        routeAsSequenceOfDistancesThroughOperationalRestrictions = [
          {
            operationalRestrictionIds: new Set(),
            distance: apiResult.totalSurfaceNmDistance,
          },
        ];
      }
      // TODO - fin
      else {
        subtotalsByOperationalRestrictionIds = getSubtotalsByOperationalRestrictionIds(
          /* routeSegments: */ apiResult.segments,
          /* totalDistanceNm: */ apiResult.totalDistanceNm
        );

        routeAsSequenceOfDistancesThroughOperationalRestrictions =
          getRouteAsSequenceOfDistancesThroughOperationalRestrictions(
            apiResult.segments,
            apiResult.totalDistanceNm
          );
      }
    }

    return {
      totalDistance: numberUtilities.round(
        sum(subtotalsByOperationalRestrictionIds.map((_) => _.distance)),
        /* noOfDecimalPlaces: */ 0
      ),
      routeAsSequenceOfDistancesThroughOperationalRestrictions:
        routeAsSequenceOfDistancesThroughOperationalRestrictions,
      subtotalsByOperationalRestrictionIds: subtotalsByOperationalRestrictionIds,
      path: apiResult.path.coordinates,
      waypointsOfSignificance: waypointsOfSignificance,
      routeRetrievedFromRoutingApiOn: new Date(),
      routingApiGraphVersion:
        apiResult.computedOperationalRestrictionDistances.routingApiGraphVersion,
    };
  }
}

function formatServerSideRouteResponse(
  apiResult
): RouteCalculationSuccessfulResult | RouteCalculationUnsuccessfulResult {
  if (
    apiResult.reasonIsUnsuccessful ===
    RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable
  ) {
    //Deleting properties to mirror dynamic nature of the clientside calculation of the route response
    delete apiResult.totalDistance;
    delete apiResult.routeAsSequenceOfDistancesThroughOperationalRestrictions;
    delete apiResult.subtotalsByOperationalRestrictionIds;
    delete apiResult.path;
    delete apiResult.waypointsOfSignificance;
    delete apiResult.routeRetrievedFromRoutingApiOn;
    delete apiResult.routingApiGraphVersion;

    return apiResult;
  }

  const { computedOperationalRestrictionDistances: serverSideRouteResponse } = apiResult;

  if (isNil(serverSideRouteResponse)) {
    return apiResult;
  }

  const formattedRouteAsSequenceOfDistancesThroughOperationalRestrictions =
    serverSideRouteResponse.routeAsSequenceOfDistancesThroughOperationalRestrictions
      .map((distThrOpRes) => ({
        ...distThrOpRes,
        operationalRestrictionIds: new Set(distThrOpRes.operationalRestrictionIds),
        zones: distThrOpRes.zones.map((z) => {
          if (z.id === emptyGuid) {
            z.id = 'Total Route Segment (artificial)';
            z.zoneTypeId = 'Total Route Segment (artificial)';
          }

          return z;
        }),
        pointAtStart: {
          ...distThrOpRes.pointAtStart,
          zoneBorderIntersectionsAtThisNm:
            distThrOpRes.pointAtStart.zoneBorderIntersectionsAtThisNm.map((zb) => {
              if (zb.routeSegment.zoneId === emptyGuid) {
                zb.routeSegment.zoneId = 'Total Route Segment (artificial)';
                zb.routeSegment.zoneTypeId = 'Total Route Segment (artificial)';
                delete zb.routeSegment.zoneNmDistanceCovered;
              }

              return zb;
            }),
        },
        pointAtEnd: {
          ...distThrOpRes.pointAtEnd,
          zoneBorderIntersectionsAtThisNm:
            distThrOpRes.pointAtEnd.zoneBorderIntersectionsAtThisNm.map((zb) => {
              if (zb.routeSegment.zoneId === emptyGuid) {
                zb.routeSegment.zoneId = 'Total Route Segment (artificial)';
                zb.routeSegment.zoneTypeId = 'Total Route Segment (artificial)';
                delete zb.routeSegment.zoneNmDistanceCovered;
              }

              return zb;
            }),
        },
      }))
      .map((distThrOpRes) => {
        if (!apiResult.segments || apiResult.segments.length === 0) {
          delete distThrOpRes.pointAtStart;
          delete distThrOpRes.pointAtEnd;
          delete distThrOpRes.zones;
        }

        return distThrOpRes;
      });

  const formattedSubtotalsByOperationalRestrictionIds =
    serverSideRouteResponse.subtotalsByOperationalRestrictionIds.map((subtotalByOpRes) => ({
      ...subtotalByOpRes,
      operationalRestrictionIds: new Set(subtotalByOpRes.operationalRestrictionIds),
    }));

  return {
    ...serverSideRouteResponse,
    routeAsSequenceOfDistancesThroughOperationalRestrictions:
      formattedRouteAsSequenceOfDistancesThroughOperationalRestrictions,
    subtotalsByOperationalRestrictionIds: formattedSubtotalsByOperationalRestrictionIds,
    routeRetrievedFromRoutingApiOn: toDate(serverSideRouteResponse.routeRetrievedFromRoutingApiOn),
  };
}
