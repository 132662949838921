import toNumber from 'lodash/toNumber';
import { CancelTokenSource } from 'axios';
import { veApi } from 'api';
import { CUBIC_METERS_PER_METRIC_TON } from 'constants/enums/stowage-factor-units';
import { isFeatureEnabled } from 'config/feature-control';

export type CargoType = {
  cargoTypeId: string,
  name: string,
  stowage: number,
  stowageUnit: StowageUnit,
  coreCargoTypeId: string,
  arcCargoTypeId: string,
};

export type CargoTypeApiDto = {
  id: string,
  name: string,
  aliases: string,
  stowageFactorMeters: number,
  stowageFactorFeet: number,
  parentCargoTypeName: string,
  coreCargoTypeId: string,
  arcCargoTypeId: string,
};

export const getCargoTypeById = async (
  cargoName: string,
  cargoTypeId: string
): Promise<CargoType> => {
  var cargoTypes = await search(cargoName);
  const matchedCargoType = cargoTypes.find((cargo) => cargo.id === cargoTypeId);

  return mapApiResponseToCargoType(matchedCargoType);
};

function mapApiResponseToCargoType(cargoTypeApiDto: CargoTypeApiDto): CargoType {
  return {
    cargoTypeId: cargoTypeApiDto.id,
    name: cargoTypeApiDto.name,
    stowage: toNumber(cargoTypeApiDto.stowageFactorMeters || 0),
    stowageUnit: CUBIC_METERS_PER_METRIC_TON,
  };
}

export async function search(
  searchTerm: string,
  cancelTokenSource: CancelTokenSource | null = null
): Promise<CargoTypeApiDto[]> {
  const url = `/cargotype/search?query=${searchTerm}`;
  if (isFeatureEnabled('useCoreCargoTypes')) {
    var response = await veApi.get(url, {
      cancelToken: cancelTokenSource && cancelTokenSource.token,
      headers: {
        'X-Calc-Use-Core-Cargo-Type': 'true',
      },
    });
    return response.data;
  } else {
    var response = await veApi.get(url, {
      cancelToken: cancelTokenSource && cancelTokenSource.token,
    });
    return response.data;
  }
}
