import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utilities/router-wrapper';
import { Helmet } from 'react-helmet';
import { selector } from './selector';
import { renameWorksheet } from 'actions/worksheet/name';
import { removeWorksheetFromWorkbook } from 'actions/workbook';
import { addWorksheetToWorkbook } from 'actions/workbook-manager';
import * as worksheetClient from 'api/clients/worksheet';
import { downloadDetailedCalculationExport } from '../../actions/calculation-details/download-detailed-calculation-export';
import { EditableLabel } from 'components/editable-label';
import { CopyWorksheetMenuButton } from './components/copy-worksheet-button';
import { ExportButton } from 'components/export-button';
import { DeleteWithConfirmationButton } from 'components/delete-with-confirmation-button';
import { isEmptyGuid } from 'utilities/guid';
import './styles.scss';
import { WorksheetSpeedAndConsumptionsModeLabelTooltip } from './components/worksheet-speed-and-consumptions-mode-label-tooltip';
import { isTanker, isWet } from 'constants/market-segments';
import { getHostNameFromUrlString } from 'utilities/url';
import EmitNotice from 'components/notices-emitter/emit-notice';
import { DISMISS_WORKSHEET_NOTICES_EXCEPT_THIS } from 'constants/enums/emit-notices';
import classNames from 'classnames';
import { LinkButton } from 'components/link-button';
import Divider from 'components/divider';
import { Overlay } from '../overlay';
import { CopyMoveWorksheetModalContainer } from '../copy-move-worksheet-modal';
import { setWorksheetApiVersion } from 'utilities/functions/set-worksheet-api-version';
import { eventDestination, trackEvent } from '../../diagnostics/calc-trackevents';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export class WorksheetInfo extends Component {
  constructor(props) {
    super(props);
    let dismissPreviousWorksheetNotice = false;
    if (
      props &&
      props.router.location &&
      props.router.location.state &&
      props.router.location.state.dismissPreviousWorksheetNotice
    ) {
      dismissPreviousWorksheetNotice = props.router.location.state.dismissPreviousWorksheetNotice;
    }
    this.state = {
      areWorksheetControlsEnabled: true,
      dismissPreviousWorksheetNotice: dismissPreviousWorksheetNotice,
      isOpen: false,
      isVisible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', (e) => {
      if (this.state.isVisible && e.key === 'Escape') this.handleVisible();
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.node) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }
    //Click is outside the menu element, and so close the menu.
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      this.props.setTooltipActive();
    }
  };
  renameWorksheetHandler = (worksheetName) => {
    const { worksheetId, workbookId } = this.props;
    this.props.renameWorksheet(worksheetId, worksheetName, workbookId);
  };

  deleteWorksheet = async () => {
    this.setState({ areWorksheetControlsEnabled: false });

    // TODO: move the code here into an action
    // such that this component doesn't know about
    // the next worksheet Id
    const { worksheetId, workbookId, nextWorksheetId } = this.props;

    this.props.removeWorksheetFromWorkbook(worksheetId, workbookId, nextWorksheetId);

    const redirectPath = isEmptyGuid(nextWorksheetId)
      ? `/workbook/${workbookId}/`
      : `/workbook/${workbookId}/worksheet/${nextWorksheetId}`;

    this.props.router.navigate(redirectPath, {
      state: { dismissPreviousWorksheetNotice: true },
    });
    await worksheetClient.deleteWorksheet({ worksheetId, workbookId });
  };
  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.setTooltipActive();
    if (!this.state.isOpen) {
      trackEvent(
        'WorksheetInfo',
        'Copy / Move Menu Activated',
        {
          sourceWorksheetBelongsToColleague: this.props.isEditable ? false : true,
        },
        {},
        eventDestination.ANALYSIS
      );
    }
  };
  handleVisible = (modalType) => {
    this.setState({
      isVisible: !this.state.isVisible,
      modalType: modalType,
    });

    if (this.state.isOpen) {
      this.setState({ isOpen: !this.state.isOpen });
      this.props.setTooltipActive();
    }
  };
  handleClose = () => {
    this.setState({ isVisible: false });
  };
  setWorksheetControlsEnabled = (value) => {
    this.setState({ areWorksheetControlsEnabled: value });
  };

  duplicateWorksheet = async () => {
    this.setState({ areWorksheetControlsEnabled: false });
    const { workbookId, activeWorksheet } = this.props;
    setWorksheetApiVersion(activeWorksheet);
    try {
      const results = await worksheetClient.copyWorksheetToExistingWorkbook({
        worksheetId: activeWorksheet.id,
        selectedWorkbookId: workbookId,
        worksheetName: `${activeWorksheet.name} - Copy`,
        createdFromProgramId: 'SeaCalcUI',
        createdFromOriginId: getHostNameFromUrlString(document.location.origin),
      });

      const worksheetId = results.worksheetId;
      const worksheetName = `${activeWorksheet.name} - Copy`;

      this.props.addWorksheetToWorkbook(workbookId, worksheetId, worksheetName);

      this.props.router.navigate(`/workbook/${workbookId}/worksheet/${worksheetId}`, {
        state: { dismissPreviousWorksheetNotice: true },
      });

      this.setState({ areWorksheetControlsEnabled: true });
    } catch (err) {
      this.setState({ areWorksheetControlsEnabled: true });
      console.log(err);
      throw err;
    }
  };

  onNodeMounted = (node) => (this.node = node);
  render() {
    const { worksheetName, workbookName, worksheetId } = this.props;
    const { areWorksheetControlsEnabled, dismissPreviousWorksheetNotice } = this.state;
    const classes = classNames({ 'menu-open': this.state.isOpen }, this.props.className);
    const copyMenuClasses = classNames('copy-move-menu');
    const { 'data-testid': testId } = this.props;
    return (
      <div className="worksheet-info__container">
        <div className="worksheet-info__details">
          {dismissPreviousWorksheetNotice && (
            <EmitNotice type={DISMISS_WORKSHEET_NOTICES_EXCEPT_THIS} worksheetId={worksheetId} />
          )}
          <div className="worksheet-info__name">
            <Helmet>
              <title>
                Sea/calc - {workbookName}: {worksheetName}
              </title>
            </Helmet>
            <EditableLabel
              text={worksheetName}
              onChange={this.renameWorksheetHandler}
              labelClassName="worksheet-info__name-label"
              inputClassName="worksheet-info__name-input"
              isDisabled={!areWorksheetControlsEnabled || !this.props.isEditable}
            />
          </div>
          {isWet(this.props.activeWorksheet.marketSegmentId) ||
          isTanker(this.props.activeWorksheet.marketSegmentId) ? null /*
            #HideDefunctFeaturesForWetCargo - the 'vessel open position from shared database' is one of the features that doesn't currently exist for Wet cargo (the first sea/calc was only for Dry Cargo which has this feature) */ : (
            <div className="worksheet-info__speed-and-consumption-mode">
              <WorksheetSpeedAndConsumptionsModeLabelTooltip
                speedAndConsumptionsMode={this.props.activeWorkbook.speedAndConsumptionsMode}
              />
            </div>
          )}
        </div>
        <CopyMoveWorksheetModalContainer
          isVisible={this.state.isVisible}
          modalType={this.state.modalType}
          handleClose={this.handleClose}
          setWorksheetControlsEnabled={this.setWorksheetControlsEnabled}
        />
        <Overlay
          hidden={!this.state.isVisible}
          data-testid={testId && `${testId}__overlay`}
          onClick={this.handleVisible}
        />
        <div className="worksheet-info__controls">
          {!this.state.isOpen && (
            <ReactTooltip
              place="bottom"
              id="worksheet-info-tooltip"
              className="ve-tooltip-default"
              noArrow
            />
          )}
          <div
            ref={this.onNodeMounted}
            data-tooltip-content={this.props.isEditable ? 'Copy / Move' : 'Copy'}
            data-tooltip-id="worksheet-info-tooltip"
          >
            <CopyWorksheetMenuButton
              className={classes}
              isDisabled={!areWorksheetControlsEnabled}
              onClick={this.toggleMenu}
            />
            {this.state.isOpen && (
              <div className={copyMenuClasses}>
                <div className="copy-move-menu_content">
                  {this.props.isEditable && (
                    <>
                      <div className="copy-move-menu_item">
                        <LinkButton
                          className="copy-move-menu_link-button"
                          onClick={this.duplicateWorksheet}
                          diagnosticId="ManageWorksheet/DuplicateInThisWorkbook"
                        >
                          Duplicate in this workbook
                        </LinkButton>
                      </div>
                      <Divider className="copy-move-menu_item__bottom-divider" />
                    </>
                  )}
                  <div className="copy-move-menu_item">
                    <LinkButton
                      className="copy-move-menu_link-button"
                      onClick={() => this.handleVisible('COPY_TO_EXISTING')}
                      diagnosticId="ManageWorksheet/CopyToAnExistingWorkbook"
                    >
                      Copy to an existing workbook
                    </LinkButton>
                  </div>
                  <div className="copy-move-menu_item">
                    <LinkButton
                      className="copy-move-menu_link-button"
                      onClick={() => this.handleVisible('COPY_TO_NEW')}
                      diagnosticId="ManageWorksheet/CopyToNewWorkbook"
                    >
                      Copy to a new workbook
                    </LinkButton>
                  </div>
                  {this.props.isEditable && (
                    <>
                      <Divider className="copy-move-menu_item__bottom-divider" />
                      <div className="copy-move-menu_item">
                        <LinkButton
                          className="copy-move-menu_link-button"
                          onClick={() => this.handleVisible('MOVE_TO_EXISTING')}
                          diagnosticId="ManageWorksheet/MoveToExistingWorkbook"
                        >
                          Move to an existing workbook
                        </LinkButton>
                      </div>
                      <div className="copy-move-menu_item">
                        <LinkButton
                          className="copy-move-menu_link-button"
                          onClick={() => this.handleVisible('MOVE_TO_NEW')}
                          diagnosticId="ManageWorksheet/MoveToNewWorkbook"
                        >
                          Move to new workbook
                        </LinkButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {!isTanker(this.props.activeWorksheet.marketSegmentId) && (
            <div data-tooltip-content="Export summary" data-tooltip-id="worksheet-info-tooltip">
              <ExportButton
                className="worksheet-info__controls--button"
                onClick={this.props.downloadDetailedCalculationExport}
                diagnosticId="WebApp/WorksheetInfo/ExportWorksheet"
              />
            </div>
          )}
          {this.props.isEditable && (
            <div data-tooltip-content="Delete worksheet" data-tooltip-id="worksheet-info-tooltip">
              <DeleteWithConfirmationButton
                className="worksheet-info__controls--button"
                isDisabled={!areWorksheetControlsEnabled}
                onConfirm={this.deleteWorksheet}
                diagnosticId="WorksheetInfo/DeleteWorksheet"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      renameWorksheet,
      removeWorksheetFromWorkbook,
      addWorksheetToWorkbook,
      downloadDetailedCalculationExport,
    },
    dispatch
  );
}

const ConnectedWorksheetInfo = connect(mapStateToProps, mapDispatchToProps)(WorksheetInfo);

export const WorksheetInfoContainer = withRouter(ConnectedWorksheetInfo);
