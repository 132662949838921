import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import numbro from 'numbro';
import isNil from 'lodash/isNil';

import {
  ballastCostChanged,
  ladenCostChanged,
  ballastWaitingTimeChanged,
  ladenWaitingTimeChanged,
  ballastTransitTimeChanged,
  ladenTransitTimeChanged,
} from 'actions/worksheet/canals';

import { selector } from './selector';
import CanalsHeader from './components/canals-header';
import CanalsRow from './components/canals-row';
import { CanalCostBallastColumns, CanalCostLadenColumns } from './canal-cost-dropdown-settings';
import { Panel, PanelSection } from 'components/panel';

import './styles.scss';
import { isBallast, isLaden } from 'constants/enums/cargo-status';

export class CanalCosts extends Component {
  onBallastCostChangeHandler = (id, value) => {
    if (isNil(value)) {
      return;
    }

    const cost = value.costBallast ? numbro.unformat(value.costBallast) : value;
    this.props.ballastCostChanged(cost, id, this.props.worksheetId);
  };

  onLadenCostChangeHandler = (id, value) => {
    if (isNil(value)) {
      return;
    }

    const cost = value.costLaden ? numbro.unformat(value.costLaden) : value;
    this.props.ladenCostChanged(cost, id, this.props.worksheetId);
  };

  onBallastWaitingTimeChangeHandler = (id, value) => {
    this.props.ballastWaitingTimeChanged(value, id, this.props.worksheetId);
  };

  onLadenWaitingTimeChangeHandler = (id, value) => {
    this.props.ladenWaitingTimeChanged(value, id, this.props.worksheetId);
  };

  onBallastTransitTimeChangeHandler = (id, value) => {
    this.props.ballastTransitTimeChanged(value, id, this.props.worksheetId);
  };

  onLadenTransitTimeChangeHandler = (id, value) => {
    this.props.ladenTransitTimeChanged(value, id, this.props.worksheetId);
  };

  render() {
    const { canals, canalCostData } = this.props;
    const showCanals = canals.length > 0;

    const hasBallast = canals.reduce((prev, canal) => prev || isBallast(canal.cargoStatus), false);
    const hasLaden = canals.reduce((prev, canal) => prev || isLaden(canal.cargoStatus), false);

    return (
      showCanals && (
        <PanelSection>
          <div className="canals">
            <CanalsHeader hasBallast={hasBallast} hasLaden={hasLaden} />
            {canals.map((canal) => (
              <CanalsRow
                key={canal.id}
                canal={canal}
                ballastCostColumns={CanalCostBallastColumns}
                ballastCostData={canalCostData[canal.canalId]}
                ladenCostColumns={CanalCostLadenColumns}
                ladenCostData={canalCostData[canal.canalId]}
                onBallastCostChange={this.onBallastCostChangeHandler}
                onLadenCostChange={this.onLadenCostChangeHandler}
                onBallastWaitingTimeChange={this.onBallastWaitingTimeChangeHandler}
                onLadenWaitingTimeChange={this.onLadenWaitingTimeChangeHandler}
                onBallastTransitTimeChange={this.onBallastTransitTimeChangeHandler}
                onLadenTransitTimeChange={this.onLadenTransitTimeChangeHandler}
              />
            ))}
          </div>
        </PanelSection>
      )
    );
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ballastCostChanged,
      ladenCostChanged,
      ballastWaitingTimeChanged,
      ladenWaitingTimeChanged,
      ballastTransitTimeChanged,
      ladenTransitTimeChanged,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CanalCosts);
