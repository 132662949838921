import Oidc from 'oidc-client';
import logger from 'diagnostics/logger';

let userManagerSettings: Oidc.UserManagerSettings;
let userManager: Oidc.UserManager;

export async function initialise(config) {
  Oidc.Log.logger = console;
  Oidc.Log.level = getOidcLogLevel(config);

  let applicationUrl = config.applicationUrl.replace(/\/$/, '');

  userManagerSettings = {
    authority: config.oidcServerUrl,
    client_id: 'sea-calc-web',
    redirect_uri: `${applicationUrl}/signin-callback.html`,
    silent_redirect_uri: `${applicationUrl}/silent-renew.html`,
    post_logout_redirect_uri: `${applicationUrl}/`,
    response_type: 'code',
    scope: 'openid profile email Permissions.VoyageEstimation',
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true, // Defaults to false.
  };

  userManager = new Oidc.UserManager(userManagerSettings);

  userManager.events.addUserLoaded(onUserLoaded);
  userManager.events.addSilentRenewError(onSilentRenewError);
  userManager.events.addAccessTokenExpired(onAccessTokenExpired);

  await userManager.clearStaleState();
}

export async function getToken() {
  let user = await userManager.getUser();
  let token = user?.access_token;
  if (!token)
    logger.warn('AuthFacade.getToken() returned a nullish token.', {
      ...getNow(),
    });
  return token;
}

export function redirectToLogin() {
  userManager.signinRedirect();
}

export function redirectToLogout() {
  userManager.signoutRedirect();
}

let getNow = () => ({ at: new Date().toISOString() });

let getOidcLogLevel = (config: any) => {
  var oidcLogLevel: string = (config?.oidcLogLevel || '').toUpperCase();
  switch (oidcLogLevel) {
    case 'DEBUG':
      return Oidc.Log.DEBUG;
    case 'ERROR':
      return Oidc.Log.ERROR;
    case 'WARN':
      return Oidc.Log.WARN;
    case 'INFO':
      return Oidc.Log.INFO;
    default:
      return Oidc.Log.NONE;
  }
};

let onUserLoaded = (user: Oidc.User): void => {
  const newToken = user.access_token;
  logger.info('AuthFacade.onUserLoaded. Retrieved new token:', {
    ...getNow(),
    token: newToken,
  });
};

let onSilentRenewError = (error: Error): void => {
  logger.error("Failed to silently renew user's token. Redirecting to login...", {
    ...getNow(),
    error,
  });
  redirectToLogin();
};

let onAccessTokenExpired = (...ev: any[]): void => {
  logger.warn('Access token has expired. Redirecting to login.', {
    ...getNow(),
  });
  redirectToLogin();
};
