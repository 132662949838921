import React from 'react';
import DropDown from 'components/dropdown';
import DraftUnitTypes from 'constants/enums/draft-units';
import { MaterialIconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { marketSegmentIds } from 'constants/market-segments';
import classNames from 'classnames';
import { isFeatureEnabled } from 'config/feature-control';

type Props = {
  addVoyageLeg: Function,
  draftUnit: any,
  onDraftUnitChange: Function,
  shouldAutoCalculateIntake: boolean,
  worksheetInvariantProps: IWorksheetInvariantProps,
};

const VoyageHeader = (props: Props): ReactElement => {
  const isWetCargo = props.worksheetInvariantProps.marketSegmentId === marketSegmentIds.wetCargo;
  return (
    <div
      className={classNames('voyage-data__header', {
        'new-voyage-table__header': isFeatureEnabled('newVoyageTable'),
      })}
    >
      <div className="voyage-data__header-cell voyage-data--grip" />
      <div className="voyage-data__header-cell voyage-data--eea">EEA</div>
      <div className="voyage-data__header-cell voyage-data--type">Type</div>
      <div className="voyage-data__header-cell voyage-data--location">Location</div>
      <div className="voyage-data__header-cell voyage-data--gear">Gear</div>
      <div className="voyage-data__header-cell voyage-data--distance">Total Dist</div>
      <div className="voyage-data__header-cell voyage-data--seca-distance">SECA</div>
      <div className="voyage-data__header-cell-no-padding voyage-data--draft">
        Draft
        <DropDown
          id="voyage-header-draft"
          items={DraftUnitTypes}
          selectedItem={props.draftUnit}
          onChange={props.onDraftUnitChange}
          asLabel
          classNamePrefix="draft"
          diagnosticId="VoyageHeader/ChangeDraftUnit"
        />
      </div>
      <div className="voyage-data__header-cell voyage-data--salinity">Salinity</div>
      <div className="voyage-data__header-cell voyage-data--quantity">
        {props.shouldAutoCalculateIntake ? 'Max Qty (MT)' : 'Qty (MT)'}
      </div>
      {!isWetCargo && (
        <div
          className={classNames('voyage-data__header-cell voyage-data--max-lift', {
            'voyage-data--max-lift-cell-unused': !props.shouldAutoCalculateIntake,
          })}
        >
          Max Lift (MT)
        </div>
      )}
      <div className="voyage-data__header-cell voyage-data--load-rate">Load / Disch</div>
      <div className="voyage-data__header-cell voyage-data--load-rate-unit">Rate</div>
      <div className="voyage-data__header-cell voyage-data--working-day">Working Day</div>
      {
        //#AlignmentIssueInVoyage - There are tickets raised to resolve/look into the alignment issue within the Voyage section (see #41483, #30210, #32082 in DevOps)
      }
      <div
        className={`voyage-data__header-cell ${
          isWetCargo ? 'voyage-data--delay-without-TT' : 'voyage-data--delay'
        }`}
      >
        Delay
      </div>
      {!isWetCargo && <div className="voyage-data__header-cell voyage-data--turn-time">TT</div>}
      <div className="voyage-data__header-cell voyage-data--weather">WF (%)</div>
      <div className="voyage-data__header-cell voyage-data--cost">Cost ($)</div>
      {
        //#See AlignmentIssueInVoyage
      }
      <div
        className={`voyage-data__header-cell  ${
          isWetCargo ? 'voyage-data--action-without-TT' : 'voyage-data--action'
        }`}
      >
        <MaterialIconButton
          className="add-voyage-leg-button"
          icon={iconEnum.AddCircle}
          onClick={props.addVoyageLeg}
          diagnosticId="VoyageHeader/AddVoyageLeg"
        />
      </div>
    </div>
  );
};

export default VoyageHeader;
