import { createSelector } from 'reselect';
import { singleOrThrow } from 'utilities/iterable';
import { getActiveVessel } from '../../../common-selectors/get-active-vessel';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';
import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';
import { getActiveWorksheet } from '../../../common-selectors/get-active-worksheet';

export const selector = createSelector(
  (state) => getActiveVessel(state).netTimeCharter,
  (state) =>
    singleOrThrow(state.worksheetsById[state.activeWorksheetId].cargoes).cargoRate.grossVoyageRate,
  (state) => getActiveCalculationResult(state).bunkers,
  (state) => getActiveCalculationResult(state).totalPortCostsNet,
  (state) => getActiveCalculationResult(state).timeCharter.totalTimeCharterCostsNet,
  (state) => getActiveCalculationResult(state).additionalCostsNetItems,
  (state) => getActiveCalculationResult(state).timeCharter.totalVoyageCostsNet,
  (state) => getActiveCalculationResult(state).voyageRate.totalVoyageCostsNet,
  (state) => getActiveCalculationResult(state).voyageRate.totalExpensesNet,
  getActiveWorksheetInvariantProps,
  (state) => getActiveCalculationResult(state).carbonBreakdown,
  (state) => state.worksheetsById[state.activeWorksheetId],
  (
    netTimeCharter,
    grossVoyageRate,
    bunkers,
    totalPortCostsNet,
    totalTimeCharterCostsNet,
    additionalCostItems,
    timeCharterTotalVoyageCostsNet,
    voyageRateTotalVoyageCostsNet,
    totalExpensesNet,
    worksheetInvariantProps,
    carbonBreakdown,
    worksheet
  ) => {
    return {
      showTimeCharter: netTimeCharter !== 0,
      showVoyageRate: grossVoyageRate !== 0,
      bunkersBallastCosts: bunkers.totalBunkersCostsBallastNet,
      bunkersLadenCosts: bunkers.totalBunkersCostsLadenNet,
      bunkersLoadCosts: bunkers.totalBunkersCostsLoadNet,
      bunkersDischargeCosts: bunkers.totalBunkersCostsDischargeNet,
      bunkersIdleCosts: bunkers.totalBunkersCostsIdleNet,
      bunkersAtPortCosts: bunkers.totalBunkersCostsAtPortNet,
      portCosts: totalPortCostsNet,
      timeCharterCosts: totalTimeCharterCostsNet,
      additionalCostItems,
      totalVoyageCostsTimeCharter: timeCharterTotalVoyageCostsNet,
      totalVoyageCostsVoyageRate: voyageRateTotalVoyageCostsNet,
      worksheetInvariantProps,
      bunkers,
      totalExpensesNetVoyageRate: totalExpensesNet,
      totalCarbonCosts: carbonBreakdown?.totalVoyageCost ?? 0,
      carbonCostIncludeInCosts: worksheet.eua?.carbonCostIncludeInCosts,
    };
  }
);
