import * as actionTypes from 'constants/action-types/intake-calculator';
import { SALT } from 'constants/enums/salinity-types';
import { METERS } from 'constants/enums/draft-units';
import { TPC } from 'constants/enums/immersion-units';
import { CUBIC_METERS } from 'constants/enums/grain-units';
import { CUBIC_FEET_PER_METRIC_TON } from 'constants/enums/stowage-factor-units';
import { emptyGuid, isEmptyGuid } from 'utilities/guid';

const initialState = {
  vessel: {
    vesselIdName: {
      vesselId: null,
      vesselName: '',
    },
    deadweight: 0,
    draft: 0,
    draftUnit: METERS.key,
    grain: 0,
    grainUnit: CUBIC_METERS.key,
    tpcmi: 0,
    immersionUnit: TPC.key,
    isParcelVoyage: false,
  },
  cargo: {
    cargoTypeId: null,
    name: '',
    stowage: 0,
    specificGravity: 0,
    stowageUnit: CUBIC_FEET_PER_METRIC_TON.key,
  },
  port: {
    id: emptyGuid,
    locationId: emptyGuid,
    name: '',
    draft: 0,
    draftUnit: METERS.key,
    salinity: SALT,
  },
  ports: [],
  constants: {
    general: 0,
    bunkersOnBoard: 0,
    freshWater: 0,
  },
  calculationResult: {
    maximumLift: 0,
    errors: [],
  },
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.INTAKE_CALCULATOR_VESSEL_ID_NAME_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          vesselIdName: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_VESSEL_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          deadweight: action.payload.deadweight,
          draft: action.payload.draft,
          grain: action.payload.grain,
          tpcmi: action.payload.tpcmi,
          draftUnit: action.payload.draftUnit,
          grainUnit: action.payload.grainUnit,
          immersionUnit: action.payload.immersionUnit,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_DEADWEIGHT_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          deadweight: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_VESSEL_DRAFT_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          draft: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_VESSEL_DRAFT_UNIT_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          draft: action.payload.draft,
          draftUnit: action.payload.draftUnit,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_GRAIN_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          grain: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_GRAIN_UNIT_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          grain: action.payload.grain,
          grainUnit: action.payload.grainUnit,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_TPCMI_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          tpcmi: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_IMMERSION_UNIT_CHANGED:
      return {
        ...state,
        vessel: {
          ...state.vessel,
          tpcmi: action.payload.immersion,
          immersionUnit: action.payload.immersionUnit,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_CARGO_CHANGED:
      return {
        ...state,
        cargo: {
          ...state.cargo,
          cargoTypeId: action.payload.cargoTypeId,
          name: action.payload.name,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_STOWAGE_CHANGED:
      return {
        ...state,
        cargo: {
          ...state.cargo,
          stowage: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_SPECIFIC_GRAVITY_CHANGED:
      return {
        ...state,
        cargo: {
          ...state.cargo,
          specificGravity: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_STOWAGE_UNIT_CHANGED:
      return {
        ...state,
        cargo: {
          ...state.cargo,
          stowage: action.payload.stowage,
          stowageUnit: action.payload.stowageUnit,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_PORT_CHANGED:
      return {
        ...state,
        port: {
          id: action.payload.id,
          locationId: action.payload.locationId,
          name: action.payload.name,
          draft: action.payload.draft,
          draftUnit: action.payload.draftUnit,
          salinity: action.payload.salinity,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_PORT_DRAFT_CHANGED:
      return {
        ...state,
        port: {
          ...state.port,
          draft: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_PORT_DRAFT_UNIT_CHANGED:
      return {
        ...state,
        port: {
          ...state.port,
          draft: action.payload.draft,
          draftUnit: action.payload.draftUnit,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_SALINITY_CHANGED:
      return {
        ...state,
        port: {
          ...state.port,
          salinity: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_SALINITY_FACTOR_CHANGED:
      return {
        ...state,
        port: {
          ...state.port,
          salinity: {
            key: 999,
            label: 'CUSTOM',
            value: action.payload,
          },
        },
      };
    case actionTypes.INTAKE_CALCULATOR_CONSTANTS_CHANGED:
      return {
        ...state,
        constants: {
          ...state.constants,
          general: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_BUNKERS_ON_BOARD_CHANGED:
      return {
        ...state,
        constants: {
          ...state.constants,
          bunkersOnBoard: action.payload,
        },
      };
    case actionTypes.INTAKE_CALCULATOR_FRESH_WATER_CHANGED:
      return {
        ...state,
        constants: {
          ...state.constants,
          freshWater: action.payload,
        },
      };
    case actionTypes.SET_INTAKE_CALCULATOR_FROM_API:
      return resetIntakeCalculatorFromApi(action.payload);
    case actionTypes.INTAKE_CALCULATOR_CALCULATE_MAX_LIFT_FROM_API:
      return {
        ...state,
        calculationResult: action.payload,
      };
    case actionTypes.INTAKE_CALCULATOR_CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
};

const resetIntakeCalculator = (payload) => {
  const { vessel, cargo, ports, constants } = payload;
  const validPorts = sanitisePorts(ports);
  const port = validPorts.length > 0 ? validPorts[0] : initialState.port;

  const state = {
    vessel: {
      vesselIdName: {
        vesselId: vessel.id,
        vesselName: vessel.name,
      },
      deadweight: vessel.deadweight,
      draft: vessel.draft,
      draftUnit: vessel.draftUnit,
      grain: vessel.grain,
      grainUnit: vessel.grainUnit,
      tpcmi: vessel.tpcmi,
      immersionUnit: vessel.immersionUnit,
    },
    cargo: {
      cargoTypeId: cargo.cargoTypeId,
      name: cargo.name,
      stowage: cargo.stowage,
      stowageUnit: cargo.stowageUnit,
      specificGravity: cargo.specificGravity,
    },
    port,
    ports: validPorts,
    constants: {
      general: constants.general,
      bunkersOnBoard: constants.bunkersOnBoard,
      freshWater: constants.freshWater,
    },
    calculationResult: {
      maximumLift: 0,
      errors: [],
    },
    marketSegmentId: payload.marketSegmentId,
  };

  return state;
};

const resetIntakeCalculatorFromApi = (payload) => {
  let state = resetIntakeCalculator(payload.worksheetProps);
  state.calculationResult = payload.maxLiftResponse;

  return state;
};

const sanitisePorts = (ports) => ports.filter((port) => isEmptyGuid(port.locationId) === false);
