import React from 'react';
import LegContainer from 'modules/voyage/components/leg-container';
import { Location } from 'modules/voyage/components/location';
import { VoyageLeg } from 'modules/voyage/components/voyage-leg';
import NextPortOfCall from 'modules/voyage/components/next-port-of-call';
import EuaLabel from './eua-label';
import classNames from 'classnames';
import { MaterialIconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  consumptionChanged,
  consumptionOverrideQtyChanged,
  speedChanged,
} from 'actions/worksheet/speed-and-consumptions';
import { nextPortOfCallIsInEeaUpdated } from 'actions/worksheet/vessel';
import { isInEeaChanged, isInSecaChanged, voyageLegMoved } from 'actions/worksheet/voyage';
import { isFeatureEnabled } from 'config/feature-control';
import type { ConsumptionPayload, SpeedPayload } from 'modules/vessel/speed-and-consumptions/types';
import type { OpenPosition } from 'modules/vessel/vessel-detail/types';
import { selector } from 'modules/voyage/selectors';
import './styles.scss';
import {
  getIndexOfLastLoadOrDischargePort,
  getPortsTotalLoadAndDischarge,
} from 'modules/voyage/helpers';
import DragAndDropContainer from 'components/drag-and-drop/drag-and-drop-container';
import DragAndDropItem from 'components/drag-and-drop/drag-and-drop-item';
import TimelineLegs from 'modules/voyage/components/route-graph/cargo-lite-ports-of-call-feature-components/timeline-legs';
import PreviousPortOfCallWetCargo from 'modules/voyage/components/route-graph/cargo-lite-ports-of-call-feature-components/previous-port-of-call';

interface ReduxProps {
  voyage: IVoyage;
  activeVessel: IVessel;
  worksheetInvariantProps: IWorksheetInvariantProps;
  worksheetId: number;
  calculationResult: any;
  isInSecaChanged: (payload: any, portId: number, worksheetId: number) => void;
  isInEeaChanged: (payload: any, portId: number, worksheetId: number) => void;
  consumptionChanged: (payload: ConsumptionPayload, portId: number, worksheetId: number) => void;
  consumptionOverrideQtyChanged: (
    payload: ConsumptionPayload,
    portId: number,
    worksheetId: number
  ) => void;
}

interface RouteGraphProps extends ReduxProps {
  handleOpenPositionPrevPortOfCallIsInEeaUpdated: (isInEea: boolean) => void;
  handleOpenPositionUpdated: (newPosition: OpenPosition) => void;
  handleLocationChanged: (voyageLegId: number, newLocationInfo: any) => Promise<void>;
  speedChanged: (payload: SpeedPayload, worksheetId: number, vesselEntryId: number) => void;
  handleSecaDistanceChanged: (
    portId: number,
    value: number,
    inboundRouteVariant: InboundRouteVariantViewModel
  ) => void;
  handleTotalDistanceChanged: (
    portId: number,
    value: number,
    inboundRouteVariant: InboundRouteVariantViewModel
  ) => void;
  handleSecaFuelTypeChanged: (newFuelType: any) => void;
  handleMainFuelTypeChanged: (newFuelType: any) => void;
  handlePortDaysChanged: (voyageLeg: IVoyageLeg, newValue: number) => void;
  handlePortCostChanged: (portId: number, value: number) => void;
  showNextPortOfCall: boolean;
  handleNextPortOfCallIsInEeaUpdated: (isInEea: boolean) => void;
  isInEeaOpenLocationVisible?: boolean;
  handleDragEnd: (dragInfo: any) => void;
  handleLocationDeleted: (id: any) => void;
  appendNewVoyageEntry: () => void;
}

const RouteGraph = ({
  voyage,
  activeVessel,
  handleOpenPositionPrevPortOfCallIsInEeaUpdated,
  worksheetInvariantProps,
  worksheetId,
  calculationResult,
  handleLocationChanged,
  isInSecaChanged,
  isInEeaChanged,
  speedChanged,
  consumptionChanged,
  consumptionOverrideQtyChanged,
  handleSecaDistanceChanged,
  handleTotalDistanceChanged,
  handleSecaFuelTypeChanged,
  handleMainFuelTypeChanged,
  handlePortDaysChanged,
  handlePortCostChanged,
  showNextPortOfCall,
  handleNextPortOfCallIsInEeaUpdated,
  handleOpenPositionUpdated,
  handleLocationDeleted,
  appendNewVoyageEntry,
  isInEeaOpenLocationVisible = true,
  handleTypeChanged,
  handleCargoQuantityChanged,
  handleDragEnd,
}: RouteGraphProps) => {
  const voyageLegs = voyage.legs;
  const { name, openPosition, initialOpenPosition } = activeVessel;
  const isWetCargoLitePortsOfCallEnabled = isFeatureEnabled('wetCargoLitePortsOfCall');
  const isWetCargoLiteMultiLegEnabled = isFeatureEnabled('wetCargoLiteMultiLeg');
  const legsHeight = isWetCargoLitePortsOfCallEnabled ? 100 : 120;
  const routeContainerPosition = isWetCargoLiteMultiLegEnabled ? -70 : 0;
  const { totalLoad, totalDischarge } = getPortsTotalLoadAndDischarge(voyageLegs);
  const lastLoadOrDischargePortIndex = getIndexOfLastLoadOrDischargePort(voyageLegs);
  return (
    <div
      className="route-container"
      style={{
        paddingLeft: isWetCargoLitePortsOfCallEnabled ? 50 : 10,
        top: routeContainerPosition,
      }}
    >
      <PreviousPortOfCallWetCargo
        handleOpenPositionPrevPortOfCallIsInEeaUpdated={
          handleOpenPositionPrevPortOfCallIsInEeaUpdated
        }
        openPosition={openPosition}
      />
      <LegContainer style={{ height: legsHeight }}>
        <div
          style={{ marginLeft: isWetCargoLitePortsOfCallEnabled ? '' : '18px' }}
          className={classNames({ 'port-of-call-top-container': isWetCargoLitePortsOfCallEnabled })}
        >
          <div className="worksheet-section__property">
            <label className="worksheet-section__property-label">Open Location</label>
            <Location
              locationId={openPosition.id}
              locationName={openPosition.name}
              isInEea={openPosition.prevPortOfCallIsInEea}
              marketSegmentId={worksheetInvariantProps.marketSegmentId}
              onLocationChange={handleOpenPositionUpdated}
              onIsInEeaChange={handleOpenPositionPrevPortOfCallIsInEeaUpdated}
              isInSecaVisible={false}
              autoPopulatedField
              activeVesselName={name}
              initialOpenPosition={initialOpenPosition}
              labelName="Open Location"
              isInEeaVisible={isInEeaOpenLocationVisible}
            />
          </div>
        </div>
      </LegContainer>
      <div style={{ display: 'flex' }}>
        <TimelineLegs voyage={voyage} openPosition={openPosition} />
        <DragAndDropContainer onDragEnd={handleDragEnd}>
          {voyageLegs.map((leg, index) => (
            <DragAndDropItem
              draggableId={leg.id}
              index={index}
              key={leg.id}
              isDragDisabled={!isFeatureEnabled('wetCargoLiteMultiLeg')}
            >
              <LegContainer
                legClassName={classNames('voyage-leg', {
                  'voyage-leg-last': index === voyageLegs.length - 1,
                })}
                style={{ height: legsHeight }}
                key={leg.id}
                disableTimeline={isWetCargoLitePortsOfCallEnabled}
                euaLabel={<EuaLabel index={index} voyage={voyage} openPosition={openPosition} />}
              >
                <VoyageLeg
                  activeVessel={activeVessel}
                  worksheetId={worksheetId}
                  voyageLeg={leg}
                  calculationResult={calculationResult}
                  marketSegmentId={worksheetInvariantProps.marketSegmentId}
                  onLocationChange={handleLocationChanged}
                  onIsInSecaChange={isInSecaChanged}
                  onIsInEeaChange={isInEeaChanged}
                  onSpeedChange={speedChanged}
                  onConsumptionChanged={consumptionChanged}
                  onConsumptionOverrideQtyChanged={consumptionOverrideQtyChanged}
                  onSecaDistanceChange={handleSecaDistanceChanged}
                  onTotalDistanceChange={handleTotalDistanceChanged}
                  onSecaFuelTypeChange={handleSecaFuelTypeChanged}
                  onMainFuelTypeChange={handleMainFuelTypeChanged}
                  onPortDaysChange={handlePortDaysChanged}
                  onPortCostsChange={handlePortCostChanged}
                  onTypeChange={handleTypeChanged}
                  onCargoQuantityChanged={handleCargoQuantityChanged}
                  isLastLoadOrDischargePort={index === lastLoadOrDischargePortIndex}
                  totalLoadCargo={totalLoad}
                  totalDischargeCargo={totalDischarge}
                  onLocationDelete={handleLocationDeleted}
                />
              </LegContainer>
            </DragAndDropItem>
          ))}
        </DragAndDropContainer>
      </div>
      {showNextPortOfCall && isWetCargoLitePortsOfCallEnabled && (
        <LegContainer legClassName="next-port-of-call-leg" dotClassName="dot solid-secondary-dot">
          <div className="port-of-call-bottom-container">
            <NextPortOfCall
              activeVessel={activeVessel}
              handleNextPortOfCallIsInEeaUpdated={handleNextPortOfCallIsInEeaUpdated}
              oldView={true}
            />
          </div>
        </LegContainer>
      )}
      {isFeatureEnabled('wetCargoLiteMultiLeg') && (
        <div className="add-voyage-button-container">
          <MaterialIconButton
            className="add-voyage-leg-button"
            icon={iconEnum.AddCircle}
            onClick={appendNewVoyageEntry}
            diagnosticId="VoyageHeader/AddVoyageLeg"
          />
          <label>Add</label>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      isInSecaChanged,
      isInEeaChanged,
      nextPortOfCallIsInEeaUpdated,
      voyageLegMoved,
      speedChanged,
      consumptionChanged,
      consumptionOverrideQtyChanged,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteGraph);
