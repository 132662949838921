import React from 'react';
import { NoticesListContext } from './notices-emitter-context';
import { NoticeViewModel } from './emitter-contract';
import classNames from 'classnames';

function Notice({
  id,
  children,
  dismiss,
  onWillDismiss,
  active,
  remainingNotifications,
  dismissNonActiveWorksheetMessages,
}) {
  React.useEffect(() => {
    return () => {
      onWillDismiss && onWillDismiss();
    };
  }, []);

  const additionalProps = {
    id: id,
    remainingNotifications:
      remainingNotifications > 9
        ? '9+'
        : remainingNotifications === 0
          ? ''
          : remainingNotifications,
  };

  const classes = classNames('notice', { 'hide-notice': !active });

  return (
    <>
      <div className={classes}>
        <div className="notice-content">
          {typeof children === 'function'
            ? children({
                dismiss,
                additionalProps,
                dismissNonActiveWorksheetMessages,
              })
            : children}
        </div>
      </div>
    </>
  );
}

interface NoticesListProps {
  /**
   * Use this to override the way notices are rendered completely.
   * This can be used to get rid of the wrapper <div> elements or
   * to provide a way to render notices consistently.
   */
  renderNotice?: (notice: NoticeViewModel) => void;
  className: string;
}

export function NoticesList({ renderNotice, className }: NoticesListProps) {
  const notices = React.useContext(NoticesListContext);
  const classes = classNames('notices-list', className);

  if (notices && notices.length) {
    const noOfNotices = notices.length - 1;
    notices.forEach((notice, index) => {
      notice.remainingNotifications = noOfNotices - index;
      notice.active = !index;
    });
  }

  return notices && notices.length ? (
    <div className={classes}>
      {notices.map(
        ({
          emitNoticeId,
          children,
          dismiss,
          onWillDismiss,
          remainingNotifications,
          active,
          dismissNonActiveWorksheetMessages,
        }) =>
          typeof renderNotice === 'function' ? (
            <React.Fragment
              key={emitNoticeId}
              remainingNotifications={remainingNotifications}
              active={active}
            >
              {renderNotice({
                emitNoticeId,
                children,
                dismiss,
                onWillDismiss,
                remainingNotifications,
                active,
                dismissNonActiveWorksheetMessages,
              })}
            </React.Fragment>
          ) : (
            <Notice
              key={emitNoticeId}
              id={emitNoticeId}
              dismiss={dismiss}
              onWillDismiss={onWillDismiss}
              active={active}
              remainingNotifications={remainingNotifications}
              dismissNonActiveWorksheetMessages={dismissNonActiveWorksheetMessages}
            >
              {children}
            </Notice>
          )
      )}
    </div>
  ) : null;
}
