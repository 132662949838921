import React from 'react';
import { Table, TableBody, TableHeader, TableRow } from 'components/table';

import './styles.scss';

const VoyageDetails = (props) => {
  return (
    <Table className="voyage-detail-table">
      <TableHeader>
        <div className="voyage-detail-table--voyage-leg">Voyage Legs</div>
        <div className="voyage-detail-table--waypoints">Used Waypoints</div>
        <div className="voyage-detail-table--waypoints">Avoided Waypoints</div>
      </TableHeader>
      <TableBody>
        {props.voyageLegs.map((voyageLeg, index) => {
          const from = getLocationName(voyageLeg.fromLocationName);
          const to = getLocationName(voyageLeg.toLocationName);

          return (
            <TableRow key={index}>
              <div className="voyage-detail-table--voyage-leg">{`${from} - ${to}`}</div>
              <div className="voyage-detail-table--waypoints">
                {getWaypoints(voyageLeg.waypoints, false)}
              </div>
              <div className="voyage-detail-table--waypoints">
                {getWaypoints(voyageLeg.waypoints, true)}
              </div>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const unspecifiedLocationName = 'Unspecified';

const getLocationName = (rawLocationName) =>
  rawLocationName === null || rawLocationName === '' ? unspecifiedLocationName : rawLocationName;

const getWaypoints = (waypoints, avoided) =>
  waypoints
    .filter((waypoint) => waypoint.avoid === avoided)
    .map(buildWaypointName)
    .join(', ');

const buildWaypointName = (waypoint) =>
  waypoint.unavoidable ? `${waypoint.name} (Unavoidable)` : waypoint.name;

export default VoyageDetails;
