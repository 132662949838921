import { Geolocation } from 'utilities/location';
import { detailedDiff } from 'deep-object-diff';
import { isAllowedWaypoint } from 'constants/data/waypoints';
import * as setUtils from 'utilities/set/set-utils';

type RouteInfoSubjectToDiff = {
  hasSludgeDischargeBan: boolean,
  waypointLocationIds: Array<LocationId>,
};

/**
 * This string is constructed purely for `detailedDiff` - TODO don't expose it. Perhaps expose `voyageEntryId` and the `fromLocationGeoCoords`.
 */
type RouteIdString = string;

export const getVoyageRouteDifferences = (
  voyageCurrentWorksheetInfo: {
    avoidSecaZones: boolean,
    sailingLegs: Array<{
      fromToLocations: FromToLocationsPairWithApplicableVessels,
      voyageEntryDetails: IVoyageLegViewModel,
      routeVariant: InboundRouteVariantViewModel,
    }>,
    waypointsToExclude: Array<IWaypointIdentity>,
  },
  voyageLatestInfo: {
    latestRoutesForLegs: Array<RouteResultWithInput>,
    latestLocationInfo: {
      [locationId: string]: {
        operationalRestrictions: { [OperationalRestrictionId]: boolean },
      },
    },
  }
): {
  added: {
    [RouteIdString]: RouteInfoSubjectToDiff,
  },
  deleted: {
    [RouteIdString]: RouteInfoSubjectToDiff,
  },
  updated: {
    [RouteIdString]: RouteInfoSubjectToDiff,
  },
} => {
  // only compare latest routes with routes that match existing routes,
  // otherwise we assume that these are new entries by the user will have the
  // latest information from routing API anyways
  const matchedVoyageLegsWithRoutes = voyageCurrentWorksheetInfo.sailingLegs
    .map(({ fromToLocations, routeVariant, voyageEntryDetails }) => ({
      fromToLocations: fromToLocations,
      voyageEntryId: voyageEntryDetails.id,
      routeVariant: routeVariant,
      latestDataForLeg: (
        voyageLatestInfo.latestRoutesForLegs.find((latestRouteForLeg) =>
          isSameLegRoute(
            {
              fromToLocations: fromToLocations,
              avoidSecaZones: voyageCurrentWorksheetInfo.avoidSecaZones,
              waypointsToExclude: voyageCurrentWorksheetInfo.waypointsToExclude,
            },
            latestRouteForLeg
          )
        ) ||
        {
          /*TODO change to .? once supported*/
        }
      ).routeResult,
    }))
    .filter((_) => _.latestDataForLeg !== undefined);

  const existingWaypointsAndSludgeDischargeBans = Object.fromEntries(
    matchedVoyageLegsWithRoutes.map((_) => [
      `${_.voyageEntryId}.from${Geolocation.getIdentityPrimitiveNullSafe(
        _.fromToLocations.fromLocation.geoCoords
      )}`,
      mapToWaypointsAndSludgeDischargeBansInfo(_.routeVariant),
    ])
  );
  const latestWaypointsAndSludgeDischargeBans = Object.fromEntries(
    matchedVoyageLegsWithRoutes.map((_) => [
      `${_.voyageEntryId}.from${Geolocation.getIdentityPrimitiveNullSafe(
        _.fromToLocations.fromLocation.geoCoords
      )}`,
      mapToWaypointsAndSludgeDischargeBansInfo(_.latestDataForLeg),
    ])
  );

  return detailedDiff(
    existingWaypointsAndSludgeDischargeBans,
    latestWaypointsAndSludgeDischargeBans
  );
};

const mapToWaypointsAndSludgeDischargeBansInfo = (
  routeViewModel: RouteViewModel
): RouteInfoSubjectToDiff => {
  return {
    hasSludgeDischargeBan: hasSludgeDischargeBan(routeViewModel),
    waypointLocationIds: Object.fromEntries(
      routeViewModel.waypoints
        .filter((waypoint) => isAllowedWaypoint(waypoint.locationId))
        .filter((_) => !_.avoid)
        .map((waypoint) => [waypoint.locationId, {}])
    ),
  };
};

const hasSludgeDischargeBan = (leg: { routeRetrievedFromRoutingApiOn: Date }): boolean => {
  const sludgeDischargeBanImplementationDate = new Date('2020-01-21T00:00:00Z');
  // from the requirements, rather than use the distances and complicate testing we
  // chosen to use the routing retrieve date being before the IMO2020 implementation date
  // as a way of deciding whether a leg contains sludge discharge bans
  return new Date(leg.routeRetrievedFromRoutingApiOn) >= sludgeDischargeBanImplementationDate;
};

const isSameLegRoute = (
  legRouteA: RouteCalculationInput,
  legRouteB: RouteCalculationInput
): boolean => {
  const isWaypointsToExcludeTheSame = setUtils.equals(
    new Set(legRouteA.waypointsToExclude.map((_) => _.locationId)),
    new Set(legRouteB.waypointsToExclude.map((_) => _.locationId))
  );
  return (
    Geolocation.areEqualNullSafe(
      legRouteA.fromToLocations.fromLocation.geoCoords,
      legRouteB.fromToLocations.fromLocation.geoCoords
    ) &&
    Geolocation.areEqualNullSafe(
      legRouteA.fromToLocations.toLocation.geoCoords,
      legRouteB.fromToLocations.toLocation.geoCoords
    ) &&
    legRouteA.avoidSecaZones === legRouteB.avoidSecaZones &&
    isWaypointsToExcludeTheSame
  );
};
