import React, { memo } from 'react';

import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

export const CopyWorksheetMenuButton = memo(({ isDisabled, onClick, className, diagnosticId }) => (
  <IconButton
    icon={iconEnum.MoreVert}
    className={className}
    disabled={isDisabled}
    onClick={onClick}
    diagnosticId={diagnosticId}
  />
));
