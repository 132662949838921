import { createSelector } from 'reselect';

export const selector = createSelector(
  (state) => state.activeWorksheetId,
  (state) => state.worksheetsById[state.activeWorksheetId].cargoes,
  (state) => state.worksheetsById[state.activeWorksheetId].voyage.shouldAutoCalculateIntake,
  (worksheetId, cargoes, shouldAutoCalculateIntake) => ({
    worksheetId,
    cargoes,
    shouldAutoCalculateIntake,
  })
);
