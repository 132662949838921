import { bunkersOnBoardId } from 'constants/enums/bunkers';
import { getSalinityTypeById } from 'constants/enums/salinity-types';
import { getDraftUnitTypeById } from 'constants/enums/draft-units';
import { getImmersionUnitTypeById } from 'constants/enums/immersion-units';
import { getGrainUnitTypeById } from 'constants/enums/grain-units';
import { getStowageUnitTypeById } from 'constants/enums/stowage-factor-units';
import { createSelector } from 'reselect';
import { getActiveWorksheet } from '../../common-selectors/get-active-worksheet';
import { getActiveVessel } from '../../common-selectors/get-active-vessel';
import { marketSegmentIds, MarketSegmentId } from 'constants/market-segments';

export const selector = createSelector(
  (state) => state.intakeCalculator,
  (state) => state.referenceData.balticIndexVessels,
  (state) => state.referenceData?.balticTankerIndexVessels,
  getActiveWorksheet,
  getActiveVessel,
  (
    intakeCalculator,
    balticIndexVessels,
    balticTankerIndexVessels,
    activeWorksheet,
    activeVessel
  ) => {
    return {
      intakeCalculatorInputs: getIntakeCalculatorInputs(
        intakeCalculator,
        activeWorksheet ? activeWorksheet.marketSegmentId : marketSegmentIds.undefined
      ),
      balticIndexVessels,
      balticTankerIndexVessels,
      worksheet:
        activeWorksheet && activeVessel ? getWorksheetData(activeWorksheet, activeVessel) : null,
    };
  }
);

export const getIntakeCalculatorInputs = (intakeCalculator, marketSegmentId: MarketSegmentId) => ({
  ...intakeCalculator,
  vessel: {
    ...intakeCalculator.vessel,
    draftUnit: getDraftUnitTypeById(intakeCalculator.vessel.draftUnit),
    immersionUnit: getImmersionUnitTypeById(intakeCalculator.vessel.immersionUnit),
    grainUnit: getGrainUnitTypeById(intakeCalculator.vessel.grainUnit),
  },
  port: {
    ...intakeCalculator.port,
    draftUnit: getDraftUnitTypeById(intakeCalculator.port.draftUnit),
  },
  cargo: {
    ...intakeCalculator.cargo,
    stowageUnit: getStowageUnitTypeById(intakeCalculator.cargo.stowageUnit),
  },
  marketSegmentId,
});

export const getWorksheetData = (worksheet, vessel) => {
  return {
    vessel: getVesselData(vessel),
    cargo: getCargoData(worksheet.cargoes),
    ports: getPortsData(worksheet.voyage),
    constants: getConstantsData(vessel, worksheet.bunkerExpenses),
    marketSegmentId: worksheet.marketSegmentId,
  };
};

const getVesselData = (vessel) => ({
  id: vessel.vesselId,
  name: vessel.name,
  deadweight: vessel.deadWeight,
  draft: vessel.draft,
  draftUnit: vessel.draftUnit,
  tpcmi: vessel.tpcmi,
  immersionUnit: vessel.immersionUnit,
  grain: vessel.grain,
  grainUnit: vessel.grainUnit,
  isParcelVoyage: vessel.isParcelVoyage,
});

const getCargoData = (cargoes) =>
  cargoes.map((cargo) => ({
    cargoTypeId: cargo.cargoTypeId,
    name: cargo.name,
    stowage: cargo.stowage,
    stowageUnit: cargo.stowageUnit,
    specificGravity: cargo.specificGravity,
    marketSegmentId: cargo.marketSegmentId,
  }))[0];

const getPortsData = (voyage) =>
  voyage.legs.map((leg) => ({
    id: leg.id,
    locationId: leg.locationId,
    name: leg.name,
    draft: leg.draft,
    draftUnit: voyage.draftUnit,
    salinity: getSalinityTypeById(leg.salinity),
  }));

const getConstantsData = (vessel, bunkerExpenses) => {
  const bunkersOnBoard = bunkerExpenses.bunkers
    .find((bunker) => bunker.key === bunkersOnBoardId)
    .fuelGrades.reduce((total, fuelGrade) => total + fuelGrade.quantity, 0);

  return {
    general: vessel.constants,
    bunkersOnBoard,
    freshWater: 0,
  };
};
