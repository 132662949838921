import { LOAD_WORKSHEET, SET_WORKSHEET_CHANGE_STATUS } from 'constants/action-types/workbook';
import { WorksheetChangeStatus } from 'constants/enums/worksheet-status';

const changeStatus = (state = null, action) => {
  if (action.type === LOAD_WORKSHEET) {
    return WorksheetChangeStatus.CLEAN;
  } else if (action.type === SET_WORKSHEET_CHANGE_STATUS) {
    return action.payload;
  }
  return state;
};

export default changeStatus;
