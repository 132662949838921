import { ALL_FUEL_GRADES_IN_UI_ORDER } from 'constants/enums/fuel-grades';
import classNames from 'classnames';
import DropDown from 'components/dropdown';

const FuelGradeSection = ({ title, selectedFuelGrade, onChange }) => (
  <>
    <div className="worksheet-section__property">
      <label className="worksheet-section__property-label">&nbsp;</label>
      <div className={classNames('modal-label__container__fuel-grade')}>
        <div className="text">{title}</div>
      </div>
    </div>
    <div className="worksheet-section__property">
      <label className="worksheet-section__property-label">Fuel Grade</label>
      <DropDown
        id={`fuel-${title.toLowerCase()}`}
        items={ALL_FUEL_GRADES_IN_UI_ORDER}
        selectedItem={selectedFuelGrade}
        onChange={onChange}
        className="fuel-grade-dropdown dropdown__display"
        diagnosticId={`FuelGrade/Change${title}`}
      />
    </div>
  </>
);
export default FuelGradeSection;
