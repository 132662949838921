import { featureToggles } from 'config';
import {
  getFeatureFlagValueFromConfig,
  getFeatureFlagValueFromLocalStorage,
  getFeatureFlagValueFromQueryString,
} from './feature-flag-sources';
import isNil from 'lodash/isNil';

export function isFeatureEnabled(featureKey: string): ?boolean {
  if (isNil(featureKey)) throw new Error('`featureKey` must be specified');

  const findResult = [
    // Note order is important, the top ones override the subsequent ones.

    // query-string overrides all below. Values found here decide the setting in a single tab
    getFeatureFlagValueFromQueryString,
    // local storage overrides all below. Values found here changes the default for one person only
    getFeatureFlagValueFromLocalStorage,
    // Values found in config file set the default for the whole team
    (featureKey) => getFeatureFlagValueFromConfig(featureToggles || {}, featureKey),
  ]
    .map((getter) => getter(featureKey))
    .find((value) => !isNil(value));

  if (findResult === undefined) return undefined;

  return findResult;
}
