import React, { FC } from 'react';
import { connect } from 'react-redux';

import { selector } from './selector';
import { delayChangedAllLegs } from '../../actions/worksheet/voyage';
import { Header } from '../../components/headers';

import './styles.scss';
import CalcInput from '../../components/calc-input';

export const TimeCharter: FC = ({
  waitingDays,
  portDays,
  ballastDays,
  ladenDays,
  totalDays,
  worksheetId,
  delayChangedAllLegs,
}) => (
  <div>
    <Header text="TC" />
    <div style={{ display: 'flex' }}>
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Waiting Days</label>
        <CalcInput
          userSpecifiedValue={waitingDays}
          isEditable
          className="worksheet-section__property-input-TC"
          maxDecimalDigits={2}
          onInputChange={(newValue) => {
            delayChangedAllLegs({ delay: newValue, delayUnit: 2 }, worksheetId);
          }}
          name="Waiting Days"
        />
      </div>
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Port Days</label>
        <CalcInput
          systemSpecifiedValue={portDays}
          className="worksheet-section__property-input-TC"
          maxDecimalDigits={2}
          isEditable={false}
          isReadonly
          name="Port Days"
        />
      </div>
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Laden Days</label>
        <CalcInput
          systemSpecifiedValue={ladenDays}
          className="worksheet-section__property-input-TC"
          maxDecimalDigits={2}
          isEditable={false}
          isReadonly
          name="Laden Days"
        />
      </div>
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Ballast Days</label>
        <CalcInput
          systemSpecifiedValue={ballastDays}
          className="worksheet-section__property-input-TC"
          maxDecimalDigits={2}
          isEditable={false}
          isReadonly
          name="Ballast Days"
        />
      </div>
      <div className="worksheet-section__property">
        <label className="worksheet-section__property-label">Total Days</label>
        <CalcInput
          systemSpecifiedValue={totalDays}
          className="worksheet-section__property-input-TC"
          maxDecimalDigits={2}
          isEditable={false}
          isReadonly
          name="Total Days"
        />
      </div>
    </div>
  </div>
);

const mapStateToProps = selector;

export default connect(mapStateToProps, { delayChangedAllLegs })(TimeCharter);
