import { DISCHARGE, LOAD } from "../voyage-leg";

export const LADEN = { id: 1, key: 'laden', label: 'Laden' };
export const BALLAST = { id: 5, key: 'ballast', label: 'Ballast' };
export const ECO_LADEN = { id: 6, key: 'ecoLaden', label: 'Eco-Laden' };
export const ECO_BALLAST = { id: 7, key: 'ecoBallast', label: 'Eco-Ballast' };
export const PORT_IDLE = { id: 8, key: 'idle', label: 'Idle' };
export const PORT_WORKING = { id: 9, key: 'working', label: 'Working' }; // Note: id = 9 - Maps to VesselSpeedAndConsumptionActionId in Vessel API
export const PORT_MANOEUVRING = {
  id: 10,
  key: 'manoeuvring',
  label: 'Manoeuvring',
};
export const CANAL = { id: 11, key: 'canal', label: 'Canal/Pilotage' };
export const LOADING = { id: 12, key: 'loading', label: 'Loading' };
export const DISCHARGING = {
  id: 13,
  key: 'discharging',
  label: 'Discharging',
};

export const SAILING = {
  id: 14,
  key: 'sailing',
  label: 'Sailing',
};

export const ECO_SAILING = {
  id: 15,
  key: 'ecoSailing',
  label: 'Eco-Sailing',
};

export const SpeedAndConsTypes = [
  LADEN,
  BALLAST,
  ECO_LADEN,
  ECO_BALLAST,
  PORT_IDLE,
  PORT_MANOEUVRING,
  CANAL,
  LOADING,
  DISCHARGING,
  SAILING,
  ECO_SAILING,
];

export const getSpeedAndConTypeById = (typeId: number) => {
  switch (typeId) {
    case LADEN.id:
      return LADEN;
    case BALLAST.id:
      return BALLAST;
    case ECO_LADEN.id:
      return ECO_LADEN;
    case ECO_BALLAST.id:
      return ECO_BALLAST;
    case PORT_IDLE.id:
      return PORT_IDLE;
    case PORT_WORKING.id:
      return PORT_WORKING;
    case PORT_MANOEUVRING.id:
      return PORT_MANOEUVRING;
    case CANAL.id:
      return CANAL;
    case LOADING.id:
      return LOADING;
    case DISCHARGING.id:
      return DISCHARGING;
    case SAILING.id:
      return SAILING;
    case ECO_SAILING.id:
      return ECO_SAILING;
    default:
      return {};
  }
};

interface ISpeedAndConsumption {
  consumption: number;
  defaultFuelGradeChangeNotRequired: boolean;
}

interface ISpeedAndConsumptions {
  ballast: ISpeedAndConsumption;
  laden: ISpeedAndConsumption;
  discharging: ISpeedAndConsumption;
  loading: ISpeedAndConsumption;
}

export const getSpeedAndConsumptionByLegType = (speedAndConsumptions: ISpeedAndConsumptions, legType: number) => {
  const mapping = {
    [LOAD.key]: speedAndConsumptions.loading,
    [DISCHARGE.key]: speedAndConsumptions.discharging,
    [BALLAST.key]: speedAndConsumptions.ballast,
    [LADEN.key]: speedAndConsumptions.laden,
  };

  return mapping[legType];
};

export const getSpeedAndConTypeIdByKey = (key: string) => {
  if (key) {
    const sandcKey = key.replace('Port', '').toLowerCase();
    if (sandcKey === 'working') {
      return PORT_WORKING;
    }
    const sandc = SpeedAndConsTypes.find((sc) => sc.key.toLowerCase() === sandcKey);
    return sandc;
  }
  return undefined;
};

export const isEcoSpeedAndConsumption = (type) =>
  type === ECO_LADEN || type === ECO_BALLAST || type === ECO_SAILING;
