import { veApi } from 'api';
import { CancelTokenSource } from 'axios';

export type CarbonFactor = {
  id: string,
  fuelTypeName: string,
  shortName: string,
  fuelGrade: number,
  factor: number,
  sortOrder: number,
};

export async function getCarbonFactor(
  cancelTokenSource: CancelTokenSource | null = null
): Promise<CarbonFactor> {
  const url = `/carbonfactor`;

  const response = await veApi.get(url, {
    cancelToken: cancelTokenSource && cancelTokenSource.token,
  });

  return response.data.map((apiFactor) => {
    return {
      id: apiFactor.id,
      fuelTypeName: apiFactor.fuelTypeName,
      shortName: apiFactor.shortName,
      fuelGrade: apiFactor.fuelGrade,
      factor: apiFactor.factor,
      sortOrder: apiFactor.sortOrder,
    };
  });
}
