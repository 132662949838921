import React from 'react';
import isFinite from 'lodash/isFinite';
import toNumber from 'lodash/toNumber';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import numbro from 'numbro';
import { trackEvent, eventDestination, trackChangeEvent } from 'diagnostics/calc-trackevents';
import VError from 'verror';
import classNames from 'classnames';
import {
  getValidationMessages,
  ValidationRuleDefinitionBase,
  ValidationRuleUsageParams,
  ValidationMessageBase,
} from 'components/validation';
import ValidationError from 'components/validation/ui/validation-error';
import stringEnum, { StringEnum } from 'utilities/enum/string-enum';

import './styles.scss';
import { WarningIcon } from 'components/icons';

type InputProps = {
  onFocus: ?Function<void>,
  onKeyDown: ?Function,
  onBlur: ?Function,
};

/**
 * null means 'nothing specified'.
 */
type AllowedValue = number | null;

type InputValue = {
  asText: string,
  asNullableNumber: AllowedValue,
  oldValue: AllowedValue,
};

const validationLevelsArray = ['error', 'guidance'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
export type ValidationLevel = 'error' | 'guidance';

export const validationLevels: StringEnum<ValidationLevel> = stringEnum(validationLevelsArray);

const validationDecorationStylesArray = ['borderOnly', 'borderAndLabel'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
export type ValidationDecorationStyle = 'borderOnly' | 'borderAndLabel';

export const validationDecorationStyles: StringEnum<ValidationLevel> = stringEnum(
  validationDecorationStylesArray
);

type ValidationMessage = ValidationMessageBase<
  InputValue,
  // See https://github.com/babel/babel-eslint/issues/485
  // eslint-disable-next-line no-use-before-define
  NumericInput,
>;
type ValidationRuleDefinition = ValidationRuleDefinitionBase<
  InputValue,
  // See https://github.com/babel/babel-eslint/issues/485
  // eslint-disable-next-line no-use-before-define
  NumericInput,
> & {
  shouldWarnWhileTyping: boolean,
  validationLevel?: ValidationLevel,
  validationDecorationStyle?: ValidationDecorationStyle,
};

export const AllNumericValidationRuleDefinitions: Array<ValidationRuleDefinition> = [
  {
    // Not getting a finite number from what the user typed (especially NaN) means it's a number 'in the making' (e.g. just a '-', or a '.', but also the word 'Infinity' if user is being rogue)
    propertyName: 'mustBeFiniteNumber',
    // This also means the rule is ON by default:
    defaultParamsWhenNoProperty: true,
    shouldFailureStopFurtherRules: true,
    // Don't warn prematurely - it's a partial number: '-' or '.'
    shouldWarnWhileTyping: false,
    validationLevel: validationLevels.error,
    isValid: (attemptedValueInfo: InputValue, ruleUsageParams: boolean, component: NumericInput) =>
      ruleUsageParams === false || isFinite(attemptedValueInfo.asNullableNumber),
    getAttemptedValueIsInvalidMessage: (
      attemptedValueInfo: InputValue,
      ruleUsageParams: boolean,
      component: NumericInput
    ) => `${component.props.fieldName || 'The value'} must be a number`,
  },
  {
    propertyName: 'minValue',
    // Don't warn while typing, because if the minValue is 2-digit, the user typing from scratch
    // will always start with a small number.
    // #TODODynamicShouldWarnWhileTyping - TODO this could be made dynamic - make shouldWarnWhileTyping=true only if minValue is <=0
    shouldWarnWhileTyping: false,
    validationLevel: validationLevels.error,
    expandParamShorthands: (ruleUsageParams) => ({
      ...ruleUsageParams,
      value: toNumber(typeof ruleUsageParams == 'object' ? ruleUsageParams.value : ruleUsageParams),
    }),
    isValid: (
      attemptedValueInfo: InputValue,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams,
      component: NumericInput
    ) => attemptedValueInfo.asNullableNumber >= ruleUsageParams.value,
    getAttemptedValueIsInvalidMessage: (
      attemptedValueInfo: InputValue,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams,
      component: NumericInput
    ) =>
      `${component.props.fieldName || 'The value'} cannot be smaller than ${
        ruleUsageParams.name ? `${ruleUsageParams.name} of ` : ''
      }${formatNumberForDisplay(ruleUsageParams.value)}`,
  },
  {
    propertyName: 'maxValue',
    // We can warn while typing, because all our maxValues are positive, so if user typed more than maxValue,
    // he should better stop typing already.
    // #TODODynamicShouldWarnWhileTyping - TODO this could be made dynamic - make shouldWarnWhileTyping=true only if maxValue is >=0 (or <0 and minus is not entered?)
    validationLevel: validationLevels.error,
    shouldWarnWhileTyping: true,
    expandParamShorthands: (ruleUsageParams) => ({
      ...ruleUsageParams,
      value: toNumber(typeof ruleUsageParams == 'object' ? ruleUsageParams.value : ruleUsageParams),
    }),
    isValid: (
      attemptedValueInfo: InputValue,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams,
      component: NumericInput
    ) => attemptedValueInfo.asNullableNumber <= ruleUsageParams.value,
    getAttemptedValueIsInvalidMessage: (
      attemptedValueInfo: InputValue,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams,
      component: NumericInput
    ) =>
      `${component.props.fieldName || 'The value'} cannot be greater than ${
        ruleUsageParams.name ? `${ruleUsageParams.name} of ` : ''
      }${formatNumberForDisplay(ruleUsageParams.value)}`,
  },
];

type ThresholdValueValidationRuleUsageParams =
  | (ValidationRuleUsageParams & {
      value: number,
      /**
       * Optional: A name by which the threshold value will be referred to in validation messages.
       * Useful, when the value is actually coming from another form field that the user typed - it could be
       * that the user will realize that it's the other field that is wrong.
       */
      name?: string,
    })
  | number;

type Props = {
  validationDecorationStyle?: ValidationDecorationStyle,
  isValidationMessageDismissable?: boolean,
  value: AllowedValue,
  mandatory?: boolean,
  className?: string,
  /**
   * Optional: A name by which this value should be referred to in messages, e.g. validation messages.
   */
  fieldName?: string,
  maxValue?: ThresholdValueValidationRuleUsageParams,
  maxLength?: number,
  minValue?: ThresholdValueValidationRuleUsageParams,
  maxDecimalDigits: ?number,
  onInputChange: (number) => void,
  inputProps?: InputProps,
  customValidationRules?: ValidationRuleDefinition[],
  validationOptions?: {
    keepInvalidTextForUserToCorrect?: boolean,
    propagateInvalidValueIfFiniteNumber?: boolean,
  },
  isReadonly?: boolean,
};
type State = {
  /**
   * Note that this `inputValue` property may hold a state that differs from this
   * component's `value` property.
   * This can be while user is typing, but also, when the number hasn't passed validation,
   * so the control is in a state of conveying to the user that he needs to correct it.
   */
  inputValue: InputValue,
  isFocus: boolean,
  hasUserTypedInCurrentFocus: boolean,

  validationMessages: Array<ValidationMessage>,
};

class NumericInput extends React.Component<Props, State> {
  constructor(props) {
    NumericInput.assertPropertiesValid(props);
    super(props);

    this.state = {
      inputValue: {
        asText: formatNumberForDisplay(props.value),
        asNullableNumber: props.value,
      },
      isFocus: false,
      hasUserTypedInCurrentFocus: false,
      validationMessages: [],
    };
  }

  inputElementRef = this.props.inputElementRef || React.createRef();

  get emptyValue() {
    return isFinite(this.props.emptyValue)
      ? toNumber(this.props.emptyValue)
      : toNumber(
          // this usage of minValue is not ideal, however it is kept to preserve
          // old behaviour and to avoid introducing any changes of behaviour.
          this.props.minValue || NumericInput.emptyValueFallback
        );
  }
  static assertPropertiesValid(props: object): void {
    const value = props.value;

    if (value !== null && !isFinite(value))
      throw new VError(
        {
          info: {
            valuePassed: value,
          },
        },
        'NumericInput control was passed bad data - value was not a number'
      );
  }

  // #TODOConsiderRemovingMinValueFallbackForEmptyNumericInputs - TODO: consider not doing this - prompt user to enter actual value and don't do any magic transforms
  setFallbackValueIfEmpty(): void {
    if (this.props.mandatory && this.props.value === null) {
      this.propagateNumberChange(parseInt(NumericInput.emptyValueFallbackString));
    }
  }

  get isFinishedEditing() {
    // Consider editing finished only when the number has passed validation and has been advertised
    // as the current `value` property. Otherwise, it means that editing hasn't finished (e.g. due to validation
    // not allowing the number).
    return this.props.value === this.state.inputValue.asNullableNumber;
  }

  handleFocus = async () => {
    const newState = {
      isFocus: true,
      hasUserTypedInCurrentFocus: false,
    };
    // Perform 'display formatting', but let's leave the current value if the user still needs to come back and finish editing.
    if (this.isFinishedEditing) {
      newState.inputValue = {
        asNullableNumber: this.props.value,
        asText: formatNumberForEditing(this.props.value),
      };
      newState.oldValue = this.props.value;
    }

    await this.setStateAsync(newState);

    // ensure that the component is in focus to avoid infinite
    // recursion
    if (this.state.isFocus) {
      this.inputElementRef.current.select();
    }
  };

  handleBlur = async (event: SyntheticInputEvent<HTMLInputElement>) => {
    const newState = {
      isFocus: false,
    };
    // Perform 'display formatting', but let's leave the current value if the user still needs to come back and finish editing.
    if (this.isFinishedEditing) {
      newState.inputValue = {
        asText: formatNumberForDisplay(this.props.value),
        asNullableNumber: this.props.value,
      };
    }
    if (this.state.hasUserTypedInCurrentFocus) {
      trackChangeEvent(this.props.diagnosticId, {
        oldValue: this.state.oldValue,
        newValue: this.state.inputValue.asNullableNumber,
      });
    }

    await this.setStateAsync(newState);

    if (!this.state.hasUserTypedInCurrentFocus) {
      return;
    }

    for (const ruleDefinition of this.state.validationMessages.map((_) => _.ruleDefinition)) {
      trackEvent(
        'numeric-input',
        `user-blur-left-value-invalid-on-${
          ruleDefinition.id ||
          ruleDefinition.propertyName ||
          'ruleDefinition-without-id-or-propertyName'
        }`,
        {},
        {},
        eventDestination.ANALYSIS
      );
    }
  };

  // #TODOConsiderRemovingMinValueFallbackForEmptyNumericInputs - TODO: consider not doing this - prompt user to enter actual value and don't do any magic transforms
  static emptyValueFallback = 0;
  static emptyValueFallbackString = formatNumber(NumericInput.emptyValueFallback, {
    thousandSeparated: false,
  });

  get allowedInputTextRegex(): RegExp {
    return new RegExp(
      `^${this.props.minValue >= 0 ? '' : '-?'}\\d*${
        isNil(this.props.maxDecimalDigits) || this.props.maxDecimalDigits > 0
          ? `(\\.\\d{0,${this.props.maxDecimalDigits}})?`
          : ''
      }`
    );
  }

  handleInputTextChange = async ({
    target: { value: attemptedValueText },
  }: SyntheticInputEvent<HTMLInputElement>) => {
    /**
     * Only calling this function will make the value actually show on the input. Without calling it here, the input never changes to new value. That's because it's a controlled component, so it needs to actually set the state, just like in [the example](https://reactjs.org/docs/forms.html#controlled-components)
     */
    const setInputValue = async (newValue: InputValue) =>
      await this.setStateAsync({
        inputValue: newValue,
        hasUserTypedInCurrentFocus: true,
      });

    attemptedValueText = attemptedValueText.trim(); // Trim especially to allow pasting with extra spaces. Prevention of this outside of pasting, that is, during normal typing, is handled by `allowedInputTextRegex`

    if (attemptedValueText === '') attemptedValueText = NumericInput.emptyValueFallbackString;

    const attemptedValueAsNullableNumber =
      attemptedValueText === '' ? null : toNumber(attemptedValueText);

    var allowedTextMatch = attemptedValueText.match(this.allowedInputTextRegex)[0];

    if (
      attemptedValueText !== allowedTextMatch &&
      isFinite(attemptedValueAsNullableNumber) &&
      attemptedValueAsNullableNumber === toNumber(allowedTextMatch)
    )
      // Support pasting a value with excessive 0s in the decimal or as padding of the integer - truncate them.
      attemptedValueText = allowedTextMatch;

    if (attemptedValueText !== allowedTextMatch) return; // Don't allow anything to change, inc. input value, as what was attempted contains some disallowed character

    const attemptedValueInfo: InputValue = {
      asText: attemptedValueText,
      asNullableNumber: attemptedValueAsNullableNumber,
    };

    const validationMessages = this.getValidationMessages(attemptedValueInfo);
    // Now we're dealing with a finite number
    if (
      validationMessages.filter((_) => _.validationLevel === validationLevels.error).length === 0
    ) {
      // Propagate if the value is valid according to all the rules
      await setInputValue(attemptedValueInfo);
      this.propagateNumberChange(attemptedValueAsNullableNumber);
    } else {
      // For an invalid value, we have options to tell us what to do with it
      const validationOptions = this.props.validationOptions || {};
      if (
        validationOptions.keepInvalidTextForUserToCorrect ||
        validationMessages.filter((_) => _.ruleDefinition.shouldWarnWhileTyping).length === 0
      ) {
        // Here our usage chose to leave the invalid value in, so that user can correct in the right place, rather than us guessing it was the last digit
        await setInputValue(attemptedValueInfo);

        if (
          validationOptions.propagateInvalidValueIfFiniteNumber &&
          isFinite(attemptedValueAsNullableNumber) // '-' or '.' can still get here
        ) {
          // Here our usage chose even propagate the invalid value, as long as it's a number
          this.propagateNumberChange(attemptedValueAsNullableNumber);
        }
      } else {
        // Otherwise, don't allow the new value at all - the component's user hasn't allowed to cap the user's typing
        return;
      }
    }
  };

  validateAndShowErrors = async () =>
    await this.showApplicableValidationErrors(this.getValidationMessages(this.state.inputValue));

  async showApplicableValidationErrors(allValidationMessages: Array<ValidationMessage>) {
    const validationMessages =
      this.state.isFocus && this.state.hasUserTypedInCurrentFocus
        ? allValidationMessages.filter((_) => _.ruleDefinition.shouldWarnWhileTyping)
        : allValidationMessages;

    if (!isEqual(this.state.validationMessages, validationMessages))
      await this.setStateAsync({
        validationMessages: validationMessages,
      });
  }

  getValidationMessages(attemptedValueInfo: InputValue): Array<ValidationMessage> {
    return getValidationMessages({
      ruleDefinitionsToTest: [
        ...AllNumericValidationRuleDefinitions,
        ...((this.props.customValidationRules &&
          this.props.customValidationRules.map((_) => ({
            defaultParamsWhenNoProperty: true,
            ..._,
          }))) ||
          []),
      ],
      attemptedValueInfo: attemptedValueInfo,
      component: this,
    });
  }

  isEmptyValue = () => this.props.value === this.emptyValue;

  propagateNumberChange(newValue: number): void {
    this.props.onInputChange && this.props.onInputChange(newValue);
  }

  componentDidMount() {
    this.setFallbackValueIfEmpty();
    this.validateAndShowErrors();
  }

  async componentDidUpdate(prevProps) {
    this.setFallbackValueIfEmpty();

    // Handle external change of the value - erase any edit-in-progress state of the input and just display the enforced new value
    if (
      prevProps.value !== this.props.value &&
      // But if the numeric interpretation of the input value is already the same, then it's just a sign of an internal edit triggerring the property change. Let's not disrupt the text value in this case (keep the caret position etc).
      this.props.value !== this.state.inputValue.asNullableNumber
    ) {
      await this.setStateAsync({
        inputValue: {
          asText: this.state.isFocus
            ? formatNumberForEditing(this.props.value)
            : formatNumberForDisplay(this.props.value),
          asNullableNumber: this.props.value,
        },
      });
    }

    const oldValidationErrors = this.state.validationMessages;
    const allNewValidationErrors = this.getValidationMessages(this.state.inputValue);
    await this.showApplicableValidationErrors(allNewValidationErrors);

    // Validation rules can change and can make a text we've been keeping from propagation suddenly valid.
    // Propagate such a number - this is the only sensible thing to do - user is not being told to fix anything, so he thinks the value is being consumed. Let's make it so then.
    if (
      oldValidationErrors.length !== 0 &&
      allNewValidationErrors.length === 0 &&
      // But a change from invalid to valid can also come from user typing the correct value, in which case the propagation will happen from `handleInputTextChange`. Let's not fire it twice.
      this.props.value !== this.state.inputValue.asNullableNumber
    )
      this.propagateNumberChange(this.state.inputValue.asNullableNumber);
  }

  render() {
    NumericInput.assertPropertiesValid(this.props);
    return (
      </* #RequiredContainerForHoveringElements - A containing HTML element is added around the `<input>`
         here to allow presenting elements like validation messages, tips, etc., which need to hover
         around it (taken out of document flow, AKA absolutely-positioned).
         Attempts of avoiding the container poses usage problems:
          * With flex: even though flex does not treat absolutely positioned elements as children to position, the result is not as desired because
            the start of flex container, not the neighbour, becomes the point of reference for the floating elements - see [this example](https://stackoverflow.com/questions/39673822/how-to-position-absolutely-a-div-within-a-flex-box-without-influencing-the-posit).
            In theory, one could use a 0 size, relative (so, non-absolute) sibling as a container for the hovering content, but it would
            be hard to position these elements on the flex' cross-axis, as they would start getting affected by the align-items.
            There's no way to prevent this, as the sibling will always be moved along the main-axis - [there's no way stack elements on the cross axis](https://stackoverflow.com/questions/41790378/css-flexbox-group-2-flex-items).
            There also seems to be no other way of having elements ignored by flex - see [this discussion](https://stackoverflow.com/questions/39839770/make-flex-element-ignore-child-element)). */

      span
        className={classNames('calc-input__container', this.props.className)}
      >
        <input
          value={this.state.inputValue.asText}
          onChange={this.handleInputTextChange}
          onBlur={getCallAllFn(
            this.props.inputProps && this.props.inputProps.onBlur,
            this.handleBlur
          )}
          onFocus={getCallAllFn(
            this.props.inputProps && this.props.inputProps.onFocus,
            this.handleFocus
          )}
          onKeyDown={this.props.inputProps && this.props.inputProps.onKeyDown}
          className={classNames('numeric-input', {
            'numeric-input--empty-value-in-mandatory-field':
              this.props.mandatory && this.isEmptyValue(),
            'calc-input--guidance': !!this.state.validationMessages.some(
              (_) => _.validationLevel === validationLevels.guidance
            ),
            'calc-input--invalid': !!this.state.validationMessages.some(
              (_) => _.validationLevel === validationLevels.error
            ),
            'numeric-input--readonly': this.props.isReadonly,
          })}
          data-testid={this.props['data-testid']}
          ref={this.inputElementRef}
          maxLength={this.props.maxLength}
          disabled={this.props.isReadonly}
        />
        {this.state.isFocus && !!this.state.validationMessages.length && (
          <div className="calc-input__validation_messages">
            {this.state.validationMessages.map((validationMessage) => (
              <ValidationError
                /* Use the message text, because we don't have a digest id over all the things that vary as messages can be produced by multi-parameter functions specified in options. */
                key={validationMessage.message}
                validationLevel={validationMessage.validationLevel}
                isDismissable={this.props.isValidationMessageDismissable}
              >
                {validationMessage.message}
              </ValidationError>
            ))}
          </div>
        )}
        {this.props.validationDecorationStyle === validationDecorationStyles.borderAndLabel && (
          <div
            className={classNames('calc-input_invalid_status_container', {
              'calc-input_invalid_status_container--hidden':
                this.state.isFocus || !this.state.validationMessages.length,
              'calc-input_invalid_status_container--guidance': this.state.validationMessages.some(
                (_) => _.validationLevel === validationLevels.guidance
              ),
              'calc-input_invalid_status_container--error': this.state.validationMessages.some(
                (_) => _.validationLevel === validationLevels.error
              ),
            })}
          >
            <label className="calc-input_invalid_status_label">Invalid</label>
            <WarningIcon className="calc-input_invalid_status_icon" />
          </div>
        )}
      </span>
    );
  }

  setStateAsync(newState) {
    // Source https://github.com/facebook/react/issues/2642#issuecomment-300892305
    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  }
}

function getCallAllFn(...fns: Array<Function>): Function {
  return function () {
    for (const fn of fns) {
      if (fn) fn.apply(this, arguments);
    }
  };
}

function formatNumberForDisplay(number: number): string {
  return formatNumber(number, {
    thousandSeparated: true,
  });
}

function formatNumberForEditing(number: number): string {
  return formatNumber(number, {
    thousandSeparated: false,
  });
}

function formatNumber(value: string, { thousandSeparated }): string {
  if (value === null) return '';
  return numbro(value).format({ thousandSeparated });
}

export default NumericInput;
