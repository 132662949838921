import {
  SET_COMPARISON_TABLE_SETTINGS,
  UPDATE_COMPARISON_TABLE_SETTINGS,
  ADJUST_COLUMNS_TO_VIEW_RATES,
} from 'constants/action-types/worksheet-comparison';
import { comparisonTableInitialColumnsState } from '../../../constants/enums/worksheet-comparison';

const initialState: IComparisonTableSettings = {
  columnsState: comparisonTableInitialColumnsState,
};

export const comparisonTableSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPARISON_TABLE_SETTINGS:
      const { columnsState } = initialState;

      const preservedComparisonTableState = action.payload.data?.length
        ? action.payload.data
        : columnsState;

      preservedComparisonTableState.forEach((newColumn) => {
        const originalColumn = columnsState.find((item) => item.columnId === newColumn.columnId);
        if (originalColumn) {
          originalColumn.state = newColumn.state;
        }
      });

      const marketSegmentAdjustedColumns =
        action.payload.marketSegmentId === 'dryCargo'
          ? columnsState.filter((column) => column.columnId !== 'VD_Sailing')
          : columnsState;

      const initialColumnsState = marketSegmentAdjustedColumns.map((column) => {
        if (column.parentId === '') {
          const childrenColumns = marketSegmentAdjustedColumns.filter(
            (col) => col.parentId === column.columnId
          );

          const checked = childrenColumns.every((el) => el.state === true);
          const unchecked = childrenColumns.every((el) => el.state === false);
          const indeterminate =
            childrenColumns.some((el) => el.state === true) &&
            childrenColumns.some((el) => el.state === false);

          if (checked) {
            return { ...column, state: true };
          }
          if (unchecked) {
            return { ...column, state: false };
          }
          if (indeterminate) {
            return { ...column, state: 'INDETERMINATE' };
          }
        }
        return column;
      });

      return {
        ...state,
        columnsState: initialColumnsState,
      };

    case UPDATE_COMPARISON_TABLE_SETTINGS:
      return {
        ...state,
        columnsState: action.payload,
      };

    case ADJUST_COLUMNS_TO_VIEW_RATES:
      const columnsToShow = () => {
        if (action.payload === 'Voyage to Time Charter') {
          return state.columnsState.map((column) =>
            (column.parentId === 'VR' || column.columnId === 'VR') && column.state === false
              ? { ...column, state: true }
              : column
          );
        } else if (action.payload === 'Time Charter to Voyage') {
          return state.columnsState.map((column) =>
            (column.parentId === 'TC' || column.columnId === 'TC') && column.state === false
              ? { ...column, state: true }
              : column
          );
        }
      };
      return {
        ...state,
        columnsState: columnsToShow(),
      };
    default:
      return state;
  }
};
