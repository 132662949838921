import stringEnum, { StringEnum } from 'utilities/enum/string-enum';
const marketSegmentIdsArray = ['undefined', 'dryCargo', 'wetCargo', 'Tankers'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
export type MarketSegmentId = 'undefined' | 'dryCargo' | 'wetCargo' | 'tankers';

export const marketSegmentIds: StringEnum<OperationalRestrictionId> =
  stringEnum(marketSegmentIdsArray);

export function getMarketSegmentForNewWorkbook() {
  return marketSegmentIds.dryCargo;
}

export function getMarketSegmentsDisplayItems() {
  return [
    {
      key: marketSegmentIds.dryCargo,
      label: 'Dry Cargo',
      iconSymbol: 'D',
      background: '#ffcb00', //$maritech-yellow, $clarksons-yellow
      color: 'white',
    },
    {
      key: marketSegmentIds.wetCargo,
      label: 'Wet Cargo',
      iconSymbol: 'W',
      background: '#0086b3', //$maritech-blue-darkest, $clarksons-blue-darkest
      color: 'white',
    },
    {
      key: marketSegmentIds.Tankers,
      label: 'Wet Cargo Lite',
      iconSymbol: 'W',
      background: '#ec407a', //$maritech-pink, $clarksons-pink
      color: 'white',
    },
  ];
}

export function findDisplayItemByMarketSegmentId(marketSegmentId) {
  return !marketSegmentId
    ? getMarketSegmentsDisplayItems()[0]
    : getMarketSegmentsDisplayItems().find((segment) => segment.key === marketSegmentId);
}

export function isUndefined(marketSegmentId: MarketSegmentId) {
  return marketSegmentId === marketSegmentIds.undefined || marketSegmentId === undefined;
}

export function isDry(marketSegmentId: MarketSegmentId) {
  return marketSegmentId === marketSegmentIds.dryCargo;
}

export function isWet(marketSegmentId: MarketSegmentId) {
  return marketSegmentId === marketSegmentIds.wetCargo;
}
export function isTanker(marketSegmentId: MarketSegmentId) {
  return marketSegmentId === marketSegmentIds.Tankers;
}
