import * as actionTypes from 'constants/action-types/intake-calculator';
import { buildAction } from 'utilities/redux';
import isEmpty from 'lodash/isEmpty';
import { getVesselById } from 'api/clients/vessel';
import type { MarketSegmentId } from 'constants/market-segments';
import { Vessel } from 'modules/vessel/vessel-detail/types';
import { METERS, convertDraft } from 'constants/enums/draft-units';
import { CUBIC_METERS, convertGrain } from 'constants/enums/grain-units';
import { TPC, convertImmersion } from 'constants/enums/immersion-units';
import { calculateMaxLiftFromApi as calcMaxLiftFromApi } from 'modules/calculate-intake-max-lift';

export const vesselChanged = ({
  vessel: { vesselId, vesselName },
  oldVessel,
  marketSegmentId,
  selectedUnits,
  maxLiftInput,
}: {
  vessel: Vessel,
  oldVessel: string,
  marketSegmentId: MarketSegmentId,
  selectedUnits: any,
  maxLiftInput: any,
}) => {
  return async (dispatch) => {
    if (vesselId !== 0 && isEmpty(vesselName) === false && vesselId !== oldVessel.vesselId) {
      dispatch(vesselIdNameChanged(vesselId, vesselName));
      try {
        const vesselDto = await getVesselById({
          vesselId: vesselId,
          marketSegmentId: marketSegmentId,
        });
        vesselDto.draftUnit = selectedUnits.draftUnit.key;
        vesselDto.grainUnit = selectedUnits.grainUnit.key;
        vesselDto.immersionUnit = selectedUnits.immersionUnit.key;

        // vessel api returns values in default units i.e  draft (METERS) , grain (CUBIC_METERS), tpcmi (TPC)
        // when user selectes different unit(s) then the below conversion needs to be applied
        if (METERS.key !== selectedUnits.draftUnit.key) {
          vesselDto.draft = convertDraft(vesselDto.draft, METERS, selectedUnits.draftUnit);
        }
        if (CUBIC_METERS.key !== selectedUnits.grainUnit.key) {
          vesselDto.grain = convertGrain(
            vesselDto.grain,
            CUBIC_METERS,
            selectedUnits.grainUnit.key
          );
        }
        if (TPC.key !== selectedUnits.immersionUnit.key) {
          vesselDto.tpcmi = convertImmersion(vesselDto.tpcmi, TPC, selectedUnits.immersionUnit.key);
        }

        maxLiftInput.deadweight = vesselDto.deadweight;
        maxLiftInput.grain = vesselDto.grain;
        maxLiftInput.grainUnit = vesselDto.draftUnit;
        maxLiftInput.immersion = vesselDto.tpcmi;
        maxLiftInput.immersionUnit = vesselDto.immersionUnit;
        maxLiftInput.vesselDraft = vesselDto.draft;
        maxLiftInput.vesselDraftUnit = vesselDto.draftUnit;
        const maxLiftResponse = await calcMaxLiftFromApi(maxLiftInput);
        dispatch(
          buildAction(actionTypes.INTAKE_CALCULATOR_CALCULATE_MAX_LIFT_FROM_API, maxLiftResponse)
        );

        return await dispatch(setIntakeCalculatorVessel(vesselDto));
      } catch (error) {
        handleError(dispatch, error);
      }
    }
  };
};

const vesselIdNameChanged = (vesselId, vesselName) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_VESSEL_ID_NAME_CHANGED, {
    vesselId,
    vesselName,
  });

const setIntakeCalculatorVessel = (vesselDto) => (dispatch) => {
  const { deadweight, draft, tpcmi, grain, draftUnit, grainUnit, immersionUnit } = vesselDto;

  const action = buildAction(actionTypes.INTAKE_CALCULATOR_VESSEL_CHANGED, {
    deadweight,
    draft,
    tpcmi,
    grain,
    draftUnit,
    grainUnit,
    immersionUnit,
  });

  dispatch(action);
};

const handleError = (dispatch, error) => {
  const action = buildAction(actionTypes.INTAKE_CALCULATOR_VESSEL_CHANGED, {
    deadweight: 0,
    draft: 0,
    tpcmi: 0,
    grain: 0,
  });

  dispatch(action);
};

export const deadweightChanged = (deadweight) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_DEADWEIGHT_CHANGED, deadweight);

export const vesselDraftChanged = (draft) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_VESSEL_DRAFT_CHANGED, draft);

export const vesselDraftUnitChanged = (payload) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_VESSEL_DRAFT_UNIT_CHANGED, {
    ...payload,
    draftUnit: payload.draftUnit.key,
  });

export const grainChanged = (grain) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_GRAIN_CHANGED, grain);

export const grainUnitChanged = (payload) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_GRAIN_UNIT_CHANGED, {
    ...payload,
    grainUnit: payload.grainUnit.key,
  });

export const tpcmiChanged = (tpcmi) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_TPCMI_CHANGED, tpcmi);

export const immersionUnitChanged = (payload) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_IMMERSION_UNIT_CHANGED, {
    ...payload,
    immersionUnit: payload.immersionUnit.key,
  });

export const cargoChanged = (cargo) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_CARGO_CHANGED, cargo);

export const stowageChanged = (stowage) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_STOWAGE_CHANGED, stowage);

export const stowageUnitChanged = (payload) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_STOWAGE_UNIT_CHANGED, {
    ...payload,
    stowageUnit: payload.stowageUnit.key,
  });

export const specificGravityChanged = (specificGravity) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_SPECIFIC_GRAVITY_CHANGED, specificGravity);

export const portChanged = (port) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_PORT_CHANGED, {
    ...port,
    draftUnit: port.draftUnit.key,
  });

export const portDraftChanged = (draft) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_PORT_DRAFT_CHANGED, draft);

export const portDraftUnitChanged = (payload) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_PORT_DRAFT_UNIT_CHANGED, {
    ...payload,
    draftUnit: payload.draftUnit.key,
  });

export const salinityChanged = (salinity) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_SALINITY_CHANGED, salinity);

export const salinityFactorChanged = (salinityFactor) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_SALINITY_FACTOR_CHANGED, salinityFactor);

export const constantsChanged = (constants) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_CONSTANTS_CHANGED, constants);

export const bunkersOnBoardChanged = (bunkersOnBoard) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_BUNKERS_ON_BOARD_CHANGED, bunkersOnBoard);

export const freshWaterChanged = (freshWater) =>
  buildAction(actionTypes.INTAKE_CALCULATOR_FRESH_WATER_CHANGED, freshWater);

export const calculateMaxLiftFromApi = (payload) => async (dispatch) => {
  const maxLiftResponse = await calcMaxLiftFromApi(payload);
  dispatch(buildAction(actionTypes.INTAKE_CALCULATOR_CALCULATE_MAX_LIFT_FROM_API, maxLiftResponse));
};

export const clearAll = () => buildAction(actionTypes.INTAKE_CALCULATOR_CLEAR_ALL);

export const setIntakeCalculatorFromApi = (payload) => async (dispatch) => {
  const maxLiftResponse = await calcMaxLiftFromApi(payload.maxLiftInput);
  const data = { ...payload, maxLiftResponse: maxLiftResponse };
  dispatch(buildAction(actionTypes.SET_INTAKE_CALCULATOR_FROM_API, data));
};
