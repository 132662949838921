import userContext from 'user-context';

import {
  SWITCH_COMPARISON_VIEW_MODE,
  SET_COLUMNS_DEFS,
} from 'constants/action-types/worksheet-comparison';

import { calculateEstimate } from 'middleware/worksheet-afterware/calculate-estimate';
import { saveWorkbook } from 'api/clients/workbook';
import { buildAction } from 'utilities/redux';
import { SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE } from 'constants/action-types/workbook';

export const setSpeedAndConsumptionsMode =
  (workbook: IWorkbookViewModel, speedAndConsumptionsMode: SpeedAndConsumptionsMode) =>
  async (dispatch) => {
    if (workbook.userId === userContext.systemUserId) {
      await saveWorkbook({
        ...workbook,
        speedAndConsumptionsMode: speedAndConsumptionsMode,
      });
    }

    dispatch(
      buildAction(SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE, {
        workbookId: workbook.id,
        speedAndConsumptionsMode: speedAndConsumptionsMode,
      })
    );
  };

export const runCalculateEstimateForWorksheetIds = (worksheetIds: UUID[]) => async (dispatch) =>
  await Promise.all(worksheetIds.map((worksheetId) => dispatch(calculateEstimate(worksheetId))));

export const switchViewMode = (mode) => async (dispatch) => {
  dispatch({
    type: SWITCH_COMPARISON_VIEW_MODE,
    payload: mode,
  });
};

export const setColumnsDefs = (columns) => ({
  type: SET_COLUMNS_DEFS,
  payload: columns,
});
