import React from 'react';

const MovingSpeedAndConsHeader = () => {
  return (
    <div className="table-header">
      <span className="speed-and-cons-multi-vessel__moving--type">Sea Status</span>
      <span className="speed-and-cons-multi-vessel__moving--info" />
      <span className="speed-and-cons-multi-vessel__moving--speed">Speed (K)</span>
      <span className="speed-and-cons-multi-vessel__moving--consumption">Con (MT/d)</span>
      <span className="status-specific-fuel-grade">Fuel Grade</span>
      <span className="speed-and-cons-multi-vessel__moving--generator">Gen (MT/d)</span>
      <span className="status-specific-fuel-grade">Fuel Grade</span>
    </div>
  );
};

export default MovingSpeedAndConsHeader;
