import guid, { emptyGuid } from 'utilities/guid';
import { WORKING } from 'modules/voyage/gear-types';
import { SALT } from 'constants/enums/salinity-types';
import { TimeUnitEnum } from 'constants/enums/time';
import { METRIC_TONNES_PER_DAY } from 'modules/voyage/load-discharge-rate-unit-types';
import { DEFAULT_FACTOR } from 'modules/voyage/components/working-day-dropdown/working-day-factors';
import * as setUtils from 'utilities/set/set-utils';
import { doesSubtotalContainSeca, sumRoutingApiDistancesBy } from './subtotal-utils';
import {
  operationalRestrictionIds,
  allPossibleOperationalRestrictionSets,
} from 'constants/operational-restrictions';
import * as numberUtilities from 'utilities/number';
import { distributeUserEditedDistances } from './user-edited-voyage-leg-distances-distributor';
import { LOAD, } from 'constants/enums/voyage-leg';
import { marketSegmentIds } from 'constants/market-segments';
import { getSpeedAndConsumptionByLegType } from "../../../constants/enums/speed-and-con-types";

export function VoyageLegBuilder(typeKey = LOAD.key) {
  let voyageLeg = {
    id: guid(),
    type: typeKey,
    locationId: emptyGuid,
    name: '',
    zone: '',
    country: '',
    longitude: null,
    latitude: null,
    gear: WORKING.key,
    draft: 0,
    salinity: SALT.key,
    cargoId: emptyGuid,
    cargoQuantity: 0,
    loadDischargeRate: 0,
    loadDischargeConsumptionsOverrideQty: null,
    rateUnit: METRIC_TONNES_PER_DAY.key,
    workingDayType: DEFAULT_FACTOR.id,
    workingDayMultiplier: DEFAULT_FACTOR.factor,
    delay: 0,
    delayUnit: TimeUnitEnum.HOURS.key,
    turnAroundTime: 0,
    turnAroundTimeUnit: TimeUnitEnum.HOURS.key,
    weatherFactor: 0,
    portCost: 0,
    portOperationalRestrictionIds: new Set(),
    inboundRoute: {
      variants: [createNoSailingInboundRouteVariant()],
    },
  };

  return {
    type(legType: { key: number, label: string }) {
      voyageLeg.type = legType.key;
      return this;
    },
    id(id) {
      voyageLeg.id = id;
      return this;
    },
    cargoId(cargoId) {
      voyageLeg.cargoId = cargoId;
      return this;
    },
    gear(key: number) {
      voyageLeg.gear = key;
      return this;
    },
    waypointsToAvoidLocationIds(waypointsToAvoidLocationIds: Array<LocationId>) {
      for (const inboundRouteVariant of voyageLeg.inboundRoute.variants) {
        inboundRouteVariant.waypoints = waypointsToAvoidLocationIds.map(
          (waypointToAvoidLocationId) => ({
            locationId: waypointToAvoidLocationId,
            avoid: true,
            unavoidable: false,
          })
        );
      }
      return this;
    },
    rateUnit(rateUnit) {
      voyageLeg.rateUnit = rateUnit;
      return this;
    },
    weatherFactor(marketSegmentId: string) {
      if (
        marketSegmentId === marketSegmentIds.wetCargo ||
        marketSegmentId === marketSegmentIds.Tankers
      ) {
        voyageLeg.weatherFactor = 5;
      }
      return this;
    },
    withPortConsumption(speedAndConsumptions: ISpeedAndConsumptions) {
      const speedAndConsumption = getSpeedAndConsumptionByLegType(speedAndConsumptions, voyageLeg.type);
      voyageLeg.loadDischargeConsumptionsOverrideQty = speedAndConsumption.consumption;
      return this;
    },
    build() {
      return voyageLeg;
    },
  };
}

export function createNoSailingInboundRouteVariant(): InboundRouteVariantViewModel &
  RouteDistancesOfNoSailing &
  EmptyRouteDetails {
  return {
    ...createEmptyRouteViewModel(),
    fromLocationGeoCoords: null,
    // TODO consider consolidating with `createEmptyRouteViewModel`, ensuring both have the same behaviour
    subtotalsByOperationalRestrictionIds: createEmptySubtotalsByOperationalRestrictionIds(),
  };
}

export function createEmptySubtotalsByOperationalRestrictionIds() {
  return [
    {
      operationalRestrictionIds: new Set(),
      userEditedDistance: 0,
      routingApiDistance: 0,
    },
    {
      operationalRestrictionIds: new Set([operationalRestrictionIds.seca]),
      userEditedDistance: 0,
      routingApiDistance: 0,
    },
    {
      operationalRestrictionIds: new Set([operationalRestrictionIds.sludgeDischargeBan]),
      userEditedDistance: 0,
      routingApiDistance: 0,
    },
    {
      operationalRestrictionIds: new Set([
        operationalRestrictionIds.sludgeDischargeBan,
        operationalRestrictionIds.seca,
      ]),
      userEditedDistance: 0,
      routingApiDistance: 0,
    },
  ];
}

export function createEmptyRouteViewModel(graphVersion?: number): RouteViewModel {
  return mapRouteRichInfoToRouteViewModel({
    path: [],
    waypoints: [],
    subtotalsByOperationalRestrictionIds: [],
    routeAsSequenceOfDistancesThroughOperationalRestrictions: [],
    routeRetrievedFromRoutingApiOn: graphVersion ? new Date() : null,
    routeRetrievedWithGraphVersion: graphVersion || null,
  });
}

export function mapRouteRichInfoToRouteViewModel({
  path,
  waypoints,
  subtotalsByOperationalRestrictionIds,
  routeAsSequenceOfDistancesThroughOperationalRestrictions,
  routeRetrievedFromRoutingApiOn,
  routeRetrievedWithGraphVersion,
}: RouteRichInfo): RouteViewModel {
  return {
    routeRetrievedFromRoutingApiOn: routeRetrievedFromRoutingApiOn,
    routeRetrievedWithGraphVersion: routeRetrievedWithGraphVersion,
    waypoints: waypoints.map((routeWaypoint: RouteCalcResultWaypoint) => ({
      locationId: routeWaypoint.locationId,
      avoid: false,
    })),
    ...mapDistanceSubtotalsToViewModelWithAgregateDistances(subtotalsByOperationalRestrictionIds),
    path: path,
    routeAsSequenceOfDistancesThroughOperationalRestrictions:
      routeAsSequenceOfDistancesThroughOperationalRestrictions,
  };

  /**
    This function's usage is needed (instead of just having View Model 'purely calculated' from `subtotalsByOperationalRestrictionIdsViewmodels`) because the extra 'agregate distances' (`totalDistance` and `totalSecaDistance` at the time of writing) are rounded to integers and are even saved separately as a way to avoid having to round them when they are displayed or consumed (because they aren't rounded at the consumption they would otherwise often display a long tail of 0.000000000000001).
  */
  function mapDistanceSubtotalsToViewModelWithAgregateDistances(
    subtotalsByOperationalRestrictionIds
  ) {
    subtotalsByOperationalRestrictionIds = fillMissingSubtotalsByOperationalRestrictionIds(
      subtotalsByOperationalRestrictionIds
    );
    const subtotalsByOperationalRestrictionIdsViewModels = subtotalsByOperationalRestrictionIds.map(
      (subtotal) => {
        return {
          routingApiDistance: subtotal.distance,
          userEditedDistance: subtotal.distance,
          operationalRestrictionIds: subtotal.operationalRestrictionIds,
        };
      }
    );

    const totalSecaDistance = numberUtilities.round(
      sumRoutingApiDistancesBy(
        subtotalsByOperationalRestrictionIdsViewModels,
        doesSubtotalContainSeca
      )
    );

    const totalDistance = numberUtilities.round(
      sumRoutingApiDistancesBy(
        subtotalsByOperationalRestrictionIdsViewModels,
        (_) => true //Sum all subtotals
      )
    );

    //When deriving the total distances from the subtotals, it is possible for the derived totals to be fractional numbers.
    //However, for display purposes the totals are rounded up. To keep the subtotals consistent the newly rounded totals,
    //a distribution is performed with the rounded totals.
    const adjustedSubtotalsByOperationalRestrictionIds = distributeUserEditedDistances(
      totalSecaDistance,
      totalDistance,
      subtotalsByOperationalRestrictionIdsViewModels
    );

    return {
      totalDistance: totalDistance,
      secaDistance: totalSecaDistance,
      subtotalsByOperationalRestrictionIds: adjustedSubtotalsByOperationalRestrictionIds,
    };

    function fillMissingSubtotalsByOperationalRestrictionIds(
      subtotalsByOperationalRestrictionIds: ISubtotalByOperationalRestrictionIds[]
    ): ISubtotalByOperationalRestrictionIds[] {
      const missingOperationalRestrictionIdsSets = allPossibleOperationalRestrictionSets.filter(
        (operationalRestrictionIdsSet) =>
          !subtotalsByOperationalRestrictionIds.some((subtotal) =>
            setUtils.equals(subtotal.operationalRestrictionIds, operationalRestrictionIdsSet)
          )
      );

      const missingSubtotals = missingOperationalRestrictionIdsSets.map(
        (missingOperationRestrictionIdsSet) => ({
          operationalRestrictionIds: missingOperationRestrictionIdsSet,
          distance: 0,
        })
      );

      return subtotalsByOperationalRestrictionIds.concat(missingSubtotals);
    }
  }
}
