import React, { Component } from 'react';
import axios, { AxiosResponse } from 'axios';
import debounce from 'lodash/debounce';
import { debounceDefaults } from 'constants/defaults/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { veApi } from 'api';
import Downshift from 'downshift';
import { User, UserMenuItem } from './types';
import ProgressBar from 'components/progress-bar';
import Size from 'components/progress-bar/size';
import UserPhoto from 'components/user-photo';
import { ErrorPanel } from 'components/error-panel';
import Dropdown from 'components/dropdown';

import './styles.scss';

export const ACTIVE_USER = { key: true, label: 'Active Users' };
export const INACTIVE_USER = { key: false, label: 'Inactive Users' };

const UserTypes = [ACTIVE_USER, INACTIVE_USER];

type Props = {
  value: User,
  onChange: (?UserMenuItem) => void,
};

type State = {
  foundOptions: Array<UserMenuItem>,
  isLoading: boolean,
  isError: boolean,
  selectedUserType: { key: boolean, label: string },
  currentSearch: string,
};

class UserAutoComplete extends Component<Props, State> {
  state: State = {
    foundOptions: [],
    isLoading: false,
    isError: false,
    selectedUserType: ACTIVE_USER,
    currentSearch: '',
  };
  cancelTokenSource: CancelTokenSource = null;
  cache: Object = {};

  promptText = 'Enter first or last name or email';
  itemToString = (item: UserMenuItem | null) => {
    if (isNil(item)) {
      return '';
    }
    return `${item.firstName || ''} ${item.lastName || ''}`; // Need null fallbacks because there may be some non-human accounts showing here (e.g. SYS-SQL-SEANET with null as last name)
  };

  async getOptionsForSearchTerm(
    searchTerm: string,
    isEnabled = true,
    cancelToken
  ): Array<UserMenuItem> {
    const response: AxiosResponse = await veApi.get(
      `/user/?searchTerm=${searchTerm}&isEnabled=${isEnabled}`,
      {
        cancelToken: cancelToken,
      }
    );

    return response.data.map((_) => ({
      id: _.systemUserId,
      userName: _.accountName,
      firstName: _.firstName,
      lastName: _.lastName,
    }));
  }

  fetchAndPopulateOptions = debounce(
    async (searchTerm: string, isEnabled: boolean, cancelToken): void => {
      try {
        const searchParamsAsString = JSON.stringify({ searchTerm, isEnabled });
        const foundOptions =
          this.cache[searchParamsAsString] ||
          (this.cache[searchParamsAsString] = await this.getOptionsForSearchTerm(
            searchTerm,
            isEnabled,
            cancelToken
          ));

        cancelToken.throwIfRequested(); // We might get called asynchronously ourselves, so watch the token even if we use caching (happens in tests where we unmount heavily)
        this.setState({
          foundOptions,
        });
      } catch (e) {
        if (axios.isCancel(e)) return;
        this.setState({
          isError: true,
        });

        throw e;
      } finally {
        if (!cancelToken.reason)
          this.setState({
            isLoading: false,
          });
      }
    },
    debounceDefaults.wait,
    {
      leading: debounceDefaults.leading,
      maxWait: debounceDefaults.maxWait,
    }
  );

  onInputChange = (value: string, { closeMenu }) => {
    this.setState({
      currentSearch: value,
    });

    if (this.cancelTokenSource !== null) {
      this.cancelTokenSource.cancel({ isUserCancellation: true });
    }

    if (isEmpty(value)) {
      const timeoutHandle = setTimeout(closeMenu, 0); // Close the menu when user erases everything, as no search is happenning. `setTimeout` is needed as otherwiser it doesn't work immediately :(  ( possibly Downshift opens the menu on any value change, in the same handler)
      this.cleanupTasks.push(() => clearTimeout(timeoutHandle));
      return;
    }

    const cancelTokenSource = (this.cancelTokenSource = axios.CancelToken.source());
    this.cleanupTasks.push(() => cancelTokenSource.cancel());
    this.setState({
      isLoading: true,
    });

    this.fetchAndPopulateOptions(value, this.state.selectedUserType.key, cancelTokenSource.token);
  };

  onDropdownValueChange = (value: { key: boolean, label: string }) => {
    this.setState({
      selectedUserType: value,
    });

    if (this.cancelTokenSource !== null) {
      this.cancelTokenSource.cancel({ isUserCancellation: true });
    }

    if (isEmpty(this.state.currentSearch)) {
      return;
    }

    const cancelTokenSource = (this.cancelTokenSource = axios.CancelToken.source());
    this.cleanupTasks.push(() => cancelTokenSource.cancel());
    this.setState({
      isLoading: true,
    });

    this.fetchAndPopulateOptions(this.state.currentSearch, value.key, cancelTokenSource.token);
  };

  render() {
    return (
      <>
        <Dropdown
          id="user-type"
          items={UserTypes}
          className={'user-autocomplete dropdown'}
          selectedItem={this.state.selectedUserType}
          onChange={this.onDropdownValueChange}
          diagnosticId="UserAutocomplete/UserType"
          asLabel
        />
        <Downshift
          onChange={this.changeValue}
          onInputValueChange={this.onInputChange}
          selectedItem={this.props.value}
          itemToString={this.itemToString}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            highlightedIndex,
            inputValue,
            selectHighlightedItem,
            openMenu,
            closeMenu,
          }) => (
            <div className="user-autocomplete">
              <input
                {...getInputProps({
                  placeholder: this.promptText,
                  onBlur: (event) => this.onBlurHandler(event),
                  onFocus: (event) => event.target.select(), // Select all input text to make it easy to type from scratch - Downshift doesn't offer this out-of-the-box
                  onKeyDown: (event) => {
                    if (event.key === 'Tab') {
                      selectHighlightedItem();
                    }
                  },
                })}
                className="user-autocomplete__input"
              />
              {isOpen && (
                <div className="user-autocomplete__menu" role="listbox">
                  {this.state.isLoading ? (
                    <ProgressBar size={Size.MEDIUM} />
                  ) : this.state.isError ? (
                    <div
                      hidden // TODO remove this when the appearance is approved. See #ErrorsHiddenUntilApproved for other places affected. For context see https://clarksonscloud.visualstudio.com/Voyage-Estimation/_workitems/edit/15112
                    >
                      <ErrorPanel className="user-autocomplete__menu-error">
                        Sorry. There's been a problem with your search. Please check your
                        connection, try again, try refreshing the page. If the problem persists,
                        contact support.
                      </ErrorPanel>
                    </div>
                  ) : (
                    this.renderFoundOptions(getItemProps, highlightedIndex)
                  )}
                </div>
              )}
            </div>
          )}
        </Downshift>
      </>
    );
  }

  renderFoundOptions = (getItemProps, highlightedIndex) =>
    this.state.foundOptions.length === 0 ? (
      <div className="user-autocomplete__menu-nothing-found-notice">
        There are no users who match this search term. Please check the search term.
      </div>
    ) : (
      this.state.foundOptions.map((option, index) =>
        this.renderOption(option, index, highlightedIndex, getItemProps)
      )
    );

  renderOption = (option, index, highlightedIndex, getItemProps) => (
    <div
      {...getItemProps({ item: option })}
      key={option.id}
      className={`user-autocomplete__menu-item
          ${highlightedIndex === index ? 'user-autocomplete__menu-item--highlighted' : ''}`}
    >
      <UserPhoto userName={option.userName} className="user-autocomplete__menu-item-photo" />
      <div className="user-autocomplete__menu-item-name">{this.itemToString(option)}</div>
    </div>
  );

  onBlurHandler(event: SyntheticFocusEvent<HTMLInputElement>): void {
    // Ability to clear value (on clear text & tab-out/click-out) - Downshift doesn't offer this out-of-the-box - https://github.com/downshift-js/downshift/issues/177
    if (isEmpty(event.target.value) && this.props.value !== null) {
      this.changeValue(null);
    }
  }

  componentWillUnmount() {
    for (const cleanupTask of this.cleanupTasks) {
      cleanupTask();
    }
  }

  cleanupTasks: Array<() => void> = [];

  changeValue = (newItem: ?UserMenuItem) => {
    this.props.onChange(newItem);
  };
}

export default UserAutoComplete;
