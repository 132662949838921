import { createSelector } from 'reselect';
import findIndex from 'lodash/findIndex';
import { bunkersOnBoardId, bunkersOnBoardName } from 'constants/enums/bunkers';
import {
  SAILING,
  LADEN,
  BALLAST,
  ECO_BALLAST,
  ECO_LADEN,
  ECO_SAILING,
  SpeedAndConsTypes,
  DISCHARGING,
} from 'constants/enums/speed-and-con-types';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { getLocationId } from 'actions/bunker-ports';
import { isWet, isDry } from 'constants/market-segments';

export const selector = createSelector(
  (state) => state.activeWorksheetId,
  getActiveWorksheet,
  (state) => state.worksheetsById[state.activeWorksheetId].bunkerExpenses,
  (state) => state.worksheetsById[state.activeWorksheetId].voyage,
  (state) =>
    state.worksheetsById[state.activeWorksheetId].vessels.map(
      (vessel) => vessel.speedAndConsumptions
    ),
  (state) => state.worksheetsById[state.activeWorksheetId].vessels,
  (worksheetId, worksheet, bunkerExpenses, voyage, vesselsSpeedAndConsumptions, vessels) => {
    return {
      worksheetId,
      worksheet,
      bunkers: getBunkers(bunkerExpenses, voyage),
      fuelGradesUsed: getFuelGradesUsed(
        vesselsSpeedAndConsumptions,
        vessels,
        worksheet.marketSegmentId
      ),
      showUnusedBunkers: worksheet.showUnusedBunkers,
    };
  }
);

export const getBunkers = (bunkerExpenses, voyage) => {
  const rawBunkers: Array<IBunkersViewModel> = bunkerExpenses.bunkers;
  return rawBunkers
    .map((bunker: IBunkersViewModel) => buildBunkerSet(bunker, voyage.legs))
    .sort(
      (bunker, comparisonBunker) =>
        findIndex(voyage.legs, ['id', bunker.bunkerId]) -
        findIndex(voyage.legs, ['id', comparisonBunker.bunkerId])
    );
};

export type BunkerQtyAndPrice = {
  quantity: number,
  price: number,
};

export type BunkersUIItem = {
  bunkerId: WorksheetBunkersItemKey,
  quantityPriceSet: Array<BunkerQtyAndPrice>,
  // TODO fill the rest
};

const buildBunkerSet = (bunker: IBunkersViewModel, voyageLegs): BunkersUIItem => ({
  bunkerId: bunker.key,
  bunkerOnWorksheet: bunker,
  locationName: getLocationName(bunker.key, voyageLegs),
  locationIndex: voyageLegs
    .map((leg, index) => ({ leg, index }))
    .filter((_) => _.leg.id === bunker.key)
    .map((_) => _.index)[0] /* TODO replace with `.?index` when available */,
  quantityPriceSet: getQuantityPriceSet(bunker),
  priceIssuedDate: bunker.priceIssuedDate,
  locationId: getLocationId(bunker, voyageLegs),
  /**
   * The name of the location that was used to import prices.
   * This is only applicable to bunkers on board.
   */
  importLocationName: bunker.locationName,
});

const getQuantityPriceSet = (bunker: IBunkersViewModel): Array<BunkerQtyAndPrice> => {
  let set = [];
  bunker.fuelGrades.forEach((fuelGrade) => {
    set[fuelGrade.key] = {
      quantity: fuelGrade.quantity,
      price: fuelGrade.price,
    };
  });
  return set;
};

const getLocationName = (bunkerId: string, voyageLegs: Array): string => {
  if (bunkerId === bunkersOnBoardId) {
    return bunkersOnBoardName;
  }

  return voyageLegs.find((_) => _.id === bunkerId).name;
};

export const getFuelGradesUsed = (
  speedAndCons: Array<ISpeedAndConsumptionsViewModel>,
  vessels,
  marketSegmentId
): Array<FuelGradeId> => {
  const usedFuelGradeKeys = new Set();
  speedAndCons.forEach((sc) => {
    usedFuelGradeKeys.add(sc.zoneSpecific.seca.fuelGradeId);
    if (sc.zoneSpecific.sludgeDischargeBan && sc.zoneSpecific.sludgeDischargeBan.fuelGradeId) {
      usedFuelGradeKeys.add(sc.zoneSpecific.sludgeDischargeBan.fuelGradeId);
    }

    if (
      sc.zoneSpecific.secaAndSludgeDischargeBan &&
      sc.zoneSpecific.secaAndSludgeDischargeBan.fuelGradeId
    ) {
      usedFuelGradeKeys.add(sc.zoneSpecific.secaAndSludgeDischargeBan.fuelGradeId);
    }
  });

  SpeedAndConsTypes.forEach(({ key }) => {
    speedAndCons.forEach((sc, index) => {
      let includeFuelGrade = true;
      if (isWet(marketSegmentId)) {
        if (vessels[index].isParcelVoyage) {
          if (
            key === LADEN.key ||
            key === BALLAST.key ||
            key === ECO_LADEN.key ||
            key === ECO_BALLAST.key
          ) {
            includeFuelGrade = false;
          }
        } else {
          if (key === SAILING.key || key === ECO_SAILING.key) {
            includeFuelGrade = false;
          }
        }
      } else if (isDry(marketSegmentId)) {
        if (key === DISCHARGING.key) {
          includeFuelGrade = false;
        }
      }

      if (sc[key] && sc[key].isActive && includeFuelGrade) {
        const fuelGradeKey = sc[key].fuelGrade;
        const generatorFuelGradeKey = sc[key].generatorFuelGrade;
        usedFuelGradeKeys.add(fuelGradeKey);
        usedFuelGradeKeys.add(generatorFuelGradeKey);
      }
    });
  });

  return Array.from(usedFuelGradeKeys);
};
