import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NumericInput from 'components/numeric-input';

import { rateCommissionTimeCharterUpdated } from 'actions/worksheet/rates';
import { selector } from './selector';

import './styles.scss';

export class Rates extends React.Component<{
  vessels: IVesselViewModel[],
  brokerCommissionTimeCharter: Number,
  addressCommissionTimeCharter: Number,
  worksheetId: WorsheetId,
}> {
  onBrokerCommissionUpdated = (newBrokerCommissionTimeCharter) => {
    this.props.rateCommissionTimeCharterUpdated(
      newBrokerCommissionTimeCharter,
      this.props.addressCommissionTimeCharter,
      this.props.worksheetId
    );
  };

  onAddressCommissionUpdated = (newAddressCommissionTimeCharter) => {
    this.props.rateCommissionTimeCharterUpdated(
      this.props.brokerCommissionTimeCharter,
      newAddressCommissionTimeCharter,
      this.props.worksheetId
    );
  };

  render() {
    return (
      <div className="worksheet-section-wrapper worksheet-section-wrapper--rates">
        <h2 data-testid="worksheet-rates-header">Time Charter Commission</h2>
        <div className="worksheet-section-wrapper__components">
          <div className="worksheet-section__property worksheet-section__property--rates">
            <label
              className="worksheet-section__property-label"
              data-testid="worksheet-rates-broker-commission-label"
            >
              Broker (%)
            </label>
            <NumericInput
              value={this.props.brokerCommissionTimeCharter}
              diagnosticId="TimeCharterCommission/Broker(%)"
              onInputChange={this.onBrokerCommissionUpdated}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="49.99"
              data-testid="worksheet-rates-broker-commission-input"
            />
          </div>
          <div className="worksheet-section__property worksheet-section__property--rates">
            <label
              className="worksheet-section__property-label"
              data-testid="worksheet-rates-address-commission-label"
            >
              Address (%)
            </label>
            <NumericInput
              value={this.props.addressCommissionTimeCharter}
              diagnosticId="TimeCharterCommission/Address(%)"
              onInputChange={this.onAddressCommissionUpdated}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="49.99"
              data-testid="worksheet-rates-address-commission-input"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      rateCommissionTimeCharterUpdated,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Rates);
