import { createSelector } from 'reselect';
import { getActiveWorkbook } from 'common-selectors/get-active-workbook';
import { getActiveMarketSegmentId } from '../../common-selectors/get-active-marketSegmentId';

export const getStageProps = createSelector(
  (state) => state.appMetadata.stageRightPanel,
  getActiveWorkbook,
  getActiveMarketSegmentId,
  (rightPanel, activeWorkbook, activeMarketSegmentId) => ({
    rightPanel,
    activeWorkbook: activeWorkbook,
    activeMarketSegmentId: activeMarketSegmentId,
  })
);
