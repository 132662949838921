import mapValues from 'lodash/mapValues';
import type { Selector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/**
 Similar to [reselect's `createStructuredSelector`](https://github.com/reduxjs/reselect#createstructuredselectorinputselectors-selectorcreator--createselector), but allows you to base all of your selectors on some nested object, specified.
 
 The benefit of using this over a handwritten object selector is optimization - reselect will return the same instance of the object if all produced properties are the same.
 The benefit of selecting properties flat onto the React component is readability (the properties are better qualified, e.g. `worksheet.id`) understandability (through relying on familiar data structure add possibility of reusing type definitions) and quicker development (less naming decisions to make).
 
 ## Usage example:
 ```javascript
 createStructuredSelectorFromBaseSelector(getActiveWorksheet, {
  someComputedProperty: worksheet => worksheet?.marketSegmentId,
})
 ```
 The above selector will return an object with properties  
 */
export function createStructuredSelectorFromBaseSelector<
  FullState,
  BaseStatePart,
  DefinitionObject: { [string]: Selector<BaseStatePart, mixed> },
>(
  baseSelector: Selector<FullState, BaseStatePart>,
  definitionObject: DefinitionObject
): Selector<FullState, { [$Keys<DefinitionObject>]: mixed }> {
  return createStructuredSelector(
    mapValues(
      definitionObject,
      (selectorFromBase) =>
        (state, ...restArgs) =>
          selectorFromBase(baseSelector(state, ...restArgs))
    )
  );
}
