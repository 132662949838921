import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  deselectAllItems,
  selectAllItems,
  updateExpandState,
  updateItemStates,
} from './/updateItemStates';
import { ComparisonTableSetterOptions } from '../comparison-table-setter-options';
import { updateComparisonTableSettings } from '../../../actions/worksheet-comparison/comparison-table-settings';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export const CheckboxState = {
  UNCHECKED: false,
  CHECKED: true,
  INDETERMINATE: 'INDETERMINATE',
};

export interface ItemState {
  columnId: string;
  name: string;
  state: CheckboxState;
  parentId: string;
  field: string;
  expanded: boolean;
}

interface IComparisonTableSetterContent {
  items: Record<string, string | boolean>[];
  updateComparisonTableSettings: () => void;
}
export const ComparisonTableSetterContent: FC<IComparisonTableSetterContent> = forwardRef(
  ({ items, updateComparisonTableSettings }, ref) => {
    const defaultItemStates: ItemState[] = items.map((i) => ({
      columnId: i.columnId,
      name: i.name,
      field: i.field,
      state: i.state,
      parentId: i.parentId,
      expanded: i.expanded,
    }));

    const [itemStates, setItemStates] = useState(defaultItemStates);
    const getStateForId = useCallback(
      (id: string) => {
        return itemStates.find((i) => i.columnId === id).state;
      },
      [itemStates]
    );
    const getExpandStateForId = useCallback(
      (id: string) => {
        return itemStates.find((i) => i.columnId === id).expanded;
      },
      [itemStates]
    );

    useEffect(() => {
      updateComparisonTableSettings(itemStates);
    }, [itemStates, updateComparisonTableSettings]);

    useImperativeHandle(ref, () => ({
      clearAll() {
        setItemStates(deselectAllItems(itemStates));
      },
      selectAll() {
        setItemStates(selectAllItems(itemStates));
      },
    }));

    const clickHandler = useCallback(
      (id) => {
        setItemStates(updateItemStates(itemStates, items, id));
      },
      [itemStates, items]
    );
    const expandHandler = useCallback(
      (id) => setItemStates(updateExpandState(itemStates, id)),
      [itemStates]
    );

    return (
      <ComparisonTableSetterOptions
        items={items}
        onClick={clickHandler}
        onExpand={expandHandler}
        getStateForId={getStateForId}
        getExpandStateForId={getExpandStateForId}
      />
    );
  }
);

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateComparisonTableSettings }, dispatch);
};

export const ComparisonTableSetterContentContainer = connect(null, mapDispatchToProps, null, {
  forwardRef: true,
})(ComparisonTableSetterContent);
