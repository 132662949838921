import { variantsReducer } from './variants';

export function inboundRouteReducer(
  state: VoyageEntryInboundRouteViewModel,
  action: Action
): VoyageEntryInboundRouteViewModel {
  const newVariantsOrSame = variantsReducer(state.variants, action);
  if (newVariantsOrSame !== state.variants)
    state = {
      ...state,
      variants: newVariantsOrSame,
    };

  switch (action.type) {
    // Here's a place for any 'inbound route related' properties.
    default:
      return state;
  }
}
