import { fromFeetToMeters, fromMetersToFeet } from 'utilities/units-converter';
import { addDaysToDate } from 'utilities/time';
import sumBy from 'lodash/sumBy';
import intersection from 'lodash/intersection';
import { LOAD } from 'constants/enums/voyage-leg';
import { isScrubberFitted, GetScrubberType } from 'constants/enums/scrubber';
import { charterTypes } from 'constants/enums/charter-types';
import { ballastBonusId } from 'modules/additional-expenses/constants';
import { singleOrThrow } from 'utilities/iterable';
import { isWet } from 'constants/market-segments';

// TODO consider moving this to the calculation API or possible done as some form of
// computed property of the view model for better re-use (like export)
function getTotalDaysBallastUntilLeg(
  voyageLegId: Guid,
  phases: IResponseTimeAtPhaseBreakdown[]
): number {
  let days = 0;
  let portDaysForLeg = 0;
  let previousLegId = phases[0].legId;
  for (let phase of phases) {
    days += phase.seaDays + phase.seaDaysWeatherFactor;
    portDaysForLeg += phase.portDaysIdle + phase.portDaysManeuvering + phase.portDaysWorking;

    if (phase.legId !== previousLegId) {
      days += portDaysForLeg;
      portDaysForLeg = 0;
    }

    if (phase.legId === voyageLegId && phase.isLaden === true) {
      break;
    }

    previousLegId = phase.legId;
  }

  return days;
}

function getVoyageStartDate(vessel: IVesselViewModel): Date {
  const openDate = vessel.openPosition.openDate;
  const currentDateAtMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  const voyageStartDate = (openDate && openDate.start) || currentDateAtMidnight;

  return voyageStartDate;
}

export function getArrivalAtFirstLoad(
  worksheet: IWorksheetViewModel,
  calculation: ICalculationViewModel,
  vessel: IVesselViewModel
): Date | null {
  if (!vessel) return null;
  const totalDaysAtLegs =
    calculation && calculation.time && calculation.time.totalDaysAtLegs
      ? calculation.time.totalDaysAtLegs
      : null;

  const loadVoyageLegs =
    worksheet && worksheet.voyage && worksheet.voyage.legs.filter((leg) => leg.type === LOAD.key);

  const firstLoadVoyageLegId =
    loadVoyageLegs && loadVoyageLegs.length > 0 ? loadVoyageLegs[0].id : null;

  if (
    calculation.phases &&
    calculation.phases.length > 0 &&
    firstLoadVoyageLegId &&
    totalDaysAtLegs &&
    totalDaysAtLegs.length > 0
  ) {
    const totalDaysUntilFirstLoad = getTotalDaysBallastUntilLeg(
      firstLoadVoyageLegId,
      calculation.phases
    );

    const voyageStartDate = getVoyageStartDate(vessel);
    const firstArrivalDate = addDaysToDate(voyageStartDate, totalDaysUntilFirstLoad);
    return firstArrivalDate;
  }

  return null;
}

export function getVoyageEndDate(
  vessel: IVesselViewModel,
  calculation: ICalculationViewModel
): Date | null {
  const totalDaysForVoyage =
    calculation && calculation.time && calculation.time.totalDays
      ? calculation.time.totalDays
      : null;

  if (totalDaysForVoyage === null || !vessel) {
    return null;
  }

  const openDate = vessel.openPosition.openDate;
  const openPositionFromDate =
    (openDate && openDate.start) ||
    new Date(); /*Inconsistency: When there isn't an open position, we are using the browser's current date & time for the voyage start date, while when calculating the arrival at first load port, in the same situation we use the browser's current date at midnight */

  return addDaysToDate(openPositionFromDate, totalDaysForVoyage);
}

function formatSpeedAndConsumptions({ speed, engineFuelConsumption }) {
  return `${speed}/${engineFuelConsumption}`;
}

export function getVesselRowProperties(worksheet, vessel, calculation, shouldBlank) {
  if (shouldBlank || !vessel) {
    return {
      vesselDeadWeight: null,
      vesselBuildDate: null,
      vesselDraftInMeters: null,
      vesselDraftInFeet: null,
      vesselBallast: '',
      vesselLaden: '',
      vesselSailing: '',
      openPositionLocationName: '',
      openPositionDate: null,
      scrubberTypeName: '',
    };
  }
  const speedAndConsumptions = vessel.speedAndConsumptions;
  // TODO normalise deadweight/deadWeight
  const deadWeight = vessel.deadweight || vessel.deadWeight || null;
  const draftInMeters = vessel.draftUnit === 1 ? vessel.draft : fromFeetToMeters(vessel.draft);
  const draftInFeet = vessel.draftUnit === 2 ? vessel.draft : fromMetersToFeet(vessel.draft);

  const openPosition = vessel.openPosition;

  const scrubberTypeName = !vessel.scrubber.typeId
    ? ''
    : GetScrubberType(vessel.scrubber.typeId).label;
  let vesselBallast;
  let vesselLaden;
  let vesselSailing;

  if (isWet(worksheet.marketSegmentId) && vessel.isParcelVoyage) {
    vesselBallast = '';
    vesselLaden = '';
    vesselSailing = shouldBlank
      ? ''
      : formatSpeedAndConsumptions(calculation.calculationInputs.speedAndConsumptions.ballast);
  } else {
    vesselBallast = shouldBlank
      ? ''
      : formatSpeedAndConsumptions(calculation.calculationInputs.speedAndConsumptions.ballast);
    vesselLaden = shouldBlank
      ? ''
      : formatSpeedAndConsumptions(calculation.calculationInputs.speedAndConsumptions.laden);
    vesselSailing = '';
  }

  return {
    vesselDeadWeight: deadWeight,
    vesselBuildDate: vessel.buildDate,
    vesselDraftInMeters: draftInMeters,
    vesselDraftInFeet: draftInFeet,
    vesselBallast: vesselBallast,
    vesselLaden: vesselLaden,
    vesselSailing: vesselSailing,
    scrubberConsumption: shouldBlank
      ? ''
      : isScrubberFitted(vessel.scrubber.typeId)
        ? speedAndConsumptions.scrubberConsumption
        : 'n/a',
    openPositionLocationName: openPosition.name,
    openPositionDate: openPosition.openDate,
    scrubberTypeName: scrubberTypeName,
  };
}

export function getVoyageRowProperties(worksheet, vessel, calculation, shouldBlank, charterTypes) {
  if (shouldBlank || !calculation.distances) {
    return {
      firstLoadDate: '',
      arrivalAtFirstLoadDate: null,
      voyageEndDate: null,
      totalDaysAtSea: null,
      totalDaysAtPort: null,
      totalDaysAtLoad: null,
      totalDaysAtDischarge: null,
      totalDaysAtIdle: null,
      totalDistance: null,
      secaDistance: null,
      ballastBonus: null,
    };
  }
  const arrivalAtFirstLoadDate = getArrivalAtFirstLoad(worksheet, calculation, vessel);
  const voyageEndDate = getVoyageEndDate(vessel, calculation);
  const totalDistance = calculation.distances.totalDistance;
  const secaDistance = calculation.distances.secaDistance;
  const ballastBonus = calculation.additionalCostsNetItems
    .filter((_) => intersection(_.charterTypes, charterTypes).length > 0)
    .find((_) => _.id === ballastBonusId);
  return {
    arrivalAtFirstLoadDate: arrivalAtFirstLoadDate,
    voyageEndDate: voyageEndDate,
    totalDaysAtSea: calculation.time.totalDaysAtSea,
    totalDaysAtPort: calculation.time.totalDaysAtPort,
    totalDaysAtLoad: calculation.time.totalDaysAtLoad,
    totalDaysAtDischarge: calculation.time.totalDaysAtDischarge,
    totalDaysAtIdle: calculation.time.totalDaysAtIdle,
    totalDistance,
    secaDistance: totalDistance > 0 ? secaDistance : secaDistance > 0 ? secaDistance : null,
    ballastBonus: (ballastBonus && ballastBonus.calculatedAmount) || null,
  };
}

export function getVoyageRowCarbonConsumptionProperties(calculation, shouldBlank) {
  if (shouldBlank || !calculation.bunkers || !calculation.bunkers.carbonEmissionsPerFuelType) {
    return {
      totalCarbonEmissions: null,
      totalEEOI: null,
      totalCarbonCost: null,
    };
  }
  return {
    totalCarbonEmissions: calculation.bunkers.carbonEmissionsPerFuelType.totalCarbonEmissions,
    totalEEOI: calculation.bunkers.carbonEmissionsPerFuelType.totalEEOI,
    totalCarbonCost: calculation.carbonBreakdown.totalVoyageCost,
  };
}

export function getCargoDetailsRowProperties(worksheet, calculation, shouldBlank) {
  if (shouldBlank) {
    return {
      cargoType: null,
      cargoQuantity: null,
    };
  }
  return {
    cargoType: worksheet.cargoes && singleOrThrow(worksheet.cargoes).name,
    cargoQuantity: calculation.totalCargoQuantity,
  };
}

export function getCostsRowProperties(calculation, shouldBlank) {
  if (shouldBlank) {
    return {
      totalBunkersCostsBallastNet: null,
      totalBunkersCostsLadenNet: null,
      totalBunkersCostsLoadNet: null,
      totalBunkersCostsDischargeNet: null,
      totalBunkersCostsIdleNet: null,
      totalPortCostsNet: null,
      totalBunkersCostsAtPortNet: null,
    };
  }
  return {
    totalBunkersCostsBallastNet: calculation.bunkers.totalBunkersCostsBallastNet,
    totalBunkersCostsLadenNet: calculation.bunkers.totalBunkersCostsLadenNet,
    totalBunkersCostsLoadNet: calculation.bunkers.totalBunkersCostsLodeNet,
    totalBunkersCostsDischargeNet: calculation.bunkers.totalBunkersCostsDischargeNet,
    totalBunkersCostsIdleNet: calculation.bunkers.totalBunkersCostsIdleNet,
    totalPortCostsNet: calculation.totalPortCostsNet,
    totalBunkersCostsAtPortNet: calculation.bunkers.totalBunkersCostsAtPortNet,
  };
}

export const getVoyageRateCostsRowProperties = (calculation, shouldBlank) => {
  if (shouldBlank) {
    return {
      totalAdditionalCostsVRNet: null,
    };
  }
  return {
    totalAdditionalCostsVRNet: sumAdditionalCostsByCharterType(calculation, charterTypes.voyage),
  };
};

export const getTimeCharterCostsRowProperties = (calculation, shouldBlank) => {
  if (shouldBlank) {
    return {
      totalAdditionalCostsTCNet: null,
    };
  }
  return {
    totalAdditionalCostsTCNet: sumAdditionalCostsByCharterType(calculation, charterTypes.time),
  };
};

const sumAdditionalCostsByCharterType = (calculation, charterType) => {
  return sumBy(
    calculation.additionalCostsNetItems.filter((c) => c.charterTypes.includes(charterType)),
    'calculatedAmount'
  );
};
