import { convertDraft, METERS } from 'constants/enums/draft-units';
import { validationLevels, AllNumericValidationRuleDefinitions } from 'components/numeric-input';
import { convertImmersion, TPC } from 'constants/enums/immersion-units';
import { convertGrain, CUBIC_METERS } from 'constants/enums/grain-units';
import { getValidationMessages } from 'components/validation';
import { toNumber } from 'lodash';

export const getValidationMessagesForVessel = (vessel, shouldAutoCalculateIntake) => {
  const validationMessages = Object.entries(
    getDynamicVesselValidationRules(vessel, shouldAutoCalculateIntake)
  ).flatMap(([propertyName, validationRules]) =>
    getValidationMessages({
      ruleDefinitionsToTest: validationRules.validationRuleDefinitions,
      attemptedValueInfo: {
        attemptedValueText: vessel[propertyName],
        asNullableNumber: vessel[propertyName] === '' ? null : toNumber(vessel[propertyName]),
      },
      /* TODO consider a more abstract contract that will be explicit in what properties are required (i.e. `fieldName` and `ruleUsageParams`) */
      component: {
        props: {
          ...validationRules.ruleUsageParams,
          fieldName: propertyName,
        },
      },
    })
  );

  return validationMessages;
};

export const deadweightMinValue = 0;
export const deadweightMaxValue = 999999999.999;

export const draftMinValue = 0;
export const draftMaxValueMeters = 99.99;

export const tpcmiMinValue = 0;
export const tpcmiMaxValueCm = 9999.99;

export const getDynamicVesselValidationRules = (vessel, shouldAutoCalculateIntake) => ({
  deadWeight: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: shouldAutoCalculateIntake
        ? {
            value: 0.001 /* a requirement from auto intake calculation, see auto-calc-intake/index.js's `validateInputs` */,
            getAttemptedValueIsInvalidMessage: (attemptedValueInfo, ruleUsageParams, component) =>
              `This field should be greater than 0`,
            validationLevel: validationLevels.guidance,
          }
        : deadweightMinValue,
      maxValue: deadweightMaxValue,
    },
  },
  draft: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: shouldAutoCalculateIntake
        ? {
            value: 0.001 /* a requirement from auto intake calculation, see auto-calc-intake/index.js's `validateInputs` */,
            getAttemptedValueIsInvalidMessage: (attemptedValueInfo, ruleUsageParams, component) =>
              `This field should be greater than 0`,
            validationLevel: validationLevels.guidance,
          }
        : draftMinValue,
      maxValue: convertDraft(draftMaxValueMeters, METERS, vessel.draftUnit),
    },
  },
  tpcmi: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: shouldAutoCalculateIntake
        ? {
            value: 0.001 /* a requirement from auto intake calculation, see auto-calc-intake/index.js's `validateInputs` */,
            getAttemptedValueIsInvalidMessage: (attemptedValueInfo, ruleUsageParams, component) =>
              `This field should be greater than 0`,
            validationLevel: validationLevels.guidance,
          }
        : tpcmiMinValue,
      maxValue: convertImmersion(tpcmiMaxValueCm, TPC, vessel.immersionUnit),
    },
  },
  grain: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: shouldAutoCalculateIntake
        ? {
            value: 0.001 /* a requirement from auto intake calculation, see auto-calc-intake/index.js's `validateInputs` */,
            getAttemptedValueIsInvalidMessage: (attemptedValueInfo, ruleUsageParams, component) =>
              `This field should be greater than 0`,
            validationLevel: validationLevels.guidance,
          }
        : 0,
      maxValue: convertGrain(999999999.999, CUBIC_METERS, vessel.grainUnit),
    },
  },
  constants: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: 0,
      maxValue: shouldAutoCalculateIntake
        ? {
            value:
              vessel.deadWeight /* a requirement from auto intake calculation, see auto-calc-intake/index.js's `validateInputs` */,
            getAttemptedValueIsInvalidMessage: (attemptedValueInfo, ruleUsageParams, component) =>
              `Constants cannot be greater than the deadweight for the vessel`,
            validationLevel: validationLevels.guidance,
          }
        : 999999999,
      maxLength: 9,
    },
  },
  grossTimeCharter: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: '-999999.99',
      maxValue: '999999.99',
      emptyValue: 0,
      mandatory: true,
    },
  },
  netTimeCharter: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: '-999999.99',
      maxValue: '999999.99',
    },
  },
  ballastBonus: {
    validationRuleDefinitions: AllNumericValidationRuleDefinitions,
    ruleUsageParams: {
      minValue: '0',
      maxValue: '99999999.99',
    },
  },
});
