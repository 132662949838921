import React from 'react';

import { ReactComponent as Logo } from './assets/Sea-Calc.svg';
import Divider from 'components/divider';

import './styles.scss';

export const SplashScreen = React.memo(({ children }) => (
  <div className="splash-screen">
    <Logo className="splash-screen__logo" />
    <Divider />
    <div className="splash-screen__content">
      {children}
      <div className="splash-screen__content__copy">&copy; Maritech Development Limited</div>
    </div>
  </div>
));
