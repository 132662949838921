import { useEffect } from 'react';

/**
 *
 * @param {React.Ref<HTMLELement>} eventTargetRef a React Ref to the target HTMLElement
 * @param {string} eventType the event type
 * @param {(e:Event)=>void} callback the callback for the event
 * @param {*} opts additional event options see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Parameters
 */
export function useDOMEvent(eventTargetRef, eventType, callback, opts = undefined) {
  useEffect(() => {
    const currentTarget = eventTargetRef.current;
    if (!currentTarget) {
      return;
    }

    currentTarget.addEventListener(eventType, callback, opts);
    return () => {
      currentTarget.removeEventListener(eventType, callback);
    };
  }, [eventTargetRef.current, callback]);
}
