import { SWITCH_COMPARISON_VIEW_MODE } from 'constants/action-types/worksheet-comparison';

const initialState: IWorksheetComparisonState = {
  viewMode: undefined,
};

export const worksheetComparisonReducer = (
  state: IWorksheetComparisonState = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case SWITCH_COMPARISON_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload,
      };
    default:
      return state;
  }
};
