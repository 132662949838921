import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import logger from 'diagnostics/logger';

export function ErrorBoundary(props) {
  return (
    <ReactErrorBoundary
      fallbackRender={props.fallbackRender}
      onError={(error, info) => {
        logger.exception(error, info);

        if (props.onError) {
          props.onError(error, info);
        }
      }}
    >
      {props.children}
    </ReactErrorBoundary>
  );
}

export { FallbackError } from './fallback-error';
