import axios, { AxiosInstance } from 'axios';
import * as AuthFacade from 'bootstrap/AuthFacade';

import { vesselApiUrl, arcUrl, veUrl, calculationUrl, portDaUrl, sandcUrl } from 'config';

export let veApi: AxiosInstance = axios.create();
export let arcApi: AxiosInstance = axios.create();
export let calculationApi: AxiosInstance = axios.create();
export let portDaApi: AxiosInstance = axios.create();
export let externalVesselApi: AxiosInstance = axios.create();
export let sandcApi: AxiosInstance = axios.create();

export const setupApiClients = () => {
  veApi = createAxiosInstanceFor(veUrl, false, false);
  arcApi = createAxiosInstanceFor(arcUrl, true, false);
  calculationApi = createAxiosInstanceFor(calculationUrl, false, false);
  portDaApi = createAxiosInstanceFor(portDaUrl, false, false);
  externalVesselApi = createAxiosInstanceFor(vesselApiUrl, false, true);
  sandcApi = createAxiosInstanceFor(sandcUrl, false, false);
};

const createAxiosInstanceFor = (
  baseUrl: string,
  shouldEncodeToken: boolean,
  useNativeAuthHeader: boolean,
  customConfig: object
): AxiosInstance => {
  const config = {
    ...customConfig,
    baseURL: baseUrl,
    headers: {
      ...(customConfig && customConfig.headers),
    },
  };

  const instance = axios.create(config);
  setRequestInterceptorFor(instance, shouldEncodeToken, useNativeAuthHeader);
  setResponseInterceptorFor(instance);

  return instance;
};

const setRequestInterceptorFor = (
  api: AxiosInstance,
  shouldEncodeToken: boolean,
  useNativeAuthHeader: boolean
) => {
  api.interceptors.request.use(async (config) => {
    // Retrieve the live token from IdentityServer and add it to the request in the specified way.
    const token = await AuthFacade.getToken();
    const formattedToken = formatToken(token, shouldEncodeToken);
    const authHeader = buildAuthHeader(formattedToken, useNativeAuthHeader);

    config.headers = {
      ...config.headers,
      ...authHeader,
    };

    return config;
  });
};

const setResponseInterceptorFor = (api: AxiosInstance) => {
  api.interceptors.response.use(
    async (response) => response,
    async (error) => {
      if (!error.response) {
        throw error;
      }
      switch (error.response.status) {
        case 401:
          AuthFacade.redirectToLogout();
          break;
        default:
          throw error;
      }
    }
  );
};

const formatToken = (token: string, shouldEncodeToken: boolean) => {
  return shouldEncodeToken ? encodeURIComponent(token) : decodeURIComponent(token);
};

const buildAuthHeader = (token: string, useNativeAuthHeader: boolean) => {
  return useNativeAuthHeader
    ? { Authorization: 'clarksons-cloud-token ' + token }
    : { 'x-clarksons-security-cloud': token };
};
