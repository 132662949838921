import React from 'react';

import classNames from 'classnames';

import './styles.scss';

export const Overlay = React.memo(({ hidden, onClick, ...props }) => {
  const classes = classNames('overlay', { 'overlay-show': !hidden });
  return <div className={classes} onClick={onClick} {...props} />;
});
