/**
   Part of #RouteRecalculationsRaceConditionPrevention - only start 'one route calculation at a time' per worksheet to prevent races, e.g. an earlier recalculation returning later than the subsequent and losing its route information.
*/
import { createRunnerInQueueOneAtATime } from '../../../../utilities/functions';
import type { DeferringAsyncRunner } from '../../../../utilities/functions';
import isNil from 'lodash/isNil';
import VError from 'verror';

/**
 This function's variant of queueing is useful to enqueue statements inside a function block. See also `wrapFnToRunInQueueForRouteCalculation`.
 */
export async function runInQueueForRouteCalculation(
  worksheetId: WorksheetId,
  functionToRun: AsyncZeroArgsFn<ReturnValue>
) {
  if (isNil(worksheetId))
    throw new VError(
      { info: { worksheetId } },
      '`worksheetId` must be specified in the first argument to `wrapFnToRunInQueueForRouteCalculation`.'
    );

  if (typeof functionToRun !== 'function')
    throw new VError(
      { info: { functionToRun } },
      'A function must be specified as the first argument to `wrapFnToRunInQueueForRouteCalculation`.'
    );

  const runInQueueForRouteCalculation = getRunnerInQueueForRouteCalculation(worksheetId);

  return await runInQueueForRouteCalculation(functionToRun);

  function getRunnerInQueueForRouteCalculation(worksheetId: WorksheetId): DeferringAsyncRunner {
    const existingRunner = runnersInQueueForRouteCalculationByWorksheetId.get(worksheetId);
    if (existingRunner) return existingRunner;
    else {
      const newRunner = createRunnerInQueueOneAtATime();
      runnersInQueueForRouteCalculationByWorksheetId.set(worksheetId, newRunner);
      return newRunner;
    }
  }
}

/**
 This function's variant of queueing is useful for wrapping entire functions. With [the pipeline operator](https://github.com/tc39/proposal-pipeline-operator), it should even be possible to add it without any extra indentation caused to the function.
 */
export function wrapFnToRunInQueueForRouteCalculation<FunctionType: (...args: any) => Promise<any>>(
  worksheetId: WorksheetId,
  functionToWrap: FunctionType
): (...args: Arguments) => FunctionType {
  return async function (...args: Arguments): ReturnValue {
    return await runInQueueForRouteCalculation(worksheetId, async () => {
      return await functionToWrap.apply(this, args);
    });
  };
}

const runnersInQueueForRouteCalculationByWorksheetId: Map<WorksheetId, DeferringAsyncRunner> =
  new Map();
