import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selector } from './selector';
import DaysBreakdownGrid from './days-breakdown-grid';
import type { IDaysBreakdownSummaryState } from './selector';
import ExpandCollapse from 'components/expand-collapse';
import { Header } from 'components/headers';
import './styles.scss';

export class DaysBreakdown extends Component<IDaysBreakdownSummaryState> {
  render() {
    return (
      <div>
        <ExpandCollapse
          header={<Header text={<h2 className="days-breakdown-header">Days Breakdown</h2>} />}
        >
          <DaysBreakdownGrid daysBreakdownSummaryState={this.props} />
        </ExpandCollapse>
      </div>
    );
  }
}

const mapStateToProps = selector;

export default connect(mapStateToProps)(DaysBreakdown);
