/** Why use this and not just have _no `await`_?
 *  - it makes it clear that lack of await is intentional (still, caller should consider adding a comment explaining **why** it is a good decision)
 *  - it will make errors not lose the 'caller' stack trace*). Without it, the major value of stack trace is lost, which is the value of being an actual _trace_ (answering 'why the failing function was called', rather than just 'what was the failing function').
 *    See ["Asynchronous stack traces: why await beats Promise#then()"](https://mathiasbynens.be/notes/async-stack-traces)
 * Where do the errors go?
 *   Errors will go to [the global `unhandledrejection` event](https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event)
 *
 * 
 * *) To see the improvement yourself, when deep in a call tree, stick these lines of code and compare the stack traces shown on the console:
```
failAfter1Sec('Failure from a simple "lack of await"');
noAwait(failAfter1Sec('Failure from noAwait(...)'));

async function failAfter1Sec(failureText) {
  try {
    await new Promise((resolve, reject) =>
      setTimeout(() => {
        reject(failureText);
      }, 1000)
    );
  } catch (e) {
    throw e;
  }
}
```
 * */
export default function noAwait(promise: Promise): void {
  if (!isPromise(promise)) {
    throw new TypeError('`promise` must be a Promise.');
  }
  callerStackCapturingHelper(); // No `await` for the async helper function here, as per caller intent - also this is what makes the outer function synchronous and not return a promise.

  // Also no `catch` in this function so that the error reaches [the global `unhandledrejection` event](https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event)

  async function callerStackCapturingHelper(promise) {
    await promise; /* Await here to capture the stack */
  }
}

/**
 * A variant of `noAwait` which silently ignores non-promises.
 * See JSDoc of `noAwait` for rationale of marking lack of awaits with these function.
 *
 * When to use instead of `noAwait`: `noAwait` is the preferred choice (it will highlight code problems early).
 * Use this one if you have a case where it is intended that the value isn't always a promise.
 */
export function noAwaitIgnoringNoPromise(value: any) {
  if (!isPromise(value)) return;
  noAwait(value);
}

function isPromise(value) {
  /* https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise/27746324#27746324 */
  return !!(value && value.then);
}
