import React from 'react';

import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

export default React.memo(function NewWorksheetButton({ isDisabled, onClick, ...props }) {
  return (
    <IconButton
      icon={iconEnum.AddCircleOutline}
      disabled={isDisabled}
      onClick={onClick}
      {...props}
    />
  );
});
