import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './styles.scss';

export default class Divider extends PureComponent {
  render() {
    const classes = classNames('divider', this.props.className);
    return <hr className={classes} />;
  }
}
export class GoldenDivider extends PureComponent {
  render() {
    const classes = classNames('divider__golden', this.props.className);
    return <Divider className={classes} />;
  }
}
