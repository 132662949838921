import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import { logErrorDetailsOnlyToConsole } from '../error/log-error-details-only-to-console';

export type Message = mixed;
export type MessageEvent = { origin: string, data: Message, source: Window };
export type OriginPredicateFn = (origin: string) => boolean;
export type MessagePredicateFn = (message: Message) => boolean;
export type SourcePredicateFn = (source: Window) => boolean;

/** Typical options that vary 'per usage'. Extracted into a separate type to ease constructing easy to use wrappers that abstract the infrastructural choices. */
export type UsageOptions = {
  abortSignal?: AbortSignal,
  /**
   * `messagePredicate` is needed especially because #BrowserAddonsUseWindowPostMessage - various browser add-ons and dev tools can send various messages, e.g. React dev tools sends { source: "react-devtools-inject-backend" }.
   *
   * An approach to have some message predicate check is even suggested by the add-on authors, eg [here](https://github.com/facebook/react-devtools/issues/812#issuecomment-308827334)
   */
  messagePredicate: MessagePredicateFn,
};

/** Extracted to a separate ty */
export type InfrastructuralOptions = {
  /**
   * Please always check the origin using this function to make sure it is trusted.
   *
   * NOTE: _"This cannot be overstated: Failure to check the origin ... enables cross-site scripting attacks."_
   * ([MDN docs: `postMessage`](https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#Notes))
   */
  allowedOriginPredicate: OriginPredicateFn,

  /**
   * `sourcePredicate` - only return a messages whose 'source' window matches the predicate.
   *
   * ##Purpose
   *
   * `sourcePredicate` has multiple purposes, depending on the scenario:
   * * In the scenario where the window that is receiving the message is also the _creator_ of the one that sends it:
   *    It enables thread safety on the side of the creator, that is: in the event that the action is invoked again, and another window is created, this prevents the execution of the first from picking up the response of this new window.
   * * In any other scenarios:
   *    it helps to silently skip any 'most likely' valid messages from other pages (also from #...), so that later we can raise errors for improper use or security attack attempts.
   *
   * ##Is it a security measure?
   * Although it is mentioned in MDN docs that checking it is a security measure as well, but it only would be for the creator of the window, not the created window (it must first establish that a window is trusted based on origin. It especially should not check those sender properties that are specific to its current 'location': it's a window, so its properties can change by the attacker redirecting to a trusted location after the attack)
   */
  sourcePredicate: SourcePredicateFn,
};

export type Options = UsageOptions & InfrastructuralOptions;

export async function receiveOneMessage(options: Options): Message {
  const messageEvent = await receiveOneMessageEvent(options);

  return messageEvent.data;
}

/**
 * An overload of `receiveOneMessage*` which returns the whole event (with `origin` and the `source` window)
 */
export async function receiveOneMessageEvent(options: Options): MessageEvent {
  return await new Promise((resolve, reject) => {
    const cleanupFunctions = [];

    window.addEventListener('message', messageEventListener);
    cleanupFunctions.push(() => window.removeEventListener('message', messageEventListener));

    if (options.abortSignal) {
      options.abortSignal.addEventListener('abort', abort);
      cleanupFunctions.push(() => options.abortSignal.removeEventListener('abort', abort));
    }

    function abort() {
      reject(new Error(`The wait for the message was aborted.`));
    }

    function messageEventListener(messageEvent: MessageEvent) {
      // Thanks to having these few below `return` which are very precise, we are also filtering any other unknown message sources. This leaves the rest of the logic to be fail-fast - be able to throw proper `Error`s that the developer will notice and get an instruction on where to make an improvement.

      if (isWellKnownMessageToIgnore(messageEvent)) return;

      if (!options.sourcePredicate(messageEvent.source)) return;

      if (!options.messagePredicate(messageEvent.data)) {
        // Send to developers. Whether it's a need to extend `messagePredicate` or `isWellKnownMessageToIgnore`, it makes sense to pass both cases as errors to let the developer know.
        unhandledExceptionSinkSend(
          logErrorDetailsOnlyToConsole(
            new Error(
              'Received a message of unknown form. See the full MessageEvent in the console error. If this is a valid message, you want to allow it in `messagePredicate`. If this is a well known message to ignore (e.g. as per #BrowserAddonsUseWindowPostMessage), then please add its detection to the `isWellKnownMessageToIgnore` function.'
            ),
            /*details: */ { messageEvent }
          )
        );

        // But don't throw, especially because #BrowserAddonsUseWindowPostMessage. In case this is some browser add-on that we didn't see yet.
        return;
      }

      if (!options.allowedOriginPredicate(messageEvent.origin)) {
        // Send to developers. Whether it's a need to extend `allowedOriginPredicate` or a security breach, it makes sense to pass both cases as errors to notify developers. Especially in the latter case, they will have a record of security breaches
        unhandledExceptionSinkSend(
          logErrorDetailsOnlyToConsole(
            new Error(
              `Received a message from an unauthorized origin ${messageEvent.origin} (full message was written to the dev console). If this is a valid origin, then \`allowedOriginPredicate\` should be fixed. Otherwise this might be an attempt at a security breach.`
            ),
            { messageEvent }
          )
        );

        // But don't throw, especially because #BrowserAddonsUseWindowPostMessage. In case this is some browser add-on that we didn't see yet.
        return;
      }

      for (const cleanupFunction of cleanupFunctions) {
        cleanupFunction();
      }

      resolve(messageEvent);

      /**
       * As part of #BrowserAddonsUseWindowPostMessage, some browser dev-tools pervasively keep firing when Dev Tools are open.
       * This method allows to short-circuit them to allow debugging the predicates without the constant annoyance.
       */
      function isWellKnownMessageToIgnore(messageEvent) {
        if (
          // Observed pervasive sends from React Dev Tools of: `{ source: "react-devtools-inject-backend" }`.
          messageEvent.data &&
          messageEvent.data.source &&
          // Use a prefix to catch anything coming from there. E.g. they [also send `react-devtools-content-script` and `react-devtools-bridge` these apparently](https://github.com/facebook/react-devtools/issues/812#issuecomment-308827334)
          messageEvent.data.source.startsWith('react-devtools-')
        ) {
          return true;
        } /*
        Add any more items here as a separate `else if` like:
        ```
        else if (messageEvent.data && messageEvent.data.someProp === 'something') return true;
        ```
     */ else {
          return false;
        }
      }
    }
  });
}
