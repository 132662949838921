import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selector } from './selectors';
import { refreshSpeedAndCons, saveSpeedAndCons } from 'actions/worksheet/vessel';
import {
  fuelGradeChanged,
  zoneSpecificSecaFuelGradeChanged,
  zoneSpecificBannedSlugDischargeFuelGradeChanged,
  zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged,
  speedChanged,
  consumptionChanged,
  activeSpeedAndConChanged,
  scrubberFuelConsumptionChanged,
} from 'actions/worksheet/speed-and-consumptions';
import Divider from 'components/divider';
import { IconButton, IconButtonToolTip } from 'components/button';
import { iconEnum } from 'components/icons';
import MovingSpeedAndConsHeader from './components/moving-speed-and-cons-header';
import MovingSpeedAndCon from './components/moving-speed-and-cons';
import StaticSpeedAndConsHeader from './components/static-speed-and-cons-header';
import StaticSpeedAndCon from './components/static-speed-and-cons';
import { Subheader } from 'components/headers';
import './styles.scss';
import { SaveWithConfirmationButton } from 'components/save-with-confirmation-button';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import EmitNotice from 'components/notices-emitter/emit-notice';
import SuccessBox from 'components/notice-box/success-box';
import ErrorBox from 'components/notice-box/error-box';
import InfoBox from 'components/notice-box/info-box';
import {
  SAVE_SPEED_AND_CONS_SUCCESS_NOTICE,
  SAVE_SPEED_AND_CONS_FAILURE_NOTICE,
  SAVE_SPEED_AND_CONS_NO_UPDATE_SUCCESS_NOTICE,
} from 'constants/enums/emit-notices';
import userContext from 'user-context';
import { isWet } from '../../../constants/market-segments';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export class SpeedAndConsumptions extends Component {
  onFuelGradeChangedHandler = (speedAndConType, isMainEngine) => (fuelGrade) => {
    this.setState({ saveButtonDisable: false });
    const payload = {
      speedAndConType,
      isMainEngine,
      fuelGrade,
    };

    this.props.fuelGradeChanged(payload, this.props.worksheetId, this.props.vessel.id);
  };

  onSpeedChangedHandler = (speedAndConType) => (speed) => {
    this.setState({ saveButtonDisable: false });
    const payload = {
      speedAndConType,
      speed,
    };

    this.props.speedChanged(payload, this.props.worksheetId, this.props.vessel.id);
  };

  onConsumptionChangedHandler = (speedAndConType, isMainEngine) => (consumption) => {
    this.setState({ saveButtonDisable: false });
    const payload = {
      speedAndConType,
      isMainEngine,
      consumption,
    };
    this.props.consumptionChanged(payload, this.props.worksheetId, this.props.vessel.id);
  };

  onActiveSpeedAndConChangedHandler = (speedAndCons) => (activeSpeedAndCon) => {
    this.setState({ saveButtonDisable: false });
    const payload = {
      activeSpeedAndCon: activeSpeedAndCon.id,
      nonActiveSpeedAndCons: speedAndCons
        .filter((speedAndCon) => speedAndCon.id !== activeSpeedAndCon.id)
        .map((speedAndCon) => speedAndCon.id),
    };

    this.props.activeSpeedAndConChanged(payload, this.props.worksheetId, this.props.vessel.id);
  };

  onSpeedAndConsRefreshedHandler = async () => {
    this.setState({ saveButtonDisable: true });
    await this.props.refreshSpeedAndCons(
      this.props.vessel,
      this.props.worksheetId,
      this.props.vessel.id
    );
  };

  onSpeedAndConsSavedHandler = async () => {
    this.setState({
      showSandCSuccessNotice: false,
      showSandCPartialSuccessNotice: false,
      showSandCFailureNotice: false,
      saveButtonDisable: true,
    });
    try {
      await this.props.saveSpeedAndCons(
        this.props.allSpeedAndCons,
        this.props.vessel.vesselId,
        this.props.worksheetId,
        this.props.vessel.isParcelVoyage
      );
      this.setState({
        showSandCSuccessNotice: true,
        showSandCPartialSuccessNotice: false,
        showSandCFailureNotice: false,
      });
    } catch (error) {
      const partialSuccess = error.response && error.response.status === 304;
      this.setState({
        showSandCSuccessNotice: false,
        showSandCPartialSuccessNotice: partialSuccess,
        showSandCFailureNotice: !partialSuccess,
        saveButtonDisable: partialSuccess,
      });
    }

    trackEvent(
      this.props.diagnosticId,
      'Speed and Cons confirm clicked',
      {},
      {},
      eventDestination.ANALYSIS
    );
  };

  movingSpeedAndConRow = (speedAndCons) => {
    return (
      <MovingSpeedAndCon
        key={speedAndCons[0].type}
        speedAndCons={speedAndCons}
        onSpeedChanged={this.onSpeedChangedHandler}
        onConsumptionChanged={this.onConsumptionChangedHandler}
        onFuelGradeChanged={this.onFuelGradeChangedHandler}
        onActiveSpeedAndConChanged={this.onActiveSpeedAndConChangedHandler}
        isParcelVoyage={this.props.vessel.isParcelVoyage}
        vesselId={this.props.vessel.vesselId}
        marketSegmentId={this.props.marketSegmentId}
      />
    );
  };

  staticSpeedAndConRow = (speedAndCon) => {
    return (
      <StaticSpeedAndCon
        key={speedAndCon.type}
        speedAndCon={speedAndCon}
        onConsumptionChanged={this.onConsumptionChangedHandler}
        onFuelGradeChanged={this.onFuelGradeChangedHandler}
        vesselId={this.props.vessel.vesselId}
        marketSegmentId={this.props.marketSegmentId}
      />
    );
  };

  showSandCSaveButton = (): boolean => {
    return (
      !this.props.vessel.isBalticVessel &&
      this.props.vessel.vesselId &&
      (isWet(this.props.marketSegmentId) || !userContext._userInfo.isInHouseCompany)
    );
  };

  saveButtonDisableStatus = (): boolean => {
    return this.state && this.state.saveButtonDisable;
  };

  render() {
    return (
      <>
        <ReactTooltip
          place="bottom"
          id="speed-and-cons-tooltip"
          className="ve-tooltip-default"
          noArrow
        />
        <Divider className="speed-and-cons-multi-vessel__top-divider" />
        <div className="speed-and-cons-multi-vessel">
          <fieldset className="speed-and-cons-multi-vessel-group" disabled={this.props.disabled}>
            <div className="speed-and-cons-multi-vessel__subheader-section">
              <div className="speed-and-cons-multi-vessel__subheader">
                <Subheader text="Speed and Consumptions" className="margin-bottom-small" />
              </div>

              <div className="speed-and-cons-multi-vessel__save-button-section">
                {this.showSandCSaveButton() ? (
                  <div
                    data-tooltip-content="Save Speed and Cons"
                    data-tooltip-id="speed-and-cons-tooltip"
                  >
                    <SaveWithConfirmationButton
                      className="worksheet-info__controls--button"
                      onConfirm={this.onSpeedAndConsSavedHandler}
                      isDisabled={this.saveButtonDisableStatus()}
                      diagnosticId="SpeedAndConsumptions/Save"
                    />
                  </div>
                ) : (
                  ''
                )}
                <IconButtonToolTip
                  icon={iconEnum.Refresh}
                  className="speed-and-cons-multi-vessel__refresh-btn"
                  onClick={this.onSpeedAndConsRefreshedHandler}
                  disabled={!this.props.vessel.vesselId}
                  text="Refresh Speed and Cons"
                  diagnosticId="SpeedAndConsumptions/Refresh"
                />
              </div>
            </div>

            <div className="speed-and-cons-multi-vessel__controls">
              <div className="speed-and-cons-multi-vessel__table--location-type-specific speed-and-cons-multi-vessel__moving">
                <MovingSpeedAndConsHeader />
                {this.props.movingSpeedAndCons.map(this.movingSpeedAndConRow)}
              </div>
              <div className="speed-and-cons-multi-vessel__table--location-type-specific speed-and-cons-multi-vessel__static">
                <StaticSpeedAndConsHeader />
                {this.props.staticSpeedAndCons.map(this.staticSpeedAndConRow)}
              </div>
            </div>
          </fieldset>
        </div>

        {this.state && this.state.showSandCSuccessNotice && (
          <EmitNotice
            type={SAVE_SPEED_AND_CONS_SUCCESS_NOTICE}
            worksheetId={this.props.worksheetId}
          >
            {({ dismiss, additionalProps }) => (
              <SuccessBox additionalprops={additionalProps}>
                <h1>Success</h1>
                <span className="save-banner-header-text">
                  Speed and Consumptions details saved for vessel {this.props.vessel.vesselName}
                </span>
                <IconButton
                  aria-label="Dismiss"
                  icon={iconEnum.Close}
                  className="notice-box__standard-dismiss-button"
                  onClick={dismiss}
                  diagnosticId="SpeedAndConsumptions/Close"
                />
              </SuccessBox>
            )}
          </EmitNotice>
        )}

        {this.state && this.state.showSandCPartialSuccessNotice && (
          <EmitNotice
            type={SAVE_SPEED_AND_CONS_NO_UPDATE_SUCCESS_NOTICE}
            worksheetId={this.props.worksheetId}
          >
            {({ dismiss, additionalProps }) => (
              <InfoBox additionalprops={additionalProps}>
                <h1>Info</h1>
                <span className="save-banner-header-text">
                  Speed and Consumptions details already exist for vessel{' '}
                  {this.props.vessel.vesselName}
                </span>
                <IconButton
                  aria-label="Dismiss"
                  icon={iconEnum.Close}
                  className="notice-box__standard-dismiss-button"
                  onClick={dismiss}
                  diagnosticId="SpeedAndConsumptions/DismissMessage"
                />
              </InfoBox>
            )}
          </EmitNotice>
        )}

        {this.state && this.state.showSandCFailureNotice && (
          <EmitNotice
            type={SAVE_SPEED_AND_CONS_FAILURE_NOTICE}
            worksheetId={this.props.worksheetId}
          >
            {({ dismiss, additionalProps }) => {
              return (
                <ErrorBox additionalprops={additionalProps}>
                  <h1>Unsuccessful</h1>
                  <span className="save-banner-header-text">
                    Unable to save Speed and Consumptions details for vessel{' '}
                    {this.props.vessel.vesselName}
                  </span>
                  <IconButton
                    aria-label="Dismiss"
                    icon={iconEnum.Close}
                    className="notice-box__standard-dismiss-button"
                    onClick={dismiss}
                    diagnosticId="SpeedAndConsumptions/DismissError"
                  />
                </ErrorBox>
              );
            }}
          </EmitNotice>
        )}
      </>
    );
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fuelGradeChanged,
      zoneSpecificSecaFuelGradeChanged,
      zoneSpecificBannedSlugDischargeFuelGradeChanged,
      zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged,
      speedChanged,
      consumptionChanged,
      scrubberFuelConsumptionChanged,
      activeSpeedAndConChanged,
      refreshSpeedAndCons,
      saveSpeedAndCons,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeedAndConsumptions);

export type { FuelGradeId } from './fuel-grades';
