export const WORKING = { key: 1, label: 'Working' };
export const IDLE = { key: 2, label: 'Idle' };
export const LOADING = { key: 3, label: 'Loading' };
export const DISCHARGING = { key: 4, label: 'Discharging' };

export const getGearTypeById = (typeId) => {
  switch (typeId) {
    case WORKING.key:
      return WORKING;
    case IDLE.key:
      return IDLE;
    case LOADING.key:
      return LOADING;
    case DISCHARGING.key:
      return DISCHARGING;
    default:
      return {};
  }
};

export const WetGearLoadingTypes = [LOADING, IDLE];
export const WetGearDischargingTypes = [DISCHARGING, IDLE];
const GearTypes = [WORKING, IDLE];

export default GearTypes;
