import React from 'react';
import classNames from 'classnames';
import { version, environment } from 'config';

import './styles.scss';

const AppInfo = ({ className }) => (
  <span className={classNames('app-info', className)}>
    &copy; Maritech Development Limited &mdash; v{version} {environment}
  </span>
);

export default AppInfo;
