import round from 'lodash/round';
import { fromCubicMetersToCubicFeet, fromCubicFeetToCubicMeters } from 'utilities/units-converter';

export const CUBIC_METERS = { key: 1, label: 'm&sup3;' };
export const CUBIC_FEET = { key: 2, label: 'ft&sup3;' };

export const getGrainUnitTypeById = (typeId) => {
  switch (typeId) {
    case CUBIC_METERS.key:
      return CUBIC_METERS;
    case CUBIC_FEET.key:
      return CUBIC_FEET;
    default:
      throw new Error(`Unable to parse invalid grain unit type id ${typeId}`);
  }
};

const GrainUnitTypes = [CUBIC_METERS, CUBIC_FEET];

export const GRAIN_NUMBER_OF_DECIMAL_PLACES = 3;

export const convertGrain = (grain, fromGrainUnit, toGrainUnit) => {
  return fromGrainUnit === toGrainUnit
    ? grain
    : toGrainUnit === CUBIC_METERS
      ? round(fromCubicFeetToCubicMeters(grain), GRAIN_NUMBER_OF_DECIMAL_PLACES)
      : round(fromCubicMetersToCubicFeet(grain), GRAIN_NUMBER_OF_DECIMAL_PLACES);
};

export default GrainUnitTypes;
