import React, { FC, useRef } from 'react';
import Downshift from 'downshift';
import './styles.scss';
import { ComparisonTableSetterContentContainer } from './comparison-table-setter-content';
import { SecondaryButton } from '../../components/button';

interface IComparisonTableColumnsSetter {
  tableConfiguration: Record<string, any>[];
  onStateChangeHandler: () => void;
}
export const ComparisonTableColumnsSetter: FC<IComparisonTableColumnsSetter> = ({
  tableConfiguration,
  onStateChangeHandler,
}) => {
  const ref = useRef();

  const categoriesAvailable = tableConfiguration.filter((cat) => cat.parentId !== '').length;

  const categoriesSelected = tableConfiguration.filter(
    (cat) => cat.state === true && cat.parentId !== ''
  ).length;

  return (
    <Downshift onStateChange={onStateChangeHandler}>
      {({ isOpen, getRootProps, toggleMenu }) => (
        <div>
          <span>Display Columns</span>
          <button
            className="ve-comparison-table__columns-setter__button"
            onClick={() => {
              toggleMenu();
            }}
          >
            <span>{`${categoriesSelected} / ${categoriesAvailable} selected`}</span>
            <i className="has-icon icon--arrow-drop-down icon" />
          </button>
          {isOpen ? (
            <div className="ve-comparison-table__columns-setter__menu" data-test="menu">
              <div className="ve-comparison-table__columns-setter__menu-header">
                <SecondaryButton
                  disabled={categoriesSelected === categoriesAvailable}
                  className="ve-comparison-table__columns-setter__menu-header-button"
                  onClick={() => ref.current.selectAll()}
                  diagnosticId="ComparisonTableColumnSelection/SelectAll"
                >
                  Select All
                </SecondaryButton>
                <SecondaryButton
                  disabled={categoriesSelected === 0}
                  className="ve-comparison-table__columns-setter__menu-header-button"
                  onClick={() => ref.current.clearAll()}
                  diagnosticId="ComparisonTableColumnSelection/Clear"
                >
                  Clear
                </SecondaryButton>
              </div>
              <div className="ve-comparison-table__columns-setter__menu-content">
                <ComparisonTableSetterContentContainer ref={ref} items={tableConfiguration} />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};
