type CostTypeId = Number;
export type CostType = { key: CostTypeId, label: String };

export const CostTypeEnum: { [symbol: string]: CostType } = {
  LUMPSUM: { key: 1, label: 'Lumpsum' },
  PER_METRIC_TON: { key: 2, label: 'Per Metric Ton' },
  PER_VOYAGE_DAY: { key: 3, label: 'Per Voyage day' },
};

export const LUMPSUM = CostTypeEnum.LUMPSUM;

const CostTypes = Object.values(CostTypeEnum);

export function getCostTypeById(typeId: CostTypeId) {
  return CostTypes.find((_) => _.key === typeId) || {};
}

export default CostTypes;
