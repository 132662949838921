import VError from 'verror';
import type { AsyncAction } from 'utilities/redux/thunk';
import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import * as iterableUtils from 'utilities/iterable/index';
import { NonVoidTryFunctionUtils } from 'utilities/functions/try-functions/non-void-try-function';
import * as PromiseUtils from 'utilities/promise';
import * as generatorFnUtils from 'utilities/iterable/generator-fn-utils';
import { createEmptyRouteViewModel } from 'reducers/worksheet/voyage/voyage-leg-builder';
import { RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable } from 'api/clients/route';
import { Geolocation } from 'utilities/location';
import { VOYAGE_ROUTE_VARIANTS_SET } from 'constants/action-types/worksheet/voyage';
import type {
  VoyageEntryAction,
  VoyageEntryActionInput,
} from 'actions/worksheet/voyage/voyage-entry-action-typings';
import { handleEachUnsuccessfulResultTypeThrowIfAnyUnknown } from 'utilities/functions/try-functions/compound-result-error-handling';
import {
  createErrorUnimplementedFeatureOfConveyingDistancesAreWrongToUser,
  createWaypointPartiallyUnavoidableErrorCausedUnimplementedFeatureOfConveyingDistancesAreWrongToUser,
} from '..';
import { tryCalculateInboundRouteVariantViewModelFromTo } from 'modules/voyage/business-model/routes-calculation';
import type { CalculateInboundRouteVariantViewModelUnsuccessfulResult } from 'modules/voyage/business-model/routes-calculation';
import { logErrorDetailsOnlyToConsole } from 'utilities/error/log-error-details-only-to-console';
import { getAllLegsWithFromToInfo } from 'modules/voyage/business-model/voyage-locations-sequence';
import { getWaypointsToExcludeForNewRoutes } from 'modules/voyage/business-model/waypoints';

export type CalculateInboundRouteVariantViewModelResult =
  | InboundRouteVariantViewModel
  | CalculateInboundRouteVariantViewModelUnsuccessfulResult;

export type ResultOfTryGetNewRouteVariantViewModel<
  ItemOrUnsuccessfulResult:
    | InboundRouteVariantViewModel
    | CalculateInboundRouteVariantViewModelUnsuccessfulResult = CalculateInboundRouteVariantViewModelResult,
> = {
  isUnchangedExistingItemFromOrigin: boolean,
  itemOrUnsuccessfulResult: ItemOrUnsuccessfulResult,
  /* Not populated when this was an 'add' of a route variant */
  originalItem?: CalculateInboundRouteVariantViewModelResult,
};

type ResultsOfVoyageEntryRoutesTryGetUpdatedVariants = {
  voyageEntryId: VoyageEntryId,
  resultsOfTryGetNewRouteVariantViewModels: Array<ResultOfTryGetNewRouteVariantViewModel>,
};

const tryGetNewRoutesForVoyageLocationsSequenceChanges =
  ({
    prevAllLegsWithFromToInfo,
    currAllLegsWithFromToInfo,
    avoidSecaZones,
    marketSegmentId,
    waypointsToExclude,
    worksheetId,
    graphVersion,
  }: InputOfSyncVoyageLocationsSequenceChangesToRoutes): AsyncAction<
    Array<ResultsOfVoyageEntryRoutesTryGetUpdatedVariants>,
  > =>
  async (dispatch, getState) =>
    await Promise.all(
      generatorFnUtils.arrayFromGeneratorFn(function* () {
        for (const currVersionOfVoyageEntry of currAllLegsWithFromToInfo) {
          const voyageEntryId = currVersionOfVoyageEntry.fromToInfo.legWithToLocation.id;
          const prevVersionOfVoyageEntry =
            prevAllLegsWithFromToInfo.find(
              (_) => _.fromToInfo.legWithToLocation.id === voyageEntryId
            ) || null;

          const prevVersionOfVoyageEntryFromLocationsGeoCoords =
            (prevVersionOfVoyageEntry &&
              prevVersionOfVoyageEntry.fromToInfo.fromLocations.map(
                (_) => _.locationInfo && _.locationInfo.geoCoords
              )) ||
            [];
          const currVersionOfVoyageEntryFromLocationsGeoCoords =
            currVersionOfVoyageEntry.fromToInfo.fromLocations.map(
              (_) => _.locationInfo && _.locationInfo.geoCoords
            );

          const prevToLocationGeoCoords =
            prevVersionOfVoyageEntry &&
            prevVersionOfVoyageEntry.fromToInfo.legWithToLocation.toLocationInfo &&
            prevVersionOfVoyageEntry.fromToInfo.legWithToLocation.toLocationInfo.geoCoords;
          const currToLocationGeoCoords =
            currVersionOfVoyageEntry.fromToInfo.legWithToLocation.toLocationInfo &&
            currVersionOfVoyageEntry.fromToInfo.legWithToLocation.toLocationInfo.geoCoords;

          // If there's no change to from-to pairs, don't change anything for this entry to keep the exact same instance and not trigger refreshes needlessly.
          if (
            Geolocation.areEqualNullSafe(prevToLocationGeoCoords, currToLocationGeoCoords) &&
            /*Checking for exact match on sequences, so include order and length. If only they are different, it means we have to reorder or apply the removals (the logic that follows handles these too - it simply builds a new array each time, reusing all instances from the previous state whose from-to match) */
            iterableUtils.areEqualSequencesWith(
              prevVersionOfVoyageEntryFromLocationsGeoCoords,
              currVersionOfVoyageEntryFromLocationsGeoCoords,
              Geolocation.areEqualNullSafe
            )
          )
            continue;

          const resultsOfTryGetNewRouteVariantViewModels: Array<ResultOfTryGetNewRouteVariantViewModel> =
            generatorFnUtils.arrayFromGeneratorFn(function* () {
              for (const currFromLocationGeoCoords of currVersionOfVoyageEntryFromLocationsGeoCoords) {
                const hasExistentMatchingFromToLocation =
                  Geolocation.areEqualNullSafe(prevToLocationGeoCoords, currToLocationGeoCoords) &&
                  prevVersionOfVoyageEntryFromLocationsGeoCoords.some((_) =>
                    Geolocation.areEqualNullSafe(_, currFromLocationGeoCoords)
                  );
                if (hasExistentMatchingFromToLocation) {
                  /* Part of #RouteRecalculationsRaceConditionPrevention - take the latest state, not the `prevAllLegsWithFromToInfo` when the enqueuing happenned.
                 We need to access state, and get the latest route as per current state, not the `prevVersionOfVoyageEntry` when the enqueuing happenned. This is in order to receive all calculations that finished in between (especially required because we need to produce a full `variants` array, even if we are just changing 1 item, and the other items may have been still calculating) */
                  const latestStateVersionOfVoyageEntry = getState().worksheetsById[
                    worksheetId
                  ].voyage.legs.find((_) => _.id === voyageEntryId);
                  if (!latestStateVersionOfVoyageEntry) {
                    return NonVoidTryFunctionUtils.createUnsuccessfulResult(
                      new VError(
                        {
                          info: {
                            worksheetId,
                            voyageEntryId,
                          },
                        },
                        'Could not find the voyage entry with the `voyageEntryId`. It could be that the entry was deleted since, or it is a sign of a bug. If it was deleted, then its an unimplemented concurrency control: any pending "requests to recalculate routes" for a voyage entry should be cancelled on deleting it.'
                      )
                    );
                  }
                  const latestStateVersionOfVoyageEntryInboundRouteVariants =
                    latestStateVersionOfVoyageEntry.inboundRoute.variants;
                  const existentRouteMatchingFromToLocation =
                    latestStateVersionOfVoyageEntryInboundRouteVariants.find((_) =>
                      Geolocation.areEqualNullSafe(
                        _.fromLocationGeoCoords,
                        currFromLocationGeoCoords
                      )
                    );
                  if (!existentRouteMatchingFromToLocation) {
                    throw logErrorDetailsOnlyToConsole(
                      new VError(
                        {
                          info: {
                            worksheetId,
                            voyageEntryId,
                            fromLocationGeoCoords: currFromLocationGeoCoords,
                          },
                        },
                        'Could not find the previous version of the route. This should never happen (also because of the #RouteRecalculationsRaceConditionPrevention) so it is likely a bug. Please investigate.'
                      ),
                      /*details: */ {
                        prevAllLegsWithFromToInfo,
                        currAllLegsWithFromToInfo,
                        latestStateVersionOfVoyageEntryInboundRouteVariants,
                      }
                    );
                  }

                  yield Promise.resolve({
                    isUnchangedExistingItemFromOrigin: true,
                    itemOrUnsuccessfulResult: existentRouteMatchingFromToLocation,
                  });
                } else {
                  yield PromiseUtils.fromAsyncBlock(async () => ({
                    isUnchangedExistingItemFromOrigin: false,
                    itemOrUnsuccessfulResult: await tryCalculateInboundRouteVariantViewModelFromTo(
                      /*fromLocationGeoCoordsOrNull: */ currFromLocationGeoCoords,
                      /*toLocationGeoCoordsOrNull: */ currToLocationGeoCoords,
                      /*options:*/ {
                        waypointsToExclude: waypointsToExclude,
                        avoidSecaZones: avoidSecaZones,
                        marketSegmentId: marketSegmentId,
                      },
                        undefined,
                        graphVersion
                    ),
                  }));
                }
              }
            });

          yield PromiseUtils.fromAsyncBlock(async () => ({
            voyageEntryId: currVersionOfVoyageEntry.fromToInfo.legWithToLocation.id,
            resultsOfTryGetNewRouteVariantViewModels: await Promise.all(
              resultsOfTryGetNewRouteVariantViewModels
            ),
          }));
        }
      })
    );

export const tryGetVoyageEntriesRoutesWithUpdatedVariants =
  ({
    predicateOfRouteVariantsToUpdate,
    avoidSecaZones,
    marketSegmentId,
    waypointsToExclude,
    worksheetId,
  }: {
    predicateOfRouteVariantsToUpdate: ({
      voyageEntryWithToLocation: IVoyageLegViewModel,
      inboundRouteVariant: InboundRouteVariantViewModel,
    }) => boolean,
    avoidSecaZones: boolean,
    marketSegmentId: string,
    waypointsToExclude: Array<IWaypointIdentity>,
    worksheetId: WorksheetId,
  }): AsyncAction<Array<ResultsOfVoyageEntryRoutesTryGetUpdatedVariants>> =>
  async (dispatch, getState) => {
    return await Promise.all(
      generatorFnUtils.arrayFromGeneratorFn(function* () {
        for (const voyageEntry of getState().worksheetsById[worksheetId].voyage.legs) {
          const resultsOfTryGetNewRouteVariantViewModels: Array<ResultOfTryGetNewRouteVariantViewModel> =
            generatorFnUtils.arrayFromGeneratorFn(function* () {
              for (const inboundRouteVariant of voyageEntry.inboundRoute.variants) {
                if (
                  !predicateOfRouteVariantsToUpdate({
                    voyageEntryWithToLocation: voyageEntry,
                    inboundRouteVariant: inboundRouteVariant,
                  })
                ) {
                  yield Promise.resolve({
                    isUnchangedExistingItemFromOrigin: true,
                    itemOrUnsuccessfulResult: inboundRouteVariant,
                  });
                } else
                  yield PromiseUtils.fromAsyncBlock(async () => {
                    return {
                      isUnchangedExistingItemFromOrigin: false,
                      originalItem: inboundRouteVariant,
                      itemOrUnsuccessfulResult:
                        await tryCalculateInboundRouteVariantViewModelFromTo(
                          /*fromLocationGeoCoordsOrNull: */ inboundRouteVariant.fromLocationGeoCoords,
                          /*toLocationGeoCoordsOrNull: */ Geolocation.fromObjectOrNullIfEmpty(
                            voyageEntry
                          ),
                          /*options:*/ {
                            waypointsToExclude: waypointsToExclude,
                            avoidSecaZones: avoidSecaZones,
                            marketSegmentId: marketSegmentId,
                          }
                        ),
                    };
                  });
              }
            });

          if (
            resultsOfTryGetNewRouteVariantViewModels.some(
              (_) => !_.isUnchangedExistingItemFromOrigin
            )
          )
            yield PromiseUtils.fromAsyncBlock(async () => ({
              voyageEntryId: voyageEntry.id,
              resultsOfTryGetNewRouteVariantViewModels: await Promise.all(
                resultsOfTryGetNewRouteVariantViewModels
              ),
            }));
        }
      })
    );
  };

export const applySuccessfulResultsOfVoyageEntryRoutesTryGetUpdatedVariants =
  ({ resultsOfVoyageEntryRoutesTryGetUpdatedVariants, worksheetId }) =>
  (dispatch) => {
    const unsuccessfulResults = resultsOfVoyageEntryRoutesTryGetUpdatedVariants
      .flatMap((_) => _.resultsOfTryGetNewRouteVariantViewModels)
      .filter((_) => NonVoidTryFunctionUtils.isUnsuccessfulResult(_.itemOrUnsuccessfulResult));

    if (unsuccessfulResults.length > 0)
      throw new VError(
        {
          info: {
            unsuccessfulResults,
          },
        },
        'Unsuccessful results found. Any supported errors should be cleared and a throw on any unknown errors (`handleEachUnsuccessfulRouteResultTypeThrowIfAnyUnknown` can be used for that).'
      );

    for (const triedVoyageEntryRouteVariant of resultsOfVoyageEntryRoutesTryGetUpdatedVariants) {
      dispatch(
        routeVariantsSet({
          worksheetId: worksheetId,
          voyageEntryId: triedVoyageEntryRouteVariant.voyageEntryId,
          newRouteVariantViewModels:
            triedVoyageEntryRouteVariant.resultsOfTryGetNewRouteVariantViewModels.map(
              (_) => _.itemOrUnsuccessfulResult
            ),
        })
      );
    }
  };

type InputOfSyncVoyageLocationsSequenceChangesToRoutes = {
  prevAllLegsWithFromToInfo: Array<VoyageEntryLocationsInfoWithDetails>,
  currAllLegsWithFromToInfo: Array<VoyageEntryLocationsInfoWithDetails>,
  avoidSecaZones: boolean,
  waypointsToExclude: Array<IWaypointIdentity>,
  worksheetId: WorksheetId,
  graphVersion?: number,
};

export const applyVoyageLocationsSequenceChangesToRoutes =
  ({
    prevAllLegsWithFromToInfo,
    currAllLegsWithFromToInfo,
    avoidSecaZones,
    marketSegmentId,
    waypointsToExclude,
    worksheetId,
    graphVersion,
  }: InputOfSyncVoyageLocationsSequenceChangesToRoutes) =>
  async (dispatch, getState) => {
    const resultsOfVoyageEntryRoutesTryGetUpdatedVariants: Array<ResultsOfVoyageEntryRoutesTryGetUpdatedVariants> =
      await dispatch(
        tryGetNewRoutesForVoyageLocationsSequenceChanges({
          prevAllLegsWithFromToInfo,
          currAllLegsWithFromToInfo,
          avoidSecaZones,
          marketSegmentId,
          waypointsToExclude,
          worksheetId,
          graphVersion,
        })
      );

    if (resultsOfVoyageEntryRoutesTryGetUpdatedVariants.length === 0) return; // Even if the logic below works just fine with `0`, the `return` here is useful as an aid for debugging or a slight optimization - don't keep calling the actions below for every change, even to unrelated properties

    handleEachUnsuccessfulRouteResultTypeThrowIfAnyUnknown(
      /*allResults: */ resultsOfVoyageEntryRoutesTryGetUpdatedVariants,
      /* unsuccessfulResultHandlersMap */ new Map([
        [
          RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable,
          (
            unsuccessfulResultsDueToWaypointsUnavoidable: Array<
              ResultOfTryGetNewRouteVariantViewModel<CalculateInboundRouteVariantViewModelUnsuccessfulResult>,
            >
          ) => {
            unhandledExceptionSinkSend(
              createWaypointPartiallyUnavoidableErrorCausedUnimplementedFeatureOfConveyingDistancesAreWrongToUser(
                (unsuccessfulResultsDueToWaypointsUnavoidable: unsuccessfulResultsDueToWaypointsUnavoidable)
              )
            );
            for (const unsuccessfulResultDueToWaypointsUnavoidable of unsuccessfulResultsDueToWaypointsUnavoidable) {
              unsuccessfulResultDueToWaypointsUnavoidable.itemOrUnsuccessfulResult =
                createEmptyRouteViewModelFromResult(
                  unsuccessfulResultDueToWaypointsUnavoidable.itemOrUnsuccessfulResult
                );
            }
          },
        ],
      ]),
      /* handleUnknownReasonsPresent: */ (
        unsupportedReasons: Set<nonVoidTryFunction.ReasonForUnsuccessfulResult>,
        allUnsuccessfulResults: Array<
          ResultOfTryGetNewRouteVariantViewModel<CalculateInboundRouteVariantViewModelUnsuccessfulResult>,
        >,
        allResults: Iterable<ResultOfTryGetNewRouteVariantViewModel>,
        createUnexpectedReasonError: () => Error
      ) => {
        unhandledExceptionSinkSend(
          createErrorUnimplementedFeatureOfConveyingDistancesAreWrongToUser({
            causeDescriptionForErrorMessage:
              'Reason for route error that is unknown to the app code/cannot be handled.',
            causeInfo: createUnexpectedReasonError(),
          })
        );
        /* We put some route variant for each from-to pair to preserve the current poor error-handling behavior (state gets part-corrupted, user is not told anything but 0 distances are shown).
         TODO - improve this by #TODOPreventStateCorruptionOnPartialFailure (see also https://dev.azure.com/clarksonscloud/Voyage-Estimation/_workitems/edit/69258) - don't leave the state partially corrupted, e.g. refrain from applying any updates until success and in case of failure ask the user to retry.
         Ideas about implementation:
       * use our `runBatchSyncingOnlyAtEnd` and:
         - revert the state using a previous Redux store state, allowing the user to retry without unloading any state
         - or offer a 'Refresh page' button, where the user will be able to retry the action
       * compare the approach with https://redux-saga.js.org/ - use the one that is best for the user and code simplicity (on a first sight it seems that the saga would require us to rewrite a lot of the code to `yield` style)
      */
        /*
        One consequence of not acting on #TODOPreventStateCorruptionOnPartialFailure is the below complexity of #ZeroingOutDistances_InLieuOf_ActioningTODOImplementConveyingOfUnsucccessfulDistanceCalc, which can be described as:
          If any 'unknown error' is found we zero-out all 'to be calculated distances', (so we even ignore successes!).
          NOTE: 'unknown error' is a case of routing calculation function *throwing* an `error`. It is different from the calculation being `unsuccessful` signified by it returning a `RouteCalculationUnsuccessfulResult`. One common case of an 'unknown error' will be a problem with reaching Routing API, due to connectivity problem or Routing API being down).
        TODO - The below zeroing-out of the distances & routes is the behavior that has been put here from the start ([Commit dc4ea4f83 - "Add integration with routing api - part 1."](https://dev.azure.com/clarksonscloud/Voyage-Estimation/_git/Web-App?path=%2Fsrc%2Factions%2Fvoyage.actions.js&version=GCdc4ea4f8328d037435bccd646765507ff1d4ca00&line=86&lineStyle=plain&lineEnd=87&lineStartColumn=1&lineEndColumn=1))
        and is left to preserve the behavior, but it can be solved differently as part of #TODOImplementConveyingOfUnsucccessfulDistanceCalc. TODO - when some conveying errors to the user is implemented do make sure the behavior here is consistent and remove this TODO comment.
      */
        /* As mentioned in #ZeroingOutDistances_InLieuOf_ActioningTODOImplementConveyingOfUnsucccessfulDistanceCalc, we zero-out all 'to be calculated distances', so we even ignore successes. The only ones that we keep are those that were not calculated. This is as per original behavior*/
        for (const triedVoyageEntryRouteVariant of allResults) {
          if (!triedVoyageEntryRouteVariant.isUnchangedExistingItemFromOrigin) {
            triedVoyageEntryRouteVariant.itemOrUnsuccessfulResult =
              createEmptyRouteViewModelFromResult(
                triedVoyageEntryRouteVariant.itemOrUnsuccessfulResult
              );
          }
        }
      }
    );

    function createEmptyRouteViewModelFromResult(
      itemOrUnsuccessfulResult:
        | InboundRouteVariantViewModel
        | CalculateInboundRouteVariantViewModelUnsuccessfulResult
    ) {
      return {
        ...itemOrUnsuccessfulResult,
        ...createEmptyRouteViewModel(),
        waypoints: itemOrUnsuccessfulResult.waypoints,

        /* Pass the reason, to allow the situation to be conveyed to the user on the right route variant, but no longer under `reasonIsUnsuccessful`, so that the result doesn't cause a throw in `applySuccessfulResultsOfVoyageEntryRoutesTryGetUpdatedVariants`.*/
        ...(itemOrUnsuccessfulResult.reasonIsUnsuccessful !== undefined
          ? {
              recalculationAttemptedButFailedForReason:
                itemOrUnsuccessfulResult.reasonIsUnsuccessful,
            }
          : {}),
        reasonIsUnsuccessful: undefined,
      };
    }

    await dispatch(
      applySuccessfulResultsOfVoyageEntryRoutesTryGetUpdatedVariants({
        resultsOfVoyageEntryRoutesTryGetUpdatedVariants:
          resultsOfVoyageEntryRoutesTryGetUpdatedVariants,
        worksheetId: worksheetId,
      })
    );
  };

/**
  A version of `handleEachUnsuccessfulResultTypeThrowIfAnyUnknown` tailored for route recalculation where each voyage-entry can have multiple route variants.
  It takes the input as `Array<ResultsOfVoyageEntryRoutesTryGetUpdatedVariants>` (which is the common return type for recalculation), and takes care of traversing its variants in search of failure reasons, so that the caller is only concerned with how to react for each type of failure reason.
 */
export function handleEachUnsuccessfulRouteResultTypeThrowIfAnyUnknown(
  resultsOfVoyageEntryRoutesTryGetUpdatedVariants: Array<ResultsOfVoyageEntryRoutesTryGetUpdatedVariants>,
  unsuccessfulResultHandlersMap: Map<
    nonVoidTryFunction.ReasonForUnsuccessfulResult,
    (
      unsuccessfulResultsWithThisReason: Array<
        ResultOfTryGetNewRouteVariantViewModel<CalculateInboundRouteVariantViewModelUnsuccessfulResult>,
      >
    ) => void,
  >,
  handleUnknownReasonsPresent?: (
    unsupportedReasons: Set<nonVoidTryFunction.ReasonForUnsuccessfulResult>,
    allUnsuccessfulResults: Array<
      ResultOfTryGetNewRouteVariantViewModel<CalculateInboundRouteVariantViewModelUnsuccessfulResult>,
    >,
    allResults: Iterable<ResultOfTryGetNewRouteVariantViewModel>,
    createUnexpectedReasonError: () => Error
  ) => void
) {
  handleEachUnsuccessfulResultTypeThrowIfAnyUnknown(
    /*allItemsWithResults: */ resultsOfVoyageEntryRoutesTryGetUpdatedVariants.flatMap(
      (_) => _.resultsOfTryGetNewRouteVariantViewModels
    ),
    /* getResult */ (_) => _.itemOrUnsuccessfulResult,
    /* unsuccessfulResultHandlersMap */ unsuccessfulResultHandlersMap,
    /* handleUnknownReasonsPresent: */ handleUnknownReasonsPresent
  );
}

/**
 * NOTE: the `routeVariants` is at the start of the name and this is intended. This is #NamePrefixesAsJsNamespacesForFlatFunctions
 */
export function routeVariantsSet({
  worksheetId,
  voyageEntryId,
  newRouteVariantViewModels,
}: VoyageEntryActionInput & {
  newRouteVariantViewModels: Array<InboundRouteVariantViewModel>,
}): VoyageEntryAction {
  return {
    type: VOYAGE_ROUTE_VARIANTS_SET,
    worksheetId: worksheetId,
    payload: {
      voyageEntryId: voyageEntryId,
      newRouteVariantViewModels: newRouteVariantViewModels,
    },
  };
}

/**
 * Rebuilds route information on all voyage entries (the information is contained in `inboundRoute.variants[]`), filling any missing entries.
 * Can be used in compound actions, where after a number of modifications one can cause all changes routes to be calculated in a single call.
 */
export const rebuildAndCalculateRoutesOnAllVoyageEntries =
  ({ worksheetId }) =>
  async (dispatch, getState) => {
    const worksheet = getState().worksheetsById[worksheetId];

    await dispatch(
      applyVoyageLocationsSequenceChangesToRoutes({
        prevAllLegsWithFromToInfo: [],
        currAllLegsWithFromToInfo: getAllLegsWithFromToInfo({
          vessels: worksheet.vessels,
          legs: worksheet.voyage.legs,
        }),
        avoidSecaZones: worksheet.voyage.avoidSecaZones,
        waypointsToExclude: getWaypointsToExcludeForNewRoutes(worksheet.voyage.legs),
        worksheetId: worksheetId,
      })
    );
  };
