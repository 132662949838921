import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import Downshift from 'downshift';
import NumericInput from 'components/numeric-input';
import WorkingDayFactors from './working-day-factors';

import './working-day-dropdown.scss';

type WorkingDayFactor = {
  id: string,
  factor: number,
  description: string,
};

type Props = {
  onChange: Function,
};

type State = {
  factors: Array<WorkingDayFactor>,
  selectedFactor: WorkingDayFactor,
};
const CUSTOM = 'CUSTOM';

class WorkingDayDropDown extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      factors: [
        ...WorkingDayFactors,
        {
          id: CUSTOM,
          factor: 1,
          description: 'User added factor',
        },
      ],
    };
  }

  onSelected = (selectedItem) => {
    if (!this.props.workingDay || selectedItem !== this.props.workingDay) {
      this.props.onChange && this.props.onChange(selectedItem);
    }
  };

  onInput = (value) => {
    let customFactorIndex = this.state.factors.findIndex((factor) => factor.id === CUSTOM);
    const newCustomFactor = {
      ...this.state.factors[customFactorIndex],
      factor: value,
    };
    const factors = this.state.factors.slice(0);
    factors.splice(customFactorIndex, 1, newCustomFactor);
    this.setState(
      () => ({ factors }),
      () => {
        this.onSelected(newCustomFactor);
      }
    );
  };

  shouldBeHighlighted(highlightedIndex: number, index: number) {
    return highlightedIndex === index;
  }

  highlightClass = 'working-day-dropdown__menu-item--highlighted';

  itemToString = (item: WorkingDayFactor | null) => {
    return item == null ? '' : item.description;
  };

  onKeyDownHandler = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
    selectHighlightedItem: Function,
    isOpen: boolean
  ): void => {
    const keyCode = event?.key;

    if (isNil(keyCode) === false && keyCode === 'Tab') {
      selectHighlightedItem();
    }
    if (isNil(keyCode) === false && keyCode === 'Escape' && !isOpen) {
      event.nativeEvent.preventDownshiftDefault = true;
    }
  };

  render() {
    const testId = this.props['data-testid'];
    return (
      <Downshift
        onChange={this.onSelected}
        selectedItem={this.props.workingDay}
        itemToString={this.itemToString}
      >
        {({
          getItemProps,
          isOpen,
          highlightedIndex,
          getLabelProps,
          getToggleButtonProps,
          getInputProps,
          selectHighlightedItem,
        }) => (
          <div className="working-day-dropdown">
            <button
              {...getLabelProps()}
              {...getToggleButtonProps()}
              {...getInputProps({
                onKeyDown: (event) => this.onKeyDownHandler(event, selectHighlightedItem, isOpen),
              })}
              data-testid={testId && `${testId}__downshift-button`}
              className="working-day-dropdown__selector"
            >
              {this.props.workingDay.id}
              <i className="has-icon icon--arrow-drop-down working-day-dropdown__selector--icon" />
            </button>
            <NumericInput
              className="working-day-dropdown__input"
              value={this.props.workingDay.factor}
              onInputChange={this.onInput}
              data-testid={testId && `${testId}__numeric-input`}
              maxDecimalDigits="4"
              minValue="1"
              maxValue="9.9999"
              diagnosticId="WorkingDayDropDown/ChangeWorkingDay"
            />
            {isOpen && this.state.factors.length > 0 ? (
              <div className="working-day-dropdown__menu">
                {this.state.factors.map((factor: WorkingDayFactor, index: number) => (
                  <div
                    {...getItemProps({ item: factor })}
                    key={factor.id}
                    data-testid={
                      testId &&
                      `${testId}__dropdown-item__${factor.id === CUSTOM ? 'CUSTOM' : index}`
                    }
                    className={`working-day-dropdown__menu-item
                      ${
                        this.shouldBeHighlighted(highlightedIndex, index) ? this.highlightClass : ''
                      }`}
                  >
                    <div className="working-day-dropdown__menu-item--part working-day-dropdown__menu-item--id">
                      <div className="working-day-dropdown__menu-item--id-inner">{factor.id}</div>
                    </div>
                    <div className="working-day-dropdown__menu-item--part working-day-dropdown__menu-item--factor">
                      <div className="working-day-dropdown__menu-item--factor-inner">
                        {factor.factor}
                      </div>
                    </div>
                    <div className="working-day-dropdown__menu-item--part working-day-dropdown__menu-item--description">
                      <div className="working-day-dropdown__menu-item--description-inner">
                        {factor.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

export default WorkingDayDropDown;
