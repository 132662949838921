import {
  SENSITIVITY_ACTIVE_CHANGED,
  SENSITIVITY_CHANGED,
  SENSITIVITY_VALUE_CHANGED,
  SENSITIVITY_FIRST_FUEL_GRADE_CHANGED,
  SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED,
  SENSITIVITY_SECOND_FUEL_GRADE_CHANGED,
  SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED,
} from 'constants/action-types/sensitivity-table';
import { buildAction } from 'utilities/redux';

export const sensitivityActiveChanged = (active) => buildAction(SENSITIVITY_ACTIVE_CHANGED, active);

export const sensitivityChanged = (sensitivity) => buildAction(SENSITIVITY_CHANGED, sensitivity);

export const sensitivityValueChanged = (sensitivity, value) =>
  buildAction(SENSITIVITY_VALUE_CHANGED, { sensitivity, value });

export const firstFuelGradeChanged = (fuelGrade) =>
  buildAction(SENSITIVITY_FIRST_FUEL_GRADE_CHANGED, fuelGrade);

export const firstFuelSensitivityChanged = (fuelSensitivity) =>
  buildAction(SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED, fuelSensitivity);

export const secondFuelGradeChanged = (fuelGrade) =>
  buildAction(SENSITIVITY_SECOND_FUEL_GRADE_CHANGED, fuelGrade);

export const secondFuelSensitivityChanged = (fuelSensitivity) =>
  buildAction(SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED, fuelSensitivity);
