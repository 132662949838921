import React, { FC, useState } from 'react';
import './styles.scss';

type Props = {
  pickProvidedName: () => void,
};
export const WorksheetModalInput: FC<Props> = ({ pickProvidedName }) => {
  const [inputValue, setInputValue] = useState('');
  const handleChange = (e) => {
    setInputValue(e.target.value);
    pickProvidedName(e.target.value);
  };

  return (
    <div className="worksheet-modal-input">
      <input
        autoFocus
        placeholder="Enter new workbook name"
        value={inputValue}
        className="calc-input"
        onChange={(e) => handleChange(e)}
        maxLength={160}
      />
    </div>
  );
};
