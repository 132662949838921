/*
 * This module is intended to be an abstraction point where we can migrate from AppInsights to Mixpanel.
 * Since the AppInsights implementation existed first, we use the AppInsights API and adapt it to Mixpanel.
 *
 * The `_mixpanelTrackEvent` variable represents the access to the Mixpanel API which must be set during
 * application startup. If the feature toggle is disabled then the variable remains `null` which prevents
 * any logs events from being sent to Mixpanel.
 *
 * The AppInsights calls are also controlled by a feature toggle. If the AppInsights calls are made, then
 * those functions that have a return type will return the value from the wrapped call to AppInsights. If
 * the feature is disabled by the toggle, then the returned value from those functions will be `undefined`.
 */

import {
  setupAppInsights as aiSetupAppInsights,
  startTrackPage as aiStartTrackPage,
  stopTrackPage as aiStopTrackPage,
  trackEvent as aiTrackEvent,
  trackPageView as aiTrackPageView,
} from '../app-insights';
import { isFeatureEnabled } from 'config/feature-control';
import { getGlobalContextMarketSegmentId } from '../get-global-context-props';
import { isUndefined } from 'constants/market-segments';

// ------------------------------------------------------------------------------------------------
// MIXPANEL
// ------------------------------------------------------------------------------------------------

export const eventDestination = {
  BOTH: 'both',
  DIAGNOSTICS: 'diagnostics',
  ANALYSIS: 'analysis',
};

const eventPrefix = 'Calc';

type MixpanelTrackEvent = (eventName: string, payload?: any, sendImmediately?: boolean) => void;

let _mixpanelTrackEvent: MixpanelTrackEvent = null;

export const setMixpanelTrackEvent = (mixpanelTrackEvent: MixpanelTrackEvent): void => {
  if (isFeatureEnabled('enableMixpanel')) {
    _mixpanelTrackEvent = mixpanelTrackEvent;
  }
};

// ------------------------------------------------------------------------------------------------
// APPINSIGHTS
// ------------------------------------------------------------------------------------------------

export const setupAppInsights = (config): void => {
  aiSetupAppInsights(config);
};

const sendEventToMixpanel = (eventName: string, payload: any) => {
  if (_mixpanelTrackEvent !== null) {
    _mixpanelTrackEvent(`${eventPrefix}: ${eventName}`, payload);
  }
};

const isMixpanelFeatureAndDestinationEnabled = (destination?: string) => {
  return (
    _mixpanelTrackEvent !== null &&
    (!destination ||
      destination === eventDestination.BOTH ||
      destination === eventDestination.ANALYSIS)
  );
};

const isAppinsightsFeatureAndDestinationEnabled = (destination?: string) => {
  return (
    !destination ||
    destination === eventDestination.BOTH ||
    destination === eventDestination.DIAGNOSTICS
  );
};

const trackEventHelper = (
  diagnosticId: string,
  eventType: string,
  properties: { [name: string]: string } = {},
  measurements: { [name: string]: number } = {},
  destination: string = eventDestination.ANALYSIS
): any => {
  properties['eventSource'] = diagnosticId;
  trackEvent(diagnosticId, eventType, properties, measurements, destination);
};

export const trackClickEvent = (
  diagnosticId: string,
  properties: { [name: string]: string } = {},
  measurements: { [name: string]: number } = {},
  destination: string = eventDestination.ANALYSIS
): any => {
  trackEventHelper(diagnosticId, 'Click', properties, measurements, destination);
};

export const trackChangeEvent = (
  diagnosticId: string,
  properties: { [name: string]: string } = {},
  measurements: { [name: string]: number } = {},
  destination: string = eventDestination.ANALYSIS
): any => {
  trackEventHelper(diagnosticId, 'Change', properties, measurements, destination);
};

export const trackEvent = (
  component: string,
  event: string,
  properties?: { [name: string]: string },
  measurements?: { [name: string]: number },
  destination?: string
): any => {
  let result = undefined;
  properties = properties || {};
  let marketSegmentId = getGlobalContextMarketSegmentId();
  properties['marketSegment'] = isUndefined(marketSegmentId) ? 'undefined' : marketSegmentId;
  if (isMixpanelFeatureAndDestinationEnabled(destination)) {
    const payload = {
      ...(properties || {}),
      ...(measurements || {}),
    };
    const { diagnosticId } = properties;
    let eventTitle = event;
    if (diagnosticId) {
      let eventObject = diagnosticId.replace(new RegExp('/', 'g'), ' ');
      eventTitle = `${eventObject} ${event}`;
    }
    sendEventToMixpanel(eventTitle, payload);
  }

  if (isAppinsightsFeatureAndDestinationEnabled(destination)) {
    result = aiTrackEvent(component, event, properties, measurements);
  }

  return result;
};

export const trackPageView = (
  name?: string,
  url?: string,
  properties?: { [name: string]: string },
  measurements?: { [name: string]: number },
  duration?: number,
  destination?: string
): any => {
  let result = undefined;

  if (isMixpanelFeatureAndDestinationEnabled(destination)) {
    const payload = {
      ...(properties || {}),
      ...(measurements || {}),
      $pageName: name,
    };
    let pageName = name.replace(new RegExp('/', 'g'), ' ');
    sendEventToMixpanel(`Track Page View ${pageName}`, payload);
  }

  if (isAppinsightsFeatureAndDestinationEnabled(destination)) {
    result = aiTrackPageView(name, url, properties, measurements, duration);
  }

  return result;
};

export const startTrackPage = (name?: string, destination?: string): any => {
  let result = undefined;

  if (isMixpanelFeatureAndDestinationEnabled(destination)) {
    const payload = {
      $pageName: name,
    };
    sendEventToMixpanel('Start Track Page', payload);
  }

  if (isAppinsightsFeatureAndDestinationEnabled(destination)) {
    result = aiStartTrackPage(name);
  }

  return result;
};

export const stopTrackPage = (
  name?: string,
  url?: string,
  properties?: { [name: string]: string },
  measurements?: { [name: string]: number },
  destination?: string
): any => {
  let result = undefined;

  if (isMixpanelFeatureAndDestinationEnabled(destination)) {
    const payload = {
      ...(properties || {}),
      ...(measurements || {}),
      $pageName: name,
    };
    sendEventToMixpanel('Stop Track Page', payload);
  }

  if (isAppinsightsFeatureAndDestinationEnabled(destination)) {
    result = aiStopTrackPage(name, url, properties, measurements);
  }

  return result;
};
