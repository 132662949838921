import React, { useEffect } from 'react';
import { LightbulbIcon } from 'components/icons';
import ViewWorkbooksButton from '../view-workbooks-button';
import { trackPageView, eventDestination } from 'diagnostics/calc-trackevents';
import { Helmet } from 'react-helmet';
import EmitNotice from 'components/notices-emitter/emit-notice';
import { DISMISS_THIS_WORKSHEET_NOTICES } from 'constants/enums/emit-notices';
import './../styles.scss';

export function WorksheetNotFoundPlaceholder({ workbook, worksheetId }) {
  useEffect(
    function trackingEffect() {
      trackPageView('Worksheet Not Found', undefined, {}, {}, undefined, eventDestination.ANALYSIS);
    },
    [] // No dependencies - fire only once - on mount
  );

  return (
    <div className="no-document-placeholder">
      <LightbulbIcon aria-label="Information" className="no-document-placeholder__type-icon" />
      <Helmet>
        <title>Sea/calc - {workbook.name}: Worksheet Does Not Exist</title>
      </Helmet>
      <section className="no-document-placeholder__user-message">
        <header>This worksheet doesn't exist</header>
        <p>
          Please select "VIEW WORKBOOKS" to select another workbook{' '}
          {workbook.isEditable
            ? 'or select + to add a blank worksheet to the current workbook'
            : ''}
        </p>
        <footer>
          <ViewWorkbooksButton diagnosticId="worksheet-not-found-placeholder/view-workbooks-button" />
        </footer>
      </section>

      <EmitNotice type={DISMISS_THIS_WORKSHEET_NOTICES} worksheetId={worksheetId} />
    </div>
  );
}

export default WorksheetNotFoundPlaceholder;
