import { SET_ACTIVE_VESSEL_ENTRY_ID } from '../../constants/action-types/user-state';

export const activeVesselEntryIdReducer = (state = null, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_VESSEL_ENTRY_ID:
      return action.payload.newActiveVesselEntryId;
    default:
      return state;
  }
};
