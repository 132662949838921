import React from 'react';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import NewGuid from 'utilities/guid';

import './styles.scss';

export function TextToolTip({ tooltipContent, children }) {
  let guid = NewGuid();

  return (
    <>
      <span data-tooltip-id={`tooltip-${guid}`} className="text-tooltip">
        {children}
      </span>
      <ReactTooltip place="bottom" id={`tooltip-${guid}`} className="ve-tooltip-default" noArrow>
        {tooltipContent}
      </ReactTooltip>
    </>
  );
}
