import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import {
  CARBON_COST_INCLUDE_IN_COSTS_CHANGED,
  EUA_PRICE_CHANGED,
  EUA_YEAR_CHANGED,
} from 'constants/action-types/worksheet/eua';

const initialState = {
  euaPrice: null,
  euaYear: null,
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return {
        ...state,
        carbonCostIncludeInCosts: action.payload.carbonCostIncludeInCosts ?? false,
        euaPrice: action.payload.euaPrice ?? null,
        isCrslEuaPrice: action.payload.isCrslEuaPrice ?? false,
        euaPriceUpdated: action.payload.euaPriceUpdated ?? null,
        isRefinitiv: action.payload.isRefinitiv ?? false,
      };
    case EUA_PRICE_CHANGED: {
      return {
        ...state,
        euaPrice: action.payload.euaPrice,
        isCrslEuaPrice: action.payload.isCrslEuaPrice,
        euaPriceUpdated: action.payload.euaPriceUpdated,
        isRefinitiv: action.payload.isRefinitiv,
      };
    }
    case EUA_YEAR_CHANGED: {
      return {
        ...state,
        euaYear: action.payload,
      };
    }
    case CARBON_COST_INCLUDE_IN_COSTS_CHANGED: {
      return {
        ...state,
        carbonCostIncludeInCosts: action.payload,
      };
    }
    default:
      return state;
  }
};
