import React from 'react';
import { format, toDate } from 'date-fns';
import { IconTooltip } from 'components/icon-tooltip';
import { iconEnum } from 'components/icons';

export const BunkerLastModifiedInfo = (props) => {
  let modifiedDate = props.modifiedDate ? format(toDate(props.modifiedDate), 'dd MMM yyyy') : '';

  return (
    <IconTooltip
      icon={iconEnum.InfoOutline}
      className={`bunkers-table-last-modified-info ${props.className || ''}`}
      disabled={!props.modifiedDate}
    >
      <div className="bunkers-table-last-modified-info-tooltip">
        {props.locationName ? (
          <p className="bunkers-table-last-modified-info-tooltip-text">
            Bunker Port:{' '}
            <span className="bunkers-table-last-modified-info-tooltip-highlight">
              {props.locationName}
            </span>
          </p>
        ) : null}
        <p className="bunkers-table-last-modified-info-tooltip-text">
          Date Issued:{' '}
          <span className="bunkers-table-last-modified-info-tooltip-highlight">{modifiedDate}</span>
        </p>
      </div>
    </IconTooltip>
  );
};
