import React from 'react';
import find from 'lodash/find';
import NumericInput from 'components/numeric-input';
import DropDown from 'components/dropdown';
import SpeedInput from './speed-input';
import classNames from 'classnames';
import { SpeedAndConsHistory } from './speed-and-cons-history';
import { ALL_FUEL_GRADES_IN_UI_ORDER } from 'constants/enums/fuel-grades';
import {
  CANAL,
  SAILING,
  ECO_SAILING,
  getSpeedAndConTypeById,
  isEcoSpeedAndConsumption,
} from 'constants/enums/speed-and-con-types';

export const consumptionMaxPrecision = 2;
export const consumptionMinValue = 0;
export const consumptionMaxValue = 999.99;

const isMainEngine: boolean = true;
const MovingSpeedAndCon = (props) => {
  const {
    speedAndCons,
    onConsumptionChanged,
    onFuelGradeChanged,
    onSpeedChanged,
    onActiveSpeedAndConChanged,
    isParcelVoyage,
    vesselId,
    marketSegmentId,
  } = props;

  const speedAndCon = find(speedAndCons, ['isActive', true]);
  const showSpeed = speedAndCon.type !== CANAL.id;
  const speedAndConType = getSpeedAndConTypeById(speedAndCon.type);

  let speedAndConLabel;
  const rowStatus = (isParcelVoyage, speedAndConLabel) => {
    if (speedAndConLabel.key === CANAL.key) return false;

    return isParcelVoyage
      ? !(speedAndConLabel.key === SAILING.key || speedAndConLabel.key === ECO_SAILING.key)
      : speedAndConLabel.key === SAILING.key || speedAndConLabel.key === ECO_SAILING.key;
  };

  if (speedAndCons.length > 1) {
    const speedAndConDropDownItems = speedAndCons.map((sc) => getSpeedAndConTypeById(sc.type));
    speedAndConLabel = (
      <DropDown
        items={speedAndConDropDownItems}
        selectedItem={speedAndConType}
        disabled={rowStatus(isParcelVoyage, speedAndConType)}
        onChange={onActiveSpeedAndConChanged(speedAndConDropDownItems)}
        asLabel
        diagnosticId="MovingSpeedAndCons/ChangeActiveSpeedAndCons"
      />
    );
  } else {
    speedAndConLabel = speedAndCon.label;
  }

  return (
    <div
      className={classNames('table-row', {
        'unused-row': rowStatus(isParcelVoyage, speedAndConType),
      })}
    >
      <div
        className={classNames('speed-and-cons-multi-vessel__moving--type', {
          'speed-and-cons-multi-vessel__moving--eco': isEcoSpeedAndConsumption(speedAndConType),
        })}
      >
        {speedAndConLabel}
      </div>
      <SpeedAndConsHistory
        sandTypeId={speedAndCon.type}
        vesselId={vesselId}
        modifiedBy={speedAndCon.auditInfo.modifiedBy}
        modifiedDate={speedAndCon.auditInfo.modifiedDate}
        marketSegmentId={marketSegmentId}
        className="speed-and-cons-multi-vessel__moving--info"
        disabled={rowStatus(isParcelVoyage, speedAndConType)}
      />
      <SpeedInput
        speed={speedAndCon.speed}
        showSpeed={showSpeed}
        speedAndConType={speedAndCon.type}
        isParcelVoyage={isParcelVoyage}
        onSpeedChanged={onSpeedChanged}
      />
      <NumericInput
        value={speedAndCon.consumption}
        onInputChange={onConsumptionChanged(speedAndCon.type, isMainEngine)}
        className="speed-and-cons-multi-vessel__moving--consumption"
        maxDecimalDigits={consumptionMaxPrecision}
        minValue={consumptionMinValue}
        maxValue={consumptionMaxValue}
        diagnosticId="MovingSpeedAndCons/ChangeConsumption"
      />
      <DropDown
        items={ALL_FUEL_GRADES_IN_UI_ORDER}
        selectedItem={speedAndCon.fuelGrade}
        onChange={onFuelGradeChanged(speedAndCon.type, isMainEngine)}
        className="status-specific-fuel-grade"
        disabled={rowStatus(isParcelVoyage, speedAndConType)}
        diagnosticId="MovingSpeedAndCons/ChangeFuelGrade"
      />
      <NumericInput
        value={speedAndCon.generatorConsumption}
        onInputChange={onConsumptionChanged(speedAndCon.type, !isMainEngine)}
        className="speed-and-cons-multi-vessel__moving--generator"
        maxDecimalDigits="2"
        minValue={consumptionMinValue}
        maxValue={consumptionMaxValue}
        diagnosticId="MovingSpeedAndCons/ChangeConsumption"
      />
      <DropDown
        items={ALL_FUEL_GRADES_IN_UI_ORDER}
        selectedItem={speedAndCon.generatorFuelGrade}
        onChange={onFuelGradeChanged(speedAndCon.type, !isMainEngine)}
        className="status-specific-fuel-grade"
        disabled={rowStatus(isParcelVoyage, speedAndConType)}
        diagnosticId="MovingSpeedAndCons/ChangeChangeFuelGrade"
      />
    </div>
  );
};

export default MovingSpeedAndCon;
