import { createSelector } from 'reselect';
import numbro from 'numbro';
import { getActiveVessel } from '../../../common-selectors/get-active-vessel';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';
import { singleOrThrow } from '../../../utilities/iterable';

export const selector = createSelector(
  (state) => getActiveVessel(state).netTimeCharter,
  (state) => getActiveVessel(state).grossTimeCharter,
  (state) => getActiveCalculationResult(state).timeCharter.voyageRateCalculatedGross,
  (state) =>
    singleOrThrow(state.worksheetsById[state.activeWorksheetId].cargoes).cargoRate.grossVoyageRate,
  (state) => getActiveCalculationResult(state).voyageRate.timeCharterRateCalculatedNet,
  (state) => getActiveCalculationResult(state).voyageRate.timeCharterRateCalculatedGross,
  (state) => getActiveCalculationResult(state).voyageRate.voyageProfitOrLossEstimate,
  (state) => getActiveCalculationResult(state).voyageRate.totalDaysVoyageProfitOrLossEstimate,
  (
    netTimeCharter,
    grossTimeCharter,
    voyageRateCalculatedGross,
    grossVoyageRate,
    timeCharterRateCalculatedNet,
    timeCharterRateCalculatedGross,
    voyageProfitOrLossEstimate,
    totalDaysVoyageProfitOrLossEstimate
  ) => {
    return {
      timeCharter: {
        showTimeCharter: netTimeCharter !== 0,
        netTimeCharter: formatNumber(netTimeCharter),
        grossTimeCharter: formatNumber(grossTimeCharter),
        grossVoyageRateCalculated: formatNumber(voyageRateCalculatedGross),
      },
      voyageRate: {
        showVoyageRate: grossVoyageRate !== 0,
        netTimeCharterCalculated: formatNumber(timeCharterRateCalculatedNet),
        grossTimeCharterCalculated: formatNumber(timeCharterRateCalculatedGross),
        grossVoyageRate: formatNumber(grossVoyageRate),
        voyageProfitOrLossEstimate: formatNumber(voyageProfitOrLossEstimate),
        totalDaysVoyageProfitOrLossEstimate: formatNumber(totalDaysVoyageProfitOrLossEstimate),
      },
    };
  }
);

const formatNumber = (input) => {
  return input
    ? numbro(input).format({
        thousandSeparated: true,
        mantissa: 2,
      })
    : '';
};
