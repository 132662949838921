import React, { useState } from 'react';
import { Menu, MenuItem } from 'components/menu';
import { iconEnum } from 'components/icons';
import classNames from 'classnames';
import { IconButton, IconButtonToolTip } from 'components/button';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { OrderType, sortArrayObjectsBy } from '../../../../utilities/array-object-sort';
import './styles.scss';

export const TabsDropdown = ({
  isDisabled,
  worksheets,
  activeWorksheetId,
  onClick,
  workbookId,
  updateSortOrderToWorkbook,
  sortOrderType,
  workbook,
}) => {
  let cloneWorksheetsObj = worksheets.map((worksheet) => ({ ...worksheet }));
  const [currentSortOrderType, setCurrentSortOrderType] = useState(sortOrderType || OrderType.NONE);

  const activeWorksheet = worksheets.find((item) => item.id === activeWorksheetId);

  const activeIndex = worksheets.findIndex((item) => item.id === activeWorksheetId);

  const getEventName = (sortOrderType: OrderType) => {
    let eventName = undefined;
    switch (sortOrderType) {
      case OrderType.NONE:
        eventName = 'Worksheet sorted by default order';
        break;
      case OrderType.ALPHA_ASC:
        eventName = 'Worksheet sorted by alphabetically ascending';
        break;
      default:
        eventName = 'Worksheet sorted by default order';
    }
    return eventName;
  };

  const getListOfSortedWorksheets = () => {
    cloneWorksheetsObj = sortArrayObjectsBy(worksheets, currentSortOrderType, 'name');
    return cloneWorksheetsObj.map((ow, index) => (
      <MenuItem
        key={ow.id}
        className={getCssClassForWorksheet(ow.id, activeWorksheetId)}
        onClick={() => onClick(ow.id, index)}
      >
        {ow.name}
      </MenuItem>
    ));
  };

  const isSelectedOrder = (order: OrderType) => {
    return currentSortOrderType === order;
  };

  const menuClosed = async () => {
    // track sorting events
    const diagnosticId = 'Worksheet Sorting';
    trackEvent(
      diagnosticId,
      getEventName(currentSortOrderType),
      {
        diagnosticId: diagnosticId,
      },
      {},
      eventDestination.ANALYSIS
    );

    // update sort order
    await updateSortOrderToWorkbook(workbookId, currentSortOrderType, workbook);
  };

  let selectedTab = activeWorksheet && activeWorksheetId && (
    <MenuItem
      key={activeWorksheetId}
      className="tabs-dropdown__tab--active"
      onClick={() => onClick(activeWorksheetId, activeIndex)}
    >
      <div className="sorting-container">
        <div className="sorting-row">
          <div className="sorting-row-title">{activeWorksheet.name}</div>
          <div className="sorting-row-icon-section">
            <IconButtonToolTip
              className={classNames('sorting-row-icon', {
                'sorting-row-icon-active': isSelectedOrder(OrderType.ALPHA_ASC),
              })}
              icon={iconEnum.Sort}
              onClick={() => setCurrentSortOrderType(OrderType.ALPHA_ASC)}
              text="Sort by worksheet name ascending"
              diagnosticId="TabsDropDown/SortByAscending"
            />
            <IconButtonToolTip
              className={classNames('sorting-row-icon', {
                'sorting-row-icon-active': isSelectedOrder(OrderType.NONE),
              })}
              icon={iconEnum.NoSort}
              onClick={() => setCurrentSortOrderType(OrderType.NONE)}
              text="Use default sort order"
              diagnosticId="TabsDropDown/DefaultSorting"
            />
          </div>
        </div>
      </div>
    </MenuItem>
  );

  return (
    <Menu
      disabled={isDisabled}
      icon={iconEnum.ChevronDown}
      promotedItem={selectedTab}
      menuClosed={menuClosed}
    >
      {getListOfSortedWorksheets()}
    </Menu>
  );
};

const getCssClassForWorksheet = (worksheetId, activeWorksheetId) =>
  worksheetId === activeWorksheetId ? 'tabs-dropdown__tab--active' : null;
