import React from 'react';
import { withRouter } from 'utilities/router-wrapper';
import { isEmptyGuid } from 'utilities/guid';
import ProgressBar, { Size } from 'components/progress-bar';

const WorksheetRedirector = withRouter(function ({
  workbook,
  worksheets,
  router,
  redirectTo,
  redirectUrl,
}) {
  const fallbackWorksheet =
    worksheets.find((_) => _.id === workbook.lastViewedWorksheetId) || worksheets[0];
  const fallbackWorksheetId = fallbackWorksheet && fallbackWorksheet.id;

  React.useEffect(
    function () {
      // if there is no valid fallback worksheet identifier
      // or the fallback identifier is actually an empty guid
      // a redirection cannot be performed
      if (!fallbackWorksheetId || isEmptyGuid(fallbackWorksheetId)) {
        return;
      }

      // if user specified a custom redirectTo call, then use that instead
      if (redirectTo) {
        redirectTo(redirectUrl || `/workbook/${workbook.id}/worksheet/${fallbackWorksheetId}`);
        return;
      }

      router.navigate(redirectUrl || `/workbook/${workbook.id}/worksheet/${fallbackWorksheetId}`);
    },
    [fallbackWorksheetId, workbook.id]
  );

  return fallbackWorksheet ? <ProgressBar size={Size.XLARGE} /> : null;
});

export default WorksheetRedirector;
