import { COMMISSION_CHANGED } from 'constants/action-types/worksheet/commission';

export function commissionUpdated(brokerCommission, worksheetId) {
  return {
    payload: {
      brokerCommission,
    },
    worksheetId,
    type: COMMISSION_CHANGED,
  };
}
