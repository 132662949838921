import * as actionTypes from 'constants/action-types/worksheet/vessel';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import vesselReducer, { vesselInitialState, getVesselViewModelFromVesselDto } from './vessel';
import { RATE_COMMISSION_TIME_CHARTER_CHANGED } from 'constants/action-types/worksheet/rates';
import logger from 'diagnostics/logger';

const initialState = [vesselInitialState];

export const vesselsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VESSELS_ADD:
      return [...state, action.payload];
    case actionTypes.VESSELS_REMOVE:
      return state.length > 1 ? state.filter((vessel) => vessel.entryId !== action.payload) : state;
    case LOAD_WORKSHEET:
      return action.payload.vessels.map((vessel) =>
        getVesselViewModelFromVesselDto(
          vessel,
          action.payload.marketSegmentId,
          action.payload.isGeneratorSpeedAndConsOn || false
        )
      );
    case RATE_COMMISSION_TIME_CHARTER_CHANGED:
      /** These action types are applied to all vessels,
       * perhaps we can consider adding `isAllVesselsAction` as per the
       * [comment](https://dev.azure.com/clarksonscloud/Voyage-Estimation/_git/Web-App/pullrequest/20403?_a=files&path=%2Fsrc%2Freducers%2Fworksheet%2Fvessels%2Findex.js&discussionId=144664)
       */
      return state.map((vessel) => vesselReducer(vessel, action));
    case actionTypes.VESSELS_MOVED:
      if (
        typeof action.sourceIndex === 'undefined' ||
        typeof action.destinationIndex === 'undefined'
      ) {
        return [...state];
      }
      let vesselsClone = [...state];
      let selectedVessel = vesselsClone[action.sourceIndex];
      vesselsClone.splice(action.sourceIndex, 1);
      vesselsClone.splice(action.destinationIndex, 0, selectedVessel);
      return vesselsClone;

    default:
      if (typeof action.vesselEntryId === 'undefined') {
        return state;
      }
      const vesselIndex = state.findIndex((v) => v.entryId === action.vesselEntryId);
      if (vesselIndex === -1) {
        logger.warn(
          `Action triggered on a vessel with id ${action.vesselEntryId} which no longer exists`
        );
        return state;
      }
      const vessel = state[vesselIndex];
      const newVessel = vesselReducer(vessel, action);
      if (newVessel === vessel) {
        return state;
      }
      const newState = state.slice(0);
      newState.splice(vesselIndex, 1, newVessel);
      return newState;
  }
};

export default vesselsReducer;
