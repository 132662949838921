import stringEnum, { StringEnum } from 'utilities/enum/string-enum';
import VError from 'verror';

const fuelGradeCodesArray = ['CST380', 'CST180', 'MDO', 'MGO', 'LSMGO', 'ULSFO', 'VLSFO', 'LNG'];
// For now, need to duplicate to declare the eraseable type
// TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered,
// or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
export type FuelGradeCode =
  | 'CST380'
  | 'CST180'
  | 'MDO'
  | 'MGO'
  | 'LSMGO'
  | 'ULSFO'
  | 'VLSFO'
  | 'LNG';

export const fuelGradeCodes: StringEnum<FuelGradeCode> = stringEnum(fuelGradeCodesArray);

export type FuelGradeId = number;

export type FuelGrade = {
  key: FuelGradeId,
  code: FuelGradeCode,
  label: string,
};

export const FUEL_GRADE_380CST: FuelGrade = {
  key: 1,
  code: fuelGradeCodes.CST380,
  label: '380 CST',
};
export const FUEL_GRADE_180CST: FuelGrade = {
  key: 3,
  code: fuelGradeCodes.CST180,
  label: '180 CST',
};
export const FUEL_GRADE_MDO: FuelGrade = { key: 7, code: 'MDO', label: 'MDO' };
export const FUEL_GRADE_MGO: FuelGrade = { key: 8, code: 'MGO', label: 'MGO' };
export const FUEL_GRADE_LSMGO: FuelGrade = {
  key: 18,
  code: fuelGradeCodes.LSMGO,
  label: 'LSMGO 0.1',
};
export const FUEL_GRADE_ULSFO: FuelGrade = {
  key: 19,
  code: fuelGradeCodes.ULSFO,
  label: 'ULSFO 0.1',
};
export const FUEL_GRADE_VLSFO: FuelGrade = {
  key: 20,
  code: fuelGradeCodes.VLSFO,
  label: 'VLSFO 0.5',
};
export const FUEL_GRADE_LNG: FuelGrade = {
  key: 21,
  code: fuelGradeCodes.LNG,
  label: 'LNG',
};

export const ALL_FUEL_GRADES_IN_UI_ORDER: Array<FuelGrade> = [
  FUEL_GRADE_380CST,
  FUEL_GRADE_180CST,
  FUEL_GRADE_VLSFO,
  FUEL_GRADE_MGO,
  FUEL_GRADE_MDO,
  FUEL_GRADE_LSMGO,
  FUEL_GRADE_ULSFO,
  //TODO find out why this breaks everything, is it needed?
  FUEL_GRADE_LNG,
];

const uiOrdinalByFuelGradeCode = Object.fromEntries(
  ALL_FUEL_GRADES_IN_UI_ORDER.map((fuelGrade: FuelGrade, index) => [fuelGrade.code, index])
);

export const getUiOrdinalForFuelGradeCode = (fuelGradeCode: FuelGradeCode): number => {
  const result = uiOrdinalByFuelGradeCode[fuelGradeCode];

  if (result === undefined)
    throw new VError(
      { info: { fuelGradeCode } },
      "No fuel found for `fuelGradeCode` (see the value in this error's properties)"
    );

  return result;
};

/* Use this instead of `ALL_FUEL_GRADES_IN_UI_ORDER` when the order is irrelevant */
export const ALL_FUEL_GRADES: Array<FuelGrade> = ALL_FUEL_GRADES_IN_UI_ORDER;

const FUEL_GRADES_MAP: { [FuelGradeId]: FuelGrade } = Object.fromEntries(
  ALL_FUEL_GRADES.map((_) => [_.key, _])
);

export const fuelGradesByCode: {
  [FuelGradeCode]: FuelGrade,
} = Object.fromEntries(ALL_FUEL_GRADES.map((_) => [_.code, _]));

export const getFuelGradeFor = (key: FuelGradeId): FuelGrade => {
  return FUEL_GRADES_MAP[key];
};

export const getFuelGradeCodeFromId = (fuelGradeId: FuelGradeId): FuelGradeCode =>
  getFuelGradeFor(fuelGradeId).code;

export const getFuelGradeByCode = (fuelGradeCode: FuelGradeCode) => {
  const result = fuelGradesByCode[fuelGradeCode];

  if (!result)
    throw new VError(
      { info: { fuelGradeCode } },
      "No fuel found for `fuelGradeCode` (see the value in this error's properties)"
    );

  return result;
};

export const getFuelGradeById = (fuelGradeId: FuelGradeId) =>
  getFuelGradeByCode(getFuelGradeCodeFromId(fuelGradeId));
