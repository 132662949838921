import guid, { emptyGuid } from 'utilities/guid';
import find from 'lodash/find';
import forIn from 'lodash/forIn';
import isNil from 'lodash/isNil';
import {
  FUEL_GRADE_180CST,
  FUEL_GRADE_380CST,
  FUEL_GRADE_LSMGO,
  FUEL_GRADE_VLSFO,
  FUEL_GRADE_ULSFO,
  FUEL_GRADE_MDO,
  FUEL_GRADE_MGO,
  FUEL_GRADE_LNG,
} from 'constants/enums/fuel-grades';
import { mapWorksheetViewModelToAdditionalExpensesCommonModel } from './worksheet-common/additional-expenses';
import type { MarketSegmentId } from 'constants/market-segments';
import { isDry } from 'constants/market-segments';

function buildWorksheetRequest(worksheet: IWorksheetViewModel): IWorksheetDto {
  return {
    id: worksheet.id || guid(),
    name: worksheet.name,
    vessels: worksheet.vessels.map((vessel) =>
      mapWorksheetViewModelVesselToRequest(
        vessel ||
          {} /* TODO Don't accept null - ensure callers don't pass it and remove this - [Clean Code > "Don't pass null"](https://www.investigatii.md/uploads/resurse/Clean_Code.pdf) */,
        worksheet.marketSegmentId
      )
    ),
    rates: mapWorksheetViewModelRatesToRequest(worksheet),
    cargoes: mapWorksheetViewModelCargoesToRequest(worksheet),
    voyage: mapWorksheetViewModelVoyageToRequest(worksheet),
    bunkers: mapWorksheetViewModelBunkersToRequest(worksheet),
    canals: mapWorksheetViewModelCanalsToRequest(worksheet),
    additionalExpenses: mapWorksheetViewModelToAdditionalExpensesCommonModel(worksheet).map(
      mapCommonAdditionalExpenseToWorksheetApiRequest
    ),
    workbookId: worksheet.workbookId,
    marketSegmentId: worksheet.marketSegmentId,
    additionalIncomes: mapAdditionalIncomes(worksheet),
    showUnusedBunkers: worksheet.showUnusedBunkers,
    euaPrice: worksheet.eua.euaPrice,
    isCrslEuaPrice: worksheet.eua.isCrslEuaPrice,
    isRefinitiv: worksheet.eua.isRefinitiv,
    euaPriceUpdated: worksheet.eua.euaPriceUpdated,
    carbonCostIncludeInCosts: worksheet.eua.carbonCostIncludeInCosts,
    totalWaitingDays: worksheet.voyage?.totalWaitingDays,
    delayUnit: worksheet.voyage?.delayUnit,
    showEditGeneratorSpeedAndCons: worksheet.showEditGeneratorSpeedAndCons,
  };
}

const mapWorksheetViewModelVesselToRequest = (
  vesselViewModel: IVesselViewModel,
  marketSegmentId: MarketSegmentId
) => {
  return {
    entryId: vesselViewModel.entryId,
    vesselId: vesselViewModel.vesselId || 0,
    name: vesselViewModel.name || '',
    openPosition: buildOpenPosition(vesselViewModel.openPosition),
    initialOpenPosition: buildOpenPosition(vesselViewModel.initialOpenPosition),
    deadweight: vesselViewModel.deadWeight || 0,
    draft: vesselViewModel.draft || 0,
    draftUnit: vesselViewModel.draftUnit || 0,
    tpcmi: vesselViewModel.tpcmi || 0,
    immersionUnit: vesselViewModel.immersionUnit || 0,
    grain: vesselViewModel.grain || 0,
    grainUnit: vesselViewModel.grainUnit || 0,
    constants: vesselViewModel.constants || 0,
    buildDate: vesselViewModel.buildDate || null,
    shipyard: vesselViewModel.shipyard || '',
    buildCountry: vesselViewModel.buildCountry || '',
    isBalticVessel: vesselViewModel.isBalticVessel || false,
    isParcelVoyage: vesselViewModel.isParcelVoyage || false,
    fleetTypeIds: vesselViewModel.fleetTypeIds || [],
    nextPortOfCallIsInEea: vesselViewModel.nextPortOfCallIsInEea || false,
    speedAndConsumptions: mapViewModelSpeedAndConsumptionsToDto(
      vesselViewModel.speedAndConsumptions || {},
      marketSegmentId
    ),
    grossTimeCharter: vesselViewModel.grossTimeCharter || 0,
    netTimeCharter: vesselViewModel.netTimeCharter || 0,
    ballastBonus: vesselViewModel.ballastBonus || 0,
    scrubber: {
      typeId: (vesselViewModel.scrubber && vesselViewModel.scrubber.typeId) || null,
      pendingTypeId: (vesselViewModel.scrubber && vesselViewModel.scrubber.pendingTypeId) || null,
    },
  };
};

const buildOpenPosition = (worksheetOpenPosition = { openDate: {} }) => {
  const {
    id,
    locationTypeId,
    name,
    zone,
    country,
    longitude,
    latitude,
    openDate,
    isInSeca,
    prevPortOfCallIsInEea,
  } = worksheetOpenPosition;

  const openPosition = {
    location: {
      id: id || emptyGuid,
      locationTypeId: locationTypeId || emptyGuid,
      name: name || '',
      zone: zone || '',
      country: country || '',
      position: buildPosition(longitude, latitude),
      isInSeca,
      prevPortOfCallIsInEea,
    },
    date: {
      from: openDate.start || null,
      to: openDate.end || null,
      displayText: openDate.displayText || '',
    },
  };

  return openPosition;
};

const buildPosition = (longitude, latitude) => {
  if (isNil(longitude) || isNil(latitude)) {
    return null;
  }

  return {
    longitude,
    latitude,
  };
};

const mapViewModelSpeedAndConsumptionsToDto = (
  speedAndConsumptionsViewModel: ISpeedAndConsumptionsViewModel,
  marketSegmentId: MarketSegmentId
) => {
  return {
    ballast: buildSpeedAndConsumption(speedAndConsumptionsViewModel.ballast || {}),
    laden: buildSpeedAndConsumption(speedAndConsumptionsViewModel.laden || {}),
    ecoBallast: buildSpeedAndConsumption(speedAndConsumptionsViewModel.ecoBallast || {}),
    ecoLaden: buildSpeedAndConsumption(speedAndConsumptionsViewModel.ecoLaden || {}),
    canal: buildSpeedAndConsumption(speedAndConsumptionsViewModel.canal || {}),
    loading: buildSpeedAndConsumption(speedAndConsumptionsViewModel.loading || {}),
    // for dryCargo, Loading should be the same as Discharging (Loading is displayed as Working on UI for DryCargo)
    discharging: isDry(marketSegmentId)
      ? buildSpeedAndConsumption(speedAndConsumptionsViewModel.loading || {})
      : buildSpeedAndConsumption(speedAndConsumptionsViewModel.discharging || {}),
    idle: buildSpeedAndConsumption(speedAndConsumptionsViewModel.idle || {}),
    manoeuvring: buildSpeedAndConsumption(speedAndConsumptionsViewModel.manoeuvring || {}),
    sailing: buildSpeedAndConsumption(speedAndConsumptionsViewModel.sailing || {}),
    ecoSailing: buildSpeedAndConsumption(speedAndConsumptionsViewModel.ecoSailing || {}),
    zoneSpecific: {
      seca: {
        fuelGradeId:
          (speedAndConsumptionsViewModel.zoneSpecific &&
            speedAndConsumptionsViewModel.zoneSpecific.seca.fuelGradeId) ||
          null,
      },
      sludgeDischargeBan: {
        fuelGradeId:
          (speedAndConsumptionsViewModel.zoneSpecific &&
            speedAndConsumptionsViewModel.zoneSpecific.sludgeDischargeBan.fuelGradeId) ||
          null,
      },
      secaAndSludgeDischargeBan: {
        fuelGradeId:
          (speedAndConsumptionsViewModel.zoneSpecific &&
            speedAndConsumptionsViewModel.zoneSpecific.secaAndSludgeDischargeBan.fuelGradeId) ||
          null,
      },
    },
    scrubberConsumption: speedAndConsumptionsViewModel.scrubberConsumption || 0,
  };
};

const buildSpeedAndConsumption = (worksheetSpeedAndCons) => ({
  auditInfo: worksheetSpeedAndCons.auditInfo || {},
  speed: worksheetSpeedAndCons.speed || 0,
  consumption: worksheetSpeedAndCons.consumption || 0,
  fuelGrade: worksheetSpeedAndCons.fuelGrade || FUEL_GRADE_380CST.key,
  generatorConsumption: worksheetSpeedAndCons.generatorConsumption || 0,
  generatorFuelGrade: worksheetSpeedAndCons.generatorFuelGrade || FUEL_GRADE_MDO.key,
  isActive: isNil(worksheetSpeedAndCons.isActive) ? true : worksheetSpeedAndCons.isActive,
});

const mapWorksheetViewModelRatesToRequest = (worksheet) => {
  const worksheetRates = worksheet.rates || {};

  const rates = {
    brokerCommission: worksheetRates.brokerCommissionTimeCharter || 0,
    addressCommission: worksheetRates.addressCommissionTimeCharter || 0,
  };

  return rates;
};

const mapWorksheetViewModelCargoesToRequest = (worksheet) => {
  if (isNil(worksheet.cargoes)) {
    return [];
  }

  return worksheet.cargoes.map(buildCargo);
};

const buildCargo = (worksheetCargo) => {
  const cargo = {
    id: worksheetCargo.id || emptyGuid,
    cargoTypeId: worksheetCargo.cargoTypeId,
    name: worksheetCargo.name || '',
    cargoCode: worksheetCargo.code || '',
    specificGravity: worksheetCargo.specificGravity || 0,
    stowage: worksheetCargo.stowage || 0,
    stowageUnit: worksheetCargo.stowageUnit || 0,
    cargoRate: {
      id: worksheetCargo.cargoRate.id,
      cargoId: worksheetCargo.id || emptyGuid,
      worksheetId: worksheetCargo.worksheetId,
      grossVoyageRate: worksheetCargo.cargoRate.grossVoyageRate || 0,
      rateType: worksheetCargo.cargoRate.rateType || 1,
      flatRate: worksheetCargo.cargoRate.flatRate || 0,
      worldscale: worksheetCargo.cargoRate.worldscale || 0,
      lumpSum: worksheetCargo.cargoRate.lumpSum || 0,
    },
    brokerCommission: worksheetCargo.brokerCommissionVoyageRate || 0,
    addressCommission: worksheetCargo.addressCommissionVoyageRate || 0,
  };

  return cargo;
};

const mapWorksheetViewModelVoyageToRequest = (worksheet) => {
  const worksheetVoyage = worksheet.voyage || {};

  const voyage = {
    draftUnit: worksheetVoyage.draftUnit || 0,
    avoidSecaZones: worksheetVoyage.avoidSecaZones || false,
    shouldAutoCalculateIntake: worksheetVoyage.shouldAutoCalculateIntake || false,
    legs: isNil(worksheetVoyage.legs) ? [] : worksheetVoyage.legs.map(buildVoyageLeg),
  };

  return voyage;
};

function buildVoyageLeg(worksheetVoyageLeg: IVoyageLegViewModel): IVoyageLeg {
  const voyageLeg = {
    id: worksheetVoyageLeg.id || emptyGuid,
    voyageLegType: worksheetVoyageLeg.type || 0,
    location: {
      id: worksheetVoyageLeg.locationId || emptyGuid,
      name: worksheetVoyageLeg.name || '',
      zone: worksheetVoyageLeg.zone || '',
      country: worksheetVoyageLeg.country || '',
      position: buildPosition(worksheetVoyageLeg.longitude, worksheetVoyageLeg.latitude),
      isInSeca: worksheetVoyageLeg.isInSeca,
      isInEea: worksheetVoyageLeg.isInEea,
      isInSecaOverridden: worksheetVoyageLeg.isInSecaOverridden ?? false,
    },
    gear: worksheetVoyageLeg.gear || 0,
    draft: worksheetVoyageLeg.draft || 0,
    salinity: worksheetVoyageLeg.salinity || 0,
    cargoId: worksheetVoyageLeg.cargoId || emptyGuid,
    loadDischargeConsumptionsOverrideQty: worksheetVoyageLeg.loadDischargeConsumptionsOverrideQty,
    quantity: worksheetVoyageLeg.cargoQuantity || 0,
    portOperationalRestrictionIds: Array.from(worksheetVoyageLeg.portOperationalRestrictionIds),
    loadDischargeRate: worksheetVoyageLeg.loadDischargeRate || 0,
    loadDischargeRateUnit: worksheetVoyageLeg.rateUnit || 0,
    workingDayFactor: worksheetVoyageLeg.workingDayMultiplier || 0,
    workingDayShorthand: worksheetVoyageLeg.workingDayType || 0,
    delay: worksheetVoyageLeg.delay || 0,
    delayUnit: worksheetVoyageLeg.delayUnit || 0,
    turnTime: worksheetVoyageLeg.turnAroundTime || 0,
    turnTimeUnit: worksheetVoyageLeg.turnAroundTimeUnit || 0,
    weatherFactor: worksheetVoyageLeg.weatherFactor || 0,
    cost: worksheetVoyageLeg.portCost || 0,
    inboundRoute: {
      variants: worksheetVoyageLeg.inboundRoute.variants.map((variant) => ({
        fromLocationGeoCoords: variant.fromLocationGeoCoords,
        totalDistance: variant.totalDistance || 0,
        secaDistance: variant.secaDistance || 0,
        routeRetrievedFromRoutingApiOn: variant.routeRetrievedFromRoutingApiOn,
        routeRetrievedWithGraphVersion: variant.routeRetrievedWithGraphVersion,
        path: buildPath(variant.path),
        waypoints: buildWaypoints(variant.waypoints),
        subtotalsByOperationalRestrictionIds:
          variant.subtotalsByOperationalRestrictionIds &&
          variant.subtotalsByOperationalRestrictionIds.map((_) => ({
            ..._,
            operationalRestrictionIds: Array.from(_.operationalRestrictionIds),
          })),
      })),
    },
  };
  return voyageLeg;
}

const buildWaypoints = (worksheetWaypoints) => {
  if (isNil(worksheetWaypoints)) {
    return [];
  }

  return worksheetWaypoints.map(buildWaypoint);
};

const buildPath = (coordinates) => {
  if (isNil(coordinates)) {
    return [];
  }

  const path = coordinates.map((coordinate) => {
    return { longitude: coordinate[0], latitude: coordinate[1] };
  });

  return path;
};

const buildWaypoint = (worksheetWaypoint = {}) => {
  const waypoint = {
    id: worksheetWaypoint.locationId || emptyGuid,
    isAvoided: worksheetWaypoint.avoid || false,
    isUnavoidable: worksheetWaypoint.unavoidable || false,
  };

  return waypoint;
};

const mapWorksheetViewModelBunkersToRequest = (worksheet) =>
  worksheet.bunkerExpenses && worksheet.bunkerExpenses.bunkers
    ? worksheet.bunkerExpenses.bunkers.map((bunker) => buildBunker(bunker))
    : [];

const buildBunker = (bunker) => ({
  voyageLegId: bunker.key || emptyGuid,
  priceIssuedDate: bunker.priceIssuedDate,
  locationName: bunker.locationName,
  locationId: bunker.locationId,
  csT380: buildQuantityPriceSet(bunker, FUEL_GRADE_380CST.key),
  csT180: buildQuantityPriceSet(bunker, FUEL_GRADE_180CST.key),
  mdo: buildQuantityPriceSet(bunker, FUEL_GRADE_MDO.key),
  mgo: buildQuantityPriceSet(bunker, FUEL_GRADE_MGO.key),
  lsmgo: buildQuantityPriceSet(bunker, FUEL_GRADE_LSMGO.key),
  ulsfo: buildQuantityPriceSet(bunker, FUEL_GRADE_ULSFO.key),
  vlsfo: buildQuantityPriceSet(bunker, FUEL_GRADE_VLSFO.key),
  lng: buildQuantityPriceSet(bunker, FUEL_GRADE_LNG.key),
});

const buildQuantityPriceSet = (bunker, fuelGradeId) => {
  let fuelGradeInfo = find(bunker.fuelGrades, (fuelGrade) => fuelGrade.key === fuelGradeId);

  if (isNil(fuelGradeInfo)) {
    return {
      quantity: 0,
      price: 0,
    };
  }

  return {
    quantity: fuelGradeInfo.quantity,
    price: fuelGradeInfo.price,
  };
};

function mapCommonAdditionalExpenseToWorksheetApiRequest(commonModelAdditionalExpense) {
  return {
    // NOTE: It seems like the target model matches exactly, but these fallbacks were here originally. TODO: Perhaps they're not needed and we can use the common model directly. Check if we ever get any of these props undefined?
    id: commonModelAdditionalExpense.id || emptyGuid,
    description: commonModelAdditionalExpense.description || '',
    cost: commonModelAdditionalExpense.cost || 0,
    costType: commonModelAdditionalExpense.costType || 0,
    isPredefinedExpense: !!commonModelAdditionalExpense.isPredefinedExpense,
    sortOrder: commonModelAdditionalExpense.sortOrder,
  };
}

const mapWorksheetViewModelCanalsToRequest = (worksheet) => {
  const mappedCanals = [];

  forIn(worksheet.canals, (canal, id) =>
    mappedCanals.push({
      id: id,
      canalId: canal.canalId,
      ballastCost: canal.ballastCost,
      ladenCost: canal.ladenCost,
      ballastWaitingTime: canal.ballastWaitingTime,
      ladenWaitingTime: canal.ladenWaitingTime,
      ballastTransitTime: canal.ballastTransitTime,
      ladenTransitTime: canal.ladenTransitTime,
      operationalRestrictionIds: canal.operationalRestrictionIds
        ? Array.from(canal.operationalRestrictionIds)
        : [],
    })
  );

  return mappedCanals;
};

const mapAdditionalIncomes = (worksheet) => {
  const additionalIncomes: IAdditionalIncomes = [];
  forIn(worksheet.additionalIncomes, (ai, id) => {
    additionalIncomes.push({
      id: ai.id || guid(),
      income: ai.income,
      description: ai.description,
      sortOrder: ai.sortOrder,
    });
  });

  return additionalIncomes;
};

export default buildWorksheetRequest;
