import { WarningIcon } from 'components/icons';

import React from 'react';

const InsufficientFuelQuantityWarning = () => {
  return (
    <div className="bunker-quantity-inline-warning">
      <WarningIcon className="bunker-quantity-inline-warning-icon" />
      <span className="bunker-quantity-inline-warning__message">
        <strong>Insufficient bunkers to complete voyage: </strong>
        <span>Review bunker quantities</span>
      </span>
    </div>
  );
};

export default InsufficientFuelQuantityWarning;
