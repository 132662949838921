import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

interface DragAndDropContainerProps {
  children: React.ReactNode;
  onDragEnd?: (dragInfo: any) => void;
  className?: string;
}

const DragAndDropContainer = ({ children, onDragEnd, className }: DragAndDropContainerProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={className}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropContainer;
