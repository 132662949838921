import {
  SET_CALCULATION_RESULTS,
  SET_CALCULATIONS_STATUS,
} from 'constants/action-types/calculation-summary';
import { LOAD_WORKSHEET, WORKBOOK_REMOVE_WORKSHEET } from 'constants/action-types/workbook';
import { CalculationStatus } from 'constants/enums/calculation-status';
import { ADD_WORKSHEET_TO_WORKBOOK } from 'constants/action-types/workbook-manager';
import isNil from 'lodash/isNil';

const createEmptyCalculationResult = () => ({
  calculationStatus: null,
  errors: [],
  totalCargoQuantity: 0,
  totalDistance: 0,
  phases: [],
  time: {
    totalDays: 0,
    totalDaysBallast: 0,
    totalDaysLaden: 0,
    totalDaysLoad: 0,
    totalDaysDischarge: 0,
    totalDaysIdle: 0,
    totalDaysAtSea: 0,
    totalDaysAtPort: 0,
    totalDaysAtLegs: [],
  },
  totalPortCostsNet: 0,
  additionalCostsNetItems: [],
  bunkers: {
    totalBunkersCostsNet: 0,
    totalBunkersCostsBallastNet: 0,
    totalBunkersCostsLadenNet: 0,
    totalBunkersCostsLoadNet: 0,
    totalBunkersCostsDischargeNet: 0,
    totalBunkersCostsIdleNet: 0,
    totalBunkersCostsAtPortNet: 0,
    totalBunkersCostsFuelCST180Net: 0,
    totalBunkersCostsFuelCST380Net: 0,
    totalBunkersCostsFuelMDONet: 0,
    totalBunkersCostsFuelMGONet: 0,
    totalBunkersCostsFuelLSMGONet: 0,
  },
  timeCharter: {
    totalTimeCharterCostsNet: 0,
    totalVoyageCostsNet: 0,
    totalVoyageCostsGross: 0,
    voyageRateCalculatedNet: 0,
    voyageRateCalculatedGross: 0,
  },
  voyageRate: {
    totalFreightCostsNet: 0,
    totalExpensesNet: 0,
    totalVoyageCostsNet: 0,
    totalVoyageCostsGross: 0,
    timeCharterRateCalculatedNet: 0,
    timeCharterRateCalculatedGross: 0,
    voyageProfitOrLossEstimate: 0,
  },
  cargoQuantityMovedAtVoyageEntries: {},
});

const immutableEmptyCalulationResult = createEmptyCalculationResult();

export const immutableEmptyCalculation = {
  calculationResults: [immutableEmptyCalulationResult],
};

const initialState = {};

export const calculationsByWorksheetIdReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return { ...state, [action.payload.id]: immutableEmptyCalculation };
    case SET_CALCULATIONS_STATUS:
      return {
        ...state,
        [action.worksheetId]: {
          calculationResults: state[action.worksheetId].calculationResults.map(
            (calculationResult) => ({
              ...calculationResult,
              calculationStatus: action.payload,
            })
          ),
        },
      };
    case SET_CALCULATION_RESULTS:
      return {
        ...state,
        [action.worksheetId]: {
          calculationResults: action.payload.calculationResults.map((result) => {
            if (result.calculationStatus === CalculationStatus.FAILED) {
              /* TODO: #TODODontPopulateBogusDataOnErrorButRatherPreventHavingToShowThem - This `createEmptyCalculationResult` should be refactored away to reduce the amount of code that executes in case of an error, and amount of places which show bogus data, by making a coarse-grained UI decision, for example replace the entire calculation with an error notification. The user should not be misinformed with data taken from 'thin air' but be presented with an information that there is no data to be shown. */
              const failureResult = createEmptyCalculationResult();
              for (const key in result) {
                /* Skip all `null` data (.NET will send them for invalid responses when it uses DTO classes), so that they don't cause the breakage that `createEmptyCalculationResult` is meant to prevent */
                if (isNil(result[key]) && !isNil(failureResult[key])) continue;

                failureResult[key] = result[key];
              }
              return failureResult;
            }
            return result;
          }),
        },
      };
    case ADD_WORKSHEET_TO_WORKBOOK:
      return {
        ...state,
        [action.payload.worksheetId]: immutableEmptyCalculation,
      };
    case WORKBOOK_REMOVE_WORKSHEET:
      const newState = { ...state };
      delete newState[action.payload.worksheetId];
      return newState;
    default:
      return state;
  }
};

export default calculationsByWorksheetIdReducer;
