import type { ReduxAction, ReduxDispatchFn } from 'utilities/redux';

type MethodName = string;

type AfterwaresOrchestrationInput = {
  afterwaresByMethodName: {
    [MethodName]: () => WorksheetSnapshotSyncAfterware,
  },
  context: {
    action: ReduxAction,
    newState: IAppState,
    dispatch: ReduxDispatchFn,
  },
};

type ResultForOrchestration = {
  [MethodName]: () => Promise,
};

/**
 * Allows orchestrating the afterwares explicitly (deciding their execution's order, parallelism/sequentiality, waiting/no waiting), by just calling an action for each afterware in the desired order, and deciding to `await` or not.
 */
export function prepareForExplicitlyOrchestratedExecution({
  afterwaresByMethodName,
  context: { action, newState, dispatch },
}: AfterwaresOrchestrationInput): ResultForOrchestration {
  return Object.fromEntries(
    Object.entries(afterwaresByMethodName).map(([methodName, afterware]) => {
      if (afterware.isSyncRequired(newState, action) === false)
        // Return an empty function so that consuming code is simple and conditionless, but also importantly, to give developer an immediate error if he mistypes any of the action or performs an incomplete rename (otherwise he would have to check existence of the property before calling, which would also make his code blind to these mistakes).
        return [methodName, async () => {}];

      const worksheetId = afterware.getWorksheetIdForRequiredSync(newState, action);

      if (!worksheetId) {
        throw new Error(
          `\`${methodName}\` found no \`worksheetId\` while handling action type '${action.type}' (it's either missing from the action or the state, depending on how the \`getWorksheetIdForRequiredSync\` obtains it - see implementation in the \`${methodName}\`'s afterware)`
        );
      }
      return [
        methodName,
        async (/* #AfterwaresPreventionOfDataLoss_ThroughOldWorksheetData_Implementation the function is made parameterless to guarantee that no old state is passed. */) => {
          await dispatch(async (dispatch, getState) => {
            // Obtain a new `latestStateForInvoke`, because this action could have been deferred due to debouncing or batching. This is also #AfterwaresPreventionOfDataLoss_ThroughOldWorksheetData_Implementation
            const latestStateForInvoke = getState();
            // Using 'destructuring' here to obtain a `newGlobalState` object that doesn't contain `worksheetsById`. This is #AfterwaresPreventionOfDataLoss_ThroughWrongActiveWorksheetOverwrite_Implementation - so that the caller doesn't accidentally use the wrong worksheet (e.g. by doing worksheetsById[activeWorksheetId])
            const {
              worksheetsById: {
                /* Use the `worksheetId` instead of the current `activeWorksheetId`. This is #AfterwaresPreventionOfDataLoss_ThroughWrongActiveWorksheetOverwrite_Implementation */
                [worksheetId]: newWorksheetState,
              },
              ...newGlobalState
            } = latestStateForInvoke;

            return await dispatch(afterware.sync(newWorksheetState, newGlobalState));
          });
        },
      ];
    })
  );
}
