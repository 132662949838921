import {
  SENSITIVITY_ACTIVE_CHANGED,
  SENSITIVITY_CHANGED,
  SENSITIVITY_VALUE_CHANGED,
  SENSITIVITY_FIRST_FUEL_GRADE_CHANGED,
  SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED,
  SENSITIVITY_SECOND_FUEL_GRADE_CHANGED,
  SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED,
} from 'constants/action-types/sensitivity-table';

import { VOYAGE_SET_PORTS_IN_SECA } from 'constants/action-types/worksheet/voyage';
import { SET_ACTIVE_VESSEL_ENTRY_ID } from '../../../constants/action-types/user-state';

const calculatableActions = [
  VOYAGE_SET_PORTS_IN_SECA,
  SET_ACTIVE_VESSEL_ENTRY_ID,
  SENSITIVITY_ACTIVE_CHANGED,
  SENSITIVITY_CHANGED,
  SENSITIVITY_VALUE_CHANGED,
  SENSITIVITY_FIRST_FUEL_GRADE_CHANGED,
  SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED,
  SENSITIVITY_SECOND_FUEL_GRADE_CHANGED,
  SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED,
];

export default calculatableActions;
