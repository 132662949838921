import React from 'react';
import { RetryIcon } from 'components/icons';
import ProgressBar from 'components/progress-bar';
import { Size } from 'components/progress-bar/size';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';

export class ProgressRenderer extends React.PureComponent {
  state = {
    status: null,
    loadWorksheetPromise: null,
    refreshBunkersPromise: null,
  };

  loadWorksheet = () => {
    trackEvent(
      'WorksheetComparison',
      'Worksheet Comparison Retry Load Worksheet',
      {},
      {},
      eventDestination.ANALYSIS
    );
    this.props.loadWorksheet(this.props.data.id);
  };

  saveChanges = () => {
    trackEvent(
      'WorksheetComparison',
      'Worksheet Comparison Retry Save Worksheet',
      {},
      {},
      eventDestination.ANALYSIS
    );
    this.props.saveWorksheet(this.props.data.id);
  };

  render() {
    const {
      value,
      data: { isLoading, isSaveFailure, isLoadFailure },
    } = this.props;

    if (isLoadFailure) {
      return <Retry onAction={this.loadWorksheet} />;
    }

    if (isSaveFailure) {
      return <Retry onAction={this.saveChanges} title="Retry as failed to update" />;
    }

    if (isLoading) {
      return (
        <div style={{ paddingTop: '7px' }}>
          <ProgressBar size={Size.XSMALL} />
        </div>
      );
    }

    return value;
  }
}

export class LinkCellRenderer extends React.PureComponent {
  render() {
    const { href } = this.props.data;
    const { value } = this.props;
    return (
      <a className="comparison-worksheet-anchor" href={href}>
        {value}
      </a>
    );
  }
}

const Retry = ({ onAction, title }) => {
  return (
    <div className="comparison-fail-handle">
      <div className="comparison-fail-handle__text" />
      <div
        className="comparison-fail-handle__refresh-icon"
        title={title || 'Retry as failed to get all worksheet details'}
        onClick={onAction}
      >
        <RetryIcon />
      </div>
    </div>
  );
};
