import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { bootstrap } from 'bootstrap';
import store from 'store';

import { ErrorBoundary } from 'components/error-boundary';
import Loader from 'modules/loader';
import SeaHeader from 'modules/sea-header';
import { ConnectedAppRoot as AppRoot } from 'modules/app-root';
import { AppFallbackError } from 'modules/app-fallback-error';

import 'normalize.css';
import { FullScreenMessage } from 'modules/fullscreen-message';
import { VersionMismatchContent } from 'modules/fullscreen-message/components/version-mismatch-content';
import * as calcDebugging from 'diagnostics/calc-debugging';
import { LicenseManager } from '@ag-grid-enterprise/core';

const BuildDate: string = '2024-09-10T13:32:07Z';
console.log(
  `This version of Sea/calc (${process.env.REACT_APP_VERSION}) was built at ${BuildDate}.`
);

type IRootProps = {
  registerWebApiVersionMismatchHandler: (callback: () => void) => void,
};

const Root = ({ registerWebApiVersionMismatchHandler }: IRootProps) => {
  const [fullScreenMessageContent, setFullScreenMessageContent] = useState(null);

  useEffect(() => {
    registerWebApiVersionMismatchHandler(() => {
      setFullScreenMessageContent(<VersionMismatchContent />);
    });
  }, []);

  LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-051544}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{MARITECH_DEVELOPMENT_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Sea/calc}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Sea/calc}_need_to_be_licensed___{Sea/calc}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_December_2024}____[v3]_[01]_MTczMzM1NjgwMDAwMA==0abff24b9a5e7db002d0633e5361cc17'
  );

  return (
    <Provider store={store}>
      <SeaHeader />
      {fullScreenMessageContent && (
        <FullScreenMessage>{fullScreenMessageContent}</FullScreenMessage>
      )}
      <ErrorBoundary fallbackRender={(_) => <AppFallbackError />}>
        <Loader>
          <AppRoot />
        </Loader>
      </ErrorBoundary>
    </Provider>
  );
};

/// wire in convenience hacks
window.calcDebugging = calcDebugging;

const container = document.getElementById('root');
const root = createRoot(container);
const app = (config) => root.render(<Root {...config} />);

bootstrap(app);

