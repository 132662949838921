import React, { useState } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import ExpandCollapse from 'components/expand-collapse';
import { Header } from 'components/headers';
import './styles.scss';
import { connect } from 'react-redux';
import { selector } from './selector';
import { Subheader } from 'components/headers';
import { MaterialIconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import NumericInput from 'components/numeric-input';
import {
  additionalIncomeDescriptionChanged,
  additionalIncomeIncomeChanged,
  additionalIncomeAdded,
  additionalIncomeRemoved,
} from 'actions/worksheet/cargoes/additional-incomes';

type IProps = {
  worksheetId: string,
  additionalIncomes: IAdditionalIncomes[],
  additionalIncomeRemoved: (index: number, worksheetId: string) => {},
  additionalIncomeAdded: (worksheetId: string) => {},
  additionalIncomeDescriptionChanged: (
    worksheetId: string,
    index: number,
    description: string
  ) => {},
  additionalIncomeIncomeChanged: (worksheetId: string, index: number, income: number) => {},
};

type IAdditionalIncomeProps = {
  itemIndex: number,
  id: string,
  income: number,
  description: string,
  additionalIncomeDescriptionChangedHandler: Function,
  additionalIncomeIncomeChangedHandler: Function,
  additionalIncomeRemovedHandler: Function,
};

const AdditionalIncomes: React.FC<IProps> = ({
  worksheetId,
  additionalIncomes,
  additionalIncomeRemoved,
  additionalIncomeAdded,
  additionalIncomeDescriptionChanged,
  additionalIncomeIncomeChanged,
}) => {
  const [forceCollapse, setForceCollapse] = useState(false);

  const expandCollapseHandler = (expanded: boolean) => {
    setForceCollapse(false);
    if (expanded && additionalIncomes.length === 0) {
      additionalIncomeAddedHandler();
    }
  };

  const additionalIncomeAddedHandler = () => {
    additionalIncomeAdded(worksheetId);
  };

  const additionalIncomeRemovedHandler = (index: number) => {
    additionalIncomeRemoved(index, worksheetId);
    if (additionalIncomes.length === 1) {
      setForceCollapse(true);
    }
  };

  const additionalIncomeDescriptionChangedHandler = (index: number, description: string) => {
    additionalIncomeDescriptionChanged(worksheetId, index, description);
  };

  const additionalIncomeIncomeChangedHandler = (index: number, amount: number) => {
    additionalIncomeIncomeChanged(worksheetId, index, amount);
  };

  return (
    <div>
      <ExpandCollapse
        header={
          <Header
            text={
              <Subheader
                text={`Additional Freight Incomes (${additionalIncomes.length})`}
                className="additional-incomes-header"
              />
            }
          />
        }
        onClick={expandCollapseHandler}
        forceCollapse={forceCollapse}
      >
        <table className="additional-incomes__table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Amount ($)</th>
              <th>
                <div className="other-expenses__table-header__cell other-expenses--add">
                  <MaterialIconButton
                    className="other-expenses--add-btn"
                    icon={iconEnum.AddCircle}
                    onClick={additionalIncomeAddedHandler}
                    diagnosticId="AdditionalIncome/AddAdditionalIncome"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(additionalIncomes, (item, index) => (
              <AdditionalIncome
                key={item.id}
                itemIndex={index}
                id={item.id}
                income={item.income}
                description={item.description}
                additionalIncomeDescriptionChangedHandler={
                  additionalIncomeDescriptionChangedHandler
                }
                additionalIncomeIncomeChangedHandler={additionalIncomeIncomeChangedHandler}
                additionalIncomeRemovedHandler={additionalIncomeRemovedHandler}
              />
            ))}
          </tbody>
        </table>
      </ExpandCollapse>
    </div>
  );
};

const AdditionalIncome: React.FC<IAdditionalIncomeProps> = ({
  itemIndex,
  id,
  income,
  description,
  additionalIncomeDescriptionChangedHandler,
  additionalIncomeIncomeChangedHandler,
  additionalIncomeRemovedHandler,
}) => {
  return (
    <tr key={id}>
      <td>
        <input
          value={description}
          className="calc-input"
          maxLength={160}
          onChange={(event) => {
            additionalIncomeDescriptionChangedHandler(itemIndex, event.target.value);
          }}
        />
      </td>
      <td>
        <NumericInput
          value={income}
          maxDecimalDigits="2"
          minValue="0"
          maxValue="999999999.99"
          onInputChange={(value) => {
            additionalIncomeIncomeChangedHandler(itemIndex, value);
          }}
          diagnosticId="AdditionalIncomes/ChangeAdditionalIncome"
        />
      </td>
      <td className="additional-incomes__table__icon">
        <MaterialIconButton
          icon={iconEnum.RemoveCircle}
          className="other-expenses--remove-btn"
          onClick={() => additionalIncomeRemovedHandler(itemIndex)}
          diagnosticId="AdditionalIncome/RemoveAdditionalIncome"
        />
      </td>
    </tr>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      additionalIncomeDescriptionChanged,
      additionalIncomeIncomeChanged,
      additionalIncomeAdded,
      additionalIncomeRemoved,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalIncomes);
