import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReasonWorkbookEditsNotAllowedEnum from 'constants/enums/workbooks/ReasonWorkbookEditsNotAllowedEnum';
import WarningBox from 'components/notice-box/warning-box';
import { ActionBarItem } from '../action-bar';
import { TabsDropdown } from './worksheets-tab-bar/tabs-dropdown';
import NewWorksheetButton from './new-worksheet-button';
import CenteredContent from 'components/centered-content';
import { getWorkbookProps } from './selector';
import * as worksheetClient from 'api/clients/worksheet';
import {
  addWorksheetToWorkbook,
  updateWorksheetSortOrderToWorkbook,
} from 'actions/workbook-manager';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import EmitNotice from 'components/notices-emitter/emit-notice';
import WorksheetContainer from 'modules/worksheet/worksheet-container';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from 'utilities/router-wrapper';
import WorksheetRedirector from './no-document-placeholders/worksheet-redirector';
import { WorkbookEmptyPlaceholder } from './no-document-placeholders/workbook-empty-placeholder';
import { PrimaryNavPortalContent } from 'modules/action-bar-primary/primary-nav-portals';
import { Carousel } from 'modules/carousel';
import { WorksheetTab } from './worksheets-tab-bar/worksheet-tab';
import { getHostNameFromUrlString } from 'utilities/url';
import { UPDATE_WORKBOOK_FAILED_OWNED_BY_SOMEONE_ELSE } from 'constants/enums/emit-notices';
import { sortArrayObjectsBy } from '../../utilities/array-object-sort';
import './styles.scss';
import userContext from 'user-context';
import { saveWorkbook } from 'api/clients/workbook';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Ugly name '*RequiresRouter' as this class is unusable without a `withRouter` wrapper,
// while there's no way to use `extends` on withRouter - https://stackoverflow.com/a/38953136
class WorkbookRequiresRouter extends React.Component<{
  worksheetId: ?string,
  selectedWorksheetPanel: string,
  workbook: object,
  PrimaryNavBarContent: React.Component,
  isWorksheetLoading: boolean,
}> {
  render() {
    const {
      workbook,
      activeWorksheetId,
      'data-testid': testId,
      worksheets,
      sortOrderType,
      isWorksheetLoading,
    } = this.props;

    const workbookId = workbook.id;

    if (!workbook) {
      throw new Error('workbook is expected to be defined within WorkbookRequiresRouter');
    }

    const sortedWorksheetTabs = () => {
      const sortedWorksheets = sortArrayObjectsBy(worksheets, sortOrderType, 'name');
      return (
        <Carousel>
          {sortedWorksheets.map((worksheet) => (
            <WorksheetTab
              key={worksheet.id}
              id={worksheet.id}
              workbookId={workbook.id}
              value={worksheet.name}
              isActive={activeWorksheetId === worksheet.id}
              isErrorState={worksheet.isErrorState}
              sortOrderType={sortOrderType}
            />
          ))}
        </Carousel>
      );
    };

    const WorksheetRedirectWrapper = ({ workbook, worksheets }) => (
      <CenteredContent>
        <WorksheetRedirector workbook={workbook} worksheets={worksheets} />
        <WorkbookEmptyPlaceholder workbook={workbook} worksheets={worksheets} />
      </CenteredContent>
    );

    return (
      <Fragment>
        {activeWorksheetId &&
          workbook.reasonEditsNotAllowed ===
            ReasonWorkbookEditsNotAllowedEnum.UserDoesntOwnTheWorkbook && (
            <>
              <EmitNotice
                type={UPDATE_WORKBOOK_FAILED_OWNED_BY_SOMEONE_ELSE}
                worksheetId={workbook.lastViewedWorksheetId}
              >
                {({ dismiss, additionalProps }) => {
                  return (
                    <WarningBox
                      className="notice-cant-save-changes-on-colleagues-work-items"
                      additionalprops={additionalProps}
                    >
                      <h1 className="warning-box__header">Edits on a colleague's worksheet</h1>
                      <div className="warning-box__text">
                        Edits made to this worksheet AREN'T SAVED to your profile or to your
                        colleague's worksheet
                      </div>
                      <IconButton
                        aria-label="Dismiss"
                        icon={iconEnum.Close}
                        className={'notice-box__standard-dismiss-button'}
                        onClick={dismiss}
                        diagnosticId="WorkbookRequiresRouter/DismissMessage"
                      />
                    </WarningBox>
                  );
                }}
              </EmitNotice>
            </>
          )}
        <PrimaryNavPortalContent>
          {sortedWorksheetTabs()}
          <ActionBarItem className="action-bar__primary--controls">
            <ActionBarItem>
              <TabsDropdown
                worksheets={worksheets}
                activeWorksheetId={activeWorksheetId}
                onClick={this.gotoWorksheet}
                workbookId={workbookId}
                sortOrderType={sortOrderType}
                workbook={workbook}
                updateSortOrderToWorkbook={this.updateSortOrderToWorkbook}
              />
            </ActionBarItem>
            {workbook.isEditable && (
              <ActionBarItem>
                <ReactTooltip
                  place="bottom"
                  className="ve-tooltip-default"
                  id="add-tooltip"
                  noArrow
                />
                <NewWorksheetButton
                  onClick={this.createWorksheet}
                  data-testid={testId && `${testId}__add-worksheet-button`}
                  data-tooltip-content="Add blank worksheet"
                  data-tooltip-id="add-tooltip"
                  diagnosticId="ActionBarPrimary/NewWorksheet"
                  disabled={isWorksheetLoading}
                />
              </ActionBarItem>
            )}
          </ActionBarItem>
        </PrimaryNavPortalContent>
        <Routes>
          <Route path="/worksheet/:worksheetId" element={<WorksheetContainer />} />
          <Route
            path="*"
            element={<WorksheetRedirectWrapper workbook={workbook} worksheets={worksheets} />}
          />
        </Routes>
      </Fragment>
    );
  }

  createWorksheet = () => {
    const { id, marketSegmentId } = this.props.workbook;
    this.createWorksheetAndNavigateTo(id, marketSegmentId);
  };

  createWorksheetAndNavigateTo = async (workbookId, marketSegmentId) => {
    // In order to show loading progress as soon as the user clicks on the `create new worksheet` button
    // we can use this component's internal state to set a promise that can then be integrated with `Async`.
    // TODO: Move this to a redux action and add middleware to dispatch action to enable/disable controls

    const worksheet = await worksheetClient.createEmptyWorksheetInWorkbook({
      createdFromProgramId: 'SeaCalcUI',
      createdFromOriginId: getHostNameFromUrlString(document.location.origin),
      workbookId,
      marketSegmentId,
    });

    this.props.addWorksheetToWorkbook(workbookId, worksheet.id, worksheet.name);

    this.props.router.navigate(`/workbook/${workbookId}/worksheet/${worksheet.id}`);
  };

  gotoWorksheet = (worksheetId) =>
    this.props.router.navigate(`/workbook/${this.props.workbook.id}/worksheet/${worksheetId}`);

  updateSortOrderToWorkbook = async (workbookId, sortOrderType, workbook) => {
    this.props.updateWorksheetSortOrderToWorkbook(workbookId, sortOrderType);

    if (workbook.userId === userContext.systemUserId) {
      await saveWorkbook({
        ...workbook,
        sortOrderType: sortOrderType,
      });
    }
  };
}

export const Workbook = withRouter(WorkbookRequiresRouter);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addWorksheetToWorkbook,
      updateWorksheetSortOrderToWorkbook,
    },
    dispatch
  );
}

export default connect(getWorkbookProps, mapDispatchToProps)(Workbook);
