import React from 'react';

const TestContext: React.Context<string> = React.createContext(null);

export function TestCase({
  'data-testid': testId,
  children,
}: {
  'data-testid': String,
  children: any,
}) {
  return <TestContext.Provider value={testId}>{children}</TestContext.Provider>;
}

export function useTestId(suffix: string) {
  const testId = React.useContext(TestContext);
  return testId ? `${testId}${suffix ? `__${suffix}` : ''}` : undefined;
}
