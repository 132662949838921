import {
  isUnrestrictedSubtotal,
  isSecaOnlySubtotal,
  isBannedOnlySubtotal,
  isSecaAndBannedSubtotal,
  doesSubtotalNotContainSeca,
  doesSubtotalContainSeca,
  sumRoutingApiDistancesBy,
} from './subtotal-utils';

export function distributeUserEditedDistances(
  totalSecaDistance: number,
  totalDistance: number,
  subtotalsByOperationalRestrictionIds: ISubtotalByOperationalRestrictionIdsViewModel[]
): ISubtotalByOperationalRestrictionIdsViewModel[] {
  const unrestrictedDistanceFromRoutingApi = sumRoutingApiDistancesBy(
    subtotalsByOperationalRestrictionIds,
    isUnrestrictedSubtotal
  );

  const secaOnlyDistanceFromRoutingApi = sumRoutingApiDistancesBy(
    subtotalsByOperationalRestrictionIds,
    isSecaOnlySubtotal
  );

  const totalNonSecaDistancesFromRoutingApi = sumRoutingApiDistancesBy(
    subtotalsByOperationalRestrictionIds,
    doesSubtotalNotContainSeca
  );

  const totalSecaDistancesFromRoutingApi = sumRoutingApiDistancesBy(
    subtotalsByOperationalRestrictionIds,
    doesSubtotalContainSeca
  );

  const ratioOfUnrestrictedDistanceOverNonSecaDistances =
    totalNonSecaDistancesFromRoutingApi === 0
      ? 1
      : unrestrictedDistanceFromRoutingApi / totalNonSecaDistancesFromRoutingApi;
  const ratioOfSecaOnlyDistanceOverTotalSecaDistances =
    totalSecaDistancesFromRoutingApi === 0
      ? 1
      : secaOnlyDistanceFromRoutingApi / totalSecaDistancesFromRoutingApi;

  let newSubtotalsByOperationalRestrictionIds = [];

  for (const subtotal of subtotalsByOperationalRestrictionIds) {
    let newSubtotal = {
      ...subtotal,
      operationalRestrictionIds: new Set(Array.from(subtotal.operationalRestrictionIds)),
    };

    if (isUnrestrictedSubtotal(subtotal)) {
      newSubtotal.userEditedDistance =
        ratioOfUnrestrictedDistanceOverNonSecaDistances * (totalDistance - totalSecaDistance);
    } else if (isBannedOnlySubtotal(subtotal)) {
      newSubtotal.userEditedDistance =
        (1 - ratioOfUnrestrictedDistanceOverNonSecaDistances) * (totalDistance - totalSecaDistance);
    } else if (isSecaOnlySubtotal(subtotal)) {
      newSubtotal.userEditedDistance =
        ratioOfSecaOnlyDistanceOverTotalSecaDistances * totalSecaDistance;
    } else if (isSecaAndBannedSubtotal(subtotal)) {
      newSubtotal.userEditedDistance =
        (1 - ratioOfSecaOnlyDistanceOverTotalSecaDistances) * totalSecaDistance;
    }

    newSubtotalsByOperationalRestrictionIds.push(newSubtotal);
  }

  return newSubtotalsByOperationalRestrictionIds;
}
