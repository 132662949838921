import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  otherExpenseDescriptionChanged,
  otherExpenseCostChanged,
} from 'actions/worksheet/additional-expenses';
import { Header } from '../../components/headers';
import { selector } from './selector';

import './styles.scss';
import CalcInput from '../../components/calc-input';

export const OtherCosts = ({
  worksheetId,
  otherExpenses,
  otherExpenseDescriptionChanged,
  otherExpenseCostChanged,
}) => {
  const itemIndex = 0;
  const otherExpense = otherExpenses[itemIndex];
  const onOtherExpenseDescriptionChanged = (newValue) => {
    otherExpenseDescriptionChanged(newValue, itemIndex, worksheetId);
  };
  const onOtherExpenseCostChanged = (newCost) => {
    otherExpenseCostChanged(newCost, itemIndex, worksheetId);
  };
  return (
    <div>
      <Header text="Other Costs" />
      <div>
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Type</label>
          <CalcInput
            inputMode="text"
            className="type-input"
            userSpecifiedValue={otherExpense.description}
            onInputChange={onOtherExpenseDescriptionChanged}
            maxLength={160}
            name="Type"
            isEditable
          />
        </div>
        <div className="worksheet-section__property">
          <label className="worksheet-section__property-label">Amount</label>
          <CalcInput
            userSpecifiedValue={otherExpense.cost}
            onInputChange={onOtherExpenseCostChanged}
            className="cost-input"
            maxDecimalDigits={2}
            unit="$"
            unitPrecedesValue
            trimDecimal={false}
            minValue="-999999.99"
            maxValue="999999999.99"
            name="Amount"
            isEditable
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      otherExpenseDescriptionChanged,
      otherExpenseCostChanged,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherCosts);
