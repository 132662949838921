import React from 'react';
import NumericInput, { validationLevels } from 'components/numeric-input';
import PortAutoComplete from 'components/port-autocomplete';
import DropDown from 'components/dropdown';
import TableDropDown from 'components/table-dropdown';
import LegTypes, {
  DISCHARGE,
  LOAD,
  VIA,
  BUNKER,
  isLoadOrDischargePort,
} from 'constants/enums/voyage-leg';
import GearTypes, { WetGearLoadingTypes, WetGearDischargingTypes } from '../../gear-types';
import { SalinityTypes } from 'constants/enums/salinity-types';
import LoadDischargeRateUnitTypes, {
  METRIC_TONNES_PER_DAY,
} from '../../load-discharge-rate-unit-types';
import { TimeUnitEnum } from 'constants/enums/time';
import WorkingDayDropdown from '../working-day-dropdown';
import { MaterialIconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import userContext from 'user-context';
import { isWet } from 'constants/market-segments';
import { METERS, convertDraft } from 'constants/enums/draft-units';
import { formatNumberWithAutoMantissa } from 'utilities/number';
import { singleOrThrow } from 'utilities/iterable';
import { connect } from 'react-redux';
import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import { IconTooltip } from 'components/icon-tooltip';
import { CalculationStatus } from 'constants/enums/calculation-status';
import { getActiveVessel } from '../../../../common-selectors/get-active-vessel';
import { isFeatureEnabled } from 'config/feature-control';
import { EEACheckbox } from '../eea';
import { getInboundRouteVariant } from 'utilities/functions/get-inbound-route-variant';

export const cargoQuantityMaxPrecision: number = 3;

const PortCell = ({ className, children }) => {
  return <div className={`voyage-data__row-cell ${className || ''}`}>{children}</div>;
};

export class PortRequiresConnect extends React.PureComponent {
  onTypeChange = (value) => {
    this.props.onTypeChange(this.props.voyageEntry.id, value, this.props.worksheet.marketSegmentId);
  };

  onLocationChange = (value) => {
    this.props.onLocationChange(this.props.voyageEntry.id, value);
  };

  onGearChange = (value) => {
    this.props.onGearChange(this.props.voyageEntry.id, value);
  };

  onTotalDistanceChange = (value) => {
    this.props.onTotalDistanceChange(
      this.props.voyageEntry.id,
      value,
      singleOrThrow(this.props.voyageEntry.inboundRoute.variants)
    );
  };

  onSecaDistanceChange = (value) => {
    this.props.onSecaDistanceChange(
      this.props.voyageEntry.id,
      value,
      singleOrThrow(this.props.voyageEntry.inboundRoute.variants)
    );
  };

  onDraftChange = (value) => {
    this.props.onDraftChange(this.props.voyageEntry.id, value);
  };

  onDraftDataLoad = () => {
    return this.props.onDraftDataLoad(this.props.voyageEntry.locationId);
  };

  onSalinityChange = (value) => {
    this.props.onSalinityChange(this.props.voyageEntry.id, value);
  };

  onCargoQuantityChange = (value) => {
    this.props.onCargoQuantityChange(this.props.voyageEntry.id, value);
  };

  onLoadDischargeRateChange = (value) => {
    this.props.onLoadDischargeRateChange(this.props.voyageEntry.id, value);
  };

  onLoadDischargeRateUnitChange = (value) => {
    this.props.onLoadDischargeRateUnitChange(this.props.voyageEntry.id, value);
  };

  onWorkingDayMultiplierChange = (value) => {
    this.props.onWorkingDayMultiplierChange(this.props.voyageEntry.id, value);
  };

  onDelayChange = (value) => {
    this.props.onDelayChange(this.props.voyageEntry.id, value);
  };

  onDelayUnitChange = (value) => {
    this.props.onDelayUnitChange(this.props.voyageEntry.id, value);
  };

  onTurnTimeChange = (value) => {
    this.props.onTurnTimeChange(this.props.voyageEntry.id, value);
  };

  onTurnTimeUnitChange = (value) => {
    this.props.onTurnTimeUnitChange(this.props.voyageEntry.id, value);
  };

  onWeatherFactorChange = (value) => {
    this.props.onWeatherFactorChange(this.props.voyageEntry.id, value);
  };

  onPortCostChange = (value) => {
    this.props.onPortCostChange(this.props.voyageEntry.id, value);
  };

  onLocationDelete = () => {
    this.props.onLocationDelete(this.props.voyageEntry.id);
  };

  onPortCostDataLoad = () => {
    return this.props.onPortCostDataLoad(this.props.voyageEntry.locationId);
  };

  onEeaStatusChange = (value) => {
    this.props.onIsInEeaChange(this.props.voyageEntry.id, value);
  };
  state = {
    maxLiftQty: '',
    showWarning: false,
  };

  componentDidUpdate(prevProps) {
    const { calculationStatus, shouldAutoCalculateIntake, voyageEntry } = this.props;
    const voyageEntryChanged = prevProps.voyageEntry.maxLiftQty !== voyageEntry.maxLiftQty;
    const isLoading = calculationStatus === CalculationStatus.LOADING;
    const isLoadType = voyageEntry.type === LOAD;
    const invalidMaxLiftQty = voyageEntry.maxLiftQty === '0' || !voyageEntry.maxLiftQty;

    if (voyageEntryChanged && !isLoading) {
      const shouldShowWarningIcon =
        !isLoading && shouldAutoCalculateIntake && isLoadType && invalidMaxLiftQty;
      const maxLiftQty = voyageEntry?.maxLiftQty === 'na' ? '' : voyageEntry?.maxLiftQty || '';

      this.setState({
        maxLiftQty,
        showWarning: shouldShowWarningIcon,
      });

      return;
    }

    if (this.unusedCol()) {
      this.setState({
        showWarning: false,
      });
    }
  }

  unusedCol = () => {
    const { shouldAutoCalculateIntake, voyageEntry } = this.props;
    return shouldAutoCalculateIntake
      ? voyageEntry.type === DISCHARGE
      : voyageEntry.type !== VIA && voyageEntry.type !== BUNKER;
  };

  render() {
    const { voyageEntry, worksheet, activeVessel } = this.props;
    const isWorkingPortPhase = voyageEntry.type === LOAD || voyageEntry.type === DISCHARGE;
    const isWetCargo = isWet(worksheet.marketSegmentId);
    const isReadOnlyDistancesField = voyageEntry.inboundRoute.variants.length > 1;
    const inboundRouteVariant = getInboundRouteVariant(voyageEntry, activeVessel);
    const getGearTypes = () => {
      if (isWetCargo) {
        if (voyageEntry.type === LOAD) {
          return WetGearLoadingTypes;
        } else if (voyageEntry.type === DISCHARGE) {
          return WetGearDischargingTypes;
        }
      } else {
        return GearTypes;
      }
    };

    return (
      <div>
        <div className="voyage-data__row">
          <PortCell className="voyage-data--grip">
            <div className="voyage-data__row-cell__grip-icon has-icon icon--grip" />
          </PortCell>
          {isFeatureEnabled('newVoyageTable') && isLoadOrDischargePort(voyageEntry.type) ? (
            <PortCell className="voyage-data__eea_row">
              <EEACheckbox
                disabled={false}
                onIsInEeaChange={this.onEeaStatusChange}
                id="eea-checkbox"
                isInEea={voyageEntry.isInEea}
                label=""
                portId={voyageEntry.id}
              />
            </PortCell>
          ) : (
            <div className="voyage-data__eea_row" style={{ width: 35 }}></div>
          )}
          <PortCell className="voyage-data--type">
            <DropDown
              id="legType"
              items={LegTypes}
              selectedItem={{ ...voyageEntry.type, previewLabel: voyageEntry.type.label.charAt(0) }}
              onChange={this.onTypeChange}
            />
          </PortCell>
          <PortCell className="voyage-data--location">
            <PortAutoComplete
              port={{
                locationId: voyageEntry.locationId,
                locationName: voyageEntry.name,
              }}
              marketSegmentId={worksheet.marketSegmentId}
              onChange={this.onLocationChange}
            />
          </PortCell>
          <PortCell
            className={classNames('voyage-data--gear', {
              'cell-placeholder': !isWorkingPortPhase,
            })}
          >
            {isWorkingPortPhase && (
              <DropDown
                id="gearType"
                items={getGearTypes()}
                selectedItem={voyageEntry.gear}
                onChange={this.onGearChange}
                diagnosticId="Port/ChangeGear"
              />
            )}
          </PortCell>
          {(() => {
            return (
              <>
                <PortCell className="voyage-data--distance">
                  <NumericInput
                    value={inboundRouteVariant.totalDistance}
                    onInputChange={this.onTotalDistanceChange}
                    maxDecimalDigits="0"
                    minValue="0"
                    maxValue="99999"
                    diagnosticId="Port/ChangeTotalDistance"
                    isReadonly={isReadOnlyDistancesField}
                  />
                </PortCell>
                <PortCell className="voyage-data--seca-distance">
                  <NumericInput
                    value={inboundRouteVariant.secaDistance}
                    onInputChange={this.onSecaDistanceChange}
                    maxDecimalDigits={0}
                    minValue={0}
                    fieldName="SECA"
                    maxValue={{
                      value: inboundRouteVariant.totalDistance,
                      name: 'Total Distance',
                    }}
                    maxLength={5}
                    validationOptions={{
                      keepInvalidTextForUserToCorrect: true,
                      propagateInvalidValueIfFiniteNumber: true,
                    }}
                    diagnosticId="Port/ChangeSecaDistance"
                    isReadonly={isReadOnlyDistancesField}
                  />
                </PortCell>
              </>
            );
          })()}
          <PortCell className="voyage-data--draft">
            <TableDropDown
              columns={this.props.draftColumns}
              items={[]}
              value={voyageEntry.draft}
              onDataLoad={this.onDraftDataLoad}
              dropdownDisabled={isWetCargo || !userContext.userInfo.isInHouseCompany}
              onDropDownChange={this.onDraftChange}
              onInputChange={this.onDraftChange}
              maxDecimalDigits={this.props.draftNumberOfDecimalPlaces}
              minValue={
                this.props.shouldAutoCalculateIntake
                  ? {
                      value: 0.001 /* a requirement from auto intake calculation, see auto-calc-intake/index.js's `validateInputs` */,
                      getAttemptedValueIsInvalidMessage: (
                        attemptedValueInfo,
                        ruleUsageParams,
                        component
                      ) => 'This field should be greater than 0',
                      validationLevel: validationLevels.guidance,
                    }
                  : 0
              }
              maxValue={convertDraft(99.99, METERS, this.props.draftUnit)}
              isValidationMessageDismissable={true}
              className="port-draft-dropdown"
            />
          </PortCell>
          <PortCell className="voyage-data--salinity">
            <DropDown
              id="salinityType"
              items={SalinityTypes}
              selectedItem={voyageEntry.salinity}
              onChange={this.onSalinityChange}
              diagnosticId="Port/ChangeSalinity"
            />
          </PortCell>
          <PortCell
            className={classNames('voyage-data--quantity', {
              'cell-placeholder': !isWorkingPortPhase,
            })}
          >
            {isWorkingPortPhase && (
              <NumericInput
                value={voyageEntry.cargoQuantity}
                onInputChange={this.onCargoQuantityChange}
                maxDecimalDigits={cargoQuantityMaxPrecision}
                minValue="0"
                maxValue="999999.999"
                validationOptions={{
                  propagateInvalidValueIfFiniteNumber: true,
                }}
                data-testid={
                  this.props['data-testid'] && `${this.props['data-testid']}_cargoQuantity`
                }
                customValidationRules={[
                  // we need to pass a new instance of the custom rules
                  // to let the NumericInput component know that it needs
                  // to update
                  {
                    validationLevel: validationLevels.error,
                    id: 'dischargedCargoMatchesLoadedQty',
                    shouldFailureStopFurtherRules: false,
                    shouldWarnWhileTyping: false,
                    isValid: () => {
                      return (
                        !this.props.isLastLoadOrDischargePort ||
                        this.props.totalLoadCargo === this.props.totalDischargeCargo
                      );
                    },
                    getAttemptedValueIsInvalidMessage: () => {
                      return (
                        <div>
                          {`Load ${formatNumberWithAutoMantissa(
                            this.props.totalLoadCargo
                          )} and Discharge ${formatNumberWithAutoMantissa(
                            this.props.totalDischargeCargo
                          )} do not match.`}

                          <br />
                          {`Difference of ${formatNumberWithAutoMantissa(
                            Math.abs(this.props.totalLoadCargo - this.props.totalDischargeCargo)
                          )}.`}
                        </div>
                      );
                    },
                  },
                ]}
                mandatory={voyageEntry.type === LOAD && !this.props.shouldAutoCalculateIntake}
                diagnosticId="Port/ChangeCargoQuantity"
              />
            )}
          </PortCell>
          {!isWetCargo && (
            <PortCell
              className={classNames('voyage-data--max-lift-cell', {
                'voyage-data--max-lift-cell-border': isWorkingPortPhase,
                'cell-placeholder': !isWorkingPortPhase,
                'voyage-data--max-lift-cell-border-bottom': this.props.isLastLoadOrDischargePort,
                'voyage-data--max-lift-value-cell-unused': this.unusedCol(),
                'voyage-data--max-lift-cell-warning': this.state.showWarning,
                'voyage-data--max-lift-cell-num': !this.state.showWarning,
              })}
            >
              {this.state.showWarning ? (
                <span className={classNames('voyage-data--max-lift-value')}>
                  <IconTooltip
                    icon={iconEnum.Warning}
                    iconClassName={'voyage-caution-icon'}
                    place="right"
                  >
                    <div className="max-lift-tooltip">
                      Vessel exceeds a draft on the voyage without any cargo
                    </div>
                  </IconTooltip>
                </span>
              ) : (
                <span className={classNames('voyage-data--max-lift-value')}>
                  {this.state.maxLiftQty}
                </span>
              )}
            </PortCell>
          )}
          <PortCell
            className={classNames('voyage-data--load-rate', {
              'cell-placeholder': !isWorkingPortPhase,
            })}
          >
            {isWorkingPortPhase && (
              <NumericInput
                value={voyageEntry.loadDischargeRate}
                onInputChange={this.onLoadDischargeRateChange}
                maxDecimalDigits="2"
                minValue="0"
                maxValue="9999999"
                mandatory={voyageEntry.rateUnit === METRIC_TONNES_PER_DAY}
                diagnosticId="Port/ChangeLoadDischargeRate"
              />
            )}
          </PortCell>
          <PortCell
            className={classNames('voyage-data--load-rate-unit', {
              'cell-placeholder': !isWorkingPortPhase,
            })}
          >
            {isWorkingPortPhase && (
              <DropDown
                id="loadRateUnit"
                items={LoadDischargeRateUnitTypes}
                selectedItem={voyageEntry.rateUnit}
                onChange={this.onLoadDischargeRateUnitChange}
                diagnosticId="Port/ChangeLoadDischargeRateUnit"
              />
            )}
          </PortCell>
          <PortCell
            className={classNames('voyage-data--working-day', {
              'cell-placeholder': !isWorkingPortPhase,
            })}
          >
            {isWorkingPortPhase && (
              <WorkingDayDropdown
                workingDay={{
                  id: voyageEntry.workingDayType,
                  factor: voyageEntry.workingDayMultiplier,
                }}
                onChange={this.onWorkingDayMultiplierChange}
              />
            )}
          </PortCell>
          <PortCell className="voyage-data--delay-value">
            <NumericInput
              value={voyageEntry.delay}
              onInputChange={this.onDelayChange}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="99.99"
              diagnosticId="Port/ChangeDelay"
            />
          </PortCell>
          <PortCell className="voyage-data--delay-unit">
            <DropDown
              id="delayUnit"
              items={Object.values(TimeUnitEnum)}
              selectedItem={voyageEntry.delayUnit}
              onChange={this.onDelayUnitChange}
              diagnosticId="Port/ChangeDelayUnit"
            />
          </PortCell>
          {!isWetCargo && (
            <>
              <PortCell className="voyage-data--turn-time-value">
                <NumericInput
                  value={voyageEntry.turnAroundTime}
                  onInputChange={this.onTurnTimeChange}
                  maxDecimalDigits="2"
                  minValue="0"
                  maxValue="99.99"
                  diagnosticId="Port/ChangeTurnTime"
                />
              </PortCell>
              <PortCell className="voyage-data--turn-time-unit">
                <DropDown
                  id="turnTimeUnit"
                  items={Object.values(TimeUnitEnum)}
                  selectedItem={voyageEntry.turnAroundTimeUnit}
                  onChange={this.onTurnTimeUnitChange}
                  diagnosticId="Port/ChangeTurnTimeUnit"
                />
              </PortCell>
            </>
          )}
          <PortCell className="voyage-data--weather">
            <NumericInput
              value={voyageEntry.weatherFactor}
              onInputChange={this.onWeatherFactorChange}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="99.99"
              diagnosticId="Port/ChangeWeatherFactor"
            />
          </PortCell>
          <PortCell className="voyage-data--cost">
            <TableDropDown
              dropdownDisabled={isWetCargo}
              columns={this.props.portCostColumns}
              items={[]}
              value={voyageEntry.portCost}
              onDataLoad={this.onPortCostDataLoad}
              onDropDownChange={this.onPortCostChange}
              onInputChange={this.onPortCostChange}
              maxDecimalDigits="2"
              minValue="0"
              maxValue="9999999.99"
              className="port-cost-dropdown"
              diagnosticId="Port/ChangeCost"
            />
          </PortCell>
          <PortCell className="voyage-data--action">
            <MaterialIconButton
              icon={iconEnum.RemoveCircle}
              className="voyage-data--action-delete"
              onClick={this.onLocationDelete}
              diagnosticId="PortRequiresConnect/DeleteLocation"
            />
          </PortCell>
        </div>
      </div>
    );
  }
}

export default connect(
  /*data props:*/ createStructuredSelector({
    worksheet:
      getActiveWorksheetInvariantProps /* Using invariant props for optimization, while this is all that this component needs. */,
    activeVessel: getActiveVessel,
  }),
  /*action props:*/ null
)(PortRequiresConnect);
