import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Worksheet from '.';
import switchExpr from 'utilities/functions/switch-expr';
import Async from 'react-async';
import ProgressBar from 'components/progress-bar';
import { createSelector } from 'reselect';
import { MAP_PANEL, BUNKER_PORTS_PANEL, WORKSHEET_PANEL } from 'constants/enums/stage-panels';
import MapView from 'modules/map-view';
import BunkerPortsTable from 'modules/bunker-ports-table';
import WorksheetNotFoundPlaceholder from 'modules/workbook/no-document-placeholders/worksheet-not-found-placeholder';
import { trackPageView, eventDestination } from 'diagnostics/calc-trackevents';
import CenteredContent from 'components/centered-content';
import {
  setActiveWorksheetId,
  loadWorksheet,
  setLastViewedWorksheetInWorkbook,
} from 'actions/workbook';
import { StageRightPortalContent } from 'modules/stage/right-stage-portal';
import WorksheetErrorBoundary from './components/worksheet-error-boundary';
import { WorksheetError } from './worksheet-error';
import { WorksheetErrorTypes } from 'constants/enums/worksheet-error-types';
import { withRouter } from 'utilities/router-wrapper';

const WorksheetDataView = function WorksheetDataView({ selectedWorksheetPanel }) {
  return switchExpr(selectedWorksheetPanel || WORKSHEET_PANEL, {
    [MAP_PANEL]: () => <MapView />,
    [BUNKER_PORTS_PANEL]: () => <BunkerPortsTable />,
    [WORKSHEET_PANEL]: () => <Worksheet />,
  });
};

export function WorksheetContainer({
  worksheet,
  workbook,
  worksheetExistsOnWorkbook,
  loadWorksheet,
  setActiveWorksheetId,
  worksheetId,
  setLastViewedWorksheetInWorkbook,
  selectedWorksheetPanel,
}) {
  const loadWorksheetAsync = React.useCallback(
    async ({ worksheetId }) => {
      setActiveWorksheetId(worksheetId, workbook.id);

      // check if the requested worksheet exists in the current workbook
      // if it cannot be found in workbook, then we can bail early
      // this also holds if we're creating a new worksheet, the new worksheet
      // must be available in the redux store before this component is loaded
      if (!worksheetExistsOnWorkbook) {
        throw new WorksheetError(
          `The worksheet exists, but it belongs to a different workbook.
           This can be a case of user manipulating the URL or a bug of confused state when creating a link.`,
          WorksheetErrorTypes.DOES_NOT_EXIST_IN_WORKBOOK
        );
      }

      trackPageView(
        `WebApp/Workbook`,
        `/workbook/${workbook.id}/worksheet/${worksheetId}`,
        {},
        {},
        0,
        eventDestination.ANALYSIS
      );

      // Load worksheet and await all data required further, so that we prevent doing anything if the call fails.
      // Appropriate error will be set by the reducer and we should certainly not update
      // `lastViewedWorksheetId` in the workbook if there's something wrong with it.
      await loadWorksheet(worksheetId);

      if (workbook.isEditable) {
        setLastViewedWorksheetInWorkbook(workbook, worksheetId);
      }
    },
    [worksheetId]
  );

  return (
    <WorksheetErrorBoundary>
      <Async promiseFn={loadWorksheetAsync} key={worksheetId} worksheetId={worksheetId}>
        <Async.Pending>
          <ProgressBar />
          <StageRightPortalContent>
            <ProgressBar />
          </StageRightPortalContent>
        </Async.Pending>
        <Async.Resolved>
          <WorksheetDataView key={worksheetId} selectedWorksheetPanel={selectedWorksheetPanel} />
        </Async.Resolved>
        <Async.Rejected>
          {(err) => {
            if (!err.type) throw err;
            return (
              <>
                <CenteredContent>
                  {switchExpr(err.type, {
                    [WorksheetErrorTypes.DOES_NOT_EXIST]: () => (
                      <WorksheetNotFoundPlaceholder workbook={workbook} worksheetId={worksheetId} />
                    ),
                    /* TODO: Change the component below to reflect the fact that 
                    the error is a different type of error. */
                    [WorksheetErrorTypes.DOES_NOT_EXIST_IN_WORKBOOK]: () => (
                      <WorksheetNotFoundPlaceholder workbook={workbook} worksheetId={worksheetId} />
                    ),
                  })}
                </CenteredContent>
                <StageRightPortalContent>
                  <CenteredContent />
                </StageRightPortalContent>
              </>
            );
          }}
        </Async.Rejected>
      </Async>
    </WorksheetErrorBoundary>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadWorksheet,
      setActiveWorksheetId,
      setLastViewedWorksheetInWorkbook,
    },
    dispatch
  );
}

export default withRouter(
  connect(
    createSelector(
      (state) => state.workbooksById[state.activeWorkbookId],
      (state) => state.appMetadata.isBunkerPricesPanelReadOnly,
      (state) => state.appMetadata.stageLeftPanel,
      (state, ownProps) => state.worksheetsById[ownProps.router.params.worksheetId],
      (state, ownProps) =>
        state.worksheetsById[ownProps.router.params.worksheetId]
          ? state.worksheetsById[ownProps.router.params.worksheetId].workbookId ===
            state.activeWorkbookId
          : false,
      (state, ownProps) => ownProps.router.params.worksheetId,
      (
        workbook,
        isBunkerPricesPanelReadOnly,
        selectedWorksheetPanel,
        worksheet,
        worksheetExistsOnWorkbook,
        worksheetId
      ) => {
        return {
          worksheetId,
          isBunkerPricesPanelReadOnly,
          selectedWorksheetPanel,
          workbook,
          worksheet,
          worksheetExistsOnWorkbook,
        };
      }
    ),
    mapDispatchToProps
  )(({ ...props }) => <WorksheetContainer key={props.worksheetId} {...props} />)
);
