import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  findDisplayItemByMarketSegmentId,
  getMarketSegmentsDisplayItems,
} from 'constants/market-segments';
import { PrimaryButton } from 'components/button';
import Filter from '../filter';
import NewWorkbookItem from '../new-workbook-item';
import WorkbooksGrid from '../workbooks-grid';
import DropDown from 'components/dropdown';
import { selector } from './selector';
import {
  removeWorkbook,
  loadMyWorkbooks,
  createWorkbookWithEmptyWorksheet,
  createWorkbookWithEmptyWorksheetKnownErrorNames,
  searchWorksheets,
} from 'actions/workbook';
import { setMarketSegmentId } from 'actions/user-state';
import WORKBOOK_STATE from '../../workbook-states';
import { buildGridOptions } from './grid-options';
import { DEFAULT_WORKBOOK_NAME } from 'constants/defaults/names';
import { createControllablePromise } from 'utilities/promise/create-controllable-promise';
import { getHostNameFromUrlString } from 'utilities/url';
import { loadUserPreference } from 'actions/user-state';

export class MyWorkbooks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: '',

      selectedMarketSegmentDisplayItem: findDisplayItemByMarketSegmentId(
        this.props.defaultMarketSegmentId
      ),
      worksheets: [],
    };

    this.gridOptions = buildGridOptions(
      this.props.removeWorkbook,
      this.isWorkbookOpen,
      this.props.loadMyWorkbooks
    );
  }

  isWorkbookOpen = (workbookId) => workbookId === this.props.activeWorkbookId;

  onFilterChange = (e) => this.setState({ filterText: e.target.value });

  onWorksheetSearchChange = async (value) => {
    this.setState({ worksheets: value });
  };

  render() {
    const { workbooks, activeWorkbookId } = this.props;
    return (
      <Fragment>
        <Filter
          value={this.state.filterText}
          onChange={this.onFilterChange}
          title={this.props.title}
        />
        <div className="search-panel__actions-above-list">
          <NewWorkbookItemSelfContained
            selectedMarketSegmentDisplayItem={this.state.selectedMarketSegmentDisplayItem}
          />
        </div>
        <div className="search-list">
          <WorkbooksGrid
            workbooks={workbooks}
            activeWorkbookId={activeWorkbookId}
            filterText={this.state.filterText}
            removeWorkbook={this.props.removeWorkbook}
            loadMyWorkbooks={this.props.loadMyWorkbooks}
            gridOptions={this.gridOptions}
          />
        </div>{' '}
      </Fragment>
    );
  }
}

const mapStateToProps = selector;

const MyWorkbooksContainer = connect(mapStateToProps, {
  removeWorkbook,
  loadMyWorkbooks,
  loadUserPreference,
  searchWorksheets,
})(MyWorkbooks);

export default MyWorkbooksContainer;

// TODO - merge into `NewWorkbookItem`
class NewWorkbookItemSelfContainedRequiresConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createWorkbookState: WORKBOOK_STATE.READY_TO_CREATE,

      selectedMarketSegmentDisplayItem: this.props.selectedMarketSegmentDisplayItem,
    };
  }

  render() {
    return (
      <div className="search-panel__add-new">
        <div className="margin-bottom-medium margin-left-auto">
          <PrimaryButton
            onClick={this.handleCreateWorkbook}
            className="search-panel__add-new-btn"
            diagnosticId="MyWorkbooks/NewWorkbook"
          >
            NEW WORKBOOK
          </PrimaryButton>
          <DropDown
            id="marketSegmentId"
            className="search-panel-dropdown"
            items={getMarketSegmentsDisplayItems()}
            selectedItem={this.displayMarketSegment()}
            iconSymbolClassName="search-panel__symbol"
            onChange={this.onSymbolChange}
            asLabel
            useIconSymbols
            diagnosticId="MyWorkbooks/ChangeSymbol"
          />
        </div>
        {this.state.createWorkbookState === WORKBOOK_STATE.READY_TO_CREATE ? null : (
          <div className="search-panel__add-new-inputs-panel">
            <NewWorkbookItem
              createWorkbookState={this.state.createWorkbookState}
              originalName={DEFAULT_WORKBOOK_NAME}
              confirmWorkbookRename={this.handleConfirmWorkbookRename}
              dismissError={this.handleDismissError}
            />
          </div>
        )}
      </div>
    );
  }
  handleCreateWorkbook = async () => {
    const controllableNamePromise = createControllablePromise();
    this.setState({
      createWorkbookState: WORKBOOK_STATE.READY_TO_RENAME,
      controllableNamePromise: controllableNamePromise,
    });
    let finalWorkbookName = await controllableNamePromise.promise;

    this.setState({ createWorkbookState: WORKBOOK_STATE.PENDING });

    try {
      await this.props.createWorkbookWithEmptyWorksheet({
        createdFromProgramId: 'SeaCalcUI',
        createdFromOriginId: getHostNameFromUrlString(document.location.origin),
        workbookName: finalWorkbookName,
        marketSegmentId: this.state.selectedMarketSegmentDisplayItem.key,
      });
      this.setState({
        createWorkbookState: WORKBOOK_STATE.READY_TO_CREATE,
      });
    } catch (error) {
      if (error.name) {
        switch (error.name) {
          case createWorkbookWithEmptyWorksheetKnownErrorNames.ERROR_CREATING:
            this.setState({
              createWorkbookState: WORKBOOK_STATE.ERROR_CREATING,
            });

            break;
          case createWorkbookWithEmptyWorksheetKnownErrorNames.ERROR_RENAMING:
            this.setState({
              createWorkbookState: WORKBOOK_STATE.ERROR_RENAMING,
            });
            break;
          default:
            break;
        }
      }
      throw error;
    }
  };
  onSymbolChange = async (symbol) => {
    this.props.setMarketSegmentId(symbol);
    this.setState({
      selectedMarketSegmentDisplayItem: symbol,
    });
  };

  displayMarketSegment = () => this.state.selectedMarketSegmentDisplayItem;

  handleConfirmWorkbookRename = async (name) => {
    this.state.controllableNamePromise.controller.resolve(name);
  };

  handleDismissError = () =>
    this.setState({
      createWorkbookState: WORKBOOK_STATE.READY_TO_CREATE,
    });
}

const NewWorkbookItemSelfContained = connect(
  /*data properties: */ null,
  /*action properties: */ {
    createWorkbookWithEmptyWorksheet,
    setMarketSegmentId,
  }
)(NewWorkbookItemSelfContainedRequiresConnect);
