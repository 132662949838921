export const fromCubicMetersToCubicFeet = (cubicMeters) => {
  return cubicMeters * 35.3146667214885903;
};

export const fromCubicFeetToCubicMeters = (cubicFeet) => {
  return cubicFeet / 35.3146667214885903;
};

export const fromMetersToFeet = (meters) => {
  return meters * 3.2808398950131234;
};

export const fromFeetToMeters = (feet) => {
  return feet / 3.2808398950131234;
};

export const fromTpcToTpi = (tpc) => {
  return tpc * 2.54;
};

export const fromTpiToTpc = (tpi) => {
  return tpi / 2.54;
};
