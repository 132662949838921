import { isFeatureEnabled } from 'config/feature-control';
import React from 'react';

export function FeatureToggle({ feature, children }) {
  return isFeatureEnabled(feature) ? children : null;
}

export function If({ children }) {
  return children;
}

export function Else({ children }) {
  return children;
}

export function FeatureToggleIfOptionalElse({ feature, children }) {
  const childrenArray = React.Children.toArray(children);
  const ifComponent =
    (childrenArray && childrenArray.length && childrenArray.find((c) => c.type === If)) || null;

  if (isFeatureEnabled(feature)) {
    return ifComponent;
  } else {
    const elseComponent =
      (childrenArray && childrenArray.length && childrenArray.find((c) => c.type === Else)) || null;

    return elseComponent;
  }
}
