import { createSelector } from 'reselect';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';
import { getActiveWorksheetFirstLeg } from 'common-selectors/get-active-worksheet-first-leg';
import { getActiveWorksheet } from '../../../common-selectors/get-active-worksheet';

export const selector = createSelector(
  (state) => getActiveCalculationResult(state),
  getActiveWorksheetFirstLeg,
  getActiveWorksheet,
  (calculationResult, firstLeg, worksheet) => {
    const carbonCostTotal = calculationResult?.carbonBreakdown?.totalVoyageCost ?? 0;
    const totalDays = calculationResult?.time.totalDays ?? 0;
    const cargoSize = firstLeg?.cargoQuantity ?? 0;
    const carbonCostPerDay =
      carbonCostTotal !== 0 && totalDays !== 0 ? carbonCostTotal / totalDays : 0;
    const carbonCostPerMT =
      carbonCostTotal !== 0 && cargoSize !== 0 ? carbonCostTotal / cargoSize : 0;

    const euasDueLeg1 = calculationResult?.carbonBreakdown?.legs[0]?.totalEuasDue ?? 0;
    const euasDueLeg2 = calculationResult?.carbonBreakdown?.legs[1]?.totalEuasDue ?? 0;
    return {
      carbonCostPerDay,
      carbonCostPerMT,
      carbonCostTotal,
      euasDueLeg1,
      euasDueLeg2,
    };
  }
);
