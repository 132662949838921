import React, { Fragment } from 'react';
import LabeledInput from '../labeled-input';
import Label from 'components/label';
import NumericInput from 'components/numeric-input';

const ConstantInputs = ({ constants, actions }) => {
  return (
    <Fragment>
      <div className="intake-calc__row intake-calc__row-first">
        <LabeledInput className="intake-calc__constants">
          <Label className="labeled-input--label">Constants (MT)</Label>
          <NumericInput
            value={constants.general}
            onInputChange={actions.constantsChanged}
            maxDecimalDigits="2"
            minValue="0"
            diagnosticId="IntakeCalculator/Constants"
          />
        </LabeledInput>
        <LabeledInput className="intake-calc__bunkers-on-board">
          <Label className="labeled-input--label">BOB (MT)</Label>
          <NumericInput
            value={constants.bunkersOnBoard}
            onInputChange={actions.bunkersOnBoardChanged}
            maxDecimalDigits="2"
            minValue="0"
            diagnosticId="IntakeCalculator/BunkersOnBoard"
          />
        </LabeledInput>
        <LabeledInput className="intake-calc__fresh-water">
          <Label className="labeled-input--label">Fresh Water (MT)</Label>
          <NumericInput
            value={constants.freshWater}
            onInputChange={actions.freshWaterChanged}
            maxDecimalDigits="2"
            minValue="0"
            diagnosticId="IntakeCalculator/FreshWater"
          />
        </LabeledInput>
      </div>
    </Fragment>
  );
};

export default ConstantInputs;
