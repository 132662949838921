export const OrderType = {
  NONE: 0,
  ALPHA_ASC: 1,
  ALPHA_DESC: 2,
  DATE_ASC: 3,
  DATE_DESC: 4,
};

export function sortArrayObjectsBy(arrObj, sortOrderType: OrderType, colName: string) {
  if (!arrObj) {
    return arrObj;
  }
  try {
    let cloneArrObj = [...arrObj];
    let defaultNoSortObj = undefined;
    switch (sortOrderType) {
      case OrderType.NONE:
        defaultNoSortObj = [...arrObj];
        break;
      case OrderType.ALPHA_ASC:
        cloneArrObj = cloneArrObj.sort((a, b) => {
          return a[colName].localeCompare(b[colName], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        });
        break;
      case OrderType.ALPHA_DESC:
        cloneArrObj = cloneArrObj.sort((a, b) => {
          return b[colName].localeCompare(a[colName], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        });
        break;
      default:
        defaultNoSortObj = [...arrObj];
    }
    return defaultNoSortObj ? defaultNoSortObj : cloneArrObj;
  } catch (err) {
    console.warn('error occurred when sorting, please check the input array, name of the column');
    return arrObj;
  }
}
