import { addMilliseconds, format, toDate } from 'date-fns';
import isNull from 'lodash/isNull';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';

const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;

export const addDaysToDate = (date, days) =>
  date && addMilliseconds(date, daysToMilliseconds(days));

export const parseDate = (dateAsString: string): Date =>
  isNil(dateAsString) ? null : toDate(dateAsString);

export const formatDateMMYYYYWithNullAsEmpty: string = (date: Date | null) => {
  if (!isDate(date) && !isNull(date))
    throw new Error(`Cannot format '${date}': it is not a date | null`);

  return isNull(date) ? '' : format(date, 'MM/yyyy');
};

export const formatDateDDMMMYYWithNullAsEmpty: string = (date: Date | null) => {
  if (!isDate(date) && !isNull(date))
    throw new Error(`Cannot format '${date}': it is not a date | null`);

  return isNull(date) ? '' : format(date, 'dd MMM yy');
};

/**
 * Compare function for dates
 * Includes guards to prevent comparison between non-date values
 * Null values are always the smallest
 *
 * Based on Array.sort comparison function
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * Defaults to ascending order sorting
 * Mind that undefined values will always end up at the end of the array, regardless ordering logic
 * Equally, undefined values will never be passed to the compare function during Array.sort
 *
 * @param {a} date or null value
 * @param {b} date or null value
 */
export const compareDatesWithNullAsSmallest = (a: Date | null, b: Date | null) => {
  if (!isDate(a) && !isNull(a))
    throw new Error(`Comparing '${a}' and '${b}': '${a}' is not a date`);
  if (!isDate(b) && !isNull(b))
    throw new Error(`Comparing '${a}' and '${b}': '${b}' is not a date`);

  if (isNull(a) && isNull(b)) return 0; // both values are null
  if (isNull(a)) return -1; // a is less than b
  if (isNull(b)) return 1; // b is less than a

  return (a > b) - (a < b);
};
