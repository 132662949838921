import {
  SET_BUNKER_PRICES,
  SET_BUNKER_PRICES_PANEL_READONLY,
} from 'constants/action-types/bunker-ports';

const initialState = {
  bunkerPrices: [],
  lastRetrievedAt: null,
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_BUNKER_PRICES:
      return {
        ...state,
        bunkerPrices: action.payload,
        lastRetrievedAt: new Date().getTime(),
      };
    case SET_BUNKER_PRICES_PANEL_READONLY:
      return {
        ...state,
        isBunkerPricesPanelReadOnly: action.payload,
      };
    default:
      return state;
  }
};
