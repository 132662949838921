import { createWorksheet } from './create-worksheet';

export type CreateWorksheetWithVesselIdsResponse = CreateWorksheetResponse;

export const createWorksheetWithVesselIds =
  (vesselIds, workbookName, createdFromProgramId: ProgramId, createdFromOriginId: OriginId) =>
  async (dispatch, getState): CreateWorksheetWithVesselIdsResponse => {
    const request: CreateWorksheetRequest = {
      workbookName,
      vessels: vesselIds.map((vesselId) => ({ id: vesselId })),
    };
    return await dispatch(createWorksheet(request, createdFromProgramId, createdFromOriginId));
  };
