//set (corrects) api version null to 0
export const setWorksheetApiVersion = (worksheet) => {
  worksheet.voyage.legs.forEach((leg) => {
    leg.inboundRoute.variants.forEach((v) => {
      if (!v.routeRetrievedWithGraphVersion) {
        v.routeRetrievedWithGraphVersion = 0;
      }
    });
  });
};
