export const HOURS = { key: 1, label: 'Hours', previewLabel: 'H' };
export const DAYS = { key: 2, label: 'Days', previewLabel: 'D' };

export const getTimeUnitEnumById = (typeId) => {
  switch (typeId) {
    case HOURS.key:
      return HOURS;
    case DAYS.key:
      return DAYS;
    default:
      return {};
  }
};

export const TimeUnitEnum = { DAYS, HOURS };
