import axios from 'axios';
import VError from 'verror';
import * as worksheetClient from 'api/clients/worksheet';
import actionsToTriggerSave from './actions-to-trigger-save';
import debounceAwaitable from 'utilities/functions/debounce-awaitable';
import { debounceDefaults, debounceDefaultExtraOptions } from 'constants/defaults/debounce';
import buildWorksheetRequest from 'api/request-builders/worksheet-request-builder';
import userContext from 'user-context';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { SET_WORKSHEET_CHANGE_STATUS } from 'constants/action-types/workbook';
import { WorksheetChangeStatus, WorksheetStatus } from 'constants/enums/worksheet-status';
import { rateLimited } from 'utilities/functions';
import type { WorksheetSnapshotSyncAfterware } from 'middleware/worksheet-afterware/types';

const autoSaveAfterware: WorksheetSnapshotSyncAfterware = {
  isSyncRequired(newState: IAppState, action): boolean {
    const isSyncRequired = actionsToTriggerSave.includes(action.type);

    if (isSyncRequired) {
      // Safeguard for detecting race-condition. Placed here deliberately, to fire *at the time of dispatch*, as soon as we see that an action was dispatched, and not be hidden by any further throttling or deferring.
      const worksheet = newState.worksheetsById[action.worksheetId];
      if (!worksheet) return; // TODO - this should not be needed (likely a hack to fix tests) but was put here on refactoring to preserve the behaviour. See #TODORemoveSilencingOfErrorsOnLackOfData

      if (worksheet.status !== WorksheetStatus.LOADED) {
        // The error has been added to guard against regressions in which
        // the <WorksheetContainer> component is reused for different worksheets.
        throw new VError(
          {
            info: {
              worksheetId: worksheet.id,
              status: worksheet.status,
            },
          },
          `Action of type ${action.type} was triggered on worksheet before it fully loaded.
          This could potentially happen if an action is triggered on a worksheet that is no longer in view.`
        );
      }
    }

    return isSyncRequired;
  },
  getWorksheetIdForRequiredSync(newState, action) {
    return action.worksheetId;
  },
  sync: (newWorksheetState: IWorksheetViewModel, newGlobalState) => async (dispatch) => {
    await dispatch(saveWorksheet(newWorksheetState.id));
  },
};

export const saveWorksheet = (worksheetId) => async (dispatch, getState) => {
  if (worksheetId === getState().activeWorksheetId) {
    // we only need to debounce saves when the user is changing
    // the active worksheet
    await saveActiveWorksheetRateLimitedDebounced(dispatch);
  } else {
    await saveWorksheetRateLimited(dispatch, worksheetId);
  }
};

/**
 * Auto-save cancellation tokens dictionary
 * keys are worksheet ids
 * values are cancellation tokens
 */
const cancelTokenSources = {};

const saveWorksheetImmediately = (worksheetId: WorksheetId) => async (dispatch, getState) => {
  const state = getState();
  const worksheet = state.worksheetsById[worksheetId];
  if (!worksheet) {
    // #TODORemoveSilencingOfErrorsOnLackOfData
    return;
  }

  const workbook = state.workbooksById && state.workbooksById[worksheet.workbookId];

  if (!workbook) {
    // #TODORemoveSilencingOfErrorsOnLackOfData
    return;
  }

  if (workbook.userId !== userContext.systemUserId) {
    trackEvent(
      'Middleware/autoSave',
      'Middleware AutoSave Editing Colleague Worksheet',
      {
        workbookId: workbook.id,
      },
      {},
      eventDestination.ANALYSIS
    );
    return;
  }

  if (cancelTokenSources[worksheetId]) {
    // Cancellation can help prevent high chances of race conditions in case of network slowdown on the client side followed by a sudden unblock, but it's helpless in case of one further into the network, so TODO - writes need to be queued (await success of previous before sending the next one) and only the ones not sent can be discarded. #TODOQueueWrites This will also be needed for 'concurrency control' (need to learn current version of resource before saving)
    cancelTokenSources[worksheetId].cancel('Cancelled!');
  }

  const { token: cancelToken } = (cancelTokenSources[worksheetId] = axios.CancelToken.source());

  try {
    const worksheetDto = buildWorksheetRequest(worksheet);
    await worksheetClient.saveWorksheet(worksheetDto, {
      cancelToken: cancelToken,
    });
    dispatch({
      worksheetId,
      type: SET_WORKSHEET_CHANGE_STATUS,
      payload: WorksheetChangeStatus.CLEAN,
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      trackEvent(
        'Middleware/autoSave',
        'Middleware AutoSave Cancelled',
        {
          worksheetId: worksheet.id,
        },
        {},
        eventDestination.ANALYSIS
      );
      return;
    }

    throw err;
  } finally {
    if (cancelTokenSources[worksheetId] && cancelTokenSources[worksheetId].token === cancelToken)
      delete cancelTokenSources[worksheetId];
  }
};

const saveWorksheetRateLimited = rateLimited(3, async (dispatch, worksheetId: WorksheetId) => {
  await dispatch(saveWorksheetImmediately(worksheetId));
});

/**
 * This function will only work on 'activeWorksheetId' (thus is devoid of the `worksheetId` parameter), because it has a `debounce` behavior and debounce will discard old calls irrespective of the input paramenters. (#ArgumentlessFunctionToSafeGuardDebounce)
 *
 * The function's responsibility is so restricted so that it is not accidentally used with different ids.
 *
 * If adding `debounce` is desired for non-active workbooks, we'd need a memoizing solution (some finds: [1](https://stackoverflow.com/a/28795512)).
 */
const saveActiveWorksheetRateLimitedDebounced = debounceAwaitable(
  async (dispatch) => {
    await dispatch(async (_, getState) => {
      await saveWorksheetRateLimited(dispatch, getState().activeWorksheetId);
    });
  },
  debounceDefaults.wait,
  {
    ...debounceDefaultExtraOptions,
    /* sea/calc has an 'Optimistic UI', where the user already sees the changed data, while saves happen in the background, so there is no reason to fire any changes immediately (`leading` or `maxWait` after a period of silence), but they can be safely deferred for after the short `wait` time. This allows us to create a complex action out of smaller redux actions without having to redesign the code while showing user multiple actions in the changes audit and reduces the the network congestion. It also makes race conditions, which can occur while #TODOQueueWrites is not resolved, less likely (sending multiple saves at the very short time would actually create a perfect storm for the race conditions to happen). */
    leading: false,
    maxWait: undefined,
  }
);

export default autoSaveAfterware;
