export const SENSITIVITY_ACTIVE_CHANGED = 'SENSITIVITY_ACTIVE_CHANGED';
export const SENSITIVITY_CHANGED = 'SENSITIVITY_CHANGED';
export const SENSITIVITY_VALUE_CHANGED = 'SENSITIVITY_VALUE_CHANGED';
export const SENSITIVITY_CLEAR_DATA = 'SENSITIVITY_CLEAR_DATA';
export const SENSITIVITY_UPDATE_DATA = 'SENSITIVITY_UPDATE_DATA';
export const SENSITIVITY_FIRST_FUEL_GRADE_CHANGED = 'SENSITIVITY_FIRST_FUEL_GRADE_CHANGED';
export const SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED =
  'SENSITIVITY_FIRST_FUEL_SENSITIVITY_CHANGED';
export const SENSITIVITY_SECOND_FUEL_GRADE_CHANGED = 'SENSITIVITY_SECOND_FUEL_GRADE_CHANGED';
export const SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED =
  'SENSITIVITY_SECOND_FUEL_SENSITIVITY_CHANGED';
