import React from 'react';
import classNames from 'classnames';

import './styles.scss';

export const Table = (props) => {
  const classes = classNames('table', props.className);
  return <div className={classes}>{props.children}</div>;
};

export const TableBody = (props) => {
  const classes = classNames('table__body', props.className);
  return <div className={classes}>{props.children}</div>;
};

export const TableHeader = (props) => {
  const classes = classNames('table__header', props.className);
  return <div className={classes}>{props.children}</div>;
};

export const TableRow = (props) => {
  const classes = classNames('table__row', props.className);
  return <div className={classes}>{props.children}</div>;
};
