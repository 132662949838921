import React from 'react';
import { OverlayView } from 'react-google-maps';
import {
  LoadPortSymbol,
  DischargePortSymbol,
  BunkerPortSymbol,
  ViaPortSymbol,
  VesselOpenLocationSymbol,
} from '../map-symbol';

import types from './marker-types';

import './styles.scss';

const Marker = ({ position, markerType, label }) => {
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div>
        {mapSymbol[markerType] || null}
        <div className="marker-label">{label}</div>
      </div>
    </OverlayView>
  );
};

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

// cache symbols as these won't change across renders
const mapSymbol = {
  [types.LOAD_PORT]: <LoadPortSymbol />,
  [types.DISCHARGE_PORT]: <DischargePortSymbol />,
  [types.VIA_PORT]: <ViaPortSymbol />,
  [types.BUNKER_PORT]: <BunkerPortSymbol />,
  [types.VESSEL_OPEN_LOCATION]: <VesselOpenLocationSymbol />,
};

export default Marker;
export { default as markerTypes } from './marker-types';
