import React from 'react';
import NumericInput from 'components/numeric-input';
import type { SpeedAndConsumptionType } from '../type';

type Props = {
  speed: number,
  showSpeed: boolean,
  speedAndConType: SpeedAndConsumptionType,
  onSpeedChanged: Function,
  isParcelVoyage: boolean,
};

export const speedMaxPrecision = 2;
export const speedMinValue = 0;
export const speedMaxValue = 999.99;

const SpeedInput = (props: Props) => {
  const { speed, showSpeed, speedAndConType, onSpeedChanged } = props;

  if (showSpeed) {
    return (
      <NumericInput
        value={speed}
        onInputChange={onSpeedChanged(speedAndConType)}
        className="speed-and-cons-multi-vessel__moving--speed"
        maxDecimalDigits={speedMaxPrecision}
        minValue={speedMinValue}
        maxValue={speedMaxValue}
        mandatory
      />
    );
  } else {
    return <div className="speed-and-cons-multi-vessel__moving--speed" />;
  }
};

export default SpeedInput;
