import React, { FC, forwardRef } from 'react';
import classnames from 'classnames';

import './styles.scss';

export interface IActionBar {
  className: string;
  children: React.ReactNode;
}

const ActionBar = forwardRef(({ className, children }, ref) => {
  const classes = classnames('action-bar', className);
  return (
    <div className={classes} ref={ref}>
      {children}
    </div>
  );
});

export interface IActionBarItem {
  className?: string;
  children: React.ReactNode;
}
export const ActionBarItem: FC<IActionBarItem> = ({ className, children }) => {
  const classes = classnames('action-bar__item', className);
  return <div className={classes}>{children}</div>;
};

export default ActionBar;
