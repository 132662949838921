import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from 'components/headers';
import { formatNumber } from 'utilities/number';
import { selector } from './selector';
import { isDry, isWet } from 'constants/market-segments';
import './styles.scss';

export class CalculationSummaryCargo extends Component {
  getStowageOrSpecificGravityLabel() {
    if (isDry(this.props.marketSegmentId)) {
      return 'Stowage ' + this.props.stowageUnit.label;
    } else if (isWet(this.props.marketSegmentId)) {
      return 'Specific Gravity';
    }
  }

  getStowageOrSpecificGravityValue() {
    if (isDry(this.props.marketSegmentId)) {
      return this.props.stowage;
    } else if (isWet(this.props.marketSegmentId)) {
      return this.props.specificGravity;
    }
  }

  render() {
    return (
      <div className="summary-cargo-container">
        <Header text="Cargo" />
        <div className="summary-data">
          <div className="summary-data__row">
            <div className="summary-data__row-cell summary-data__row-cell-label">Cargo Type</div>
            <div className="summary-data__row-cell summary-data__row-cell-value">
              {this.props.name}
            </div>
          </div>
          <div className="summary-data__row">
            <div className="summary-data__row-cell summary-data__row-cell-label">Quantity</div>
            <div className="summary-data__row-cell summary-data__row-cell-value">
              {this.props.quantity}
            </div>
          </div>
          <div className="summary-data__row">
            <div
              className="summary-data__row-cell summary-data__row-cell-label"
              dangerouslySetInnerHTML={{
                __html: this.getStowageOrSpecificGravityLabel(),
              }}
            />
            <div className="summary-data__row-cell summary-data__row-cell-value">
              {this.getStowageOrSpecificGravityValue()}
            </div>
          </div>
          <div className="summary-data__row">
            <div className="summary-data__row-cell summary-data__row-cell-label">
              Prevailing Intake MT
            </div>
            <div className="summary-data__row-cell summary-data__row-cell-value">
              <div className="lowest-maximum-lift__value">
                {this.props.lowestMaximumLiftInVoyage.errors.length === 0 ? (
                  formatNumber(this.props.lowestMaximumLiftInVoyage.lowestMaximumLift, 0)
                ) : (
                  <>&mdash;</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

export default connect(mapStateToProps)(CalculationSummaryCargo);
