import find from 'lodash/find';
import { TC_TO_FREIGHT } from './sensitivity-types';
import { createSelector } from 'reselect';
import { singleOrThrow } from 'utilities/iterable';
import { grossFromNet } from 'utilities/rates-converter';
import { getActiveVessel } from '../../common-selectors/get-active-vessel';

// TODO add type definitions

const getAverageFuelGradePrice = (bunkers, consumptions, fuelGrade) => {
  if (!consumptions) {
    return null;
  }
  let totalConsumption = 0;
  let totalBunkersCost = 0;

  bunkers.forEach((bunker) => {
    const bunkerConsumptions = find(consumptions, ['id', bunker.bunkerId]);
    totalConsumption += bunkerConsumptions[fuelGrade.code];
    totalBunkersCost += bunkerConsumptions[fuelGrade.code] * bunker[fuelGrade.code].price;
  });

  return totalConsumption > 0 ? totalBunkersCost / totalConsumption : 0;
};

function getCalculations(results, firstFuelGrade, secondFuelGrade) {
  var calcResults = results[0].calculationResult;

  return calcResults.map((result) => {
    var calculationInputs =
      (result.calculationResult && result.calculationResult.calculationInputs) ||
      result.calculationInputs;

    const {
      rates: { rateParameters, timeCharterNet, timeCharterCommission },
      bunkers: bunkersRequest,
    } = calculationInputs;

    const {
      timeCharter,
      voyageRate,
      bunkers: bunkersResponse,
    } = result.calculationResult || result;

    const timeCharterGross = grossFromNet(timeCharterNet, timeCharterCommission);

    const timeCharterRateCalculatedNet = voyageRate && voyageRate.timeCharterRateCalculatedNet;
    const timeCharterRateCalculatedGross = voyageRate && voyageRate.timeCharterRateCalculatedGross;
    const totalConsumptionPerBunker = bunkersResponse && bunkersResponse.totalConsumptionPerBunker;
    const voyageRateCalculatedGross = timeCharter && timeCharter.voyageRateCalculatedGross;
    const averageFirstFuelGradePrice = getAverageFuelGradePrice(
      bunkersRequest,
      totalConsumptionPerBunker,
      firstFuelGrade
    );
    const averageSecondFuelGradePrice = getAverageFuelGradePrice(
      bunkersRequest,
      totalConsumptionPerBunker,
      secondFuelGrade
    );

    return {
      voyageRateGross: rateParameters?.voyageRateGross,
      voyageRateCalculatedGross,
      timeCharterGross,
      timeCharterNet,
      timeCharterRateCalculatedNet,
      timeCharterRateCalculatedGross,
      averageFirstFuelGradePrice,
      averageSecondFuelGradePrice,
    };
  });
}

export const selector = createSelector(
  (state) => state.calculationSensitivity,
  (state) =>
    (state.worksheetsById[state.activeWorksheetId] &&
      singleOrThrow(state.worksheetsById[state.activeWorksheetId].cargoes).cargoRate
        .grossVoyageRate) ||
    0,
  (state) =>
    (getActiveVessel(state) || /* Todo - change to `?.` once available */ {}).grossTimeCharter || 0,
  (
    {
      activeSensitivities,
      grossTimeCharterRateSensitivity,
      grossFreightRateSensitivity,
      firstFuelGrade,
      firstFuelSensitivity,
      secondFuelGrade,
      secondFuelSensitivity,
      results,
    },
    grossVoyageRate,
    grossTimeCharter
  ) => {
    const activeSensitivity = activeSensitivities[0];
    const sensitivityValue =
      activeSensitivity === TC_TO_FREIGHT
        ? grossTimeCharterRateSensitivity
        : grossFreightRateSensitivity;
    let calculationResults = [];

    if (results[0]) {
      const hasAllCalculationResults = results.every((_) => _.calculationResult);
      const hasAllCalculationResultCalculationInputs = results[0].calculationResult.every(
        (_) => _.calculationInputs
      );

      let calculations = getCalculations(results, firstFuelGrade, secondFuelGrade);
      calculationResults =
        (hasAllCalculationResults && hasAllCalculationResultCalculationInputs && calculations) ||
        [];
    }

    return {
      activeSensitivity,
      sensitivityValue,
      firstFuelGrade,
      firstFuelSensitivity,
      secondFuelGrade,
      secondFuelSensitivity,
      calculationResults,
      grossVoyageRate,
      grossTimeCharter,
    };
  }
);
