export function isUrlsHostnameInDomain(url: URL, domainToCheck: string) {
  return (
    url.hostname === domainToCheck ||
    url.hostname.endsWith(ensureDomainStartsWithDot(domainToCheck))
  );
}

export function isUrlsPortDefault(url: URL) {
  // Empty string when no port as per spec - https://developer.mozilla.org/en-US/docs/Web/API/URL/port
  return url.port === '';
}

export function isUrlsProtocolWithEncryption(url: URL) {
  return url.protocol === 'https:';
}

/**
 * This is a 'double-checking' to add a trailing '.' because this is critical for security: if we don't check '.sea.live', but just 'sea.live' (note lack of the '.' at the start), then a hacker is free to register a domain like 'pirates-of-the-carribean-sea.live' that he can use to infiltrate us.
 */
function ensureDomainStartsWithDot(domainNameWithOrWithoutTrailingDot) {
  return domainNameWithOrWithoutTrailingDot[0] === '.'
    ? domainNameWithOrWithoutTrailingDot
    : '.' + domainNameWithOrWithoutTrailingDot;
}

export function getHostNameFromUrl(url: URL) {
  return url.hostname;
}

export function getHostNameFromUrlString(urlString: string) {
  return getHostNameFromUrl(new URL(urlString));
}
