import { secaZones } from 'constants/data/waypoints';
import stringEnum, { StringEnum } from 'utilities/enum/string-enum';
import { generatePowerset } from 'utilities/set/set-utils';
import { soxPercentLimits } from './sox-percent-limits';

const operationalRestrictionIdsArray = ['seca', 'sludgeDischargeBan'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
export type OperationalRestrictionId = 'seca' | 'sludgeDischargeBan';

export const operationalRestrictionIds: StringEnum<OperationalRestrictionId> = stringEnum(
  operationalRestrictionIdsArray
);

export const allPossibleOperationalRestrictionSets = generatePowerset(
  Object.values(operationalRestrictionIds)
);

export type ZoneTypeId = UUID;

export function getOperationalRestrictionIdFromZoneInfo(
  zoneInfo: Iterable<IZoneIdentityAndType>
): OperationalRestrictionId | null {
  switch (normalizeCaseForComparison(zoneInfo.typeId)) {
    case supportedZoneTypeIds.openLoopRestrictedArea___State_Active___Level_ScrubberDischargeBan:
      return operationalRestrictionIds.sludgeDischargeBan;
    case supportedZoneTypeIds.ecaActive:
      if (isEcaZoneWhichCurrentlyIsNotASecaZone(zoneInfo.id)) return null;
      else return operationalRestrictionIds.seca;
    default:
      return null;
  }
}

/**
 ECA - Emission Control Area - this merely means an area considered in legislature, but *when* the specific restrictions will applied may vary (e.g. some zones can have sulphur emissions restrictions set to go in force only at some future date)
 SECA - Sulfur Emission Control Area - these zones are the ones which sea/calc considers to have the SOX (sulfur oxide) emissions currently in force.
 */
export function isEcaZoneWhichCurrentlyIsNotASecaZone(zoneId: LocationId) {
  /* SECA metadata would one day come from routeResult (api/clients/location)*/
  const waypointSecaInfo = secaZones[zoneId];
  /**
    We need to filter because, unlike the obsolete waypoints, 
    the Routing API Keeps returning these zones that may have a lower limit in the future.
    If we didn't filter them, they would get persisted even on new worksheets, 
    but in the current persistence model there is no way to hold information that 
    the waypoint hasn't been used for calculating the SECA distance, so it would 
    always show to the user.
  */
  if (
    waypointSecaInfo &&
    waypointSecaInfo.currentSOXLimitPercent &&
    waypointSecaInfo.currentSOXLimitPercent >= soxPercentLimits.imoGlobalLimit
  ) {
    // Zones with SOX limit equal and over the IMO global limit are not considered SECA
    return true;
  }
  return false;
}

export const supportedZoneTypeIds: { [zoneTypeName: string]: ZoneTypeId } = {
  /* 
  NOTE: this is a zone called "ECA Active" and not the similarly named zone called 
  "ECA" (3a40ab9e-6989-44e2-b742-3f4ea77b3f72)! The "ECA" one contains deprecated zones 
  only. In an effort to de-cluttered and re-consolidated ECA zones split by status 
  (e.g. Active), there might be a new zone type, which we might be required to filter 
  the inactive instances by status, or even voyage dates - if we are meant to support 
  performing a calculation with future ECA zones). However, at present, it's the 
  "ECA Active" that is contractually agreed to contain the zones used for voyage estimation. 
  */
  ecaActive: normalizeCaseForComparison('22f0eeed-1c5a-4a7f-9c30-5e7f1cf609d2'),
  openLoopRestrictedArea___State_Active___Level_ScrubberDischargeBan: normalizeCaseForComparison(
    '2a803e55-cfd0-4f98-a5e2-239f6ff05c74'
  ),
};

function normalizeCaseForComparison(stringValue: string) {
  return stringValue.toLowerCase();
}
