import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Label = (props) => {
  const classes = classNames('label', props.className);
  return <label className={classes}>{props.children}</label>;
};

export default Label;
