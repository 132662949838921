import { RATE_COMMISSION_TIME_CHARTER_CHANGED } from 'constants/action-types/worksheet/rates';

export function rateCommissionTimeCharterUpdated(
  brokerCommissionTimeCharter,
  addressCommissionTimeCharter,
  worksheetId
) {
  return {
    payload: {
      brokerCommissionTimeCharter,
      addressCommissionTimeCharter,
    },
    worksheetId,
    type: RATE_COMMISSION_TIME_CHARTER_CHANGED,
  };
}
