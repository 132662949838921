import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import { selector } from './selector';
import DaysBreakdown from './days-breakdown/days-breakdown';
import './styles.scss';

export class CalculationSummaryVoyage extends Component {
  renderHeaderRowCell = (content, rightAlign) => {
    const classes = classNames('summary-voyage-data__header-cell', {
      'summary-voyage-data__header-cell--right': rightAlign,
    });

    return <div className={classes}>{content}</div>;
  };

  renderHeaderRow = () => {
    return (
      <div className="summary-voyage-data__header">
        {this.renderHeaderRowCell('Type')}
        {this.renderHeaderRowCell('Location')}
        {this.renderHeaderRowCell('Date')}
        {this.renderHeaderRowCell('Qty (MT)', true)}
        {this.renderHeaderRowCell('Cost ($)', true)}
        {this.renderHeaderRowCell('Dist. (nm)', true)}
        {this.renderHeaderRowCell('Days', true)}
      </div>
    );
  };

  renderDataRows = () => {
    return this.props.legs.map((item, index) => (
      <div key={index} className="summary-voyage-data__row">
        <DataCell>{item.type.label}</DataCell>
        <DataCell>{item.name}</DataCell>
        <DataCell>
          <time dateTime={item.date && item.date.toGMTString()}>{formatDate(item.date)}</time>
        </DataCell>
        <DataCell rightAlign>{item.cargoQuantity}</DataCell>
        <DataCell rightAlign>{item.portCosts}</DataCell>
        <DataCell rightAlign>{item.distance}</DataCell>
        <DataCell rightAlign>{item.days}</DataCell>
      </div>
    ));
  };

  renderFooterRowCell = (content, rightAlign, highlight) => {
    const classes = classNames(
      'summary-voyage-data__footer-cell',
      {
        'summary-voyage-data__footer-cell--right': rightAlign,
      },
      {
        'summary-voyage-data__footer-cell--highlight': highlight,
      }
    );

    return <div className={classes}>{content}</div>;
  };

  renderFooterRow = () => {
    return (
      <div className="summary-voyage-data__footer">
        {this.renderFooterRowCell('Total')}
        {this.renderFooterRowCell('')}
        {this.renderFooterRowCell('')}
        {this.renderFooterRowCell(this.props.totalCargoQuantity, true, true)}
        {this.renderFooterRowCell(this.props.totalPortCosts, true, true)}
        {this.renderFooterRowCell(this.props.totalDistance, true, true)}
        {this.renderFooterRowCell(this.props.totalDays, true, true)}
      </div>
    );
  };

  render() {
    return (
      <div className="summary-section summary-section--border">
        <h2>Voyage</h2>
        <div className="summary-voyage-data">
          {this.renderHeaderRow()}
          {this.renderDataRows()}
          {this.renderFooterRow()}
        </div>
        <DaysBreakdown />
      </div>
    );
  }
}

function DataCell({ children, rightAlign }) {
  return (
    <div
      className={classNames('summary-voyage-data__row-cell', {
        'summary-voyage-data__row-cell--right': rightAlign,
      })}
    >
      {children}
    </div>
  );
}

const mapStateToProps = selector;

const formatDate = (date: Date): string => (isNil(date) ? '' : format(date, 'dd MMM yy'));

export default connect(mapStateToProps)(CalculationSummaryVoyage);
