import { veApi } from 'api';
import numbro from 'numbro';
import { REFERENCE_DATA_BALTIC_TANKER_INDEX_VESSELS_LOADED } from 'constants/action-types/reference-data';
import { AxiosError } from 'axios';

export const loadBalticTankerIndexVessels = () => (dispatch) => {
  return veApi
    .get('/vessel/baltictankerindex')
    .then((response) => {
      const vessels = response.data.vessels.map(parseVessel);
      dispatch(balticTankerIndexVesselsLoaded(vessels));
    })
    .catch((error: AxiosError) => {
      dispatch(balticTankerIndexVesselsLoaded([]));
    });
};

const parseVessel = (vessel) => {
  const minDeadweight = numbro(vessel.minDeadweight).format({
    thousandSeparated: true,
  });
  const maxDeadweight = numbro(vessel.maxDeadweight).format({
    thousandSeparated: true,
  });
  return {
    vesselId: vessel.vesselId,
    vesselName: vessel.displayName,
    deadweight: `${minDeadweight}-${maxDeadweight}`,
    isBalticVessel: true,
  };
};

function balticTankerIndexVesselsLoaded(payload) {
  return {
    type: REFERENCE_DATA_BALTIC_TANKER_INDEX_VESSELS_LOADED,
    payload,
  };
}
