import WorksheetNameReducer from '../worksheet/name';
import VesselsReducer from '../worksheet/vessels';
import BunkersReducer from '../worksheet/bunkers';
import CanalCostsReducer from '../worksheet/canals';
import VoyageReducer from '../worksheet/voyage';
import ChangeStatusReducer from '../worksheet/change-status';
import StatusReducer from '../worksheet/status';
import AdditionalExpensesReducer from '../worksheet/additional-expenses';
import CargoesReducer from '../worksheet/cargoes';
import AdditionalIncomesReducer from '../worksheet/cargoes/additional-incomes';
import RatesReducer from '../worksheet/rates';
import { combineReducersMergeable } from 'utilities/redux/combine-reducers-mergeable';
import concatenateReducers from 'redux-concatenate-reducers';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import { ensuredNotNil } from 'utilities/assertions/ensured-not-nil';
import ShowUnusedBunkersReducer from '../worksheet/show-unused-bunkers';
import EuaReducer from '../worksheet/eua';
import { GENERATOR_CHANGED_IN_EDIT_SPEED_AND_CONS_CHANGED } from '../../constants/action-types/worksheet/speed-and-consumptions';

export const worksheetReducer = concatenateReducers([
  /* Complex mutable object properties go here: */
  combineReducersMergeable({
    name: WorksheetNameReducer,
    vessels: VesselsReducer,
    cargoes: CargoesReducer,
    rates: RatesReducer,
    voyage: VoyageReducer,
    bunkerExpenses: BunkersReducer,
    canals: CanalCostsReducer,
    additionalExpenses: AdditionalExpensesReducer,
    /**
     * This property tracks whether the worksheet view model has been updated,
     * but hasn't been persisted.i.e. it has changes that haven't been pushed
     * to the database. It is useful to detect on per-worksheet basis whether
     * the auto-save middleware failed for a specific worksheet.
     */
    changeStatus: ChangeStatusReducer,
    status: StatusReducer,
    additionalIncomes: AdditionalIncomesReducer,
    showUnusedBunkers: ShowUnusedBunkersReducer,
    eua: EuaReducer,
  }),
  /* Simple immutable properties initialized on load go here: */
  (state, action) => {
    switch (action.type) {
      case LOAD_WORKSHEET:
        return {
          id: action.payload.id,
          workbookId: action.payload.workbookId,
          marketSegmentId: ensuredNotNil(action.payload.marketSegmentId, {
            errorMessagePostamble:
              'The worksheet from the `action.payload` of the `LOAD_WORKSHEET` action did not contain `marketSegmentId`',
          }),
          createdFromProgramId: action.payload.createdFromProgramId,
          createdFromOriginId: action.payload.createdFromOriginId,
          copiedFromWorksheetId: action.payload.copiedFromWorksheetId,
          showEditGeneratorSpeedAndCons: action.payload.showEditGeneratorSpeedAndCons,
        };
      case GENERATOR_CHANGED_IN_EDIT_SPEED_AND_CONS_CHANGED:
        return {
          ...state,
          showEditGeneratorSpeedAndCons: action.payload,
        };
      default:
        return state;
    }
  },
]);
