import React from 'react';
import LabeledInput from '../labeled-input';
import Label from 'components/label';
import CargoAutoComplete from 'components/cargo-autocomplete';
import {
  convertStowage,
  CUBIC_METERS_PER_METRIC_TON,
  STOWAGE_NUMBER_OF_DECIMAL_PLACES,
} from 'constants/enums/stowage-factor-units';
import round from 'lodash/round';
import { StowageAndSpecificGravityInput } from 'components/cargo-stowage-and-specific-gravity-input';
import { isDry, isTanker, isWet } from 'constants/market-segments';
import classNames from 'classnames';

const CargoInputs = ({ cargo, actions, marketSegmentId }) => {
  const onStowageUnitChangedHandler = React.useCallback(
    (stowageUnit) => {
      const stowage = convertStowage(cargo.stowage, cargo.stowageUnit, stowageUnit);
      const payload = { stowage, stowageUnit };
      actions.stowageUnitChanged(payload);
    },
    [actions, cargo]
  );

  const cargoChanged = (cargo) => {
    cargoChangedAndCallMaxLiftApi(cargo);
  };

  const cargoChangedAndCallMaxLiftApi = React.useCallback(
    (cargo) => {
      const cargoWithMaxLiftFlag = [cargo, { updateMaxLift: true }];
      actions.cargoChanged(cargoWithMaxLiftFlag);
      if (
        cargo.stowageMeters !== undefined &&
        cargo.stowageFeet !== undefined &&
        isDry(marketSegmentId)
      ) {
        const newStowage =
          cargo.stowageUnit === CUBIC_METERS_PER_METRIC_TON
            ? cargo.stowageMeters
            : cargo.stowageFeet;
        const newRoundedStowage = round(newStowage, STOWAGE_NUMBER_OF_DECIMAL_PLACES);
        const stowageWithMaxLiftFlag = [
          round(newRoundedStowage, STOWAGE_NUMBER_OF_DECIMAL_PLACES),
          { updateMaxLift: false },
        ];
        actions.stowageChanged(stowageWithMaxLiftFlag);
      } else if (isWet(marketSegmentId)) {
        const specificGravityWithMaxLiftFlag = [cargo.specificGravity, { updateMaxLift: false }];
        actions.specificGravityChanged(specificGravityWithMaxLiftFlag);
      }
    },
    [actions, marketSegmentId]
  );

  const stowageAndSpecificGravityProps = {
    marketSegmentId: marketSegmentId,
    stowageUnit: cargo.stowageUnit,
    stowage: cargo.stowage,
    specificGravity: cargo.specificGravity,
    onStowageUnitUpdated: onStowageUnitChangedHandler,
    stowageUnitDiagnosticId: 'IntakeCalculator/CargoStowageUnit',
    stowageDiagnosticId: 'IntakeCalculator/CargoStowage',
    onSpecificGravityUpdated: actions.specificGravityChanged,
    onStowageUpdated: actions.stowageChanged,
  };

  return (
    <div className="intake-calc__row intake-calc__row-first">
      <LabeledInput
        className={classNames('intake-calc__cargo', {
          'tankers-intake': isTanker(marketSegmentId),
        })}
      >
        <Label className="labeled-input--label">Cargo Type</Label>
        <CargoAutoComplete
          cargo={cargo}
          onChange={cargoChanged}
          diagnosticId="IntakeCalculator/Cargo"
          marketSegmentId={marketSegmentId}
        />
      </LabeledInput>
      <LabeledInput className="intake-calc__stowage">
        <StowageAndSpecificGravityInput {...stowageAndSpecificGravityProps} />
      </LabeledInput>
    </div>
  );
};

export default CargoInputs;
