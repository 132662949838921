import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import Downshift from 'downshift';
import NumericInput from 'components/numeric-input';
import ProgressBar, { Size } from 'components/progress-bar';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { Grid } from 'components/grid';
import './styles.scss';
import classNames from 'classnames';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

type Column = {
  key: string,
  label: string,
  minWidth: ?number,
  maxWidth: ?number,
  rightAlign: ?boolean,
};

type Props = {
  columns: React.ChildrenArray<Column>,
  items: React.ChildrenArray<object>,
  /**
   * Use when `items` should be retrieved on every focus.
   */
  onDataLoad?: () => Promise<Array<object>>,
  value: number,
  onDropDownChange: Function,
  onInputChange: Function,
  maxDecimalDigits: ?number,
  minValue: ?number,
  maxValue: ?number,
  className: ?string,
  /**
   * When `true`, the control will not offer the dropdown - it will behave as a simple input.
   */
  dropdownDisabled: boolean,
};

type State = {};

class TableDropDown extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items || [],
      isLoadingData: false,
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      (isNil(this.props.value) && isNil(nextProps.value)) ||
      this.state !== nextState
    );
  }

  onFocusHandler = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
    clearSelection: Function,
    openMenu: Function
  ): void => {
    if (this.props.dropdownDisabled) return;

    clearSelection();
    openMenu();

    if (this.props.onDataLoad) {
      this.setState({
        isLoadingData: true,
      });

      this.props.onDataLoad().then((items) => {
        this.setState({
          items,
          isLoadingData: false,
        });
      });
    }
  };

  onKeyDownHandler = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
    selectHighlightedItem: Function
  ): void => {
    const keyCode = event?.key;

    if (isNil(keyCode) === false && keyCode === 'Tab') {
      selectHighlightedItem();
    }
  };

  renderDataLoadingRow = (columns: React.ChildrenArray<Column>): React.Node => (
    <div style={{ display: 'flex' }}>
      {columns.map((column: Column): React.Node => (
        <div
          key={column.key}
          style={{ minWidth: `${column.minWidth}px`, maxWidth: `${column.maxWidth}px` }}
        >
          {<ProgressBar size={Size.XSMALL} />}
        </div>
      ))}
    </div>
  );

  dynamicColumnDefs = () =>
    this.props.columns?.map((column) => {
      const headerCellClass = classNames('table-dropdown__menu-header-cell', {
        'table-dropdown__menu-cell--highlighted': column.highlight,
      });
      const itemCellClass = classNames('table-dropdown__menu-cell', {
        'table-dropdown__menu-cell--highlighted': column.highlight,
        'table-dropdown__menu-cell--right': column.rightAlign,
      });

      return {
        headerName: column.label,
        field: column.key,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        autoHeight: true,
        tooltipValueGetter: (params) => params.value,
        cellClass: itemCellClass,
        headerClass: headerCellClass,
      };
    });

  gridOptions = {
    headerHeight: 32,
    animateRows: true,
    defaultColDef: { sortable: true },
    rowClass: 'table-dropdown__menu-item',
    columnDefs: this.dynamicColumnDefs(),
    autoSizeStrategy: {
      type: 'fitGridWidth',
    },
    suppressHorizontalScroll: true,
    scrollbarWidth: 8,
    rowBuffer: 30,
    tabIndex: 0,
  };

  updateGrid(agGridApi) {
    const gridElement = document.querySelector('#table-dropdown-grid');

    if (this.state.items.length < 6) {
      agGridApi.setGridOption('domLayout', 'autoHeight');
      gridElement.style.height = 'auto';
    } else {
      agGridApi.setGridOption('domLayout', 'normal');
      gridElement.style.height = '250px';
    }
  }
  itemToString = (item: object | null): string => '';
  handleRowClick = (row, closeMenu) => {
    closeMenu();
    this.props.onDropDownChange(row.data);
  };
  render(): React.Node {
    const {
      columns,
      value,
      onDropDownChange,
      onInputChange,
      maxDecimalDigits,
      minValue,
      maxValue,
      numericInputClassName,
      className,
      isValidationMessageDismissable,
      diagnosticId,
    }: Props = this.props;

    return (
      <Downshift onChange={onDropDownChange} itemToString={this.itemToString}>
        {({
          getInputProps,
          getItemProps,
          isOpen,
          highlightedIndex,
          selectHighlightedItem,
          clearSelection,
          openMenu,
          closeMenu,
        }) => (
          <div className="table-dropdown">
            <NumericInput
              value={value}
              diagnosticId={diagnosticId}
              onInputChange={onInputChange}
              maxDecimalDigits={maxDecimalDigits}
              minValue={minValue}
              maxValue={maxValue}
              isValidationMessageDismissable={isValidationMessageDismissable}
              className={numericInputClassName}
              inputProps={getInputProps({
                onFocus: (event) => this.onFocusHandler(event, clearSelection, openMenu),
                onKeyDown: (event) => this.onKeyDownHandler(event, selectHighlightedItem),
              })}
            />
            {isOpen ? (
              <div
                id={`table-dropdown-grid`}
                className={`table-dropdown__menu ${className}`}
                data-test="menu"
                role="menu"
              >
                <Grid
                  getRowId={(params) => params.data.id}
                  rowData={this.state.items}
                  gridOptions={this.gridOptions}
                  onGridReady={this.onGridReady}
                  className="dropdown-grid"
                  reactiveCustomComponents
                  onRowClicked={(row) => {
                    this.handleRowClick(row, closeMenu);
                  }}
                  overlayNoRowsTemplate="No data available for this location"
                  tooltipShowDelay={300}
                  noRowsOverlayComponent={() =>
                    this.state.isLoadingData
                      ? this.renderDataLoadingRow(columns)
                      : 'No data available for this location'
                  }
                  onRowDataUpdated={(params) => this.updateGrid(params.api)}
                />
              </div>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

export default TableDropDown;
