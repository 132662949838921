import React from 'react';
import { compose, withProps } from 'recompose';
import { withGoogleMap, GoogleMap, Polyline } from 'react-google-maps';
import isNil from 'lodash/isNil';
import Marker from '../marker';

const defaultOptions = {
  gestureHandling: 'greedy',
  mapTypeControl: false,
  fullscreenControl: false,
  disableDefaultUI: true,
  minZoom: 2,
  maxZoom: 7,
};

const Map = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `65vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(({ routes, markers, closeMap }) => {
  const { zoom, center } = getDefaultPosition;

  return (
    <GoogleMap
      ref={(map) => fitToBounds(map, routes)}
      options={defaultOptions}
      defaultZoom={zoom}
      defaultCenter={center}
    >
      {routes.map((route, index) => (
        <Polyline key={route.id} path={route.coordinates} options={buildRouteLineOptions(index)} />
      ))}
      {markers.map((marker) => (
        <Marker
          key={marker.id}
          position={marker.coordinate}
          markerType={marker.markerType}
          label={marker.name}
        />
      ))}
    </GoogleMap>
  );
});

const fitToBounds = (map, routes) => {
  if (isNil(routes) || routes.length !== 0) {
    const bounds = new window.google.maps.LatLngBounds();

    for (const route of routes) {
      for (const coordinate of route.coordinates) {
        const { lng, lat } = coordinate;
        const latLng = new window.google.maps.LatLng(lat, lng);

        bounds.extend(latLng);
      }
    }

    return map && map.fitBounds(bounds) && map.setCenter(bounds.getCenter());
  }
};

const buildRouteLineOptions = (index) => {
  const routeLineColourIndex = index % routeLineColours.length;
  const routeColour = routeLineColours[routeLineColourIndex];

  return {
    geodesic: true,
    strokeColor: routeColour,
    strokeOpacity: 0.8,
    strokeWeight: 2,
  };
};

const routeLineColours = [
  '#00bfff', // clarksons-blue
  '#af00ff', // clarksons-purple
  '#aa7839', // clarksons-gold
  '#ec407a', // clarksons-pink
  '#ff6c00', // clarksons-orange
  '#607d8b', // clarksons-grey
  '#ffcb00', // clarksons-yellow
  '#00a69a', // clarksons-teal
  '#5a64f0', // clarksons-violet
  '#d1c50a', // clarksons-mustard
  '#a6adbc', // clarksons-white
  '#737373', // clarksons-border-color
];

const getDefaultPosition = {
  zoom: 2,
  center: {
    lat: 46,
    lng: 0,
  },
};

export default Map;
