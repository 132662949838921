import {
  LADEN,
  BALLAST,
  ECO_LADEN,
  ECO_BALLAST,
  PORT_IDLE,
  PORT_MANOEUVRING,
  CANAL,
  getSpeedAndConTypeById,
  LOADING,
  DISCHARGING,
  SAILING,
  ECO_SAILING,
} from 'constants/enums/speed-and-con-types';
import { getFuelGradeFor } from 'constants/enums/fuel-grades';
import { createSelector } from 'reselect';
import { isScrubberFitted } from 'constants/enums/scrubber';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';
import { isDry } from 'constants/market-segments';

export const selector = createSelector(
  getActiveWorksheetId,
  getActiveWorksheetInvariantProps,
  (state, props) => props.vessel,
  (worksheetId, worksheetInvariantProps, vessel) => {
    const enrich = (speedAndCon) => {
      let speedAndConType = { ...getSpeedAndConTypeById(speedAndCon.type) };

      // for dryCargo, Loading should be the same as Discharging (Loading is displayed as Working on UI for DryCargo)
      if (isDry(worksheetInvariantProps.marketSegmentId) && speedAndConType.key === 'loading') {
        speedAndConType.label = 'Working';
      }

      const label: string = speedAndConType.label;
      return {
        ...speedAndCon,
        label,
        fuelGrade: getFuelGradeFor(speedAndCon.fuelGrade),
        generatorFuelGrade: getFuelGradeFor(speedAndCon.generatorFuelGrade),
      };
    };

    const speedAndConsumptions = vessel.speedAndConsumptions;
    const ballast = enrich(speedAndConsumptions[BALLAST.key]);
    const laden = enrich(speedAndConsumptions[LADEN.key]);
    const ecoBallast = enrich(speedAndConsumptions[ECO_BALLAST.key]);
    const ecoLaden = enrich(speedAndConsumptions[ECO_LADEN.key]);
    const canal = enrich(speedAndConsumptions[CANAL.key]);
    const idle = enrich(speedAndConsumptions[PORT_IDLE.key]);
    const manoeuvring = enrich(speedAndConsumptions[PORT_MANOEUVRING.key]);
    const loading = enrich(speedAndConsumptions[LOADING.key]);
    const discharging = enrich(speedAndConsumptions[DISCHARGING.key]);
    const sailing = enrich(speedAndConsumptions[SAILING.key]);
    const ecoSailing = enrich(speedAndConsumptions[ECO_SAILING.key]);

    const movingSpeedAndCons = isDry(worksheetInvariantProps.marketSegmentId)
      ? [[ballast, ecoBallast], [laden, ecoLaden], [canal]]
      : [[ballast, ecoBallast], [laden, ecoLaden], [sailing, ecoSailing], [canal]];
    const staticSpeedAndCons = isDry(worksheetInvariantProps.marketSegmentId)
      ? [loading, idle, manoeuvring]
      : [loading, discharging, idle, manoeuvring];

    const allSpeedAndCons = [
      ballast,
      ecoBallast,
      laden,
      ecoLaden,
      canal,
      sailing,
      ecoSailing,
      loading,
      discharging,
      idle,
      manoeuvring,
    ];

    return {
      worksheetId,
      worksheetInvariantProps,
      vessel: {
        id: vessel.entryId,
        vesselId: vessel.vesselId,
        vesselName: vessel.name,
        isBalticVessel: vessel.isBalticVessel || false,
        scrubber: vessel.scrubber,
        isParcelVoyage: vessel.isParcelVoyage || false,
      },
      isScrubberFitted: isScrubberFitted(vessel.scrubber && vessel.scrubber.typeId),
      movingSpeedAndCons,
      staticSpeedAndCons,
      allSpeedAndCons,
      zoneSpecific: speedAndConsumptions.zoneSpecific,
      scrubberConsumption: speedAndConsumptions.scrubberConsumption,
      isTankerIndexVessel: speedAndConsumptions.isTankerIndexVessel
    };
  }
);
