import { ActionsRenderer } from 'modules/search-panel/components/workbooks-grid/components/actions-renderer';
import { getDefaultGridOptions } from 'modules/search-panel/components/workbooks-grid/default-grid-options';

export const buildGridOptions = (
  handleRemoveWorkbookFunc,
  isWorkbookOpenFunc,
  loadMyWorkbooksFunc
) => {
  const customizedGridOptions = getDefaultGridOptions(loadMyWorkbooksFunc);
  customizedGridOptions.columnDefs.push({
    cellRenderer: ActionsRenderer,
    cellRendererParams: {
      removeWorkbook: handleRemoveWorkbookFunc,
      isWorkbookOpen: isWorkbookOpenFunc,
    },
    width: 65,
    sortable: false,
  });
  const gridOptions = {
    ...customizedGridOptions,
  };

  return gridOptions;
};
