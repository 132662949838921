import React, { useState } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { IconButton, IconButtonToolTip } from 'components/button';
import { iconEnum } from 'components/icons';
import UserPhoto from 'components/user-photo';
import './styles.scss';
import { getVesselHistoryById } from 'api/clients/vessel';
import { getSpeedAndConTypeById } from 'constants/enums/speed-and-con-types';
import { getFuelGradeByCode } from 'constants/enums/fuel-grades';

export const SpeedAndConsHistory = (props) => {
  const { className, vesselId, sandTypeId, marketSegmentId, disabled } = props;
  const classes = classNames('speed-cons-multi-vessel-audit-info', className);
  const [show, setShow] = useState(false);
  const [history, setHistory] = useState(undefined);
  const iconDisabled = disabled || !vesselId;
  const showHistory = () => {
    return show && history;
  };

  const closeWindow = () => {
    setShow(false);
    setHistory(undefined);
  };

  const scType = getSpeedAndConTypeById(sandTypeId).label;
  const historyToolTip = `View ${scType} history`;

  return (
    <div className={classes}>
      <IconButtonToolTip
        icon={iconEnum.TimeUpdate}
        diagnosticId="SpeedAndConsHistory/GetSpeedAndCons"
        disabled={iconDisabled}
        text={historyToolTip}
        onClick={async () => {
          if (!iconDisabled) {
            setShow(true);
            var response = await getSpeedAndConsHistory(vesselId, sandTypeId, marketSegmentId);
            setHistory(response);
          }
        }}
      />
      {showHistory() ? (
        <SpeedAndConsHistoryModal
          history={history}
          vesselId={vesselId}
          sandTypeId={sandTypeId}
          marketSegmentId={marketSegmentId}
          windowHandler={closeWindow}
        />
      ) : (
        ''
      )}
    </div>
  );
};

const getSpeedAndConsHistory = async (vesselId, sandTypeId, marketSegmentId) => {
  if (vesselId && sandTypeId && marketSegmentId) {
    var response = await getVesselHistoryById({
      vesselId,
      sandTypeId,
      marketSegmentId,
    });
    return response;
  }
};

const dateFormat = 'dd MMMM yyyy HH:mm';

const historyRow = (data, inx) => (
  <>
    <div className="sandc-user-img">
      <AuthorPhoto userName={data.createdByUserADName} />
    </div>
    <div className="sandc-data-row-main">
      <div className="sandc-data-row">
        <div className="sandc-data-col highlighted">{data.speed_KN} K </div>
        <div className="sandc-data-col highlighted">
          {data.mainEngineConsumption.consumption_MT} MT
        </div>
        <div className="sandc-data-col highlighted">
          {getFuelGradeByCode(data.mainEngineConsumption.fuelGrade).label}
        </div>
        <div className="sandc-data-col">Updated on {format(data.createdDate, dateFormat)}</div>
      </div>
      <div className="sandc-data-row normal">
        <div className="sandc-data-col">Generator</div>
        <div className="sandc-data-col">{data.generatorConsumption.consumption_MT} MT</div>
        <div className="sandc-data-col">
          {getFuelGradeByCode(data.generatorConsumption.fuelGrade).label}
        </div>
        {data.name && <div className="sandc-data-col">by {data.name}</div>}
        {!data.name && <div className="sandc-data-col" />}
      </div>
    </div>
  </>
);

const historyRows = (history) => {
  return (
    <>
      <div key="nscr0" className="sandc-row-container border">
        {historyRow(history[0], 'nscr_0')}
      </div>
      <div key="scr0" className="sandc-scrollable">
        {history.slice(1).map((data, inx) => (
          <div key={inx + 1} className="sandc-row-container border">
            {historyRow(data, `scr_${inx + 1}`)}
          </div>
        ))}
      </div>
    </>
  );
};

const noDataAvailable = () => {
  return (
    <div key="nscr0" className="sandc-no-data-container">
      Speed and Cons History Data Not Available
    </div>
  );
};

export const SpeedAndConsHistoryModal = ({
  history,
  vesselId,
  sandTypeId,
  marketSegmentId,
  windowHandler,
}) => {
  const scType = getSpeedAndConTypeById(sandTypeId).label;
  return (
    <div className={`sandc-audit-modal`}>
      <section className="sandc-section">
        <div className="sandc-data-container">
          <div className="sandc-header-row">
            <span className="sandc-title">
              History of the changes in {scType} speed and consumptions by user
            </span>
            <IconButton
              className={'sandc-close'}
              icon={iconEnum.Close}
              onClick={() => windowHandler()}
              diagnosticId="SpeedAndConsumptions/Close"
            />
          </div>
          {history.length ? historyRows(history) : noDataAvailable()}
        </div>
      </section>
    </div>
  );
};

const AuthorPhoto = ({ userName }) => {
  return <UserPhoto userName={userName} className="user-img" />;
};
