import React, { PureComponent, Fragment } from 'react';

import NumericInput from 'components/numeric-input';
import DropDown from 'components/dropdown';

import type { AdditionalExpense } from '../../../types/state.type';
import type {
  PreDefinedExpenseCostChanged,
  PreDefinedExpenseCostTypeChanged,
} from 'actions/additional-expenses.actions';

type Props = {
  itemIndex: number,
  preDefinedExpense: AdditionalExpense,
  preDefinedExpenseCostChanged: PreDefinedExpenseCostChanged,
  preDefinedExpenseCostTypeChanged: PreDefinedExpenseCostTypeChanged,
  costTypes: Array<any>,
  sortOrder: number,
};

export class PreDefinedExpense extends PureComponent<Props> {
  onPreDefinedExpenseCostChanged = (value) => {
    this.props.preDefinedExpenseCostChanged(value, this.props.itemIndex);
  };
  onPreDefinedExpenseCostTypeChanged = (value) => {
    this.props.preDefinedExpenseCostTypeChanged(value, this.props.itemIndex);
  };

  render() {
    return (
      <Fragment>
        <div className="pre-defined-expense-property expense-cost">
          <label className="pre-defined-expense-property__label">
            {this.props.preDefinedExpense.description}
          </label>
          <NumericInput
            className="pre-defined-expense-property__small-input"
            value={this.props.preDefinedExpense.cost}
            onInputChange={this.onPreDefinedExpenseCostChanged}
            maxDecimalDigits="2"
            minValue="-999999.99"
            maxValue="999999.99"
            diagnosticId="PreDefinedExpense/ChangeCost"
          />
        </div>
        <div className="pre-defined-expense-property pre-defined-expense-property--right-margin expense-cost-type">
          <label className="pre-defined-expense-property__label">&nbsp;</label>
          <DropDown
            items={this.props.costTypes}
            selectedItem={this.props.preDefinedExpense.costType}
            onChange={this.onPreDefinedExpenseCostTypeChanged}
            diagnosticId="PreDefinedExpense/ChangePreDefinedExpenseCostType"
          />
        </div>
      </Fragment>
    );
  }
}

export default PreDefinedExpense;
