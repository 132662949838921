import React from 'react';
import Label from 'components/label';
import { SearchIcon } from 'components/icons';

const Filter = ({ value, onChange, title }) => {
  return (
    <div className="search-filter">
      <Label>
        <div className="search-filter__label-container">{title ? title : 'Filter'}</div>
        <div className="search-filter__input">
          <SearchIcon className="search-filter__input--icon" />
          <input
            value={value}
            onChange={onChange}
            className="search-filter__input--box"
            placeholder="Enter name of the workbook"
          />
        </div>
      </Label>
    </div>
  );
};

export default Filter;
