const vesselDwtColumn = {
  key: 'vesselDwt',
  label: 'DWT',
  minWidth: 70,
  maxWidth: 70,
  rightAlign: true,
};

const cargoColumn = {
  key: 'cargo',
  label: 'Cargo',
  minWidth: 135,
  maxWidth: 300,
};

const cargoQuantityColumn = {
  key: 'cargoQuantity',
  label: 'Cargo Qty (MT)',
  minWidth: 135,
  maxWidth: 135,
  rightAlign: true,
};

const costBallastColumn = {
  key: 'costBallast',
  label: 'Ballast ($)',
  minWidth: 90,
  maxWidth: 90,
  rightAlign: true,
};

const costBallastColumnHighlighted = {
  ...costBallastColumn,
  highlight: true,
};

const costLadenColumn = {
  key: 'costLaden',
  label: 'Laden ($)',
  minWidth: 90,
  maxWidth: 90,
  rightAlign: true,
};

const costLadenColumnHighlighted = {
  ...costLadenColumn,
  highlight: true,
};

const lastUpdatedColumn = {
  key: 'lastUpdated',
  label: 'Last Updated',
  minWidth: 110,
  maxWidth: 110,
};

const CanalCostBallastColumns = [
  vesselDwtColumn,
  cargoColumn,
  cargoQuantityColumn,
  costBallastColumnHighlighted,
  costLadenColumn,
  lastUpdatedColumn,
];

const CanalCostLadenColumns = [
  vesselDwtColumn,
  cargoColumn,
  cargoQuantityColumn,
  costBallastColumn,
  costLadenColumnHighlighted,
  lastUpdatedColumn,
];

export { CanalCostBallastColumns, CanalCostLadenColumns };
