import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import storePromiseOnCall from 'utilities/functions/store-promise-on-call';
import Async from 'react-async';
import axios from 'axios';
import { NonVoidTryFunctionUtils } from 'utilities/functions/try-functions/non-void-try-function';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import remove from 'lodash/remove';
import flatten from 'lodash/flatten';
import findIndex from 'lodash/findIndex';
import numbro from 'numbro';
import { compareDesc } from 'date-fns';
import Port from './components/port';
import Waypoints from './components/waypoints';
import { TextToolTip } from './components/text-tooltip';
import CanalCosts from 'modules/canals';
import EmitNotice from 'components/notices-emitter/emit-notice';
import WarningBox from 'components/notice-box/warning-box';
import { SecondaryButton } from 'components/button';
import { IconButton } from 'components/button';
import { iconEnum, RouteIcon } from 'components/icons';
import LinkButton from 'components/link-button';
import { Header } from 'components/headers';
import Toggle from 'components/toggle';
import VerticalDivider from 'components/divider/vertical';
import { updatedDiff } from 'deep-object-diff';
import userContext from 'user-context';
import {
  BUNKER,
  isDischargePort,
  isLoadOrDischargePort,
  isLoadPort,
} from 'constants/enums/voyage-leg';
import { METERS, DRAFT_NUMBER_OF_DECIMAL_PLACES, convertDraft } from 'constants/enums/draft-units';
import { MAP_PANEL } from 'constants/enums/stage-panels';
import { getWaypoint } from 'constants/data/waypoints';
import {
  PortCostColumns,
  PortDraftColumns,
  mapPortCosts,
  mapPortDrafts,
  NO_RESTRICTION,
} from './port-data';
import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import { emptyGuid, isEmptyGuid } from 'utilities/guid';
import { Geolocation } from 'utilities/location';
import { joinStringList } from 'utilities/string';
import * as locationClient from 'api/clients/location';
import { portDaApi } from 'api';
import {
  avoidSecaZonesChanged,
  cargoQuantityChanged,
  delayChanged,
  delayUnitChanged,
  draftChanged,
  draftUnitChanged,
  gearChanged,
  loadDischargeRateChanged,
  loadDischargeRateUnitChanged,
  locationAdded,
  locationChanged,
  locationDeleted,
  portCostChanged,
  isInSecaChanged,
  isInEeaChanged,
  salinityChanged,
  secaDistanceChanged,
  totalDistanceChanged,
  turnTimeChanged,
  turnTimeUnitChanged,
  typeChanged,
  setPortsInSeca,
  voyageLegMoved,
  locationsToAvoidChanged,
  weatherFactorChanged,
  workingDayMultiplierChanged,
  removeWaypointsAndCalculateLegsInVoyage,
  toggleAutoIntakeCalc,
  setCalculationStatus,
  voyageLegsLoaded,
} from 'actions/worksheet/voyage';
import { loadBunkerPrice } from 'actions/worksheet/bunkers';
import { setStageLeftAs } from 'actions/stage';
import { selector } from './selectors';
import './styles.scss';
import {
  operationalRestrictionIds,
  OperationalRestrictionId,
} from 'constants/operational-restrictions';
import { secaZones } from 'constants/data/waypoints';
import { isOpenLoopOrUnknownScrubberFitted } from 'constants/enums/scrubber';
import {
  getAllUserSignificantWaypointsWithOccurrencesInLegs,
  getWaypointsToExcludeForNewRoutes,
} from './business-model/waypoints';
import { getAllLegsWithFromToInfo } from './business-model/voyage-locations-sequence';
import type {
  VoyageEntryLocationsWithInboundRoutesInfo,
  VoyageEntryLocationsInfoWithDetails,
} from './business-model/voyage-locations-sequence';
import { applyVoyageLocationsSequenceChangesToRoutes } from '../../actions/worksheet/voyage/routes';
import {
  openPositionUpdated,
  openPositionPrevPortOfCallIsInEeaUpdated,
  nextPortOfCallIsInEeaUpdated,
} from '../../actions/worksheet/vessel';
import {
  mainFuelGradeChanged,
  zoneSpecificSecaFuelGradeChanged,
  speedChanged,
  consumptionChanged,
  consumptionOverrideQtyChanged,
} from 'actions/worksheet/speed-and-consumptions';
import {
  wrapFnToRunInQueueForRouteCalculation,
  runInQueueForRouteCalculation,
} from '../../actions/worksheet/voyage/routes/queue-for-async-changes';
import {
  isValidSailingGeolocationsPair,
  voyageTypeChangedUpdateDelayAllLegs,
} from '../../actions/worksheet/voyage';
import { tryCalculateInboundRouteVariantViewModelFromTo } from './business-model/routes-calculation';
import { getVoyageRouteDifferences } from 'modules/voyage/get-voyage-route-differences';
import { marketSegmentIds, isDry, isTanker } from 'constants/market-segments';
import {
  ROUTING_API_WARNING_NOTICE,
  UPDATE_VOYAGE_DUE_TO_DEVIATION,
  DISMISS_NOTICE_BY_ID,
} from 'constants/enums/emit-notices';
import { DAYS } from './load-discharge-rate-unit-types';
import classNames from 'classnames';
import { isFeatureEnabled } from 'config/feature-control';
import RouteGraph from './components/route-graph';
import VoyageGraph from './components/voyage-graph';
import SpeedAndConsSummaryInVoyage from './components/speed-and-cons-summary-in-voyage';
import DragAndDropItem from '../../components/drag-and-drop/drag-and-drop-item';
import { getPortsTotalLoadAndDischarge } from './helpers';
import { trackChangeEvent } from '../../diagnostics/calc-trackevents';

export type VoyageEntryLocationsInfoWithDetailsWithValidToInfo =
  VoyageEntryLocationsInfoWithDetails & {
    fromToInfo: {
      legWithToLocation: {
        // this is replacing the nullable property type with a non-nullable one:
        toLocationInfo: LocationBasicInfo,
      },
    },
  };

export interface IVoyageDeviationViewModel {
  deviationType: string;
  names: string[];
}

export type LocationRoutingIdentity = {
  geoCoords: Geolocation,
};

export type FromToLocationsPair = {
  fromLocation: LocationRoutingIdentity,
  toLocation: LocationRoutingIdentity,
};

type RouteCalculationInput = {
  fromToLocations: FromToLocationsPair,
  avoidSecaZones: boolean,
  waypointsToExclude: Array<IWaypointIdentity>,
};

type RouteResultWithInput = RouteCalculationInput & {
  routeResult: InboundRouteVariantViewModel,
};

export type FromToLocationsPairWithApplicableVessels = FromToLocationsPair & {
  vessels: Set<IVesselViewModel>,
};

export class Voyage extends Component {
  cancelTokenSource = null;
  portDataCache = {};
  PORT_DATA_CACHE_DRAFT = 'draft';
  PORT_DATA_CACHE_COST = 'costs';
  routeGraphApiUpdateNotificationRequired = false;
  newSecaWayPoints = [];
  routeNoticeId = `routeNotice-voyage-1`;
  imoNoticeId = `imoNotice-voyage-1`;
  graphApiNoticeStatus = undefined;
  currentDisplayNoticeId = undefined;
  currentDisplayNoticeType = undefined;
  showNextPortOfCall = false;

  state: {
    // undefined means that the latest routing information is pending
    voyageUpdateInfo?: {
      latestRoutesForLegs: Array<RouteResultWithInput>,
      latestLocationInfo: {
        [locationId: string]: {
          operationalRestrictions: { [OperationalRestrictionId]: boolean },
        },
      },
    },
  } = {};

  getLegById = (voyageLegId: string) => {
    return find(this.props.voyage.legs, ['id', voyageLegId]);
  };
  getLegIndexById = (legId: string) => {
    return findIndex(this.props.voyage.legs, { id: legId });
  };

  handleDragEnd = (dragInfo): void => {
    const handledLeg = this.getLegById(dragInfo.draggableId);

    trackChangeEvent('voyage/leg-order-changed', {
      prevLegIndex: dragInfo.source?.index,
      legType: handledLeg?.type.label,
      cargoQty: handledLeg?.cargoQuantity,
      legIndex: dragInfo.destination?.index,
      worksheetId: this.props.worksheetId,
      numberOfLegsInVoyage: this.props.voyage.legs.length,
    });

    if (!dragInfo.destination || dragInfo.destination.index === dragInfo.source.index) {
      return;
    }

    this.props.voyageLegMoved({
      sourceIndex: dragInfo.source.index,
      destinationIndex: dragInfo.destination.index,
      worksheetId: this.props.worksheetId,
    });

    // if leg moved and last leg is load/discharge then next port of call should be false
    if (isFeatureEnabled('newVoyageTable')) {
      let legsClone = [...this.props.voyage.legs];
      let leg = legsClone[dragInfo.source.index];
      legsClone.splice(dragInfo.source.index, 1);
      legsClone.splice(dragInfo.destination.index, 0, leg);

      var lastLeg = legsClone[legsClone.length - 1];
      if (isLoadOrDischargePort(lastLeg.type)) {
        this.props.nextPortOfCallIsInEeaUpdated(
          false,
          this.props.worksheetId,
          this.props.activeVessel.entryId
        );
      }
    }
  };

  handleTypeChanged = (voyageLegId: string, value, marketSegmentId: marketSegmentIds) => {
    const handledLeg = this.getLegById(voyageLegId);
    const cargoId = this.props.cargoCodes[0].key;
    const handledLegIndex = this.getLegIndexById(voyageLegId);

    this.props.typeChanged(
      value,
      voyageLegId,
      handledLeg?.locationId,
      cargoId,
      this.props.worksheetId,
      marketSegmentId,
      this.props.activeVessel.speedAndConsumptions
    );

    trackChangeEvent('voyage/leg-type-changed', {
      prevLegType: handledLeg?.type.label,
      legType: value.label,
      cargoQty: null,
      legIndex: handledLegIndex,
      worksheetId: this.props.worksheetId,
      numberOfLegsInVoyage: this.props.voyage.legs.length,
    });

    if (value === BUNKER) {
      const voyageLeg = this.getLegById(voyageLegId);
      const portName = voyageLeg && voyageLeg.name;

      if (isNil(portName) === false) {
        const payload = { voyageLegId, portName, isTanker: isTanker(marketSegmentId) };
        this.props.loadBunkerPrice(payload, this.props.worksheetId);
      }
    }

    if (
      isFeatureEnabled('wetCargoLiteMultiLeg') &&
      isTanker(this.props.worksheetInvariantProps.marketSegmentId)
    ) {
      this.props.voyageTypeChangedUpdateDelayAllLegs(true, this.props.worksheetId);
    }

    var legs = [...this.props.voyage.legs];

    var lastLeg = legs.pop();

    if (lastLeg.id !== voyageLegId || !isLoadOrDischargePort(value)) return;

    if (isDischargePort(value)) {
      const { totalLoad, totalDischarge } = getPortsTotalLoadAndDischarge(legs);

      this.props.cargoQuantityChanged(
        Math.max(totalLoad - totalDischarge, 0),
        voyageLegId,
        this.props.worksheetId
      );
    }

    // if last leg changed to load/discharge then next port of call should be false
    if (isFeatureEnabled('newVoyageTable')) {
      this.props.nextPortOfCallIsInEeaUpdated(
        false,
        this.props.worksheetId,
        this.props.activeVessel.entryId
      );
    }
  };

  handleOpenPositionUpdated = (newPosition) => {
    this.props.openPositionUpdated(
      newPosition,
      this.props.worksheetId,
      this.props.activeVessel.entryId
    );
  };

  handleOpenPositionPrevPortOfCallIsInEeaUpdated = (value) => {
    this.props.openPositionPrevPortOfCallIsInEeaUpdated(
      !value,
      this.props.worksheetId,
      this.props.activeVessel.entryId
    );
  };

  handleNextPortOfCallIsInEeaUpdated = (value) => {
    this.props.nextPortOfCallIsInEeaUpdated(
      !value,
      this.props.worksheetId,
      this.props.activeVessel.entryId
    );
  };

  handleLocationChanged = async (voyageLegId, newLocationInfo) => {
    let marketSegmentId = this.props.worksheetInvariantProps.marketSegmentId;
    let isInSeca = await this.isPortInSeca(
      voyageLegId,
      newLocationInfo.locationId,
      marketSegmentId
    );
    const voyageLeg = this.getLegById(voyageLegId);
    await this.props.locationChanged(
      {
        portInSequenceBeingChanged: {
          ...newLocationInfo,
          isInSeca,
        },
        waypointsToExclude: this.waypointsToExcludeForNewRoutes,
        type: voyageLeg.type,
        avoidSecaZones: this.props.voyage.avoidSecaZones,
      },
      voyageLegId,
      this.props.worksheetId
    );

    if (voyageLeg.type.key === BUNKER.key) {
      if (isNil(newLocationInfo.locationName) === false) {
        this.props.loadBunkerPrice(
          {
            voyageLegId,
            portName: newLocationInfo.locationName,
            isTanker: isTanker(marketSegmentId),
          },
          this.props.worksheetId
        );
      }
    }
  };

  handleMainFuelTypeChanged = (newFuelType) => {
    this.props.mainFuelGradeChanged(
      {
        key: newFuelType.key,
        isMainEngine: true,
      },
      this.props.worksheetId,
      this.props.activeVessel.entryId
    );
  };

  handleSecaFuelTypeChanged = (newFuelType) => {
    this.props.zoneSpecificSecaFuelGradeChanged(
      newFuelType.key,
      this.props.worksheetId,
      this.props.activeVessel.entryId
    );
  };

  handleGearChanged = (portId, value) => {
    this.props.gearChanged(value, portId, this.props.worksheetId);
  };

  handleTotalDistanceChanged = (portId, value, inboundRouteVariant) => {
    this.props.totalDistanceChanged({
      newValue: value,
      worksheetId: this.props.worksheetId,
      voyageEntryId: portId,
      inboundRouteVariant: inboundRouteVariant,
    });
  };

  handleSecaDistanceChanged = (portId, value, inboundRouteVariant) => {
    this.props.secaDistanceChanged({
      newValue: value,
      worksheetId: this.props.worksheetId,
      voyageEntryId: portId,
      inboundRouteVariant: inboundRouteVariant,
    });
  };

  handlePortDaysChanged = (voyageLeg, newValue) => {
    this.props.loadDischargeRateChanged(newValue, voyageLeg.id, this.props.worksheetId);
    if (voyageLeg.rateUnit?.key !== DAYS.key) {
      this.props.loadDischargeRateUnitChanged(DAYS, voyageLeg.id, this.props.worksheetId);
    }
  };

  handleDraftChanged = (portId, value) => {
    if (value === null || value === undefined) {
      return;
    }

    let draft = 0;

    if (typeof value === 'object') {
      switch (value.maxDraft) {
        case NO_RESTRICTION:
          draft = convertDraft(100, METERS, this.props.voyage.draftUnit);
          break;
        case '':
          draft = 0;
          break;
        default:
          draft = convertDraft(
            numbro.unformat(value.maxDraft),
            METERS,
            this.props.voyage.draftUnit
          );
      }
    } else {
      draft = value;
    }

    this.props.draftChanged(draft, portId, this.props.worksheetId);
  };

  handleDraftUnitChanged = (newDraftUnit) => {
    this.props.draftUnitChanged(
      /* newDraftByLegId: */ Object.fromEntries(
        this.props.voyage.legs.map((leg) => [
          leg.id,
          convertDraft(leg.draft, this.props.voyage.draftUnit, newDraftUnit),
        ])
      ),
      newDraftUnit,
      this.props.worksheetId
    );
  };

  handleSalinityChanged = (portId, value) => {
    this.props.salinityChanged(value, portId, this.props.worksheetId);
  };

  handleCargoQuantityChanged = (portId, value) => {
    this.props.cargoQuantityChanged(value, portId, this.props.worksheetId);

    const handledLeg = this.getLegById(portId);
    const handledLegIndex = this.getLegIndexById(portId);

    trackChangeEvent('voyage/leg-cargo-quantity-changed', {
      prevCargoQty: handledLeg?.cargoQuantity,
      cargoQty: value,
      legIndex: handledLegIndex,
      legType: handledLeg?.type.label,
      worksheetId: this.props.worksheetId,
      numberOfLegsInVoyage: this.props.voyage.legs.length,
    });

    if (!isLoadPort(handledLeg?.type)) return;

    const dischargeLegs = this.props.voyage.legs.filter((leg) => isDischargePort(leg.type));
    const loadLegs = this.props.voyage.legs.filter((leg) => isLoadPort(leg.type));

    if (dischargeLegs?.length !== 1 || loadLegs?.length !== 1) return;

    const dischargeLeg = dischargeLegs[dischargeLegs.length - 1];

    if (dischargeLeg.cargoQuantity !== handledLeg?.cargoQuantity) return;

    this.props.cargoQuantityChanged(value, dischargeLeg.id, this.props.worksheetId);
  };

  handleLoadDischargeRateChanged = (portId, value) => {
    this.props.loadDischargeRateChanged(value, portId, this.props.worksheetId);
  };

  handleLoadDischargeRateUnitChanged = (portId, value) => {
    this.props.loadDischargeRateUnitChanged(value, portId, this.props.worksheetId);
  };

  handleWorkingDayMultiplierChanged = (portId, value) => {
    this.props.workingDayMultiplierChanged(value, portId, this.props.worksheetId);
  };

  handleDelayChanged = (portId, value) => {
    this.props.delayChanged(value, portId, this.props.worksheetId);
  };

  handleDelayUnitChanged = (portId, value) => {
    this.props.delayUnitChanged(value, portId, this.props.worksheetId);
  };

  handleTurnTimeChanged = (portId, value) => {
    this.props.turnTimeChanged(value, portId, this.props.worksheetId);
  };

  handleTurnTimeUnitChanged = (portId, value) => {
    this.props.turnTimeUnitChanged(value, portId, this.props.worksheetId);
  };

  handleWeatherFactorChanged = (portId, value) => {
    this.props.weatherFactorChanged(value, portId, this.props.worksheetId);
  };

  handlePortCostChanged = (portId, value) => {
    if (value === null || value === undefined) {
      return;
    }

    this.props.portCostChanged(
      value.cost ? numbro.unformat(value.cost) : value,
      portId,
      this.props.worksheetId
    );
  };

  handlePortDraftDataLoad = (locationId) => {
    return this.handlePortDataLoad(locationId, this.PORT_DATA_CACHE_DRAFT, this.props.worksheetId);
  };

  handlePortCostDataLoad = (locationId) => {
    return this.handlePortDataLoad(locationId, this.PORT_DATA_CACHE_COST, this.props.worksheetId);
  };

  handleEeaStatusChange = (portId, value) => {
    this.props.isInEeaChanged(!value, portId, this.props.worksheetId);
  };

  handlePortDataLoad = async (locationId, portDataType) => {
    locationId = locationId || emptyGuid;

    if (this.portDataCache[locationId]) {
      return this.portDataCache[locationId][portDataType];
    }

    try {
      const response = await portDaApi.get(
        `/api/GetPortsByLocationIdFunction?locationId=${locationId}`
      );
      const data = response.data
        .sort((portA, portB) => compareDesc(portA.sourceUpdatedDate, portB.sourceUpdatedDate))
        .map((port) => {
          //We are still sending unformatted source names (i.e. GPortsDry)
          //However, in the next iteration we will format it at the API level.
          const formattedSourceName = port.sourceName === 'GPortsDry' ? 'GPorts' : port.sourceName;

          return { ...port, sourceName: formattedSourceName };
        });

      let cacheEntry = {};
      cacheEntry[this.PORT_DATA_CACHE_DRAFT] = mapPortDrafts(data);
      cacheEntry[this.PORT_DATA_CACHE_COST] = mapPortCosts(data);
      this.portDataCache[locationId] = cacheEntry;

      return this.portDataCache[locationId][portDataType];
    } catch (err) {
      unhandledExceptionSinkSend(err);
      return [];
    }
  };

  appendNewVoyageEntry = () => {
    const cargoId = this.props.cargoCodes[0].key;
    const voyageLegs = this.props.voyage.legs;
    this.props.locationAdded(cargoId, this.props.worksheetId);
    if (
      isFeatureEnabled('wetCargoLiteMultiLeg') &&
      isTanker(this.props.worksheetInvariantProps.marketSegmentId)
    ) {
      this.props.voyageTypeChangedUpdateDelayAllLegs(true, this.props.worksheetId);
    }

    trackChangeEvent('voyage/leg-added', {
      legType: 'Load',
      cargoQty: 0,
      legIndex: voyageLegs.length,
      worksheetId: this.props.worksheetId,
      numberOfLegsInVoyage: voyageLegs.length + 1,
    });
  };

  handleLocationDeleted = async (voyageEntryId: VoyageEntryId) => {
    const allVoyageEntriesLocationsInfo = this.allVoyageEntriesLocationsInfo;
    if (allVoyageEntriesLocationsInfo.length === 1) {
      // #ClearingLastVoyageRowInsteadOfAllowingZeroVoyageEntries - Add a new entry before removing the last one. This is for simplicity to the user (there's no use for a worksheet without any entries, so the user would need to append anyways - we save him a click this way) as well as to the code (it never has to deal with a situation of no voyage entries)
      this.appendNewVoyageEntry();
    }
    const voyageLegs = this.props.voyage.legs;
    const handledLeg = this.getLegById(voyageEntryId);
    const handledLegIndex = this.getLegIndexById(voyageEntryId);
    const updatedNumberOfLegsInVoyage = voyageLegs.length === 1 ? 1 : voyageLegs.length - 1;

    await this.props.locationDeleted(voyageEntryId, this.props.worksheetId);

    trackChangeEvent('voyage/leg-removed', {
      legType: handledLeg?.type.label,
      cargoQty: handledLeg?.cargoQuantity,
      legIndex: handledLegIndex,
      worksheetId: this.props.worksheetId,
      numberOfLegsInVoyage: updatedNumberOfLegsInVoyage,
    });

    if (
      isFeatureEnabled('wetCargoLiteMultiLeg') &&
      isTanker(this.props.worksheetInvariantProps.marketSegmentId)
    ) {
      this.props.voyageTypeChangedUpdateDelayAllLegs(true, this.props.worksheetId);
    }
  };

  /*
   Calling through `wrapFnToRunInQueueForRouteCalculation` as part of #RouteRecalculationsRaceConditionPrevention - only start 'one route calculation at a time' per worksheet so any earlier waypoint-to-avoid changes have settled their avoidance results already.
  */
  handleWaypointAvoidChanged = wrapFnToRunInQueueForRouteCalculation(
    this.props.worksheetId,
    async (changedWaypointLocationId, newShouldAvoid) => {
      /* #TODOSupport_WaypointsAvoidedInOnlySomeLegs - Add support for #WaypointsAvoidedInOnlySomeLegs. To do that `newWaypointsToExclude` should be calculated based on that leg's waypoints, not waypoints from all legs. The shortcoming was reported in [ticket #50219](https://dev.azure.com/clarksonscloud/Voyage-Estimation/_workitems/edit/50219)
       #TODOSupport_WaypointsAvoidedInOnlySomeLegs_ConsequencesOfNoSupport - Otherwise not only we lose the avoidance of the waypoint for that leg, but also the user's preference (while we didn't action #TODORefactorAvoidedWaypointsOutOfLegs) on a mere refresh of a route (caused by things like: a simple 'Update to latest route data', change to 'Minimise SECA zones' or trying to newly avoid a waypoint which was sailed through previously.)
       */
      let newWaypointsToExclude = [...this.waypointsToExcludeForNewRoutes];
      updateWaypointsToExclude(newWaypointsToExclude, changedWaypointLocationId, newShouldAvoid);

      await this.props.locationsToAvoidChanged({
        allExcludeWaypointsToAttempt: newWaypointsToExclude,
        waypointToToggleAvoidFlagOn: {
          locationId: changedWaypointLocationId,
          avoid: !newShouldAvoid,
        },
        avoidSecaZones: this.props.voyage.avoidSecaZones,
        marketSegmentId: this.props.worksheetInvariantProps.marketSegmentId,
        worksheetId: this.props.worksheetId,
      });

      function updateWaypointsToExclude(
        waypointsToExclude: Array<IWaypointIdentity>,
        changedWaypointLocationId: LocationId,
        newShouldAvoid: Boolean
      ) {
        if (
          newShouldAvoid &&
          !waypointsToExclude.map((_) => _.locationId).includes(changedWaypointLocationId)
        ) {
          waypointsToExclude.push({
            locationId: changedWaypointLocationId,
          });
        } else if (
          !newShouldAvoid &&
          waypointsToExclude.map((_) => _.locationId).includes(changedWaypointLocationId)
        ) {
          remove(
            waypointsToExclude,
            (waypoint) => waypoint.locationId === changedWaypointLocationId
          );
        }
      }
    }
  );

  /*
   Calling through `runInQueueForRouteCalculation` as part of #RouteRecalculationsRaceConditionPrevention - only start 'one route calculation at a time' per worksheet so any earlier recalculations have inserted the routes already.
  */
  handleAvoidSecaZonesChanged = wrapFnToRunInQueueForRouteCalculation(
    this.props.worksheetId,
    async (newShouldAvoidSeca) => {
      await this.props.avoidSecaZonesChanged({
        /* #TODOSupport_WaypointsAvoidedInOnlySomeLegs. To support it here, we need to use `waypointsToExclude` only for the leg, not the ones for new routes (the latter comes from all the legs), to not lose the avoidance (all consequences in #TODOSupport_WaypointsAvoidedInOnlySomeLegs_ConsequencesOfNoSupport).*/
        waypointsToExclude: this.waypointsToExcludeForNewRoutes,
        newShouldAvoidSeca: newShouldAvoidSeca,
        worksheetId: this.props.worksheetId,
        marketSegmentId: this.props.worksheetInvariantProps.marketSegmentId,
      });
    }
  );

  openMap = () => this.props.setStageLeftAs(MAP_PANEL);

  isPortInSeca = async (portId, locationId, marketSegmentId, cancelToken) =>
    await locationClient.isPortInSeca(locationId, marketSegmentId, cancelToken);

  renderVoyageLeg = (
    port,
    index,
    cargoCodes,
    accumulatedCargoToThisPort,
    totalLoadCargo,
    totalDischargeCargo,
    lastLoadOrDischargePortIndex,
    calculationStatus
  ) => {
    const { voyage } = this.props;

    return (
      <DragAndDropItem
        key={port.id}
        draggableId={port.id}
        index={index}
        className={classNames('voyage-data__row__drag', {
          'new-voyage-table__row': isFeatureEnabled('newVoyageTable'),
        })}
      >
        <Port
          voyageEntry={port}
          draftUnit={voyage.draftUnit}
          cargoCodes={cargoCodes}
          onTypeChange={this.handleTypeChanged}
          onLocationChange={this.handleLocationChanged}
          onGearChange={this.handleGearChanged}
          totalLoadCargo={totalLoadCargo}
          totalDischargeCargo={totalDischargeCargo}
          isLastLoadOrDischargePort={index === lastLoadOrDischargePortIndex}
          onTotalDistanceChange={this.handleTotalDistanceChanged}
          onSecaDistanceChange={this.handleSecaDistanceChanged}
          draftColumns={PortDraftColumns}
          accumulatedQuantity={accumulatedCargoToThisPort}
          onDraftChange={this.handleDraftChanged}
          onDraftDataLoad={this.handlePortDraftDataLoad}
          draftNumberOfDecimalPlaces={DRAFT_NUMBER_OF_DECIMAL_PLACES}
          onSalinityChange={this.handleSalinityChanged}
          onCargoQuantityChange={this.handleCargoQuantityChanged}
          onLoadDischargeRateChange={this.handleLoadDischargeRateChanged}
          onLoadDischargeRateUnitChange={this.handleLoadDischargeRateUnitChanged}
          onWorkingDayMultiplierChange={this.handleWorkingDayMultiplierChanged}
          onDelayChange={this.handleDelayChanged}
          onDelayUnitChange={this.handleDelayUnitChanged}
          onTurnTimeChange={this.handleTurnTimeChanged}
          onTurnTimeUnitChange={this.handleTurnTimeUnitChanged}
          onWeatherFactorChange={this.handleWeatherFactorChanged}
          portCostColumns={
            userContext.userInfo.isInHouseCompany
              ? PortCostColumns
              : PortCostColumns.filter((_) => !_.isForInHouseUserOnly)
          }
          onPortCostChange={this.handlePortCostChanged}
          onPortCostDataLoad={this.handlePortCostDataLoad}
          onLocationDelete={this.handleLocationDeleted}
          shouldAutoCalculateIntake={voyage.shouldAutoCalculateIntake}
          calculationStatus={calculationStatus}
          onIsInEeaChange={this.handleEeaStatusChange}
        />
      </DragAndDropItem>
    );
  };

  get portLegs() {
    return this.allLegsWithValidToLocation
      .filter((_) => isLoadOrDischargePort(_.voyageEntryDetails.type))
      .map((_) => _.voyageEntryDetails);
  }

  updateAllSecaPorts = async () => {
    const latestLocationSecaInfos = Object.entries(this.state.voyageUpdateInfo.latestLocationInfo)
      .map(([locationId, latestLocationInfo]) => ({
        locationId,
        latestLocationInfo: latestLocationInfo,
      }))
      .map((_) => ({
        locationId: _.locationId,
        isInSeca: _.latestLocationInfo.operationalRestrictions.seca,
      }));

    this.props.setPortsInSeca(
      latestLocationSecaInfos.filter((_) => _.isInSeca).map((_) => _.locationId),
      /*inSeca: */ true,
      this.props.worksheetId
    );
    this.props.setPortsInSeca(
      latestLocationSecaInfos.filter((_) => _.isInSeca === false).map((_) => _.locationId),
      /*inSeca: */ false,
      this.props.worksheetId
    );
  };

  async getLatestLocationInfo(cancelToken): {
    [locationId: string]: {
      operationalRestrictions: { [OperationalRestrictionId]: boolean },
    },
  } {
    const locationInSecaInfo = {};
    for (const leg of this.portLegs) {
      const marketSegmentId = this.props.worksheetInvariantProps.marketSegmentId;

      locationInSecaInfo[leg.locationId] = {
        operationalRestrictions: {
          [operationalRestrictionIds.seca]: await this.isPortInSeca(
            leg.id,
            leg.locationId,
            marketSegmentId,
            cancelToken
          ),
        },
      };
    }
    return locationInSecaInfo;
  }

  /**
     NOTE: In contrast to `allValidSailingLegsWithVoyageEntry` and `allLocationPairsWithValidSailing`, this collection skips any legs which currently don't have a `routeVariant` in the worksheet.
     A route variant might not exist because it might be in the process of being calculated or there was error calculating it.
   */
  get allValidSailingLegsWithVoyageEntryAndRouteVariant(): Array<{
    fromToLocations: FromToLocationsPairWithApplicableVessels,
    voyageEntryDetails: IVoyageLegViewModel,
    routeVariant: InboundRouteVariantViewModel,
  }> {
    return this.allValidSailingLegsWithVoyageEntry
      .map((_) => ({
        ..._,
        routeVariant: _.voyageEntryDetails.inboundRoute.variants.find((rv) =>
          Geolocation.areEqualNullSafe(
            rv.fromLocationGeoCoords,
            _.fromToLocations.fromLocation.geoCoords
          )
        ),
      }))
      .filter((_) => _.routeVariant !== undefined);
  }

  get allLocationPairsWithValidSailing(): Array<FromToLocationsPairWithApplicableVessels> {
    return this.allValidSailingLegsWithVoyageEntry.map((_) => _.fromToLocations);
  }

  get allValidSailingLegsWithVoyageEntry(): Array<{
    fromToLocations: FromToLocationsPairWithApplicableVessels,
    voyageEntryDetails: IVoyageLegViewModel,
  }> {
    return this.allLegsWithValidToLocation.flatMap((voyageEntryWithFromToInfo) =>
      voyageEntryWithFromToInfo.fromToInfo.fromLocations
        .map((fromLocation) => ({
          fromToLocations: {
            fromLocation: fromLocation.locationInfo,
            toLocation: voyageEntryWithFromToInfo.fromToInfo.legWithToLocation.toLocationInfo,
            vessels: fromLocation.vessels,
            id: voyageEntryWithFromToInfo.voyageEntryDetails.id,
          },
          voyageEntryDetails: voyageEntryWithFromToInfo.voyageEntryDetails,
        }))
        .filter(
          (_) =>
            _.fromToLocations.fromLocation != null &&
            _.fromToLocations.toLocation != null &&
            isValidSailingGeolocationsPair(
              _.fromToLocations.fromLocation.geoCoords,
              _.fromToLocations.toLocation.geoCoords
            )
        )
    );
  }

  get allLegsWithValidToLocation(): Array<VoyageEntryLocationsInfoWithDetailsWithValidToInfo> {
    return this.allLegsWithFromToInfo.filter((_) => !isEmptyGuid(_.voyageEntryDetails.locationId));
  }

  get allVoyageEntriesLocationsInfo(): Array<VoyageEntryLocationsWithInboundRoutesInfo> {
    return this.allLegsWithFromToInfo.map((_) => _.fromToInfo);
  }

  get allLegsWithFromToInfo(): Array<VoyageEntryLocationsInfoWithDetails> {
    return Voyage.getAllLegsWithFromToInfoFromProps(this.props);
  }

  async getLatestRoutesForLegs(cancelToken: CancelToken): Array<RouteResultWithInput> {
    const avoidSecaZones = this.props.voyage.avoidSecaZones;
    const waypointsToExclude = this.waypointsToExcludeForNewRoutes;
    const marketSegmentId = this.props.worksheetInvariantProps.marketSegmentId;
    const latestRoutesForLegs = await Promise.all(
      this.allLocationPairsWithValidSailing.map(async (locationsPair) => ({
        fromToLocations: locationsPair,
        avoidSecaZones: avoidSecaZones,
        waypointsToExclude: waypointsToExclude,
        routeResult: await tryCalculateInboundRouteVariantViewModelFromTo(
          locationsPair.fromLocation.geoCoords,
          locationsPair.toLocation.geoCoords,
          /*options:*/ {
            /* #TODOSupport_WaypointsAvoidedInOnlySomeLegs. To support it here, we need to use `waypointsToExclude` only for the leg, not the ones for new routes (the latter comes from all the legs), to not lose the avoidance (all consequences in #TODOSupport_WaypointsAvoidedInOnlySomeLegs_ConsequencesOfNoSupport).*/
            waypointsToExclude: waypointsToExclude,
            avoidSecaZones: avoidSecaZones,
            marketSegmentId: marketSegmentId,
          },
          cancelToken
        ),
      }))
    );

    return latestRoutesForLegs.filter(
      // filter 'no route info' results, ignore these legs until they're fixed by the user
      (_) => !NonVoidTryFunctionUtils.isUnsuccessfulResult(_.routeResult)
    );
  }

  /*
   Calling through `runInQueueForRouteCalculation` as part of #RouteRecalculationsRaceConditionPrevention - don't race with any other recalculation (if their responses come after this call they would overwrite this newer decision and the user would lose his data changes).
  */
  updateVoyage = wrapFnToRunInQueueForRouteCalculation(this.props.worksheetId, async () => {
    this.updateVoyageLegsRouteversion();
    await this.updateAllSecaPorts();

    await this.props.removeWaypointsAndCalculateLegsInVoyage({
      shouldAvoidSeca: this.props.voyage.avoidSecaZones,
      marketSegmentId: this.props.worksheetInvariantProps.marketSegmentId,
      worksheetId: this.props.worksheetId,
    });
  });

  updateVoyageLegsRouteversion = () => {
    this.props.voyage.legs.forEach((leg) => {
      if (
        leg &&
        leg.inboundRoute &&
        leg.inboundRoute.variants &&
        leg.inboundRoute.variants.length > 0
      ) {
        leg.inboundRoute.variants.forEach((v) => {
          v.routeRetrievedWithGraphVersion = this.props.routeGraphVersion;
          v.routeRetrievedFromRoutingApiOn = new Date();
        });
      }
    });
  };

  getVoyageDeviations(): Array<IVoyageDeviationViewModel> {
    this.updateNewSecaZoneList();
    const voyageUpdateInfo = this.state.voyageUpdateInfo;
    if (!voyageUpdateInfo) {
      return [];
    }

    const { voyage } = this.props;
    /* #TODOSupport_WaypointsAvoidedInOnlySomeLegs. To support it here, we need to use `waypointsToExclude` only for the leg, not the ones for new routes (the latter comes from all the legs), to not lose the avoidance (all consequences in #TODOSupport_WaypointsAvoidedInOnlySomeLegs_ConsequencesOfNoSupport).*/
    const waypointsToExclude = this.waypointsToExcludeForNewRoutes;

    const voyageDeviationDifferences = getVoyageRouteDifferences(
      {
        avoidSecaZones: voyage.avoidSecaZones,
        waypointsToExclude: waypointsToExclude,
        sailingLegs: this.allValidSailingLegsWithVoyageEntryAndRouteVariant,
      },
      voyageUpdateInfo
    );

    const deviations = [];
    const removedWaypointsForVoyage = new Set(
      flatten(
        Object.values(voyageDeviationDifferences.deleted).map(
          (_) => Object.keys(_.waypointLocationIds),
          []
        )
      )
    );

    const obsoleteWaypointsForVoyage = this.allUserSignificantWaypointsWithOccurrencesInLegs.filter(
      (waypoint) =>
        waypoint.waypointData.isObsolete || removedWaypointsForVoyage.has(waypoint.locationId)
    );
    if (obsoleteWaypointsForVoyage.length) {
      deviations.push({
        deviationType: 'Obsolete waypoints',
        names: obsoleteWaypointsForVoyage.map((waypoint) => waypoint.waypointData.name),
        values: new Set(obsoleteWaypointsForVoyage.map((waypoint) => waypoint.locationId)),
      });
    }

    const newWaypointsForVoyage = Array.from(
      new Set(
        flatten(
          Object.values(voyageDeviationDifferences.added).map(
            (_) => Object.keys(_.waypointLocationIds),
            []
          )
        )
      )
    ).map((waypointLocationId) => getWaypoint(waypointLocationId));

    if (newWaypointsForVoyage.length || this.newSecaWayPoints.length) {
      const waypointNames = newWaypointsForVoyage.map((wp) => wp.name);
      const allWaypointNames = waypointNames.concat(this.newSecaWayPoints);
      deviations.push({
        deviationType: 'New waypoints',
        names: allWaypointNames,
      });
    }

    const existingLocationInfo = Object.fromEntries(
      voyage.legs.map((leg) => [
        leg.locationId,
        {
          operationalRestrictions: {
            seca: doesLegPortHaveOperationalRestriction(leg, operationalRestrictionIds.seca),
          },
        },
      ])
    );

    const newPropValuesOfChangedLocationsByLocationId = updatedDiff(
      existingLocationInfo,
      voyageUpdateInfo.latestLocationInfo
    );

    const locationIdsNewlyInSeca = Object.entries(newPropValuesOfChangedLocationsByLocationId)
      .filter(([_, locationInfo]) => locationInfo.operationalRestrictions.seca)
      .map(([locationId, locationInfo]) => locationId);

    if (locationIdsNewlyInSeca.length) {
      deviations.push({
        deviationType: 'New SECA Ports',
        names: locationIdsNewlyInSeca.map(
          (locationId) => voyage.legs.find((leg) => leg.locationId === locationId).name
        ),
      });
    }

    const locationIdsNoLongerInSeca = Object.entries(newPropValuesOfChangedLocationsByLocationId)
      .filter(([_, locationInfo]) => !locationInfo.operationalRestrictions.seca)
      .map(([locationId, locationInfo]) => locationId);

    if (locationIdsNoLongerInSeca.length) {
      deviations.push({
        deviationType: 'Ports no longer in SECA',
        names: locationIdsNoLongerInSeca.map(
          (locationId) => voyage.legs.find((leg) => leg.locationId === locationId).name
        ),
      });
    }

    const hasScrubberOpenLoopType = this.props.vessels.some((_) =>
      isOpenLoopOrUnknownScrubberFitted(_.scrubber.typeId)
    );
    const hasAddedLegsInSludgeDischargeBanDifferences = Object.values(
      voyageDeviationDifferences.added
    ).some((_) => _.hasSludgeDischargeBan);
    const hasUpdatedLegsInSludgeDischargeBanDifferences = Object.values(
      voyageDeviationDifferences.updated
    ).some((_) => _.hasSludgeDischargeBan);

    const hasImo2020VoyageDeviation =
      hasScrubberOpenLoopType &&
      (hasAddedLegsInSludgeDischargeBanDifferences ||
        hasUpdatedLegsInSludgeDischargeBanDifferences);

    if (hasImo2020VoyageDeviation) {
      deviations.push({
        deviationType: 'IMO2020',
        names: ['Updates'],
      });
    }

    // add new routing version
    if (this.routeGraphApiUpdateNotificationRequired) {
      deviations.push({
        deviationType: 'Routing engine version',
        names: [this.props.routeGraphVersion],
      });
    }

    return deviations;
  }

  newSecaZoneCheck = (waypoints, secaZoneAddedDate) => {
    this.newSecaWayPoints = [];
    this.props.voyage.legs.forEach((leg) => {
      if (
        leg &&
        leg.inboundRoute &&
        leg.inboundRoute.variants &&
        leg.inboundRoute.variants.length > 0
      ) {
        if (
          leg.inboundRoute.variants[0].waypoints &&
          leg.inboundRoute.variants[0].waypoints.length
        ) {
          const zoneCheckRequired =
            new Date(leg.inboundRoute.variants[0].routeRetrievedFromRoutingApiOn) <
            new Date(secaZoneAddedDate);

          if (zoneCheckRequired) {
            waypoints.forEach((z) => {
              const wayPointFound = leg.inboundRoute.variants[0].waypoints.filter(
                (wp) => wp.locationId.indexOf(z) > -1
              );
              if (wayPointFound && wayPointFound.length) {
                const secaZoneName = secaZones[wayPointFound[0].locationId].name;
                if (this.newSecaWayPoints.indexOf(secaZoneName) === -1) {
                  this.newSecaWayPoints.push(secaZoneName);
                }
              }
            });
          }
        }
      }
    });
    return this.newSecaWayPoints;
  };

  routeGraphVersionCheck = (latestRouteGraphVersion) => {
    let availableGraphVersions: number = [];
    this.props.voyage.legs.forEach((leg) => {
      if (
        leg &&
        leg.inboundRoute &&
        leg.inboundRoute.variants &&
        leg.inboundRoute.variants.length > 0
      ) {
        const legGraphVersion = leg.inboundRoute.variants[0].routeRetrievedWithGraphVersion;
        if (
          legGraphVersion !== undefined &&
          legGraphVersion !== null &&
          availableGraphVersions.indexOf(legGraphVersion) === -1
        ) {
          availableGraphVersions.push(legGraphVersion);
        }
      }
    });

    if (
      latestRouteGraphVersion !== undefined &&
      latestRouteGraphVersion !== null &&
      latestRouteGraphVersion !== -1 &&
      availableGraphVersions.indexOf(latestRouteGraphVersion) === -1
    ) {
      availableGraphVersions.push(latestRouteGraphVersion);
    }
    this.routeGraphApiUpdateNotificationRequired = false;
    // if worksheet contains multiple graph version
    if (availableGraphVersions && availableGraphVersions.length > 1) {
      this.routeGraphApiUpdateNotificationRequired = true;
    }
  };

  nextPortOfCallDisplayCheck = (legs) => {
    if (isFeatureEnabled('newVoyageTable')) {
      const totalLegs = legs.length - 1;
      this.showNextPortOfCall = !isLoadOrDischargePort(legs[totalLegs].type);
    }
  };

  render() {
    this.routeGraphVersionCheck(this.props.routeGraphVersion);
    this.nextPortOfCallDisplayCheck(this.props.voyage.legs);

    const voyageDeviations = this.getVoyageDeviations();

    let imoUpdateNotificationRequired = voyageDeviations.some((_) => _.deviationType === 'IMO2020');

    const currentImoNoticeId = () => {
      return this.imoNoticeId || this.routeNoticeId;
    };

    const currentRouteNoticeId = () => {
      return this.routeNoticeId || this.imoNoticeId;
    };

    const canShowGraphApiNotice = () => {
      const status =
        !imoUpdateNotificationRequired &&
        !this.graphApiNoticeStatus &&
        !this.props.voyage.legs.some((leg) => leg.isInSecaOverridden) &&
        (this.routeGraphApiUpdateNotificationRequired || voyageDeviations.length > 0);
      return status;
    };

    const useAutoIntakeToggleOnChange = () => {
      this.props.setCalculationStatus(this.props.worksheetId);
      this.props.toggleAutoIntakeCalc(this.props.worksheetId);
    };

    const headerText = isTanker(this.props.worksheetInvariantProps.marketSegmentId)
      ? 'Route'
      : 'Voyage';

    return (
      <Fragment>
        <div className="voyage-title-container">
          {isTanker(this.props.worksheetInvariantProps.marketSegmentId) && (
            <div className="voyage-icon">
              <RouteIcon className="voyage-icon__size" />
            </div>
          )}
          <Header text={headerText} className="voyage-title" />
          {isTanker(this.props.worksheetInvariantProps.marketSegmentId) &&
            isFeatureEnabled('wetCargoLiteMultiLeg') && (
              <SpeedAndConsSummaryInVoyage
                ballastSpeed={this.props.activeVessel.speedAndConsumptions.ballast.speed}
                ladenSpeed={this.props.activeVessel.speedAndConsumptions.laden.speed}
                ballastCons={this.props.activeVessel.speedAndConsumptions.ballast.consumption}
                ladenCons={this.props.activeVessel.speedAndConsumptions.laden.consumption}
              />
            )}
          {!isTanker(this.props.worksheetInvariantProps.marketSegmentId) && (
            <>
              <VerticalDivider className="voyage-title-container--divider" />
              <LinkButton
                onClick={this.openMap}
                data-testid="view-map-button"
                diagnosticId="Voyage/ViewVoyageMap"
              >
                View voyage map
              </LinkButton>
            </>
          )}
          <div className="voyage-title-container--controls">
            {isDry(this.props.worksheetInvariantProps.marketSegmentId) && (
              <>
                <Toggle
                  id="use-auto-intake-toggle"
                  checked={this.props.voyage.shouldAutoCalculateIntake}
                  onChange={() => useAutoIntakeToggleOnChange()}
                  label="Use Auto Intake"
                  diagnosticId="useAutoIntake"
                />
                <VerticalDivider className="voyage-title-container--divider" />
              </>
            )}
            {!isTanker(this.props.worksheetInvariantProps.marketSegmentId) && (
              <Toggle
                id="avoid-seca-zones-toggle"
                checked={this.props.voyage.avoidSecaZones}
                onChange={this.handleAvoidSecaZonesChanged}
                label="Minimise SECA zones"
                diagnosticId="minimiseSECAZones"
              />
            )}
          </div>
        </div>
        {isTanker(this.props.worksheetInvariantProps.marketSegmentId) ? (
          <RouteGraph
            handleOpenPositionPrevPortOfCallIsInEeaUpdated={
              this.handleOpenPositionPrevPortOfCallIsInEeaUpdated
            }
            handleOpenPositionUpdated={this.handleOpenPositionUpdated}
            handleLocationChanged={this.handleLocationChanged}
            speedChanged={this.speedChanged}
            handleSecaDistanceChanged={this.handleSecaDistanceChanged}
            handleTotalDistanceChanged={this.handleTotalDistanceChanged}
            handleSecaFuelTypeChanged={this.handleSecaFuelTypeChanged}
            handleMainFuelTypeChanged={this.handleMainFuelTypeChanged}
            handlePortDaysChanged={this.handlePortDaysChanged}
            handlePortCostChanged={this.handlePortCostChanged}
            showNextPortOfCall={this.showNextPortOfCall}
            handleNextPortOfCallIsInEeaUpdated={this.handleNextPortOfCallIsInEeaUpdated}
            isInEeaOpenLocationVisible={!isFeatureEnabled('wetCargoLitePortsOfCall')}
            handleTypeChanged={this.handleTypeChanged}
            handleCargoQuantityChanged={this.handleCargoQuantityChanged}
            handleDragEnd={this.handleDragEnd}
            handleLocationDeleted={this.handleLocationDeleted}
            appendNewVoyageEntry={this.appendNewVoyageEntry}
          />
        ) : (
          <VoyageGraph
            handleOpenPositionPrevPortOfCallIsInEeaUpdated={
              this.handleOpenPositionPrevPortOfCallIsInEeaUpdated
            }
            renderVoyageLeg={this.renderVoyageLeg}
            handleNextPortOfCallIsInEeaUpdated={this.handleNextPortOfCallIsInEeaUpdated}
            handleOpenPositionUpdated={this.handleOpenPositionUpdated}
            appendNewVoyageEntry={this.appendNewVoyageEntry}
            handleDraftUnitChanged={this.handleDraftUnitChanged}
            showNextPortOfCall={this.showNextPortOfCall}
            handleDragEnd={this.handleDragEnd}
          />
        )}
        <Waypoints
          className={classNames({
            'tankers-waypoints': isTanker(this.props.worksheetInvariantProps.marketSegmentId),
          })}
          waypoints={this.waypointsAggregatedInfo.map((waypoint) => ({
            ...waypoint,
            isObsolete: voyageDeviations
              .filter((_) => _.deviationType === 'Obsolete waypoints')
              .some((obsoleteWayPointDeviation) =>
                obsoleteWayPointDeviation.values.has(waypoint.locationId)
              ),
          }))}
          onWaypointChange={this.handleWaypointAvoidChanged}
        />
        {!isTanker(this.props.worksheetInvariantProps.marketSegmentId) && <CanalCosts />}

        {
          <Async promise={this.state.initializationPromise}>
            <Async.Fulfilled>
              {imoUpdateNotificationRequired ? (
                <EmitNotice
                  type={UPDATE_VOYAGE_DUE_TO_DEVIATION}
                  key={JSON.stringify(voyageDeviations)}
                  worksheetId={this.props.worksheetId}
                  updateWithNoticeId={currentRouteNoticeId()}
                  noticeId={this.imoNoticeId}
                >
                  {({ dismiss, additionalProps }) => {
                    this.currentDisplayNoticeId = additionalProps.id;
                    this.currentDisplayNoticeType = 'imo';
                    return (
                      <WarningBox
                        className="notice-cant-save-changes-on-colleagues-work-items warning-box"
                        additionalprops={additionalProps}
                      >
                        <h1 className="warning-box__header">IMO 2020 Update</h1>
                        <div className="warning-box__text">
                          May update distances, waypoints and fuels used. More info:{' '}
                          <TextToolTip
                            tooltipContent={
                              <VoyageDeviationTooltipBody deviations={voyageDeviations} />
                            }
                          >
                            here
                          </TextToolTip>
                        </div>
                        <SecondaryButton
                          onClick={() => {
                            dismiss();
                          }}
                          diagnosticId="Voyage/VoyageDeviationTopLevelNoticeRemindMeLater"
                        >
                          Remind me later
                        </SecondaryButton>
                        <SecondaryButton
                          onClick={() => {
                            dismiss();
                            this.updateVoyage();
                          }}
                          diagnosticId="Voyage/VoyageDeviationTopLevelNoticeUpdate"
                        >
                          Update
                        </SecondaryButton>
                        <IconButton
                          aria-label="Dismiss"
                          icon={iconEnum.Close}
                          className="notice-box__standard-dismiss-button"
                          onClick={() => {
                            dismiss();
                          }}
                        />
                      </WarningBox>
                    );
                  }}
                </EmitNotice>
              ) : (
                <>{/*  Some children are required by `Async.Fulfilled` */}</>
              )}

              {canShowGraphApiNotice() && (
                <>
                  <EmitNotice
                    type={ROUTING_API_WARNING_NOTICE}
                    worksheetId={this.props.worksheetId}
                    key={JSON.stringify(voyageDeviations)}
                    updateWithNoticeId={currentImoNoticeId()}
                    noticeId={this.routeNoticeId}
                  >
                    {({ dismiss, additionalProps }) => {
                      this.currentDisplayNoticeId = additionalProps.id;
                      this.currentDisplayNoticeType = 'route';
                      return (
                        <WarningBox
                          additionalprops={additionalProps}
                          className="notice-cant-save-changes-on-colleagues-work-items"
                        >
                          <h1 className="warning-box__header">Routing Update</h1>
                          <div className="warning-box__text">
                            May change distances and waypoints.
                            {voyageDeviations && voyageDeviations.length > 0 && (
                              <>
                                {' '}
                                More Info:{' '}
                                <TextToolTip
                                  tooltipContent={
                                    <VoyageDeviationTooltipBody deviations={voyageDeviations} />
                                  }
                                >
                                  here
                                </TextToolTip>
                              </>
                            )}
                          </div>

                          <SecondaryButton
                            onClick={() => {
                              dismiss();
                              this.graphApiNoticeStatus = 'remind';
                            }}
                            diagnosticId="Voyage/Remind"
                          >
                            Remind me later
                          </SecondaryButton>
                          <SecondaryButton
                            onClick={() => {
                              dismiss();
                              this.updateVoyage();
                              this.graphApiNoticeStatus = 'undate';
                            }}
                            diagnosticId="Voyage/Update"
                          >
                            Update
                          </SecondaryButton>
                          <IconButton
                            aria-label="Dismiss"
                            icon={iconEnum.Close}
                            className={'notice-box__standard-dismiss-button'}
                            onClick={() => {
                              dismiss();
                              this.graphApiNoticeStatus = 'dismiss';
                            }}
                          />
                        </WarningBox>
                      );
                    }}
                  </EmitNotice>
                </>
              )}
            </Async.Fulfilled>
          </Async>
        }

        <>
          {((!this.routeGraphApiUpdateNotificationRequired && !voyageDeviations.length) ||
            this.graphApiNoticeStatus) &&
          !imoUpdateNotificationRequired &&
          this.currentDisplayNoticeType ? (
            <EmitNotice
              type={DISMISS_NOTICE_BY_ID}
              noticeIdToDismiss={this.currentDisplayNoticeId}
            />
          ) : (
            <> </>
          )}
        </>
      </Fragment>
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    /*
      Calling through `runInQueueForRouteCalculation` as part of #RouteRecalculationsRaceConditionPrevention - only start 'one route calculation at a time' per worksheet so that:
       * any earlier recalculations have inserted the routes already
       * any changes to the route preferences are already reflected in `this.props` (especially `waypointsToExclude`, whose item's `avoid` flag is settled only **after** calculation returned).
     */
    await runInQueueForRouteCalculation(
      this.props.worksheetId,
      async () =>
        await this.props.applyVoyageLocationsSequenceChangesToRoutes({
          prevAllLegsWithFromToInfo: Voyage.getAllLegsWithFromToInfoFromProps(prevProps),
          currAllLegsWithFromToInfo: this.allLegsWithFromToInfo,
          avoidSecaZones: this.props.voyage.avoidSecaZones,
          marketSegmentId: this.props.worksheetInvariantProps.marketSegmentId,
          waypointsToExclude: this.waypointsToExcludeForNewRoutes,
          worksheetId: this.props.worksheetId,
          graphVersion: this.props.routeGraphVersion,
        })
    );
    this.routeGraphVersionCheck(this.props.routeGraphVersion);
    this.nextPortOfCallDisplayCheck(this.props.voyage.legs);
  }

  componentDidMount = storePromiseOnCall(
    async () => {
      this.cancelTokenSource = axios.CancelToken.source();

      const [latestRoutesForLegs, latestLocationInfo] = await Promise.all([
        this.getLatestRoutesForLegs(this.cancelTokenSource.token),
        this.getLatestLocationInfo(this.cancelTokenSource.token),
      ]);

      this.routeGraphVersionCheck(this.props.routeGraphVersion);
      this.updateNewSecaZoneList();

      this.props.voyageLegsLoaded(latestRoutesForLegs, this.props.worksheetId);

      this.setState({
        voyageUpdateInfo: {
          latestRoutesForLegs: latestRoutesForLegs,
          latestLocationInfo: latestLocationInfo,
        },
      });
    },
    /* storePromise: */ (initializationPromise) => this.setState({ initializationPromise })
  );

  updateNewSecaZoneList = () => {
    // new waypoint check - to show banner
    const secaZoneAddedDate = new Date('2022-11-23T15:00:00Z');
    const wayPointsName = ['Iceland ECA', 'Hainan ECA', 'South Korea ECA'];
    let newWayPoints = Object.keys(secaZones).filter((id) =>
      wayPointsName.includes(secaZones[id].name)
    );
    this.newSecaZoneCheck(newWayPoints, secaZoneAddedDate);
  };

  componentWillUnmount() {
    // TODO follow the `cleanUpTask` approach
    this.cancelTokenSource.cancel();
  }

  /**
   * This member is the ViewModel for the UI that lists/manipulates waypoints travelled/excluded for *all* legs.
   */
  get waypointsAggregatedInfo(): Array<IVoyageWaypoint> {
    return this.allUserSignificantWaypointsWithOccurrencesInLegs.map((waypoint) => ({
      locationId: waypoint.locationId,
      name: waypoint.waypointData.name,
      avoid: waypoint.occurrencesInLegs.some(
        /* The choice of `some` for `avoid` is only significant in the case of #WaypointsAvoidedInOnlySomeLegs. Choosing `some` is a better behavior for displaying/manipulating existing waypoints, in that it allows the user to see that the avoidance is in force in some of the legs. */
        (_) => _.avoid
      ),
      unavoidable: waypoint.occurrencesInLegs.some((_) => _.unavoidable),
    }));
  }

  get waypointsToExcludeForNewRoutes(): Array<IWaypointIdentity> {
    return getWaypointsToExcludeForNewRoutes(this.props.voyage.legs);
  }

  get allUserSignificantWaypointsWithOccurrencesInLegs(): Array<WaypointsWithOccurrencesInLeg> {
    return getAllUserSignificantWaypointsWithOccurrencesInLegs(this.props.voyage.legs);
  }

  static getAllLegsWithFromToInfoFromProps(props) {
    return getAllLegsWithFromToInfo({
      vessels: props.vessels,
      legs: props.voyage.legs,
    });
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      avoidSecaZonesChanged,
      cargoQuantityChanged,
      delayChanged,
      delayUnitChanged,
      draftChanged,
      draftUnitChanged,
      gearChanged,
      loadBunkerPrice,
      loadDischargeRateChanged,
      loadDischargeRateUnitChanged,
      locationAdded,
      locationChanged,
      locationDeleted,
      openPositionUpdated,
      openPositionPrevPortOfCallIsInEeaUpdated,
      nextPortOfCallIsInEeaUpdated,
      portCostChanged,
      isInSecaChanged,
      isInEeaChanged,
      salinityChanged,
      secaDistanceChanged,
      totalDistanceChanged,
      turnTimeChanged,
      turnTimeUnitChanged,
      typeChanged,
      setPortsInSeca,
      voyageLegMoved,
      locationsToAvoidChanged,
      weatherFactorChanged,
      workingDayMultiplierChanged,
      setStageLeftAs,
      removeWaypointsAndCalculateLegsInVoyage,
      toggleAutoIntakeCalc,
      applyVoyageLocationsSequenceChangesToRoutes,
      setCalculationStatus,
      mainFuelGradeChanged,
      zoneSpecificSecaFuelGradeChanged,
      speedChanged,
      consumptionChanged,
      consumptionOverrideQtyChanged,
      voyageLegsLoaded,
      voyageTypeChangedUpdateDelayAllLegs,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Voyage);

function VoyageDeviationTooltipBody({ deviations }) {
  return (
    <>
      {deviations.map(({ deviationType, names }) => {
        return names && names.length ? (
          <div className="voyage-deviation-tooltip__body" key={deviationType}>
            <span>{deviationType}: </span>
            <span className="voyage-deviation-tooltip__body--names">
              {joinStringList(names, ', ', ' and ')}
            </span>
          </div>
        ) : null;
      })}
    </>
  );
}

function doesLegPortHaveOperationalRestriction(leg, operationalRestrictionId) {
  return (
    leg.portOperationalRestrictionIds &&
    leg.portOperationalRestrictionIds.has(operationalRestrictionId)
  );
}
