export const debounceDefaults = {
  /**
   * Start with an immediate execution on the first call. 'First' meaning both the first call ever, or just first after a longer pause (longer than `wait` ms since the previous).
   */
  leading: true,
  /**
   * Don't execute if there's already been a call less than `wait` ms ago.
   */
  wait: 350,
  /**
   * But then do execute after that `wait` time. This ensures that the user eventually sees all the changes (natural for uses where each call flags a need to update to reflect new data).
   *
   * NOTE: This is [the default for debounce](https://lodash.com/docs/#debounce) already.
   */
  trailing: true,
  /**
   * Setting `maxWait` to something positive means that we add 'extra in-between executions' even if the user keeps producing calls with less than `wait` time in between (useful for showing the user some in-between results as he continues to produce input).
   **/
  maxWait: 1000,
};

/**
 * A shorthand form when you want to change some of the options
 *
 * Usage:
 * ```javascript
 * debounce(() => someFn(), debounceDefaults.wait, { ...debounceDefaultExtraOptions, maxWait: 1337 })
 * ```
 */
export const debounceDefaultExtraOptions = {
  leading: debounceDefaults.leading,
  trailing: debounceDefaults.trailing,
  maxWait: debounceDefaults.maxWait,
};

/**
 * A shorthand form with minimal boilerplate.
 *
 * Usage:
 * ```javascript
 * debounce(() => someFn(), ...debounceDefaultsSpreadable)
 * ```
 */
export const debounceDefaultsSpreadable = [debounceDefaults.wait, debounceDefaultExtraOptions];
