import { combineReducers, ReducersMapObject, Reducer } from 'redux';
import { isProductionEnvironment } from 'utilities/environments/is-production-environment';

/*
  Allows to use the resulting `combineReducers` in further composition on the same level (e.g. with `concatenateReducers`, `reduceReducers` etc) without the `combineReducers` producing its warnings. ([`reduceReducers(combinedReducers, crossSliceReducer)` is suggested in the official docs](https://redux.js.org/recipes/structuring-reducers/beyond-combinereducers) which [ends up producing the warning](https://github.com/reduxjs/redux/issues/2427#issuecomment-308589581))
*/
export function combineReducersMergeable<S>(
  reducersMapObject: ReducersMapObject<S, any>
): Reducer<S> {
  const combinedReducer = combineReducers(reducersMapObject);
  if (isProductionEnvironment(reducersMapObject))
    /* On production there's no need to wrap because [_"the warnings are only actually logged in development"_](https://github.com/reduxjs/redux/issues/2427#issuecomment-304499200)*/

    return combinedReducer;
  else return addFilteringOfExtraPropsToSuppressCombineReducerWarnings(combinedReducer);
}

export const addFilteringOfExtraPropsToSuppressCombineReducerWarnings = (reducer) => {
  // Source: [this StackOverflow entry > Edit 4](https://stackoverflow.com/q/43290107)
  let knownKeys = Object.keys(reducer(undefined, { type: '@@FILTER/INIT' }));

  return (state, action) => {
    let filteredState = state;

    if (knownKeys.length && state !== undefined) {
      filteredState = knownKeys.reduce((current, key) => {
        current[key] = state[key];
        return current;
      }, {});
    }

    let newState = reducer(filteredState, action);

    let nextState = state;

    if (newState !== filteredState) {
      knownKeys = Object.keys(newState);
      nextState = {
        ...state,
        ...newState,
      };
    }

    return nextState;
  };
};
