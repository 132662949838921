import {
  UPDATE_COMPARISON_TABLE_SETTINGS,
  ADJUST_COLUMNS_TO_VIEW_RATES,
  SET_COMPARISON_TABLE_SETTINGS,
} from 'constants/action-types/worksheet-comparison';
import {
  getComparisonTableState,
  updateComparisonTableState,
} from '../../../api/clients/user-preference';
import { buildAction } from '../../../utilities/redux';
import { eventDestination, trackEvent } from '../../../diagnostics/calc-trackevents';

export const setComparisonTableSettings = (marketSegmentId, data) => ({
  type: SET_COMPARISON_TABLE_SETTINGS,
  payload: { marketSegmentId, data },
});
export const updateComparisonTableSettings = (columnsState) => ({
  type: UPDATE_COMPARISON_TABLE_SETTINGS,
  payload: columnsState,
});

export const adjustColumnsToViewRates = (viewMode) => async (dispatch) => {
  await dispatch(buildAction(ADJUST_COLUMNS_TO_VIEW_RATES, viewMode));

  trackEvent(
    'ComparisonTable',
    'ComparisonTableIllegalViewStateChanged',
    {
      illegalState: false,
      viewRates: viewMode,
    },
    {},
    eventDestination.ANALYSIS
  );
};

export const fetchComparisonTableSettings = (marketSegmentId) => async (dispatch) => {
  const data = await getComparisonTableState();
  dispatch(setComparisonTableSettings(marketSegmentId, data));
};

export const triggerUpdateComparisonTableState = () => (dispatch, getState) => {
  const comparisonTableStateDetails = getState()
    .comparisonTableSettings.columnsState.filter((el) => el.parentId !== '')
    .map((e) => {
      return {
        columnId: e.columnId,
        state: e.state,
      };
    });
  updateComparisonTableState(comparisonTableStateDetails);
};
