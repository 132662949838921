import stringEnum, { StringEnum } from 'utilities/enum/string-enum';
import VError from 'verror';
import { LOAD, DISCHARGE } from 'constants/enums/voyage-leg';

const voyageEntryTypeValues = ['load', 'discharge'];

export type VoyageEntryType = 'load' | 'discharge';

export const voyageEntryTypes: StringEnum<VoyageEntryType> = stringEnum(voyageEntryTypeValues);

export function mapVoyageEntryToVoyageLegEnum(voyageEntryType: VoyageEntryType) {
  switch (voyageEntryType) {
    case voyageEntryTypes.load:
      return LOAD;
    case voyageEntryTypes.discharge:
      return DISCHARGE;
    default:
      throw new VError(`Mapping ${voyageEntryType} to a voyage leg enum is not supported`);
  }
}
