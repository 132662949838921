import React from 'react';
import { ComponentType } from 'react';

export interface NextPortOfCallProps {
  children?: React.ReactNode;
  dotClassName?: string;
  legClassName?: string;
  euaLabel?: ComponentType;
  style?: React.CSSProperties;
  disableTimeline?: boolean;
}

const LegContainer = ({
  children,
  dotClassName,
  euaLabel,
  legClassName,
  style,
  disableTimeline = false,
}: NextPortOfCallProps) => {
  return (
    <div className={legClassName || 'voyage-leg'} style={style}>
      {!disableTimeline && (
        <div className="timeline">
          <div className={dotClassName || 'dot'} />
          {euaLabel}
        </div>
      )}
      {children}
    </div>
  );
};

export default LegContainer;
