import React from 'react';
import { IconButtonToolTip } from 'components/button';
import { bunkersOnBoardId } from 'constants/enums/bunkers';
import { iconEnum } from 'components/icons';
import { willFuelGradeBeUsed } from '../../utilities';
import BunkerRowQuantityPriceCell from '../bunker-row-quantity-price-cell';
import { BunkerLastModifiedInfo } from '../../components/bunkers-last-modified-info';
import { ALL_FUEL_GRADES_IN_UI_ORDER } from 'constants/enums/fuel-grades';
import isNil from 'lodash/isNil';

const hasFuelGradeUsedList = (fuelGradesUsed) => isNil(fuelGradesUsed) === false;

//#region Class Name Constants

export const tableName = 'bunkers-table';
export const tableFlex = `${tableName}-flex`;
export const rowClassName = `${tableFlex}-row`;
export const colClassName = `${tableFlex}-col`;
export const headerClassName = `${tableFlex}-header-col`;
export const headerLocClassName = `${tableFlex}-header-location-col`;
export const leftColFixed = `${tableFlex}-fixed-left`;
export const scrollableCol = `${tableFlex}-scrollable`;
export const rightColFixed = `${tableFlex}-fixed-right`;

//#endregion

//#region Location (fixed left column related functions)

const LocationHeader = () => {
  return (
    <div className={`${rowClassName}`}>
      <div className={`${headerLocClassName}`}>Location</div>
    </div>
  );
};

const LocationsCol = (props) => {
  return props.bunkers.map((bunker) => {
    return (
      <div key={`bkr-loc-row-${bunker.locationIndex}`} className={`${rowClassName}`}>
        <div key={`bkr-loc-${bunker.locationIndex}`} className={`${colClassName}-location`}>
          {bunker.locationName || `Loc ${bunker.locationIndex + 1} unassigned`}
        </div>
        {bunker.bunkerId === bunkersOnBoardId && (
          <IconButtonToolTip
            key={`bkr-tooltip-${bunker.locationIndex}`}
            onClick={() => props.showBunkerPortsTable(/*isBunkerPricesPanelReadOnly*/ false)}
            text="Import Bunker Prices"
            icon={iconEnum.FileDownload}
            diagnosticId="BunkerRow/ShowTable"
          />
        )}
        <BunkerLastModifiedInfo
          key={`bkr-modified-${bunker.locationIndex}`}
          modifiedDate={bunker.priceIssuedDate}
          locationName={bunker.importLocationName}
        />
      </div>
    );
  });
};

export const LocationSection = (props) => {
  return (
    <>
      <LocationHeader />
      <LocationsCol {...props} />
    </>
  );
};

//#endregion

//#region Scrollable Section Header functions

export const Header = (props) => {
  return ALL_FUEL_GRADES_IN_UI_ORDER.map(({ key, label }) => {
    const isFuelGradeUsed =
      hasFuelGradeUsedList(props.fuelGradesUsed) && willFuelGradeBeUsed(props.fuelGradesUsed, key);
    const styles = isFuelGradeUsed ? null : 'bunkers-table-unused-col';
    return ((isFuelGradeUsed || props.showUnusedBunkers) && (
      <div key={`${key}-key`} className={`${headerClassName}`}>
        <div className={`${headerClassName}-fuel-grade`}>
          <div className={`${headerClassName}-fuel-grade-title ${styles}`}>{label}</div>
          <div className={`${headerClassName}-fuel-grade-breakdown ${styles}`}>
            <div className={`${headerClassName}-fuel-grade-breakdown-quantity ${styles}`}>
              Qty (MT)
            </div>
            <div className={`${headerClassName}-fuel-grade-breakdown-divider`}>@</div>
            <div className={`${headerClassName}-fuel-grade-breakdown-price`}>($/MT)</div>
          </div>
        </div>
      </div>
    ): null);
  });
};

export const HeaderRefreshIcon = (props) => {
  if (!props.bunkers || props.bunkers.length <= 1) {
    return null;
  }
  return (
    <IconButtonToolTip
      onClick={async () => {
        await props.refreshBunkersForWorksheet(props.worksheetId);
      }}
      text="Refresh to latest bunker prices"
      icon={iconEnum.Refresh}
      diagnosticId="BunkerHeader/RefreshBunkers"
    />
  );
};

//#endregion

//#region Scrollable Section Row and Col functions

const bunkerCell = (bunker, props) => {
  const { bunkerId, quantityPriceSet } = bunker;
  return ALL_FUEL_GRADES_IN_UI_ORDER.map((fuelGrade) => {
    const fuelGradeId = fuelGrade.key;
    const isFuelGradeUsed =
      hasFuelGradeUsedList(props.fuelGradesUsed) &&
      willFuelGradeBeUsed(props.fuelGradesUsed, fuelGradeId);
    const { quantity, price } = quantityPriceSet[fuelGrade.key];
    const { onQuantityChanged, onPriceChanged, calculationResults } = props;
    const bunkerQuantityPriceCellProps = {
      quantity,
      price,
      onQuantityChanged: onQuantityChanged(bunkerId, fuelGradeId),
      onPriceChanged: onPriceChanged(bunkerId, fuelGradeId),
      isFuelGradeUsed,
      fuelGradeId,
      calculationResults,
      bunkerId,
    };

    return ((isFuelGradeUsed || props.showUnusedBunkers) && (
      <div key={`bkr-div-${fuelGrade.key}`} className={`${colClassName}`}>
        <BunkerRowQuantityPriceCell
          key={`bkr-cell-${fuelGrade.key}`}
          worksheetId={props.worksheetId}
          {...bunkerQuantityPriceCellProps}
        />
      </div>
    ): null);
  });
};

const bunkerCells = (bunker, props) => {
  return (
    <>
      {bunkerCell(bunker, props)}
      <div className={`${colClassName}`}>
        {/*
        We do not show the button when there is BOB. See #PreventUpdatingPriceOnNoImportedPriceOrForBOB 
        */}
        {bunker.bunkerId !== bunkersOnBoardId && (
          <IconButtonToolTip
            onClick={async () => {
              await props.updateBunkerPrice({
                worksheetId: props.worksheetId,
                bunkerPort: bunker.bunkerOnWorksheet,
              });
            }}
            text="Refresh to latest bunker prices"
            icon={iconEnum.Refresh}
            disabled={!bunker.locationId}
            diagnosticId="BunkerRow/RefreshPrices"
          />
        )}
      </div>
    </>
  );
};

export const bunkerRows = (props) => {
  return props.bunkers.map((bunker) => {
    return (
      <div key={`bkr-row-${bunker.locationIndex}`} className={`${rowClassName}`}>
        {bunkerCells(bunker, props)}
      </div>
    );
  });
};
//#endregion
