import { isValidOpenPositionLocationType } from 'modules/voyage/business-model/voyage-locations-sequence';
import type { ExportDetailedCalculationRequest } from '../clients/export';
import { singleOrThrow } from '../../utilities/iterable';
import buildWorksheetRequest from './worksheet-request-builder';

export function buildDetailedCalculationExportRequest(
  worksheetViewModel: IWorksheetViewModel,
  vessel: IVesselViewModel,
  calculationViewModel: ICalculationViewModel
): ExportDetailedCalculationRequest {
  const { cargoQuantityMovedAtVoyageEntries, ...calculationSummaryRequest } = calculationViewModel;

  const worksheetRequest = buildWorksheetRequest(worksheetViewModel);

  /*
  Remove the vessels that aren't part of the export as otherwise it will throw (even though it accepts an array of vessels).
  #TODOMakeCalculationExportApiAcceptSingleCalculationInputInsteadOfWholeWorksheet - TODO: calculation export service only deals with a single calculation for a single vessel, so it shouldn't be requiring in its contract the entire worksheet, with all the vessels. Rather it should only the input taken for the calculation.
  */
  const requestVessel = singleOrThrow(
    worksheetRequest.vessels.filter((_) => _.entryId === vessel.entryId)
  );
  worksheetRequest.vessels = [requestVessel];

  removeOpenPositionContainingInvalidLocationTypes(requestVessel);
  setCargoQuantityMovedAtVoyageEntry(worksheetRequest, cargoQuantityMovedAtVoyageEntries);

  return {
    worksheet: worksheetRequest,
    calculationSummary: calculationSummaryRequest,
    exportCreatorTimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  function removeOpenPositionContainingInvalidLocationTypes(vessel) {
    if (
      vessel.openPosition &&
      vessel.openPosition.location &&
      !isValidOpenPositionLocationType(vessel.openPosition.location.locationTypeId)
    ) {
      // Prevent the report from listing the location
      vessel.openPosition.location = null;
    }
  }
}

function setCargoQuantityMovedAtVoyageEntry(worksheetRequest, cargoQuantityMovedAtVoyageEntries) {
  for (const leg of worksheetRequest.voyage.legs) {
    leg.quantity = cargoQuantityMovedAtVoyageEntries[leg.id];
  }
}
