import React from 'react';
import * as locationClient from '../../../../api/clients/location';
import PortAutocomplete from 'components/port-autocomplete';
import { Checkbox } from 'components/checkbox';
import { EEACheckbox } from './../eea';
import './styles.scss';
import { isTanker } from 'constants/market-segments';

export const Location: FC = ({
  locationId,
  locationName,
  isInSeca,
  isMandatory,
  isReadonly,
  isInEea,
  isInSecaOverridden,
  marketSegmentId,
  onLocationChange,
  onIsInSecaChange,
  onIsInEeaChange,
  isInSecaVisible = true,
  isInEeaVisible = true,
  labelName,
  autoPopulatedField,
  activeVesselName,
  initialOpenPosition,
}) => {
  const handleLocationChanged = (newLocationInfo) => {
    onLocationChange(newLocationInfo);
    if (isInSecaVisible) {
      locationClient.isPortInSeca(newLocationInfo.locationId, marketSegmentId).then((result) => {
        onIsInSecaChange({
          isInSeca: result,
          isInSecaOverridden: false,
        });
      });
    }
  };

  return (
    <>
      <PortAutocomplete
        port={{ locationId, locationName }}
        onChange={handleLocationChanged}
        isMandatory={isMandatory}
        marketSegmentId={marketSegmentId}
        isReadonly={isReadonly}
        autoPopulatedField={autoPopulatedField}
        activeVesselName={activeVesselName}
        initialOpenPosition={initialOpenPosition}
      />
      <div className="checkboxes">
        {isTanker(marketSegmentId) && isInEeaVisible ? (
          <EEACheckbox
            onIsInEeaChange={onIsInEeaChange}
            id="eea-checkbox"
            isInEea={isInEea}
            label="EEA"
            isReadonly={isReadonly}
            isInEeaVisible={isInEeaVisible}
            diagnosticId={labelName ? `${labelName}/isInEea` : 'isInEea'}
          />
        ) : (
          <div className="empty-checkbox ve-checkbox"></div>
        )}
        {isInSecaVisible ? (
          <Checkbox
            disabled={!locationId}
            id="seca-checkbox"
            onChange={() =>
              onIsInSecaChange({
                isInSeca: !isInSeca,
                isInSecaOverridden: !isInSecaOverridden,
              })
            }
            value={isInSeca}
            label="SECA"
            diagnosticId={labelName ? `${labelName}/isInSeca` : 'isInSeca'}
            isReadonly={isReadonly}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
