import VError from 'verror';

import {
  VOYAGE_AVOID_SECA_ZONES_CHANGED,
  VOYAGE_CARGO_QUANTITY_CHANGED,
  VOYAGE_DELAY_CHANGED,
  VOYAGE_DELAY_UNIT_CHANGED,
  VOYAGE_DRAFT_CHANGED,
  VOYAGE_DRAFT_UNIT_CHANGED,
  VOYAGE_GEAR_CHANGED,
  VOYAGE_LEG_MOVED,
  VOYAGE_LOAD_DISCHARGE_RATE_CHANGED,
  VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED,
  VOYAGE_LOCATION_ADDED,
  VOYAGE_LOCATION_CHANGED,
  VOYAGE_LOCATION_DELETED,
  VOYAGE_PORT_COST_CHANGED,
  VOYAGE_IS_IN_SECA_CHANGED,
  VOYAGE_IS_IN_EEA_CHANGED,
  VOYAGE_SALINITY_CHANGED,
  VOYAGE_TURN_TIME_CHANGED,
  VOYAGE_TURN_TIME_UNIT_CHANGED,
  VOYAGE_TYPE_CHANGED,
  VOYAGE_WEATHER_FACTOR_CHANGED,
  VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED,
  VOYAGE_SET_PORTS_IN_SECA,
  VOYAGE_TOGGLE_AUTO_INTAKE_CALC,
  VOYAGE_DELAY_CHANGED_ALL_LEGS,
  VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS,
  VOYAGE_LEGS_LOADED,
  VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS,
} from 'constants/action-types/worksheet/voyage';
import { BUNKER, VIA, LOAD, DISCHARGE } from 'constants/enums/voyage-leg';
import { WORKING, LOADING, DISCHARGING } from 'modules/voyage/gear-types';
import {
  METRIC_TONNES_PER_DAY,
  METRIC_TONNES_PER_HOUR,
} from 'modules/voyage/load-discharge-rate-unit-types';
import { DEFAULT_FACTOR } from 'modules/voyage/components/working-day-dropdown/working-day-factors';
import { Geolocation } from 'utilities/location';
import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import { RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable } from 'api/clients/route';
import {
  tryGetVoyageEntriesRoutesWithUpdatedVariants,
  applySuccessfulResultsOfVoyageEntryRoutesTryGetUpdatedVariants,
  handleEachUnsuccessfulRouteResultTypeThrowIfAnyUnknown,
} from './routes';
import * as iterableUtils from '../../../utilities/iterable';
import { distributeUserEditedDistances } from 'reducers/worksheet/voyage/user-edited-voyage-leg-distances-distributor';
import { routeVariantsSet } from './routes';
import { createNewEmptyLeg } from 'reducers/worksheet/voyage';
import uniq from 'lodash/uniq';
import { marketSegmentIds } from 'constants/market-segments';
import { SET_CALCULATIONS_STATUS } from 'constants/action-types/calculation-summary';
import { CalculationStatus } from 'constants/enums/calculation-status';
import { getSpeedAndConsumptionByLegType } from "../../../constants/enums/speed-and-con-types";

function typeChanged(
    payload,
    portId,
    locationId,
    cargoId,
    worksheetId,
    marketSegmentId,
    speedAndConsumptions)  {
  const portDataToUpdate = {
    type: payload.key,
    locationId,
  };

  if (payload === BUNKER || payload === VIA) {
    portDataToUpdate.gear = null;
    portDataToUpdate.cargoId = null;
    portDataToUpdate.cargoQuantity = null;
    portDataToUpdate.loadDischargeRate = null;
    portDataToUpdate.rateUnit = null;
    portDataToUpdate.workingDayType = null;
    portDataToUpdate.workingDayMultiplier = null;
  } else {
    if (marketSegmentId === marketSegmentIds.wetCargo) {
      if (payload === LOAD) {
        portDataToUpdate.gear = LOADING.key;
      } else if (payload === DISCHARGE) {
        portDataToUpdate.gear = DISCHARGING.key;
      }
      portDataToUpdate.rateUnit = METRIC_TONNES_PER_HOUR.key;
    } else {
      portDataToUpdate.gear = WORKING.key;
      portDataToUpdate.rateUnit = METRIC_TONNES_PER_DAY.key;
    }

    portDataToUpdate.cargoId = cargoId;
    portDataToUpdate.cargoQuantity = 0;
    portDataToUpdate.loadDischargeRate = 0;
    portDataToUpdate.workingDayType = DEFAULT_FACTOR.id;
    portDataToUpdate.workingDayMultiplier = DEFAULT_FACTOR.factor;
  }

  if (speedAndConsumptions.isTankerIndexVessel) {
      const speedAndConsumption = getSpeedAndConsumptionByLegType(speedAndConsumptions, payload.key);
      portDataToUpdate.loadDischargeConsumptionsOverrideQty = speedAndConsumption?.consumption;
  }

  return {
    type: VOYAGE_TYPE_CHANGED,
    payload: portDataToUpdate,
    portId,
    worksheetId,
  };
}

function locationChanged(payload, portInSequenceBeingChangedId, worksheetId) {
  return async function (dispatch) {
    const location = payload.portInSequenceBeingChanged;
    const locationType = payload.type.key;

    dispatch({
      worksheetId,
      type: VOYAGE_LOCATION_CHANGED,
      payload: {
        type: locationType,
        locationId: location.locationId,
        locationName: location.locationName,
        isInSeca: location.isInSeca,
        isInEea: location.isInEea,
        zone: location.zone,
        country: location.country,
        longitude: location.longitude,
        latitude: location.latitude,
      },
      // `port*` here really means a leg - See #FixLegsMisnamedAsPorts
      portId: portInSequenceBeingChangedId,
    });
  };
}

function setPortsInSeca(locationIds, inSeca = true, worksheetId) {
  return {
    worksheetId,
    type: VOYAGE_SET_PORTS_IN_SECA,
    payload: { locationIds, inSeca },
  };
}

function gearChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_GEAR_CHANGED, payload.key, portId, worksheetId);
}

const totalDistanceChanged =
  ({
    newValue,
    voyageEntryId,
    worksheetId,
    inboundRouteVariant,
  }: {
    newValue: number,
    voyageEntryId: VoyageEntryId,
    worksheetId: WorksheetId,
    inboundRouteVariant: InboundRouteVariantViewModel,
  }) =>
  (dispatch, getState) => {
    dispatch(
      routeVariantsSet({
        voyageEntryId: voyageEntryId,
        worksheetId: worksheetId,
        newRouteVariantViewModels: iterableUtils.mapMatchingAndLeaveOthersErrorIfNoneFound(
          iterableUtils.singleOrThrow(
            getState().worksheetsById[worksheetId].voyage.legs.filter((_) => _.id === voyageEntryId)
          ).inboundRoute.variants,
          /* itemPredicate: */ (variant) => variant === inboundRouteVariant,
          /* getNewItem: */ (variant) => ({
            ...variant,
            totalDistance: newValue,
            subtotalsByOperationalRestrictionIds: distributeUserEditedDistances(
              variant.secaDistance,
              newValue,
              variant.subtotalsByOperationalRestrictionIds
            ),
          })
        ),
      })
    );
  };

const secaDistanceChanged =
  ({
    newValue,
    voyageEntryId,
    worksheetId,
    inboundRouteVariant,
  }: {
    newValue: number,
    voyageEntryId: VoyageEntryId,
    worksheetId: WorksheetId,
    inboundRouteVariant: InboundRouteVariantViewModel,
  }) =>
  (dispatch, getState) => {
    dispatch(
      routeVariantsSet({
        voyageEntryId: voyageEntryId,
        worksheetId: worksheetId,
        newRouteVariantViewModels: iterableUtils.mapMatchingAndLeaveOthersErrorIfNoneFound(
          iterableUtils.singleOrThrow(
            getState().worksheetsById[worksheetId].voyage.legs.filter((_) => _.id === voyageEntryId)
          ).inboundRoute.variants,
          /* itemPredicate: */ (variant) => variant === inboundRouteVariant,
          /* getNewItem: */ (variant) => ({
            ...variant,
            secaDistance: newValue,
            subtotalsByOperationalRestrictionIds: distributeUserEditedDistances(
              newValue,
              variant.totalDistance,
              variant.subtotalsByOperationalRestrictionIds
            ),
          })
        ),
      })
    );
  };

function draftChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_DRAFT_CHANGED, payload, portId, worksheetId);
}

function draftUnitChanged(newDraftByLegId, draftUnit, worksheetId) {
  return {
    worksheetId,
    type: VOYAGE_DRAFT_UNIT_CHANGED,
    payload: newDraftByLegId,
    draftUnit: draftUnit.key,
  };
}

function salinityChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_SALINITY_CHANGED, payload.key, portId, worksheetId);
}

function cargoQuantityChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_CARGO_QUANTITY_CHANGED, payload, portId, worksheetId);
}

function cargoQuantityChangedAllLegs(payload, worksheetId) {
  return {
    type: VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS,
    payload,
    worksheetId,
  };
}

function loadDischargeRateChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_LOAD_DISCHARGE_RATE_CHANGED, payload, portId, worksheetId);
}

function loadDischargeRateUnitChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED, payload.key, portId, worksheetId);
}

function workingDayMultiplierChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED, payload, portId, worksheetId);
}

function delayChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_DELAY_CHANGED, payload, portId, worksheetId);
}

function delayUnitChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_DELAY_UNIT_CHANGED, payload.key, portId, worksheetId);
}

function delayChangedAllLegs(payload, worksheetId) {
  return {
    type: VOYAGE_DELAY_CHANGED_ALL_LEGS,
    payload,
    worksheetId,
  };
}

function voyageTypeChangedUpdateDelayAllLegs(payload, worksheetId) {
  return {
    type: VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS,
    payload,
    worksheetId,
  };
}

function turnTimeChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_TURN_TIME_CHANGED, payload, portId, worksheetId);
}

function turnTimeUnitChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_TURN_TIME_UNIT_CHANGED, payload.key, portId, worksheetId);
}

function weatherFactorChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_WEATHER_FACTOR_CHANGED, payload, portId, worksheetId);
}

function portCostChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_PORT_COST_CHANGED, payload, portId, worksheetId);
}

function isInSecaChanged(payload, isInSecaOverridden, portId, worksheetId) {
  return actionBuilder(
    VOYAGE_IS_IN_SECA_CHANGED,
    {
      isInSeca: payload,
      isInSecaOverridden: isInSecaOverridden,
    },
    portId,
    worksheetId
  );
}

function isInEeaChanged(payload, portId, worksheetId) {
  return actionBuilder(VOYAGE_IS_IN_EEA_CHANGED, payload, portId, worksheetId);
}

function locationDeleted(portInSequenceBeingDeletedId, worksheetId) {
  return {
    worksheetId,
    type: VOYAGE_LOCATION_DELETED,
    portId: portInSequenceBeingDeletedId,
  };
}

function getAllWaypointsToAvoidLocationIds(legs: Iterable<IVoyageLegViewModel>): Array<LocationId> {
  return uniq(
    legs
      .flatMap((leg) =>
        leg.inboundRoute.variants.flatMap((_) => _.waypoints.filter((waypoint) => waypoint.avoid))
      )
      .map((_) => _.locationId)
  );
}

const locationAdded = (cargoId, worksheetId) => async (dispatch, getState) => {
  const worksheet = getState().worksheetsById[worksheetId];
  const speedAndConsumption = worksheet.vessels[0].speedAndConsumptions;

  const leg = createNewEmptyLeg({
    cargoId: cargoId,
    /* As per #ComplexityOfAddingPointsToAvoidFromAllLegs */
    waypointsToAvoidLocationIds: getAllWaypointsToAvoidLocationIds(worksheet.voyage.legs),
    marketSegmentId: worksheet.marketSegmentId,
    speedAndConsumption,
  });
  await dispatch({
    type: VOYAGE_LOCATION_ADDED,
    payload: { leg },
    worksheetId,
  });
};

const locationsToAvoidChanged =
  ({
    allExcludeWaypointsToAttempt,
    waypointToToggleAvoidFlagOn,
    avoidSecaZones,
    marketSegmentId,
    worksheetId,
  }) =>
  async (dispatch, getState) => {
    /* TODO: #TODOFixUserSurprisedByLosingAvoidancePreferrenceAndComplexity :
      At the time of writing `locationsToAvoidChanged()` is probably the last hole through which a user can have legs with different waypoints with `avoid: true` (he adds all the locations he wants, and only then unchecks a waypoint - the waypoint with `avoid: true` will only be added to the voyage entry which had that waypoint). This can lead to surprising routes being calculated when he doesn't notice that upon removing some legs, his avoid preference gets lost (unexpected because for some leg it doesn't get lost).

      Some ideas:
      * add `waypointsToExclude` as an item of `waypoints` with `avoid: true` to all legs here (just like other places with #ComplexityOfAddingPointsToAvoidFromAllLegs). Doing this will have some performance overhead, in that every 'check the waypoint back to stop avoiding it' will run calculation on all the legs, but this is rather minimal increase on rare user actions, and not some massive fan-out.
      * or remove the need for all this complexity by addressing #TODORefactorAvoidedWaypointsOutOfLegs.
      * or #TODORefactorAvoidedWaypointsByAddingHasRequestedState - add a separate `hasRequestedToAvoid` property (or make `avoid` an enum with states of `requestedToAvoid` `avoidedOnThisRoute` `unavoidableOnThisRoute`) which records separately the information whether the waypoint was was used for avoidance, or just recorded. This would keep the model capable to 'per leg' avoidance preference, and still prevent the user's surprise. It's probably possible to have states of both #TODORefactorAvoidedWaypointsOutOfLegs and #TODORefactorAvoidedWaypointsByAddingHasRequestedState , but if the former is derivable from the latter then there's probably no point, as it would open up possibility of inconsistent state.
   */
    const newWaypointState = {
      locationId: waypointToToggleAvoidFlagOn.locationId,
      avoid: !waypointToToggleAvoidFlagOn.avoid,
    };

    /*
   The below `tryGetVoyageEntriesRoutesWithUpdatedVariants` is one place through which #WaypointsAvoidedInOnlySomeLegs can happen (via its call eventually doing #AddingPointsToAvoidToSingleVoyageEntry). Notably:
    * This one could be prevented by just adding the avoided waypoints to all the legs.
    * Unfortunately, there are other ways that are much harder to prevent. Essentially, a user can successfully avoid a waypoint on all legs, and only then add a new leg where that waypoint cannot be avoided (this is the same use case as the one described in [#36035](https://dev.azure.com/clarksonscloud/Voyage-Estimation/_workitems/edit/36035) & #TODOImplementConveyingOfUnsucccessfulDistanceCalc).
  */

    const resultsOfVoyageEntryRoutesTryGetUpdatedVariants: Array<ResultsOfVoyageEntryRoutesTryGetUpdatedVariants> =
      await dispatch(
        tryGetVoyageEntriesRoutesWithUpdatedVariants({
          predicateOfRouteVariantsToUpdate: ({ inboundRouteVariant }) =>
            inboundRouteVariant.waypoints.some(
              (legWaypoint) =>
                legWaypoint.locationId === waypointToToggleAvoidFlagOn.locationId &&
                legWaypoint.avoid !==
                  newWaypointState.avoid /* Also filtering out legs which already have the correct `avoid` flags to save on calls to the APIs - the situation can can happen when #WaypointsAvoidedInOnlySomeLegs */
            ),
          avoidSecaZones,
          marketSegmentId: marketSegmentId,
          waypointsToExclude: allExcludeWaypointsToAttempt,
          worksheetId,
        })
      );

    handleEachUnsuccessfulRouteResultTypeThrowIfAnyUnknown(
      /*allResults: */ resultsOfVoyageEntryRoutesTryGetUpdatedVariants,
      /* unsuccessfulResultHandlersMap */ new Map([
        [
          RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable,
          (
            unsuccessfulResultsDueToWaypointsUnavoidable: Array<
              ResultOfTryGetNewRouteVariantViewModel<CalculateInboundRouteVariantViewModelUnsuccessfulResult>,
            >
          ) => {
            const isUserRemovingWaypointToAvoid = newWaypointState.avoid === false;
            if (isUserRemovingWaypointToAvoid) {
              // This rare case means the user is fixing the situation by re-checking the waypoints to no longer to avoid them, but he has a few more to remove to get the route working. Let's throw the usual error for diagnostics:
              unhandledExceptionSinkSend(
                createWaypointPartiallyUnavoidableErrorCausedUnimplementedFeatureOfConveyingDistancesAreWrongToUser(
                  {
                    unsuccessfulResultsDueToWaypointsUnavoidable:
                      unsuccessfulResultsDueToWaypointsUnavoidable,
                  }
                )
              );
            } /*else, the user was trying to avoid a new waypoint */ else {
              /* When the user was trying to avoid the waypoint and got a 'no-success' for the first time, it's a case we have covered - this is where we prevent the user from avoiding so and mark the waypoint as 'unavoidable' in the leg.*/
              for (const unsuccessfulResultDueToWaypointsUnavoidable of unsuccessfulResultsDueToWaypointsUnavoidable) {
                unsuccessfulResultDueToWaypointsUnavoidable.itemOrUnsuccessfulResult = {
                  ...unsuccessfulResultDueToWaypointsUnavoidable.originalItem,
                  waypoints: [
                    ...iterableUtils.mapMatchingAndLeaveOthersErrorIfNoneFound(
                      /*sourceIterable: */ unsuccessfulResultDueToWaypointsUnavoidable.originalItem
                        .waypoints,
                      /*itemPredicate: */ (waypoint) =>
                        waypoint.locationId === waypointToToggleAvoidFlagOn.locationId,
                      /* getNewItem: */ (waypoint) => ({
                        ...waypoint,
                        unavoidable: true,
                      })
                    ),
                  ],
                };
              }
            }
          },
        ],
      ]),
      /* handleUnknownReasonsPresent: */ undefined /* Don't pass any `handleUnknownReasonsPresent` - we want to refrain from changes and log an exception, which nicely happens out-of-the-box if we don't pass anything here. */
    );

    await dispatch(
      applySuccessfulResultsOfVoyageEntryRoutesTryGetUpdatedVariants({
        resultsOfVoyageEntryRoutesTryGetUpdatedVariants:
          resultsOfVoyageEntryRoutesTryGetUpdatedVariants,
        worksheetId: worksheetId,
      })
    );
  };

const avoidSecaZonesChanged =
  ({ waypointsToExclude, newShouldAvoidSeca, marketSegmentId, worksheetId }) =>
  async (dispatch) => {
    await dispatch(
      tryUpdateRouteVariants({
        predicateOfRouteVariantsToUpdate: ({ inboundRouteVariant }) => true,
        shouldAvoidSeca: newShouldAvoidSeca,
        marketSegmentId: marketSegmentId,
        waypointsToExclude: waypointsToExclude,
        worksheetId: worksheetId,
      })
    );
    dispatch({
      worksheetId,
      type: VOYAGE_AVOID_SECA_ZONES_CHANGED,
      avoidSecaZones: newShouldAvoidSeca,
      marketSegmentId: marketSegmentId,
    });
  };

function voyageLegMoved({ sourceIndex, destinationIndex, worksheetId }) {
  return async (dispatch) => {
    dispatch({
      type: VOYAGE_LEG_MOVED,
      sourceIndex: sourceIndex,
      destinationIndex: destinationIndex,
      worksheetId: worksheetId,
    });
  };
}

const removeWaypointsAndCalculateLegsInVoyage =
  ({ shouldAvoidSeca, marketSegmentId, worksheetId }) =>
  async (dispatch) => {
    await dispatch(
      tryUpdateRouteVariants({
        predicateOfRouteVariantsToUpdate: ({ inboundRouteVariant, voyageEntryWithToLocation }) =>
          isValidSailingGeolocationsPair(
            inboundRouteVariant.fromLocationGeoCoords,
            Geolocation.fromObjectOrNullIfEmpty(voyageEntryWithToLocation)
          ),
        shouldAvoidSeca: shouldAvoidSeca,
        marketSegmentId: marketSegmentId,
        waypointsToExclude: [], //Ignore avoided waypoints as part of resetting the voyage
        worksheetId: worksheetId,
      })
    );
  };

const tryUpdateRouteVariants =
  ({
    predicateOfRouteVariantsToUpdate,
    shouldAvoidSeca,
    marketSegmentId,
    waypointsToExclude,
    worksheetId,
  }: {
    predicateOfRouteVariantsToUpdate: ({
      voyageEntryWithToLocation: IVoyageLegViewModel,
      inboundRouteVariant: InboundRouteVariantViewModel,
    }) => boolean,
    avoidSecaZones: boolean,
    marketSegmentId: string,
    waypointsToExclude: Array<IWaypointIdentity>,
    worksheetId: WorksheetId,
  }) =>
  async (dispatch) => {
    const resultsOfVoyageEntryRoutesTryGetUpdatedVariants: Array<ResultsOfVoyageEntryRoutesTryGetUpdatedVariants> =
      await dispatch(
        tryGetVoyageEntriesRoutesWithUpdatedVariants({
          predicateOfRouteVariantsToUpdate: predicateOfRouteVariantsToUpdate,
          avoidSecaZones: shouldAvoidSeca,
          marketSegmentId: marketSegmentId,
          waypointsToExclude: waypointsToExclude,
          worksheetId,
        })
      );

    handleEachUnsuccessfulRouteResultTypeThrowIfAnyUnknown(
      /*allResults: */ resultsOfVoyageEntryRoutesTryGetUpdatedVariants,
      /* unsuccessfulResultHandlersMap */ new Map([
        [
          RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable,
          (
            unsuccessfulResultsDueToWaypointsUnavoidable: Array<
              ResultOfTryGetNewRouteVariantViewModel<CalculateInboundRouteVariantViewModelUnsuccessfulResult>,
            >
          ) => {
            unhandledExceptionSinkSend(
              createWaypointPartiallyUnavoidableErrorCausedUnimplementedFeatureOfConveyingDistancesAreWrongToUser(
                {
                  unsuccessfulResultsDueToWaypointsUnavoidable:
                    unsuccessfulResultsDueToWaypointsUnavoidable,
                }
              )
            );
            for (const unsuccessfulResultDueToWaypointsUnavoidable of unsuccessfulResultsDueToWaypointsUnavoidable) {
              unsuccessfulResultDueToWaypointsUnavoidable.itemOrUnsuccessfulResult = {
                ...unsuccessfulResultDueToWaypointsUnavoidable.originalItem,
                recalculationAttemptedButFailedForReason:
                  unsuccessfulResultDueToWaypointsUnavoidable.itemOrUnsuccessfulResult
                    .reasonIsUnsuccessful,
              };
            }
          },
        ],
      ]),
      /* handleUnknownReasonsPresent: */ undefined /* Don't pass any `handleUnknownReasonsPresent` - we want to refrain from changes and log an exception, which nicely happens out-of-the-box if we don't pass anything here. */
    );

    await dispatch(
      applySuccessfulResultsOfVoyageEntryRoutesTryGetUpdatedVariants({
        resultsOfVoyageEntryRoutesTryGetUpdatedVariants:
          resultsOfVoyageEntryRoutesTryGetUpdatedVariants,
        worksheetId: worksheetId,
      })
    );
  };

function toggleAutoIntakeCalc(worksheetId) {
  return {
    type: VOYAGE_TOGGLE_AUTO_INTAKE_CALC,
    worksheetId: worksheetId,
  };
}

function setCalculationStatus(worksheetId) {
  return {
    type: SET_CALCULATIONS_STATUS,
    payload: CalculationStatus.LOADING,
    worksheetId: worksheetId,
  };
}

function voyageLegsLoaded(latestRoutesForLegs, worksheetId) {
  return {
    type: VOYAGE_LEGS_LOADED,
    payload: {
      latestRoutesForLegs,
    },
    worksheetId,
  };
}

export {
  avoidSecaZonesChanged,
  typeChanged,
  locationChanged,
  gearChanged,
  setPortsInSeca,
  draftChanged,
  draftUnitChanged,
  salinityChanged,
  cargoQuantityChanged,
  cargoQuantityChangedAllLegs,
  loadDischargeRateChanged,
  loadDischargeRateUnitChanged,
  workingDayMultiplierChanged,
  delayChanged,
  delayUnitChanged,
  delayChangedAllLegs,
  turnTimeChanged,
  turnTimeUnitChanged,
  weatherFactorChanged,
  portCostChanged,
  isInSecaChanged,
  isInEeaChanged,
  locationDeleted,
  locationAdded,
  locationsToAvoidChanged,
  voyageLegMoved,
  totalDistanceChanged,
  secaDistanceChanged,
  removeWaypointsAndCalculateLegsInVoyage,
  toggleAutoIntakeCalc,
  setCalculationStatus,
  voyageLegsLoaded,
  voyageTypeChangedUpdateDelayAllLegs,
};

//helper methods

const actionBuilder = (type, payload, portId, worksheetId) => {
  return { type, payload, portId, worksheetId };
};

export function createWaypointPartiallyUnavoidableErrorCausedUnimplementedFeatureOfConveyingDistancesAreWrongToUser({
  unsuccessfulResultsDueToWaypointsUnavoidable,
}) {
  return createErrorUnimplementedFeatureOfConveyingDistancesAreWrongToUser({
    causeDescriptionForErrorMessage:
      'A route recalculation did not succeed because the user previously selected certain "waypoints to avoid" but then changed the voyage locations that made these waypoints unavoidable (in this or previous user edits). See #UnsucccessfulDistanceCalc_DueTo_WaypointsAvoidedInOnlySomeLegs in the code for more info.',
    causeInfo: unsuccessfulResultsDueToWaypointsUnavoidable,
  });
}

// TODO - this will not be needed when we implement [#36035](https://dev.azure.com/clarksonscloud/Voyage-Estimation/_workitems/edit/36035)
export function createErrorUnimplementedFeatureOfConveyingDistancesAreWrongToUser({
  causeDescriptionForErrorMessage,
  causeInfo,
}: {
  causeDescriptionForErrorMessage: string,
  causeInfo: Error | mixed,
}) {
  return new VError(
    {
      name: 'NotImplementedError',
      ...(causeInfo instanceof Error
        ? {
            cause: causeInfo,
          }
        : { info: causeInfo }),
    },
    `There has been an error calculating the route (see this error's properties) which effectively breaks the worksheet, but the feature of conveying that to the user is unimplemented.${
      causeDescriptionForErrorMessage
        ? 'The reason for the error is\n\t:' + causeDescriptionForErrorMessage
        : ''
    }`
    /* This is #UnsucccessfulDistanceCalc_DueTo_WaypointsAvoidedInOnlySomeLegs : TODO implement some way of conveying that to the user as he is looking at wrong distances right now - see [#36035](https://dev.azure.com/clarksonscloud/Voyage-Estimation/_workitems/edit/36035) - #TODOImplementConveyingOfUnsucccessfulDistanceCalc - To address this one in a lossy "ignore invalid input" way, we would need to either have the UI not accept the location change (makes the app simpler, as it does not need to ever deal with "partially valid" worksheet data, but might be hard to implement and has a danger of user not noticing that his input was revered). To address it in an "accept invalid input and allow the user to fix" way, we would need to highlight that the distance is not correct (e.g. by removing the `distance` and `routeRetrievedFromRoutingApiOn` together with location change, and always raise an error when distance is not there, i.e. `routeRetrievedFromRoutingApiOn` not there or distance 0 while the leg from&to locations have different coordinates).*/
  );
}

export function isValidSailingGeolocationsPair(locationA: Geolocation, locationB: Geolocation) {
  return (
    Geolocation.isValid(locationA) &&
    Geolocation.isValid(locationB) &&
    !Geolocation.areEqual(locationA, locationB)
  );
}
