import {
  CARGO_CHANGED,
  CARGO_STOWAGE_CHANGED,
  CARGO_SPECIFIC_GRAVITY_CHANGED,
  CARGO_STOWAGE_UNIT_CHANGED,
  CARGO_GROSS_VOYAGE_RATE_CHANGED,
  CARGO_BROKER_COMMISSION_VOYAGE_RATE_CHANGED,
  CARGO_ADDRESS_COMMISSION_VOYAGE_RATE_CHANGED,
} from 'constants/action-types/worksheet/cargoes';

export type StandardCargoPayload<TPayload> = {
  type: string,
  payload: TPayload,
  cargoCode: string,
};

export type CargoUpdated = (payload: string, cargoCode: string) => StandardCargoPayload<string>;

export type StowageUpdated = (payload: string, cargoCode: string) => StandardCargoPayload<number>;

export type SpecificGravityUpdated = (
  payload: string,
  cargoCode: string
) => StandardCargoPayload<number>;

export type StowageUnitUpdated = (
  payload: string,
  cargoCode: string
) => StandardCargoPayload<number>;

export type GrossVoayageRateUpdated = (
  payload: string,
  cargoCode: string
) => StandardCargoPayload<number>;

export type BrokerCommissionVoyageRateUpdated = (
  payload: string,
  cargoCode: string
) => StandardCargoPayload<number>;

export type AddressCommissionVoyageRateUpdated = (
  payload: string,
  cargoCode: string
) => StandardCargoPayload<number>;

export function cargoUpdated(
  payload: string,
  cargoCode: string,
  worksheetId
): StandardCargoPayload<string> {
  return {
    worksheetId,
    type: CARGO_CHANGED,
    payload: payload,
    cargoCode: cargoCode,
  };
}

export function specificGravityUpdated(
  payload: number,
  cargoCode: string,
  worksheetId
): StandardCargoPayload<number> {
  return {
    worksheetId,
    type: CARGO_SPECIFIC_GRAVITY_CHANGED,
    payload: payload,
    cargoCode: cargoCode,
  };
}

export function stowageUpdated(
  payload: number,
  cargoCode: string,
  worksheetId
): StandardCargoPayload<number> {
  return {
    worksheetId,
    type: CARGO_STOWAGE_CHANGED,
    payload: payload,
    cargoCode: cargoCode,
  };
}

export function stowageUnitUpdated(
  payload: {
    stowage: number,
    stowageUnit: StowageUnit,
  },
  cargoCode: string,
  worksheetId
) {
  return {
    worksheetId,
    type: CARGO_STOWAGE_UNIT_CHANGED,
    payload: { ...payload, stowageUnit: payload.stowageUnit.key },
    cargoCode: cargoCode,
  };
}

export function grossVoyageRateUpdated(
  payload: number,
  cargoCode: string,
  worksheetId
): StandardCargoPayload<number> {
  return {
    worksheetId,
    type: CARGO_GROSS_VOYAGE_RATE_CHANGED,
    payload: payload,
    cargoCode: cargoCode,
  };
}

export function brokerCommissionVoyageRateUpdated(
  payload: number,
  cargoCode: string,
  worksheetId
): StandardCargoPayload<number> {
  return {
    worksheetId,
    type: CARGO_BROKER_COMMISSION_VOYAGE_RATE_CHANGED,
    payload: payload,
    cargoCode: cargoCode,
  };
}

export function addressCommissionVoyageRateUpdated(
  payload: number,
  cargoCode: string,
  worksheetId
): StandardCargoPayload<number> {
  return {
    worksheetId,
    type: CARGO_ADDRESS_COMMISSION_VOYAGE_RATE_CHANGED,
    payload: payload,
    cargoCode: cargoCode,
  };
}

export function setCargoFromDto(cargoTypeDto: CargoType, cargoCode, worksheetId) {
  return async (dispatch) => {
    await dispatch(
      cargoUpdated(
        { cargoTypeId: cargoTypeDto.cargoTypeId, name: cargoTypeDto.name },
        cargoCode,
        worksheetId
      )
    );

    if (cargoTypeDto.stowage !== undefined) {
      await dispatch(
        stowageUnitUpdated(
          {
            stowage: cargoTypeDto.stowage,
            stowageUnit: cargoTypeDto.stowageUnit,
          },
          cargoCode,
          worksheetId
        )
      );
    }
  };
}
