import classNames from 'classnames';
import numbro from 'numbro';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showCarbonFactorTableModal } from '../../actions/carbon';
import {
  euaPriceUpdated,
  euaYearUpdated,
  fetchEuaPriceAndSyncStore,
  includeInCostsChanged,
} from '../../actions/worksheet/eua';
import VerticalDivider from '../../components/divider/vertical';
import { Header } from '../../components/headers';
import LinkButton from '../../components/link-button';
import { selector } from './selector';

import { IconButton } from 'components/button';
import CalcInput from 'components/calc-input';
import { IconTooltip } from 'components/icon-tooltip';
import { OpenPopupIcon, iconEnum } from 'components/icons';
import { isFeatureEnabled } from 'config/feature-control';
import { format, toDate } from 'date-fns';
import isNil from 'lodash/isNil';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import userContext from 'user-context';
import { getInboundRouteVariant } from 'utilities/functions/get-inbound-route-variant';
import Toggle from '../../components/toggle';
import { getVoyageLegTypeById } from '../../constants/enums/voyage-leg';
import { isTanker } from '../../constants/market-segments';
import { trackChangeEvent, trackClickEvent } from '../../diagnostics/calc-trackevents';
import './styles.scss';

const PHASE_IN_COST_YEARS = [
  { text: 'Your Voyage', year: null },
  { text: '2024', year: 2024 },
  { text: '2025', year: 2025 },
  { text: '2026', year: 2026 },
];

const EMISSION_AREA = {
  inPort: 0,
  atSea: 1,
};

export const Eua: FC = ({
  marketSegmentId,
  worksheetId,
  includeInCosts,
  euaPrice,
  euaYear,
  voyageLegs,
  openDate,
  carbonBreakdown,
  isCrslEuaPrice,
  euaPriceUpdatedDate,
  euaPriceUpdated,
  euaYearUpdated,
  includeInCostsChanged,
  fetchEuaPriceAndSyncStore,
  showCarbonFactorTableModal,
  isInSummaryPanel = false,
  activeVessel,
  isRefinitiv,
}) => {
  useEffect(() => {
    if (!isInSummaryPanel) {
      euaYearUpdated(null, worksheetId);
    }
  }, [openDate]);

  const buildPhaseInCostScenarios = () => (
    <div className="carbon-breakdown-header--eua-years-container">
      <div>Phase in cost scenarios</div>
      <div className="carbon-breakdown-header--eua-years-selector">
        {PHASE_IN_COST_YEARS.map((item) => (
          <div
            key={item.text}
            className="carbon-breakdown-header--eua-year"
            onClick={() => {
              trackClickCostScenariosEvent(item.year);
              euaYearUpdated(item.year, worksheetId);
            }}
            data-is-selected={item.year === euaYear}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );

  const trackClickCostScenariosEvent = (selectedYear) => {
    // Find the corresponding URL based on the selected year
    const selectedYearObject = PHASE_IN_COST_YEARS.find(
      (yearObject) => yearObject.year === selectedYear
    );

    if (selectedYearObject) {
      const diagnosticId = selectedYearObject.year
        ? `eua/start-year-${selectedYearObject.year}`
        : 'eua/start-year-your-voyage';

      trackClickEvent(diagnosticId);
    }
  };

  const buildCarbonBreakdownTable = () => (
    <div
      className="carbon-breakdown"
      style={{
        gridTemplateColumns: !isInSummaryPanel
          ? `45px 90px 90px 90px 90px 90px 110px ${carbonBreakdown.euaYears
              .map(() => '100px 110px')
              .join(' ')} 100px 110px`
          : '',
      }}
    >
      {buildFirstHeaderLine()}
      {buildSecondHeaderLine()}
      {carbonBreakdown.legs.map(buildLegLine)}
      {buildFooterLine()}
    </div>
  );

  const buildSummaryPanelCarbonBreakdownTable = () => (
    <div className="carbon-breakdown">
      {buildFirstHeaderLineSummaryPanel()}
      {buildSecondHeaderLine(true)}
      {carbonBreakdown.legs.map(buildLegLine)}
      {buildFooterLine(true)}
    </div>
  );

  const wetLiteHeaderClass = isTanker(marketSegmentId) ? 'header-lite' : '';
  const wetLiteRightBorderClass = isTanker(marketSegmentId) ? 'right-border-lite' : '';
  const wetLiteTopBorderClass = isTanker(marketSegmentId) ? 'footer-lite' : '';

  const buildFirstHeaderLine = () => {
    return (
      <>
        <div
          className={`carbon-breakdown--right-border carbon-breakdown--first-header ${wetLiteHeaderClass} ${wetLiteRightBorderClass}`}
        >
          Leg
        </div>
        <div
          className={`carbon-breakdown--right-border carbon-breakdown--first-header ${wetLiteHeaderClass} ${wetLiteRightBorderClass} carbon-breakdown--rowspan-3`}
        >
          CO
          <sub>2</sub> Emissions (MT)
        </div>
        <div
          className={`carbon-breakdown--right-border carbon-breakdown--first-header ${wetLiteHeaderClass} ${wetLiteRightBorderClass} carbon-breakdown--rowspan-3`}
        >
          EUAs
        </div>
        {carbonBreakdown.euaYears.map((item, index) => (
          <div
            key={item.year}
            className={`carbon-breakdown--right-border carbon-breakdown--first-header ${wetLiteHeaderClass} ${wetLiteRightBorderClass} carbon-breakdown--rowspan-2`}
          >
            {item.year} @ {item.euaPercentage * 100}%
          </div>
        ))}
        <div
          className={`carbon-breakdown--first-header ${wetLiteHeaderClass} carbon-breakdown--rowspan-2`}
        >
          Carbon Cost
        </div>
      </>
    );
  };

  const buildFirstHeaderLineSummaryPanel = () => (
    <>
      <div className="carbon-breakdown--right-border carbon-breakdown--first-header-summaryPanel">
        Leg
      </div>
      <div className="carbon-breakdown--right-border carbon-breakdown--first-header-summaryPanel carbon-breakdown--rowspan-3">
        CO₂ Emissions (MT)
      </div>
      <div className="carbon-breakdown--first-header-summaryPanel carbon-breakdown--rowspan-2">
        Carbon Cost
      </div>
    </>
  );

  const buildSecondHeaderLine = (isSummaryPanel: boolean) => {
    return (
      <>
        <div className={`carbon-breakdown--right-border ${wetLiteRightBorderClass}`} />
        <div className="carbon-breakdown--second-header">at Sea</div>
        <div className="carbon-breakdown--second-header">in Port</div>
        <div
          className={`carbon-breakdown--right-border ${wetLiteRightBorderClass} carbon-breakdown--second-header`}
        >
          Total
        </div>
        {!isSummaryPanel && (
          <>
            <div className="carbon-breakdown--second-header">at Sea (%)</div>
            <div className="carbon-breakdown--second-header">in Port (%)</div>
            <div
              className={`carbon-breakdown--right-border ${wetLiteRightBorderClass} carbon-breakdown--second-header`}
            >
              Total EUAs
            </div>
            {carbonBreakdown.euaYears.map((item, index) => (
              <React.Fragment key={item.year}>
                <div className="carbon-breakdown--second-header">
                  EUAs {item.euaPercentage * 100}%
                </div>
                <div
                  className={`carbon-breakdown--right-border ${wetLiteRightBorderClass} carbon-breakdown--second-header`}
                >
                  Total ($)
                </div>
              </React.Fragment>
            ))}
          </>
        )}
        <div className="carbon-breakdown--second-header">EUAs Due</div>
        <div className="carbon-breakdown--second-header">Total ($)</div>
      </>
    );
  };

  const buildLegLine = (leg, index) => {
    if (!voyageLegs[index]) return <></>;
    const baseClassNames = classNames({
      'carbon-breakdown--row-even': index % 2 === 0 && !isInSummaryPanel,
      'carbon-breakdown--row-even-summary-panel': index % 2 === 0 && isInSummaryPanel,
    });
    const classNamesRightBorder = classNames(
      baseClassNames,
      'carbon-breakdown--right-border',
      wetLiteRightBorderClass
    );

    const legTooltipContent = () => {
      const openPositionName = activeVessel?.openPosition?.name;
      const { name: currentPortName, type: currentLegType } = voyageLegs[index] || {};
      const { name: previousPortName, type: previousLegType } = voyageLegs[index - 1] || {};

      const previousLocation =
        index === 0
          ? openPositionName || 'Open Location'
          : previousPortName || `${getVoyageLegTypeById(previousLegType).label} Location`;

      const currentLocation =
        currentPortName || `${getVoyageLegTypeById(currentLegType).label} Location`;

      const distanceValue = getInboundRouteVariant(voyageLegs[index], activeVessel).totalDistance;

      const distance =
        (index === 0 && (!openPositionName || !currentPortName)) ||
        (index !== 0 && (!previousPortName || !currentPortName))
          ? 'unknown distance'
          : `${numbro(distanceValue).format({ thousandSeparated: true })} nm`;

      return `${previousLocation} ➔ ${currentLocation} (${distance})`;
    };

    return (
      <React.Fragment key={index}>
        <div
          className={classNamesRightBorder}
          data-tooltip-content={legTooltipContent()}
          data-tooltip-id={`leg-tooltip-${isInSummaryPanel}`}
        >
          <ReactTooltip
            id={`leg-tooltip-${isInSummaryPanel}`}
            className="ve-tooltip-default"
            noArrow
          />
          {index + 1}
        </div>
        <div className={baseClassNames}>
          {formatNumber(getEntryByArea(leg, EMISSION_AREA.atSea).emissionsMT)}
        </div>
        <div className={baseClassNames}>
          {formatNumber(getEntryByArea(leg, EMISSION_AREA.inPort).emissionsMT)}
        </div>
        <div className={classNamesRightBorder}>{formatNumber(leg.summary.emissionsMT)}</div>
        {!isInSummaryPanel && (
          <>
            <div className={baseClassNames}>
              {getEntryByArea(leg, EMISSION_AREA.atSea).euaFactor * 100}
            </div>
            <div className={baseClassNames}>
              {getEntryByArea(leg, EMISSION_AREA.inPort).euaFactor * 100}
            </div>
            <div className={classNamesRightBorder}>{formatNumber(leg.summary.requiredEuas)}</div>
            {carbonBreakdown.euaYears.map((item, index) => (
              <React.Fragment key={item.year}>
                <div className={baseClassNames}>
                  {formatNumber(getEntryByYear(leg, item.year)?.euasDue)}
                </div>
                <div className={classNamesRightBorder}>
                  {formatNumber(getEntryByYear(leg, item.year)?.emissionsEuaCost)}
                </div>
              </React.Fragment>
            ))}
          </>
        )}
        <div
          className={classNames(!isInSummaryPanel ? `carbon-breakdown--total` : ``, baseClassNames)}
        >
          {!isInSummaryPanel ? formatNumber(getEuasDue(leg)) : formatNumber(leg.totalEuasDue)}
        </div>
        <div
          className={classNames(!isInSummaryPanel ? `carbon-breakdown--total` : ``, baseClassNames)}
        >
          {!isInSummaryPanel
            ? formatNumber(leg.summary.emissionsEuaCost)
            : formatNumber(leg.emissionsEuaCostByVoyage)}
        </div>
      </React.Fragment>
    );
  };

  const buildFooterLine = (isSummaryPanel: boolean) => {
    const summaryPanelSuffix = isInSummaryPanel ? '-summaryPanel' : '';
    const footerClassNames = 'carbon-breakdown--footer' + summaryPanelSuffix;
    const totalClassNames = 'carbon-breakdown--total' + summaryPanelSuffix;
    return (
      <>
        <div
          className={classNames(
            'carbon-breakdown--right-border',
            wetLiteRightBorderClass,
            isSummaryPanel ? 'carbon-breakdown--footer-summaryPanel' : 'carbon-breakdown--footer',
            wetLiteTopBorderClass
          )}
        />
        <div className={classNames(footerClassNames, wetLiteTopBorderClass)}>
          {formatNumber(getEmissionsAmountByArea(EMISSION_AREA.atSea))}
        </div>
        <div className={classNames(footerClassNames, wetLiteTopBorderClass)}>
          {formatNumber(getEmissionsAmountByArea(EMISSION_AREA.inPort))}
        </div>
        <div
          className={classNames(
            'carbon-breakdown--right-border',
            wetLiteRightBorderClass,
            footerClassNames,
            totalClassNames,
            wetLiteTopBorderClass
          )}
        >
          {formatNumber(carbonBreakdown.summary.emissionsMT)}
        </div>
        {!isSummaryPanel && (
          <>
            <div className={`carbon-breakdown--footer ${wetLiteTopBorderClass}`} />
            <div className={`carbon-breakdown--footer ${wetLiteTopBorderClass}`} />
            <div
              className={classNames(
                'carbon-breakdown--right-border',
                wetLiteRightBorderClass,
                'carbon-breakdown--footer',
                totalClassNames,
                wetLiteTopBorderClass
              )}
            >
              {formatNumber(carbonBreakdown.summary.requiredEuas)}
            </div>
            {carbonBreakdown.euaYears.map((item, index) => (
              <React.Fragment key={item.year}>
                <div className={`carbon-breakdown--footer ${wetLiteTopBorderClass}`} />
                <div
                  className={classNames(
                    'carbon-breakdown--footer',
                    wetLiteTopBorderClass,
                    index === carbonBreakdown.euaYears.length - 1
                      ? `carbon-breakdown--right-border ${wetLiteRightBorderClass}`
                      : ''
                  )}
                />
              </React.Fragment>
            ))}
          </>
        )}
        <div className={classNames(footerClassNames, totalClassNames, wetLiteTopBorderClass)}>
          {formatNumber(!isSummaryPanel ? getEuasDueTotal : getEuasDueTotalSummaryPanel)}
        </div>
        <div className={classNames(footerClassNames, totalClassNames, wetLiteTopBorderClass)}>
          {formatNumber(
            !isSummaryPanel
              ? carbonBreakdown.summary.emissionsEuaCost
              : carbonBreakdown.totalVoyageCost
          )}
        </div>
      </>
    );
  };

  const getEmissionsAmountByArea = (area) =>
    carbonBreakdown.legs
      .map((leg) => getEntryByArea(leg, area).emissionsMT)
      .reduce((acc, curr) => acc + curr, 0);

  const getEntryByArea = (leg, area) => leg.byArea.find((item) => item.emissionsArea === area);

  const getEntryByYear = (leg, year) => leg.byYear.find((item) => item.year === year);

  const getEuasDue = (leg) =>
    leg.byYear.map((year) => year.euasDue).reduce((acc, curr) => acc + curr, 0);

  const getEuasDueTotal = carbonBreakdown?.legs
    .map((leg) => getEuasDue(leg))
    .reduce((acc, curr) => acc + curr, 0);

  const getEuasDueTotalSummaryPanel = carbonBreakdown?.legs
    .map((leg) => leg.totalEuasDue)
    .reduce((acc, curr) => acc + curr, 0);

  const formatNumber = (number) => {
    if (number === null || number === undefined) {
      return '-';
    }
    return number < 1000
      ? numbro(number).format({ thousandSeparated: true, mantissa: 2 })
      : numbro(Math.round(number)).format({ thousandSeparated: true });
  };

  const onToggleIncludeInCostsHandler = (toggleState) => {
    includeInCostsChanged(toggleState, worksheetId);
    trackChangeEvent('eua/include-in-costs', {
      oldValue: includeInCosts,
      newValue: toggleState,
    });
  };

  const refreshEuaPrice = async () => {
    await fetchEuaPriceAndSyncStore(worksheetId);
  };

  const formatDateTime = (date: string): string =>
    isNil(date) ? '' : format(toDate(date), "dd MMM yy 'at' HH:mm:ss");

  const EuaPriceTooltipText = () => {
    const formattedEuaPriceUpdatedDate = formatDateTime(euaPriceUpdatedDate);
    const dateElement = <strong>{formattedEuaPriceUpdatedDate}</strong>;

    const crslEuaPriceContent = (
      <span>
        Issued on: {dateElement} from industry sources. <br />
        <br />
        EUA price is a weekly estimate of the nearest December futures price, compiled from a range
        of industry sources, expressed in $/tonne.
      </span>
    );

    const refinifivEuaPriceContent = (
      <span>
        Issued on: {dateElement} Data source: LSEG <br />
        <br />
        EUA price represents the closing value from the previous business day, measured in $ per
        tonne.
      </span>
    );

    const manualUpdateContent = <span>Manually updated on: {dateElement}</span>;
    const euaPriceNotEntered = <span>Enter an EUA price to calculate the Carbon Cost.</span>;
    const euaPriceEnteredButNoUpdateDate = <span>Refresh for latest EUA price.</span>;
    const hasNoDateOfLastUpdateOfEuaPrice = !euaPriceUpdatedDate;
    const hasNoEuaPrice = !euaPrice;
    let resultText = <span>""</span>;
    const shouldDisplayRefinitivContent =
      userContext._userInfo.isInHouseCompany &&
      isFeatureEnabled('refinitivEuaPrices') &&
      isRefinitiv;

    if (isCrslEuaPrice) {
      resultText = shouldDisplayRefinitivContent ? refinifivEuaPriceContent : crslEuaPriceContent;
    } else if (hasNoDateOfLastUpdateOfEuaPrice) {
      resultText = hasNoEuaPrice ? euaPriceNotEntered : euaPriceEnteredButNoUpdateDate;
    } else {
      resultText = manualUpdateContent;
    }

    return resultText;
  };

  return (
    <>
      <div>
        {!isInSummaryPanel ? (
          <>
            <div className="carbon-breakdown-header--container">
              <Header text="EUA Carbon Breakdown" className="carbon-breakdown-title" />
              <VerticalDivider className="carbon-breakdown-header--divider" />
              <LinkButton
                onClick={() => {
                  showCarbonFactorTableModal('EUACarbonBreakdown');
                }}
                diagnosticId="EUACarbonBreakdown/carbon-factors"
              >
                <span className="carbon-breakdown-header--carbon-factors-link">
                  Carbon Factors Reference
                  <OpenPopupIcon className="carbon-breakdown-header--open-popup-icon" />
                </span>
              </LinkButton>
              {!isTanker(marketSegmentId) && (
                <>
                  <div className="carbon-breakdown-header-right">
                    <Toggle
                      id="include-in-costs"
                      label="Include in Costs"
                      checked={includeInCosts}
                      onChange={onToggleIncludeInCostsHandler}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="carbon-breakdown-header">
              <div className="worksheet-section__property eua-price-property">
                <span className="eua-price-label">
                  <label>EUA Price ($)</label>
                  <IconTooltip icon={iconEnum.InfoOutline} className="iconTooltip-eua">
                    <div className="iconTooltip-eua-text">{EuaPriceTooltipText()}</div>
                  </IconTooltip>
                </span>
                <span className="carbon-breakdown-price">
                  {isTanker(marketSegmentId) ? (
                    <CalcInput
                      systemSpecifiedValue={euaPrice}
                      className="eua-price"
                      diagnosticId="eua/price"
                      maxDecimalDigits={2}
                      trimDecimal={false}
                      onInputChange={(newValue) =>
                        euaPriceUpdated(
                          { euaPrice: newValue, isCrslEuaPrice: false, isRefinitiv: false },
                          worksheetId
                        )
                      }
                      name="EUA"
                      isMandatory
                      marketSegmentId={marketSegmentId}
                      showEditButton
                      isDefaultValue={!isCrslEuaPrice}
                      getDefaultValue={async () => {
                        await refreshEuaPrice();
                      }}
                    />
                  ) : (
                    <CalcInput
                      userSpecifiedValue={euaPrice}
                      className="eua-price"
                      diagnosticId="eua/price"
                      isEditable
                      maxDecimalDigits={2}
                      trimDecimal={false}
                      onInputChange={(newValue) =>
                        euaPriceUpdated(
                          { euaPrice: newValue, isCrslEuaPrice: false, isRefinitiv: false },
                          worksheetId
                        )
                      }
                      name="EUA"
                      isMandatory
                      marketSegmentId={marketSegmentId}
                    />
                  )}
                  <IconButton
                    icon={iconEnum.Refresh}
                    className="refresh-btn"
                    onClick={async () => {
                      await refreshEuaPrice();
                    }}
                    diagnosticId="EuaCarbonBreakdown/RefreshEuaPrice"
                  />
                </span>
              </div>
              {buildPhaseInCostScenarios()}
            </div>
            {carbonBreakdown && buildCarbonBreakdownTable()}
          </>
        ) : (
          <> {carbonBreakdown && buildSummaryPanelCarbonBreakdownTable()} </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      euaPriceUpdated,
      euaYearUpdated,
      fetchEuaPriceAndSyncStore,
      showCarbonFactorTableModal,
      includeInCostsChanged,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Eua);
