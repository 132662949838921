import React from 'react';
import { Portal, PortalContent } from 'components/portals';

export function PrimaryNavPortal({ children }) {
  return <Portal name="primary-nav-content">{children}</Portal>;
}

export function PrimaryNavPortalContent({ children }) {
  return <PortalContent name="primary-nav-content">{children}</PortalContent>;
}
