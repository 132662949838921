import React from 'react';

import classNames from 'classnames';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

import './styles.scss';

export const Grid = ({ className, ...rest }: Components.IGridProps) => {
  const classes = classNames(className);

  return (
    <div className={classes}>
      <AgGridReact modules={[ClientSideRowModelModule]} {...rest} />
    </div>
  );
};
