import { getActiveWorksheet } from './get-active-worksheet';
import { createStructuredSelectorFromBaseSelector } from 'utilities/redux/create-structured-selector-from-base-selector';

/**
  It can be especially used for optimization instead of the full `getActiveWorksheet`, where the reduced data is all that a component needs (it will keep returning the same instance regardless of Redux creating a new instance due to changes to other properties).
 */
export const getActiveWorksheetInvariantProps: Selector<IAppState, IWorksheetInvariantProps> =
  createStructuredSelectorFromBaseSelector(getActiveWorksheet, {
    id: (worksheet) => worksheet && worksheet.id,
    marketSegmentId: (worksheet) => worksheet && worksheet.marketSegmentId,
  });
