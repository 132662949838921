import { REFERENCE_DATA_BALTIC_TANKER_INDEX_VESSELS_LOADED } from 'constants/action-types/reference-data';

const initialState = [];

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case REFERENCE_DATA_BALTIC_TANKER_INDEX_VESSELS_LOADED:
      return action.payload;
    default:
      return state;
  }
};
