import {
  SET_BUNKER_PRICES,
  APPLY_PRICES_TO_BUNKERS_ON_BOARD,
  SET_BUNKER_PRICES_PANEL_READONLY,
} from 'constants/action-types/bunker-ports';
import { buildAction } from 'utilities/redux';
import { setStageLeftAs } from 'actions/stage';
import { BUNKER_PORTS_PANEL } from 'constants/enums/stage-panels';
import { emptyGuid } from 'utilities/guid';
import { bunkersOnBoardId } from 'constants/enums/bunkers';
import * as exceptionSink from 'diagnostics/unhandled-exception-sink';
import {
  FUEL_GRADE_380CST,
  FUEL_GRADE_180CST,
  FUEL_GRADE_LSMGO,
  FUEL_GRADE_MGO,
  FUEL_GRADE_MDO,
  FUEL_GRADE_ULSFO,
  FUEL_GRADE_VLSFO,
  FUEL_GRADE_LNG,
} from 'constants/enums/fuel-grades';
import { SET_WORKSHEET_BUNKER_PRICES } from 'constants/action-types/worksheet/bunkers';
import { fetchAllBunkerPrices } from 'api/clients/bunker-price';

export const fetchAllBunkerPricesAndSyncStore = () => async (dispatch) => {
  const bunkerPrices = await fetchAllBunkerPrices();
  dispatch(buildAction(SET_BUNKER_PRICES, bunkerPrices));

  return bunkerPrices;
};

export const showBunkerPortsTable = (isBunkerPricesPanelReadOnly) => async (dispatch) => {
  try {
    await dispatch(fetchAllBunkerPricesAndSyncStore());
  } finally {
    dispatch(setBunkerPricesPanelReadOnly(isBunkerPricesPanelReadOnly));
    dispatch(setStageLeftAs(BUNKER_PORTS_PANEL));
  }
};

export const refreshBunkersForWorksheet =
  (
    worksheetId: WorksheetId,
    bunkerPrices: Array<BunkerImportedPricesItem> | undefined,
    forceRefresh: boolean = false /** Force refresh is useful for comparison retries to force triggering the auto-save middleware */
  ) =>
  async (dispatch, getState) => {
    const state = getState();
    const worksheet = state.worksheetsById[worksheetId];

    if (!bunkerPrices) {
      bunkerPrices = await dispatch(fetchAllBunkerPricesAndSyncStore());
    }

    const updatedBunkers = await Promise.all(
      worksheet.bunkerExpenses.bunkers.map(
        async (bunkerPort) =>
          await dispatch(
            getBunkerWithUpdatedPrices({
              worksheetId: worksheetId,
              bunkerPort: bunkerPort,
              bunkerPrices: bunkerPrices,
            })
          )
      )
    );
    if (forceRefresh || hasBunkersBeenUpdated(worksheet.bunkerExpenses.bunkers, updatedBunkers)) {
      dispatch({
        type: SET_WORKSHEET_BUNKER_PRICES,
        payload: [...updatedBunkers],
        worksheetId: worksheet.id,
      });
    }

    function hasBunkersBeenUpdated(bunkers, updatedBunkers) {
      return updatedBunkers.some((bunker, index) => bunker !== bunkers[index]);
    }
  };

export const updateBunkerPrice =
  ({ worksheetId, bunkerPort }: { worksheetId: WorksheetId, bunkerPort: IBunkersViewModel }) =>
  async (dispatch, getState) => {
    const state = getState();

    const worksheet = state.worksheetsById[worksheetId];
    const bunkerWithUpdatedPrices = await dispatch(
      getBunkerWithUpdatedPrices({
        worksheetId: worksheetId,
        bunkerPort: bunkerPort,
      })
    );
    dispatch({
      type: SET_WORKSHEET_BUNKER_PRICES,
      payload: worksheet.bunkerExpenses.bunkers.map((b) =>
        b !== bunkerPort ? b : bunkerWithUpdatedPrices
      ),
      worksheetId: worksheet.id,
    });
  };

/**
 @returns an updated `bunkerPort` if there is price information, or the unchanged `bunkerPort` itself, if there isn't.
 */
const getBunkerWithUpdatedPrices =
  ({
    worksheetId,
    bunkerPort,
    bunkerPrices,
  }: {
    worksheetId: WorksheetId,
    bunkerPort: IBunkersViewModel,
    bunkerPrices: Array<BunkerImportedPricesItem> | undefined,
  }) =>
  async (dispatch, getState): IBunkersViewModel => {
    const state = getState();
    const worksheet = state.worksheetsById[worksheetId];

    bunkerPrices = bunkerPrices || (await dispatch(fetchAllBunkerPricesAndSyncStore()));

    const locationId = getLocationId(bunkerPort, worksheet.voyage.legs);

    const bunkerPriceForItem = bunkerPrices.find((b) => b.locationId === locationId);

    return getBunkerWithUpdatedPricesPrivate(bunkerPort, bunkerPriceForItem);

    function getBunkerWithUpdatedPricesPrivate(
      bunkerOnWorksheet: IBunkersViewModel,
      bunkerPrice: BunkerImportedPricesItem | undefined
    ): IBunkersViewModel {
      // #PreventUpdatingPriceOnNoImportedPriceOrForBOB
      // We don't want to update when no imported bunker prices, or if it is BOB (as BOB will have a locationId when prices have not been manually updated)
      if (!bunkerPrice || bunkerOnWorksheet.key === bunkersOnBoardId) {
        return bunkerOnWorksheet;
      }

      const { fuelGrades } = bunkerOnWorksheet;

      return {
        ...bunkerOnWorksheet,
        priceIssuedDate: bunkerPrice.dateIssued,
        fuelGrades: fuelGrades.map((fuelGrade) => ({
          ...fuelGrade,
          price: getPriceForFuelGrade(fuelGrade.key, fuelGrade.price, bunkerPrice),
        })),
      };

      function getPriceForFuelGrade(fuelGradeKey, fuelGradePrice, bunkerPrice) {
        const bunkerPriceKey = getBunkerPriceKeyByFuelGradeKey(fuelGradeKey);
        return bunkerPrice[bunkerPriceKey] > 0 ? bunkerPrice[bunkerPriceKey] : fuelGradePrice;
      }

      function getBunkerPriceKeyByFuelGradeKey(fuelGradeKey) {
        const fuelGradeToBunkerPriceMap = {
          [FUEL_GRADE_180CST.key]: 'cst180Price',
          [FUEL_GRADE_380CST.key]: 'cst380Price',
          [FUEL_GRADE_MGO.key]: 'mgoPrice',
          [FUEL_GRADE_MDO.key]: 'mdoPrice',
          [FUEL_GRADE_LSMGO.key]: 'lsMgoPrice',
          [FUEL_GRADE_ULSFO.key]: 'ulsfoOneTenthPercPrice',
          [FUEL_GRADE_VLSFO.key]: 'vlsfoFiveTenthPercPrice',
          [FUEL_GRADE_LNG.key]: 'lngPrice',
        };

        return fuelGradeToBunkerPriceMap[fuelGradeKey];
      }
    }
  };

export const refreshBunkersForWorksheets =
  (worksheets: IWorksheetViewModel[]) => async (dispatch) => {
    try {
      const latestPrices = await dispatch(fetchAllBunkerPricesAndSyncStore());

      worksheets.forEach((worksheet) =>
        dispatch(refreshBunkersForWorksheet(worksheet.id, latestPrices))
      );
    } catch (err) {
      exceptionSink.send(err);
    }
  };

export const getLocationId = (bunker: IBunkersViewModel, voyageLegs: Array): string => {
  if (bunker.key === bunkersOnBoardId) {
    return bunker.locationId;
  } else {
    // Voyage leg `id` is equal to bunker `key` for all bunkers except for bunkers on board
    const bunkerLocationId = voyageLegs.find((leg) => leg.id === bunker.key).locationId;

    if (bunkerLocationId !== emptyGuid) return bunkerLocationId;
    // When there is a bunker added, but no location provided
    else return null;
  }
};

export const applyPricesToBunkersOnBoard = (payload, worksheetId) => ({
  payload,
  worksheetId,
  type: APPLY_PRICES_TO_BUNKERS_ON_BOARD,
});

export const setBunkerPricesPanelReadOnly = (payload) => async (dispatch) => {
  dispatch(buildAction(SET_BUNKER_PRICES_PANEL_READONLY, payload));
};
