import React, { useState } from 'react';
import './styles.scss';
import classNames from 'classnames';
import type { BunkersItineraryTotals } from 'modules/bunkers/business-model/bunkers-itinerary-totals';
import type { BunkerSourceDetailsByFuel } from 'modules/bunkers/business-model/bunkers-itinerary-source-details';
import ExpandCollapseButton from 'components/expand-collapse/expand-collapse-button';
import { formatMoneyOrNullAsEmpty } from 'utilities/number/units-precisions/money';
import { formatFuelMtOrNullAsEmpty } from 'utilities/number/units-precisions/fuel-mt';
import { getFuelGradeByCode } from 'constants/enums/fuel-grades';
import { NumericRangeDisplayWithCustomRender } from 'components/numeric-range-display/numeric-range-display-with-custom-render';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { bunkersOnBoardId } from 'constants/enums/bunkers';

export const BunkersItineraryView = ({
  bunkersItineraryTotals,
  bunkersItinerarySourceDetails,
  className,
  ...restProps
}: {
  bunkersItineraryTotals: BunkersItineraryTotals | null,
  bunkersItinerarySourceDetails: Array<BunkerSourceDetailsByFuel> | null,
}) => {
  let [isliftingExpanded, setIsLiftingExpanded] = useState(false);
  let [isEndedExpanded, setIsEndedExpanded] = useState(false);

  if (
    !bunkersItineraryTotals ||
    !bunkersItineraryTotals.totalsPerFuelGrade ||
    !bunkersItineraryTotals.totalsPerOutcomeType
  ) {
    bunkersItineraryTotals = {
      totalsPerFuelGrade: [
        {
          fuelGradeCode: null,

          atBeginning: {
            quantityMt: null,
            cost: null,
            price: null,
          },
          lifted: {
            quantityMt: null,
            cost: null,
            prices: { min: null, max: null },
          },
          consumed: {
            quantityMt: null,
            cost: null,
            prices: { min: null, max: null },
          },
          atEnd: {
            quantityMt: null,
            cost: null,
            prices: { min: null, max: null },
          },
        },
      ],
      totalsPerOutcomeType: {
        atBeginning: {
          cost: null,
        },
        atEnd: {
          cost: null,
        },
        consumed: {
          cost: null,
        },
        lifted: {
          cost: null,
        },
      },
    };
  }

  if (!bunkersItinerarySourceDetails) {
    bunkersItinerarySourceDetails = [];
  }

  const totalsPerFuelGrade: Array<FuelGradeItineraryTotalsItem> =
    bunkersItineraryTotals.totalsPerFuelGrade;
  const totalsPerOutcomeType: TotalsPerOutcomeType = bunkersItineraryTotals.totalsPerOutcomeType;
  const sourceDetails = bunkersItinerarySourceDetails.perFuelGrade
    ? bunkersItinerarySourceDetails.perFuelGrade.reduce(function (map, obj) {
        map[obj.fuelGradeCode] = obj;
        return map;
      }, {})
    : [];

  const perLocation = bunkersItinerarySourceDetails.perOutcomeType;

  const isLiftExpandableLocationValid =
    perLocation && perLocation.lifted && perLocation.lifted.figuresByLocations.length !== 0;

  const isEndExpandableLocationValid =
    perLocation &&
    perLocation.atEnd &&
    perLocation.atEnd.figuresByLocations.filter((e) => e.voyageEntryId !== bunkersOnBoardId)
      .length !== 0;

  function handleLiftExpandCollapseClick() {
    setIsLiftingExpanded((isliftingExpanded) => !isliftingExpanded);
  }

  function handleEndExpandCollapseClick() {
    setIsEndedExpanded((isEndedExpanded) => !isEndedExpanded);
  }

  return (
    <div className={classNames('bunkers-itinerary-view', className)} {...restProps}>
      <div className="table-outer">
        <div className="table-inner-pinned-column table-row-definition left-column fuel-grade-row">
          <div />
          <div className="cell-heading">Type</div>
          <div className="type-heading-none-expand cell-heading">Start</div>

          {!isLiftExpandableLocationValid && (
            <div className="type-heading-none-expand cell-heading">Lifting</div>
          )}

          {!isEndExpandableLocationValid && (
            <div className="type-heading-none-expand cell-heading">End</div>
          )}

          {isLiftExpandableLocationValid && (
            <div className="type-heading-expand">
              <ExpandCollapseButton
                expanded={isliftingExpanded}
                onClick={handleLiftExpandCollapseClick}
              >
                <div className="clarksons-white">Liftings</div>
              </ExpandCollapseButton>
            </div>
          )}
          {isLiftExpandableLocationValid &&
            isliftingExpanded &&
            perLocation.lifted.figuresByLocations.map((location, index) => (
              <div
                key={`type-lifted-${index}`}
                data-tooltip-id={`tooltip-lifted-${index}`}
                className="type-heading-expand-child truncate"
                data-tooltip-content={location.locationName}
              >
                {location.locationName}
                <ReactTooltip
                  place="bottom"
                  offset={-4}
                  id={`tooltip-lifted-${index}`}
                  className="ve-tooltip-default"
                  noArrow
                />
              </div>
            ))}
          {isEndExpandableLocationValid && (
            <div className="type-heading-expand">
              <ExpandCollapseButton
                expanded={isEndedExpanded}
                onClick={handleEndExpandCollapseClick}
              >
                <div className="clarksons-white">End</div>
              </ExpandCollapseButton>
            </div>
          )}
          {isEndExpandableLocationValid &&
            isEndedExpanded &&
            perLocation.atEnd.figuresByLocations.map((location, index) => (
              <div
                key={`type-end-${index}`}
                className="type-heading-expand-child truncate"
                data-tooltip-id={`tooltip-end-${index}`}
                data-tooltip-content={location.locationName}
              >
                {location.locationName}
                <ReactTooltip
                  place="bottom"
                  offset={-4}
                  id={`tooltip-end-${index}`}
                  className="ve-tooltip-default"
                  noArrow
                />
              </div>
            ))}

          <div className="text-nowrap">
            Consumption <br />
            <span className="consumption-formula">Start+Lifting-End</span>
          </div>
        </div>

        <div className="table-inner-fuel-grade">
          {totalsPerFuelGrade.map((_, index) => (
            <React.Fragment key={_.fuelGradeCode}>
              <div className="fuel-grade-columns">
                <div className="fuel-grade-row">
                  <div className="cell-heading-fuel-type">
                    {_.fuelGradeCode != null ? getFuelGradeByCode(_.fuelGradeCode).label : ''}
                  </div>
                </div>
                <div className="fuel-grade-row cell-heading">
                  <div>MT</div>
                  <div>@</div>
                  <div>($/MT)</div>
                  <div>Cost ($)</div>
                </div>

                {
                  // atBeginning
                }
                <div className="fuel-grade-row">
                  <div>{formatFuelMtOrNullAsEmpty(_.atBeginning.quantityMt)}</div>
                  <div>@</div>
                  <div>{formatMoneyOrNullAsEmpty(_.atBeginning.price)}</div>
                  <div>{formatMoneyOrNullAsEmpty(_.atBeginning.cost)}</div>
                </div>

                {
                  // lifted
                }
                <div className="fuel-grade-row">
                  <div>{formatFuelMtOrNullAsEmpty(_.lifted.quantityMt)}</div>

                  <div>@</div>
                  <div>
                    <NumericRangeDisplayWithCustomRender
                      value={_.lifted.prices}
                      formatNumberOrNull={formatMoneyOrNullAsEmpty}
                    >
                      {formatMoneyOrNullAsEmpty(
                        _.lifted.quantityMt === null || _.lifted.quantityMt === 0
                          ? null
                          : _.lifted.cost / _.lifted.quantityMt
                      )}{' '}
                      avg
                    </NumericRangeDisplayWithCustomRender>
                  </div>
                  <div>{formatMoneyOrNullAsEmpty(_.lifted.cost)}</div>
                </div>
                {isLiftExpandableLocationValid &&
                  isliftingExpanded &&
                  sourceDetails[_.fuelGradeCode].lifted.figuresByLocations.map(
                    (location, index) => (
                      <div key={`fuel-lifted-${index}`} className="fuel-grade-row">
                        <div>{formatFuelMtOrNullAsEmpty(location.quantityMt)}</div>
                        <div>{location.quantityMt !== null && location.cost !== null && '@'}</div>
                        <div>{formatFuelMtOrNullAsEmpty(location.price)}</div>
                        <div>{formatMoneyOrNullAsEmpty(location.cost)}</div>
                      </div>
                    )
                  )}
                {
                  // atEnd
                }
                <div
                  className={
                    !isEndedExpanded || !isEndExpandableLocationValid
                      ? 'fuel-grade-row cell-ending'
                      : 'fuel-grade-row'
                  }
                >
                  <div>{formatFuelMtOrNullAsEmpty(_.atEnd.quantityMt)}</div>
                  <div>@</div>
                  <div className="text-nowrap">
                    <NumericRangeDisplayWithCustomRender
                      value={_.atEnd.prices}
                      formatNumberOrNull={formatMoneyOrNullAsEmpty}
                    >
                      {formatMoneyOrNullAsEmpty(
                        _.atEnd.quantityMt === null || _.atEnd.quantityMt === 0
                          ? null
                          : _.atEnd.cost / _.atEnd.quantityMt
                      )}{' '}
                      avg
                    </NumericRangeDisplayWithCustomRender>
                  </div>
                  <div>{formatMoneyOrNullAsEmpty(_.atEnd.cost)}</div>
                </div>
                {isEndExpandableLocationValid &&
                  isEndedExpanded &&
                  sourceDetails[_.fuelGradeCode].atEnd.figuresByLocations.map(
                    (location, atEndIndex) => (
                      <div
                        key={`fuel-end-${atEndIndex}`}
                        className={
                          atEndIndex ===
                          sourceDetails[_.fuelGradeCode].atEnd.figuresByLocations.length - 1
                            ? 'fuel-grade-row cell-ending'
                            : 'fuel-grade-row'
                        }
                      >
                        <div>{formatFuelMtOrNullAsEmpty(location.quantityMt)}</div>
                        <div>{location.quantityMt !== null && location.cost !== null && '@'}</div>
                        <div>{formatFuelMtOrNullAsEmpty(location.price)}</div>
                        <div>{formatMoneyOrNullAsEmpty(location.cost)}</div>
                      </div>
                    )
                  )}
                {
                  // consumed
                }
                <div className="fuel-grade-row">
                  <div>{formatFuelMtOrNullAsEmpty(_.consumed.quantityMt)}</div>
                  <div>@</div>
                  <div className="text-nowrap">
                    <NumericRangeDisplayWithCustomRender
                      value={_.consumed.prices}
                      formatNumberOrNull={formatMoneyOrNullAsEmpty}
                    >
                      {formatMoneyOrNullAsEmpty(
                        _.consumed.quantityMt === null || _.consumed.quantityMt === 0
                          ? null
                          : _.consumed.cost / _.consumed.quantityMt
                      )}{' '}
                      avg
                    </NumericRangeDisplayWithCustomRender>
                  </div>
                  <div>{formatMoneyOrNullAsEmpty(_.consumed.cost)}</div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="table-inner-pinned-column table-row-definition right-column">
          <div />
          <div className="cell-heading">Total Costs ($)</div>
          <div>{formatMoneyOrNullAsEmpty(totalsPerOutcomeType.atBeginning.cost)}</div>
          <div>{formatMoneyOrNullAsEmpty(totalsPerOutcomeType.lifted.cost)}</div>
          {isLiftExpandableLocationValid &&
            isliftingExpanded &&
            perLocation.lifted.figuresByLocations.map((location, index) => (
              <div key={`total-lifted-${index}`}>{formatMoneyOrNullAsEmpty(location.cost)}</div>
            ))}

          <div>{formatMoneyOrNullAsEmpty(totalsPerOutcomeType.atEnd.cost)}</div>
          {isEndExpandableLocationValid &&
            isEndedExpanded &&
            perLocation.atEnd.figuresByLocations.map((location, index) => (
              <div key={`total-end-${index}`}>{formatMoneyOrNullAsEmpty(location.cost)}</div>
            ))}
          <div>{formatMoneyOrNullAsEmpty(totalsPerOutcomeType.consumed.cost)}</div>
        </div>
      </div>
    </div>
  );
};
