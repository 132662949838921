import { createSelector } from 'reselect';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';

export const selector = createSelector(getActiveCalculationResult, (calculationResult) => {
  const voyageRate = calculationResult?.voyageRate;
  const freightIncomeGross = voyageRate?.freightIncomeGross ?? 0;
  const totalCargoQuantity = calculationResult?.totalCargoQuantity ?? 1;
  const dollarPerTonne = totalCargoQuantity !== 0 ? freightIncomeGross / totalCargoQuantity : 0;
  return {
    timeCharterRateCalculatedNet: voyageRate?.timeCharterRateCalculatedNet ?? 0,
    totalDaysVoyageProfitOrLossEstimate: voyageRate?.totalDaysVoyageProfitOrLossEstimate ?? 0,
    dollarPerTonne: dollarPerTonne,
    freightIncomeGross,
  };
});
