import React, { useEffect, useState, useRef, useCallback } from 'react';
import Label from 'components/label';
import { SearchIcon } from 'components/icons';
import debounce from 'lodash/debounce';
import Toggle from 'components/toggle';
import UserAutocomplete from 'components/user-autocomplete';
import { callAPItoSearchWorksheets } from 'api/clients/worksheet';
import WorksheetsGrid from '../worksheets-grid';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import axios from 'axios';

import { iconEnum } from 'components/icons';
import { IconTooltip } from 'components/icon-tooltip';

const WorksheetFilter = ({ closePanel, visible: isVisible }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [worksheets, setWorksheets] = useState(null);
  const [shouldShowTeamsWorkbooks, setshouldShowTeamsWorkbooks] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState(false);
  const [searchCriteriaUser, setSearchCriteriaUser] = useState(null);
  const isUserMounted = useRef(true);
  const isSearchCriteriaMounted = useRef(true);
  const fetchUserDetailsDebounced = useCallback(
    debounce(async (value: string): void => {
      fetchUserDetails(value);
    }, 300),
    [user, shouldShowTeamsWorkbooks]
  );
  let cancelTokenSource = useRef();

  const onInputChange = async (value: string): void => {
    setSearchTerm(value);
    fetchUserDetailsDebounced(value);
  };

  const fetchUserDetails = async (value: string): void => {
    if (value.length > 2) {
      setDefaultMessage(false);
      setLoading(true);
      var result = await getSearchResults(encodeURIComponent(value));
      if (result === 'aborted') {
        return;
      }
      if (result && result.data) {
        setLoading(false);
        setWorksheets(result.data.worksheets);
      } else {
        setLoading(false);
        setWorksheets([]);
      }
      trackEvent(
        'WorksheetFilter',
        'Worksheet searched for',
        {
          value: value,
          searchQuery: result.data.searchQuery,
          correlationId: result.data.correlationId,
          totalSearchResults: result.data.totalSearchResults,
          queryTime_ms: result.data.queryTime_ms,
        },
        {},
        eventDestination.ANALYSIS
      );
    } else {
      if (cancelTokenSource.current !== undefined) {
        cancelTokenSource.current.cancel({ isUserCancellation: true });
      }
      setDefaultMessage(true);
      setWorksheets(undefined);
      setLoading(false);
    }
  };

  const getSearchResults = async (value: string) => {
    try {
      var userID = shouldShowTeamsWorkbooks ? user : '';
      if (cancelTokenSource.current !== undefined) {
        cancelTokenSource.current.cancel({ isUserCancellation: true });
      }
      cancelTokenSource.current = axios.CancelToken.source();
      const cancelToken = cancelTokenSource.current;

      var result = await callAPItoSearchWorksheets(value, userID, 0, 100, cancelToken.token);
      cancelTokenSource.current = undefined;
      if (!result) {
        return 'aborted';
      }
      return result;
    } catch (e) {
      if (axios.isCancel(e)) return 'aborted';
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      fetchUserDetails(e.target.value);
    }
  };

  const onToggleWorkbooksMode = (newValue) => {
    //clear search term and worksheet grid when colleague toggled on/off
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel({ isUserCancellation: true });
    }
    setSearchTerm('');
    setWorksheets([]);
    setDefaultMessage(true);
    setshouldShowTeamsWorkbooks(newValue);
    setLoading(false);
  };

  const onUserSearchChange = (newValue) => {
    if (newValue) {
      setSearchCriteriaUser(newValue);
      setUser(newValue.id);
    } else {
      setUser('');
      setSearchCriteriaUser(null);
    }
  };

  useEffect(() => {
    if (isUserMounted.current) {
      isUserMounted.current = false;
      return;
    }
    if (searchTerm) {
      fetchUserDetails(searchTerm);
    }
  }, [user]);

  useEffect(() => {
    if (!isVisible) {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel({ isUserCancellation: true });
        if (worksheets && worksheets.length === 0) {
          setDefaultMessage(true);
        }
      }
      setLoading(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isSearchCriteriaMounted.current) {
      isSearchCriteriaMounted.current = false;
      return;
    }
    if (user) {
      fetchUserDetails(searchTerm);
    }
  }, [shouldShowTeamsWorkbooks]);

  return (
    <div className="search-filter">
      <div>
        <div className="search-panel__tool-bar">
          <div className="search-panel__tool-bar-container">
            <div className="search-panel__tool-bar-toggle">
              <Toggle
                id="search=panel-toggle"
                checked={shouldShowTeamsWorkbooks}
                onChange={onToggleWorkbooksMode}
                label="Search for a colleague"
                className="search-panel__toggle"
                diagnosticId="WorksheetPanel/SearchForAColleague"
              />
            </div>
          </div>
          <div
            className={
              shouldShowTeamsWorkbooks
                ? 'search-filter__colleague--show'
                : 'search-filter__colleague--hidden'
            }
          >
            <div className="team-workbooks">
              <UserAutocomplete onChange={onUserSearchChange} value={searchCriteriaUser} />
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          'search-filter__worksheet ' +
          (!shouldShowTeamsWorkbooks || (shouldShowTeamsWorkbooks && user)
            ? 'search-filter__worksheet--show'
            : 'search-filter__worksheet--hidden')
        }
      >
        <Label>
          <div className="search-filter__label-container">
            <div className="search-filter__label-container--label">Search for a worksheet</div>
            <IconTooltip
              icon={iconEnum.InfoOutline}
              className="search-filter__label-container--infobtn"
            >
              <div className="search-filter__label-container--tooltip-text">
                Search by Worksheet name, Cargo type, Vessel name, Load location and Discharge
                location
              </div>
            </IconTooltip>
          </div>

          <div className="search-filter__input">
            <SearchIcon className="search-filter__input--icon" />
            <input
              onChange={(event) => onInputChange(event.target.value)}
              onKeyDown={(event) => onKeyDownHandler(event)}
              className="search-filter__input--box"
              placeholder="Search..."
              maxLength="500"
              value={searchTerm}
            />
          </div>
        </Label>
        <div>
          <WorksheetsGrid
            worksheets={worksheets}
            loading={loading}
            defaultMessage={defaultMessage}
            classNames={
              'search-filter__worksheet ' + (shouldShowTeamsWorkbooks ? 'grid-height--shorter' : '')
            }
            searchTerm={searchTerm}
            closePanel={closePanel}
            shouldShowTeamsWorkbooks={shouldShowTeamsWorkbooks}
          />
        </div>
      </div>
    </div>
  );
};

export default WorksheetFilter;
