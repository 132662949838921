import {
  ADDITIONAL_INCOME_DESCRIPTION_UPDATED,
  ADDITIONAL_INCOME_INCOME_UPDATED,
  ADDITIONAL_INCOME_ADDED,
  ADDITIONAL_INCOME_REMOVED,
} from 'constants/action-types/worksheet/cargoes/additional-incomes';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import guid from 'utilities/guid';
import maxBy from 'lodash/maxBy';

const initialState = [];

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return getAdditionalIncomesViewModelFromWorksheetDto(action.payload);
    case ADDITIONAL_INCOME_DESCRIPTION_UPDATED:
      return (() => {
        let others = state.slice();
        others[action.payload.index].description = action.payload.description;
        return others;
      })();
    case ADDITIONAL_INCOME_INCOME_UPDATED:
      return (() => {
        let others = state.slice();
        others[action.payload.index].income = action.payload.income;
        return others;
      })();
    case ADDITIONAL_INCOME_ADDED:
      const lastExpense = maxBy(state, 'sortOrder');

      return state.slice().concat({
        id: guid(),
        description: '',
        income: 0,
        sortOrder: lastExpense ? lastExpense.sortOrder + 1 : 0,
      });
    case ADDITIONAL_INCOME_REMOVED:
      return (() => {
        let incomes = state.slice();
        incomes.splice(action.payload, 1);
        return incomes.length > 0
          ? incomes
          : [
              {
                id: guid(),
                description: '',
                income: 0,
                sortOrder: 0,
              },
            ];
      })();
    default:
      return state;
  }
};

export const getAdditionalIncomesViewModelFromWorksheetDto: (
  worksheet: Data.IWorksheet
) => Redux.IAdditionalExpenses = ({ additionalIncomes }) => {
  return !additionalIncomes ? [] : additionalIncomes.map(mapIncome);

  function mapIncome({ ...income }) {
    return { ...income };
  }
};
