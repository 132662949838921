import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import classNames from 'classnames';
import SearchPanel from 'modules/search-panel';
import { WorkbookRedirector } from 'modules/workbook-redirector';
import './styles.scss';
import { createSelector } from 'reselect';
import { NoticesBoundary } from 'components/notices-emitter/notices-emitter-context';
import { NoticesList } from 'components/notices-emitter/notices-list';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { PortalsContainer } from 'components/portals';
import Stage from 'modules/stage';
import { AppGlobalsProvider } from 'components/app-globals-registry';

// TODO remove this once ag-grid context is embedded within the app
// this is currently a work around to allow components hosted within ag-grid
// to interact with the appRootNoticesEmitter
export let appRootNoticesEmitter;

export class AppRoot extends React.PureComponent {
  onNoticesEmitterCreated = (x) => (appRootNoticesEmitter = x);

  onNotice = (notice) => {
    if (notice.isTrackable && notice.type) {
      trackEvent('notices-boundary', `Notice ${notice.type}`, {}, {}, eventDestination.ANALYSIS);
    }
  };

  render() {
    const props = this.props;
    const { isOverlayVisible } = props;
    const classes = classNames('app-root', {
      'app-root--no-scroll': isOverlayVisible,
    });

    return (
      <div className={classes}>
        <NoticesBoundary
          className="app-header-item"
          onNoticesEmitterCreated={this.onNoticesEmitterCreated}
          onNotice={this.onNotice}
        >
          <AppGlobalsProvider>
            <PortalsContainer>
              <NoticesList className="app-header-item" />
              <Router>
                <Fragment>
                  <SearchPanel
                    liftToAppGlobals={{
                      SEARCH_PANEL_TOGGLE_VISIBLITY_GLOBAL: 'toggleVisible',
                    }}
                  />
                  <Routes>
                    <Route path="/" element={<WorkbookRedirector />} />
                    <Route path="/workbook/:workbookId/*" element={<Stage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Fragment>
              </Router>
            </PortalsContainer>
          </AppGlobalsProvider>
        </NoticesBoundary>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.appMetadata.isOverlayVisible,
  (isOverlayVisible) => ({
    isOverlayVisible,
  })
);

export const ConnectedAppRoot = connect(mapStateToProps)(AppRoot);
