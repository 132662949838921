import { createSelector } from 'reselect';
import { getActiveWorksheet } from './get-active-worksheet';
import { getActiveVessel } from './get-active-vessel';
import { getAllLegsWithInboundRouteForVessel } from '../modules/voyage/business-model/voyage-locations-sequence';

export const getActiveVesselLegsWithInboundRoute: ReduxSelector<
  Array<SingleVesselVoyageEntryRoute>,
> = createSelector(getActiveWorksheet, getActiveVessel, (activeWorksheet, activeVessel) => {
  if (activeWorksheet === null || activeVessel === null) return null;

  return getAllLegsWithInboundRouteForVessel({
    worksheet: activeWorksheet,
    vessel: activeVessel,
  });
});
