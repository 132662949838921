import { createSelector } from 'reselect';
import { getActiveWorkbookId } from './get-active-workbook-id';
export const getActiveMarketSegmentId = createSelector(
  getActiveWorkbookId,
  (state) => state.workbooksById,
  getMarketSegmentId
);
export function getMarketSegmentId(activeWorkbookId, workbooksById) {
  if (activeWorkbookId === null || workbooksById[activeWorkbookId] === null) return null;
  return workbooksById[activeWorkbookId].marketSegmentId || null;
}
