import React from 'react';
import { connect } from 'react-redux';
import CalculationSummaryVessel from './vessel';
import CalculationSummaryCargo from './cargo';
import CalculationSummaryEstimate from './estimate';
import CalculationSummaryVoyage from './voyage';
import CalculationSummaryCostBreakdown from './cost-breakdown';
import AdditionalIncomeSummary from './additional-income';
import AppInfo from 'modules/app-info';
import { Panel, PanelSection } from 'components/panel';
import { Header } from 'components/headers';
import { GoldenDivider } from 'components/divider';
import ExpandCollapse from 'components/expand-collapse';
import SensitivityTable from 'modules/sensitivity-table';
import { sensitivityActiveChanged } from 'actions/sensitivity-table';
import { getActiveCalculationResult } from '../../common-selectors/get-active-calculation-result';
import { getActiveVessel } from '../../common-selectors/get-active-vessel';
import { BunkersItineraryView } from './bunkers-itinerary-view';
import InsufficientFuelQuantityWarning from './cost-breakdown/insufficient-fuel-quantity-warning';
import { getActiveWorksheetCalculations } from 'common-selectors/get-active-worksheet-calculations';
import { getActiveMarketSegmentId } from '../../common-selectors/get-active-marketSegmentId';
import { showCarbonFactorTableModal } from 'actions/carbon';
import LinkButton from 'components/link-button';
import VerticalDivider from 'components/divider/vertical';
import { OpenPopupIcon } from 'components/icons';
import CarbonBreakdownView from './carbon-breakdown';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import ProgressBar, { Size } from 'components/progress-bar';
import { CalculationStatus } from 'constants/enums/calculation-status';
import './styles.scss';
import { isTanker } from '../../constants/market-segments';
import { TankersSummaryContainer } from 'modules/tankers-summary';
import { getEmptyCarbonBreakdown } from '../eua/selector';
import { Eua } from '../eua';
import { getActiveWorksheet } from '../../common-selectors/get-active-worksheet';
import { isFeatureEnabled } from '../../config/feature-control';

const CalculationSummary = ({
  activeVessel,
  activeCalculationResult,
  sensitivityActiveChanged,
  activeWorksheetCalculations,
  activeMarketSegmentId,
  showCarbonFactorTableModal,
  activeWorksheet,
}) => {
  let isLoading =
    activeVessel === null ||
    activeCalculationResult === null ||
    activeWorksheetCalculations === null;
  const showBukerSection =
    activeCalculationResult &&
    activeCalculationResult.calculationStatus !== CalculationStatus.LOADING;
  const showBukerWarning =
    showBukerSection && activeCalculationResult.bunkersQuantitiesInvalid === true;
  let carbonBreakdown =
    (activeCalculationResult && activeCalculationResult?.carbonBreakdown) ??
    (activeWorksheet?.voyage?.legs && getEmptyCarbonBreakdown(0, activeWorksheet?.voyage.legs));
  const carbonBreakdownExpandCollapseHandler = (expanded: boolean) => {
    if (expanded) {
      trackEvent(
        'SummaryCarbonBreakdown',
        'Summary Tab Carbon Breakdown Table Viewed',
        {},
        {},
        eventDestination.ANALYSIS
      );
    }
  };

  return (
    <>
      {!isLoading ? (
        isTanker(activeMarketSegmentId) ? (
          <TankersSummaryContainer />
        ) : (
          <div className="calculation-summary">
            <div className="summary-section summary-vessel-cargo">
              <CalculationSummaryVessel />
              <CalculationSummaryCargo />
            </div>
            <CalculationSummaryEstimate />
            <Panel>
              <PanelSection>
                <ExpandCollapse
                  header={
                    <Header
                      text={
                        <span>
                          <span className="clarksons-body-1-bold">Base calculation:</span> Increase
                          or decrease bunker prices or rates
                        </span>
                      }
                    />
                  }
                  onClick={sensitivityActiveChanged}
                  className="sensitivity-analysis__expand_collapse-header"
                >
                  <SensitivityTable />
                </ExpandCollapse>
              </PanelSection>
              <GoldenDivider />
            </Panel>

            <AdditionalIncomeSummary />

            <CalculationSummaryVoyage />

            <div className="summary-section summary-section--border">
              <ExpandCollapse
                onClick={carbonBreakdownExpandCollapseHandler}
                className="summary-section--expand"
                additionalExpandHeaderSection={
                  <>
                    <VerticalDivider className="carbon-section--divider" />
                    <div className="carbon-section--link-section">
                      <LinkButton
                        className="carbon-section--link"
                        diagnosticId="Summary/CarbonReference"
                        onClick={showCarbonFactorTableModal}
                      >
                        Carbon Factors Reference&nbsp;&nbsp;
                        <OpenPopupIcon className="carbon-breakdown-header--open-popup-icon" />
                      </LinkButton>
                    </div>
                  </>
                }
                header={
                  <Header text={<h2 className="carbon-section--title">Carbon Breakdown</h2>} />
                }
              >
                <div>
                  {isFeatureEnabled('isEuaSectionVisible') && (
                    <>
                      <h2 className="carbon-section--title">EUAs</h2>
                      <Eua
                        carbonBreakdown={carbonBreakdown}
                        voyageLegs={activeWorksheet.voyage.legs}
                        activeVessel={activeVessel}
                        isInSummaryPanel={true}
                      />
                    </>
                  )}
                  <h2 className="carbon-section--title">Emissions</h2>

                  <CarbonBreakdownView />
                </div>
              </ExpandCollapse>
            </div>

            <div className="summary-section summary-section--border padding-bottom-tiny">
              <h2>Total Net Voyage Cost Breakdown ($)</h2>
              <CalculationSummaryCostBreakdown />
            </div>

            <div className="summary-section summary-section--border">
              <h2>Bunker Details</h2>

              {showBukerSection ? (
                showBukerWarning ? (
                  <InsufficientFuelQuantityWarning />
                ) : (
                  <BunkersItineraryView
                    bunkersItineraryTotals={activeCalculationResult.bunkersItineraryTotals}
                    bunkersItinerarySourceDetails={
                      activeCalculationResult.bunkersItinerarySourceDetails
                    }
                    className="width-100percent"
                  />
                )
              ) : (
                <div className="sensitivity__progress-bar">
                  <ProgressBar size={Size.MEDIUM} />
                </div>
              )}
            </div>

            <AppInfo className="margin-top-medium" />
          </div>
        )
      ) : isTanker(activeMarketSegmentId) ? (
        <div>
          <TankersSummaryContainer />
        </div>
      ) : (
        <div className="sensitivity__progress-bar">
          <ProgressBar size={Size.MEDIUM} />
        </div>
      )}
    </>
  );
};

export default connect(
  /* data props: */ (state) => ({
    activeCalculationResult: getActiveCalculationResult(state),
    activeVessel: getActiveVessel(state),
    activeWorksheetCalculations: getActiveWorksheetCalculations(state),
    activeMarketSegmentId: getActiveMarketSegmentId(state),
    activeWorksheet: getActiveWorksheet(state),
  }),
  /* action props: */ {
    sensitivityActiveChanged,
    showCarbonFactorTableModal,
  }
)(CalculationSummary);
