import React from 'react';
import 'modules/voyage/components/speed-and-cons-summary-in-voyage/styles.scss';
import { ModeEditIcon } from 'components/icons';
import VerticalDivider from 'components/divider/vertical';
import SpeedConsDisplayItem from 'modules/voyage/components/speed-and-cons-summary-in-voyage/SpeedConsDisplayItem';
import { Header } from 'components/headers';
import { LinkButton } from 'components/link-button';
import { showSpeedAndConsEditModal } from 'actions/worksheet/speed-and-consumptions';
import { selector } from 'modules/eua/selector';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

type SpeedAndConsSummaryInVoyageProps = {
  ballastSpeed: string,
  ladenSpeed: string,
  ballastCons: string,
  ladenCons: string,
  showSpeedAndConsEditModal: (isVisible: boolean) => void,
};

const SpeedAndConsSummaryInVoyage: React.FC<SpeedAndConsSummaryInVoyageProps> = ({
  ballastSpeed,
  ladenSpeed,
  ballastCons,
  ladenCons,
  showSpeedAndConsEditModal,
}) => {
  return (
    <div className="speed-display-container">
      <div className="speed-display-container-header">
        <Header text="Speed and Consumptions" className="speed-display-container-title" />
        <div className="speed-display-container-table">
          <div className="speed-column">
            <SpeedConsDisplayItem label="Ballast Speed" value={ballastSpeed} unit="kn" />
            <SpeedConsDisplayItem label="Ballast Cons" value={ballastCons} unit="MT/d" />
          </div>
          <div className="cons-column">
            <SpeedConsDisplayItem label="Laden Speed" value={ladenSpeed} unit="kn" />
            <SpeedConsDisplayItem label="Laden Cons" value={ladenCons} unit="MT/d" />
          </div>
        </div>
      </div>
      <div className="speed-display-container--action">
        <VerticalDivider className="speed-display-container--divider" />
        <LinkButton
          className="edit-sac"
          onClick={() => {
            showSpeedAndConsEditModal();
          }}
          diagnosticId="Route/Edit_Speed_And_Cons"
        >
          <ModeEditIcon className="edit-sac--open-popup-icon" />
        </LinkButton>
      </div>
    </div>
  );
};
const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSpeedAndConsEditModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeedAndConsSummaryInVoyage);
