import isNil from 'lodash/isNil';
import * as generatorFnUtils from 'utilities/iterable/generator-fn-utils';
import { LOAD, DISCHARGE, BUNKER, VIA, getVoyageLegTypeById } from 'constants/enums/voyage-leg';
import { markerTypes } from './components/marker';
import { createSelector } from 'reselect';
import { getKnownWaypointOrNull } from '../../constants/data/waypoints';
import { getActiveVesselLegsWithInboundRoute } from '../../common-selectors/get-active-vessel-legs-with-inbound-route';
import type { SingleVesselVoyageEntryRoute } from '../voyage/business-model/voyage-locations-sequence';

export const selector = createSelector(
  getActiveVesselLegsWithInboundRoute,
  (voyageEntriesWithRoute: Array<SingleVesselVoyageEntryRoute>) => {
    return {
      routes: getRoutes(voyageEntriesWithRoute),
      markers: getMarkers(voyageEntriesWithRoute),
      voyageLegs: getVoyageLegs(voyageEntriesWithRoute),
    };
  }
);

export const getRoutes = (
  voyageEntriesWithRoute: Array<SingleVesselVoyageEntryRoute>
): Array<{
  id: string,
  coordinates: Array<{
    lng: number,
    lat: number,
  }>,
}> => {
  return voyageEntriesWithRoute
    .filter(({ inboundRouteVariant: { path } }) => isNil(path) === false && path.length !== 0)
    .map((_) => ({
      id: _.voyageEntryDetails.id,
      coordinates: _.inboundRouteVariant.path.map((lnglat) => ({
        lng: lnglat[0],
        lat: lnglat[1],
      })),
    }));
};

export type Marker = {
  markerType: $Values<typeof markerTypes>,
  id: string,
  name: string,
  coordinate: {
    lng: number,
    lat: number,
  },
};

export const getMarkers: (
  voyageEntriesWithRoute: Array<SingleVesselVoyageEntryRoute>
) => Array<Marker> = generatorFnUtils.wrapFnWithArrayConvert(function* (voyageEntriesWithRoute) {
  const openLocationInfo = voyageEntriesWithRoute[0].fromLocationInfo;
  if (openLocationInfo) {
    yield {
      id: `open_position`,
      markerType: markerTypes.VESSEL_OPEN_LOCATION,
      name: openLocationInfo.name,
      coordinate: {
        lng: openLocationInfo.geoCoords.longitude,
        lat: openLocationInfo.geoCoords.latitude,
      },
    };
  }

  for (const leg of voyageEntriesWithRoute.map((_) => _.voyageEntryDetails).filter(isValidMarker)) {
    yield {
      id: leg.id,
      markerType: getMarkerType(leg.type),
      name: leg.name,
      coordinate: {
        lng: leg.longitude,
        lat: leg.latitude,
      },
    };
  }

  function isValidMarker({ locationId, longitude, latitude }) {
    return isNil(locationId) === false && isNil(latitude) === false && isNil(longitude) === false;
  }

  function getMarkerType(legTypeId) {
    const legType = getVoyageLegTypeById(legTypeId);

    switch (legType) {
      case LOAD:
        return markerTypes.LOAD_PORT;
      case DISCHARGE:
        return markerTypes.DISCHARGE_PORT;
      case BUNKER:
        return markerTypes.BUNKER_PORT;
      case VIA:
        return markerTypes.VIA_PORT;
      default:
        return markerTypes.UNKNOWN;
    }
  }
});

export type VoyageLeg = {
  fromLocationName: string,
  toLocationName: string,
  waypoints: Array<IVoyageWaypoint & { name: string }>,
};

export const getVoyageLegs: (
  voyageEntriesWithRoute: Array<SingleVesselVoyageEntryRoute>
) => VoyageLeg = generatorFnUtils.wrapFnWithArrayConvert(function* (voyageEntriesWithRoute) {
  if (!voyageEntriesWithRoute[0].fromLocationInfo)
    /* Skip one item if there's no open location. First `fromLocationInfo` is an open-location which is entirely optional, and lack of which indicates that the vessel starts at the first voyageEntry. The second item has the first item's location under `fromLocationInfo`, so it's going to nicely produce the first leg as the one between the first item and the second */
    voyageEntriesWithRoute = voyageEntriesWithRoute.slice(/* begin: */ 1);
  for (const voyageEntryWithRoute of voyageEntriesWithRoute) {
    yield {
      fromLocationName:
        voyageEntryWithRoute.fromLocationInfo && voyageEntryWithRoute.fromLocationInfo.name,
      toLocationName:
        voyageEntryWithRoute.toLocationInfo && voyageEntryWithRoute.toLocationInfo.name,
      waypoints: (
        (voyageEntryWithRoute.inboundRouteVariant &&
          voyageEntryWithRoute.inboundRouteVariant.waypoints) ||
        []
      )
        .map((waypoint: IVoyageWaypoint) => ({
          waypoint: waypoint,
          knownWaypointData: getKnownWaypointOrNull(waypoint.locationId),
        }))
        .filter((_) => _.knownWaypointData !== null)
        .map((_) => ({
          ..._.waypoint,
          name: _.knownWaypointData.name,
        })),
    };
  }
});
