import debounce, { DebounceSettings } from 'lodash/debounce';
import { createControllablePromise } from 'utilities/promise/create-controllable-promise';

/**
 * An awaitable version of `debounce` which returns a promise to get the results of the future call.
 *
 * NOTE: This is unlike in [`lodash/debounce`](https://lodash.com/docs/#debounce) where the previous call's results are returned immediately (can be somewhat useful for sync functions).
 */
export default function debounceAwaitable<Result>(
  func: (...args: any) => Promise<Result> | Result,
  wait: number,
  options?: DebounceSettings
): (...args: any) => Promise<Result> {
  let controllablePromiseForCallsWaiting;
  const debouncedFunction = debounce(
    async function executeFuncImmediately() /*use `function` to be able to receive and pass `this` */ {
      const currentPromiseController = controllablePromiseForCallsWaiting.controller; // Hold on to the original promise controller, especially not to signal calls that were invoked after us, but still signal calls invoked before us and waiting.
      controllablePromiseForCallsWaiting = null; // Before executing, clear the `controllablePromiseForCallsWaiting`, so that the new calls don't get stale data if they manage to trigger another call even before the await comes back.
      try {
        const result = await func.apply(this, arguments);
        currentPromiseController.resolve(result);
      } catch (error) {
        currentPromiseController.reject(error);
      }
    },
    wait,
    options
  );

  return async function handleOneCallAllowingItToAwaitForExecution(): Promise<Result> {
    /* copy the promise to a local `const` because it might be erased in the call to `debouncedFunction` */
    const thisCallPromise = (
      controllablePromiseForCallsWaiting ||
      (controllablePromiseForCallsWaiting = createControllablePromise())
    ).promise;

    debouncedFunction.apply(this, arguments);
    return await thisCallPromise;
  };
}
