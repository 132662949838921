import VError from 'verror';
import { isFeatureEnabled } from 'config/feature-control';
import * as generatorFnUtils from 'utilities/iterable/generator-fn-utils';
import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import type {
  AreBothEqualFunc,
  GetIdentityPrimitiveFunc,
} from 'utilities/iterable/aggregation-typings';
import debounceAwaitable from 'utilities/functions/debounce-awaitable';
import noAwait from 'utilities/functions/no-await';

/// Checks that all values are the same, and returns that repeated value, or throws a meaningful exception showing all the different values that were found.
export function singleUniqueOrThrowIfManyOrEmpty<T>(allValues: Iterable<T>): T {
  const allUniqueValues = [...new Set(allValues)];
  if (allUniqueValues.length !== 1) {
    if (allUniqueValues.length === 0)
      throw new VError(
        { info: { allUniqueValues } },
        'Expected a single (possibly repeated) value but none.'
      );
    else
      throw new VError(
        { info: { allUniqueValues } },
        "Expected a single (possibly repeated) value but found many. See the error's `allUniqueValues` property."
      );
  }
  return allUniqueValues[0];
}

export async function asyncIterableToArrayPromise<Item>(
  asyncIterable: AsyncIterable<Item>
): () => Promise<Array<Item>> {
  const resultArray = [];
  for await (const item of asyncIterable) {
    resultArray.push(item);
  }
  return resultArray;
}

/**
  A performant difference thanks to the second argument being a set.
 */
export function differenceWithSet<Item>(
  collectionA: Iterable<Item>,
  setB: Set<Item>
): Iterable<Item> {
  return Array.from([...collectionA].filter((item) => !setB.has(item)));
}

export function areEqualSequencesBy<Item>(
  firstSequence: Iterable<Item>,
  secondSequence: Iterable<Item>,
  getIdentityPrimitive: GetIdentityPrimitiveFunc<Item>
) {
  return areEqualSequencesWith(
    firstSequence,
    secondSequence,
    (firstValue, secondValue) =>
      getIdentityPrimitive(firstValue) === getIdentityPrimitive(secondValue)
  );
}

/** Equivalent of .NET's `SequenceEqual`. The suffix `With` follows lodash `differenceWith` and `intersectionWith`, which signify that they take the a `AreBothEqualFunc` argument, as oppose to `differenceBy` and `intersectionBy`, which take a function that produces an `IdentityPrimitive`.
 */
export function areEqualSequencesWith<Item>(
  firstSequence: Iterable<Item>,
  secondSequence: Iterable<Item>,
  areItemsEqual: AreBothEqualFunc<Item>
) {
  const secondSequenceIterator = secondSequence[Symbol.iterator]();

  for (const firstSequenceItem of firstSequence) {
    const secondSequenceIteration = secondSequenceIterator.next();

    if (secondSequenceIteration.done) {
      return false;
    } else if (!areItemsEqual(secondSequenceIteration.value, firstSequenceItem)) {
      return false;
    }
  }

  if (secondSequenceIterator.next().done) return true;
  else return false;
}

/**
 * Returns null if the `iterable` is empty, the single item if it has only one, or throw if it has more than one item.
 * (Equivalent of .NET LINQ's [`SingleOrDefault`](https://docs.microsoft.com/en-us/dotnet/api/system.linq.enumerable.singleordefault))
 */
export function singleOrNullIfEmptyOrThrowIfMany<T>(
  iterable: Iterable<T>,
  errorMessagePreamble: string
): T | null {
  if (Array.from(iterable).length === 0) return null;
  else return singleOrThrow(iterable, errorMessagePreamble);
}

/**
 * Returns the single item in the `iterable` or throw if the `iterable` is empty or has more than one item.
 */
export function singleOrThrow<T>(iterable: Iterable<T>, errorMessagePreamble: string): T {
  const values = Array.from(iterable);
  if (values.length !== 1) {
    const errorPrefix = errorMessagePreamble ? errorMessagePreamble + '.\n\tError caused:\n\t' : '';
    if (values.length === 0)
      throw new VError(
        errorPrefix + 'Expected a single value in the iterable but found no values.'
      );
    else
      throw new VError(
        {
          info: {
            actualLength: values.length,
          },
        },
        errorPrefix +
          "Expected a single value in the iterable but it had many. See the error's `actualLength` property."
      );
  }
  return values[0];
}

export function internalUseOnly_BeginContextWithSuppressErrorIfMultiVesselFeatureOn(): CleanupFn {
  internalUseOnly_context_IsSuppressErrorIfMultiVesselFeatureOn = true;
  return () => (internalUseOnly_context_IsSuppressErrorIfMultiVesselFeatureOn = false);
}
let internalUseOnly_context_IsSuppressErrorIfMultiVesselFeatureOn = false;

const debouncerFor__notifyDevelopersOfRichDataSourceSupportUnimplemented = debounceAwaitable(
  /* Use an awaitable debounce to allow preserving stacktrace */
  async function (callee) {
    return await callee();
  },
  /* wait: */ 5000,
  {
    leading: true,
  }
);

// TODO - when cleaning up `features.FEATURE_MULTI_VESSEL`, consider whether we don't want to extract these `notifyDevelopersOfRichDataSourceSupportUnimplemented` somewhere in `src/utilities/` to help developing another feature in such a piecemeal way.
export function notifyDevelopersOfRichDataSourceSupportUnimplemented({
  todoReference,
  featureId,
  accessPointDescription,
  internalUseOnly_SuppressErrorIfMultiVesselFeatureOn,
}) {
  if (isFeatureEnabled(featureId)) {
    if (
      internalUseOnly_SuppressErrorIfMultiVesselFeatureOn !== true &&
      internalUseOnly_context_IsSuppressErrorIfMultiVesselFeatureOn !== true
    ) {
      noAwait(
        // `noAwait` will make the stacktrace preserved
        debouncerFor__notifyDevelopersOfRichDataSourceSupportUnimplemented(() =>
          unhandledExceptionSinkSend(
            new Error(
              getRichDataSourceSupportUnimplementedMessage({
                todoReference: todoReference,
                featureId: featureId,
                accessPointDescription: accessPointDescription,
              })
            )
          )
        )
      );
    }
  }
}

function getRichDataSourceSupportUnimplementedMessage({
  todoReference,
  featureId,
  accessPointDescription,
}) {
  return `This message is an indicator that the feature '${featureId}' (which introduces data structures capable of holding new data) is still not implemented${
    accessPointDescription ? ' (' + accessPointDescription + ')' : ''
  }. It marks code that still needs changing before the release of that feature with reference ${todoReference} without breaking the app, to be able to test the features that are already complete.
  You are seeing this thanks to the feature-flag being 'on' for this feature: the app takes the data in the old obsolete way. Without the flag it will cause a thrown error (so that we indeed never release in this state).
  If you are cleaning-up the feature toggle, MAKE SURE there are no occurrences of this error on the console or app logs before you proceed to remove the feature-toggle.
  `;
}

/**
  A version of `groupBy` that supports non-primitive types thanks to using `Map`.
*/
export function groupByToMap<Item, GroupIdentity, Value>(
  iterable: Iterable<Item>,
  getGroupIdentityKey: (item: Item) => GroupIdentity,
  getValueFromItem?: (item: Item) => Value = (item) => item
): Map<GroupIdentity, Array<Item>> {
  const resultMap: Map<Group, Array<Item>> = new Map();

  for (const item of iterable) {
    const groupIdentityKey = getGroupIdentityKey(item);
    let groupArray = resultMap.get(groupIdentityKey);
    if (!groupArray) {
      groupArray = [];
      resultMap.set(groupIdentityKey, groupArray);
    }
    groupArray.push(getValueFromItem(item));
  }
  return resultMap;
}

/**
  Prefer this overload over `mapMatchingAndLeaveOthers` if possible for a fail-fast code that warns developers early about any mistake.
 */
export const mapMatchingAndLeaveOthersErrorIfNoneFound = generatorFnUtils.wrapFnWithArrayConvert(
  function* (
    sourceIterable: Iterable<Item>,
    itemPredicate: (item: Item) => boolean,
    getNewItem: (item: Item) => Item,
    createNoneFoundError?: () => Error
  ): Iterable<Item> {
    let isAnyFound = false;
    for (const item of mapMatchingAndLeaveOthers(
      /*sourceIterable: */ sourceIterable,
      /*itemPredicate: */ itemPredicate,
      /*getNewItem: */ function () {
        isAnyFound = true;
        return getNewItem.apply(this, arguments);
      }
    )) {
      yield item;
    }

    if (!isAnyFound)
      throw (
        (createNoneFoundError && createNoneFoundError()) ||
        new Error(
          'Expected to find at least one item that matches the `itemPredicate` but found none.'
        )
      );
  }
);

export const mapMatchingAndLeaveOthers = generatorFnUtils.wrapFnWithArrayConvert(function* (
  sourceIterable: Iterable<Item>,
  itemPredicate: (item: Item) => boolean,
  getNewItem: (item: Item) => Item
): Iterable<Item> {
  for (const item of sourceIterable) {
    if (!itemPredicate(item)) yield item;
    else yield getNewItem(item);
  }
});

export const replaceOrAppend = generatorFnUtils.wrapFnWithArrayConvert(function* (
  sourceIterable: Iterable<Item>,
  areEqualIdentity: (a: Item, b: Item) => boolean,
  newItem: Item
): Iterable<Item> {
  let hasReplaced = false;
  for (const item of sourceIterable) {
    if (areEqualIdentity(item, newItem)) {
      yield newItem;
      hasReplaced = true;
    } else {
      yield item;
    }
  }
  if (!hasReplaced) yield newItem;
});

export const replaceAtIndexOrAppend = generatorFnUtils.wrapFnWithArrayConvert(function* (
  sourceIterable: Iterable<Item>,
  targetIndex: number,
  newItem: Item
): Iterable<Item> {
  let currentIndex = 0;
  for (const item of sourceIterable) {
    if (currentIndex === targetIndex) {
      yield newItem;
    } else {
      yield item;
    }
    ++currentIndex;
  }
  if (currentIndex === targetIndex) yield newItem;
  else
    throw new VError(
      {
        info: {
          targetIndex,
          actualLength: currentIndex,
        },
      },
      'Cannot append at `targetIndex` that points beyond `actualLength`'
    );
});

/** A safer an more precise equivalent of `Array.splice` - does not modify the source array, and doesn't accept any `undefined`s (`Array.splice(undefined, ..., item)` results in no error and an insert at 0 index) or 'out of range' inserts (`start` greater than length results in append in `Array.splice`) and more universal: works on any `Iterable` (not just arrays). */
export const insertAtIndex = generatorFnUtils.wrapFnWithArrayConvert(function* (
  sourceIterable: Iterable<Item>,
  targetIndex: number,
  newItem: Item
): Iterable<Item> {
  if (typeof targetIndex !== 'number')
    throw new Error(
      `Type of \`targetIndex\` is required to be a \`number\` but was ${typeof targetIndex}`
    );

  let currentIdxAtSource = 0;

  let hasInserted = false;

  for (const item of sourceIterable) {
    if (currentIdxAtSource === targetIndex) {
      hasInserted = true;
      yield newItem;
    }
    yield item;
    ++currentIdxAtSource;
  }

  if (!hasInserted) {
    if (currentIdxAtSource !== targetIndex)
      throw new VError(
        {
          info: {
            targetIndex,
            actualLength: currentIdxAtSource,
          },
        },
        'Cannot insert at `targetIndex` that points beyond `actualLength`'
      );
    yield newItem;
  }
});
