import { veApi } from 'api';
import { ICalculationWebApiRequest } from 'api/request-builders/calculate-estimate-request-builder';
import buildWorksheetRequest from 'api/request-builders/worksheet-request-builder';
import { isFeatureEnabled } from '../../../config/feature-control';

export async function CalculateWorksheet(
  data: ICalculationWebApiRequest,
  cancelToken: cancelToken | null = null
) {
  const url = `/Calculation/worksheet`;
  if (data.isEditable) {
    data.worksheet = undefined;
  } else {
    const worksheetDto = buildWorksheetRequest(data.worksheet);
    worksheetNullValueReplace(worksheetDto);
    data = { ...data, worksheet: worksheetDto };
  }

  var wetCargoLiteMultiLeg = isFeatureEnabled('wetCargoLiteMultiLeg');

  var response = await veApi.post(url, data, {
    cancelToken: cancelToken.token,
    headers: {
      'X-Wet-Cargo-Lite-Multi-Leg': wetCargoLiteMultiLeg,
    },
  });

  return response.data.response;
}

export async function CalculateWorkbook(data: ICalculationWebApiRequest, cancelToken: cancelToken) {
  const url = `/Calculation/workbook`;
  var response = await veApi.post(url, data, cancelToken);
  return response.data.response;
}

export async function CalculateSensitivity(
  data: CalculateSensitivityRequest,
  cancelToken: cancelToken
) {
  const url = `/Calculation/sensitivity`;
  if (data.isEditable) {
    data.worksheet = undefined;
  } else {
    let worksheetDto = buildWorksheetRequest(data.worksheet);
    worksheetNullValueReplace(worksheetDto);
    data = { ...data, worksheet: worksheetDto };
  }
  var response = await veApi.post(url, data, cancelToken);
  return response;
}

export async function calculateMaximumLift(data: IMaximumLiftInputs, cancelToken: cancelToken) {
  const url = `/Calculation/maximum-lift`;
  var response = await veApi.post(url, data, cancelToken);
  return response.data.response;
}

function worksheetNullValueReplace(worksheetDto) {
  worksheetDto.voyage.legs.forEach((leg) => {
    leg.inboundRoute.variants.forEach((v) => {
      if (!v.routeRetrievedWithGraphVersion) {
        v.routeRetrievedWithGraphVersion = 0;
      }
    });
  });
}
