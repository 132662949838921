import ReasonWorkbookEditsNotAllowedEnum from 'constants/enums/workbooks/ReasonWorkbookEditsNotAllowedEnum';
import userContext from 'user-context';

export default class WorkbookComputedProps {
  get isEditable(): boolean {
    return this.reasonEditsNotAllowed === null;
  }

  get reasonEditsNotAllowed(): ReasonWorkbookEditsNotAllowedEnum {
    if (this.userId !== userContext.systemUserId)
      return ReasonWorkbookEditsNotAllowedEnum.UserDoesntOwnTheWorkbook;
    return null;
  }
}
