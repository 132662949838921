import * as actionTypes from 'constants/action-types/worksheet/speed-and-consumptions';
import { VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED } from 'constants/action-types/worksheet/voyage';
import type {
  FuelGradePayload,
  FuelGradeUpdatedAction,
  MainFuelGradeUpdatedAction,
  SpeedPayload,
  SpeedUpdatedAction,
  ConsumptionPayload,
  ConsumptionUpdatedAction,
  ApplyNewSpeedAndConsPayload,
} from './types';
import { trackEvent } from 'diagnostics/calc-trackevents';
import {
  GENERATOR_CHANGED_IN_EDIT_SPEED_AND_CONS_CHANGED,
  SHOW_SPEED_AND_CONS_EDIT_MODAL,
} from 'constants/action-types/worksheet/speed-and-consumptions';

export function fuelGradeChanged(
  payload: FuelGradePayload,
  worksheetId,
  vesselEntryId
): FuelGradeUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_FUELGRADE_CHANGED,
    payload: payload,
  };
}

export function mainFuelGradeChanged(
  payload: MainFuelGradePayload,
  worksheetId,
  vesselEntryId
): MainFuelGradeUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_MAIN_FUELGRADE_CHANGED,
    payload: payload,
  };
}

export function zoneSpecificSecaFuelGradeChanged(
  newFuelGradeId: FuelGradeId,
  worksheetId,
  vesselEntryId
): FuelGradeUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_ZONESPECIFIC_SECA_FUELGRADE_CHANGED,
    payload: newFuelGradeId,
  };
}

export function zoneSpecificBannedSlugDischargeFuelGradeChanged(
  newFuelGradeId: FuelGradeId,
  worksheetId,
  vesselEntryId
): FuelGradeUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_ZONESPECIFIC_SLUGDISCHARGE_FUELGRADE_CHANGED,
    payload: newFuelGradeId,
  };
}

export function zoneSpecificSecaAndBannedSlugDischargeFuelGradeChanged(
  newFuelGradeId: FuelGradeId,
  worksheetId,
  vesselEntryId
): FuelGradeUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_ZONESPECIFIC_SECA_AND_SLUGDISCHARGE_FUELGRADE_CHANGED,
    payload: newFuelGradeId,
  };
}

export function speedChanged(
  payload: SpeedPayload,
  worksheetId,
  vesselEntryId
): SpeedUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_SPEED_CHANGED,
    payload: payload,
  };
}

export function consumptionChanged(
  payload: ConsumptionPayload,
  worksheetId,
  vesselEntryId
): ConsumptionUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_CONSUMPTION_CHANGED,
    payload: payload,
  };
}

export function consumptionOverrideQtyChanged(
  payload: ConsumptionPayload,
  worksheetId,
  vesselEntryId
): ConsumptionUpdatedAction {
  return {
    worksheetId,
    vesselEntryId,
    type: VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED,
    payload: payload,
  };
}

export function applyNewSpeedAndCons(
  payload: ApplyNewSpeedAndConsPayload,
  worksheetId,
  vesselEntryId
) {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_APPLY_NEW_DETAILS,
    payload: payload,
  };
}

export function resetSpeedAndCons(worksheetId, vesselEntryId) {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_RESET,
  };
}

export function activeSpeedAndConChanged(payload, worksheetId, vesselEntryId) {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_ACTIVE_CHANGED,
    payload: payload,
  };
}

export function scrubberFuelConsumptionChanged(
  consumption: number,
  worksheetId: string,
  vesselEntryId: UUID
): ScrubberFuelConsumptionChanged {
  return {
    worksheetId,
    vesselEntryId,
    type: actionTypes.SPEEDCONS_SCRUBBER_CONSUMPTION_CHANGED,
    payload: consumption,
  };
}

export function showSpeedAndConsEditModal(value = true, area = 'SpeedAndConsEdit') {
  if (value) {
    trackEvent(`${area}/Edit-Speed-And-Cons-Modal`, 'Edit Speed and Cons Modal Displayed', {
      eventSource: `${area}/Edit-Speed-And-Cons-Modal`,
    });
  }
  return {
    payload: value,
    type: SHOW_SPEED_AND_CONS_EDIT_MODAL,
  };
}

export function generatorChangedInEditSandConsChanged(payload, worksheetId) {
  return {
    payload,
    worksheetId,
    type: GENERATOR_CHANGED_IN_EDIT_SPEED_AND_CONS_CHANGED,
  };
}
