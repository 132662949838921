import React from 'react';
import { connect } from 'react-redux';
import NumericInput, {
  validationDecorationStyles,
  validationLevels,
} from 'components/numeric-input';
import {
  fuelMtNoOfDecimalPlaces,
  roundFuelMtCeiling,
} from 'utilities/number/units-precisions/fuel-mt';
import { moneyNoOfDecimalPlaces } from 'utilities/number/units-precisions/money';
import { createStructuredSelector } from 'reselect';
import { getFuelGradeFor } from 'constants/enums/fuel-grades';

type Props = {
  quantity: number,
  price: number,
  isFuelGradeUsed: boolean,
  onQuantityChanged: Function,
  onPriceChanged: Function,
  worksheetId: WorksheetId,
  BunkerId: WorksheetBunkersItemKey,
  fuelGradeId: FuelGradeId,
};

const BunkerRowQuantityPriceCell = (props: Props) => {
  const colClassName = 'bunkers-table-flex-col';
  const {
    quantity,
    price,
    isFuelGradeUsed,
    onQuantityChanged,
    onPriceChanged,
    calculationResults,
    bunkers,
    fuelGradeId,
    bunkerId,
  } = props;

  /* TODO: We can use minBunkerQuantity instead of isFuelGradeUsed to express whether fuel grades UI (qty/price) should be dimmed or not.
   * However, at the moment when having no bunkers and only flat fee, this would make all fuel grades qty/price dimmed.
   * Also to note that Bunker header fuel grades will also need the changes applied therefore needs to receive calculationResults.
   */
  const styles = isFuelGradeUsed ? '' : 'bunkers-table-unused-col';
  let minBunkerQuantity =
    calculationResults &&
    roundFuelMtCeiling(
      Math.max(
        ...calculationResults.map((_) => {
          if (
            !_.bunkersMinQuantitiesToLift ||
            !_.bunkersMinQuantitiesToLift[bunkerId] ||
            !_.bunkersMinQuantitiesToLift[bunkerId][getFuelGradeFor(fuelGradeId).code]
          ) {
            return null;
          }

          return _.bunkersMinQuantitiesToLift[bunkerId][getFuelGradeFor(fuelGradeId).code];
        })
      )
    );

  const validationMessage =
    bunkers.length === 1
      ? `Minimum ${minBunkerQuantity} MT is required or replace all values with 0 to auto calculate`
      : `Minimum ${minBunkerQuantity} MT is required`;

  return (
    <div className={`${colClassName}-fuel-grade ${styles}`}>
      <NumericInput
        value={quantity}
        onInputChange={onQuantityChanged}
        className={`${colClassName}-fuel-grade-quantity`}
        maxDecimalDigits={fuelMtNoOfDecimalPlaces}
        minValue={
          !isNaN(minBunkerQuantity)
            ? {
                value: minBunkerQuantity,
                getAttemptedValueIsInvalidMessage: (
                  attemptedValueInfo,
                  ruleUsageParams,
                  component
                ) => validationMessage,
                validationLevel: validationLevels.guidance,
              }
            : null
        }
        maxValue="9999.99"
        validationDecorationStyle={validationDecorationStyles.borderOnly}
        diagnosticId="BunkerRowQuantity/ChangeQuantity"
      />
      <span className={`${colClassName}-fuel-grade-divider`}>@</span>
      <NumericInput
        value={price}
        onInputChange={onPriceChanged}
        className={`${colClassName}-fuel-grade-price`}
        maxDecimalDigits={moneyNoOfDecimalPlaces}
        minValue="0"
        maxValue="9999.99"
        diagnosticId="BunkerRowQuantity/ChangePrice"
      />
    </div>
  );
};

export default connect(
  /*data props:*/ createStructuredSelector({
    calculationResults: (state, ownProps) =>
      state.calculationsByWorksheetId[ownProps.worksheetId] &&
      state.calculationsByWorksheetId[ownProps.worksheetId].calculationResults,

    bunkers: (state, ownProps) => state.worksheetsById[ownProps.worksheetId].bunkerExpenses.bunkers,
  }),
  /*action props:*/ null
)(BunkerRowQuantityPriceCell);
