import { getWorkbookById, getMyWorkbooks } from '../../api/clients/workbook';
import { getWorksheetById, callAPItoSearchWorksheets } from '../../api/clients/worksheet';
import { getVesselById } from 'api/clients/vessel';
import * as workbookClient from 'api/clients/workbook';
import * as worksheetClient from 'api/clients/worksheet';
import { getRoute } from 'api/clients/route/get-route';
import { buildAction } from 'utilities/redux';
import {
  SET_WORKBOOK,
  LOAD_WORKSHEET,
  SEARCH_WORKSHEET,
  WORKBOOK_REMOVE_WORKSHEET,
  WORKBOOK_SET_ALL,
  WORKBOOK_REMOVE,
  SET_ACTIVE_WORKSHEET_ID,
  SET_WORKSHEET_STATUS,
  SET_ACTIVE_WORKBOOK_ID,
} from 'constants/action-types/workbook';
import {
  startTrackPage,
  stopTrackPage,
  trackEvent,
  eventDestination,
} from 'diagnostics/calc-trackevents';
import { WorksheetStatus } from 'constants/enums/worksheet-status';
import { rateLimited } from 'utilities/functions';
import { saveWorkbook } from 'api/clients/workbook';
import * as PromiseUtils from 'utilities/promise';
import { Geolocation } from 'utilities/location';
import { setRouteGraphVersion } from 'actions/stage';
import VError from 'verror';

export const setLastViewedWorksheetInWorkbook =
  (workbook, worksheetId: string) => async (dispatch) => {
    await saveWorkbook({
      ...workbook,
      lastViewedWorksheetId: worksheetId,
    });
  };

type LoadWorksheetFunction = (
  dispatch: (action) => void,
  getState: () => object,
  worksheetId: string,
  force: Boolean
) => Promise<void>;

const loadPromisesMap = {};

const rateLimitedLoadWorksheet: LoadWorksheetFunction = rateLimited(
  5 /* Number of concurrent operations at a time */,
  (dispatch, getState, ...params) => loadWorksheet(...params)(dispatch, getState)
);

export const loadWorksheet: LoadWorksheetFunction = (worksheetId: string, force: boolean = true) =>
  async function (dispatch, getState): Promise<IWorksheetDto> {
    if (!force) {
      const { worksheetsById } = getState();
      if (!worksheetsById[worksheetId]) {
        /**
         * This can happen when a user deletes a worksheet while multiple worksheets haven't finished loading
         * for example user deletes worksheet during worksheet comparison loading.
         */
        return;
      }
      if (worksheetsById[worksheetId].status === WorksheetStatus.LOADED) {
        return;
      }
      if (loadPromisesMap[worksheetId]) {
        await loadPromisesMap[worksheetId];
        return;
      }
    }

    const stateBeforeAnyAsyncCall = getState();

    await (loadPromisesMap[worksheetId] = PromiseUtils.fromAsyncBlock(async () => {
      try {
        dispatch({
          type: SET_WORKSHEET_STATUS,
          worksheetId: worksheetId,
          payload: WorksheetStatus.LOADING,
        });

        const worksheet = await getWorksheetById(worksheetId);

        {
          /* Hydrate immutable workbook properties into the worksheet, so that we're able to write self-contained worksheet components*/

          // Get this worksheet's workbook - use the `stateBeforeAnyAsyncCall`, so that if a developer ever changes the order of retrieval (doesn't wait for the workbook before loading the worksheet) he causes an error here and realizes the problem.

          const workbook = stateBeforeAnyAsyncCall.workbooksById[worksheet.workbookId];

          if (!workbook)
            throw new Error(
              'Failed to hydrate worksheet with immutable workbook properties due to the workbook not being present!'
            );

          Object.assign(worksheet, {
            marketSegmentId: workbook.marketSegmentId,
          });

          for (let vessel of worksheet.vessels) {
            if (!vessel.fleetTypeIds || !vessel.fleetTypeIds.length) {
              if (!!vessel && 0 !== vessel.vesselId) {
                try {
                  let newVessel = await getVesselById({
                    marketSegmentId: worksheet.marketSegmentId,
                    vesselId: vessel.vesselId,
                  });

                  if (newVessel && newVessel.fleetTypeIds) {
                    let newFleetTypeIds = newVessel.fleetTypeIds.map((x) => x);
                    vessel.fleetTypeIds = newFleetTypeIds;
                    vessel.speedAndConsumptions.isTankerIndexVessel = newVessel.isTankerIndexVessel;
                  }
                } catch (e) {
                  console.warn(e);
                }
              }
            }
          }
        }

        dispatch({
          worksheetId,
          type: LOAD_WORKSHEET,
          payload: worksheet,
        });
      } catch (e) {
        // TODO: remove once we start using `Async` in the `WorksheetComparison` module
        dispatch({
          worksheetId,
          type: SET_WORKSHEET_STATUS,
          payload: WorksheetStatus.FAILED,
        });
        throw e;
      } finally {
        delete loadPromisesMap[worksheetId];
      }
    }));
  };

export const loadWorksheetsByIds = (worksheetIds: UUID[]) =>
  async function (dispatch, getState): Promise<void> {
    var time = Date.now();
    try {
      /**
       * TODO consider using [startTrackEvent/stopTrackEvent](https://github.com/microsoft/ApplicationInsights-JS#sending-telemetry-to-the-azure-portal) to do the event tracking.
       */
      trackEvent(
        'actions/workbook',
        'Workbook Load Worksheets By Ids Start',
        {},
        {
          worksheetCount: worksheetIds.length,
        },
        eventDestination.ANALYSIS
      );
      await Promise.all(
        worksheetIds.map((worksheetId) =>
          rateLimitedLoadWorksheet(dispatch, getState, worksheetId, false)
        )
      );
    } finally {
      trackEvent(
        'actions/workbook',
        'Workbook Load Worksheets By Ids End',
        {},
        {
          duration: (Date.now() - time) / 1000,
        },
        eventDestination.ANALYSIS
      );
    }
  };

export const loadWorkbook = (workbookId) => async (dispatch) => {
  startTrackPage(`Workbook/${workbookId}`, eventDestination.ANALYSIS);
  const workbook = await getWorkbookById(workbookId);
  stopTrackPage(`Workbook/${workbookId}`, undefined, {}, {}, eventDestination.ANALYSIS);

  dispatch(buildAction(SET_WORKBOOK, workbook));

  const routingApiGraphVersion = await getRouteGraphVersionFromAPI();
  await dispatch(setRouteGraphVersion(routingApiGraphVersion));
  return workbook;
};

async function getRouteGraphVersionFromAPI() {
  // dummy Geolocation to call getRoute method and store graph api version in to redux store
  const fromLocation = new Geolocation(0, 0);
  const toLocation = new Geolocation(0, 1);
  let routingApiGraphVersion;
  try {
    const apiResult = await getRoute(fromLocation, toLocation, [], true, undefined, null);
    routingApiGraphVersion =
      apiResult?.computedOperationalRestrictionDistances?.routingApiGraphVersion ?? -1;
  } catch (error) {
    routingApiGraphVersion = -1;
  }
  return routingApiGraphVersion;
}

export const setActiveWorkbookId = (workbookId) => async (dispatch) => {
  dispatch(buildAction(SET_ACTIVE_WORKBOOK_ID, { workbookId }));
};

export const removeWorksheetFromWorkbook = (worksheetId, workbookId, nextWorksheetId) =>
  buildAction(WORKBOOK_REMOVE_WORKSHEET, {
    worksheetId,
    workbookId,
    nextWorksheetId,
  });

export const loadMyWorkbooks = () => async (dispatch) => {
  const workbooks = await getMyWorkbooks();
  dispatch(buildAction(WORKBOOK_SET_ALL, workbooks));
};

export const searchWorksheets = (input, userid, skip, take) => async (dispatch) => {
  const workbooks = await callAPItoSearchWorksheets(input, userid, skip, take);
  dispatch(buildAction(SEARCH_WORKSHEET, workbooks));
};

export const removeWorkbook = (workbookId) => {
  return buildAction(WORKBOOK_REMOVE, workbookId);
};

export const setActiveWorksheetId = (worksheetId, workbookId) => async (dispatch) => {
  dispatch(buildAction(SET_ACTIVE_WORKSHEET_ID, { worksheetId, workbookId }));
};

export const createWorkbookWithEmptyWorksheet =
  ({
    workbookName,
    worksheetId,
    marketSegmentId,
    createdFromProgramId,
    createdFromOriginId,
  }: {
    workbookName: string,
    marketSegmentId: MarketSegmentId,
    createdFromProgramId: ProgramId,
    createdFromOriginId: OriginId,
  }): AsyncAction<{
    workbookId: WorkbookId,
    worksheetId: WorksheetId,
  }> =>
  async (dispatch) => {
    let workbookId;
    let worksheet;
    try {
      workbookId = await workbookClient.createWorkbook({
        marketSegmentId: marketSegmentId,
      });
      worksheet = await worksheetClient.createEmptyWorksheetInWorkbook({
        createdFromProgramId,
        createdFromOriginId,
        worksheetId,
        workbookId,
        marketSegmentId,
      });
    } catch (e) {
      throw new VError(
        {
          name: createWorkbookWithEmptyWorksheetKnownErrorNames.ERROR_CREATING,
          cause: e,
        },
        'Error creating the workbook'
      );
    }

    try {
      /* Now rename the workbook. TODO - allow to specify the name at creation, to reduce the many roundtrips and make the user-actions audit represent the actual user actions */
      const workbook = {
        ...(await dispatch(loadWorkbook(workbookId))),
        name: workbookName,
        marketSegmentId: marketSegmentId,
      };

      await workbookClient.saveWorkbook(workbook);
      await dispatch(loadWorkbook(workbookId));
    } catch (err) {
      throw new VError(
        {
          name: createWorkbookWithEmptyWorksheetKnownErrorNames.ERROR_RENAMING,
          cause: err,
        },
        'Error renaming the workbook'
      );
    }

    return { workbookId: workbookId, worksheetId: worksheet.id };
  };

export const createWorkbookWithEmptyWorksheetKnownErrorNames = {
  ERROR_CREATING: 'ERROR_CREATING',
  ERROR_RENAMING: 'ERROR_RENAMING',
};
