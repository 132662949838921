import { createSelector } from 'reselect';
import { getActiveVesselEntryId } from './get-active-vessel-entry-id';
import { getActiveWorksheetCalculations } from './get-active-worksheet-calculations';
import { singleOrNullIfEmptyOrThrowIfMany } from '../utilities/iterable';
export const getActiveCalculationResult = createSelector(
  getActiveVesselEntryId,
  getActiveWorksheetCalculations,
  (activeVesselEntryId, activeWorksheetCalculations) =>
    activeWorksheetCalculations &&
    singleOrNullIfEmptyOrThrowIfMany(
      activeWorksheetCalculations.calculationResults.filter(
        (_) => _.vesselEntryId === activeVesselEntryId
      )
    )
);
