import { createSelector } from 'reselect';
import {
  BALLAST,
  LADEN,
  ECO_BALLAST,
  ECO_LADEN,
  SAILING,
  ECO_SAILING,
} from 'constants/enums/speed-and-con-types';
import { formatDateMMYYYYWithNullAsEmpty } from 'utilities/time';
import { getActiveVessel } from '../../../common-selectors/get-active-vessel';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';

export const selector = createSelector(
  getActiveVessel,
  getActiveCalculationResult,
  (vesselDetails, calculationResult) => {
    const ballast = { speed: null, consumption: null, label: BALLAST.label };
    const laden = { speed: null, consumption: null, label: LADEN.label };
    const sailing = { speed: null, consumption: null, label: SAILING.label };
    const isParcelVoyage = vesselDetails.isParcelVoyage;
    if (
      calculationResult &&
      calculationResult.calculationInputs &&
      calculationResult.calculationInputs.speedAndConsumptions
    ) {
      const speedAndConsumptions = calculationResult.calculationInputs.speedAndConsumptions;
      ballast.speed = speedAndConsumptions.ballast.speed;
      ballast.consumption = speedAndConsumptions.ballast.engineFuelConsumption;
      ballast.label = speedAndConsumptions.ballast.isActive ? BALLAST.label : ECO_BALLAST.label;
      laden.speed = speedAndConsumptions.laden.speed;
      laden.consumption = speedAndConsumptions.laden.engineFuelConsumption;
      laden.label = speedAndConsumptions.laden.isActive ? LADEN.label : ECO_LADEN.label;
      // sailing S&C details replaced with calc api result (either ballast or laden)
      if (isParcelVoyage) {
        sailing.speed = speedAndConsumptions.ballast.speed;
        sailing.consumption = speedAndConsumptions.ballast.engineFuelConsumption;
        sailing.label = speedAndConsumptions.ballast.isActive ? SAILING.label : ECO_SAILING.label;
      }
    }
    return {
      name: vesselDetails.name,
      speedAndConsumptions: {
        ballast: ballast,
        laden: laden,
        sailing: sailing,
      },
      yearOfBuild:
        vesselDetails.buildDate &&
        formatDateMMYYYYWithNullAsEmpty(new Date(vesselDetails.buildDate)),
      country: vesselDetails.buildCountry,
      yard: vesselDetails.shipyard,
      isParcelVoyage: isParcelVoyage,
    };
  }
);
