import round from 'lodash/round';
import { fromMetersToFeet, fromFeetToMeters } from 'utilities/units-converter';

export const METERS = { key: 1, label: 'm' };
export const FEET = { key: 2, label: 'ft' };

export const getDraftUnitTypeById = (typeId) => {
  switch (typeId) {
    case METERS.key:
      return METERS;
    case FEET.key:
      return FEET;
    default:
      throw new Error(`Unable to parse invalid draft unit type id ${typeId}`);
  }
};

const DraftUnitTypes = [METERS, FEET];

export const DRAFT_NUMBER_OF_DECIMAL_PLACES = 2;

export const convertDraft = (draft, fromDraftUnit, toDraftUnit) => {
  return fromDraftUnit === toDraftUnit
    ? draft
    : toDraftUnit === METERS
      ? round(fromFeetToMeters(draft), DRAFT_NUMBER_OF_DECIMAL_PLACES)
      : round(fromMetersToFeet(draft), DRAFT_NUMBER_OF_DECIMAL_PLACES);
};

export default DraftUnitTypes;
