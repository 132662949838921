// https://tools.ietf.org/html/rfc4122
// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export default function newGuid(): UUID {
  /* TODO - consider using crypto.getRandomValues() */
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const emptyGuid: UUID = '00000000-0000-0000-0000-000000000000';

export const isEmptyGuid = (guid: UUID): boolean => {
  return guid === emptyGuid;
};

export function isValidGuid(guid: string): boolean {
  if (typeof guid !== 'string') {
    return false;
  }

  //As per the StackOverflow [answer](https://stackoverflow.com/a/13653180/123451)
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
}
