import React from 'react';
import classNames from 'classnames';
import { eventDestination, trackClickEvent } from 'diagnostics/calc-trackevents';

import './styles.scss';

export const LinkButton = (props) => {
  const { className, children, onClick, diagnosticId, ...rest } = props;
  const classes = classNames('link-button', className);

  const handleOnClick = (value) => {
    if (onClick) {
      onClick(value);
    }
    trackClickEvent(diagnosticId);
  };

  return (
    <button className={classes} onClick={handleOnClick} {...rest}>
      {children}
    </button>
  );
};

export default React.memo(LinkButton);
