import React, { useEffect } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { Header } from 'components/headers';
import './styles.scss';
import { connect } from 'react-redux';
import { selector } from './selector';
import CalcInput from './../../components/calc-input';
import {
  additionalIncomeDescriptionChanged,
  additionalIncomeIncomeChanged,
  additionalIncomeAdded,
  additionalIncomeRemoved,
} from 'actions/worksheet/cargoes/additional-incomes';
import { MaterialIconButton } from '../../components/button';
import { iconEnum } from '../../components/icons';

type IProps = {
  worksheetId: string,
  additionalIncomes: IAdditionalIncomes[],
  additionalIncomeRemoved: (index: number, worksheetId: string) => {},
  additionalIncomeAdded: (worksheetId: string) => {},
  additionalIncomeDescriptionChanged: (
    worksheetId: string,
    index: number,
    description: string
  ) => {},
  additionalIncomeIncomeChanged: (worksheetId: string, index: number, income: number) => {},
};

type IAdditionalIncomeProps = {
  itemIndex: number,
  id: string,
  income: number,
  description: string,
  additionalIncomeDescriptionChangedHandler: Function,
  additionalIncomeIncomeChangedHandler: Function,
  additionalIncomeRemovedHandler: Function,
};

const AdditionalFreightIncomes: React.FC<IProps> = ({
  worksheetId,
  additionalIncomes,
  additionalIncomeRemoved,
  additionalIncomeAdded,
  additionalIncomeDescriptionChanged,
  additionalIncomeIncomeChanged,
}) => {
  useEffect(() => {
    if (additionalIncomes.length === 0) {
      additionalIncomeAddedHandler();
    }
  }, []);

  const additionalIncomeAddedHandler = () => {
    additionalIncomeAdded(worksheetId);
  };

  const additionalIncomeRemovedHandler = (index: number) => {
    additionalIncomeRemoved(index, worksheetId);
  };

  const additionalIncomeDescriptionChangedHandler = (index: number, description: string) => {
    additionalIncomeDescriptionChanged(worksheetId, index, description);
  };

  const additionalIncomeIncomeChangedHandler = (index: number, amount: number) => {
    additionalIncomeIncomeChanged(worksheetId, index, amount);
  };

  return (
    <div className="freight-incomes">
      <Header text="Additional Freight Incomes" />
      <table className="freight-incomes__table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {_.map(additionalIncomes, (item, index) => (
            <AdditionalIncome
              key={item.id}
              itemIndex={index}
              id={item.id}
              income={item.income}
              description={item.description}
              additionalIncomeDescriptionChangedHandler={additionalIncomeDescriptionChangedHandler}
              additionalIncomeIncomeChangedHandler={additionalIncomeIncomeChangedHandler}
              additionalIncomeRemovedHandler={additionalIncomeRemovedHandler}
            />
          ))}
          <tr>
            <td className="freight-incomes-add">
              <MaterialIconButton
                className="freight-incomes-add-btn"
                icon={iconEnum.AddCircle}
                onClick={additionalIncomeAddedHandler}
                diagnosticId="AdditionalFreightIncomes/AddIncome"
              />
              <span>Add</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const AdditionalIncome: React.FC<IAdditionalIncomeProps> = ({
  itemIndex,
  id,
  income,
  description,
  additionalIncomeDescriptionChangedHandler,
  additionalIncomeIncomeChangedHandler,
  additionalIncomeRemovedHandler,
}) => {
  return (
    <tr key={id}>
      <td>
        <input
          value={description}
          className="calc-input"
          maxLength={160}
          onChange={(event) => {
            additionalIncomeDescriptionChangedHandler(itemIndex, event.target.value);
          }}
        />
      </td>
      <td>
        <CalcInput
          userSpecifiedValue={income}
          className="freight-income-input"
          unit="$"
          maxDecimalDigits={2}
          minValue="0"
          isEditable
          unitPrecedesValue
          maxValue="999999999.99"
          onInputChange={(value) => {
            additionalIncomeIncomeChangedHandler(itemIndex, value);
          }}
          diagnosticId="AdditionalFreightIncome/ChangeAdditionalIncome"
        />
      </td>
      <td>
        <MaterialIconButton
          className="freight-incomes-remove-btn"
          icon={iconEnum.RemoveCircle}
          onClick={() => additionalIncomeRemovedHandler(itemIndex)}
          diagnosticId="AdditionalFreightIncome/RemoveIncome"
        />
      </td>
    </tr>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      additionalIncomeDescriptionChanged,
      additionalIncomeIncomeChanged,
      additionalIncomeAdded,
      additionalIncomeRemoved,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFreightIncomes);
