import axios from 'axios';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import { parseAsBoolean } from 'utilities/boolean';

export let applicationUrl = undefined;
export let oidcServerUrl = undefined;
export let cloudUrl = undefined;
export let vesselApiUrl = undefined;
export let arcUrl = undefined;
export let veUrl = undefined;
export let cdnUrl = undefined;
export let calculationUrl = undefined;
export let sandcUrl = undefined;
export let portDaUrl = undefined;
export let version = undefined;
export let environment = undefined;
export let featureToggles = undefined;
export let applicationInsights = undefined;
export let routingConfigCollection = undefined;
export let headerCdnUrl = undefined;

interface IRoutingConfig {
  avoidLocations: string[];
  avoidMultiplier: number;
  minimiseFuelCosts: boolean;
  useShippingLanes: boolean;
}

interface IRoutingConfigCollection {
  environmentGraphVersion: Number;
  config: IRoutingConfig;
}

const mergeConfigWithEnvironmentIfPresent = (config, featureToggles) => {
  let envConfig = JSON.parse(process.env.REACT_APP_PUBLIC_CONFIG_JSON || '{}');
  envConfig.oidcLogLevel = process.env.REACT_APP_OIDC_LOG_LEVEL || 'NONE';

  /* merge environment featureToggles with the config featureToggles
    e.g. if featureToggles are set in .env.development.local we merge them here.
  */

  merge(config, envConfig);
  merge(featureToggles, envConfig.featureToggles);

  var result = [config, featureToggles];
  return result;
};

let prevConfigPromise = null;
const getDefaultConfigFn = () => {
  // Ensure we only send the http request once
  if (!prevConfigPromise) {
    prevConfigPromise = axios
      .all([
        // Make sure that that `data` is returned, and not the axios response.
        axios.get('/config.json').then((result) => result.data),
        axios.get('/config.featureToggles.json').then((result) => result.data),
      ])
      .catch((reason) => {
        console.error('Failed to load configuration!', reason);
      });
  }
  return prevConfigPromise;
};

export const getConfig = async (fn: () => Promise = getDefaultConfigFn) => {
  const [defaultConfig, defaultFeatureTogglesConfig] = await fn();

  const [config, featureTogglesConfig] = mergeConfigWithEnvironmentIfPresent(
    defaultConfig,
    defaultFeatureTogglesConfig
  );

  parseFeatureToggles(featureTogglesConfig);

  applicationUrl = config.applicationUrl;
  oidcServerUrl = config.oidcServerUrl;
  cloudUrl = config.cloudUrl;
  vesselApiUrl = config.vesselApiUrl;
  arcUrl = config.arcUrl;
  veUrl = config.veUrl;
  cdnUrl = config.cdnUrl;
  calculationUrl = config.calculationUrl;
  sandcUrl = config.sandcUrl;
  portDaUrl = config.portDaUrl;
  version = config.version;
  environment = config.environment;
  featureToggles = featureTogglesConfig;
  applicationInsights = config.applicationInsights;
  routingConfigCollection = parseRoutingConfigCollection(config.routing);
  headerCdnUrl = config.headerCdnUrl;

  return config;
};

const parseRoutingConfigCollection = (routing) => {
  let routingConfigCollection: IRoutingConfigCollection = {};

  Object.keys(routing).forEach((key) => {
    const versionKey = parseInt(key.replace('routing-', ''));
    routingConfigCollection[versionKey] = parseRoutingConfig(routing[key]);
  });

  return routingConfigCollection;
};

const parseRoutingConfig = (route) => {
  let routingConfig: IRoutingConfig;
  routingConfig = {
    avoidMultiplier: route.avoidMultiplier,
    minimiseFuelCosts: route.minimiseFuelCosts,
    useShippingLanes: route.useShippingLanes,
    avoidLocations: route.avoidLocations.map((l) => {
      return l.id;
    }),
  };
  return routingConfig;
};

//Currently, azure devops releases only supports string variable substitution.
//https://docs.microsoft.com/en-us/azure/devops/pipelines/tasks/transforms-variable-substitution?view=azure-devops&viewFallbackFrom=vsts#json-variable-substitution
//This means data types like booleans end up stringified. E.g. true => "true".
//This function parses all feature toogles back to a boolean data type.
//It is relaxed to allow boolean datatypes through for local dev environments.
const parseFeatureToggles = (featureToggles) => {
  if (isNil(featureToggles)) {
    featureToggles = {};
  } else {
    for (const key of Object.keys(featureToggles)) {
      featureToggles[key] = parseAsBoolean(featureToggles[key]);
    }
  }
};
