import { veApi } from 'api';
import * as axiosUtils from 'api/clients/utilities/axios-utils';
import { NOT_FOUND } from 'http-status-codes';
import { WorksheetError } from 'modules/worksheet/worksheet-error';
import { WorksheetErrorTypes } from 'constants/enums/worksheet-error-types';
import { isFeatureEnabled } from 'config/feature-control';

export async function getWorksheetById(
  worksheetId: string,
  options: { cancelToken: CancelToken }
): Promise<IWorksheetDto> {
  try {
    const worksheet = axiosUtils.getValidObjectFromAxiosJsonResponse(
      await veApi.get(`/worksheet/${worksheetId}`, {
        cancelToken: options && options.cancelToken,
      })
    );
    return worksheet;
  } catch (err) {
    if (err.response && err.response.status === NOT_FOUND)
      throw new WorksheetError(`Worksheet does not exist`, WorksheetErrorTypes.DOES_NOT_EXISTs);
    else throw err;
  }
}

export async function callAPItoSearchWorksheets(input, userID, skip, take, cancelToken) {
  const worksheets = await veApi.get(
    `/worksheet/Search?input=${input}&userid=${userID}&skip=${skip}&take=${take}`,
    {
      cancelToken: cancelToken,
    }
  );
  return worksheets;
}

export function hydrateWorksheet(worksheet) {
  const voyage = worksheet && worksheet.voyage;
  if (voyage && voyage.legs) {
    for (const leg of voyage.legs) {
      for (const inboundRouteVariant of leg.inboundRoute.variants) {
        if (inboundRouteVariant.routeRetrievedFromRoutingApiOn) {
          inboundRouteVariant.routeRetrievedFromRoutingApiOn = new Date(
            inboundRouteVariant.routeRetrievedFromRoutingApiOn
          );
        }
      }
    }
  }
}

export type ProgramId =
  | 'SeaCalcUI'
  | 'SeaCalcSdk-createWorksheetWithVesselIds'
  | 'SeaCalcSdk-createWorksheetForCargoOrder'
  | 'MigrationFromVE1'
  | string;

export type OriginId = string;

export type WorksheetUpdateSuccessResult = {
  id: WorksheetId,
};

export type WorksheetCreateSuccessResult = {
  id: WorksheetId,
  name: string,
};

export interface WorksheetRequest {
  createdFromProgramId: ProgramId;
  createdFromOriginId: OriginId;
}

export interface CreateEmptyWorksheetRequest extends WorksheetRequest {
  worksheetId?: UUID;
  workbookId: UUID;
  marketSegmentId: MarketSegmentId;
}

export async function createEmptyWorksheetInWorkbook(
  {
    createdFromProgramId,
    createdFromOriginId,
    worksheetId,
    workbookId,
    marketSegmentId,
  }: CreateEmptyWorksheetRequest,
  options?: { cancelToken: CancelToken }
): WorksheetCreateSuccessResult | WorksheetUpdateSuccessResult {
  if (worksheetId) {
    await createOrUpdateWorksheet(
      {
        worksheetId,
        workbookId,
        worksheet: null,
        createdFromProgramId,
        createdFromOriginId,
      },
      options
    );

    return {
      id: worksheetId,
    };
  }

  var url = isFeatureEnabled('refinitivEuaPrices') ? `/worksheet/Refinitiv` : `/worksheet`;

  const response = await veApi.post(
    url,
    {
      createdFromProgramId: createdFromProgramId,
      createdFromOriginId: createdFromOriginId,
      worksheetId: worksheetId,
      workbookId: workbookId,
      marketSegmentId: marketSegmentId,
      worksheet: null,
    },
    {
      cancelToken: options && options.cancelToken,
    }
  );

  return response.data;
}

export interface CreateWorksheetWithDataRequest extends WorksheetRequest {
  worksheet: IWorksheetDto;
  copiedFromWorksheetId: WorksheetId;
}

export async function createWorksheetWithData(
  { createdFromProgramId, createdFromOriginId, worksheet }: CreateWorksheetWithDataRequest,
  options?: { cancelToken: CancelToken }
): WorksheetCreateSuccessResult {
  var url = isFeatureEnabled('refinitivEuaPrices') ? `/worksheet/Refinitiv` : `/worksheet`;
  const response = await veApi.post(
    url,
    {
      createdFromProgramId: createdFromProgramId,
      createdFromOriginId: createdFromOriginId,
      copiedFromWorksheetId: worksheet.id,
      workbookId: worksheet.workbookId,
      worksheet: {
        ...worksheet,
        id: undefined /* Server-side is responsible for generating the `id`. We don't need to remove it from the payload because the server ignores the property anyway, but let's do remove, even if just to not confuse anyone who's testing this */,
      },
    },
    {
      cancelToken: options && options.cancelToken,
    }
  );

  return response.data;
}

export type SaveWorksheetSuccessResult = {
  worksheetId: String,
  worksheetName: String,
  updatedDate: string,
};

export async function saveWorksheet(
  worksheet: IWorksheetDto,
  options: { cancelToken: CancelToken }
): SaveWorksheetSuccessResult {
  return await createOrUpdateWorksheet(
    {
      worksheetId: worksheet.id,
      workbookId: worksheet.workbookId,
      worksheet,
    },
    options
  );
}

type CreateOrUpdateWorksheetRequest = {
  worksheetId: UUID,
  workbookId: UUID,
  worksheet?: IWorksheetDto,
  createdFromProgramId?: ProgramId,
  createdFromOriginId?: OriginId,
};

async function createOrUpdateWorksheet(
  {
    createdFromProgramId,
    createdFromOriginId,
    worksheetId,
    workbookId,
    worksheet,
  }: CreateOrUpdateWorksheetRequest,
  options: { cancelToken: CancelToken }
): SuccessfulResult {
  // set  graph version 0 if its null
  if (worksheet && worksheet.voyage && worksheet.voyage.legs) {
    worksheet.voyage.legs.forEach((leg) => {
      if (
        leg &&
        leg.inboundRoute &&
        leg.inboundRoute.variants &&
        leg.inboundRoute.variants.length
      ) {
        leg.inboundRoute.variants.forEach((v) => {
          if (!v.routeRetrievedWithGraphVersion) {
            v.routeRetrievedWithGraphVersion = 0;
          }
        });
      }
    });
  }
  var useCoreCargoType = isFeatureEnabled('useCoreCargoTypes');
  var url = isFeatureEnabled('refinitivEuaPrices')
    ? `/Worksheet/Refinitiv/${worksheetId}`
    : `/Worksheet/${worksheetId}`;
  await veApi.put(
    url,
    {
      workbookId,
      worksheet,
      createdFromProgramId,
      createdFromOriginId,
    },
    {
      cancelToken: options && options.cancelToken,
      headers: {
        'X-Calc-Use-Core-Cargo-Type': useCoreCargoType,
      },
    }
  );
}

export async function deleteWorksheet({
  worksheetId,
  workbookId,
}: {
  worksheetId: WorksheetId,
  workbookId: WorkbookId,
}): void {
  await veApi({
    method: 'delete',
    url: `worksheet/${worksheetId}`,
    data: { workbookId: workbookId },
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export interface CopyMoveWorksheetRequest extends WorksheetRequest {
  worksheetId: string;
  selectedWorkbookId?: string;
  workbookName: string;
  worksheetName?: string;
}
export interface CopyMoveWorksheetSuccessResult {
  actionName: string;
  workbookId: string;
  statusCode: number;
  worksheetId: string;
}

export async function copyWorksheetToExistingWorkbook(
  {
    worksheetId,
    selectedWorkbookId,
    workbookName,
    worksheetName,
    createdFromProgramId,
    createdFromOriginId,
  }: CopyMoveWorksheetRequest,
  options?: { cancelToken: CancelToken }
): CopyMoveWorksheetSuccessResult {
  const response = await veApi.post(
    `/worksheet/copy-to-existing-workbook`,
    {
      worksheetId: worksheetId,
      destinationWorkbookId: selectedWorkbookId,
      workbookName: workbookName,
      worksheetName: worksheetName,
      createdFromProgramId: createdFromProgramId,
      createdFromOriginId: createdFromOriginId,
    },
    {
      cancelToken: options && options.cancelToken,
    }
  );

  return response.data;
}

export async function copyWorksheetToNewWorkbook(
  {
    worksheetId,
    workbookName,
    worksheetName,
    createdFromProgramId,
    createdFromOriginId,
  }: CopyMoveWorksheetRequest,
  options?: { cancelToken: CancelToken }
): CopyMoveWorksheetSuccessResult {
  const response = await veApi.post(
    `/worksheet/copy-to-new-workbook`,
    {
      worksheetId: worksheetId,
      workbookName: workbookName,
      worksheetName: worksheetName,
      createdFromProgramId: createdFromProgramId,
      createdFromOriginId: createdFromOriginId,
    },
    {
      cancelToken: options && options.cancelToken,
    }
  );

  return response.data;
}
export async function moveWorksheetToExistingWorkbook(
  {
    worksheetId,
    selectedWorkbookId,
    workbookName,
    createdFromProgramId,
    createdFromOriginId,
  }: CopyMoveWorksheetRequest,
  options?: { cancelToken: CancelToken }
): CopyMoveWorksheetSuccessResult {
  const response = await veApi.post(
    `/worksheet/move-to-existing-workbook`,
    {
      worksheetId: worksheetId,
      destinationWorkbookId: selectedWorkbookId,
      workbookName: workbookName,
      createdFromProgramId: createdFromProgramId,
      createdFromOriginId: createdFromOriginId,
    },
    {
      cancelToken: options && options.cancelToken,
    }
  );

  return response.data;
}

export async function moveWorksheetToNewWorkbook(
  {
    worksheetId,
    workbookName,
    createdFromProgramId,
    createdFromOriginId,
  }: CopyMoveWorksheetRequest,
  options?: { cancelToken: CancelToken }
): CopyMoveWorksheetSuccessResult {
  const response = await veApi.post(
    `/worksheet/move-to-new-workbook`,
    {
      worksheetId: worksheetId,
      workbookName: workbookName,
      createdFromProgramId: createdFromProgramId,
      createdFromOriginId: createdFromOriginId,
    },
    {
      cancelToken: options && options.cancelToken,
    }
  );

  return response.data;
}
