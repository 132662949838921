import { createSelector } from 'reselect';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { emptyGuid } from '../../utilities/guid';
import filter from 'lodash/filter';
import { getActiveWorkbookId } from '../../common-selectors/get-active-workbook-id';

export const selector = createSelector(
  getActiveWorksheet,
  getActiveWorkbookId,
  (state) => filter(state.worksheetsById, (e) => e.workbookId === state.activeWorkbookId),
  (activeWorksheet, activeWorkbookId, worksheets) => {
    const worksheetId = activeWorksheet.id;
    const nextWorksheetId = findNextWorksheetId(worksheetId, worksheets);
    return {
      activeWorksheet: activeWorksheet,
      nextWorksheetId: nextWorksheetId,
      activeWorkbookId: activeWorkbookId,
    };
  }
);
// It's a duplicated piece of code. It has to be refactored - probably util will be a good solution
const findNextWorksheetId = (currentWorksheetId, worksheets) => {
  if (!worksheets || worksheets.length < 2) {
    return emptyGuid;
  }
  const currentIndex = worksheets.findIndex((w) => w.id === currentWorksheetId);

  if (currentIndex < 0) {
    return emptyGuid;
  }

  if (currentIndex === 0) {
    return worksheets[currentIndex + 1].id;
  }

  return worksheets[currentIndex + 1 < worksheets.length ? currentIndex + 1 : currentIndex - 1].id;
};
