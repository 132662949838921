import {
  CARGO_CHANGED,
  CARGO_STOWAGE_CHANGED,
  CARGO_SPECIFIC_GRAVITY_CHANGED,
  CARGO_STOWAGE_UNIT_CHANGED,
  CARGO_GROSS_VOYAGE_RATE_CHANGED,
  CARGO_BROKER_COMMISSION_VOYAGE_RATE_CHANGED,
  CARGO_ADDRESS_COMMISSION_VOYAGE_RATE_CHANGED,
} from 'constants/action-types/worksheet/cargoes';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import guid from 'utilities/guid';
import { singleOrThrow } from 'utilities/iterable';
import { COMMISSION_CHANGED } from 'constants/action-types/worksheet/commission';
import {
  TANKER_RATE_TYPE_CHANGED,
  TANKER_FLAT_RATE_CHANGED,
  TANKER_WORLD_SCALE_CHANGED,
  TANKER_GROSS_VOYAGE_RATE_CHANGED,
  TANKER_LUMP_SUM_CHANGED,
} from './../../../constants/action-types/worksheet/tanker-rates/index';
import { DollarsPerMetricTonne } from 'constants/enums/tanker-rates';
export const legacyAlphabeticalIdentifierStartingValForMultiCargoFeature = 'A';

const initialState = [
  {
    id: guid(),
    cargoTypeId: null,
    name: '',
    code: legacyAlphabeticalIdentifierStartingValForMultiCargoFeature,
    specificGravity: 0,
    stowage: 0,
    stowageUnit: 2,
    cargoRate: {
      rateType: DollarsPerMetricTonne.key,
      grossVoyageRate: 0,
      flatRate: 0,
      worldscale: 0,
      lumpSum: 0,
    },
    brokerCommissionVoyageRate: 0,
    addressCommissionVoyageRate: 0,
    marketSegmentId: undefined,
  },
];

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return getCargoesViewModelFromWorksheetDto(action.payload);
    case CARGO_CHANGED:
      return state.map((cargo) =>
        cargo.code === action.cargoCode
          ? {
              ...cargo,
              cargoTypeId: action.payload.cargoTypeId,
              name: action.payload.name,
            }
          : cargo
      );
    case CARGO_SPECIFIC_GRAVITY_CHANGED:
      return state.map((cargo) =>
        cargo.code === action.cargoCode ? { ...cargo, specificGravity: action.payload } : cargo
      );
    case CARGO_STOWAGE_CHANGED:
      return state.map((cargo) =>
        cargo.code === action.cargoCode ? { ...cargo, stowage: action.payload } : cargo
      );
    case CARGO_STOWAGE_UNIT_CHANGED:
      return state.map((cargo) =>
        cargo.code === action.cargoCode
          ? {
              ...cargo,
              stowage: action.payload.stowage,
              stowageUnit: action.payload.stowageUnit,
            }
          : cargo
      );
    case CARGO_GROSS_VOYAGE_RATE_CHANGED:
      return state.map((cargo) =>
        cargo.code === action.cargoCode
          ? {
              ...cargo,
              cargoRate: {
                ...cargo.cargoRate,
                grossVoyageRate: action.payload,
              },
            }
          : cargo
      );
    case TANKER_RATE_TYPE_CHANGED:
      return state.map((cargo, index) => {
        return {
          ...cargo,
          cargoRate: {
            ...cargo.cargoRate,
            rateType: action.payload.value,
          },
        };
      });
    case TANKER_FLAT_RATE_CHANGED:
      return state.map((cargo, index) => {
        return {
          ...cargo,
          cargoRate: {
            ...cargo.cargoRate,
            flatRate: action.payload,
          },
        };
      });
    case TANKER_WORLD_SCALE_CHANGED:
      return state.map((cargo, index) => {
        return {
          ...cargo,
          cargoRate: {
            ...cargo.cargoRate,
            worldscale: action.payload,
          },
        };
      });
    case TANKER_GROSS_VOYAGE_RATE_CHANGED:
      return state.map((cargo, index) => {
        return {
          ...cargo,
          cargoRate: {
            ...cargo.cargoRate,
            grossVoyageRate: action.payload,
          },
        };
      });
    case TANKER_LUMP_SUM_CHANGED:
      return state.map((cargo, index) => {
        return {
          ...cargo,
          cargoRate: {
            ...cargo.cargoRate,
            lumpSum: action.payload,
          },
        };
      });

    case CARGO_BROKER_COMMISSION_VOYAGE_RATE_CHANGED:
      return state.map((cargo) =>
        cargo.code === action.cargoCode
          ? { ...cargo, brokerCommissionVoyageRate: action.payload }
          : cargo
      );
    case CARGO_ADDRESS_COMMISSION_VOYAGE_RATE_CHANGED:
      return state.map((cargo) =>
        cargo.code === action.cargoCode
          ? { ...cargo, addressCommissionVoyageRate: action.payload }
          : cargo
      );
    case COMMISSION_CHANGED:
      return state.map((cargo) => ({
        ...cargo,
        brokerCommissionVoyageRate: action.payload.brokerCommission,
        addressCommissionVoyageRate: 0,
      }));
    default:
      return state;
  }
};

export function getCargoesViewModelFromWorksheetDto({ cargoes, marketSegmentId }) {
  const cargo = singleOrThrow(cargoes);
  return [
    {
      id: cargo.id,
      cargoTypeId: cargo.cargoTypeId,
      name: cargo.name,
      code: cargo.cargoCode,
      stowage: cargo.stowage,
      stowageUnit: cargo.stowageUnit,
      cargoRate: cargo.cargoRate,
      brokerCommissionVoyageRate: cargo.brokerCommission,
      addressCommissionVoyageRate: cargo.addressCommission,
      specificGravity: cargo.specificGravity,
      marketSegmentId,
    },
  ];
}

/**
 * The function is used to transform the redux object into the
 * equivalent worksheet dto. This is the dto received from the API.
 * The reasoning is to slowly transform the redux store to store only the DTOs.
 * TODO: This should be removed once the redux store is storing the DTOs.
 */
export const transformToWorksheetCargoes = ({ cargoes: cargoesInRedux }) => {
  return cargoesInRedux.map((cargo) => {
    const { addressCommissionVoyageRate, brokerCommissionVoyageRate, code, ...rest } = cargo;

    return {
      addressCommission: addressCommissionVoyageRate,
      brokerCommission: brokerCommissionVoyageRate,
      cargoCode: code,
      ...rest,
    };
  });
};
