import React from 'react';
import { PrimaryButton } from 'components/button';
import { iconEnum, WarningIcon } from 'components/icons';

import './styles.scss';

export const VersionMismatchContent = () => {
  return (
    <div className="version-mismatch-content">
      <div className="version-mismatch-content__header">
        <WarningIcon />
        &nbsp;Refresh Required
      </div>
      <div className="version-mismatch-content__text">
        <p>Select 'Refresh' to get the latest Sea/calc version</p>
      </div>
      <div className="version-mismatch-content__actions">
        <PrimaryButton
          icon={iconEnum.Retry}
          onClick={() => window.location.reload()}
          diagnosticId="VersionMismatchContent/Refresh"
        >
          Refresh
        </PrimaryButton>
      </div>
    </div>
  );
};
