import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import numbro from 'numbro';
import isNil from 'lodash/isNil';
import ExpandCollapseButton from 'components/expand-collapse/expand-collapse-button';
import classNames from 'classnames';
import { charterTypes } from 'constants/enums/charter-types';

import './styles.scss';
import { selector } from './selector';
import { isDry } from 'constants/market-segments';

type RowModel = {
  name: string,
  voyageRateValue: number,
  timeCharterValue: number,
};

type RowViewModel = RowModel & {
  name: React.Node,
  expandCollapseButton?: React.Node,
};
export const CalculationSummaryCostBreakdown = React.memo(
  ({ showTimeCharter, showVoyageRate, ...props }) => {
    // We need to remove properties from calculation that isn't actually performed. TODO - consider refactoring so we only get properties from the calculation that was performed and remove this function
    const removeInactiveCalculations = (rows: Iterable<RowModel>) =>
      rows.map((row) => ({
        ...row,
        voyageRateValue: showVoyageRate ? row.voyageRateValue : undefined,
        timeCharterValue: showTimeCharter ? row.timeCharterValue : undefined,
      }));

    return (
      <table className="summary-cost-data">
        <thead>
          <tr className="summary-cost-data__header">
            <th className="summary-cost-data__header-cell summary-cost-data__header-cell-expand-collapse" />
            <th className="summary-cost-data__header-cell summary-cost-data__header-cell-label" />
            <th className="summary-cost-data__header-cell summary-cost-data__cell--numeric">
              Time Charter
            </th>
            <th className="summary-cost-data__header-cell summary-cost-data__cell--numeric">
              Voyage Rate
            </th>
          </tr>
        </thead>
        <tbody>
          {isDry(props.worksheetInvariantProps.marketSegmentId) ? (
            <RowsGrouped groupName="Bunkers">
              {removeInactiveCalculations([
                {
                  id: 'Bunkers (Ballast)',
                  name: 'Bunkers (Ballast)',
                  voyageRateValue: props.bunkersBallastCosts,
                  timeCharterValue: props.bunkersBallastCosts,
                },
                {
                  id: 'Bunkers (Laden)',
                  name: 'Bunkers (Laden)',
                  voyageRateValue: props.bunkersLadenCosts,
                  timeCharterValue: props.bunkersLadenCosts,
                },
                {
                  id: 'Port Bunker Costs',
                  name: 'Port Bunker Costs',
                  voyageRateValue: props.bunkersAtPortCosts,
                  timeCharterValue: props.bunkersAtPortCosts,
                },
              ])}
            </RowsGrouped>
          ) : (
            <RowsGrouped groupName="Bunkers">
              {removeInactiveCalculations([
                {
                  id: 'Load',
                  name: 'Load',
                  voyageRateValue: props.bunkersLoadCosts,
                  timeCharterValue: props.bunkersLoadCosts,
                },
                {
                  id: 'Discharge',
                  name: 'Discharge',
                  voyageRateValue: props.bunkersDischargeCosts,
                  timeCharterValue: props.bunkersDischargeCosts,
                },
                {
                  id: 'Ballast',
                  name: 'Ballast',
                  voyageRateValue: props.bunkersBallastCosts,
                  timeCharterValue: props.bunkersBallastCosts,
                },
                {
                  id: 'Laden',
                  name: 'Laden',
                  voyageRateValue: props.bunkersLadenCosts,
                  timeCharterValue: props.bunkersLadenCosts,
                },
                {
                  id: 'Idle',
                  name: 'Idle',
                  voyageRateValue: props.bunkersIdleCosts,
                  timeCharterValue: props.bunkersIdleCosts,
                },
              ])}
            </RowsGrouped>
          )}
          <Rows className="summary-cost-data__row-top-level-item">
            {removeInactiveCalculations([
              {
                id: 'Port Costs',
                name: 'Port Costs',
                voyageRateValue: props.portCosts,
                timeCharterValue: props.portCosts,
              },
              {
                id: 'Total TC Costs',
                name: 'Total TC Costs',
                timeCharterValue: props.timeCharterCosts,
              },
            ])}
          </Rows>
          {props.carbonCostIncludeInCosts && (
            <Rows className="summary-cost-data__row-top-level-item">
              {removeInactiveCalculations([
                {
                  id: 'Total Carbon Costs',
                  name: 'Total Carbon Costs',
                  voyageRateValue: formatNumber(props.totalCarbonCosts),
                  timeCharterValue: formatNumber(props.totalCarbonCosts),
                },
              ])}
            </Rows>
          )}
          <RowsGrouped groupName="Additional Costs">
            {removeInactiveCalculations(
              props.additionalCostItems.map((_) => ({
                id: _.id,
                name: _.name,
                voyageRateValue: _.charterTypes.includes(charterTypes.voyage)
                  ? _.calculatedAmount
                  : 0,
                timeCharterValue: _.charterTypes.includes(charterTypes.time)
                  ? _.calculatedAmount
                  : 0,
              }))
            )}
          </RowsGrouped>
        </tbody>
        <tfoot>
          <tr className="summary-cost-data__footer">
            <th className="summary-cost-data__footer-cell summary-cost-data__footer-cell-expand-collapse" />
            <th className="summary-cost-data__footer-cell summary-cost-data__footer-cell-label">
              Total Net Voyage Costs
            </th>
            <th className="summary-cost-data__footer-cell summary-cost-data__footer-cell-value summary-cost-data__cell--numeric">
              {showTimeCharter && formatNumber(props.totalVoyageCostsTimeCharter)}
            </th>
            <th className="summary-cost-data__footer-cell summary-cost-data__footer-cell-value summary-cost-data__cell--numeric">
              {showVoyageRate && formatNumber(props.totalExpensesNetVoyageRate)}
            </th>
          </tr>
        </tfoot>
      </table>
    );
  }
);

const Rows = React.memo(({ children: items, className }: { children: Iterable<RowViewModel> }) =>
  items.map((item) => (
    <tr key={item.id || item.name} className={classNames(className, 'summary-cost-data__row')}>
      <td className="summary-cost-data__row-cell summary-cost-data__row-cell-expand-collapse">
        {item.expandCollapseButton}
      </td>
      <td className="summary-cost-data__row-cell summary-cost-data__row-cell-label">{item.name}</td>
      <td className="summary-cost-data__row-cell summary-cost-data__row-cell-value summary-cost-data__cell--numeric">
        {formatNumber(item.timeCharterValue)}
      </td>
      <td className="summary-cost-data__row-cell summary-cost-data__row-cell-value summary-cost-data__cell--numeric">
        {formatNumber(item.voyageRateValue)}
      </td>
    </tr>
  ))
);

export class RowsGrouped extends Component<{
  groupName: string,
  children: Iterable<RowModel>,
}> {
  state: { isExpanded: boolean } = { isExpanded: false };

  render() {
    const { groupName, children: items } = this.props;
    const isDisabled = !items || items.length === 0;

    return (
      <Fragment>
        <Rows>
          {[
            {
              id: { groupName },
              expandCollapseButton: (
                <ExpandCollapseButton
                  expanded={isDisabled ? false : this.state.isExpanded}
                  onClick={this.handleExpandCollapseClick}
                  disabled={isDisabled}
                />
              ),
              name: (
                <span
                  className="summary-cost-data__row-group-name"
                  onClick={isDisabled ? null : this.handleExpandCollapseClick}
                >
                  {groupName}
                </span>
              ),
              timeCharterValue: RowsGrouped.sumNumbersKeepingAllNilsAsNil(
                items.map((_) => _.timeCharterValue)
              ),
              voyageRateValue: RowsGrouped.sumNumbersKeepingAllNilsAsNil(
                items.map((_) => _.voyageRateValue)
              ),
            },
          ]}
        </Rows>
        {this.state.isExpanded && (
          <Rows className="summary-cost-data__row--group-member">{items}</Rows>
        )}
      </Fragment>
    );
  }

  handleExpandCollapseClick = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  static sumNumbersKeepingAllNilsAsNil(source: Iterable<number>) {
    return source.reduce(
      (partial_sum, item) =>
        isNil(item) ? partial_sum : isNil(partial_sum) ? item : partial_sum + item,
      undefined /* Value to return in case the source is an empty Iterable */
    );
  }
}

const mapStateToProps = selector;

const formatNumber = (input) => {
  if (isNil(input)) return '';
  return numbro(input).format({
    thousandSeparated: true,
    mantissa: 2,
  });
};

export default connect(mapStateToProps)(CalculationSummaryCostBreakdown);
