import React from 'react';
import numbro from 'numbro';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { TableRow } from 'components/table';
import DataCell from '../data-cell';

const formatNumber = (input) =>
  !isNil(input)
    ? numbro(input).format({
        thousandSeparated: true,
        mantissa: 2,
      })
    : '―';

const DataRow = ({ rowData, rowIndex, highlight }) => (
  <TableRow
    className={classNames({
      'sensitivity__table__row--highlight': highlight,
    })}
  >
    {rowData.map((data, cellIndex) => (
      <DataCell key={`sensitivity-table-${rowIndex}-${cellIndex}`}>{formatNumber(data)}</DataCell>
    ))}
  </TableRow>
);

export default DataRow;
