import { veApi } from 'api';
import { BAD_REQUEST } from 'http-status-codes';
import { RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable } from './';
import { routingConfigCollection } from 'config';

export async function getRoute(
  fromLocation,
  toLocation,
  waypointsToExcludeIds,
  avoidSecaZones,
  marketSegmentId,
  cancelToken?: CancelToken
) {
  try {
    const apiGraphConfigResponse = await veApi.get('/route/graph-version');

    const payload = {
      from: fromLocation,
      to: toLocation,
      tryMinimiseVoyageInEmissionControlAreas: avoidSecaZones,
      excludeLocationIds: waypointsToExcludeIds,
      routingOption: routingConfigCollection[apiGraphConfigResponse.data.environmentGraphVersion],
      marketSegmentId: marketSegmentId,
    };

    const apiResponse = await veApi.post('/route/calculate', payload, {
      cancelToken: cancelToken,
    });

    return apiResponse.data;
  } catch (error) {
    var { response } = error;

    if (
      response.status === BAD_REQUEST &&
      response.data != null &&
      response.data.reasonIsUnsuccessful ===
        RouteCalculationUnsuccessfulReason_RequestedAvoidWaypointsUnavoidable
    ) {
      return error.response.data;
    }

    throw error;
  }
}
