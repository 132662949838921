import React, { PureComponent } from 'react';

import noop from 'lodash/noop';
import classNames from 'classnames';

import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

import './styles.scss';

export class DeleteWithConfirmationButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmationVisible: false,
    };
  }

  toggleConfirmation = (isConfirmationVisible, callback = noop) => {
    this.setState(
      {
        isConfirmationVisible,
      },
      // allow the callback to be a promise, this wraps the promise within a function
      // this can then be called by setState once the state has been updated
      () => callback()
    );
  };

  showConfirmation = () => this.toggleConfirmation(true);

  handleConfirm = () => this.toggleConfirmation(false, this.props.onConfirm);

  handleMouseLeave = () => this.toggleConfirmation(false);

  render() {
    const { isConfirmationVisible } = this.state;
    const { className, isDisabled } = this.props;

    const buttonClasses = classNames('delete-with-confirmation-button', className);

    const iconClasses = classNames('delete-with-confirmation-button__icon', {
      'delete-with-confirmation-button__icon--confirmed': isConfirmationVisible,
    });

    const confirmationClasses = classNames('delete-with-confirmation-button__confirmation', {
      'delete-with-confirmation-button__confirmation--visible': isConfirmationVisible,
    });

    return (
      <div className={buttonClasses} onMouseLeave={this.handleMouseLeave}>
        <IconButton
          icon={iconEnum.Delete}
          className={iconClasses}
          onClick={this.showConfirmation}
          disabled={isDisabled}
          diagnosticId={this.props.diagnosticId}
        />
        <button className={confirmationClasses} onClick={this.handleConfirm}>
          Confirm
        </button>
      </div>
    );
  }
}
