import isNil from 'lodash/isNil';
import { parseDate } from 'utilities/time';
import * as actionTypes from 'constants/action-types/worksheet/vessel';
import guid, { emptyGuid } from 'utilities/guid';
import SpeedAndConsumptionsReducer from './speed-and-consumptions';
import { METERS, convertDraft, getDraftUnitTypeById } from 'constants/enums/draft-units';
import { TPC, convertImmersion, getImmersionUnitTypeById } from 'constants/enums/immersion-units';
import { CUBIC_METERS, convertGrain, getGrainUnitTypeById } from 'constants/enums/grain-units';
import type { VesselAction } from './types';
import { RATE_COMMISSION_TIME_CHARTER_CHANGED } from 'constants/action-types/worksheet/rates';
import {
  RATE_TIME_CHARTER_CHANGED,
  RATE_BALLAST_BONUS_CHANGED,
} from 'constants/action-types/worksheet/vessel/rates';
import { speedAndConsumptionsInitialState } from './speed-and-consumptions';
import { grossFromNet } from 'utilities/rates-converter';
import { getSpeedAndConsumptionViewModelFromSpeedAndConsDto } from './speed-and-consumptions/index';
import { MarketSegmentId } from 'constants/market-segments';
import logger from 'diagnostics/logger';

export const createEmptyVessel = () => ({
  entryId: null,
  vesselId: null,
  name: '',
  openPosition: {
    id: emptyGuid,
    locationTypeId: null,
    name: '',
    zone: '',
    country: '',
    longitude: null,
    latitude: null,
    prevPortOfCallIsInEea: false,
    openDate: {
      start: null,
      end: null,
      displayText: '',
    },
  },
  initialOpenPosition: {
    id: emptyGuid,
    locationTypeId: null,
    name: '',
    zone: '',
    country: '',
    longitude: null,
    latitude: null,
    prevPortOfCallIsInEea: false,
    openDate: {
      start: null,
      end: null,
      displayText: '',
    },
  },
  buildDate: null,
  buildCountry: null,
  shipyard: null,
  deadWeight: 0,
  draft: 0,
  draftUnit: METERS.key,
  tpcmi: 0,
  immersionUnit: TPC.key,
  grain: 0,
  grainUnit: CUBIC_METERS.key,
  constants: 0,
  isBalticVessel: false,
  netTimeCharter: 0,
  grossTimeCharter: 0,
  ballastBonus: 0,
  speedAndConsumptions: speedAndConsumptionsInitialState,
  isParcelVoyage: false,
  scrubber: {
    typeId: null,
    pendingTypeId: null,
  },
  fleetTypeIds: [],
  nextPortOfCallIsInEea: false,
});

export function getNewVesselEntryId(): VesselEntryId {
  return guid();
}

export const vesselInitialState = createEmptyVessel();

export const vesselReducer = (state: object = vesselInitialState, action: VesselAction): object => {
  switch (action.type) {
    case actionTypes.VESSEL_CHANGED:
      return getStateOnVesselChange(state, action);
    case actionTypes.VESSEL_APPLY_NEW_DETAILS:
      return applyNewVesselDetails(state, action);
    case actionTypes.VESSEL_APPLY_NEW_OPEN_POSITION:
      return applyNewOpenPosition(state, action);
    case actionTypes.VESSEL_SCRUBBER_CHANGED:
      return {
        ...state,
        speedAndConsumptions: SpeedAndConsumptionsReducer(state.speedAndConsumptions, action),
        scrubber: {
          ...state.scrubber,
          typeId: action.payload,
        },
      };
    case actionTypes.VESSEL_OPEN_POSITION_CHANGED:
      return {
        ...state,

        openPosition: {
          ...state.openPosition,
          id: action.payload.locationId,
          locationTypeId: action.payload.locationTypeId,
          name: action.payload.locationName,
          prevPortOfCallIsInEea: action.payload.prevPortOfCallIsInEea,
          zone: action.payload.zone || '',
          country: action.payload.country || '',
          longitude: action.payload.longitude || null,
          latitude: action.payload.latitude || null,
        },
      };
    case actionTypes.VESSEL_OPEN_POSITION_IS_IN_EEA_CHANGED:
      return {
        ...state,
        openPosition: {
          ...state.openPosition,
          prevPortOfCallIsInEea: action.payload,
        },
      };
    case actionTypes.VESSEL_NEXT_PORT_OF_CALL_IS_IN_EEA_CHANGED:
      return {
        ...state,
        nextPortOfCallIsInEea: action.payload,
      };
    case actionTypes.VESSEL_OPEN_DATE_CHANGED:
      return {
        ...state,
        openPosition: {
          ...state.openPosition,
          openDate: {
            ...state.openPosition.openDate,
            start: action.payload.start,
            end: action.payload.end,
            displayText: action.payload.displayText,
          },
        },
      };
    case actionTypes.VESSEL_DEADWEIGHT_CHANGED:
      return {
        ...state,
        deadWeight: action.payload,
      };
    case actionTypes.VESSEL_PARCEL_VOYAGE_TOGGLE_CHANGED:
      return {
        ...state,
        isParcelVoyage: action.payload,
      };
    case actionTypes.VESSEL_DRAFT_CHANGED:
      return {
        ...state,
        draft: action.payload,
      };
    case actionTypes.VESSEL_DRAFT_UNIT_CHANGED:
      return {
        ...state,
        draft: action.payload.draft,
        draftUnit: action.payload.draftUnit,
      };
    case actionTypes.VESSEL_TPCMI_CHANGED:
      return {
        ...state,
        tpcmi: action.payload,
      };
    case actionTypes.VESSEL_IMMERSION_UNIT_CHANGED:
      return {
        ...state,
        tpcmi: action.payload.immersion,
        immersionUnit: action.payload.immersionUnit,
      };
    case actionTypes.VESSEL_GRAIN_CHANGED:
      return {
        ...state,
        grain: action.payload,
      };
    case actionTypes.VESSEL_GRAIN_UNIT_CHANGED:
      return {
        ...state,
        grain: action.payload.grain,
        grainUnit: action.payload.grainUnit,
      };
    case actionTypes.VESSEL_CONSTANTS_CHANGED:
      return {
        ...state,
        constants: action.payload,
      };
    case actionTypes.VESSEL_BUILD_DATE_CHANGED:
      const builtDate: Date = parseDate(action.payload);
      return {
        ...state,
        buildDate: builtDate,
      };
    case actionTypes.VESSEL_SHIPYARD_CHANGED:
      return {
        ...state,
        shipyard: action.payload,
      };
    case actionTypes.VESSEL_BUILD_COUNTRY_CHANGED:
      return {
        ...state,
        buildCountry: action.payload,
      };
    case RATE_TIME_CHARTER_CHANGED:
      return {
        ...state,
        grossTimeCharter: action.payload.grossTimeCharter,
        netTimeCharter: action.payload.netTimeCharter,
      };
    case RATE_BALLAST_BONUS_CHANGED:
      return {
        ...state,
        ballastBonus: action.payload,
      };
    case RATE_COMMISSION_TIME_CHARTER_CHANGED:
      return {
        ...state,
        grossTimeCharter: calculateGrossTimeCharter(
          action.payload.brokerCommissionTimeCharter,
          action.payload.addressCommissionTimeCharter,
          state.netTimeCharter
        ),
      };
    default:
      const speedAndConsumptions = SpeedAndConsumptionsReducer(state.speedAndConsumptions, action);
      if (speedAndConsumptions !== state.speedAndConsumptions) {
        return { ...state, speedAndConsumptions };
      }
      return state;
  }
};

export const getVesselViewModelFromVesselDto = (
  vessel: IVessel,
  marketSegmentId: MarketSegmentId
): IVesselViewModel => {
  try {
    const mapLocation = (
      openPositionObject
    ): {
      id: number,
      locationTypeId: number,
      name: string,
      zone: string,
      country: string,
      position: {
        longitude: number,
        latitude: number,
      },
      prevPortOfCallIsInEea: boolean,
      openDate: {
        start: Date,
        end: Date,
        displayText: string,
      },
    } => {
      const { id, locationTypeId, name, zone, country, position, prevPortOfCallIsInEea } =
        openPositionObject.location;

      const { from, to, displayText } = openPositionObject.date;

      const start: Date = parseDate(from);
      const end: Date = parseDate(to);

      let longitude = null;
      let latitude = null;

      if (isNil(position) === false) {
        longitude = position.longitude;
        latitude = position.latitude;
      }

      return {
        id,
        locationTypeId,
        name,
        zone,
        country,
        longitude,
        latitude,
        prevPortOfCallIsInEea,
        openDate: {
          start,
          end,
          displayText,
        },
      };
    };

    const initialOpenPosition = mapLocation(vessel?.initialOpenPosition);
    const openPosition = mapLocation(vessel?.openPosition);
    const parsedBuildDate: Date = parseDate(vessel?.buildDate);

    const vesselViewModel = {
      entryId: vessel.entryId || 0,
      vesselId: vessel.vesselId,
      name: vessel.name,
      openPosition,
      initialOpenPosition,
      buildDate: parsedBuildDate,
      buildCountry: vessel.buildCountry,
      shipyard: vessel.shipyard,
      deadWeight: vessel.deadweight,
      draft: vessel.draft,
      draftUnit: vessel.draftUnit || METERS.key,
      tpcmi: vessel.tpcmi,
      immersionUnit: vessel.immersionUnit || TPC.key,
      grain: vessel.grain,
      grainUnit: vessel.grainUnit || CUBIC_METERS.key,
      constants: vessel.constants,
      isBalticVessel: vessel.isBalticVessel || false,
      isParcelVoyage: vessel.isParcelVoyage || false,
      ballastBonus: vessel.ballastBonus || 0,
      grossTimeCharter: vessel.grossTimeCharter,
      netTimeCharter: vessel.netTimeCharter,
      nextPortOfCallIsInEea: vessel.nextPortOfCallIsInEea || false,
      speedAndConsumptions: getSpeedAndConsumptionViewModelFromSpeedAndConsDto(
        vessel.speedAndConsumptions,
        marketSegmentId
      ),
      scrubber: vessel.scrubber,
      fleetTypeIds: (vessel.fleetTypeIds || []).map((x) => x),
    };
    return vesselViewModel;
  } catch (err) {
    logger.error('Error occurred at getVesselViewModelFromVesselDto', {
      ...err,
    });
    return createEmptyVessel();
  }
};

const getStateOnVesselChange = (state, action) => {
  const { vesselId, vesselName, isBalticVessel } = action.payload;
  const isVesselEmpty =
    (isNil(vesselId) || vesselId === 0) && (isNil(vesselName) || vesselName === '');

  const newState = {
    ...state,
    vesselId: vesselId || 0,
    name: vesselName || '',
    isBalticVessel: isBalticVessel || false,
  };

  if (isVesselEmpty) {
    newState.buildDate = null;
    newState.buildCountry = '';
    newState.shipyard = '';
  }

  return newState;
};

const applyNewVesselDetails = (state, action) => {
  const {
    buildDate,
    buildCountry,
    shipyard,
    deadweight,
    draft,
    tpcmi,
    grain,
    scrubber,
    fleetTypeIds,
  } = action.payload;

  const parsedBuildDate: Date = parseDate(buildDate);
  const calculatedDraft = draft
    ? convertDraft(draft, METERS, getDraftUnitTypeById(state.draftUnit))
    : 0;
  const calculatedImmersion = tpcmi
    ? convertImmersion(tpcmi, TPC, getImmersionUnitTypeById(state.immersionUnit))
    : 0;
  const calculatedGrain = grain
    ? convertGrain(grain, CUBIC_METERS, getGrainUnitTypeById(state.grainUnit))
    : 0;

  return {
    ...state,
    buildDate: parsedBuildDate,
    buildCountry: buildCountry || '',
    shipyard: shipyard || '',
    deadWeight: deadweight || 0,
    draft: calculatedDraft,
    tpcmi: calculatedImmersion,
    grain: calculatedGrain,
    scrubber,
    fleetTypeIds: fleetTypeIds || [],
    speedAndConsumptions: SpeedAndConsumptionsReducer(state.speedAndConsumptions, action),
  };
};

const applyNewOpenPosition = (state, action) => {
  const {
    openPosition: { locationId, locationTypeId, locationName, zone, country, latitude, longitude },
    openDate: { start, end, displayText },
  } = action.payload;

  return {
    ...state,
    openPosition: {
      id: locationId,
      locationTypeId: locationTypeId,
      name: locationName,
      zone: zone || '',
      country: country || '',
      longitude: longitude || null,
      latitude: latitude || null,
      openDate: {
        start,
        end,
        displayText,
      },
    },
    initialOpenPosition: {
      id: locationId,
      locationTypeId: locationTypeId,
      name: locationName,
      zone: zone || '',
      country: country || '',
      longitude: longitude || null,
      latitude: latitude || null,
      openDate: {
        start,
        end,
        displayText,
      },
    },
  };
};

const calculateGrossTimeCharter = (
  brokerCommissionTimeCharter,
  addressCommissionTimeCharter,
  netTimeCharter
) => {
  const totalCommission = brokerCommissionTimeCharter + addressCommissionTimeCharter;

  return totalCommission === 100 ? 0 : grossFromNet(netTimeCharter, totalCommission);
};

export default vesselReducer;
