import * as actionTypes from 'constants/action-types/worksheet/bunkers';
import { getBunkerPriceByPortName } from 'api/clients/bunker-price';

export const bunkerQuantityChanged = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.BUNKER_QUANTITY_CHANGED,
  payload: payload,
});

export const bunkerPriceChanged = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.BUNKER_PRICE_CHANGED,
  payload: payload,
});

export const toggleShowUnusedBunkers = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.UPDATE_WORKSHEET_SHOW_UNUSED_BUNKER_PRICE,
  payload: payload,
});

export const loadBunkerPrice = (payload, worksheetId) => async (dispatch) => {
  const { voyageLegId, portName, isTanker } = payload;

  const { newFuelGradePrices, priceIssuedDate, delivery } =
    await getBunkerPriceByPortName(portName);

  dispatch({
    type: actionTypes.BUNKER_LOAD_PRICE,
    payload: {
      voyageLegId,
      newFuelGradePrices,
      priceIssuedDate,
      delivery,
      isTanker,
    },
    worksheetId,
  });
};
