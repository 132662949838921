import React from 'react';

import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { veApi } from 'api';

export function DeleteWorkbookButton(props) {
  const handleDeleteWorkbook = async () => {
    const { id: workbookId, worksheets } = props.data;

    if (props.isWorkbookOpen(workbookId)) {
      return window.alert(
        'This workbook is currently open. To delete it, select another workbook and repeat the delete'
      );
    }

    const worksheetsCount = worksheets.length;
    const confirmationMsg = `Are you sure you want to delete this workbook and it's ${worksheetsCount} worksheets?`;

    if (window.confirm(confirmationMsg)) {
      await veApi
        .delete(`/workbook/${workbookId}`)
        .then((response) => props.removeWorkbook(workbookId));
    }
  };

  return (
    <IconButton
      icon={iconEnum.Delete}
      className="workbook-card__cell--actions-delete"
      onClick={handleDeleteWorkbook}
      diagnosticId="DeleteWorkbookButton/Delete"
    />
  );
}
