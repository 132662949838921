export function sendMessageToWindowOpener(options: {
  targetProgram: string,
  messageType: string,
  targetOrigin: string,
  data: any,
}) {
  if (!window.opener) {
    throw new Error(
      `Found no \`window.opener\` to send the message of type: \`${options.messageType}\`.`
    );
  } else {
    const message = {
      /** `targetProgram` is needed especially because #BrowserAddonsUseWindowPostMessage */
      targetProgram: options.targetProgram,
      type: options.messageType,
    };

    if (options.data) {
      message.data = options.data;
    }

    window.opener.postMessage(message, options.targetOrigin);
  }
}
