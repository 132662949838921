import { createSelector } from 'reselect';

export const selector = createSelector(
  (state) => state.worksheetsById[state.activeWorksheetId].id,
  (state, ownProps) => state.worksheetsById[state.activeWorksheetId].vessels,
  (state) => state.worksheetsById[state.activeWorksheetId].rates,
  (worksheetId, vessels, rates) => ({
    worksheetId,
    vessels: vessels,
    brokerCommissionTimeCharter: rates.brokerCommissionTimeCharter,
    addressCommissionTimeCharter: rates.addressCommissionTimeCharter,
  })
);
