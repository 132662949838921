import React, { useState } from 'react';
import Switch from 'react-switch';
import guid from 'utilities/guid';
import classNames from 'classnames';

import './styles.scss';
import { trackClickEvent } from '../../diagnostics/calc-trackevents';

type ToggleProps = {
  id: string,
  checked: boolean,
  disabled?: boolean,
  label: string,
  onChange: Function<Promise> | Function,
  className?: string,
  diagnosticId?: string,
};

const Toggle = (props: ToggleProps) => {
  const id = props.id || guid();
  const { checked, disabled, label, onChange, className, diagnosticId } = props;
  let [pendingTarget, setPendingTarget] = useState(undefined);

  const handleChange = async (checked) => {
    setPendingTarget(checked);
    try {
      await onChange(checked);
    } finally {
      setPendingTarget(undefined);
    }
    trackClickEvent(diagnosticId);
  };

  return (
    <label htmlFor={id} className={classNames('toggle', className)}>
      <Switch
        id={id}
        checked={pendingTarget !== undefined ? pendingTarget : checked}
        disabled={pendingTarget !== undefined || disabled}
        onChange={handleChange}
        className="toggle__switch"
        checkedIcon={false}
        uncheckedIcon={false}
        handleDiameter={20}
        height={15}
        width={30}
        onColor="#87c1f0"
        offColor="#586674"
        onHandleColor="#2196f3"
        offHandleColor="#bdbdbd"
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
      />
      <span>{label}</span>
    </label>
  );
};

export default Toggle;
