import isEmpty from 'lodash/isEmpty';
import { veApi } from 'api';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { send as unhandledExceptionSinkSend } from 'diagnostics/unhandled-exception-sink';
import {
  ALL_FUEL_GRADES,
  FUEL_GRADE_380CST,
  FUEL_GRADE_180CST,
  FUEL_GRADE_MDO,
  FUEL_GRADE_MGO,
  FUEL_GRADE_LSMGO,
  FUEL_GRADE_ULSFO,
  FUEL_GRADE_VLSFO,
  FUEL_GRADE_LNG,
} from 'constants/enums/fuel-grades';

export async function fetchAllBunkerPrices() {
  const startTime = Date.now();

  try {
    const response = await veApi.get('/bunkerprice');
    const bunkerPrices = response.data;

    trackEvent(
      'BunkerPorts',
      'Fetch All Bunker Prices Succeeded',
      {},
      {
        duration: Date.now() - startTime,
      },
      eventDestination.ANALYSIS
    );

    return bunkerPrices;
  } catch (err) {
    trackEvent(
      'BunkerPorts',
      'Fetch All Bunker Prices Failed',
      {
        message: err.message,
      },
      {
        duration: Date.now() - startTime,
      },
      eventDestination.ANALYSIS
    );

    throw err;
  }
}

export async function getBunkerPriceByPortName(portName) {
  if (isEmpty(portName)) {
    return buildEmptyBunkerPriceResponse();
  }

  try {
    const response = await veApi.get(`/bunkerprice/${portName}`);
    return getPriceData(response);
  } catch (error) {
    unhandledExceptionSinkSend(error);
    return buildEmptyBunkerPriceResponse();
  }
}

function buildEmptyBunkerPriceResponse() {
  const emptyFuelGrades = ALL_FUEL_GRADES.map((grade) => ({
    key: grade.key,
    price: 0,
  }));

  return {
    newFuelGradePrices: emptyFuelGrades,
    priceIssuedDate: null,
  };
}

const getPriceData = (response) => {
  if (!response || !response.data) {
    return buildEmptyBunkerPriceResponse();
  }

  const prices = response.data;

  return {
    newFuelGradePrices: [
      {
        key: FUEL_GRADE_380CST.key,
        price: prices.cst380Price,
      },
      {
        key: FUEL_GRADE_180CST.key,
        price: prices.cst180Price,
      },
      { key: FUEL_GRADE_MDO.key, price: prices.mdoPrice },
      { key: FUEL_GRADE_MGO.key, price: prices.mgoPrice },
      { key: FUEL_GRADE_LSMGO.key, price: prices.lsMgoPrice },
      {
        key: FUEL_GRADE_ULSFO.key,
        price: prices.ulsfoOneTenthPercPrice,
      },
      {
        key: FUEL_GRADE_VLSFO.key,
        price: prices.vlsfoFiveTenthPercPrice,
      },
      {
        key: FUEL_GRADE_LNG.key,
        price: prices.lngPrice,
      },
    ],
    priceIssuedDate: prices.dateIssued || null,
    delivery: prices.delivery || null,
  };
};
