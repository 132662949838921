import React from 'react';
import { TickIcon } from './../../icons';
import './styles.scss';
import classNames from 'classnames';

const SuccessBox = ({ className, children, ...props }) => (
  <div {...props} className={classNames('success-box', className)}>
    <TickIcon
      aria-label="Success"
      className="notice-box__type-icon"
      remainingnotifications={
        props && props.additionalprops ? props.additionalprops.remainingNotifications : 0
      }
    />
    <span className="notice-box__content">{children}</span>
  </div>
);

export default SuccessBox;
