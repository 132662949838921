export type ReasonForUnsuccessfulResult = AnyNonNil;

export type UnsuccessfulResult<
  TypeOfReasons: ReasonForUnsuccessfulResult = ReasonForUnsuccessfulResult,
> = {
  reasonIsUnsuccessful: TypeOfReasons,
};

export type SuccessfulResult = $Diff<AnyDefined, UnsuccessfulResult>;

/// A variant of a 'TryFunction' that returns `undefined` on a successful call, and a non-undefined `TUnsuccessfulReturnValue` value on lack of success.
export type NonVoidTryFunction<
  TypeOfSuccessfulResult: SuccessfulResult = SuccessfulResult,
  TypeOfUnsuccessfulResult: UnsuccessfulResult = UnsuccessfulResult,
> = TryFunction<TypeOfSuccessfulResult, TypeOfUnsuccessfulResult>;

/// Using this type it's easy to mark any existing function without modifying where the type annotations are, because it can be just used as a replacement for the function return type annotation.
export type NonVoidTryFunctionResult<
  TypeOfSuccessfulResult: SuccessfulResult = SuccessfulResult,
  TypeOfUnsuccessfulResult: UnsuccessfulResult = UnsuccessfulResult,
> = TypeOfSuccessfulResult | TypeOfUnsuccessfulResult;

/**
  This type can be used on your signature to convey when the `reasonIsUnsuccessful` can come from capturing a thrown error.
 */
export type UnsuccessfulResultFromCapturingThrownError = UnsuccessfulResult<
  | Error
  | AnyNonNil /* `AnyNonNil` because we can't rule out that someone won't throw a non `Error` */,
>;

/// TODO Turn into a class called just `NonVoidTryFunction` once [this issue is fixed](https://github.com/facebook/flow/issues/7552#issuecomment-542160519)
export class NonVoidTryFunctionUtils {
  static isUnsuccessfulResult(result: AnyDefined) {
    return NonVoidTryFunctionUtils.getReasonIfUnsuccessfulResultOrNull(result) !== null;
  }

  static getReasonIfUnsuccessfulResultOrNull(result: AnyDefined) {
    const reasonIsUnsuccessful = result.reasonIsUnsuccessful;
    return reasonIsUnsuccessful !== undefined ? reasonIsUnsuccessful : null;
  }

  static createUnsuccessfulResult<TypeOfReasons: AnyNonNil>(
    reasonIsUnsuccessful: TypeOfReasons
  ): UnsuccessfulResult<TypeOfReasons> {
    if (reasonIsUnsuccessful === undefined)
      throw new Error('`reasonIsUnsuccessful` must be specified');
    return {
      reasonIsUnsuccessful: reasonIsUnsuccessful,
    };
  }
}
