import { RATE_COMMISSION_TIME_CHARTER_CHANGED } from 'constants/action-types/worksheet/rates';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import { COMMISSION_CHANGED } from 'constants/action-types/worksheet/commission';

const initialState = {
  brokerCommissionTimeCharter: 0,
  addressCommissionTimeCharter: 0,
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_WORKSHEET:
      return getRatesViewModelFromWorksheetDto(action.payload);
    case RATE_COMMISSION_TIME_CHARTER_CHANGED:
      return {
        brokerCommissionTimeCharter: action.payload.brokerCommissionTimeCharter,
        addressCommissionTimeCharter: action.payload.addressCommissionTimeCharter,
      };
    case COMMISSION_CHANGED:
      return {
        brokerCommissionTimeCharter: action.payload.brokerCommission,
        addressCommissionTimeCharter: 0,
      };
    default:
      return state;
  }
};

export function getRatesViewModelFromWorksheetDto({ rates, ...worksheet }) {
  return {
    addressCommissionTimeCharter: rates.addressCommission,
    brokerCommissionTimeCharter: rates.brokerCommission,
  };
}
