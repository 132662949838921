import React from 'react';
import classNames from 'classnames';
import './styles.scss';

/**
 * NOTE: The divider will do its job only with `display: flex` that is horizontal,
 * (flex-direction: row/row-reverse) or with 'height' property defined.
 */
export default function VerticalDivider({ className, ...rest }) {
  // NOTE: using standard `<hr>` would work (see [SO with demo](https://stackoverflow.com/a/56015580/4356868)), but we use `normalize.css` [which uses old-browser compatible `height`](https://github.com/necolas/normalize.css/blob/fc091cce1534909334c1911709a39c22d406977b/normalize.css#L55) that's fixed for horizontal `writing-mode` (might not really be needed for us, as it mentions firefox, or it could instead specify [`inline-size`](https://developer.mozilla.org/en-US/docs/Web/CSS/inline-size), if compatibility policy allows)
  return <div className={classNames('vertical-divider', className)} />;
}
