import { createSelector } from 'reselect';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';
import { formatNumberWithNullAndUndefinedAsEmpty } from 'utilities/number';
import {
  getUiOrdinalForFuelGradeCode,
  FuelGradeCode,
  getFuelGradeByCode,
  getFuelGradeCodeFromId,
} from 'constants/enums/fuel-grades';
import sortBy from 'lodash/sortBy';

const gradeCodeCol = 0;
const emissionValueCol = 1;

export type CarbonBreakdownItem = {
  fuelGradeCode: FuelGradeCode,
  fuelGradeLabel: String,
  perDay: number,
  total: number,
};

export type EEOIItem = {
  fuelGradeCode: FuelGradeCode,
  fuelGradeLabel: String,
  value: number,
};

export const selector = createSelector(
  (state) => getActiveCalculationResult(state),
  (state) => state.carbonFactors,
  (calculationResult, carbonFactors) => {
    const bunkers = calculationResult.bunkers;
    const carbonFactorsKeyValuePairs = getCarbonFactorsKeyValuePairs(carbonFactors);

    const consumedFuel = getConsumedFuel(calculationResult);

    if (!bunkers || !bunkers.carbonEmissionsPerFuelType) {
      return {
        carbonBreakdown: [],
        eeoiBreakdown: [],
        totalCarbonEmissions: '',
        totalCarbonEmissionsDay: '',
        totalEEOI: '',
        carbonFactors: carbonFactorsKeyValuePairs,
        consumedFuel: consumedFuel,
      };
    }
    return {
      carbonBreakdown: mapCarbonEmission(bunkers.carbonEmissionsPerFuelType),
      totalCarbonEmissions: formatNumberWithNullAndUndefinedAsEmpty(
        bunkers.carbonEmissionsPerFuelType.totalCarbonEmissions
      ),
      totalCarbonEmissionsDay: formatNumberWithNullAndUndefinedAsEmpty(
        bunkers.carbonEmissionsPerFuelType.totalCarbonEmissionsDay
      ),
      eeoiBreakdown: mapEEOIData(bunkers.carbonEmissionsPerFuelType),
      totalEEOI: formatNumberWithNullAndUndefinedAsEmpty(
        bunkers.carbonEmissionsPerFuelType.totalEEOI
      ),
      carbonFactors: carbonFactorsKeyValuePairs,
      consumedFuel: consumedFuel,
    };
  }
);

function mapCarbonEmission(carbonEmissions) {
  const perDay = carbonEmissions.perDay;
  const total = carbonEmissions.total;

  const carbonBreakdownItems = Object.entries(perDay)
    .filter((_) => _[emissionValueCol] > 0)
    .map((_) => ({
      fuelGradeCode: _[gradeCodeCol],
      fuelGradeLabel: getFuelGradeByCode(_[gradeCodeCol]).label,
      perDay: formatNumberWithNullAndUndefinedAsEmpty(_[emissionValueCol]),
      total: formatNumberWithNullAndUndefinedAsEmpty(total[_[gradeCodeCol]]),
    }));

  const orderedCarbonBreakdownItems = sortBy(
    Object.values(carbonBreakdownItems),
    (fuelGradeItineraryTotalsItem: CarbonBreakdownItem) =>
      getUiOrdinalForFuelGradeCode(fuelGradeItineraryTotalsItem.fuelGradeCode)
  );

  return orderedCarbonBreakdownItems;
}

function mapEEOIData(carbonEmissions) {
  if (!carbonEmissions.eeoi) {
    return [];
  }
  const eeoiData = carbonEmissions.eeoi;

  const eeoiItems = Object.entries(eeoiData)
    .filter((_) => _[emissionValueCol] > 0)
    .map((_) => ({
      fuelGradeCode: _[gradeCodeCol],
      fuelGradeLabel: getFuelGradeByCode(_[gradeCodeCol]).label,
      value: formatNumberWithNullAndUndefinedAsEmpty(_[emissionValueCol]),
    }));

  const orderedEEOIItems = sortBy(Object.values(eeoiItems), (eeoiItems: EEOIItem) =>
    getUiOrdinalForFuelGradeCode(eeoiItems.fuelGradeCode)
  );

  return orderedEEOIItems;
}

function getConsumedFuel(calculationResult) {
  return calculationResult &&
    calculationResult.bunkersItineraryTotals &&
    calculationResult.bunkersItineraryTotals.totalsPerFuelGrade
    ? calculationResult.bunkersItineraryTotals.totalsPerFuelGrade.reduce((r, e) => {
        r[e.fuelGradeCode] = e.consumed.quantityMt;
        return r;
      }, {})
    : [];
}

function getCarbonFactorsKeyValuePairs(carbonFactors) {
  return !carbonFactors
    ? []
    : carbonFactors
        .filter((cf) => cf.fuelGrade !== 0)
        .map((cf) => ({
          factor: cf.factor,
          shortName: getFuelGradeCodeFromId(cf.fuelGrade),
        }))
        .reduce((r, e) => {
          r[e.shortName] = e.factor;
          return r;
        }, {});
}
