export const DAYS = { key: 1, label: 'Days' };
export const METRIC_TONNES_PER_DAY = { key: 2, label: 'MT/d' };
export const HOURS = { key: 3, label: 'Hours', previewLabel: 'Hrs' };
export const LONG_TONS_PER_DAY = { key: 4, label: 'LT/d' };
export const METRIC_TONNES_PER_HOUR = { key: 5, label: 'MT/hr' };

export const getLoadDischargeRateUnitTypeById = (typeId) => {
  switch (typeId) {
    case DAYS.key:
      return DAYS;
    case METRIC_TONNES_PER_DAY.key:
      return METRIC_TONNES_PER_DAY;
    case METRIC_TONNES_PER_HOUR.key:
      return METRIC_TONNES_PER_HOUR;
    case HOURS.key:
      return HOURS;
    case LONG_TONS_PER_DAY.key:
      return LONG_TONS_PER_DAY;
    default:
      return {};
  }
};

const LoadDischargeRateUnitTypes = [
  METRIC_TONNES_PER_DAY,
  DAYS,
  HOURS,
  LONG_TONS_PER_DAY,
  METRIC_TONNES_PER_HOUR,
];

export default LoadDischargeRateUnitTypes;
