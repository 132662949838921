import React from 'react';
import { PrimaryButton } from 'components/button';
import { withPullAppGlobals } from 'components/app-globals-registry';

const PrimaryButtonWithGlobals = withPullAppGlobals(PrimaryButton);

export function ViewWorkbooksButton(props) {
  return (
    <PrimaryButtonWithGlobals
      pullFromGlobals={{
        SEARCH_PANEL_TOGGLE_VISIBLITY_GLOBAL: 'onClick',
      }}
      {...props}
    >
      VIEW WORKBOOKS
    </PrimaryButtonWithGlobals>
  );
}

export default ViewWorkbooksButton;
