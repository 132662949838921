import { formatNumberStrict, round, roundCeiling } from 'utilities/number';

export const fuelMtNoOfDecimalPlaces = 2;

export function formatFuelMtOrNullAsEmpty(value: number | null) {
  return value === null ? '' : formatFuelMt(value);
}

export function formatFuelMt(value: number) {
  return formatNumberStrict(value, {
    noOfDecimalPlaces: fuelMtNoOfDecimalPlaces,
  });
}

export function roundFuelMt(value: number) {
  return round(value, /* noOfDecimalPlaces: */ fuelMtNoOfDecimalPlaces);
}

export function roundFuelMtCeiling(value: number) {
  return roundCeiling(value, /* noOfDecimalPlaces: */ fuelMtNoOfDecimalPlaces);
}
