import { EEACheckbox } from 'modules/voyage/components/eea';
import React from 'react';

export interface NextPortOfCallProps {
  activeVessel: IVessel;
  handleNextPortOfCallIsInEeaUpdated: (isInEea: boolean) => void;
  oldView: boolean;
}

const NextPortOfCall = ({
  activeVessel,
  handleNextPortOfCallIsInEeaUpdated,
  oldView = false,
}: NextPortOfCallProps) => {
  return oldView ? (
    <div className="port-of-call-bottom">
      <label className="worksheet-section__property-label">Next Port of Call</label>
      <EEACheckbox
        onIsInEeaChange={handleNextPortOfCallIsInEeaUpdated}
        id="eea-next-port-of-call-checkbox"
        isInEea={activeVessel?.nextPortOfCallIsInEea}
        label="EEA"
      />
    </div>
  ) : (
    <>
      <EEACheckbox
        onIsInEeaChange={handleNextPortOfCallIsInEeaUpdated}
        id="eea-checkbox"
        isInEea={activeVessel?.nextPortOfCallIsInEea}
        label=""
      />
      <label className="worksheet-section__property-label">Next Port of Call is in EEA</label>
    </>
  );
};

export default NextPortOfCall;
