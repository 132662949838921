import React from 'react';
import { DeleteWorkbookButton } from './delete-workbook-button';
import { RenameWorkbookNameButton } from './rename-workbook-name-button';

export const ActionsRenderer = (props) => {
  return (
    <div className="workbook-card__cell--actions">
      <RenameWorkbookNameButton {...props} />
      <DeleteWorkbookButton {...props} />
    </div>
  );
};
