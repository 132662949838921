import { createSelector } from 'reselect';
import { getActiveCalculationResult } from '../../../common-selectors/get-active-calculation-result';

export const selector = createSelector(getActiveCalculationResult, (calculationResult) => {
  const voyageRate = calculationResult?.voyageRate;
  const bunkers = calculationResult?.bunkers;
  const freightIncomeGross = voyageRate?.freightIncomeGross ?? 0;
  return {
    freightIncomeGross,
    totalIncomeGross: voyageRate?.totalIncomeGross ?? 0,
    totalIncomeNet: voyageRate?.totalIncomeNet ?? 0,
    freightIncomeNet: voyageRate?.freightIncomeNet ?? 0,
    totalBunkers: (bunkers?.totalBunkersCostsNet ?? 0) - (bunkers?.totalBunkersCostsAtPortNet ?? 0),

    inPortBunkerCost: bunkers?.totalBunkersCostsAtPortNet ?? 0,
    portAndOtherCosts:
      (calculationResult?.totalPortCostsNet ?? 0) +
      (calculationResult?.totalAdditionalCostsNet ?? 0),
    totalExpensesNet: voyageRate?.totalExpensesNet ?? 0,
  };
});
