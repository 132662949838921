import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStageLeftAs } from 'actions/stage';
import { selector } from './selector';
import { WORKSHEET_PANEL } from 'constants/enums/stage-panels';

import Map from './components/map';
import MapLegend from './components/map-legend';
import VoyageDetail from './components/voyage-detail';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { Header } from 'components/headers';
import ScrollablePanel from 'components/scrollable-panel';
import { trackPageView, eventDestination } from 'diagnostics/calc-trackevents';

import './styles.scss';

export const MapView = (props) => {
  useEffect(function trackingEffect() {
    trackPageView('MapView', undefined, {}, {}, undefined, eventDestination.ANALYSIS);
  }, []);

  return (
    <div className="map-container">
      <ScrollablePanel>
        <div className="map-header">
          <Header text="Voyage Map" className="map-header--title" />
          <IconButton
            icon={iconEnum.Close}
            className="map-header--close-btn"
            onClick={() => props.setStageLeftAs(WORKSHEET_PANEL)}
            diagnosticId="Voyage/CloseVoyageMap"
          />
        </div>
        <Map routes={props.routes} markers={props.markers} />
        <MapLegend />
        <VoyageDetail voyageLegs={props.voyageLegs} />
      </ScrollablePanel>
    </div>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setStageLeftAs,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MapView);
