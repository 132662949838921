import React, { Component } from 'react';
import isNil from 'lodash/isNil';

import { AscendingIcon, DescendingIcon } from 'components/icons';

export class WorkbookHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortable: this.props.enableSorting,
      sortDirection: this.props.column.sort,
    };
    this.props.column.addEventListener('sortChanged', this.handleSortChanged);
  }

  sortIconMap = {
    asc: <AscendingIcon className="workbook-header__sort-icon" />,
    desc: <DescendingIcon className="workbook-header__sort-icon" />,
  };

  getSortIcon = (sortDirection) => (isNil(sortDirection) ? null : this.sortIconMap[sortDirection]);

  handleClick = () => this.state.sortable && this.props.progressSort();

  handleSortChanged = ({ column }) => this.setState({ sortDirection: column.sort });

  render() {
    const { sortDirection } = this.state;
    const sortIcon = this.getSortIcon(sortDirection);

    return (
      <div className="workbook-header" onClick={this.handleClick}>
        <div>{this.props.displayName}</div>
        {sortIcon}
      </div>
    );
  }
}
