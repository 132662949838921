import React from 'react';
import { useScrollReset } from 'hooks/scroll-reset';
import './styles.scss';

export default function ScrollablePanel({ children, hideHorizontalScroll = false }) {
  const containerRef = React.useRef();
  const [hasScrolled, scrollToTop] = useScrollReset(containerRef);

  return (
    <div
      className="scrollable-panel"
      ref={containerRef}
      data-has-scrolled={hasScrolled}
      hide-horizontal-scroll={hideHorizontalScroll.toString()}
    >
      {children}
      <div className="reset-scroll-button" onClick={scrollToTop}>
        Up
      </div>
    </div>
  );
}
