/// Log to the console the full details for the last chance to see it (useful where sending it in an `Error` is not desired because the data gets logged, whether due to potential leak of private information or the payload size).
export function logErrorDetailsOnlyToConsole<TypeOfError: Error>(
  error: TypeOfError,
  details: mixed
): TypeOfError {
  console.error(
    'Data omitted from an Error for [user privacy/size] reasons: %o.\n\tThe related Error that was sent to diagnostics: %o',
    details,
    error
  );
  return error;
}
