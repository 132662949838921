import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selector } from './selector';
import { formatNumberWithAutoMantissa } from 'utilities/number';

interface CalculationSummaryVesselProps {
  name: string;
  speedAndConsumptions: {
    ballast: {
      speed: null | number,
      consumption: null | number,
      label: null | string,
    },
    laden: {
      speed: null | number,
      consumption: null | number,
      label: null | string,
    },
    sailing: {
      speed: null | number,
      consumption: null | number,
      label: null | string,
    },
  };
  yearOfBuild: string;
  country: string;
  yard: string;
  isParcelVoyage: Boolean;
}

export class CalculationSummaryVessel extends PureComponent<CalculationSummaryVesselProps> {
  render() {
    const { ballast, laden, sailing } = this.props.speedAndConsumptions;
    const isParcelVoyage = this.props.isParcelVoyage;
    const showSpeedAndConsumption = !!(
      ballast.speed ||
      ballast.consumption ||
      laden.speed ||
      laden.consumption
    );
    return (
      <div className="summary-vessel-container">
        <h2>Vessel: {this.props.name}</h2>
        <div className="summary-data">
          <div className="summary-data__row">
            <div className="summary-data__row-cell summary-data__row-cell-label">
              {!isParcelVoyage && (
                <>
                  <span>{ballast.label}</span>
                  <span> | </span>
                  <span>{laden.label}</span>
                </>
              )}
              {isParcelVoyage && (
                <>
                  <span>{sailing.label}</span>
                </>
              )}
            </div>
            <div className="summary-data__row-cell summary-data__row-cell-value">
              {showSpeedAndConsumption ? (
                <div className="summary-speed-and-cons">
                  {!isParcelVoyage && (
                    <div>{`${ballast.speed}/${formatNumberWithAutoMantissa(
                      ballast.consumption,
                      1
                    )} | ${laden.speed}/${formatNumberWithAutoMantissa(
                      laden.consumption,
                      1
                    )}`}</div>
                  )}
                  {isParcelVoyage && (
                    <div>{`${sailing.speed}/${formatNumberWithAutoMantissa(
                      sailing.consumption,
                      1
                    )}`}</div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className="summary-data__row">
            <div className="summary-data__row-cell summary-data__row-cell-label">YOB</div>
            <div className="summary-data__row-cell summary-data__row-cell-value">
              {this.props.yearOfBuild}
            </div>
          </div>
          <div className="summary-data__row">
            <div className="summary-data__row-cell summary-data__row-cell-label">Country</div>
            <div className="summary-data__row-cell summary-data__row-cell-value">
              {this.props.country}
            </div>
          </div>
          <div className="summary-data__row">
            <div className="summary-data__row-cell summary-data__row-cell-label">Yard</div>
            <div className="summary-data__row-cell summary-data__row-cell-value">
              {this.props.yard}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = selector;

export default connect(mapStateToProps)(CalculationSummaryVessel);
