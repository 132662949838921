import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import numbro from 'numbro';

const renderDataCell = (children, rightAlign, whiteFontCell = false) => {
  const cellClass = 'summary-data__row-cell';
  return (
    <div
      className={classNames(cellClass, {
        'summary-data__row-cell--right': rightAlign,
        'summary-data__row-cell--white-font-cell': whiteFontCell,
      })}
    >
      {children}
    </div>
  );
};

const formatNumber = (number, unit, unitPrecedesValue) => {
  if (number === null || number === undefined) {
    return '-';
  }
  const result =
    number < 1000
      ? numbro(number).format({ thousandSeparated: true, mantissa: 2 })
      : numbro(Math.round(number)).format({ thousandSeparated: true });
  return unit ? (unitPrecedesValue ? `${unit} ${result}` : `${result} ${unit}`) : result;
};

const SummaryDataRow = ({
  title,
  data,
  unit,
  unitPrecedesValue = false,
  whiteFontCell = false,
}) => {
  return (
    <div className="summary-data__row">
      {renderDataCell(title, false, whiteFontCell)}
      {renderDataCell(formatNumber(data, unit, unitPrecedesValue), true)}
    </div>
  );
};

export default SummaryDataRow;
