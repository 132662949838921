import { createSelector } from 'reselect';
import { getActiveVesselEntryId } from './get-active-vessel-entry-id';
import { getActiveWorksheet } from './get-active-worksheet';
import { singleOrNullIfEmptyOrThrowIfMany } from '../utilities/iterable';

export const getActiveVessel = createSelector(
  getActiveVesselEntryId,
  getActiveWorksheet,
  getActiveVesselFromWorksheet
);

export function getActiveVesselFromWorksheet(activeVesselEntryId, worksheet) {
  if (worksheet === null) return null;

  return singleOrNullIfEmptyOrThrowIfMany(
    worksheet.vessels.filter((_) => _.entryId === activeVesselEntryId)
  );
}
