export const SET_WORKBOOK = 'SET_WORKBOOK';
export const SET_ACTIVE_WORKBOOK_ID = 'SET_ACTIVE_WORKBOOK_ID';
export const SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE = 'SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE';
export const SEARCH_WORKSHEET = 'SEARCH_WORKSHEET';
export const LOAD_WORKSHEET = 'LOAD_WORKSHEET';
export const SET_WORKSHEET_STATUS = 'SET_WORKSHEET_STATUS';
export const SET_WORKSHEET_CHANGE_STATUS = 'SET_WORKSHEET_CHANGE_STATUS';
export const SET_ACTIVE_WORKSHEET_ID = 'SET_ACTIVE_WORKSHEET_ID';

export const WORKBOOK_REMOVE_WORKSHEET = 'WORKBOOK_REMOVE_WORKSHEET';
export const WORKBOOK_SET_ALL = 'WORKBOOK_SET_ALL';
export const WORKBOOK_REMOVE = 'WORKBOOK_REMOVE';
export const UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK = 'UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK';
