import React from 'react';
import MyWorkbooks from '../my-workbooks';
import TeamWorkbooks from '../team-workbooks';
export default class SearchWorkbooksTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowTeamsWorkbooks: false,
    };
  }

  onToggleWorkbooksMode = (newValue) => {
    this.setState({
      shouldShowTeamsWorkbooks: newValue,
    });
  };

  render() {
    return (
      <>
        <div className="search-panel-tab-wrapper-search-tab__content">
          <TeamWorkbooks
            title="Search for a workbook"
            onToggleWorkbooksMode={this.onToggleWorkbooksMode}
          />
          <div
            style={{
              display: this.state.shouldShowTeamsWorkbooks && 'none',
            }}
          >
            <div className="search-panel-tab-wrapper-search-tab__content_inner">
              <MyWorkbooks filterText={''} title="Search for a workbook" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
