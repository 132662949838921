import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import { Tab } from 'components/tab';
import './styles.scss';

export const WorksheetTab = ({ isActive, value, workbookId, id, isErrorState, sortOrderType }) => {
  const classes = classNames('worksheet-tab', {
    'worksheet-tab--active': isActive,
  });

  const url = `/workbook/${workbookId}/worksheet/${id}`;

  return (
    <>
      <ReactTooltip className="ve-tooltip-default" id="tab-tooltip" noArrow />
      <Link className="worksheet-tab__wrapper" to={url} tabIndex="-1">
        <Tab
          isActive={isActive}
          className={classes}
          sortOrderType={sortOrderType}
          tabName={value}
          data-tooltip-content={value}
          data-tooltip-delay-show={250}
          data-tooltip-id="tab-tooltip"
          data-tooltip-place="bottom"
        >
          <TabContent value={value} />
        </Tab>
      </Link>
    </>
  );
};

const TabContent = ({ hasErrored, value }) => <div className="worksheet-tab__content">{value}</div>;
