import isObject from 'lodash/isObject';
import * as iterableUtils from 'utilities/iterable/index';
import { voyageEntryReducer } from './voyage-entry';
import VError from 'verror';
import type { VoyageEntryAction } from 'actions/worksheet/voyage/voyage-entry-action-typings';

export function voyageEntriesReducer(
  state: IVoyageLegViewModel[],
  action: VoyageEntryAction
): IVoyageLegViewModel[] {
  const voyageEntryId = isObject(action.payload) ? action.payload.voyageEntryId : undefined;
  if (voyageEntryId === undefined) return state;

  state = iterableUtils.mapMatchingAndLeaveOthersErrorIfNoneFound(
    /*sourceIterable: */ state,
    /*itemPredicate: */ (_) => _.id === voyageEntryId,
    /*getNewItem:*/ (voyageEntry) => voyageEntryReducer(voyageEntry, action),
    /* createNoneFoundError */ () =>
      new VError(
        {
          info: {
            actionType: action.type,
            worksheetId: action.worksheetId,
            voyageEntryId: voyageEntryId,
          },
        },
        'Did not find the voyage entry pointed by the action. This may be a race condition or an uncancelled asynchronous action. See the error properties for the entity affected.'
      )
  );

  switch (action.type) {
    // TODO - move here cases from `reducers/worksheet/voyage` VOYAGE_LOCATION_ADDED, VOYAGE_LOCATION_DELETED etc. Notably, while `VOYAGE_ROUTE_VARIANTS_SET` has #ToRemoveIfSwitchingToLocationAPI__Determining_PortOperationalRestrictionIds_From_Route, we also need to move that part here
    default:
      return state;
  }
}
