import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selector } from './selector';
import { Header } from '../../components/headers';
import { commissionUpdated } from '../../actions/worksheet/commission';

import './styles.scss';
import CalcInput from '../../components/calc-input';

export const Commission: React.FC = ({
  worksheetId,
  brokerCommission,
  grossFreight,
  commissionUpdated,
}) => (
  <div>
    <Header text="Commission" />
    <div className="commission__section">
      <label className="worksheet-section__property-label">Commission</label>
      <CalcInput
        userSpecifiedValue={brokerCommission}
        className="commission-percentage"
        isEditable
        maxDecimalDigits={3}
        onInputChange={(newValue) => commissionUpdated(newValue, worksheetId)}
        unit="%"
        name="Commission"
      />
    </div>

    <div className="commission__section ">
      <label className="worksheet-section__property-label">&nbsp;</label>
      <CalcInput
        systemSpecifiedValue={(brokerCommission * grossFreight) / 100}
        className="commission-amount"
        maxDecimalDigits={2}
        trimDecimal={false}
        isEditable={false}
        unit="$"
        unitPrecedesValue
        isReadonly
        name="Commission"
      />
    </div>
  </div>
);

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      commissionUpdated,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Commission);
