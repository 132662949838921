import React from 'react';
import { Overlay } from 'modules/overlay';
import { SplashScreen } from 'modules/splash-screen';

export function FullScreenMessage({ children }) {
  return (
    <Overlay>
      <SplashScreen>{children}</SplashScreen>
    </Overlay>
  );
}
