import React, { FC } from 'react';
import classNames from 'classnames';
import './styles.scss';

type PlaceholderProps = {
  className?: string | null,
};

const CalcInputPlaceHolder: FC<PlaceholderProps> = ({ className }) => {
  const placeholderClasses = classNames({
    'tanker-numeric-input--placeholder': true,
  });

  return (
    <div className={classNames('numeric-input__container', className)}>
      <div className={placeholderClasses} />
    </div>
  );
};

export default CalcInputPlaceHolder;
