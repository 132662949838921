import React from 'react';
import { arcApi } from 'api';
import classNames from 'classnames';

import './styles.scss';

type State = {
  start: ?Date,
  end: ?Date,
  displayText: string,
  isDirtyState: boolean,
};

type OpenDate = {
  start: ?Date,
  end: ?Date,
  displayText: string,
};

type Props = {
  value: OpenDate,
  className?: string,
  onBlur: (State) => void,
  isMandatory?: boolean,
};

class MaritimeDate extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      start: props.value.start,
      end: props.value.end,
      displayText: props.value.displayText,
      isDirtyState: false,
    };
  }

  parseDate = async (input) => {
    return (await arcApi.get(`/api/1/0/Utilities/MaritimeDateRange?text=${input}`)).data;
  };

  formatDateToString = (date) => {
    var month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  formatDate = async (input) => {
    const start = this.formatDateToString(input.start);
    const end = this.formatDateToString(input.end);

    return (await arcApi.get(`/api/1/0/Utilities/MaritimeDateString/${start}/${end}`)).data;
  };

  handleInputChange = async (event) => {
    this.setState({
      displayText: event.target.value,
      isDirtyState: true,
    });

    const parsedDate = await this.parseDate(event.target.value);
    this.setState({
      start: parsedDate.start ? new Date(parsedDate.start) : null,
      end: parsedDate.end ? new Date(parsedDate.end) : null,
    });
  };

  handleBlur = async (event) => {
    if (this.state === null || this.state.start === null || this.state.end === null) {
      this.setState(
        {
          displayText: '',
        },
        () => {
          if (this.state.isDirtyState) {
            this.props.onBlur(this.state);
          }
          this.setState({
            isDirtyState: false,
          });
        }
      );
    } else if (this.props.onBlur) {
      const formattedDate = await this.formatDate(this.state);
      this.setState(
        {
          displayText: formattedDate,
        },
        () => {
          if (this.state.isDirtyState) {
            this.props.onBlur(this.state);
          }
          this.setState({
            isDirtyState: false,
          });
        }
      );
    }
  };

  getDisplayValue = (): string => {
    const displayValue = this.state.isDirtyState
      ? this.state.displayText || ''
      : this.props.value.displayText || '';

    return displayValue;
  };
  isEmptyValue = () => this.getDisplayValue() === '';
  render() {
    return (
      <div>
        <input
          value={this.getDisplayValue()}
          className={classNames('maritime-date', this.props.className, {
            'maritime-date-isMandatory': this.props.isMandatory && this.isEmptyValue(),
          })}
          onChange={this.handleInputChange}
          onBlur={this.handleBlur}
          maxLength={160}
        />
      </div>
    );
  }
}

export default MaritimeDate;
