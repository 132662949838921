import * as actionTypes from 'constants/action-types/worksheet/tanker-rates';

export const rateTypeChanged = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.TANKER_RATE_TYPE_CHANGED,
  payload: payload,
});

export const flatRateChanged = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.TANKER_FLAT_RATE_CHANGED,
  payload: payload,
});

export const worldscaleChanged = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.TANKER_WORLD_SCALE_CHANGED,
  payload: payload,
});

export const grossVoyageRateChanged = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.TANKER_GROSS_VOYAGE_RATE_CHANGED,
  payload: payload,
});

export const lumpSumChanged = (payload, worksheetId) => ({
  worksheetId,
  type: actionTypes.TANKER_LUMP_SUM_CHANGED,
  payload: payload,
});
