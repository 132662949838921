import isNumber from 'lodash/isNumber';
import isNull from 'lodash/isNull';
import {
  formatNumber,
  formatNumberWithNullAsEmpty,
  formatNumberWithNullAndZeroAsEmpty,
  formatNumberWithAutoMantissa,
} from 'utilities/number';
import {
  compareDatesWithNullAsSmallest,
  formatDateMMYYYYWithNullAsEmpty,
  formatDateDDMMMYYWithNullAsEmpty,
} from 'utilities/time';
import { compare as createNaturalSortCompareFn } from 'natural-orderby';
import { compareNumbersWithNullAsSmallest } from '../../utilities/number';
import { compareStringsUsingNaturalSortWithNullAsSmallest } from '../../utilities/string-sort';
import { formatMoneyOrNullAsEmpty } from 'utilities/number/units-precisions/money';
import { isDry, MarketSegmentId } from 'constants/market-segments';
import { CheckboxState } from '../comparison-table-column-setter/comparison-table-setter-content';
import { LinkCellRenderer, ProgressRenderer } from 'modules/worksheet-comparison/cell-renderers';

const columnSizes = {
  NO: 30,
  WORKSHEET_NAME: 92,
  VESSEL_NAME: 94,
  GROSS_TIME_CHARTER: 75,
  NET_TIME_CHARTER: 75,
  GROSS_VOYAGE_RATE: 75,
  VOYAGE_PROFIT_OR_LOSS_ESTIMATE: 150,
  TOTAL_DAYS_VOYAGE_PROFIT_OR_LOSS_ESTIMATE: 175,
  DEAD_WEIGHT_MT: 75,
  BUILD_DATE: 75,
  DRAFT_METERS: 75,
  DRAFT_FEET: 75,
  SCRUBBER_TYPE: 95,
  BALLAST: 75,
  LADEN: 75,
  SAILING: 75,
  EXTRA_SEA_CON: 85,
  OPEN_LOCATION: 120,
  OPEN_DATE: 140,
  LOWEST_MAXIMUM_LIFT_IN_VOYAGE: 110,
  ARRIVAL_AT_LOAD: 100,
  CARGO_QTY: 100,
  CARGO_TYPE: 140,
  TOTAL_FREIGHT: 100,
  END_DATE: 100,
  SEA_DAYS: 75,
  PORT_DAYS: 75,
  TOTAL_DISTANCE: 75,
  SECA_DISTANCE: 75,
  BALLAST_BONUS: 75,
  BUNKERS_BALLAST: 115,
  BUNKERS_LADEN: 110,
  BUNKER_COSTS_AT_PORT: 95,
  PORT_COSTS: 100,
  ADDITIONAL_COSTS: 137,
  TOTAL_BUNKER_COSTS: 145,
  TOTAL_BUNKER_CONSUMPTION_COSTS: 210,
  TOTAL_CARBON_EMISSIONS: 130,
  TOTAL_EEOI: 120,
  TOTAL_CARBON_COST: 120,
};

/**
 * Custom comparator for numeric values
 * Based on ag-grid definition
 * https://www.ag-grid.com/javascript-grid-sorting/#custom-sorting
 */
export const numericComparator = (
  valueA: number | null,
  valueB: number | null,
  nodeA: any,
  nodeB: any,
  isInverted: boolean
) => {
  return compareNumbersWithNullAsSmallest(valueA, valueB);
};

const agGridTooltipGetter = ({ valueFormatted, value }) => valueFormatted || value || '';

const numericValueFormatter = (value, decimalPlaces = 2, defaultValue = 0) =>
  isNumber(value)
    ? value === defaultValue
      ? ''
      : formatNumber(value, decimalPlaces)
    : value || '';

const numericFormatterWithAutoMantissa = (
  value: number,
  options: { treatZeroAsEmpty: boolean } = { treatZeroAsEmpty: false }
) => {
  return options.treatZeroAsEmpty && value === 0 ? '' : formatNumberWithAutoMantissa(value);
};

const agGridValueFormatter =
  (formatter) =>
  ({ value }) =>
    formatter(value);

const determineVisibility = (columnsState, field) => {
  return columnsState?.some((e) => e.field === field && e.state === CheckboxState.UNCHECKED);
};

export const comparisonColumns = (
  workbookName: string,
  workbookId: string,
  loadWorksheet: (worksheetId: string) => void,
  saveWorksheet: (worksheet) => void,
  marketSegmentId: MarketSegmentId,
  columnsState
) => {
  const cols: Components.IColumnDef<IComparisonDataRow>[] = [
    {
      headerName: workbookName,
      headerClass: 'group-header group-header--workbookname',
      headerTooltip: workbookName,
      children: [
        {
          headerName: '',
          pinned: 'left',
          colId: 'index',
          width: columnSizes.NO,
          valueGetter: ({ data: { isLoading, isLoadFailure, isSaveFailure, index } }) =>
            isLoading
              ? 'loading worksheet'
              : isLoadFailure
                ? 'load failure'
                : isSaveFailure
                  ? 'save failure'
                  : index,
          suppressMovable: true,
          cellRendererParams: {
            loadWorksheet,
            saveWorksheet,
          },

          cellRendererSelector: ({ index }) => {
            return {
              component: ProgressRenderer,
              params: {
                loadWorksheet,
                saveWorksheet,
              },
            };
          },
          headerClass: 'comparison-header comparison-header--right comparison-header--no',
          cellClass: 'comparison-cell comparison-cell--right',
          comparator: (
            valueA: number | null,
            valueB: number | null,
            nodeA: any,
            nodeB: any,
            isInverted: boolean
          ) => {
            const ignoreValue = ['loading worksheet', 'load failure', 'save failure'];
            if (ignoreValue.includes(valueA) || ignoreValue.includes(valueB)) return 0;
            return numericComparator(valueA, valueB, nodeA, nodeB, isInverted);
          },
        },
        {
          headerName: 'Worksheet',
          pinned: 'left',
          field: 'worksheetName',
          headerClass: 'comparison-header comparison-header--left comparison-header--worksheet',
          cellClass:
            'comparison-cell comparison-cell--trim comparison-cell--edge comparison-cell--left',
          width: columnSizes.WORKSHEET_NAME,
          sortable: true,
          cellRenderer: LinkCellRenderer,
          comparator: compareStringsUsingNaturalSortWithNullAsSmallest,
          tooltipValueGetter: ({ value }) => value,
        },
        {
          headerName: 'Vessel',
          pinned: 'left',
          field: 'vesselName',
          suppressMovable: true,
          width: columnSizes.VESSEL_NAME,
          headerClass: 'comparison-header comparison-header--left ',
          cellClass: 'comparison-cell comparison-cell--trim comparison-cell--left',
          sortable: true,
          cellRenderer: LinkCellRenderer,
          comparator: compareStringsUsingNaturalSortWithNullAsSmallest,
          tooltipValueGetter: ({ value }) => value,
        },
      ],
    },
    {
      headerName: 'Time Charter to Voyage ($)',
      headerClass: 'group-header',
      children: [
        {
          headerName: 'GTC',
          field: 'grossTimeCharter',
          suppressMovable: true,
          width: columnSizes.GROSS_TIME_CHARTER,
          cellClass:
            'comparison-cell comparison-cell--calc comparison-cell--edge comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          headerTooltip: 'Gross Time Charter',
          sortable: true,
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'NTC',
          field: 'netTimeCharter',
          suppressMovable: true,
          width: columnSizes.NET_TIME_CHARTER,
          cellClass: 'comparison-cell comparison-cell--calc comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          headerTooltip: 'Net Time Charter',
          sortable: true,
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'GVR',
          field: 'calculatedGrossVoyageRate',
          suppressMovable: true,
          width: columnSizes.GROSS_VOYAGE_RATE,
          cellClass: 'comparison-cell-output comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          headerTooltip: 'Gross Voyage Rate',
          sortable: true,
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Voyage to Time Charter ($)',
      headerClass: 'group-header',
      children: [
        {
          headerName: 'GVR',
          field: 'grossVoyageRate',
          headerClass: 'comparison-header comparison-header--right',
          cellClass:
            'comparison-cell comparison-cell--calc comparison-cell--edge comparison-cell--right',
          width: columnSizes.GROSS_VOYAGE_RATE,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          headerTooltip: 'Gross Voyage Rate',
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'GTC',
          field: 'calculatedGrossTimeCharter',
          suppressMovable: true,
          cellClass: 'comparison-cell comparison-cell-output comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.GROSS_TIME_CHARTER,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          headerTooltip: 'Gross Time Charter',
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'NTC',
          field: 'calculatedNetTimeCharter',
          suppressMovable: true,
          width: columnSizes.NET_TIME_CHARTER,
          cellClass: 'comparison-cell comparison-cell--calc comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          headerTooltip: 'Net Time Charter',
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          sortable: true,
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'P&L Estimate (Per Day)',
          field: 'voyageProfitOrLossEstimate',
          suppressMovable: true,
          width: columnSizes.VOYAGE_PROFIT_OR_LOSS_ESTIMATE,
          cellClass: 'comparison-cell comparison-cell--calc comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          sortable: true,
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'P&L Estimate (Total Voyage)',
          field: 'totalDaysVoyageProfitOrLossEstimate',
          suppressMovable: true,
          width: columnSizes.TOTAL_DAYS_VOYAGE_PROFIT_OR_LOSS_ESTIMATE,
          cellClass: 'comparison-cell comparison-cell--calc comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          sortable: true,
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Intake',
      headerTooltip: 'Intake',
      children: [
        {
          headerName: 'Prevailing Intake',
          field: 'lowestMaximumLiftInVoyage',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.LOWEST_MAXIMUM_LIFT_IN_VOYAGE,
          valueFormatter: agGridValueFormatter((value) => {
            if (value === null || value.errors.length > 0) {
              return '';
            }

            return numericFormatterWithAutoMantissa(value.lowestMaximumLift);
          }),
          comparator: (
            a: ILowestMaximumLiftInVoyageResult,
            b: ILowestMaximumLiftInVoyageResult,
            nodeA: any,
            nodeB: any,
            isInverted: boolean
          ) => {
            return numericComparator(
              a ? a.lowestMaximumLift : null,
              b ? b.lowestMaximumLift : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
          //The underlying value is an object, so we can't use the helper method of agGridTooltipGetter as it will always show `[object] [object]`.
          tooltipValueGetter: ({ valueFormatted }) => valueFormatted || '',
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Vessel Details',
      headerTooltip: 'Vessel Details',
      children: [
        {
          headerName: 'DWT (MT)',
          field: 'vesselDeadWeight',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.DEAD_WEIGHT_MT,
          valueFormatter: agGridValueFormatter((value) => {
            if (!isNumber(value)) {
              return value;
            }

            return numericFormatterWithAutoMantissa(value, {
              treatZeroAsEmpty: true,
            });
          }),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'YOB',
          field: 'vesselBuildDate',
          cellClass: 'comparison-cell comparison-cell--left',
          headerClass: 'comparison-header comparison-header--left',
          width: columnSizes.BUILD_DATE,
          valueFormatter: agGridValueFormatter(formatDateMMYYYYWithNullAsEmpty),
          comparator: compareDatesWithNullAsSmallest,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Draft (m)',
          field: 'vesselDraftInMeters',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.DRAFT_METERS,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Draft (ft)',
          field: 'vesselDraftInFeet',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.DRAFT_FEET,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Scrubber',
          field: 'scrubberTypeName',
          cellClass: 'comparison-cell comparison-cell--left',
          headerClass: 'comparison-header comparison-header--left',
          width: columnSizes.SCRUBBER_TYPE,
          tooltipValueGetter: agGridTooltipGetter,
          comparator: compareStringsUsingNaturalSortWithNullAsSmallest,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Ballast',
          field: 'vesselBallast',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.BALLAST,
          valueFormatter: agGridValueFormatter(numericValueFormatter),
          sortable: false,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Laden',
          field: 'vesselLaden',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.LADEN,
          valueFormatter: agGridValueFormatter(numericValueFormatter),
          sortable: false,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        ...(!isDry(marketSegmentId)
          ? [
              {
                headerName: 'Sailing',
                field: 'vesselSailing',
                cellClass: 'comparison-cell comparison-cell--right',
                headerClass: 'comparison-header comparison-header--right',
                width: columnSizes.SAILING,
                valueFormatter: agGridValueFormatter(numericValueFormatter),
                sortable: false,
                tooltipValueGetter: agGridTooltipGetter,
                get hide() {
                  return determineVisibility(columnsState, this.field);
                },
              },
            ]
          : []),
        {
          headerName: 'Extra Sea Con',
          field: 'scrubberConsumption',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.EXTRA_SEA_CON,
          valueFormatter: agGridValueFormatter((value) => {
            if (!isNumber(value)) {
              return value;
            }

            return numericFormatterWithAutoMantissa(value);
          }),
          sortable: false,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Open Location',
          field: 'openPositionLocationName',
          cellClass: 'comparison-cell comparison-cell--left',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.OPEN_LOCATION,
          valueFormatter: agGridValueFormatter(numericValueFormatter),
          comparator: createNaturalSortCompareFn(),
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Open Date',
          field: 'openPositionDate',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.OPEN_DATE,
          valueFormatter: agGridValueFormatter((value: IOpenDate) => {
            if (!isNull(value)) {
              return value.displayText;
            }
            return '';
          }),
          comparator: (
            valueA: IOpenDate | null,
            valueB: IOpenDate | null,
            nodeA: any,
            nodeB: any,
            isInverted: boolean
          ) => {
            const startOrder = compareDatesWithNullAsSmallest(
              valueA && valueA.start,
              valueB && valueB.start
            );

            if (startOrder === 0) {
              // start dates are equal, compare end date
              return compareDatesWithNullAsSmallest(valueA && valueA.end, valueB && valueB.end);
            }

            return startOrder;
          },
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Cargo Details',
      headerTooltip: 'Cargo Details',
      children: [
        {
          headerName: 'Cargo Qty',
          field: 'cargoQuantity',
          headerClass: 'comparison-header comparison-header--left',
          cellClass: 'comparison-cell comparison-cell--trim comparison-cell--right',
          width: columnSizes.CARGO_QTY,
          valueFormatter: agGridValueFormatter((value) =>
            formatNumberWithNullAndZeroAsEmpty(value, 3)
          ),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Cargo Type',
          field: 'cargoType',
          headerClass: 'comparison-header comparison-header--left ',
          cellClass: 'comparison-cell comparison-cell--trim comparison-cell--left',
          width: columnSizes.CARGO_TYPE,
          comparator: compareStringsUsingNaturalSortWithNullAsSmallest,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Freight Income',
      headerTooltip: 'Freight Income',
      children: [
        {
          headerName: 'Total Freight',
          field: 'totalFreight',
          headerClass: 'comparison-header comparison-header--left',
          cellClass: 'comparison-cell comparison-cell--trim comparison-cell--right',
          width: columnSizes.TOTAL_FREIGHT,
          valueFormatter: agGridValueFormatter((value) =>
            formatNumberWithNullAndZeroAsEmpty(value, 2)
          ),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Voyage Details',
      headerTooltip: 'Voyage Details',
      children: [
        {
          headerName: 'Arrival at Load',
          field: 'arrivalAtFirstLoadDate',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.ARRIVAL_AT_LOAD,
          valueFormatter: agGridValueFormatter(formatDateDDMMMYYWithNullAsEmpty),
          comparator: compareDatesWithNullAsSmallest,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'End date',
          field: 'voyageEndDate',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.END_DATE,
          valueFormatter: agGridValueFormatter(formatDateDDMMMYYWithNullAsEmpty),
          comparator: compareDatesWithNullAsSmallest,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Sea days',
          field: 'totalDaysAtSea',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.SEA_DAYS,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Port days',
          field: 'totalDaysAtPort',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.PORT_DAYS,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Total dist',
          field: 'totalDistance',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.TOTAL_DISTANCE,
          valueFormatter: agGridValueFormatter((value) =>
            formatNumberWithNullAndZeroAsEmpty(value, 0)
          ),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'SECA dist',
          field: 'secaDistance',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.SECA_DISTANCE,
          valueFormatter: agGridValueFormatter((value) => formatNumberWithNullAsEmpty(value, 0)),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'BB',
          field: 'ballastBonus',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.BALLAST_BONUS,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Costs',
      headerTooltip: 'Costs',
      children: [
        {
          headerName: 'Bunkers (ballast)',
          field: 'totalBunkersCostsBallastNet',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.BUNKERS_BALLAST,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Bunkers (laden)',
          field: 'totalBunkersCostsLadenNet',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.BUNKERS_LADEN,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Port bunkers',
          field: 'totalBunkersCostsAtPortNet',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.BUNKER_COSTS_AT_PORT,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Port costs',
          field: 'totalPortCostsNet',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.PORT_COSTS,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Additional Costs (TC)',
          field: 'totalAdditionalCostsTCNet',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.ADDITIONAL_COSTS,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Additional Costs (VR)',
          field: 'totalAdditionalCostsVRNet',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.ADDITIONAL_COSTS,
          valueFormatter: agGridValueFormatter(formatNumberWithNullAndZeroAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Bunker Details',
      headerTooltip: 'Bunker Details',
      children: [
        {
          headerName: 'Overall Bunker Cost ($)',
          field: 'overallBunkerCost',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.TOTAL_BUNKER_COSTS,
          valueFormatter: agGridValueFormatter(formatMoneyOrNullAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Total Bunker Consumption Cost ($)',
          field: 'totalBunkerConsumptionCost',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.TOTAL_BUNKER_CONSUMPTION_COSTS,
          valueFormatter: agGridValueFormatter(formatMoneyOrNullAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
    {
      headerName: 'Carbon Breakdown',
      headerTooltip: 'Carbon Breakdown',
      children: [
        {
          headerName: 'Total CO₂ Emissions',
          field: 'totalCarbonEmissions',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.TOTAL_CARBON_EMISSIONS,
          valueFormatter: agGridValueFormatter(formatMoneyOrNullAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Total EEOI',
          field: 'totalEEOI',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.TOTAL_EEOI,
          valueFormatter: agGridValueFormatter(formatMoneyOrNullAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
        {
          headerName: 'Total Carbon Cost',
          field: 'totalCarbonCost',
          cellClass: 'comparison-cell comparison-cell--right',
          headerClass: 'comparison-header comparison-header--right',
          width: columnSizes.TOTAL_CARBON_COST,
          valueFormatter: agGridValueFormatter(formatMoneyOrNullAsEmpty),
          comparator: numericComparator,
          tooltipValueGetter: agGridTooltipGetter,
          get hide() {
            return determineVisibility(columnsState, this.field);
          },
        },
      ],
    },
  ].filter((e) => e);
  return cols;
};
