import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import './styles.scss';
import classNames from 'classnames';
import stringEnum, { StringEnum } from '../../utilities/enum/string-enum';
import { CloseIcon, ErrorOutlineIcon, ModeEditIcon } from '../icons';
import numbro from 'numbro';
import toNumber from 'lodash/toNumber';
import ValidationError from '../validation/ui/validation-error';
import {
  getValidationMessages,
  ValidationMessageBase,
  ValidationRuleDefinitionBase,
} from 'components/validation';
import { ValidationRuleUsageParams } from '../validation';
import { eventDestination, trackChangeEvent, trackEvent } from '../../diagnostics/calc-trackevents';
import isFinite from 'lodash/isFinite';
import { isTanker } from 'constants/market-segments/index';

const MAX_INPUT_VALUE = 1_000_000_000_000;

export const formatNumber = (
  value: string,
  maxDecimalDigits: number,
  trimDecimal: boolean,
  { thousandSeparated }: { thousandSeparated: boolean }
): string => {
  numbro.zeroFormat('0');
  if (value === null || value === '' || value === '.') return '';

  if (maxDecimalDigits === undefined)
    return numbro(value).format({
      thousandSeparated,
    });

  return numbro(value).format({
    thousandSeparated,
    mantissa: maxDecimalDigits,
    trimMantissa: trimDecimal,
  });
};

export const formatNumberForDisplay = (
  number: any,
  maxDecimalDigits: number,
  trimDecimal: boolean
): string => {
  return formatNumber(number, maxDecimalDigits, trimDecimal, {
    thousandSeparated: true,
  });
};

export const formatNumberForEditing = (
  number: number,
  maxDecimalDigits: number,
  trimDecimal: boolean
): string => {
  return formatNumber(number, maxDecimalDigits, trimDecimal, {
    thousandSeparated: false,
  });
};
type ThresholdValueValidationRuleUsageParams =
  | (ValidationRuleUsageParams & {
      value: number,
      name?: string,
    })
  | number;
type ValidationLevel = 'error' | 'guidance';

const validationLevelsArray: ValidationLevel[] = ['error', 'guidance'];
const validationLevels: StringEnum<ValidationLevel> = stringEnum(validationLevelsArray);
const validationDecorationStylesArray = ['borderOnly', 'borderAndLabel'];
export type ValidationDecorationStyle = 'borderOnly' | 'borderAndLabel';
export const validationDecorationStyles: StringEnum<ValidationLevel> = stringEnum(
  validationDecorationStylesArray
);
type AllowedValue = number | null;

type InputValueType = {
  asText: string,
  isSystemSpecifiedValue: boolean,
  asNumber: AllowedValue,
};

type ValidationMessage = ValidationMessageBase<InputValueType, CalcInput>;

type ValidationRuleDefinition = ValidationRuleDefinitionBase<InputValueType, CalcInput> & {
  shouldWarnWhileTyping: boolean,
  validationLevel?: ValidationLevel,
  validationDecorationStyle?: ValidationDecorationStyle,
};

export const AllNumericValidationRuleDefinitions: Array<ValidationRuleDefinition> = [
  {
    propertyName: 'mustBeFiniteNumber',
    defaultParamsWhenNoProperty: true,
    shouldFailureStopFurtherRules: true,
    shouldWarnWhileTyping: false,
    validationLevel: validationLevels.error,
    isValid: (attemptedValueInfo: InputValueType, ruleUsageParams: boolean) => {
      return ruleUsageParams === false || isFinite(attemptedValueInfo?.asNumber);
    },
    getAttemptedValueIsInvalidMessage: (
      attemptedValueInfo: InputValueType,
      ruleUsageParams: boolean,
      component: CalcInput
    ) => `${component.props.name || 'The value'} must be a number`,
  },
  {
    propertyName: 'minValue',
    shouldWarnWhileTyping: false,
    validationLevel: validationLevels.error,
    expandParamShorthands: (ruleUsageParams: any) => ({
      ...ruleUsageParams,
      value: toNumber(
        typeof ruleUsageParams === 'object' ? ruleUsageParams.value : ruleUsageParams
      ),
    }),
    isValid: (
      attemptedValueInfo: InputValueType,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams
    ) => attemptedValueInfo?.asNumber >= ruleUsageParams.value,
    getAttemptedValueIsInvalidMessage: (
      attemptedValueInfo: InputValueType,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams,
      component: CalcInput
    ) =>
      `${component.props.name || 'The value'} cannot be smaller than ${
        ruleUsageParams.name ? `${ruleUsageParams.name} of ` : ''
      }${ruleUsageParams.value}`,
  },
  {
    propertyName: 'maxValue',
    validationLevel: validationLevels.error,
    shouldWarnWhileTyping: true,
    expandParamShorthands: (ruleUsageParams: any) => ({
      ...ruleUsageParams,
      value: toNumber(
        typeof ruleUsageParams === 'object' ? ruleUsageParams.value : ruleUsageParams
      ),
    }),
    isValid: (
      attemptedValueInfo: InputValueType,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams
    ) => attemptedValueInfo?.asNumber <= ruleUsageParams.value,
    getAttemptedValueIsInvalidMessage: (
      attemptedValueInfo: InputValueType,
      ruleUsageParams: ThresholdValueValidationRuleUsageParams,
      component: CalcInput
    ) =>
      `${component.props.name || 'The value'} cannot be greater than ${
        ruleUsageParams.name ? `${ruleUsageParams.name} of ` : ''
      }${formatNumberForDisplay(ruleUsageParams.value)}`,
  },
];

type InputProps = {
  inputMode?: string | null,
  name?: string,
  isMandatory?: boolean,
  isEditable?: boolean,
  isReadonly?: boolean,
  showEditButton?: boolean,
  systemSpecifiedValue?: number | null,
  userSpecifiedValue?: string | null,
  unit?: string | null,
  unitPrecedesValue?: boolean,
  className?: string | null,
  onBlur?: () => void,
  onFocus?: () => void,
  onInputChange?: (value: any) => void,
  maxDecimalDigits?: number,
  trimDecimal?: boolean,
  maxValue?: ThresholdValueValidationRuleUsageParams,
  maxLength?: number,
  minValue?: ThresholdValueValidationRuleUsageParams,
  validationDecorationStyle?: ValidationDecorationStyle | null,
  isValidationMessageDismissable?: boolean | null,
  customValidationRules?: ValidationRuleDefinition[],
  validationOptions?: {
    keepInvalidTextForUserToCorrect?: boolean,
    propagateInvalidValueIfFiniteNumber?: boolean,
  },
  marketSegmentId?: string,
  validationMessageRefreshValue?: number | null,
};

function getCallAllFn(...fns: Function[]): Function {
  return function (...args: any[]) {
    for (const fn of fns) {
      if (fn) fn.apply(this, args);
    }
  };
}

const CalcInput: FC<InputProps> = ({
  inputMode = 'decimal',
  name,
  diagnosticId,
  isMandatory = false,
  isEditable = false,
  showEditButton = false,
  isReadonly = false,
  systemSpecifiedValue = null,
  userSpecifiedValue = null,
  unit = null,
  unitPrecedesValue = false,
  className = null,
  onBlur,
  onFocus,
  onInputChange,
  maxDecimalDigits,
  trimDecimal = true,
  maxValue = null,
  maxLength = null,
  minValue = null,
  validationDecorationStyle = validationDecorationStyles.borderAndLabel,
  isValidationMessageDismissable = true,
  customValidationRules = null,
  validationOptions = null,
  marketSegmentId = 'Tankers',
  isDefaultValue = false,
  getDefaultValue,
  validationMessageRefreshValue,
}) => {
  const primaryInputValue =
    userSpecifiedValue !== null
      ? userSpecifiedValue.toString()
      : !isEditable && systemSpecifiedValue !== null
        ? systemSpecifiedValue.toString()
        : '';
  const [state, setState] = useState({
    inputValue: {
      asText:
        inputMode === 'decimal'
          ? formatNumberForDisplay(primaryInputValue, maxDecimalDigits, trimDecimal)
          : primaryInputValue,
      isSystemSpecifiedValue: !isEditable && systemSpecifiedValue !== null && !isDefaultValue,
      asNumber:
        userSpecifiedValue !== null
          ? userSpecifiedValue
          : !isEditable && systemSpecifiedValue !== null
            ? systemSpecifiedValue
            : 0,
    },
    isFocus: false,
    hasUserTypedInCurrentFocus: false,
    validationMessages: [],
    isEditMode: isDefaultValue,
    isEditable: isEditable,
    minValue: minValue,
    maxValue: maxValue,
    systemSpecifiedValue: systemSpecifiedValue !== null ? systemSpecifiedValue : null,
    isReadonly: isReadonly,
    validationOptions:
      validationOptions === null
        ? {
            keepInvalidTextForUserToCorrect: true,
            propagateInvalidValueIfFiniteNumber: true,
          }
        : validationOptions,
    oldValue: primaryInputValue,
  });

  const spanElementRef = useRef(null);
  const inputElementRef = useRef(null);
  const unitElementRef = useRef(null);
  const buttonElementRef = useRef(null);
  const emptySpaceRef = useRef(null);
  const errorIconRef = useRef(null);
  function propagateInputChange(value) {
    onInputChange && onInputChange(value);
  }
  function propagaGetDefaultValue() {
    getDefaultValue && getDefaultValue();
  }

  function hasProperNumberOfDigits(inputValue: string): boolean {
    const decimalRegex = new RegExp(`^\\d*(\\.\\d{0,${maxDecimalDigits}})?$`);
    return decimalRegex.test(inputValue);
  }

  const getValidationMessageList = (
    attemptedValueInfo: InputValueType
  ): Array<ValidationMessage> => {
    return getValidationMessages({
      ruleDefinitionsToTest: [
        ...AllNumericValidationRuleDefinitions,
        ...((customValidationRules &&
          customValidationRules.map((_) => ({
            defaultParamsWhenNoProperty: true,
            ..._,
          }))) ||
          []),
      ],
      attemptedValueInfo: attemptedValueInfo,
      component: { props: { minValue, maxValue, name } },
    });
  };

  const setUnitStyle = (defaultTranslateX?: number) => {
    const inputFieldWidth = spanElementRef.current.offsetWidth;
    const emptySpaceWidth = emptySpaceRef.current ? emptySpaceRef.current.offsetWidth : 0;
    const inputValueLength = inputElementRef.current.value.length;
    const unitLength = unit && unitElementRef.current ? unitElementRef.current.offsetWidth : 0;
    const editButtonLength =
      showEditButton && buttonElementRef.current ? buttonElementRef.current.offsetWidth : 10;
    const errorIconWidth = !showEditButton && !!state.validationMessages.length ? 5 : 0;
    const calc =
      inputFieldWidth -
      editButtonLength -
      (inputValueLength * 8 - Math.floor(inputValueLength / 4) * 5) -
      unitLength -
      emptySpaceWidth -
      errorIconWidth;

    const calculatedTranslateX = unitPrecedesValue && calc > 0 && inputValueLength !== 0 ? calc : 0;
    const translateX = defaultTranslateX ?? calculatedTranslateX;
    if (spanElementRef.current) {
      const unitLabel = spanElementRef.current
        .closest('.numeric-input__container')
        ?.querySelector('.unit-label');
      if (unitLabel) {
        unitLabel.style.setProperty('--unit-label-transform', `translateX(${translateX}px)`);
      }
    }
  };
  const handleNewValue = (newValue: any): InputValueType | null => {
    let formattedValue = newValue;

    if (newValue === '') {
      formattedValue = formatNumber(0, maxDecimalDigits, trimDecimal, {
        thousandSeparated: true,
      });
    } else {
      if (!hasProperNumberOfDigits(newValue)) {
        return null;
      }
      formattedValue = newValue;
    }
    const newValueAsNullableNumber = newValue === '' ? null : toNumber(newValue);
    if (newValueAsNullableNumber > MAX_INPUT_VALUE) {
      return null;
    }
    return {
      asText: formattedValue,
      asNumber: newValueAsNullableNumber,
    };
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (isReadonly) {
      return;
    }
    if (newValue === '' && inputMode === 'decimal') {
      newValue = 0;
      const attemptedValueInfo = handleNewValue(newValue);
      if (attemptedValueInfo === null) {
        return;
      }
      const validations = getValidationMessageList(attemptedValueInfo);
      setState((prevState) => ({
        ...prevState,
        inputValue: {
          ...prevState.inputValue,
          asText: showEditButton ? '' : '0',
          asNumber: 0,
        },
        validationMessages: validations,
      }));
      propagateInputChange(0);

      return;
    }
    if (state.isEditable) {
      if (inputMode === 'decimal') {
        newValue = newValue.trim();

        const attemptedValueInfo = handleNewValue(newValue);
        if (attemptedValueInfo === null) {
          return;
        }
        const validations = getValidationMessageList(attemptedValueInfo);
        if (
          validations.filter((message) => message.validationLevel === validationLevels.error)
            .length === 0
        ) {
          setState((prevState) => ({
            ...prevState,
            inputValue: {
              ...prevState.inputValue,
              asText: attemptedValueInfo.asText,
              asNumber: attemptedValueInfo.asNumber,
            },
            hasUserTypedInCurrentFocus: true,
            validationMessages: validations,
          }));

          propagateInputChange(attemptedValueInfo.asNumber);
        } else {
          const validationOptions = state.validationOptions || {};
          if (
            validationOptions.keepInvalidTextForUserToCorrect ||
            validations.filter((message) => message.ruleDefinition.shouldWarnWhileTyping).length ===
              0
          ) {
            setState((prevState) => ({
              ...prevState,
              inputValue: {
                ...prevState.inputValue,
                asText: attemptedValueInfo.asText,
                asNumber: attemptedValueInfo.asNumber,
              },
              hasUserTypedInCurrentFocus: true,
              validationMessages: validations,
            }));
            if (
              validationOptions.propagateInvalidValueIfFiniteNumber &&
              isFinite(attemptedValueInfo.asNumber) // '-' or '.' can still get here
            ) {
              // Here our usage chose even propagate the invalid value, as long as it's a number
              propagateInputChange(attemptedValueInfo.asNumber);
            }
          }
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          inputValue: {
            ...prevState.inputValue,
            asText: newValue,
          },
          hasUserTypedInCurrentFocus: true,
        }));
        propagateInputChange(newValue);
      }
    }
  };

  const handleEditClick = () => {
    setState((prevState) => ({
      ...prevState,
      isEditMode: true,
      isEditable: true,
      isFocus: true,
    }));

    inputElementRef.current.select();
    setUnitStyle(0);
  };

  const handleClearClick = () => {
    const attemptedValueInfo = handleNewValue(state.systemSpecifiedValue);
    if (attemptedValueInfo === null) {
      return;
    }

    const validations = getValidationMessageList(attemptedValueInfo);

    setState((prevState) => ({
      ...prevState,
      inputValue: {
        asText:
          inputMode === 'decimal'
            ? formatNumberForDisplay(state.systemSpecifiedValue, maxDecimalDigits, trimDecimal)
            : state.systemSpecifiedValue,
        asNumber: state.systemSpecifiedValue,
        isSystemSpecifiedValue: true,
      },
      isEditMode: false,
      isEditable: false,
      isFocus: false,
      validationMessages: validations,
    }));
    propagateInputChange(state.systemSpecifiedValue);
    propagaGetDefaultValue();
  };

  const handleBlur = () => {
    if (state.isReadonly && !state.isEditable) {
      return;
    }
    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
    }
    if (state.inputValue.asText === '' && state.systemSpecifiedValue !== null) {
      setState((prevState) => ({
        ...prevState,
        inputValue: {
          asText:
            inputMode === 'decimal'
              ? formatNumberForDisplay(state.systemSpecifiedValue, maxDecimalDigits, trimDecimal)
              : state.systemSpecifiedValue,
          asNumber: state.systemSpecifiedValue,
          isSystemSpecifiedValue: true,
        },
        isFocus: false,
        isEditMode: false,
        isEditable: false,
      }));
      if (state.hasUserTypedInCurrentFocus) {
        propagateInputChange(state.systemSpecifiedValue);
        propagaGetDefaultValue();
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        inputValue: {
          ...prevState.inputValue,
          asText:
            inputMode === 'decimal'
              ? formatNumberForDisplay(prevState.inputValue.asText, maxDecimalDigits, trimDecimal)
              : prevState.inputValue.asText,
          isSystemSpecifiedValue: false,
        },
        isFocus: false,
        isEditMode: true,
        isEditable: true,
      }));
    }
    for (const ruleDefinition of state.validationMessages.map(
      (message) => message.ruleDefinition
    )) {
      trackEvent(
        'CalcInput',
        `user-blur-left-value-invalid-on-${
          ruleDefinition.id ||
          ruleDefinition.propertyName ||
          'ruleDefinition-without-id-or-propertyName'
        }`,
        {},
        {},
        eventDestination.ANALYSIS
      );
    }
    if (state.hasUserTypedInCurrentFocus) {
      diagnosticId &&
        trackChangeEvent(diagnosticId, {
          oldValue: state.oldValue,
          newValue: state.inputValue.asNumber,
        });
    }
  };
  const handleFocus = async (event: FocusEvent) => {
    if (state.isReadonly && !state.isEditable) {
      return;
    }
    if (event.target.tagName.toLowerCase() === 'button') {
      return; // Prevent focusing on buttons
    }

    if (state.inputValue.isSystemSpecifiedValue && showEditButton) {
      setState((prevState) => ({
        ...prevState,
        isFocus: true,
        inputValue: {
          asText: '',
          asNumber: 0,
          isSystemSpecifiedValue: false,
        },
        isEditMode: true,
        isEditable: true,
      }));
      inputElementRef.current.select();
      setUnitStyle(0);
      return;
    }
    setState((prevState) => ({
      ...prevState,
      isFocus: true,
      inputValue: {
        ...prevState.inputValue,
        asText:
          inputMode === 'decimal'
            ? formatNumberForEditing(prevState.inputValue.asText, maxDecimalDigits, trimDecimal)
            : prevState.inputValue.asText,
        isSystemSpecifiedValue: false,
      },
      hasUserTypedInCurrentFocus: false,
      isEditMode: true,
      isEditable: true,
    }));

    inputElementRef.current.select();
    setUnitStyle(0);
  };

  useEffect(() => {
    if (!state.isFocus) {
      setUnitStyle();
    }
  }, [state.inputValue, state.validationMessages]);

  useEffect(() => {
    const systemSpecifiedValueChanged =
      systemSpecifiedValue !== state.inputValue.asNumber && systemSpecifiedValue !== null;
    let attemptedValueInfo: InputValueType = {
      asText: state.inputValue.asText,
      asNumber: state.inputValue.asNumber,
    };
    if (!state.isFocus && systemSpecifiedValueChanged) {
      attemptedValueInfo = handleNewValue(systemSpecifiedValue);

      setState((prevState) => ({
        ...prevState,
        inputValue: {
          asText:
            inputMode === 'decimal'
              ? formatNumberForDisplay(systemSpecifiedValue, maxDecimalDigits, trimDecimal)
              : systemSpecifiedValue,
          isSystemSpecifiedValue: true,
          asNumber: systemSpecifiedValue,
        },
        isEditMode: false,
        isEditable: false,
        systemSpecifiedValue: systemSpecifiedValue,
      }));
    }
    if (state.maxValue !== maxValue || state.minValue !== minValue) {
      const validations = getValidationMessageList(attemptedValueInfo);
      setState((prevState) => ({
        ...prevState,
        validationMessages: validations,
        minValue: minValue,
        maxValue: maxValue,
      }));
    }
  }, [systemSpecifiedValue, minValue, maxValue, validationMessageRefreshValue]);

  useEffect(() => {
    const userSpecifiedValueChanged =
      userSpecifiedValue !== state.inputValue.asNumber && userSpecifiedValue !== null;
    let attemptedValueInfo: InputValueType = {
      asText: state.inputValue.asText,
      asNumber: state.inputValue.asNumber,
    };
    const validations = getValidationMessageList(attemptedValueInfo);

    if (!state.isFocus && userSpecifiedValueChanged) {
      attemptedValueInfo = handleNewValue(userSpecifiedValue);

      setState((prevState) => ({
        ...prevState,
        inputValue: {
          asText:
            inputMode === 'decimal'
              ? formatNumberForDisplay(userSpecifiedValue, maxDecimalDigits, trimDecimal)
              : userSpecifiedValue,
          isSystemSpecifiedValue: true,
          asNumber: userSpecifiedValue,
        },
        isEditMode: false,
        isEditable: false,
        systemSpecifiedValue: userSpecifiedValue,
        validationMessages: validations,
      }));
    } else if (inputMode === 'decimal') {
      setState((prevState) => ({
        ...prevState,
        validationMessages: validations,
      }));
    }
    if (state.maxValue !== maxValue || state.minValue !== minValue) {
      const validations = getValidationMessageList(attemptedValueInfo);
      setState((prevState) => ({
        ...prevState,
        validationMessages: validations,
        minValue: minValue,
        maxValue: maxValue,
      }));
    }
  }, [userSpecifiedValue, minValue, maxValue, validationMessageRefreshValue]);

  useEffect(() => {
    if (inputMode === 'decimal') {
      let attemptedValueInfo: InputValueType = {
        asText: state.inputValue.asText,
        asNumber: state.inputValue.asNumber,
      };
      const validations = getValidationMessageList(attemptedValueInfo);
      setState((prevState) => ({
        ...prevState,
        validationMessages: validations,
      }));
    }
    const unitOffsetWidth = unitElementRef.current ? unitElementRef.current.offsetWidth : 0;
    const calculatedPaddingLeft =
      unitPrecedesValue && unitElementRef.current ? `${unitOffsetWidth + 8}px` : `5px`;
    const showEditButtonWidth =
      showEditButton && buttonElementRef.current ? buttonElementRef.current.offsetWidth : 10;
    const extraOffsetWidth = showEditButton && unit && !unitPrecedesValue ? 5 : 0;
    const unitLabelWidth = unit && !unitPrecedesValue ? unitOffsetWidth : 0;
    const totalWidthUsed = unitLabelWidth + showEditButtonWidth - extraOffsetWidth;
    const calculatedPaddingRightWithError = totalWidthUsed + 14;
    const calculatedPaddingRight = `${Math.max(totalWidthUsed, 10)}px`;
    if (inputElementRef.current) {
      inputElementRef.current.style.setProperty('--input-padding-left', calculatedPaddingLeft);
      inputElementRef.current.style.setProperty('--input-padding-right', calculatedPaddingRight);
      inputElementRef.current.style.setProperty(
        '--input-padding-right-with-error',
        `${calculatedPaddingRightWithError}px`
      );
    }

    // validation after render
    if (inputMode === 'decimal') {
      let attemptedValueInfo: InputValueType = {
        asText: state.inputValue.asText,
        asNumber: state.inputValue.asNumber,
      };
      const validations = getValidationMessageList(attemptedValueInfo);
      setState((prevState) => ({
        ...prevState,
        validationMessages: validations,
      }));
    }
  }, []);

  const callAllOnBlur = getCallAllFn(handleBlur, onBlur);
  const callAllOnFocus = getCallAllFn(handleFocus, onFocus);
  const inputClasses = classNames({
    'tanker-numeric-input': true,
    'tanker-numeric-input--unit': true,
    'tanker-numeric-input__container--unit-precedes-value': unitPrecedesValue,
    'tanker-numeric-input__container--unit-precedes-value-with-value':
      unitPrecedesValue && state.inputValue.asText !== '',
    'tanker-numeric-input--readonly': !isEditable,
    'tanker-numeric-input--readonly--on-edit-mode': state.isEditable,
    'tanker-numeric-input--empty-value-in-mandatory-field':
      isMandatory && (state.inputValue.asText === '' || state.inputValue.asNumber === 0),
    'tanker-numeric-input--dry-wet-mandatory-field':
      isMandatory &&
      (state.inputValue.asText === '' || state.inputValue.asNumber === 0) &&
      !isTanker(marketSegmentId),
    'tanker-numeric-input--text-mode': inputMode === 'text',
    'calc-input--guidance': !!state.validationMessages.some(
      (item) => item.validationLevel === validationLevels.guidance
    ),
    'calc-input--invalid': !!state.validationMessages.some(
      (item) => item.validationLevel === validationLevels.error
    ),
  });
  return (
    <span
      id={name}
      ref={spanElementRef}
      tabIndex={isReadonly ? -1 : 0}
      onFocus={callAllOnFocus}
      onBlur={callAllOnBlur}
      className={classNames(
        'numeric-input__container',
        {
          'numeric-input__container--unit-precedes-value': unitPrecedesValue,
          'numeric-input__container--unit-precedes-value-with-value':
            unitPrecedesValue && state.inputValue.asText !== '',
        },
        className
      )}
    >
      {unitPrecedesValue && unit && (
        <span
          ref={unitElementRef}
          className={`unit-label${state.inputValue.asText ? ' moving' : ''} ${
            unitPrecedesValue ? 'unit-label-transformed' : ''
          }`}
        >
          {unit}
          <span ref={emptySpaceRef}>&nbsp;</span>
        </span>
      )}
      {showEditButton && state.isEditMode ? (
        <input
          inputMode={inputMode}
          value={state.inputValue.asText}
          onChange={handleInputChange}
          className={inputClasses}
          ref={inputElementRef}
          maxLength={maxLength}
          data-has-validation-messages={state.validationMessages.length ? 'true' : 'false'}
          empty-mandatory-field={
            isMandatory && (state.inputValue.asText === '' || state.inputValue.asNumber === 0)
              ? 'true'
              : 'false'
          }
        />
      ) : (
        <input
          inputMode={inputMode}
          value={state.inputValue.asText}
          readOnly={!state.isEditable || isReadonly}
          onChange={handleInputChange}
          className={inputClasses}
          ref={inputElementRef}
          maxLength={maxLength}
          data-has-validation-messages={state.validationMessages.length ? 'true' : 'false'}
          empty-mandatory-field={
            isMandatory && (state.inputValue.asText === '' || state.inputValue.asNumber === 0)
              ? 'true'
              : 'false'
          }
        />
      )}
      {!unitPrecedesValue && unit && (
        <span
          ref={unitElementRef}
          className={`unit-label`}
          data-has-edit-button={showEditButton ? 'true' : 'false'}
          data-has-validation-messages={state.validationMessages.length ? 'true' : 'false'}
        >
          <span ref={emptySpaceRef}>&nbsp;</span>
          {unit}
        </span>
      )}
      {!state.isEditable &&
        showEditButton &&
        !state.isEditMode &&
        state.inputValue.isSystemSpecifiedValue && (
          <button
            className="clear-button"
            ref={buttonElementRef}
            onClick={handleEditClick}
            tabIndex={-1}
            data-has-validation-messages={state.validationMessages.length ? 'true' : 'false'}
          >
            <ModeEditIcon className="clear-button__icon" />
          </button>
        )}
      {showEditButton && state.isEditMode && !state.inputValue.isSystemSpecifiedValue && (
        <button
          ref={buttonElementRef}
          className="clear-button clear-button__clear"
          onClick={handleClearClick}
          tabIndex={-1}
          data-has-validation-messages={state.validationMessages.length ? 'true' : 'false'}
        >
          <CloseIcon className="clear-button__icon" />
        </button>
      )}
      {state.isFocus && !!state.validationMessages.length && (
        <div className="calc-input__validation_messages">
          {state.validationMessages.map((validationMessage) => (
            <ValidationError
              /* Use the message text, because we don't have a digest id over all the things that vary as messages can be produced by multi-parameter functions specified in options. */
              key={validationMessage.message}
              validationLevel={validationMessage.validationLevel}
              isDismissable={isValidationMessageDismissable}
              showWarningIcon={false}
            >
              {validationMessage.message}
            </ValidationError>
          ))}
        </div>
      )}
      {validationDecorationStyle === validationDecorationStyles.borderAndLabel &&
        !!state.validationMessages.length && (
          <div
            ref={errorIconRef}
            className={classNames('invalid_status_container', {
              'invalid_status_container--hidden': !state.validationMessages.length,
              'invalid_status_container--guidance': state.validationMessages.some(
                (_) => _.validationLevel === validationLevels.guidance
              ),
              'invalid_status_container--error': state.validationMessages.some(
                (_) => _.validationLevel === validationLevels.error
              ),
            })}
          >
            <ErrorOutlineIcon className="invalid_status_container--icon" />
          </div>
        )}
    </span>
  );
};

export default CalcInput;
