export const comparisonTableInitialColumnsState = [
  {
    columnId: 'TC',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'TC_GTC',
    state: true,
    parentId: 'TC',
  },
  {
    columnId: 'TC_NTC',
    state: true,
    parentId: 'TC',
  },
  {
    columnId: 'TC_GVR',
    state: true,
    parentId: 'TC',
  },
  {
    columnId: 'VR',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'VR_GTC',
    state: true,
    parentId: 'VR',
  },
  {
    columnId: 'VR_NTC',
    state: true,
    parentId: 'VR',
  },
  {
    columnId: 'VR_GVR',
    state: true,
    parentId: 'VR',
  },
  {
    columnId: 'VR_PL_Estimate_Per_Day',
    state: true,
    parentId: 'VR',
  },
  {
    columnId: 'VR_PL_Estimate_Total_Voyage',
    state: true,
    parentId: 'VR',
  },
  {
    columnId: 'IN',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'IN_Prevailing_Intake',
    state: true,
    parentId: 'IN',
  },
  {
    columnId: 'VD',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'VD_DWT_MT',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_YOB',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Draft_M',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Draft_FT',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Scrubber',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Ballast',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Laden',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Sailing',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Extra_Sea_Con',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Open_Location',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'VD_Open_Date',
    state: true,
    parentId: 'VD',
  },
  {
    columnId: 'CD',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'CD_Cargo_Qty',
    state: true,
    parentId: 'CD',
  },
  {
    columnId: 'CD_Cargo_Type',
    state: true,
    parentId: 'CD',
  },
  {
    columnId: 'FI',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'FI_Total_Freight',
    state: true,
    parentId: 'FI',
  },
  {
    columnId: 'VY',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'VY_Arrival_At_Load',
    state: true,
    parentId: 'VY',
  },
  {
    columnId: 'VY_End_Date',
    state: true,
    parentId: 'VY',
  },
  {
    columnId: 'VY_Sea_Days',
    state: true,
    parentId: 'VY',
  },
  {
    columnId: 'VY_Port_Days',
    state: true,
    parentId: 'VY',
  },
  {
    columnId: 'VY_Total_Dist',
    state: true,
    parentId: 'VY',
  },
  {
    columnId: 'VY_SECA_Dist',
    state: true,
    parentId: 'VY',
  },
  {
    columnId: 'VY_BB',
    state: true,
    parentId: 'VY',
  },
  {
    columnId: 'COS',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'COS_Bunkers_Ballast',
    state: true,
    parentId: 'COS',
  },
  {
    columnId: 'COS_Bunkers_Laden',
    state: true,
    parentId: 'COS',
  },
  {
    columnId: 'COS_Port_Bunkers',
    state: true,
    parentId: 'COS',
  },
  {
    columnId: 'COS_Port_Costs',
    state: true,
    parentId: 'COS',
  },
  {
    columnId: 'COS_Additional_Costs_TC',
    state: true,
    parentId: 'COS',
  },
  {
    columnId: 'COS_Additional_Costs_VR',
    state: true,
    parentId: 'COS',
  },
  {
    columnId: 'BD',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'BD_Overall_Bunker_Cost',
    state: true,
    parentId: 'BD',
  },
  {
    columnId: 'BD_Total_Bunker_Consumption_Cost',
    state: true,
    parentId: 'BD',
  },
  {
    columnId: 'CB',
    state: undefined,
    parentId: '',
  },
  {
    columnId: 'CB_Total_CO2_Emissions',
    state: true,
    parentId: 'CB',
  },
  {
    columnId: 'CB_Total_EEOI',
    state: true,
    parentId: 'CB',
  },
  {
    columnId: 'CB_Total_COST',
    state: true,
    parentId: 'CB',
  },
];
