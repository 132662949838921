import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import userContext from 'user-context';

export const selector = createSelector(
  (state) => state.workbooksById,
  (state, props) => (props && props.userId) || userContext.systemUserId,
  (state) => state.worksheetsById,
  (state) => state.activeWorkbookId,
  (state) => state.userPreference?.defaultMarketSegmentId,
  (workbooksById, userId, worksheetsById, activeWorkbookId, defaultMarketSegmentId) => ({
    activeWorkbookId,
    defaultMarketSegmentId,
    workbooks: Object.values(workbooksById)
      .filter((wb) => wb.userId === userId)
      .map((workbook) => ({
        ...workbook,
        worksheets: filter(worksheetsById, (worksheet) => worksheet.workbookId === workbook.id),
      })),
  })
);
