import {
  CARBON_COST_INCLUDE_IN_COSTS_CHANGED,
  EUA_PRICE_CHANGED,
  EUA_YEAR_CHANGED,
} from 'constants/action-types/worksheet/eua';
import { getEuaPrice } from '../../../api/clients/eua-price';

export function euaPriceUpdated(payload, worksheetId) {
  const updatedPayload = { ...payload };
  if (!payload.euaPriceUpdated) {
    updatedPayload.euaPriceUpdated = new Date().toISOString();
  }
  return {
    payload: updatedPayload,
    worksheetId,
    type: EUA_PRICE_CHANGED,
  };
}

export function euaYearUpdated(payload, worksheetId) {
  return {
    payload,
    worksheetId,
    type: EUA_YEAR_CHANGED,
  };
}
export function includeInCostsChanged(payload, worksheetId) {
  return {
    payload,
    worksheetId,
    type: CARBON_COST_INCLUDE_IN_COSTS_CHANGED,
  };
}

export const fetchEuaPriceAndSyncStore = (worksheetId) => async (dispatch) => {
  const euaPrice = await getEuaPrice();
  const priceValue = euaPrice.euaPrice;
  dispatch(
    euaPriceUpdated(
      {
        euaPrice: priceValue,
        isCrslEuaPrice: true,
        euaPriceUpdated: euaPrice.timeStamp,
        isRefinitiv: euaPrice.isRefinitiv,
      },
      worksheetId
    )
  );
  return priceValue;
};
