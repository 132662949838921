import React from 'react';
import { NoticesEmitterContext } from './notices-emitter-context';
import type { EmitNoticeProps } from './emitter-contract';

type Props = EmitNoticeProps & { children: RenderNoticeFunction | React.Node };

/**
 * `<EmitNotice>` - a 'JSX friendly' component for emitting a notification that the user should see.
 *
 * This component allows for emitting notices declaratively.
 * The component works in conjunction with {@link NoticesContext}.
 *
 * **Usage with `key=`** - If the parent component is reused for multiple instances of changing data,
 * and the appearance of the notice should be re-evaluated with the changing underlying data instance,
 * use the React's [`key={someData.someId}`](https://reactjs.org/docs/reconciliation.html) attribute to
 * point at the unique identifier of the data instance.
 *
 * For example usage, see tests in {@link emit-notice.test}
 * **Note**: for non-declarative notices, please see {@link notices-emitter-context}
 *
 */

let _id = 0;
let seed = Date.now();

function getNextId() {
  _id += 1;
  return `${seed}-${_id}`;
}

export function EmitNotice({
  noticeId,
  type,
  emitter,
  onWillDismiss,
  dismissOnUnmount,
  children,
  worksheetId,
  updateWithNoticeId,
  noticeIdToDismiss,
}: Props) {
  if (type === undefined) {
    throw new Error(
      'A valid emit-notice type was expected. Please provide a value that will identify the type of this notice.'
    );
  }

  const [id] = React.useState(getNextId);
  if (!noticeId) {
    noticeId = id;
  }
  const [_noticeId] = React.useState(noticeId);
  let emitNoticeId = _noticeId || id;

  const noticesContext = React.useContext(NoticesEmitterContext);

  const dismiss = React.useCallback(() => {
    if (!noticesContext && !emitter) {
      throw new Error(`No emitter provided from context or props`);
    }
    (noticesContext || emitter).dismiss(emitNoticeId, false);
  }, [emitter, emitNoticeId, noticesContext]);

  React.useEffect(
    () => {
      if (!noticesContext && !emitter) {
        return;
      }
      (noticesContext || emitter).emit({
        emitNoticeId,
        onWillDismiss,
        children,
        dismiss,
        worksheetId,
        type,
        updateWithNoticeId,
        noticeIdToDismiss,
      });

      return dismissOnUnmount ? dismiss : undefined;
    },
    // don't pass the children as these are newly created, only the `key` should signify a change has occurred
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dismiss, dismissOnUnmount, emitter, emitNoticeId, noticesContext]
  );
  return null;
}

export default EmitNotice;
