import React from 'react';
import TimelineLeg from 'modules/voyage/components/route-graph/cargo-lite-ports-of-call-feature-components/timeline-leg';
import { isFeatureEnabled } from 'config/feature-control';

interface TimelineLegProps {
  openPosition: IOpenPosition;
  voyage: IVoyage;
}

const TimelineLegs = ({ openPosition, voyage }: TimelineLegProps) => {
  if (!isFeatureEnabled('wetCargoLitePortsOfCall')) {
    return null;
  }
  return (
    <div>
      {voyage.legs.map((leg, index) => (
        <TimelineLeg
          key={index}
          leg={leg}
          voyage={voyage}
          index={index}
          openPosition={openPosition}
          isLast={index === voyage.legs.length - 1}
        />
      ))}
    </div>
  );
};

export default TimelineLegs;
