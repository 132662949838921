import { veApi } from 'api';

// TODO consider minimising the duplication and using the related api clients
export const getUser = async (userId) => {
  return await veApi.get(`/user/${userId}`);
};

export const getWorkbook = async (workbookId) => {
  return await veApi.get(`/workbook/${workbookId}`);
};

export const deleteWorkbook = async (workbookId) => {
  return await veApi.delete(`/workbook/${workbookId}`);
};

export const updateWorkbook = async (workbookId, workbook) => {
  return await veApi.put(`/workbook/${workbookId}`, { workbook: workbook });
};

export const copyWorksheetsToWorkbook = async ({
  worksheetIds,
  destinationWorkbookId,
  createdFromProgramId,
  createdFromOriginId,
}): {
  worksheetIds: WorksheetId[],
  destinationWorkbookId: WorkbookId,
  createdFromProgramId: string,
  createdFromOriginId: string,
} => {
  await veApi.post(`/worksheet/copy`, {
    worksheetIds,
    destinationWorkbookId,
    createdFromProgramId,
    createdFromOriginId,
  });
};
