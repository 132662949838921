import React from 'react';
import classNames from 'classnames';
import { IconButton } from 'components/button';
import { iconEnum } from 'components/icons';

type DismissButtonProps =
  | {
      className?: string,
    }
  | Object;

export type RenderNoticeFunction = ({
  /**
   * Standard app's "dismiss" button with standard appearance.
   */
  DismissButton: (props: DismissButtonProps) => React.StatelessFunctionalComponent,
  /**
   * Use this dismiss function under any non-standard ways of dismissing, like an 'OK' button or a user choice that ends with the notification not being needed anymore.
   */
  dismiss: () => void,
}) => React.Node;

type Props = {
  children: RenderNoticeFunction,
  /**
   * Your code to change the state of the notice to dismissed.
   */
  handleDismiss: () => void,
};

/**
 * Use this component to render a standard dismiss button as part of a `Dismissible`
 */
export function DismissButton({
  dismiss,
  className,
  ...props
}: {
  dismiss: () => void,
  className?: string,
}) {
  return (
    <IconButton
      aria-label="Dismiss"
      icon={iconEnum.Close}
      {...props}
      className={classNames('notice-box__standard-dismiss-button', className)}
      onClick={dismiss}
    />
  );
}

/** A non-self-contained dismissable notice-box, where its dismissed state is to be controlled by the state of the parent.
 * For a self-contained version see {@link dismissible-self-contained}
 *
 * TODO: the following code should be revisited to make less-boilerplatey,
 * the goal to is to achieve something like the following:
 *
 * Case 1:
 * <Dismissible><ErrorBox dismissible>Some message<DismissButton /></ErrorBox></Dismissible>
 *
 * Case 2:
 * <ErrorBox dismissible>Some message<DismissButton /></ErrorBox>
 *
 * Case 3:
 * <ErrorBox>some message<DismissButton /></ErrorBox>
 *
 */
export const Dismissible = ({ handleDismiss: dismiss, children: renderFunction }: Props) => {
  return renderFunction({
    dismiss,
    DismissButton: ({ ...props }) => <DismissButton {...props} dismiss={dismiss} />,
  });
};

export default Dismissible;
