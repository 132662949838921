import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';
import flatten from 'lodash/flatten';
import { getOperationalRestrictionIdFromZoneInfo } from 'constants/operational-restrictions';
import * as setUtils from 'utilities/set/set-utils';
import stringEnum, { StringEnum } from 'utilities/enum/string-enum';

interface IPointsOfIntersection {
  entryPointNm: number;
  exitPointNm: number;
}

type ZoneId = UUID;

/**
 Note: A segment is NOT contiguous fragment of a route because:
   * #RoutingAPISegmentIsNotAContiguousRouteFragment - There can be multiple intersections if the area has a concave edge (shoehorn shape can be an extreme example) or the path has turns (related: #MultipleIntersectionsWithPathPossible)
   * #RoutingAPISegmentsCanOverlap - Segments essentially represent the fact that the Route goes through a zone and zones can overlap.
 */
interface ISegment {
  zoneId: ZoneId;
  zoneTypeId: ZoneTypeId;
  name: string;
  pointsOfIntersection: IPointsOfIntersection[];
}

interface IPath {
  coordinates: number[][];
  type: String;
}

// eslint-disable-next-line no-unused-vars
interface IRoutingApiResponse {
  path: IPath;
  segments: ISegment[];
}

/**
 * _Note - this is a base class to draw correspondence between types that use same interpretation of distances and document that interpretation._
 *
 * _span_ - As a consequence of #RoutingAPISegmentsCanOverlap, the term _span_ is to be used when breaking down a complete voyage into non-overlapping distances #SpansDontOverlapInBreakdownBySameCharacteristic.
 *
 * A _distance span_ here indicates the distance where some characteristic of the route fully _spans_. Importantly, this means it can represent both a contiguous fragment of voyage where the characteristic applies, but also a summation of many distances of the same characteristic (as in subtotal of all fragments by that characteristic) - #SpanCanBeOfSummationOrAnUnsummatedItem.
 */
interface IDistanceSpan {
  /**
   * The distance on which the characteristics (described by the other properties of this instance) fully _span_.
   *
   * E.g. a value `{ distance: 30, operationalRestrictionIds: new Set('seca') }` represents the fact that the mentioned 'seca' restriction fully _spans_ that distance.
   *
   * Note - this can be a subtotal or a single fragment - #SpanCanBeOfSummationOrAnUnsummatedItem. Code naming should be used to distinguish which it is (see the member name or call that produced it).
   */
  distance: number;
}

interface IOperationalRestrictionsDistanceSpan extends IDistanceSpan {
  operationalRestrictionIds: Set<OperationalRestrictionId>;
}

export function getSubtotalsByOperationalRestrictionIds(
  routeSegments: ISegment[],
  totalDistanceNm: number
): IOperationalRestrictionsDistanceSpan[] {
  return Object.values(
    groupBy(
      [
        ...getRouteAsSequenceOfDistancesThroughOperationalRestrictions(
          routeSegments,
          totalDistanceNm
        ),
      ],
      (operationalRestrictionsDistanceSpan) =>
        setUtils.getEquatableString(operationalRestrictionsDistanceSpan.operationalRestrictionIds) // TODO everytime groupBy compares, it will sort the zoneTypeIds. This equatable string can be added onto the result before calling the `groupBy`, so it's more performant.
    )
  ).map((group) => ({
    distance: sum(group.map((g) => g.distance)),
    operationalRestrictionIds: group[0].operationalRestrictionIds,
  }));
}

interface IZoneIdentity {
  id: ZoneId;
}

interface IZoneIdentityAndType extends IZoneIdentity {
  typeId: ZoneTypeId;
}

interface IZone extends IZoneIdentityAndType {
  name: string;
}

type IZoneOnRoute = IZone & {
  intersections: IPointsOfIntersection[],
};

interface IZonesDistanceSpan extends IDistanceSpan {
  zones: Iterable<IZoneOnRoute>;
}

function getOperationalRestrictionIdsSetFromZoneInfo(
  zonesInfo: Iterable<IZoneIdentityAndType>
): Set<OperationalRestrictionId> {
  /* create sets based on zoneTypeIds for the purposes of group by later, however also allow any 
      not well known zoneTypeIds (by virtue of giving us an undefined operationalRestricitionId) to 
      fall into the Set({}) bucket which represents the none (no restriction) better as opposed to the 
      Set({undefined}) bucket. */

  return new Set(
    [...zonesInfo]
      .map((zoneInfo) => getOperationalRestrictionIdFromZoneInfo(zoneInfo))
      .filter((operationalRestrictionId) => operationalRestrictionId !== null)
  );
}

const borderIntersectionTypeIdArray = ['entryPointNm', 'exitPointNm'];
// For now, need to duplicate to declare the eraseable type but TODO - remove duplication when [this Flow feature](https://github.com/facebook/flow/issues/961) is delivered, or when moved to TypeScript, using [this approach](https://stackoverflow.com/questions/52085454/typescript-define-a-union-type-from-an-array-of-strings/55505556#55505556)
type BorderIntersectionTypeId = 'entryPointNm' | 'exitPointNm';

const borderIntersectionTypeId: StringEnum<BorderIntersectionTypeId> = stringEnum(
  borderIntersectionTypeIdArray
);

interface IContiguousZonesDistanceSpan extends IZonesDistanceSpan {
  pointAtEnd: {
    nm: Number,
    zoneBorderIntersectionsAtThisNm: Iterable<{
      type: BorderIntersectionTypeId,
      routeSegment: ISegment,
      intersection: IPointsOfIntersection,
    }>,
  };
}

/**
  Invariants of the return value:
   * Summing the items will give distance of the complete route (total distance from start to end). This is especially in contrast to `routeSegments`, which only represent intersections with some zones of interest, but they don't include the 'open-sea, no intersection with any zone' distances.
   * The items are in order - same sequence as they occur on the route
 */
function* getRouteAsSequenceOfDistancesThroughZones(
  routeSegments: ISegment[],
  totalDistanceNm: number
): IContiguousZonesDistanceSpan[] {
  routeSegments = [
    ...routeSegments,
    /* Add the artificial item to cover the scenario of 'open sea' by the origin or destination points (`origin | open sea | some zones | ...` or `... | some zones | open sea | destination`)
     We are meant to cover the distance end-to-end. So, if there's some distance uncovered by zones at the ends, we need to return the end points of the journey.
    */
    {
      zoneId: 'Total Route Segment (artificial)',
      zoneTypeId: 'Total Route Segment (artificial)',
      name: 'Total Route Segment (artificial)',
      pointsOfIntersection: [
        {
          entryPointNm: 0,
          exitPointNm: totalDistanceNm,
        },
      ],
    },
  ];

  const zoneBorderPoints = [
    ...flatten(
      routeSegments.map((routeSegment) =>
        flatten(
          routeSegment.pointsOfIntersection.map((intersection) => [
            {
              nm: intersection.entryPointNm,
              type: borderIntersectionTypeId.entryPointNm,
              routeSegment,
              intersection,
            },
            {
              nm: intersection.exitPointNm,
              type: borderIntersectionTypeId.exitPointNm,
              routeSegment,
              intersection,
            },
          ])
        )
      )
    ),
  ];

  const orderedRouteNmWithZoneBorderIntersections = Object.entries(
    groupBy(zoneBorderPoints, (zoneBorderPoint) => zoneBorderPoint.nm)
  )
    .sort(([nmAsStringA, groupA], [nmAsStringB, groupB]) => groupA[0].nm - groupB[0].nm)
    .map(([nmAsString, group]) => ({
      nm: group[0].nm,
      zoneBorderIntersectionsAtThisNm: group,
    }));

  const currentZonesByZoneIdsMap: Map<ZoneId, Set<IZoneOnRoute>> = new Map();

  const adjacentPairs = orderedRouteNmWithZoneBorderIntersections
    .map((item, index) => ({
      pointAtStart: orderedRouteNmWithZoneBorderIntersections[index - 1],
      pointAtEnd: item,
    }))
    /* Skip one, which doesn't have a `pointAtStart`. We want the pairs only, of which there will be `length - 1`. And we will always have length of at least 2, because we're adding the artificial 'total route' segment. */
    .slice(/* begin: */ 1);

  for (const routeFragment of adjacentPairs) {
    for (const zoneBorderIntersection of routeFragment.pointAtStart
      .zoneBorderIntersectionsAtThisNm) {
      if (zoneBorderIntersection.type === borderIntersectionTypeId.entryPointNm) {
        currentZonesByZoneIdsMap.set(zoneBorderIntersection.routeSegment.zoneId, {
          id: zoneBorderIntersection.routeSegment.zoneId,
          name: zoneBorderIntersection.routeSegment.name,
          zoneTypeId: zoneBorderIntersection.routeSegment.zoneTypeId,
          intersections: zoneBorderIntersection.routeSegment.pointsOfIntersection,
        });
      } else {
        currentZonesByZoneIdsMap.delete(zoneBorderIntersection.routeSegment.zoneId);
      }
    }

    yield {
      distance: routeFragment.pointAtEnd.nm - routeFragment.pointAtStart.nm,
      zones: [...currentZonesByZoneIdsMap.values()],
      pointAtStart: routeFragment.pointAtStart,
      pointAtEnd: routeFragment.pointAtEnd,
    };
  }
}

export function getRouteAsSequenceOfDistancesThroughOperationalRestrictions(
  routeSegments: ISegment[],
  totalDistanceNm: Number
): Array<IOperationalRestrictionsDistanceSpan & IZonesDistanceSpan & IContiguousZonesDistanceSpan> {
  return [...getRouteAsSequenceOfDistancesThroughZones(routeSegments, totalDistanceNm)].map(
    (zonesDistanceSpan) => ({
      ...zonesDistanceSpan,
      distance: zonesDistanceSpan.distance,
      operationalRestrictionIds: getOperationalRestrictionIdsSetFromZoneInfo(
        [...zonesDistanceSpan.zones].map((zoneOnRoute) => ({
          id: zoneOnRoute.zoneId,
          typeId: zoneOnRoute.zoneTypeId,
        }))
      ),
    })
  );
}
