import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Panel, PanelSection } from 'components/panel';
import { Header } from 'components/headers';
import Divider, { GoldenDivider } from 'components/divider';
import { PrimaryButton, SecondaryButton } from 'components/button';
import { selector } from './selector';
import {
  vesselChanged,
  deadweightChanged,
  vesselDraftChanged,
  vesselDraftUnitChanged,
  grainChanged,
  grainUnitChanged,
  tpcmiChanged,
  immersionUnitChanged,
  cargoChanged,
  stowageChanged,
  stowageUnitChanged,
  portChanged,
  portDraftChanged,
  portDraftUnitChanged,
  salinityChanged,
  salinityFactorChanged,
  constantsChanged,
  bunkersOnBoardChanged,
  freshWaterChanged,
  calculateMaxLiftFromApi,
  clearAll,
  setIntakeCalculatorFromApi,
  specificGravityChanged,
} from 'actions/intake-calculator';
import round from 'lodash/round';
import { formatNumber } from 'utilities/number';
import VesselInputs from './components/vessel-inputs';
import CargoInputs from './components/cargo-inputs';
import PortInputs from './components/port-inputs';
import ConstantInputs from './components/constant-inputs';
import { emptyGuid, isEmptyGuid } from 'utilities/guid';
import { SALT } from 'constants/enums/salinity-types';
import { METERS } from 'constants/enums/draft-units';
import { CUBIC_FEET_PER_METRIC_TON } from 'constants/enums/stowage-factor-units';
import { SalinityTypes } from 'constants/enums/salinity-types';
import './styles.scss';
import { isTanker } from 'constants/market-segments';

class IntakeCalculator extends Component {
  componentDidMount() {
    this.applyWorksheetData();
  }

  getUpdateMaxLiftFlag = (args) => {
    const defaultFlag = true;
    if (args && args[0] && args[0].length === 2) {
      const updateMaxLift = args[0][1].updateMaxLift;
      return updateMaxLift === undefined ? defaultFlag : updateMaxLift;
    } else {
      return defaultFlag;
    }
  };

  getArg = (args) => {
    let arg = [];
    if (args && args[0] && args[0].length === 2) {
      arg[0] = args[0][0];
    } else {
      arg = args;
    }
    return arg;
  };

  appendMaxLiftAction =
    (actionDetail) =>
    (...args) => {
      const action = actionDetail.action;
      const inputName = actionDetail.inputName;
      let updateMaxLift = this.getUpdateMaxLiftFlag(args);
      let arg = this.getArg(args);
      // if it's vessel then action apply should happen later
      // because its fetching vessel grain, immersion and other details from reducer/action
      if (!arg[0].vessel) {
        action.apply(this, arg);
      }
      const payloadMaxLiftInput = this.maxLiftInput(
        this.props.intakeCalculatorInputs,
        false,
        arg,
        inputName
      );
      if (arg[0].vessel) {
        arg[0].maxLiftInput = payloadMaxLiftInput;
        action.apply(this, arg);
      }
      if (updateMaxLift && !payloadMaxLiftInput.ignoreMaxLift) {
        this.props.calculateMaxLiftFromApi(payloadMaxLiftInput);
      }
    };

  applyWorksheetData = () => {
    if (this.props.worksheet) {
      const payload = {
        maxLiftInput: this.maxLiftInput(this.props.worksheet, true),
        worksheetProps: this.props.worksheet,
      };
      this.props.setIntakeCalculatorFromApi(payload);
    }
  };

  sanitisePorts = (ports) => ports.filter((port) => isEmptyGuid(port.locationId) === false);

  maxLiftInput = (inputs, reset, inputArgs, inputName) => {
    const { vessel, cargo, port, ports, constants, marketSegmentId } = inputs;
    let { general, bunkersOnBoard, freshWater } = constants;
    let inputPort = port;
    let inputCargo = cargo;
    let ignoreVesselDetails = false;
    let salinityFactor;
    if (reset) {
      let emptyPort = {
        id: emptyGuid,
        locationId: emptyGuid,
        name: '',
        draft: 0,
        draftUnit: METERS.key,
        salinity: SALT,
      };
      const validPorts = this.sanitisePorts(ports);
      inputPort = validPorts.length > 0 ? validPorts[0] : emptyPort;
    } else {
      if (inputArgs && inputArgs.length) {
        if (inputArgs[0].locationId) {
          inputPort = inputArgs[0];
        }
        if (inputArgs[0].label) {
          const salinityLabel = inputArgs[0].label;
          const index = SalinityTypes.findIndex((st) => st.label === salinityLabel);
          if (index > -1) {
            salinityFactor = inputArgs[0].value;
          }
        }
        if (inputArgs[0].cargoTypeId) {
          inputCargo = inputArgs[0];
        }
        if (inputArgs[0].vessel) {
          // when vessel name change,
          // vesselChanged event will fetch  additional info which required to cala max lift
          // so ignore to call max lift calc here
          // that will happen part of vesselChanged event acion
          ignoreVesselDetails = true;
        }

        switch (inputName) {
          case 'deadweight':
            vessel.deadweight = inputArgs[0];
            break;
          case 'vesselDraft':
            vessel.draft = inputArgs[0];
            break;
          case 'grain':
            vessel.grain = inputArgs[0];
            break;
          case 'tpcmi':
            vessel.tpcmi = inputArgs[0];
            break;
          case 'stowage':
            inputCargo.stowage = inputArgs[0];
            break;
          case 'specificGravity':
            inputPort.specificGravity = inputArgs[0];
            break;
          case 'portDraft':
            inputPort.draft = inputArgs[0];
            break;
          case 'salinityFactor':
            salinityFactor = inputArgs[0];
            break;
          case 'constants':
            general = inputArgs[0];
            break;
          case 'bunkersOnBoard':
            bunkersOnBoard = inputArgs[0];
            break;
          case 'freshWater':
            freshWater = inputArgs[0];
            break;
          default:
            break;
        }
      }
    }

    const totalConstants = general + bunkersOnBoard + freshWater;
    const stowageUnit = inputCargo.stowageUnit.key
      ? inputCargo.stowageUnit.key
      : inputCargo.stowageUnit;

    let stowageFactor;
    if (inputCargo.stowage) {
      stowageFactor = inputCargo.stowage;
    } else {
      stowageFactor =
        stowageUnit === CUBIC_FEET_PER_METRIC_TON.key
          ? inputCargo.stowageFeet
          : inputCargo.stowageMeters;
    }

    return {
      deadweight: ignoreVesselDetails ? 0 : vessel.deadweight,
      vesselDraft: ignoreVesselDetails ? 0 : vessel.draft,
      vesselDraftUnit: ignoreVesselDetails
        ? 0
        : vessel.draftUnit.key
          ? vessel.draftUnit.key
          : vessel.draftUnit,
      grain: ignoreVesselDetails ? 0 : vessel.grain,
      grainUnit: ignoreVesselDetails
        ? 0
        : vessel.grainUnit.key
          ? vessel.grainUnit.key
          : vessel.grainUnit,
      immersion: ignoreVesselDetails ? 0 : vessel.tpcmi,
      immersionUnit: ignoreVesselDetails
        ? 0
        : vessel.immersionUnit.key
          ? vessel.immersionUnit.key
          : vessel.immersionUnit,
      constants: totalConstants,
      portDraft: inputPort.draft,
      portDraftUnit: inputPort.draftUnit.key ? inputPort.draftUnit.key : inputPort.draftUnit,
      salinityFactor: salinityFactor ? salinityFactor : inputPort.salinity.value,
      stowageFactor: stowageFactor,
      stowageUnit: stowageUnit,
      specificGravity: inputCargo.specificGravity,
      marketSegmentId: marketSegmentId,
      ignoreMaxLift: ignoreVesselDetails,
    };
  };

  vesselActions = {
    vesselChanged: this.appendMaxLiftAction({
      action: this.props.vesselChanged,
      inputName: 'vessel',
    }),
    deadweightChanged: this.appendMaxLiftAction({
      action: this.props.deadweightChanged,
      inputName: 'deadweight',
    }),
    vesselDraftChanged: this.appendMaxLiftAction({
      action: this.props.vesselDraftChanged,
      inputName: 'vesselDraft',
    }),
    vesselDraftUnitChanged: this.appendMaxLiftAction({
      action: this.props.vesselDraftUnitChanged,
      inputName: 'vesselDraftUnit',
    }),
    grainChanged: this.appendMaxLiftAction({
      action: this.props.grainChanged,
      inputName: 'grain',
    }),
    grainUnitChanged: this.appendMaxLiftAction({
      action: this.props.grainUnitChanged,
      inputName: 'grainUnit',
    }),
    tpcmiChanged: this.appendMaxLiftAction({
      action: this.props.tpcmiChanged,
      inputName: 'tpcmi',
    }),
    immersionUnitChanged: this.appendMaxLiftAction({
      action: this.props.immersionUnitChanged,
      inputName: 'immersionUnit',
    }),
  };

  cargoActions = {
    cargoChanged: this.appendMaxLiftAction({
      action: this.props.cargoChanged,
      inputName: 'cargo',
    }),
    stowageChanged: this.appendMaxLiftAction({
      action: this.props.stowageChanged,
      inputName: 'stowage',
    }),
    stowageUnitChanged: this.appendMaxLiftAction({
      action: this.props.stowageUnitChanged,
      inputName: 'stowageUnit',
    }),
    specificGravityChanged: this.appendMaxLiftAction({
      action: this.props.specificGravityChanged,
      inputName: 'specificGravity',
    }),
  };

  portActions = {
    portChanged: this.appendMaxLiftAction({
      action: this.props.portChanged,
      inputName: 'port',
    }),
    portDraftChanged: this.appendMaxLiftAction({
      action: this.props.portDraftChanged,
      inputName: 'portDraft',
    }),
    portDraftUnitChanged: this.appendMaxLiftAction({
      action: this.props.portDraftUnitChanged,
      inputName: 'portDraftUnit',
    }),
    salinityChanged: this.appendMaxLiftAction({
      action: this.props.salinityChanged,
      inputName: 'salinity',
    }),
    salinityFactorChanged: this.appendMaxLiftAction({
      action: this.props.salinityFactorChanged,
      inputName: 'salinityFactor',
    }),
  };

  constantsActions = {
    constantsChanged: this.appendMaxLiftAction({
      action: this.props.constantsChanged,
      inputName: 'constants',
    }),
    bunkersOnBoardChanged: this.appendMaxLiftAction({
      action: this.props.bunkersOnBoardChanged,
      inputName: 'bunkersOnBoard',
    }),
    freshWaterChanged: this.appendMaxLiftAction({
      action: this.props.freshWaterChanged,
      inputName: 'freshWater',
    }),
  };

  render() {
    const {
      vessel,
      cargo,
      port: currentPort,
      ports,
      constants,
      calculationResult,
      marketSegmentId,
    } = this.props.intakeCalculatorInputs;

    const { balticTankerIndexVessels, balticIndexVessels } = this.props;
    return (
      <Panel>
        <PanelSection>
          <Header text="Vessel" />
          <VesselInputs
            vessel={vessel}
            balticIndexVessels={
              isTanker(marketSegmentId) ? balticTankerIndexVessels : balticIndexVessels
            }
            actions={this.vesselActions}
          />
        </PanelSection>
        <GoldenDivider />
        <PanelSection>
          <Header text="Cargo" />
          <CargoInputs
            cargo={cargo}
            actions={this.cargoActions}
            marketSegmentId={marketSegmentId}
          />
        </PanelSection>
        <GoldenDivider />
        <PanelSection>
          <Header text="Port" />
          <PortInputs
            port={currentPort}
            ports={ports}
            actions={this.portActions}
            marketSegmentId={marketSegmentId}
          />
        </PanelSection>
        <GoldenDivider />
        <PanelSection>
          <Header text="Constants" />
          <ConstantInputs constants={constants} actions={this.constantsActions} />
        </PanelSection>
        <Divider />
        <PanelSection>
          <div className="intake-calc__row intake-calc__row-reverse">
            <div className="intake-calc__result">
              <div className="intake-calc__result--label">Maximum Lift</div>
              <div className="intake-calc__result--value">
                {calculationResult.errors.length === 0 ? (
                  `${formatNumber(round(calculationResult.maximumLift), 0)} MT`
                ) : (
                  <>&mdash;</>
                )}
              </div>
            </div>
          </div>
        </PanelSection>
        <GoldenDivider />
        <PanelSection>
          <div className="intake-calc__row intake-calc__actions">
            <SecondaryButton onClick={this.props.clearAll} diagnosticId="IntakeCalculator/Clear">
              CLEAR
            </SecondaryButton>
            <PrimaryButton
              onClick={this.applyWorksheetData}
              diagnosticId="IntakeCalculator/Refresh"
            >
              REFRESH
            </PrimaryButton>
          </div>
        </PanelSection>
      </Panel>
    );
  }
}

const mapStateToProps = selector;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      vesselChanged,
      deadweightChanged,
      vesselDraftChanged,
      vesselDraftUnitChanged,
      grainChanged,
      grainUnitChanged,
      tpcmiChanged,
      immersionUnitChanged,
      cargoChanged,
      stowageChanged,
      stowageUnitChanged,
      portChanged,
      portDraftChanged,
      portDraftUnitChanged,
      salinityChanged,
      salinityFactorChanged,
      constantsChanged,
      bunkersOnBoardChanged,
      freshWaterChanged,
      calculateMaxLiftFromApi,
      clearAll,
      setIntakeCalculatorFromApi,
      specificGravityChanged,
    },
    dispatch
  );
};

const IntakeCalculatorContainer = connect(mapStateToProps, mapDispatchToProps)(IntakeCalculator);

export default IntakeCalculatorContainer;
