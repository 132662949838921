import React from 'react';
import NumericInput from 'components/numeric-input';
import DropDown from 'components/dropdown';
import { ALL_FUEL_GRADES_IN_UI_ORDER } from 'constants/enums/fuel-grades';
import { SpeedAndConsHistory } from './speed-and-cons-history';

const isMainEngine: boolean = true;

const StaticSpeedAndCon = (props) => {
  const { speedAndCon, vesselId, onConsumptionChanged, onFuelGradeChanged, marketSegmentId } =
    props;

  return (
    <div className="table-row">
      <div className="speed-and-cons-multi-vessel__static--type">{speedAndCon.label}</div>
      <SpeedAndConsHistory
        sandTypeId={speedAndCon.type}
        vesselId={vesselId}
        modifiedBy={speedAndCon.auditInfo.modifiedBy}
        modifiedDate={speedAndCon.auditInfo.modifiedDate}
        marketSegmentId={marketSegmentId}
        className="speed-and-cons-multi-vessel__static--info"
      />
      <NumericInput
        value={speedAndCon.consumption}
        onInputChange={onConsumptionChanged(speedAndCon.type, isMainEngine)}
        className="speed-and-cons-multi-vessel__static--consumption"
        maxDecimalDigits="2"
        minValue="0"
        maxValue="999.99"
        diagnosticId="StaticSpeedAndCons/ChangeConsumption"
      />
      <DropDown
        items={ALL_FUEL_GRADES_IN_UI_ORDER}
        selectedItem={speedAndCon.fuelGrade}
        onChange={onFuelGradeChanged(speedAndCon.type, isMainEngine)}
        className="status-specific-fuel-grade"
        diagnosticId="StaticSpeedAndCons/ChangeFuelGrade"
      />
      <NumericInput
        value={speedAndCon.generatorConsumption}
        onInputChange={onConsumptionChanged(speedAndCon.type, !isMainEngine)}
        className="speed-and-cons-multi-vessel__static--generator"
        maxDecimalDigits="2"
        minValue="0"
        maxValue="999.99"
        diagnosticId="StaticSpeedAndCons/ChangeConsumption"
      />
      <DropDown
        items={ALL_FUEL_GRADES_IN_UI_ORDER}
        selectedItem={speedAndCon.generatorFuelGrade}
        onChange={onFuelGradeChanged(speedAndCon.type, !isMainEngine)}
        className="status-specific-fuel-grade"
        diagnosticId="StaticSpeedAndCons/ChangeFuelGrade"
      />
    </div>
  );
};

export default StaticSpeedAndCon;
