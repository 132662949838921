import VError from 'verror';
import { veApi } from 'api';
import { SET_USER_STATE, SET_USER_PREFERENCE_STATE } from 'constants/action-types/user-state';
import { NOT_FOUND } from 'http-status-codes';
import { buildAction } from 'utilities/redux';
import { createWorksheetWithVesselIds } from 'actions/compound-actions/create-worksheet-with-vessel-ids';
import { createWorksheetForCargoOrder } from 'actions/compound-actions/create-worksheet-for-cargo-order';
import { receiveCalcEntryPointMessageEventFromWindowOpener } from 'cross-app-integration/receive-calc-entry-point-message-event-from-window-opener';
import { sendMessageToWindowOpener } from 'cross-app-integration/send-message-to-window-opener';
import * as abortSignalUtils from 'utilities/abort-signal-utils';
import { logErrorDetailsOnlyToConsole } from 'utilities/error/log-error-details-only-to-console';
import { createWorkbookWithEmptyWorksheet } from 'actions/workbook';
import { DEFAULT_WORKBOOK_NAME } from 'constants/defaults/names';
import { getHostNameFromUrlString } from 'utilities/url';
import { getMarketSegmentForNewWorkbook, marketSegmentIds } from 'constants/market-segments';

export function loadUserState() {
  return async (dispatch, getState) => {
    let userState = await getUserState(dispatch);

    const isFirstTimeUser = userState === null;
    if (isFirstTimeUser) {
      userState = {
        lastViewedWorkbookId: null,
      };
      await createUserState(userState);
    }

    dispatch(setUserState(userState));

    if (window.location.hash === '#handle-app-entry-point-message-from-window-opener') {
      const messageEvent = await receiveCalcEntryPointMessageEventFromWindowOpener({
        abortSignal: abortSignalUtils.fromTimeoutMs(5000),
        // `messagePredicate` is needed especially because #BrowserAddonsUseWindowPostMessage
        messagePredicate: (message) => message && message.targetProgram === 'SEA_CALC__ENTRY_POINT',
      });
      const message = messageEvent.data;

      switch (message.entrypointId) {
        case 'REQUEST__NAVIGATE_TO_NEW_WORKSHEET_WITH_VESSEL_IDS': {
          const args = message.args;
          const result = await dispatch(
            createWorksheetWithVesselIds(
              args.vesselIds,
              args.workbookName,
              'SeaCalcSdk-createWorksheetWithVesselIds',
              getHostNameFromUrlString(messageEvent.origin)
            )
          );
          window.history.replaceState(
            /* data: */ undefined,
            /*title:*/ undefined,
            /*url: */ `#/workbook/${result.workbookId}/worksheet/${result.worksheetId}`
          );
          break;
        }
        case 'REQUEST__NAVIGATE_TO_NEW_WORKSHEET_FOR_CARGO_ORDER': {
          let result = null;

          try {
            result = await dispatch(
              createWorksheetForCargoOrder(
                message.args,
                'SeaCalcSdk-createWorksheetForCargoOrder',
                getHostNameFromUrlString(messageEvent.origin)
              )
            );

            window.history.replaceState(
              /* data: */ undefined,
              /*title:*/ undefined,
              /*url: */ `#/workbook/${result.workbookId}/worksheet/${result.worksheetId}`
            );
          } catch (error) {
            sendMessageToWindowOpener({
              targetProgram: 'OPENER_OF__SEA_CALC',
              messageType: 'EVENT__SEA_CALC__EXCEPTION',
              targetOrigin: messageEvent.origin,
              data: {
                errorData: {
                  message: error.message,
                  name: error.name,
                  info: VError.info(error),
                },
              },
            });

            //Rethrowing the error, so code further above the stack can _react_ 😉 to it, if they wish to.
            throw new VError(error);
          }

          window.onbeforeunload = function () {
            const worksheetId = result.worksheetId;
            const state = getState();
            const worksheet = state.worksheetsById[worksheetId];
            const calculations = state.calculationsByWorksheetId[
              worksheetId
            ].calculationResults.map((calculationResult) => {
              const vessel = worksheet.vessels.find(
                (v) => v.entryId === calculationResult.vesselEntryId
              );
              const calculation = {
                vesselId: vessel.vesselId,
                netTimeCharterEquivalent: calculationResult.voyageRate.timeCharterRateCalculatedNet,
              };
              return calculation;
            });
            const latestWorksheetCalculationView = {
              worksheetId: worksheet.id,
              calculations,
            };
            sendMessageToWindowOpener({
              targetProgram: 'OPENER_OF__SEA_CALC',
              messageType: 'EVENT__SEA_CALC__CALCULATION_RESULT',
              targetOrigin: messageEvent.origin,
              data: latestWorksheetCalculationView,
            });
          };

          break;
        }
        default:
          throw logErrorDetailsOnlyToConsole(
            new Error(
              /*message: */ `Not implemented handler for \`message.type\`=${message.type} (see full message in dev console - written there only for confidentiality)`
            ),
            /* details: */ { message }
          );
      }
    } else if (isFirstTimeUser) {
      const { workbookId, worksheetId } = await dispatch(
        createWorkbookWithEmptyWorksheet({
          createdFromProgramId: 'SeaCalcUI',
          createdFromOriginId: getHostNameFromUrlString(document.location.origin),
          workbookName: DEFAULT_WORKBOOK_NAME,
          marketSegmentId: getMarketSegmentForNewWorkbook(),
        })
      );

      /* Redirect to the new workbook
           But check the entry URL so that we don't redirect where the first time user actually came to see a colleagues' worksheet. */
      if (!window.location.hash.startsWith('#/workbook'))
        // TODO - consider moving this create & redirect into the workbook panel, where it can do it while informing the user. Alternatively, ditch the feature completely and just open the workbooks panel for these users - it has a big 'Add new book' they cannot miss.
        window.history.replaceState(
          /* data: */ undefined,
          /*title:*/ undefined,
          /*url: */ `#/workbook/${workbookId}/worksheet/${worksheetId}`
        );
    }
  };
}

export const loadUserPreference = () => async (dispatch, getState) => {
  let response = await getUserPreference(dispatch);

  let validResponse =
    response !== null &&
    response.userPreference.defaultMarketSegmentId !== marketSegmentIds.undefined;

  let defaultMarketSegmentId = validResponse
    ? response.userPreference.defaultMarketSegmentId
    : marketSegmentIds.dryCargo;

  let userPreference = {
    defaultMarketSegmentId: defaultMarketSegmentId,
  };

  if (!validResponse) {
    await veApi.put('/userPreference', userPreference);
  }

  dispatch(setUserPreference(userPreference));
};

const getUserPreference = async (dispatch) => {
  try {
    return (await veApi.get('/userPreference')).data;
  } catch (error) {
    if (error.response && error.response.status === NOT_FOUND) {
      return null;
    }
    throw error;
  }
};

export const updateUserPreference = (userPreference) => async (dispatch) => {
  const response = await veApi.put('/userPreference', userPreference);
  await dispatch(setUserPreference(userPreference));
  return response;
};

export const setMarketSegmentId = (symbol: any) => async (dispatch) => {
  return await dispatch(updateUserPreference({ defaultMarketSegmentId: symbol.key }));
};

export const setUserPreference = (userPreference) =>
  buildAction(SET_USER_PREFERENCE_STATE, userPreference);

const getUserState = async (dispatch) => {
  try {
    return (await veApi.get('/userState')).data;
  } catch (error) {
    if (error.response && error.response.status === NOT_FOUND) {
      return null;
    }
    throw error;
  }
};

export const setLastViewedWorkbook = (lastViewedWorkbookId: string) => async (dispatch) => {
  return await dispatch(updateUserState({ lastViewedWorkbookId: lastViewedWorkbookId }));
};

const setUserState = (userState) => buildAction(SET_USER_STATE, userState);

const createUserState = async (userState) => {
  return (await veApi.post('/userState', userState)).data;
};

const updateUserState = (userState) => async (dispatch) => {
  const response = (await veApi.put('/userState', userState)).data;
  await dispatch(setUserState(userState));
  return response;
};
