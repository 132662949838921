import { getValueFromLocalStorage } from 'utilities/string-key-value-sources/sources/local-storage';

/**
 * `?` denotes that when the source has no data, the function will return `undefined`.
 */
type FeatureFlagSourceResultType = ?mixed;

export function getFeatureFlagValueFromQueryString(
  featureKey: string
): FeatureFlagSourceResultType {
  const stringValue = new URLSearchParams(window.location.search).get(
    // add an affix to make it clear this is a feature toggle and make clashes practically impossible
    `featureToggles.${featureKey}`
  );

  if (stringValue === '')
    // Treat empty string as true for simplicity (a bit like in [HTML attributes](https://html.spec.whatwg.org/multipage/common-microsyntaxes.html#boolean-attributes)). The lack of the flag will most often  be represented by no parameter at all.
    return true;

  return JSON.parse(stringValue);
}

export function getFeatureFlagValueFromLocalStorage(
  featureKey: string
): FeatureFlagSourceResultType {
  const stringValueOrNull = getValueFromLocalStorage(featureKey);
  if (stringValueOrNull === undefined) return undefined;
  return JSON.parse(stringValueOrNull);
}

export function getFeatureFlagValueFromConfig(
  features: object,
  featureName: string
): FeatureFlagSourceResultType {
  if (!features) throw new Error(`'features' must be specified`);
  if (!featureName) throw new Error(`'featureName' must be specified`);

  const featureValue = features[featureName];
  if (typeof featureValue === 'undefined') {
    return undefined;
  }

  return JSON.parse(featureValue);
}
