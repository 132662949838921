import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import type { CleanupFn } from 'typings/core-types';
import { Grid } from 'components/grid';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { default as VesselWithMultiVesselSupport } from 'modules/vessel/vessel';
import {
  vesselsAdd,
  vesselsMoved,
  vesselsRemove,
  vesselUpdated,
  refreshOpenPosition,
  refreshAllVesselsOpenPosition,
  openPositionUpdated,
  openDateUpdated,
} from 'actions/worksheet/vessel';
import { createEmptyVessel, getNewVesselEntryId } from 'reducers/worksheet/vessels/vessel';
import NumericInput from 'components/numeric-input';
import {
  grossTimeCharterUpdated,
  netTimeCharterUpdated,
  ballastBonusUpdated,
} from 'actions/worksheet/vessel/rates';
import { IconButton, MaterialIconButton } from 'components/button';
import { iconEnum, WarningIcon } from 'components/icons';
import VesselAutoComplete from 'components/vessel-autocomplete';

import DropDown from 'components/dropdown';
import { ScrubberTypesEnum } from 'constants/enums/scrubber';
import { scrubberTypeUpdated } from 'actions/worksheet/vessel';
import ExpandCollapseButton from 'components/expand-collapse/expand-collapse-button';
import { setActiveVesselEntryId } from 'actions/user-state/set-active-vessel-entry-id';
import { singleOrNullIfEmptyOrThrowIfMany, singleOrThrow } from 'utilities/iterable';
import { addEventListenerGetCleanupFn } from 'utilities/dom-events/add-event-listener-get-cleanup-fn';
import './styles.scss';
import {
  getDynamicVesselValidationRules,
  getValidationMessagesForVessel,
} from 'modules/worksheet/business-model/validation';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { EXCEED_PORT_DRAFT_WITHOUT_CARGO } from 'modules/calculate-intake-max-lift/max-lift-error-messages';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { getActiveWorksheetInvariantProps } from 'common-selectors/get-active-worksheet-invariant-props';
import { createStructuredSelector } from 'reselect';
import { marketSegmentIds } from 'constants/market-segments';
import PortAutocomplete from 'components/port-autocomplete';
import { mapToOpenPositionUIComponentModel } from 'modules/vessel/vessel-detail/selectors';
import MaritimeDate from 'components/maritime-date';
import { isDry } from 'constants/market-segments';

ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule]);

// ag-grid hooks extracted from [`ICellRendererComp`](https://www.ag-grid.com/javascript-grid-cell-rendering-components/#cell-renderer-component)
interface CellRendererHooks {
  /* found that in some cases prevents horrible flicker */
  // Mandatory - Get the cell to refresh. Return true if the refresh succeeded, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create
  // a new component in its place with the new values.
  // see https://www.ag-grid.com/javascript-grid-cell-rendering-components/#handling-refresh-2
  refresh(params): boolean;
}

class VesselDetailsRenderer extends React.Component implements CellRendererHooks {
  constructor(props) {
    super(props);
    this.state = { ...this.props.data };
    this.containerElementRef = React.createRef();
    this.hasBuiltTooltip = false;
  }

  cleanupFunctions: Array<CleanupFn> = [];

  componentDidMount() {
    this.addBehavioursToLinkChildWithParentSelection();
    this.addBehaviourToFixHoverTooltipNotShownOnFirstMount();
  }

  addBehavioursToLinkChildWithParentSelection() {
    /* TODO - track [AG-1722 Allow detail rows to be selected](https://www.ag-grid.com/ag-grid-pipeline/) (found [here](https://github.com/ag-grid/ag-grid/issues/1720)), to see if this isn't built-in in the latest version */
    this.addBehaviourToSelectRowOwningThisDetailOnInteraction();
    /* TODO make the detail highlighted when the parent is selected, e.g. using `addEventListenerGetCleanupFn(this.props.api, 'selectionChanged', () => ...)`:
      Some options:
        * use `state` here and style only the contents
        * or explore if [`getRowClass`](https://www.ag-grid.com/javascript-grid-row-styles/#row-class) has access to the data allowing to tell if this row's parent is selected, and if so,  [use `redrawRows` here to nudge it after the selection changed](https://www.ag-grid.com/javascript-grid-refresh/#redraw-rows)
    */
  }

  addBehaviourToFixHoverTooltipNotShownOnFirstMount() {
    this.cleanupFunctions.push(
      addEventListenerGetCleanupFn(this.containerElementRef.current, 'mouseover', () => {
        if (!this.hasBuiltTooltip) {
          this.hasBuiltTooltip = true;
        }
      })
    );
  }

  addBehaviourToSelectRowOwningThisDetailOnInteraction() {
    const ensureRowOwningThisDetailIsSelected = () => {
      if (!this.props.node.parent.selected) this.props.node.parent.setSelected(true);
    };

    this.cleanupFunctions.push(
      addEventListenerGetCleanupFn(
        this.containerElementRef.current,
        'click',
        ensureRowOwningThisDetailIsSelected
      ),
      // Add same for 'focusin', so that the owning row also gets selected when using focus instead of click (e.g. when using Tab)
      addEventListenerGetCleanupFn(
        this.containerElementRef.current,
        /* Interestingly, [onFocusIn isn't implemented by React yet](https://github.com/facebook/react/issues/6410), so we wouldn't be able to add it on the element directly */
        'focusin',
        ensureRowOwningThisDetailIsSelected
      )
    );
  }

  refresh(params) {
    this.setState({ ...params.data });
    return true;
  }

  render() {
    const vessel = this.state;
    return (
      <div key={vessel.entryId} data-testid="vessel-details" ref={this.containerElementRef}>
        <VesselWithMultiVesselSupport vessel={vessel} />
      </div>
    );
  }

  componentWillUnmount() {
    for (const cleanupFunction of this.cleanupFunctions) {
      cleanupFunction();
    }
  }
}

class ExpandCollapseActionRenderer extends React.Component implements CellRendererHooks {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.value,
    };
  }

  refresh(params) {
    this.setState({ expanded: params.value });
    return true;
  }
  render() {
    const { expanded } = this.state;
    return (
      <ExpandCollapseButton
        data-testid="worksheet-vessel-grid-expandcollapse-button"
        expanded={expanded}
        onClick={() => {
          const expandedToggled = !expanded;
          this.props.node.setExpanded(expandedToggled);
          this.setState({ expanded: expandedToggled });
        }}
      />
    );
  }
}

class VesselStatusRenderer extends React.Component {
  render() {
    const { shouldShowWarningIcon, tooltipHtmlText } = this.props.value;
    if (!shouldShowWarningIcon) {
      return null;
    }

    return (
      <div>
        <div
          className="worksheet-vessel-grid-vesselstatus__tooltip_content"
          data-tooltip-id="vessel-status-icon-tooltip"
          data-tooltip-content={tooltipHtmlText}
        >
          <WarningIcon className="worksheet-vessel-grid-vesselstatus__icon" />
        </div>
        {tooltipHtmlText && (
          <ReactTooltip
            id="vessel-status-icon-tooltip"
            className="ve-tooltip-default vessel-status-tooltip"
            noArrow
            style={{ width: '250px', textWrap: 'wrap' }}
          />
        )}
      </div>
    );
  }
}

class VesselDragRenderer extends React.Component {
  render() {
    return (
      <div>
        <span className="vessel-grid-grip has-icon icon--grip"></span>
      </div>
    );
  }
}

function VesselsGridRequiresConnect({
  worksheetId,
  worksheetInvariantProps,
  vessels,
  cargo,
  voyage,
  bunkerExpenses,
  vesselsAdd,
  vesselsRemove,
  vesselsMoved,
  setActiveVesselEntryId,
  refreshAllVesselsOpenPosition,
  calculationResults,
}) {
  const portDraftErrors = calculationResults
    ? calculationResults
        .flatMap((el) => {
          return el.additionalResults?.errors;
        })
        .filter((error) => error === EXCEED_PORT_DRAFT_WITHOUT_CARGO)
    : [];

  const columnDefs = useMemo(() => {
    return createColumnDefs({
      worksheetId,
      worksheetInvariantProps: worksheetInvariantProps,
      shouldAutoCalculateIntake: voyage.shouldAutoCalculateIntake,
      cargo,
      voyage,
      bunkerExpenses,
      refreshAllVesselsOpenPosition,
      vesselsAdd,
      vesselsRemove,
      calculationResults,
    });
  }, [voyage, calculationResults[0].vesselEntryId, portDraftErrors.length]);

  let immutableStore = vessels;

  const [gridApi, setGridApi] = useState(null);
  const [oldIndex, setOldIndex] = useState(null);
  const [destinationIndex, setDestinationIndex] = useState(null);

  function createRowsIndexDictionary(): { [key: number]: number } {
    const rowCount = gridApi.getDisplayedRowCount();
    const rowsAsDict: { [key: number]: number } = {};
    let noOfExpandedRows = 0;

    for (let i = 0; i < rowCount; i++) {
      const rowNode = gridApi.getDisplayedRowAtIndex(i);
      if (rowNode.level === 0) {
        rowsAsDict[i] = i - noOfExpandedRows;
      } else {
        noOfExpandedRows++;
        rowsAsDict[i] = i - noOfExpandedRows;
      }
    }
    return rowsAsDict;
  }

  function onRowDragEnd() {
    const indexMap = createRowsIndexDictionary();

    vesselsMoved({
      worksheetId: worksheetId,
      sourceIndex: oldIndex,
      destinationIndex: indexMap[destinationIndex],
    });
  }

  function onRowDragLeave() {
    const indexMap = createRowsIndexDictionary();
    vesselsMoved({
      worksheetId: worksheetId,
      sourceIndex: oldIndex,
      destinationIndex: indexMap[destinationIndex],
    });
  }

  function onRowDragEnter(e) {
    const indexMap = createRowsIndexDictionary();
    setOldIndex(indexMap[e.node.rowIndex]);
  }

  function onRowDragMove(event: RowDragMoveEvent) {
    const movingNode = event.node;
    const overNode = event.overNode;

    const rowNeedsToMove = movingNode !== overNode;

    const shouldMoveDownWhenOverNodeIsExpanded =
      overNode.detail && movingNode.rowIndex < overNode.rowIndex;

    const shouldMoveUpWhenOverNodeIsExpanded =
      (!!overNode.detailNode || (!overNode.master && overNode.expanded)) &&
      movingNode.rowIndex > overNode.rowIndex;

    const shouldMoveWhenOverNodeIsNotExpanded = overNode.expanded === false;

    const rowShouldMove =
      shouldMoveDownWhenOverNodeIsExpanded |
      shouldMoveUpWhenOverNodeIsExpanded |
      shouldMoveWhenOverNodeIsNotExpanded;

    if (rowNeedsToMove && rowShouldMove) {
      const movingData = movingNode.data;
      const overData = overNode.data;

      const fromIndex = immutableStore.indexOf(movingData);
      const toIndex = immutableStore.indexOf(overData);
      setDestinationIndex(overNode.rowIndex);
      const newStore = immutableStore.slice();
      moveInArray(newStore, fromIndex, toIndex);

      immutableStore = newStore;
      gridApi.setGridOption('rowData', newStore);

      gridApi.clearFocusedCell();
    }

    function moveInArray(arr: any[], fromIndex: number, toIndex: number) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells();
    }
  }, []);

  return (
    <div>
      <Grid
        getRowId={(params) => params.data.entryId}
        onGridReady={(params) => {
          immutableStore.forEach(function (data, index) {
            data.id = index;
          });

          setGridApi(params.api);
          params.api.setGridOption('rowData', immutableStore);
        }}
        className="vessels-grid"
        masterDetail={true}
        rowSelection="single"
        onSelectionChanged={(event: SelectionChangedEvent) => {
          const selectedRowData = singleOrNullIfEmptyOrThrowIfMany(event.api.getSelectedRows());
          if (selectedRowData === null) {
            // Nothing selected. This can happen on a delete. We don't allow 'no vessel selected' #DontAllowsNoVesselSelected, so let's immediately set one selected.
            event.api.getDisplayedRowAtIndex(0).setSelected(true);
            return; // We're going to get called again.
          } else setActiveVesselEntryId(selectedRowData.entryId);
        }}
        onFirstDataRendered={(event: AgGridEvent) => {
          const firstRowNode = event.api.getDisplayedRowAtIndex(0);

          // Select the first row as per #DontAllowsNoVesselSelected
          firstRowNode.setSelected(true);

          // If there's only one vessel, expand its details, especially to keep UI's familiarity to long-time users of single-vessel workshet sea/calc
          if (event.api.rowModel.getRowCount() === 1) {
            firstRowNode.setExpanded(true);
            // Also need to refresh because of #ExpandCollapseInOwnColumn - we have our own column for the expand/collapse button and it isn't linked to any data field
            event.api.refreshCells({
              rowNodes: [firstRowNode],
              columns: ['expandCollapse'],
            });
          }
        }}
        domLayout="autoHeight"
        rowHeight={32}
        headerHeight={32}
        detailCellRenderer={VesselDetailsRenderer}
        detailRowHeight={isDry(worksheetInvariantProps.marketSegmentId) ? 320 : 345}
        tabToNextCell={() => {
          /* Empty `tabToNextCell` function as part of #TabbingSupportViaNativeHTMLTab_When_DontUseAgGridCellsEditModeButImplementEditInReadOnlyModeRenderer
            #QuirkOfAgGridNoEditModeCellsFocusingOuterDiv - When not using edit mode, Ag-Grid all cells are read only and if we allowed its default `tabToNextCell` behavior by not overriding, it would 'focus' the outer div, thus losing the input's focus.
            TODO: #TODOConsiderUsingAgGridRecognizedFocus - Consider using "Ag-Grid-recognized" 'focus' by implementing a return value here - see https://www.ag-grid.com/javascript-grid-keyboard-navigation/#example-custom-navigation (NOTE: This will also require taking care of #QuirkOfAgGridNoEditModeCellsFocusingOuterDiv, e.g. using a [`onCellFocused`](https://www.ag-grid.com/javascript-grid-events/#selection) to be used for then focusing the actual input, not the div)
          */
        }}
        navigateToNextCell={() => {
          /* Empty `navigateToNextCell` function as part of #TabbingSupportViaNativeHTMLTab_When_DontUseAgGridCellsEditModeButImplementEditInReadOnlyModeRenderer. It simply prevents ag-grid from swallowing standard keystrokes like 'down arrow' to expand our dropdown. It also disables grid navigation using arrows, but this is fine. This kind of grid is simply more a form, so it is more conventionally navigated using tabs rather than arrows.*/
        }}
        components={{
          VesselDetailsRenderer: VesselDetailsRenderer,
          ExpandCollapseActionRenderer: DecorateRendererWithSelectOwnerRowOnFocus(
            ExpandCollapseActionRenderer
          ),
          VesselStatusRenderer: DecorateRendererWithSelectOwnerRowOnFocus(VesselStatusRenderer),
          VesselDragRenderer: VesselDragRenderer,
        }}
        rowData={vessels}
        immutableData={true}
        getRowNodeId={(rowData) => rowData.entryId}
        rowDragManaged={false}
        defaultColDef={{
          suppressMovable: true,
          sortable: false,
          resizable: false,
        }}
        columnDefs={columnDefs}
        reactiveCustomComponents
        onRowDragMove={onRowDragMove}
        onRowDragEnd={onRowDragEnd}
        onRowDragEnter={onRowDragEnter}
        onRowDragLeave={onRowDragLeave}
        // suppressMoveWhenRowDragging={true}
      />
    </div>
  );
}

export const VesselsGrid = connect(
  /* data properties: */ createStructuredSelector({
    cargo: (state) => singleOrThrow(getActiveWorksheet(state).cargoes),
    voyage: (state) => getActiveWorksheet(state).voyage,
    bunkerExpenses: (state) => getActiveWorksheet(state).bunkerExpenses,
    worksheetInvariantProps:
      getActiveWorksheetInvariantProps /* Using invariant props for optimization, while this is all that this component needs. */,
  }),
  /* action properties: */ {
    vesselsAdd,
    vesselsRemove,
    vesselsMoved,
    setActiveVesselEntryId,
    refreshAllVesselsOpenPosition,
  }
)(VesselsGridRequiresConnect);

function createColumnDefs({
  worksheetId,
  worksheetInvariantProps,
  /* TODO - #TODOVesselsGridRenderersToAccessStoreDirectly - remove this boilerplate of passing stuff accessible from the store. Make the renderers access store directly (e.g. like near the `colId: 'vessel'`) */
  shouldAutoCalculateIntake,
  cargo,
  voyage,
  bunkerExpenses,
  refreshAllVesselsOpenPosition,
  vesselsAdd,
  vesselsRemove,
  calculationResults,
}) {
  return [
    {
      headerName: '',
      cellRenderer: VesselDragRenderer,
      width: 26,
      rowDrag: true,
      colId: 'drag',
    },
    {
      /* #ExpandCollapseInOwnColumn:
      It was decided to have a dedicated column for expanding. This had correctness benefits when using cell renderer/editor approach (the approach is currently abandoned as per DontUseAgGridCellsEditModeButImplementEditInReadOnlyModeRenderer - the editor mode didn't render the arrow), but it is still kept as its header can serve as a place for 'expand all'/'collapse all'.
      An alternative would be to follow ag-grid's [example and use the 'agGroupCellRenderer'](https://www.ag-grid.com/javascript-grid-master-detail/#enabling-master-detail) together with our first column's renderer as its [`groupRowInnerRenderer`](https://www.ag-grid.com/javascript-grid-grouping/#full-width-groups-rendering) */
      headerName: '',
      colId: 'expandCollapse',
      valueGetter: (params) => {
        return params.node.expanded;
      },
      width: 24,
      cellClass: 'vessel-grid-expand-collapse',
      cellRenderer: ExpandCollapseActionRenderer,
    },
    {
      headerName: '',
      colId: 'vesselStatus',
      cellClass: 'worksheet-vessel-grid-vesselstatus',
      valueGetter: (params) => {
        const vessel = params.data;
        if (!shouldAutoCalculateIntake) {
          return {
            shouldShowWarningIcon: false,
            tooltipHtmlEncodedText: false,
          };
        }

        const validationMessages = getValidationMessagesForVessel(
          vessel,
          shouldAutoCalculateIntake
        );

        let portDraftErrors = [];
        if (calculationResults && calculationResults.length) {
          const activeVesselResult = calculationResults.filter(
            (cr) => cr.vesselEntryId === vessel.entryId
          );
          if (
            activeVesselResult &&
            activeVesselResult.length &&
            activeVesselResult[0].additionalResults &&
            activeVesselResult[0].additionalResults.errors
          ) {
            portDraftErrors = activeVesselResult[0].additionalResults.errors.filter(
              (error) => error === EXCEED_PORT_DRAFT_WITHOUT_CARGO
            );
          }
        }

        return {
          shouldShowWarningIcon: validationMessages.length > 0 || portDraftErrors.length > 0,
          tooltipHtmlText:
            portDraftErrors.length > 0
              ? 'Vessel exceeds a draft on the voyage without any cargo'
              : 'Value(s) for this vessel are incompatible for this voyage',
        };
      },
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      width: 30,
      cellRenderer: VesselStatusRenderer,
    },
    {
      colId: 'vessel',
      headerClass: 'column-header-left',
      cellClass: 'column-cell',
      headerName: 'Vessel',
      width: 221,
      valueGetter: (params) => {
        const data = params.data;
        return {
          vesselId: data.vesselId,
          vesselName: data.name,
        };
      },
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      cellRenderer: DecorateRendererWithSelectOwnerRowOnFocus(
        DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(
          connect(
            /* data properties: */ createStructuredSelector({
              balticIndexVessels: (state) =>
                state.referenceData && state.referenceData.balticIndexVessels,
            }),
            /* action properties: */ {
              vesselUpdated,
            }
          )(
            ({
              value: vesselIdName,
              balticIndexVessels,
              vesselUpdated,
              node: { id: vesselEntryId },
            }) => (
              <VesselAutoComplete
                marketSegmentId={worksheetInvariantProps.marketSegmentId}
                data-testid="worksheet-vessel-grid-vesselautocomplete-input"
                vessel={vesselIdName}
                onChange={(vessel) => {
                  vesselUpdated({
                    vessel: vessel,
                    worksheetId: worksheetId,
                    vesselEntryId: vesselEntryId,
                    marketSegmentId: worksheetInvariantProps.marketSegmentId,
                  });
                }}
                balticIndexVessels={balticIndexVessels}
                diagnosticId="VesselsGrid/ChangeVessel"
              />
            )
          )
        )
      ),
    },
    {
      colId: 'scrubber',
      headerClass: 'column-header-left',
      cellClass: 'column-cell',
      headerName: 'Scrubber',
      width: 140,
      valueGetter: (params) => params.data.scrubber,
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      cellRenderer: DecorateRendererWithSelectOwnerRowOnFocus(
        DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(
          connect(
            /* data properties: */ null,
            /* action properties: */ {
              scrubberTypeUpdated,
            }
          )(({ value, scrubberTypeUpdated, node: { id: vesselEntryId } }) => (
            <DropDown
              id="scrubber-type"
              data-testid="worksheet-vessel-grid-scrubbertype-input"
              className="imo-2020__section-field imo-2020__section-field--scrubber"
              onChange={(newType) => scrubberTypeUpdated(newType.id, worksheetId, vesselEntryId)}
              role="combobox"
              items={Object.values(ScrubberTypesEnum).map((scrubberType) => ({
                ...scrubberType,
                key: scrubberType.id,
              }))}
              selectedItem={Object.values(ScrubberTypesEnum).find((_) => _.id === value.typeId)}
              diagnosticId="VesselsGrid/ChangeScrubberType"
            />
          ))
        )
      ),
    },
    {
      colId: 'openLocation',
      headerClass: 'column-header-left',
      cellClass: 'column-cell-open-location',
      headerName: 'Open Location',
      valueGetter: ({ data: vessel }) =>
        mapToOpenPositionUIComponentModel(vessel.openPosition).openPosition,
      cellRenderer: DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(
        connect(
          /* data properties: */ null,
          /* action properties: */ {
            openPositionUpdated,
          }
        )(({ value, openPositionUpdated, node: { id: vesselEntryId } }) => (
          <PortAutocomplete
            port={value}
            onChange={(newValue) => openPositionUpdated(newValue, worksheetId, vesselEntryId)}
            marketSegmentId={worksheetInvariantProps.marketSegmentId}
          />
        ))
      ),
      width: 306,
    },
    {
      colId: 'openDate',
      headerClass: 'column-header-open-date',
      cellClass: 'column-cell-open-date',
      headerName: 'Open Date',
      valueGetter: ({ data: vessel }) =>
        mapToOpenPositionUIComponentModel(vessel.openPosition).openDate,
      width: 150,
      cellRenderer: DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(
        connect(
          /* data properties: */ null,
          /* action properties: */ {
            openDateUpdated,
            refreshOpenPosition,
          }
        )(
          ({
            value,
            data: vessel,
            openDateUpdated,
            refreshOpenPosition,
            node: { id: vesselEntryId },
          }) => (
            <>
              <MaritimeDate
                value={value}
                onBlur={(newValue) => openDateUpdated(newValue, worksheetId, vesselEntryId)}
              />
              {worksheetInvariantProps.marketSegmentId === marketSegmentIds.wetCargo ? null /*
                #HideDefunctFeaturesForWetCargo - the 'vessel speed & cons from shared database' is one of the features that doesn't currently exist for Wet cargo (the first sea/calc was only for Dry Cargo which has this feature) */ : (
                <IconButton
                  icon={iconEnum.Refresh}
                  className="vessel__open-position__refresh-button"
                  disabled={!vessel.vesselId}
                  onClick={async () => await refreshOpenPosition(worksheetId, vesselEntryId)}
                  diagnosticId="VesselDetailsRenderer/RefreshOpenPosition"
                />
              )}
            </>
          )
        )
      ),
      headerComponent: (props) => {
        return (
          <>
            <label>Open Date</label>
            {worksheetInvariantProps.marketSegmentId === marketSegmentIds.wetCargo ? null /*
            #HideDefunctFeaturesForWetCargo - the 'vessel open position from shared database' is one of the features that doesn't currently exist for Wet cargo (the first sea/calc was only for Dry Cargo which has this feature) */ : (
              <IconButton
                icon={iconEnum.Refresh}
                onClick={async () => await refreshAllVesselsOpenPosition(worksheetId)}
                diagnosticId="VesselDetailsRenderer/RefreshAllVesselsOpenPostitions"
              />
            )}
          </>
        );
      },
    },
    {
      colId: 'grossTimeCharter',
      type: 'numericColumn',
      cellClass: 'column-cell',
      headerName: 'GTC ($/d)',
      field: 'grossTimeCharter',
      width: 110,
      valueGetter: (params) => {
        const vessel = params.data;
        return {
          grossTimeCharter: vessel.grossTimeCharter,
          vessel: vessel,
          shouldAutoCalculateIntake: shouldAutoCalculateIntake,
        };
      },
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      cellRenderer: DecorateRendererWithSelectOwnerRowOnFocus(
        DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(
          connect(
            /* data properties: */ null,
            /* action properties: */ {
              grossTimeCharterUpdated,
            }
          )(
            ({
              value: { grossTimeCharter, vessel, shouldAutoCalculateIntake },
              grossTimeCharterUpdated,
              node: { id: vesselEntryId },
            }) => (
              <NumericInput
                data-testid="worksheet-vessel-grid-grosstimecharter-input"
                className="vessel-grid__row-gross-time-charter"
                value={grossTimeCharter}
                maxDecimalDigits="2"
                {...getDynamicVesselValidationRules(vessel, shouldAutoCalculateIntake)
                  .grossTimeCharter.ruleUsageParams}
                onInputChange={(newGrossTimeCharter) => {
                  grossTimeCharterUpdated(newGrossTimeCharter, worksheetId, vesselEntryId);
                }}
                diagnosticId="VesselsGrid/ChangeGrossTimeCharter"
              />
            )
          )
        )
      ),
    },
    {
      colId: 'netTimeCharter',
      type: 'numericColumn',
      cellClass: 'column-cell',
      headerName: 'NTC ($/d)',
      width: 110,
      valueGetter: (params) => {
        const vessel = params.data;
        return {
          netTimeCharter: vessel.netTimeCharter,
          vessel: vessel,
          shouldAutoCalculateIntake: shouldAutoCalculateIntake,
        };
      },
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      cellRenderer: DecorateRendererWithSelectOwnerRowOnFocus(
        DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(
          connect(
            /* data properties: */ null,
            /* action properties: */ {
              netTimeCharterUpdated,
            }
          )(
            ({
              value: { netTimeCharter, vessel, shouldAutoCalculateIntake },
              netTimeCharterUpdated,
              node: { id: vesselEntryId },
            }) => (
              <NumericInput
                className="vessel-grid__row-net-time-charter"
                value={netTimeCharter}
                maxDecimalDigits="2"
                {...getDynamicVesselValidationRules(vessel, shouldAutoCalculateIntake)
                  .netTimeCharter.ruleUsageParams}
                data-testid="worksheet-vessel-grid-nettimecharter-input"
                onInputChange={(newNetTimeCharter) => {
                  netTimeCharterUpdated(newNetTimeCharter, worksheetId, vesselEntryId);
                }}
                diagnosticId="VesselsGrid/ChangeNetTimeCharter"
              />
            )
          )
        )
      ),
    },
    {
      colId: 'ballastBonus',
      type: 'numericColumn',
      cellClass: 'column-cell',
      headerName: 'Ballast Bonus ($)',
      width: 110,
      valueSetter: (params) => {
        /* Added to silence aggrid console message about column missing `valueSetter`. This is supposed to be used by cellEditor to pass changes made in the view back, however it is ignored as we're using redux to propagate changes */
      },
      valueGetter: (params) => {
        const vessel = params.data;
        return {
          ballastBonus: vessel.ballastBonus,
          vessel: vessel,
          shouldAutoCalculateIntake: shouldAutoCalculateIntake,
        };
      },
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      cellRenderer: DecorateRendererWithSelectOwnerRowOnFocus(
        DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(
          connect(
            /* data properties: */ null,
            /* action properties: */ {
              ballastBonusUpdated,
            }
          )(
            ({
              value: { ballastBonus, vessel, shouldAutoCalculateIntake },
              ballastBonusUpdated,
              node: { id: vesselEntryId },
            }) => (
              <NumericInput
                className="vessel-grid__row-ballast-bonus"
                value={ballastBonus}
                maxDecimalDigits="2"
                {...getDynamicVesselValidationRules(vessel, shouldAutoCalculateIntake).ballastBonus
                  .ruleUsageParams}
                data-testid="worksheet-vessel-grid-ballastbonus-input"
                onInputChange={(newBallastBonus) => {
                  ballastBonusUpdated(newBallastBonus, worksheetId, vessel.entryId);
                }}
                diagnosticId="VesselsGrid/ChangeBallastBonus"
              />
            )
          )
        )
      ),
    },
    {
      colId: 'addVessel',
      headerClass: 'column-header-action',
      cellClass: 'column-cell-action',
      headerName: '',
      width: 34,
      headerComponent: (props) => {
        return (
          <MaterialIconButton
            data-testid="worksheet-vessel-grid-vesseladd-button"
            tabIndex={-1}
            icon={iconEnum.AddCircle}
            className="actions-add-vessel"
            onClick={() =>
              vesselsAdd({
                worksheetId: worksheetId,
                vessel: {
                  ...createEmptyVessel(),
                  entryId: getNewVesselEntryId(),
                },
              })
            }
            diagnosticId="VesselDetailsRenderer/CreateEmptyVessel"
          />
        );
      },
      valueGetter: (params) => params.data.entryId,
      equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      cellRenderer: (props) => {
        const vesselEntryId = props.value;
        return (
          <MaterialIconButton
            data-testid="worksheet-vessel-grid-vesselremove-button"
            tabIndex={-1}
            icon={iconEnum.RemoveCircle}
            className="actions-delete-vessel"
            onClick={async () => {
              const vesselsCount = props.api.getDisplayedRowCount();
              if (vesselsCount === 1) {
                // this gives the appearance that deleting the last row clears the row data and it maintains the minimum one vessel in the grid
                vesselsAdd({
                  worksheetId: worksheetId,
                  vessel: {
                    ...createEmptyVessel(),
                    entryId: getNewVesselEntryId(),
                  },
                });
              }

              if (props.node.expanded) {
                await props.node.setExpanded(false);
                setTimeout(() => {
                  vesselsRemove({
                    worksheetId: worksheetId,
                    vesselId: vesselEntryId,
                  });
                }, 100);
              } else {
                vesselsRemove({
                  worksheetId: worksheetId,
                  vesselId: vesselEntryId,
                });
              }
            }}
            diagnosticId="VesselDetailsRenderer/RemoveVessel"
          />
        );
      },
      suppressNavigable: true,
    },
  ];
}

/** Part of #TabbingSupportViaNativeHTMLTab_When_DontUseAgGridCellsEditModeButImplementEditInReadOnlyModeRenderer - ensures that the row becomes selected when any of its input are tabbed-into. TODO: This will not be needed if we action #TODOConsiderUsingAgGridRecognizedFocus, as focus readily selects rows */
function DecorateRendererWithSelectOwnerRowOnFocus(RendererClass) {
  const decoratedClass = class extends RendererClass {
    // Note - We can't use all Ag-grid callbacks here. For allowed ones see  https://www.ag-grid.com/javascript-grid-cell-editor/#react-methods-lifecycle
    constructor(props) {
      super(props);
      this._selectRowOnInteractionCleanupFunctions.push(
        addEventListenerGetCleanupFn(props.eGridCell, 'focusin', () => {
          if (!this.props.node.selected) this.props.node.setSelected(true);
        })
      );
    }

    componentWillUnmount() {
      for (const cleanupFunction of this._selectRowOnInteractionCleanupFunctions) {
        cleanupFunction();
      }

      super.componentWillUnmount && super.componentWillUnmount();
    }

    _selectRowOnInteractionCleanupFunctions: Array<CleanupFn> = [];
  };

  Object.defineProperty(decoratedClass, 'name', {
    // As per 'dynamic class name' StackoVerflow - https://stackoverflow.com/a/46132163
    value: `DecoratedWithSelectOwnerRowOnFocus${RendererClass}`,
  });

  return decoratedClass;
}

/**
  Allows use of React functional components while still providing the `refresh = () => true;`
 which prevents Ag-Grid from causing the flicker from rebuilding the HTML node.
 */
function DecorateFunctionalComponentRendererWithNoElementRecreateOnAgGridRefresh(RenderFunction) {
  const decoratedClass = class extends React.Component implements CellRendererHooks {
    state = {};

    refresh = (newProps) => {
      /* #AgGridReactBindingDoesntUpdateValueOrDataProps - Despite Ag-grid calls this `refresh` and despite
      it's also responsible for providing `this.props` to this React component, it doesn't actually change
      the props from the initial value on such a refresh, and our `render()` will see the old props
      (it could especially at least update `this.props.value`, since it has produced in from `valueGetter`
      and caused the refresh to be even called!). As such, we need to workaround and use state to pass the
      variant values. Here we take care of `value` and `data` because they are standard Ag-grid provided.
      If more are needed we can take a `getVariantProps` as a parameter and take care of any custom ones. */
      if (this.state.value !== newProps.value || this.state.data !== newProps.data)
        this.setState({ value: newProps.value, data: newProps.data });
      return true;
    };

    render = () => <RenderFunction {...this.props} {...this.state} />;
  };

  Object.defineProperty(decoratedClass, 'name', {
    // As per 'dynamic class name' StackoVerflow - https://stackoverflow.com/a/46132163
    value: `DecoratedWithNoElementLossOnAgGridRefresh${RenderFunction.name}`,
  });

  return decoratedClass;
}
