import { createSelector } from 'reselect';
import { getActiveMarketSegmentId } from '../../common-selectors/get-active-marketSegmentId';

export const selector = createSelector(
  (state) => state.appMetadata.stageRightPanel,
  getActiveMarketSegmentId,
  (activeTab) => ({ activeTab }),
  (activeTab, activeMarketSegmentId) => ({
    activeTab,
    activeMarketSegmentId: activeMarketSegmentId,
  })
);
