import { buildAction } from 'utilities/redux';
import {
  SET_STAGE_LEFT_PANEL,
  SET_STAGE_RIGHT_PANEL,
  SELECTED_RIGHT_PANEL_KEY,
  SET_ROUTE_GRAPH_VERSION,
} from 'constants/action-types/stage';

export const setStageLeftAs = (panelName) => {
  return buildAction(SET_STAGE_LEFT_PANEL, panelName);
};

export const setStageRightAs = (panelName) => {
  sessionStorage.setItem(SELECTED_RIGHT_PANEL_KEY, panelName);
  return buildAction(SET_STAGE_RIGHT_PANEL, panelName);
};

export const setRouteGraphVersion = (routeGraphVersion) => {
  return buildAction(SET_ROUTE_GRAPH_VERSION, routeGraphVersion);
};
